/*
*
* ManageLPOA Reducers
*
*/
import { cloneDeep, pick } from 'lodash';
import { properCase } from '@frontend/common';
import {
  LPOA_SETTINGS_GET,
  LPOA_SETTINGS_UPDATE,
  LPOA_ADDRESS_UPDATE,
} from './constants';

const initialState = {
  settings: {},
  addresses: {
    mailing: {},
    physical: {},
  },
  fullSettingsObject: {},
};

export function getLPOAPermissions(firm) {
  return pick(firm, [
    'AllowViewStateTaxDocuments',
    'AllowViewFederalTaxDocuments',
    'AllowContribute',
    'AllowTransfer',
    'AllowOptionChange',
    'AllowWithdraw',
  ]);
}

export function formatAddress(address) {
  if (address) {
    return {
      id: address.AddressId,
      street1: properCase(address.StreetAddress1),
      street2: properCase(address.StreetAddress2),
      city: properCase(address.City),
      state: address.State,
      zip: address.PostalCode,
    };
  }
  
  return {
    id: 0,
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
  };
}

function ManageLPOAReducer(state = initialState, action) {
  
  const newState = cloneDeep(state);

  switch (action.type) {
    
    case LPOA_SETTINGS_GET: {
      const firm = action.payload.data;
      newState.settings = getLPOAPermissions(firm);
      newState.addresses = {
        mailing: formatAddress(firm.Address),
        physical: formatAddress(firm.Address1),
      };
      newState.fullSettingsObject = firm;

      return newState;
    }

    case LPOA_SETTINGS_UPDATE: {
      const updatedFirm = action.payload.data;
      newState.settings = getLPOAPermissions(updatedFirm);
      newState.fullSettingsObject = updatedFirm;
      
      return newState;
    }

    case LPOA_ADDRESS_UPDATE: {
      const updatedFirm = action.payload.data;
      newState.addresses = {
        mailing: formatAddress(updatedFirm.MailingAddress),
        physical: formatAddress(updatedFirm.PhysicalAddress),
      };

      return newState;
    }
    
    default:
      return state;
  }

}

export default ManageLPOAReducer;