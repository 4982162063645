/*
*
* Username Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  TextField,
  Button,
} from '@mui/material';

import {
  LoadingOverlay,
  notificationShow,
  PasswordInput,
} from '@frontend/common';

import { usernameChange } from 'components/AppRoot/Navigation/actions';

import styles from '../styles.module.css';

const select = (state) => ({
  claims: state.session.claims,
});

export class Username extends React.Component {
  static propTypes = {
    notificationShow: PropTypes.func.isRequired, 
    usernameChange: PropTypes.func.isRequired,
    claims: PropTypes.object.isRequired,
  };

  state = {
    username: '',
    usernameError: '',
    usernamePassword: '',
    usernamePasswordError: '',
    loading: false,
  }

  disableUpdateButton = () => {
    const { username } = this.state;

    const err_field_required = 'Field is required.';

    let usernameError = '';
    if (!username) {
      usernameError = err_field_required;
    }

    return Boolean(usernameError);
  }

  usernameInputsValidate = () => {
    const { username, usernamePassword } = this.state;
    const { claims: { Login } } = this.props;
    
    let usernameError = '';
    let usernamePasswordError = '';

    const err_field_required = 'Field is required.';
    const err_different_username = 'Username is same as current';
    const err_username_with_numbers = 'Username cannot have more than 3 consecutive digits.';
    const err_username_length = 'Field cannot exceed 40 characters.';
    const err_username_spaces = 'Field cannot contain leading or trailing spaces.';
    const err_username_min_length = 'Username must be at least 6 characters.';   

    if (username.match(/\d\d\d\d/)) {
      usernameError = err_username_with_numbers;
    }

    if (username.length > 40) {
      usernameError = err_username_length;
    }

    if (username.match(/^\s+|\s+$/)) {
      usernameError = err_username_spaces;
    }

    if (username.length > 0 && username.length < 6) {
      usernameError = err_username_min_length;
    }

    if (username.toLowerCase() === Login.toLowerCase()) {
      usernameError = err_different_username;
    }

    if (!usernamePassword) {
      usernamePasswordError = err_field_required;
    }

    this.setState({ usernameError, usernamePasswordError });
    const hasError = Boolean(usernameError) || Boolean(usernamePasswordError);

    return !hasError;
  }

  onUsernameChange = (e) => {
    // also reset username errors
    this.setState({
      username: e.target.value,
      usernameError: '',
    });
  }

  onUsernamePasswordChange = (e) => {
    // also reset password errors
    this.setState({
      usernamePassword: e.target.value,
      usernamePasswordError: '',
    });
  }

  onSubmitUsername = (e) => {
    e.preventDefault();
    const { username, usernamePassword } = this.state;
    // if validation doesn't return any errors proceed with api call
    if (this.usernameInputsValidate()) {
      this.setState({ loading: true });
      this.props.usernameChange(username, usernamePassword)
        .then(() => {
          this.props.notificationShow('Username updated.', 'success');
          this.setState({ loading: false, usernamePassword: '' });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  }

  render() {
    const { claims: { Login } } = this.props;
    const { username, usernameError, usernamePassword, usernamePasswordError, loading } = this.state;

    return (
      <form className={styles.myInfo_right_container} onSubmit={this.onSubmitUsername}>
        <div className={styles.myInfo_textInput}>
          <TextField
            label='Current Username'
            name='currentUsername'
            value={Login}
            disabled={true}
            fullWidth
          />
        </div>
        <div className={styles.myInfo_textInput}>
          <TextField
            label='New Username'
            name='username'
            value={username}
            onChange={this.onUsernameChange}
            error={Boolean(usernameError)}
            helperText={usernameError}
            disabled={loading}
            fullWidth
            autoFocus
          />
        </div>
        <div>
          <PasswordInput
            autoComplete='current-password'
            label='Current Password'
            name='usernamePassword'
            value={usernamePassword}
            onChange={this.onUsernamePasswordChange}
            errorText={usernamePasswordError}
            disabled={loading}
            fullWidth
            variant='standard'
          />
        </div>
        <div className={styles.myInfo_button}>
          <LoadingOverlay show={loading}>
            <Button
              type='submit'
              variant='contained'
              disabled={this.disableUpdateButton()}
            >
              Change Username
            </Button>
          </LoadingOverlay>
        </div>
      </form>
    );
  }
}

export default connect(select, { 
  usernameChange,
  notificationShow
})(Username);
