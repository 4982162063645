import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import bowser from 'bowser';
import { withRouter } from 'react-router-dom';

import { My529Logo } from '@frontend/common';
import { SwipeableDrawer } from '@mui/material';

import SidenavMenuOption from './SidenavMenuOption';

import styles from './styles.module.css';

const appVersion = window.appVersion;

const select = (state) => ({
  userDetails: state.session.userDetails,
  lpoaDetails: state.session.lpoaDetails,
  permissions: state.session.permissions,
  portfolioProviders: state.manageData.portfolioProviders,
  lpoaHelpNumber: state.static.environmentVars.lpoaHelpNumber,
  lpoaHelpEmail: state.static.environmentVars.lpoaHelpEmail,
  lpoaHelpFax: state.static.environmentVars.lpoaHelpFax,
  documentLinks: state.static.documents,
  advisor: state.myInfo.advisor,
  claims: state.session.claims,
});

export class Sidenav extends Component {

  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    handleLogout: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    lpoaDetails: PropTypes.object.isRequired,
    permissions: PropTypes.object.isRequired,
    portfolioProviders: PropTypes.array,
    lpoaHelpNumber: PropTypes.string,
    lpoaHelpEmail: PropTypes.string,
    lpoaHelpFax: PropTypes.string,
    documentLinks: PropTypes.object.isRequired,
    advisor: PropTypes.object.isRequired,
    claims: PropTypes.object.isRequired,
  };

  accountOptionsCompose() {
    const { optionChanges } = this.props.permissions;
    const { MasterAdmin } = this.props.claims; // A String of either 'true' or 'false'
    const options = [
      <SidenavMenuOption
        key='existing-accounts-option'
        name='Existing Accounts'
        link='/accounts'
        onOptionClick={this.props.onClose}
      />,
      <SidenavMenuOption
        key='new-accounts-option'
        name='New Accounts'
        link='/new-accounts'
        onOptionClick={this.props.onClose}
      />
    ];
    if (optionChanges) {
      options.push(
        <SidenavMenuOption
          key='bulk-option-change-option'
          name='Bulk Option Change'
          link='/bulk-option-change'
          onOptionClick={this.props.onClose}
        />
      );
    }
    if (MasterAdmin === 'true') {
      options.push(
        <SidenavMenuOption
          key='bulk-statement-download-option'
          name='Bulk Statement Download'
          link='/bulk-statement-download'
          onOptionClick={this.props.onClose}
        />
      );
    }
    return options;
  }

  adminOptionsCompose() {
    const { open } = this.props;
    const { editLPOAContactInfo, editLPOASettings, manageUsers, isFirmEntity } = this.props.permissions;

    if (editLPOAContactInfo || editLPOASettings || manageUsers) {
      const adminOptions = [];
      
      if (editLPOAContactInfo || editLPOASettings) {
        adminOptions.push(
          <SidenavMenuOption
            name='Manage LPOA'
            link='/manage-lpoa'
            onOptionClick={this.props.onClose}
            key='ManageLPOA'
          />
        );
      }

      if (manageUsers && isFirmEntity) {
        adminOptions.push(
          <SidenavMenuOption
            name='Manage Users'
            link='/manage-users'
            onOptionClick={this.props.onClose}
            key='ManageUsers'
          />
        );
      }

      return (
        <SidenavMenuOption
          isSideNavOpen={open}
          name='Admin'
          rootMenuOptionIconName='settings'
        >
          {adminOptions}
        </SidenavMenuOption>
      );
    }
    else {
      return null;
    }
  }

  dataOptionsCompose() {
    const { permissions: { setupData, downloadData }, portfolioProviders, open, } = this.props;
    const dataOptions = [];

    if (downloadData && portfolioProviders.filter(provider => provider.enabled).length > 0) {
      dataOptions.push(
        <SidenavMenuOption
          name='Download'
          link='/manage-data/download'
          onOptionClick={this.props.onClose}
          key='PortfolioDataDownload'
        />
      );
    }

    if (setupData) {
      dataOptions.push(
        <SidenavMenuOption
          name='Accounts to Export'
          link='/manage-data/accounts'
          onOptionClick={this.props.onClose}
          key='AccountsToExport'
        />,
        <SidenavMenuOption
          name='Setup'
          link='/manage-data'
          onOptionClick={this.props.onClose}
          key='SetupData'
        />
      );
    }

    if (dataOptions.length > 0) {
      return (
        <SidenavMenuOption
          isSideNavOpen={open}
          name='Data'
          rootMenuOptionIconName='import_export'
        >
          {dataOptions}
        </SidenavMenuOption>
      );
    }

    return null;
  }

  onMyInfoClick = () => {
    this.props.onClose();
    this.props.history.push('/my-info');
  }

  onLPOASettingsClick = () => {
    this.props.onClose();
    this.props.history.push('/lpoa-settings');
  }

  onLogoutClick = () => {
    this.props.handleLogout();
    this.props.onClose();
  }

  render() {
    /* eslint-disable indent */
    const { 
      permissions, lpoaDetails, userDetails, open, onClose, onOpen, 
      lpoaHelpNumber, lpoaHelpEmail, lpoaHelpFax, advisor
    } = this.props;
    return (
      <SwipeableDrawer
        open={open}
        onClose={onClose}
        onOpen={onOpen}
      >
        <div className={`${styles.Sidenav_contents} hideOnPrint`}>
          {/* There are no CSS styles for Sidenav_top */}
          <div className='Sidenav_top'> 
            <div className={styles.Sidenav_userDetails}>
              <div className={styles.Sidenav_detailRow}>
                <span><b>{lpoaDetails.firmName}</b></span>
              </div>
              <div className={styles.Sidenav_detailRow}>
                <span>LPOA #: </span>
                <span><b>{userDetails.lpoaNumber}</b></span>
              </div>
              <div className={styles.Sidenav_detailRow}>
                <span>PIN: </span>
                <span><b>{advisor.Pin}</b></span>
              </div>
              <div className={styles.Sidenav_detailRow}>
                <span>Previous Login: </span>
                <span>{userDetails.previousLogin}</span>
              </div>
            </div>
            <SidenavMenuOption
              name='Home'
              link='/'
              onOptionClick={onClose}
              rootMenuOptionIconName='home'
            />
            <SidenavMenuOption
              isSideNavOpen={open}
              name='Accounts'
              rootMenuOptionIconName='people'
            >
              {this.accountOptionsCompose()}
            </SidenavMenuOption>
            {(!bowser.mobile && !bowser.tablet) && (
                <SidenavMenuOption
                  name='Templates'
                  link='/templates'
                  onOptionClick={onClose}
                  rootMenuOptionIconName='insert_drive_file'
                />
            )}
            {(permissions.adminReports || permissions.accountReports) && (
                <SidenavMenuOption
                  name='Reports'
                  link='/reports'
                  onOptionClick={onClose}
                  rootMenuOptionIconName='insert_chart_outlined'
                />
            )}
            {this.dataOptionsCompose()}
            {this.adminOptionsCompose()}
            <SidenavMenuOption
              isSideNavOpen={open}
              name='Resources'
              rootMenuOptionIconName='link'
            >
              <SidenavMenuOption
                name='Advisor Info'
                externalLink='https://advisor.my529.org/'
                onOptionClick={onClose}
              />
              <SidenavMenuOption
                name='Program Description'
                externalLink={this.props.documentLinks.programDescription || '#'}
                onOptionClick={onClose}
              />
              <SidenavMenuOption
                name='Forms'
                externalLink='https://my529.org/how-to-save/forms-documents/'
                onOptionClick={onClose}
              />
              <SidenavMenuOption
                name='Performance'
                externalLink='http://www.my529.org/performance-returns/'
                onOptionClick={onClose}
              />
              <SidenavMenuOption
                name='FAQs'
                externalLink='https://my529.org/faq/'
                onOptionClick={onClose}
              />
            </SidenavMenuOption>
            <div className={styles.Sidenav_profileSection}>
              <SidenavMenuOption
                isSideNavOpen={open}
                name='Profile'
                rootMenuOptionIconName='person'
              >
                <SidenavMenuOption
                  name='My Info'
                  onOptionClick={this.onMyInfoClick}
                />
                <SidenavMenuOption
                  name='LPOA Settings'
                  onOptionClick={this.onLPOASettingsClick}
                />
                <SidenavMenuOption
                  name='Log out'
                  onOptionClick={this.onLogoutClick}
                />
              </SidenavMenuOption>
            </div>
          </div>
          <div className={styles.Sidenav_bottom}>
            <div className={styles.Sidenav_help}>
              <My529Logo height='35px' />
              <div>Need help? Contact my529 at:</div>
              <span>{lpoaHelpEmail ? <a href={`mailto:${lpoaHelpEmail}`}>{lpoaHelpEmail}</a> : 'Loading...'}</span>
              <span>p:&nbsp;{lpoaHelpNumber ? <a href={`tel:${lpoaHelpNumber}`}>{lpoaHelpNumber}</a> : 'Loading...'}</span>
              <span>f:&nbsp;{lpoaHelpFax ? <p>{lpoaHelpFax}</p> : 'Loading...'}</span>
            </div>
            <div className={styles.Sidenav_buildVersion}>
              Version: {appVersion}
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    );
    /* eslint-enable indent */
  }
}

export default withRouter(connect(select, {})(Sidenav));
