/*
*
* BulkStatementDownload actions
*
*/
import {
  BULK_STATEMENTS_AVAILABLE_GET,
  BULK_STATEMENTS_DOWNLOAD_GET,
} from './constants';

import { GETrequest } from 'utils/helpers/api_handler';

export function bulkStatementsAvailableGet() {
  const response = GETrequest('/Accounts/bulkStatementsAvailable');

  return {
    type: BULK_STATEMENTS_AVAILABLE_GET,
    payload: response,
  };
}

export function bulkStatementsDownloadGet() {
  const response = GETrequest('/Accounts/bulkStatements', { responseType: 'blob' });

  return {
    type: BULK_STATEMENTS_DOWNLOAD_GET,
    payload: response,
  };
}
