import { POSTrequest } from 'utils/helpers/api_handler';
import {
  USER_NAME_FORGOT
} from './constants';


export function forgotUsername(params) {
  const response = POSTrequest('/authentication/forgotusername', params);
  return {
    type: USER_NAME_FORGOT,
    payload: response
  };
}