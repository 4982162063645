import { cloneDeep, findIndex, sortBy } from 'lodash';

import dayjs from 'dayjs';

import {
  PASSWORD_REQUIREMENTS_GET,
  RELATIONSHIPS_GET,
  OPTIONS_GET,
  CUSTOM_OPTIONS_GET,
  POA_LEVELS_GET,
  AGE_BRACKETS_GET,
  ENVIRONMENT_VARIABLES_GET,
  STATIC_DOCUMENTS_GET,
  ENV_COLOR_KEY,
  WEB_MESSAGES_GET,
  SET_LOADING_PUBLIC_VARS,
  BANNER_LOCATIONS,
  GET_NEW_AGE_BASED_SCHEMA,
  GET_NEW_STATIC_SCHEMA,
} from './constants';

import { convert2DCalculatorSchemaTo3D } from '@frontend/common';

import messages from './messages.js';

const initialState = {
  ageBrackets: [],
  environmentVars: {
    downloadPortfolioData: {
      oldestSelectableDate: ''
    },
    contributionVariables: {},
    usStates: [],
    withdrawalVariables: {
      paymentTypes: [],
      recipientTypes: [],
      sellDates: [],
      withdrawalTypes: [],
    },
    environmentColor: '',
    AccountAccessNoFrontEndActivityTimeout: 300, // just to be on the safe side set to 5 mins
    LogoutWarningTimeout: 300, // just to be on the safe side set to 5 mins
    ServerDateTime: new Date(),
    AnnualGiftExclusionAmount: 0,
    MaximumBeneficiaryDeposit: 0,
    MaximumBenficiaryAccountValueForGift: 0,
  },
  newAgeBasedSchema: {
    TemplateName: null,
    FundCategories: [],
  },
  newStaticSchema: {
    TemplateName: null,
    FundCategories: [],
  },
  customStaticOptions: [],
  customAgeBasedOptions: [],
  options: [],
  passwordRequirements: [],
  poaLevels: [],
  relationships: [],
  documents: {},
  // needs to be initialized to empty strings
  webMessages: Object.values(BANNER_LOCATIONS).reduce((obj, bannerLocation) => ({ ...obj, [bannerLocation]: '' }), {}),
  loadingPublicVars: false,
};

function StaticResourcesReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case PASSWORD_REQUIREMENTS_GET: {
      newState.passwordRequirements = action.payload.data;
      return newState;
    }

    case RELATIONSHIPS_GET: {
      newState.relationships = action.payload.data;

      return newState;
    }

    case OPTIONS_GET: {
      newState.options = action.payload.data.map(option => {
        const formattedOption = {
          id: option.OptionId,
          isCustom: option.IsCustom,
          my529Fee: option.AssetFeeRate,
          name: option.Name,
          optionType: option.OptionType,
          isStaticOrEnrollment: (option.OptionType === 'S' || option.OptionType === 'T') && !option.IsCustom,
        };
        const allocations = option.OptionAllocations;
        if (allocations.length === 0) {
          formattedOption.funds = [];
        }
        else if (allocations.length === 1) {
          formattedOption.funds = allocations[0].OptionAllocationFunds.map(fund => {
            return {
              id: fund.Fund.FundId,
              name: fund.Fund.Name,
              ticker: fund.Fund.StockSymbol,
              fee: fund.Fund.FeeRate,
              percentage: fund.Percentage,
            };
          });
        }

        return formattedOption;
      });

      return newState;
    }

    case GET_NEW_AGE_BASED_SCHEMA: {
      newState.newAgeBasedSchema = convert2DCalculatorSchemaTo3D(action.payload.data);
      return newState;
    }

    case GET_NEW_STATIC_SCHEMA: {
      newState.newStaticSchema = convert2DCalculatorSchemaTo3D(action.payload.data);
      return newState;
    }

    case CUSTOM_OPTIONS_GET: {
      const staticOptions = [];
      const ageBasedOptions = [];
      action.payload.data.forEach(option => {
        const formattedOption = {
          id: option.CustomTemplateHeaderId,
          my529Fee: null,
          name: option.Name,
          optionType: option.TypeCode,
        };
        if (option.TypeCode === 'S') {
          formattedOption.funds = option.CustomTemplateHeaderFunds.map(fund => {
            return {
              id: fund.Fund.FundId,
              name: fund.Fund.Name,
              ticker: fund.Fund.StockSymbol,
              fee: fund.Fund.FeeRate,
              percentage: fund.Percentage,
            };
          });
          staticOptions.push(formattedOption);
        }
        else if (option.TypeCode === 'A') {
          const ageBrackets = [];

          option.CustomTemplateHeaderFunds.forEach(fund => {
            const existingAgeBracketIndex = findIndex(ageBrackets, { minAge: fund.MinimumAge });
            if (existingAgeBracketIndex !== -1) {
              ageBrackets[existingAgeBracketIndex].funds.push({
                id: fund.Fund.FundId,
                name: fund.Fund.Name,
                ticker: fund.Fund.StockSymbol,
                fee: fund.Fund.FeeRate,
                percentage: fund.Percentage,
              });
            }
            else {
              ageBrackets.push({
                minAge: fund.MinimumAge,
                maxAge: null,
                funds: [{
                  id: fund.Fund.FundId,
                  name: fund.Fund.Name,
                  ticker: fund.Fund.StockSymbol,
                  fee: fund.Fund.FeeRate,
                  percentage: fund.Percentage,
                }],
              });
            }
          });

          formattedOption.ageBrackets = sortBy(ageBrackets, ['minAge']);
          ageBasedOptions.push(formattedOption);
        }
      });

      newState.customAgeBasedOptions = sortBy(ageBasedOptions, [ageBasedOption => ageBasedOption.name.toLowerCase()], ['name']);
      newState.customStaticOptions = sortBy(staticOptions, [staticOption => staticOption.name.toLowerCase()], ['name']);

      return newState;
    }

    case POA_LEVELS_GET: {
      newState.poaLevels = action.payload.data.map(level => ({
        key: level,
        displayName: `Level ${level}`,
      }));

      return newState;
    }

    case AGE_BRACKETS_GET: {
      newState.ageBrackets = action.payload.data.map(range => ({
        displayName: range.Description.match(/Age\s(\d*)\s?([-]?)\s?(\d*)([+]*)/).slice(1)
          .reduce(((str, match) => str + match), ''),
        longDisplayName: range.Description,
        minAge: range.MinimumAge,
        maxAge: range.MaximumAge
      }));

      return newState;
    }

    case ENVIRONMENT_VARIABLES_GET: {
      const data = { ...newState.environmentVars, ...action.payload.data, }; // keep any previous parameters before loading over them

      newState.environmentVars.AccountAccessNoFrontEndActivityTimeout = data.AccountAccessNoFrontEndActivityTimeout;
      newState.environmentVars.LogoutWarningTimeout = data.LogoutWarningTimeout;
      newState.environmentVars.ServerDateTime = data.ServerDateTime;
      newState.environmentVars.AnnualGiftExclusionAmount = data.AnnualGiftExclusionAmount;
      newState.environmentVars.MaximumBeneficiaryDeposit = data.MaximumBeneficiaryDeposit;
      newState.environmentVars.MaximumBenficiaryAccountValueForGift = data.MaximumBenficiaryAccountValueForGift;
      newState.environmentVars.DocumentTypes = data.DocumentTypes;
      newState.environmentVars.GiactResultTypes = data.GiactResultTypes;

      newState.environmentVars.downloadPortfolioData = {
        ...newState.environmentVars.downloadPortfolioData,
        ...(data.PortfolioDownloadAvailabilityMonths && { oldestSelectableDate: dayjs().subtract(data.PortfolioDownloadAvailabilityMonths, 'month') }),
      };

      newState.environmentVars.contributionVariables = {
        ...newState.environmentVars.contributionVariables,
        ...(data.CurrentTradeDate && { firstAvailTradeDate: dayjs(data.CurrentTradeDate).format('YYYY-MM-DD') }),
        ...(data.MaximumOneTimeOnlineContribution && { maxOneTimeContribution: data.MaximumOneTimeOnlineContribution }),
        ...(data.MinimumOneTimeOnlineContribution && { minOneTimeContribution: data.MinimumOneTimeOnlineContribution }),
        ...(data.MaximumRecurringOnlineContribution && { maxRecurringContribution: data.MaximumRecurringOnlineContribution }),
        ...(data.MinimumOneTimeOnlineContribution && { minRecurringContribution: data.MinimumOneTimeOnlineContribution }),
        ...(data.ValidOneTimeContributionDates && { oneTimeContributionDates: data.ValidOneTimeContributionDates.map(date => dayjs(date).format('YYYY-MM-DD')) }),
        ...(data.MaximumRecurringContributionLeadDays && { recurringContributionLeadDays: data.MaximumRecurringContributionLeadDays }),
      };

      newState.environmentVars.withdrawalVariables = {
        ...newState.environmentVars.withdrawalVariables,
        ...(data.MinimumOnlineWithdrawalAmount && { minWithdrawal: data.MinimumOnlineWithdrawalAmount }),
        ...(data.DisbursementSignatureGuaranteeAmount && { maxWithdrawal: data.DisbursementSignatureGuaranteeAmount }),
        ...(data.PaymentMethods && {
          paymentTypes: data.PaymentMethods.map(type => ({
            id: type.Code,
            name: type.Name,
          }))
        }),
        ...(data.RecipientTypes && {
          recipientTypes: data.RecipientTypes
            .filter(type => type.Code === 'P' || type.Code === 'B' || type.Code === 'I')
            .map(type => ({
              id: type.Code,
              name: type.Name,
              messages: messages.find(message => message.id === type.Code).messages
            }))
        }),
        ...(data.ValidOneTimeWithdrawalDates && { sellDates: data.ValidOneTimeWithdrawalDates.map(date => dayjs(date).format('YYYY-MM-DD')) }),
        ...(data.TradeCutOffTime && { tradeCutoff: dayjs(data.TradeCutOffTime).format('YYYY-MM-DD') }),
        ...(data.TradeCutoffApproachingWarningMinutes && { tradeCutoffWarningTime: data.TradeCutoffApproachingWarningMinutes }),
        ...(data.WithdrawalTypes && {
          withdrawalTypes: data.WithdrawalTypes
            .filter(type => type.Code === 'HigherEd' || type.Code === 'K-12Tuition' || type.Code === 'NonQualified')
            .map(type => ({
              id: type.Code,
              name: type.Name,
              messages: messages.find(message => message.id === type.Code).messages
            }))
        }),
      };

      if (data.USStates) {
        newState.environmentVars.usStates = data.USStates.map(state => ({ abbreviation: state.Code, fullName: state.Name }));
        // add clear state item at index 0
        newState.environmentVars.usStates.unshift({ abbreviation: '', fullName: 'Clear State' });
      }
      if (data.ProfessionalServicesPhoneNumber) newState.environmentVars.lpoaHelpNumber = data.ProfessionalServicesPhoneNumber;
      if (data.ProfessionalServicesEmail) newState.environmentVars.lpoaHelpEmail = data.ProfessionalServicesEmail;
      if (data.ProfessionalServicesFaxNumber) newState.environmentVars.lpoaHelpFax = data.ProfessionalServicesFaxNumber;
      if (data.WebBackgroundColor) {
        if (data.WebBackgroundColor.length === 6) {
          data.WebBackgroundColor = `#${data.WebBackgroundColor}`;
        }
        // required state for environment header
        localStorage.setItem(ENV_COLOR_KEY, data.WebBackgroundColor);
        newState.environmentVars.environmentColor = data.WebBackgroundColor;
      }
      return newState;
    }

    case STATIC_DOCUMENTS_GET: {
      const docs = action.payload.data;
      newState.documents = {
        onlineLPOAGuide: docs.find(doc => doc.FormType === 'OnlineLpoaAuthorizationGuide').Location,
        programDescription: docs.find(doc => doc.FormType === 'ProgramDescription').Location,
        form400: docs.find(doc => doc.FormType === 'InternalTransfer-400').Location,
      };

      return newState;
    }

    case WEB_MESSAGES_GET: {
      const webMessageTypes = action.payload.data;
      // get only website message type messages
      const webMessageType = Boolean(webMessageTypes) && webMessageTypes.length > 0 && webMessageTypes.find(messageType => messageType.Name === 'WEBSITE MESSAGES');
      const webMessages = Boolean(webMessageType.WebsiteMessages) && webMessageType.WebsiteMessages.length > 0 && webMessageType.WebsiteMessages;
      // initialize all banners to empty strings each time api is called
      newState.webMessages = Object.values(BANNER_LOCATIONS).reduce((obj, bannerLocation) => ({ ...obj, [bannerLocation]: '' }), {});

      // distribute messages between banner locations (if exists will overwrite empty string)
      webMessages && webMessages.forEach(webMessage => {
        webMessage.WebsiteMessageURLs.forEach(websiteMessageURL => {
          newState.webMessages[`${websiteMessageURL.URL}/${websiteMessageURL.Page}`] = webMessage.Body;
        });
      });

      return newState;
    }

    case SET_LOADING_PUBLIC_VARS: {
      newState.loadingPublicVars = action.payload.isLoading;
      return newState;
    }

    default: {
      return state;
    }
  }

}

export default StaticResourcesReducer;
