/*
*
* StaticResources Constants
*
*/
export const PASSWORD_REQUIREMENTS_GET = 'src/components/AppRoot/StaticResources/PASSWORD_REQUIREMENTS_GET';
export const RELATIONSHIPS_GET = 'src/components/AppRoot/StaticResources/RELATIONSHIPS_GET';
export const OPTIONS_GET = 'src/components/AppRoot/StaticResources/OPTIONS_GET';
export const CUSTOM_OPTIONS_GET = 'src/components/AppRoot/StaticResources/CUSTOM_OPTIONS_GET';
export const POA_LEVELS_GET = 'src/components/AppRoot/StaticResources/POA_LEVELS_GET';
export const AGE_BRACKETS_GET = 'src/components/AppRoot/StaticResources/AGE_BRACKETS_GET';
export const ENVIRONMENT_VARIABLES_GET = 'src/components/AppRoot/StaticResources/ENVIRONMENT_VARIABLES_GET';
export const STATIC_DOCUMENTS_GET = 'src/components/AppRoot/StaticResources/STATIC_DOCUMENTS_GET';
export const SERVER_DATETIME_GET = 'src/components/AppRoot/StaticResources/SERVER_DATETIME_GET';
export const WEB_MESSAGES_GET = 'src/components/AppRoot/StaticResources/WEB_MESSAGES_GET';
export const SET_LOADING_PUBLIC_VARS = 'src/components/AppRoot/StaticResources/SET_LOADING_PUBLIC_VARS';
export const GET_NEW_AGE_BASED_SCHEMA = 'src/components/AppRoot/StaticResources//GET_NEW_AGE_BASED_SCHEMA';
export const GET_NEW_STATIC_SCHEMA = 'src/components/AppRoot/StaticResources//GET_NEW_STATIC_SCHEMA';

export const ENV_COLOR_KEY = 'fa_WebBackgroundColor';

// hardcoded banners - the value has to match exactly `${URL}/${Page}` from WebsiteMessage api 
// add/remove when needed
export const BANNER_LOCATIONS = {
  LOGIN: '/login/Login Page',
  HOME: '/Home Page',
  CONTRIBUTIONS_NEW: '/agents/{agentid}/groups/{accountgroupid}/accounts/{accountid}/contributions/new/Contribution (New)',
  CONTRIBUTIONS_EDIT: '/agents/{agentid}/groups/{accountgroupid}/accounts/{accountid}/contributions/{contributionId}/edit/Contribution (Edit)',
  OPTION_CHANGES_NEW: '/agents/{agentid}/groups/{accountgroupid}/accounts/{accountid}/option-changes/new/Option Change (New)',
  OPTION_CHANGES_EDIT: '/agents/{agentid}/groups/{accountgroupid}/accounts/{accountid}/option-changes/{optionid}/edit/Option Change (Edit)',
  OPTION_CHANGES_VIEW: '/agents/{agentid}/groups/{accountgroupid}/accounts/{accountid}/option-changes/{optionid}/view/Option Change (View)',
  BULK_OPTION_CHANGE: '/bulk-option-change/Bulk Option Change',
  TRANSFERS_NEW: '/agents/{agentid}/groups/{accountgroupid}/accounts/{accountid}/transfers/new/Transfer (New)',
  TRANSFERS_EDIT: '/agents/{agentid}/groups/{accountgroupid}/accounts/{accountid}/transfers/{transferId}/edit/Transfer (Edit)',
  WITHDRAWALS_NEW: '/agents/{agentid}/groups/{accountgroupid}/accounts/{accountid}/withdrawals/new/Withdrawal (New)',
  NEW_ACCOUNTS: '/new-accounts/New Accounts',
  NEW_ACCOUNTS_ADD: '/new-accounts/add/New Accounts Add',
  TEMPLATE_MSG: '/templates/Template Page',
  BOSP_MSG: '/manage-data/Manage Data (BOSP)',
  GIFTING_MSG: '/agents/{agentid}/groups/{accountgroupid}/accounts/{accountid}/gifting/Gifting Page',
};