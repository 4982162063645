/*
*
* BeneficiaryAgeBarChart Component
*
*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper } from '@mui/material';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Cell,
} from 'recharts';
import { LoadingOverlay, sizify } from '@frontend/common';
import { selectedBeneficiaryBarChartType } from '../constants';
import styles from './styles.module.css';

const frontendConfig = window.frontendConfig;
const location = window.location;

const select = (state) => ({
  selectedBeneficiaryBarChart: state.dashboardSettings.selectedBeneficiaryBarChart,
});

export class BeneficiaryAgeBarChart extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    size: PropTypes.shape({
      windowHeight: PropTypes.number
    }),
    activeIndex: PropTypes.number,
    selectedBeneficiaryBarChart: selectedBeneficiaryBarChartType,
    accountsByAge: PropTypes.array,
    accountsByAgeBand: PropTypes.array,
    onMouseEnterBar: PropTypes.func,
    onMouseLeaveBar: PropTypes.func,
    onMouseClickBar: PropTypes.func,
  };

  /**
   * Handles mouse movement on BarChart and sends index of item hovered to parent component for Metric Toolbar
   * @param data object passed from Recharts that indicates which item is hovered
   */
  onMouseMove = (data) => {
    if (data.activeTooltipIndex !== undefined) {
      this.props.onMouseEnterBar(data.activeTooltipIndex);
    }
  };

  render() {
    const nonProd = location.hostname.toLowerCase() !== 'fa.my529.org' && (!Object.prototype.hasOwnProperty.call(frontendConfig, 'envWarning') || frontendConfig.envWarning);
    const { selectedBeneficiaryBarChart, activeIndex, size, loading, onMouseLeaveBar, onMouseClickBar } = this.props;
    const chartHeight = size.windowHeight - 350 < 200 ? 200 : size.windowHeight - (nonProd ? 400 : 350);
    const margin = (size.windowHeight - (chartHeight + 350)) / 2;
    return (
      <div
        className={styles.BeneficiaryAgeBarChart_container}
        style={{
          margin: `${margin + 25 >= 0 ? margin + 25 : 0}px auto ${margin >= 0 ? margin : 0}px`
        }}
      >
        <Paper elevation={1}>
          {loading
            ? <LoadingOverlay show={loading} indicatorHeight='10px' width='100%' />
            : null
          }
          <ResponsiveContainer width='100%' height={chartHeight}>
            <BarChart
              data={this.props[selectedBeneficiaryBarChart]}
              margin={{
                top: 10,
                right: 10,
                bottom: 0,
                left: 0
              }}
              onClick={onMouseClickBar}
              onMouseMove={this.onMouseMove}
              onMouseLeave={onMouseLeaveBar}
            >
              <CartesianGrid vertical={false} />
              <XAxis dataKey='key' minTickGap={0} />
              <YAxis allowDecimals={false} />
              <Tooltip
                content={() => (<div />)}
              />
              <Bar dataKey='accounts'>
                {
                  this.props[selectedBeneficiaryBarChart].map((data, index) => (
                    <Cell
                      key={`cell-${index}`}
                      cursor='pointer'
                      fill={index === activeIndex ? 'var(--primary)' : 'var(--accent)'}
                    />
                  ))
                }
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Paper>
      </div>
    );
  }
}

export default connect(select, {})(sizify(BeneficiaryAgeBarChart));
