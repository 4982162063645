/*
*
* BackOfficeAuthorization Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import styles from './styles.module.css';


export function BackOfficeAuthorization({ firmName, address, providerName, date }) {
  return (
    <div className={styles.BackOfficeAuthorization_container}>
      <div className={styles.BackOfficeAuthorization_title}>Authorization to Disclose Information and Agreement to Indemnify</div>
      <hr />
      <p>The undersigned Advisor Firm, located at <span className={styles.BackOfficeAuthorization_emphasize}>{address}</span>, executes this Authorization to Disclose Information and Agreement to Indemnify (&quot;Authorization&quot;) in favor of my529 on the date set forth below.</p>
      <p>
        This Authorization is intended to provide a value-added service to Advisor Firm and the undersigned hereby expressly authorizes my529 to disclose information, including confidential information,
        relating to my529 accounts owned by its clients to back-office service provider, <span className={styles.BackOfficeAuthorization_emphasize}>{providerName}</span> (&quot;Servicer&quot;). Servicer, in turn, may use such information to enable you to access such
        client information as part of the services that the Servicer provides to you.
      </p>
      <p>
        The undersigned, as an entity that may be granted Limited Power of Attorney (LPOA) authority with my529 accounts, acknowledges and represents that it has obtained from its clients all necessary authorizations
        for the release of this information and has done so in a manner consistent with all applicable privacy laws and regulations. Further, the undersigned hereby agrees to indemnify and to hold my529 harmless from and
        against any and all claims arising in connection with this Authorization and my529&apos;s reliance thereon.
      </p>
      <br />
      <div>
        <div>Acknowledged and agreed to by:</div>
        <div className={styles.BackOfficeAuthorization_firmName}>{firmName}</div>
        <div className={styles.BackOfficeAuthorization_date}>{dayjs(date, 'YYYY-MM-DDTHH:mm').format('dddd, MMMM D, YYYY')}</div>
      </div>
    </div>
  );
}

BackOfficeAuthorization.propTypes = {
  firmName: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  providerName: PropTypes.string.isRequired,
  date: PropTypes.string,
};

BackOfficeAuthorization.defaultProps = {
  date: dayjs().toISOString(),
};

export default BackOfficeAuthorization;
