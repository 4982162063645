import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { saveTemplate } from 'components/Features/protected/Templates/actions';
import {
  customOptionsGet
} from 'components/AppRoot/StaticResources/actions';
import { allNotificationsHide, notificationShow } from '@frontend/common';
import TemplatesList from './TemplatesList';
import TemplateCopy from './TemplateCopy';
import TemplateNewCustom from './TemplateNewCustom';
import TemplateView from './TemplateView';
import PageNotFound from 'components/Features/protected/PageNotFound';

export class Templates extends React.Component {
  static propTypes = {
    allNotificationsHide: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    saveTemplate: PropTypes.func.isRequired,
    customOptionsGet: PropTypes.func.isRequired
  };

  state = {
    template: {},
    loadingOnSave: false,
    templateName: '',
    calculatorErrors: {
      templateNameNot1To40CharsError: '',
      hasAnyBracketTotalNot100Error: '',
      hasAllocationAmountError: '',
      hasBracketTotalSumOver100Error: ''
    },
    matrixAllocations: [],
  }

  onTemplateNameChange = (templateName, calculatorErrors) => {
    this.setState({ templateName, calculatorErrors });
  }

  onAllocationChange = (matrixAllocations, calculatorErrors) => {
    const { hasAllocationAmountError, hasBracketTotalSumOver100Error } = calculatorErrors;
    // throw a toast on specific form errors
    if (hasAllocationAmountError || hasBracketTotalSumOver100Error) {
      hasBracketTotalSumOver100Error && this.props.notificationShow(hasBracketTotalSumOver100Error, 'error');
      hasAllocationAmountError && this.props.notificationShow(hasAllocationAmountError, 'error');
    }
    else {
      this.props.allNotificationsHide();
    }

    this.setState({ matrixAllocations, calculatorErrors });
  }

  onSave = () => {
    // validate template name and allocations and save to api
    const {
      matrixAllocations,
      templateName,
      template,
      calculatorErrors: {
        hasAnyBracketTotalNot100Error,
        hasAllocationAmountError,
        templateNameNot1To40CharsError,
        hasBracketTotalSumOver100Error,
      }
    } = this.state;

    const {
      allNotificationsHide, notificationShow, history,
      saveTemplate, customOptionsGet
    } = this.props;

    if (!hasAnyBracketTotalNot100Error && !hasAllocationAmountError && !templateNameNot1To40CharsError && !hasBracketTotalSumOver100Error) {
      this.setState({ loadingOnSave: true });
      const updatedTemplate = { ...template, TemplateName: templateName.trim() };
      saveTemplate(matrixAllocations, updatedTemplate)
        .then(() => {
          allNotificationsHide();
          notificationShow('Template saved.', 'success');
          this.setState({
            loadingOnSave: false,
            templateName: ''
          }, () => history.push('/templates'));
        })
        .then(() => customOptionsGet()) // update custom templates
        .catch(() => this.setState({ loadingOnSave: false }));
    }
    else {
      // show error toasts
      hasAnyBracketTotalNot100Error && notificationShow(hasAnyBracketTotalNot100Error, 'error');
      hasAllocationAmountError && notificationShow(hasAllocationAmountError, 'error');
      templateNameNot1To40CharsError && notificationShow(templateNameNot1To40CharsError, 'error');
      hasBracketTotalSumOver100Error && notificationShow(hasBracketTotalSumOver100Error, 'error');
    }
  }

  render() {
    const additionalProps = {
      onAllocationChange: this.onAllocationChange,
      onTemplateNameChange: this.onTemplateNameChange,
      onMount: (template) => this.setState({ template }),
      onSave: this.onSave
    };

    return (
      <Switch>
        <Route exact path='/templates' component={TemplatesList} />
        <Route
          key='templatesNewAgeBased'
          path='/templates/new/age-based'
          render={() => (
            <TemplateNewCustom
              {...additionalProps}
              isStatic={false}
            />
          )}
        />
        <Route
          key='templatesNewStatic'
          path='/templates/new/static'
          render={() => (
            <TemplateNewCustom
              {...additionalProps}
              isStatic={true}
            />
          )}
        />
        <Route
          key='templateCopy'
          path='/templates/copy/:id'
          render={() => (
            <TemplateCopy
              {...additionalProps}
            />
          )}
        />
        <Route path='/templates/view/:id' component={TemplateView} />
        <Route component={PageNotFound} />
      </Switch>
    );
  }
}

export default withRouter(connect(null, {
  allNotificationsHide,
  notificationShow,
  saveTemplate,
  customOptionsGet
})(Templates));