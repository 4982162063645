/*
*
* NewAccountForm Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { find, isEqual } from 'lodash';
import { connect } from 'react-redux';
import dayjs from 'dayjs';

import {
  relationshipsGet,
  optionsGet,
  customOptionsGet,
  poaLevelsGet,
} from 'components/AppRoot/StaticResources/actions';
import { statuses } from '../constants';

import {
  TextField,
  Tabs,
  Tab,
} from '@mui/material';

import {
  Checkbox,
  Card,
  DatePicker,
  Dropdown,
  notificationShow,
  allNotificationsHide,

  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';
import StateDropdown from 'components/Features/protected/StateDropdown';

import events from 'utils/ga';

import styles from './styles.module.css';

const select = (state) => ({
  relationships: state.static.relationships,
  options: state.static.options,
  customStaticOptions: state.static.customStaticOptions,
  customAgeBasedOptions: state.static.customAgeBasedOptions,
  poaLevels: state.static.poaLevels,
});

const errorTabStyles = {
  backgroundColor: 'rgba(255, 0, 0, 0.25)',
  color: 'rgb(255, 0, 0)',
};


export class NewAccountForm extends React.Component {

  static propTypes = {
    account: PropTypes.object.isRequired,
    updateAccount: PropTypes.func.isRequired,
    disableInputs: PropTypes.bool,
    formErrors: PropTypes.array,
    relationships: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    customStaticOptions: PropTypes.array.isRequired,
    customAgeBasedOptions: PropTypes.array.isRequired,
    poaLevels: PropTypes.array.isRequired,
    tabErrorCounts: PropTypes.object,
    relationshipsGet: PropTypes.func.isRequired,
    optionsGet: PropTypes.func.isRequired,
    customOptionsGet: PropTypes.func.isRequired,
    poaLevelsGet: PropTypes.func.isRequired,
    toggleUseOwnerAddresses: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disableInputs: false,
    tabErrorCounts: {},
    formErrors: [],
  };

  state = {
    useOwnerAddresses: false,
    activeAOAddressTab: 0,
    activeBeneAddressTab: 0,
    activeSuccessorTab: 0,
  };

  addressInputsCompose(addressType) {
    let overrideProps = {};
    let type = addressType;
    if (this.state.useOwnerAddresses && addressType.toLowerCase().includes('beneficiary')) {
      overrideProps = { disabled: true };
      type = addressType.replace('Beneficiary', 'Owner');
    }

    const stateInputKey = `${type}State`;
    return [
      this.textFieldCompose(`${type}Address1`, 'Street Address 1', overrideProps),
      this.textFieldCompose(`${type}Address2`, 'Street Address 2', overrideProps),
      this.textFieldCompose(`${type}City`, 'City', overrideProps),
      <StateDropdown
        key={stateInputKey}
        value={this.props.account[stateInputKey] || ''}
        errorText={this.inputErrorTextGet(stateInputKey)}
        onChange={value => this.updateAccount({ target: { name: stateInputKey, value } })}
        FormControlProps={{ key: stateInputKey, disabled: this.props.disableInputs || overrideProps.disabled }}
        autoComplete={'off'}
        SelectProps={{ name: stateInputKey, inputProps: { name: stateInputKey } }}
      />,
      this.textFieldCompose(`${type}ZipCode`, 'ZIP Code', overrideProps),
    ];
  }

  datePickerCompose(key, label) {
    const error = this.inputErrorTextGet(key);
    return (
      <DatePicker
        label={label}
        name={key}
        key={key}
        value={this.props.account[key] || null}
        onChange={date => this.updateAccount({ target: { name: key, value: date && date.isValid() ? dayjs(date).format('L') : date } })}
        autoComplete={'off'}
        disabled={this.props.disableInputs}
        maxDate={dayjs()}
        error={Boolean(error)}
        helperText={error}
        disableFuture
        fullWidth={true}
      />
    );
  }

  dropdownCompose(key, label, menuItems) {
    return (
      <Dropdown
        label={label}
        errorText={this.inputErrorTextGet(key)}
        value={this.props.account[key] || ''}
        options={menuItems}
        onChange={value => this.updateAccount({ target: { name: key, value } })}
        FormControlProps={{ key, disabled: this.props.disableInputs }}
        SelectProps={{ name: key, inputProps: { name: key } }}
      />
    );
  }

  generalFormErrorsCompose(section) {
    const errors = [];
    this.props.formErrors.forEach(error => {
      if (error.input === `${section}General`) {
        errors.push(error.message);
      }
    });

    return (
      <div>
        {errors.map((error, index) => (<div className={styles.NewAccountForm_error} key={`${error}_${index}`}>{error}</div>))}
      </div>
    );
  }

  inputErrorTextGet(input) {
    const error = find(this.props.formErrors, { input });
    return error ? error.message : '';
  }

  menuOptionsCompose(items, valueKey, primaryTextKey) {
    return items.map(item => ({
      value: valueKey ? item[valueKey] : item,
      display: primaryTextKey ? item[primaryTextKey] : item,
    }));
  }

  optionAllocationsDataCompile() {
    const optionName = this.props.account.OptionName;
    const templateName = this.props.account.TemplateName;
    const beneficiaryAge = dayjs(this.props.account.BeneficiaryBirthdate).isValid() ? dayjs().diff(this.props.account.BeneficiaryBirthdate, 'year') : 0;

    let funds = [];
    const option = find(this.props.options, { name: optionName });

    if (option) {
      if (option.name === 'Customized Static' && templateName && this.props.customStaticOptions.length > 0) {
        const template = find(this.props.customStaticOptions, { name: templateName });
        if (template) {
          funds = template.funds.map(fund => ({
            id: fund.id,
            name: fund.name,
            ticker: fund.ticker,
            fees: fund.fee + (option.my529Fee * 100),
            percentage: fund.percentage * 100,
          }));
        }
      }
      else if (option.name === 'Customized Age-Based' && templateName && this.props.customAgeBasedOptions.length > 0) {
        let activeAgeBracket = {};
        const template = find(this.props.customAgeBasedOptions, { name: templateName });
        if (template) {
          template.ageBrackets.forEach(ageBracket => {
            if (beneficiaryAge >= ageBracket.minAge) {
              activeAgeBracket = ageBracket;
            }
          });
          funds = activeAgeBracket.funds.map(fund => ({
            id: fund.id,
            name: fund.name,
            ticker: fund.ticker,
            fees: fund.fee + (option.my529Fee * 100),
            percentage: fund.percentage * 100,
          }));
        }
      }
      else if (option.optionType === 'S' || option.optionType === 'T') {
        funds = option.funds.map(fund => ({
          id: fund.id,
          name: fund.name,
          ticker: fund.ticker,
          fees: fund.fee + (option.my529Fee * 100),
          percentage: fund.percentage * 100,
        }));
      }
    }

    return funds;
  }

  textFieldCompose(key, label, overrideProps) {
    const error = this.inputErrorTextGet(key);
    return (
      <TextField
        key={key}
        autoComplete={'off'}
        label={label}
        name={key}
        value={this.props.account[key] || ''}
        onChange={this.updateAccount}
        disabled={this.props.disableInputs}
        error={Boolean(error)}
        helperText={error}
        fullWidth
        {...overrideProps}
      />
    );
  }

  toggleCompose(key, label, overrideProps) {
    return (
      <div
        key={key}
        className={styles.NewAccountForm_toggle}
      >
        <Checkbox
          disabled={this.props.disableInputs}
          label={label}
          checked={this.props.account[key] || false}
          onChange={(e) => this.updateAccount({ target: { name: key, value: e.target.checked } })}
          {...overrideProps}
        />
        <div className={styles.NewAccountForm_error}>{this.inputErrorTextGet(key)}</div>
      </div>
    );
  }

  toggleUseOwnerAddresses = (useOwnerAddresses) => {
    this.setState({
      useOwnerAddresses
    }, () => {
      this.props.toggleUseOwnerAddresses(useOwnerAddresses);
    });
  }

  updateAccount = (e) => {
    this.props.updateAccount(e.target.name, e.target.value);
  }

  componentDidMount() {
    const apiCalls = [this.props.customOptionsGet()];

    if (this.props.relationships.length === 0) {
      apiCalls.push(this.props.relationshipsGet());
    }

    if (this.props.options.length === 0) {
      apiCalls.push(this.props.optionsGet());
    }

    if (this.props.poaLevels.length === 0) {
      apiCalls.push(this.props.poaLevelsGet());
    }

    Promise.all(apiCalls)
      .catch(() => null);
  }

  componentDidUpdate(prevProps) {
    const { account } = this.props;
    const { BeneficiaryIsResident, BeneficiaryMailingState, OwnerIsResident, OwnerMailingState, Status, } = account;

    if (
      isEqual(account, prevProps.account)
      && !isEqual(this.props.formErrors, prevProps.formErrors)
      && this.props.formErrors.length > 0
    ) {
      const tabErrorCounts = this.props.tabErrorCounts;
      this.setState({
        activeAOAddressTab: tabErrorCounts.aoMailing === 0 && tabErrorCounts.aoPhysical > 0 ? 1 : 0,
        activeBeneAddressTab: tabErrorCounts.beneMailing === 0 && tabErrorCounts.benePhysical > 0 ? 1 : 0,
        activeSuccessorTab: tabErrorCounts.successor === 0 && tabErrorCounts.ssuccessor > 0 ? 1 : 0,
      });
    }

    // display warning toast when residency checkbox doesn't match up with selected state
    if (
      (!Status || Status === statuses.LOADED) // only trigger when form is new or in "Loaded" status
      && (BeneficiaryIsResident !== prevProps.account.BeneficiaryIsResident
        || BeneficiaryMailingState !== prevProps.account.BeneficiaryMailingState
        || OwnerIsResident !== prevProps.account.OwnerIsResident
        || OwnerMailingState !== prevProps.account.OwnerMailingState)
    ) {
      const isBeneStateUtah = BeneficiaryMailingState === 'UT';
      const isOwnerStateUtah = OwnerMailingState === 'UT';
      let beneWarningTriggered = false;

      if (BeneficiaryMailingState
        && ((!BeneficiaryIsResident && isBeneStateUtah) || (BeneficiaryIsResident && !isBeneStateUtah))
        && !this.state.useOwnerAddresses) { // Don't show bene warning, if it is using the owner's address
        this.props.notificationShow('The selected state for the beneficary\'s address doesn\'t match their declared Utah residency.', 'warning');
        beneWarningTriggered = true;
      }
      else this.props.allNotificationsHide();
      if (OwnerMailingState
        && ((!OwnerIsResident && isOwnerStateUtah) || (OwnerIsResident && !isOwnerStateUtah))) {
        this.props.notificationShow('The selected state for the owner\'s address doesn\'t match their declared Utah residency.', 'warning');
      }
      else if (!beneWarningTriggered) this.props.allNotificationsHide();
    }
  }

  componentWillUnmount() {
    this.props.allNotificationsHide(); // mainly here to remove the residency warning toasts
  }

  render() {
    const { activeAOAddressTab, activeBeneAddressTab, activeSuccessorTab, useOwnerAddresses, } = this.state;
    const optionAllocationsData = this.optionAllocationsDataCompile();
    /* eslint-disable indent */
    return (
      <div className={styles.NewAccountForm_cards}>
        <Card
          title='Account Owner/Agent'
          className={styles.NewAccountForm_owner}
        >
          {this.generalFormErrorsCompose('AO')}
          <div className={styles.NewAccountForm_ownerForm}>
            <div className={styles.NewAccountForm_ownerPersonalDetails_group1}>
              {this.textFieldCompose('OwnerFirstName', 'First Name')}
              {this.textFieldCompose('OwnerMiddleName', 'Middle Name')}
              {this.textFieldCompose('OwnerLastName', 'Last Name')}
              {this.textFieldCompose('OwnerSSN', 'Social Security Number')}
              {this.datePickerCompose('OwnerBirthdate', 'Birthdate')}
            </div>
            <div className={styles.NewAccountForm_ownerPersonalDetails_group2}>
              {this.textFieldCompose('OwnerEmail', 'Email')}
              {this.textFieldCompose('OwnerHomePhone', 'Home Phone')}
              {this.textFieldCompose('OwnerWorkPhone', 'Work Phone')}
              {this.textFieldCompose('OwnerOtherPhone', 'Other Phone')}
            </div>
            <div className={styles.NewAccountForm_ownerAddresses}>
              {this.toggleCompose('OwnerIsResident', 'Utah Resident')}
              <Tabs
                onChange={(e, value) => this.setState({ activeAOAddressTab: value })}
                value={activeAOAddressTab}
                variant='fullWidth'
              >
                <Tab
                  label='Mailing'
                  value={0}
                  style={this.props.tabErrorCounts.aoMailing > 0 ? errorTabStyles : {}}
                />
                <Tab
                  label='Physical'
                  value={1}
                  style={this.props.tabErrorCounts.aoPhysical > 0 ? errorTabStyles : {}}
                />
              </Tabs>
              {activeAOAddressTab === 0 && this.addressInputsCompose('OwnerMailing')}
              {activeAOAddressTab === 1 && this.addressInputsCompose('OwnerPhysical')}
            </div>
          </div>
        </Card>
        <Card
          title='Beneficiary'
          className={styles.NewAccountForm_beneficiary}
        >
          {this.generalFormErrorsCompose('Bene')}
          <div className={styles.NewAccountForm_beneficiaryForm}>
            <div className={styles.NewAccountForm_beneficiaryPersonalDetails_group1}>
              {this.textFieldCompose('BeneficiaryFirstName', 'First Name')}
              {this.textFieldCompose('BeneficiaryMiddleName', 'Middle Name')}
              {this.textFieldCompose('BeneficiaryLastName', 'Last Name')}
            </div>
            <div className={styles.NewAccountForm_beneficiaryPersonalDetails_group2}>
              {this.textFieldCompose('BeneficiarySSN', 'Social Security Number')}
              {this.datePickerCompose('BeneficiaryBirthdate', 'Birthdate')}
              {this.dropdownCompose('Relationship', 'Relationship to Account Owner', this.menuOptionsCompose(this.props.relationships, 'key', 'value'))}
            </div>
            <div className={styles.NewAccountForm_beneficiaryAddresses}>
              {this.toggleCompose('BeneficiaryIsResident', 'Utah Resident', { disabled: useOwnerAddresses || this.props.disableInputs })}
              {this.toggleCompose('useOwnerAddresses', 'Use Account Owner Addresses', {
                checked: useOwnerAddresses,
                onChange: (e) => this.toggleUseOwnerAddresses(e.target.checked),
              })}
              <Tabs
                onChange={(e, value) => this.setState({ activeBeneAddressTab: value })}
                value={this.state.activeBeneAddressTab}
                variant='fullWidth'
              >
                <Tab
                  label='Mailing'
                  value={0}
                  style={this.props.tabErrorCounts.beneMailing > 0 ? errorTabStyles : {}}
                />
                <Tab
                  label='Physical'
                  value={1}
                  style={this.props.tabErrorCounts.benePhysical > 0 ? errorTabStyles : {}}
                />
              </Tabs>
              {activeBeneAddressTab === 0 && this.addressInputsCompose('BeneficiaryMailing')}
              {activeBeneAddressTab === 1 && this.addressInputsCompose('BeneficiaryPhysical')}
            </div>
          </div>
        </Card>
        <Card
          title='Successor Account Owners'
          className={styles.NewAccountForm_successors}
        >
          {this.generalFormErrorsCompose('Successor')}
          {this.generalFormErrorsCompose('SSuccessor')}
          <div className={styles.NewAccountForm_successorsForm}>
            <Tabs
              onChange={(e, value) => this.setState({ activeSuccessorTab: value })}
              value={this.state.activeSuccessorTab}
              variant='fullWidth'
            >
              <Tab
                label='Primary'
                value={0}
                style={this.props.tabErrorCounts.successor > 0 ? errorTabStyles : {}}
              />
              <Tab
                label='Secondary'
                value={1}
                style={this.props.tabErrorCounts.ssuccessor > 0 ? errorTabStyles : {}}
              />
            </Tabs>
            {activeSuccessorTab === 0 && [
              this.textFieldCompose('SuccessorFirstName', 'First Name'),
              this.textFieldCompose('SuccessorMiddleName', 'Middle Name'),
              this.textFieldCompose('SuccessorLastName', 'Last Name'),
              this.textFieldCompose('SuccessorSSN', 'Social Security Number'),
              this.datePickerCompose('SuccessorBirthdate', 'Birthdate'),
            ]}
            {activeSuccessorTab === 1 && [
              this.textFieldCompose('SSuccessorFirstName', 'First Name'),
              this.textFieldCompose('SSuccessorMiddleName', 'Middle Name'),
              this.textFieldCompose('SSuccessorLastName', 'Last Name'),
              this.textFieldCompose('SSuccessorSSN', 'Social Security Number'),
              this.datePickerCompose('SSuccessorBirthDate', 'Birthdate'),
            ]}
          </div>
        </Card>
        <Card
          title='Account Settings'
          className={styles.NewAccountForm_settings}
        >
          {this.generalFormErrorsCompose('POA')}
          <div className={styles.NewAccountForm_settingsForm}>
            {this.dropdownCompose('OptionName', 'Investment Option', this.menuOptionsCompose(this.props.options, 'name', 'name'))}
            {(this.props.account.OptionName && this.props.account.OptionName.includes('Customized')) &&
              this.dropdownCompose(
                'TemplateName',
                'Customized Template',
                this.menuOptionsCompose(this.props.account.OptionName === 'Customized Static' ? this.props.customStaticOptions : this.props.customAgeBasedOptions, 'name', 'name'))
            }
            {this.toggleCompose('CanViewFedTaxDocs', 'View Federal Tax Documents')}
            {this.toggleCompose('CanViewStateTaxDocs', 'View Utah State Tax Documents')}
            {this.dropdownCompose('PoaLevel', 'Access Level', this.menuOptionsCompose(this.props.poaLevels, 'key', 'displayName'))}
            {this.textFieldCompose('PromoCode', 'Promo Code')}
          </div>
        </Card>
        {this.props.account.OptionName && (
          <Card
            title='Option Allocations'
            className={styles.NewAccountForm_funds}
          >
            {this.props.account.OptionName.includes('Customized') && (!this.props.account.TemplateName || optionAllocationsData.length === 0)
              ? <div className={styles.NewAccountForm_fundsMessage}>
                Select a customized template under <strong>Account Settings</strong> to display option allocations.
              </div>
              : <div className={styles.NewAccountForm_fundsTable}>
                <SmartTable
                  idKey='id'
                  rows={optionAllocationsData}
                  columns={[
                    {
                      key: 'name',
                      title: 'Fund Name',
                      type: 'string',
                      customStyle: { width: '1.5fr' },
                    },
                    {
                      key: 'ticker',
                      title: 'Ticker',
                      type: 'string',
                      hideOn: ['phone'],
                    },
                    {
                      key: 'fees',
                      title: 'Fee',
                      type: 'number',
                      format: data => `${data.toFixed(3)}%`,
                      hideOn: ['phone'],
                    },
                    {
                      key: 'percentage',
                      title: 'Allocation',
                      type: 'number',
                      format: data => `${data.toFixed(2)}%`,
                    },
                  ]}
                >
                  <TableToolbar
                    onSearchFocus={() => events.tableSearchAccessed('Option Allocations', window.location.pathname)}
                  />
                  <TableContainer minWidth='100%'>
                    <TableHeader />
                    <TableRows />
                  </TableContainer>
                  <TablePagination />
                </SmartTable>
              </div>
            }
          </Card>
        )}
      </div>
    );
    /* eslint-enable indent */
  }
}

export default connect(select, {
  relationshipsGet,
  optionsGet,
  customOptionsGet,
  poaLevelsGet,
  notificationShow,
  allNotificationsHide,
})(NewAccountForm);
