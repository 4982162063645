import './constants.css';

const cssValue = (property) => {
  return getComputedStyle(document.documentElement).getPropertyValue(property);
};

export const colors = {
  primary: cssValue('--primary'),
  accent: cssValue('--accent'),
  warn: cssValue('--warn'),
  error: cssValue('--error'),
  text: cssValue('--text'),
  linkText: cssValue('--linkText'),
  darkPrimary: cssValue('--darkPrimary'),
  lightGray: cssValue('--lightGray'),
  lightHeaderText: cssValue('--lightHeaderText')
};
