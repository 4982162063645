/*
*
* TransferTermsAndConditions Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
  Icon,
} from '@mui/material';
import {
  currencyFormatter,
} from '@frontend/common';
import { TransferInputTypes, TransferTypes } from '../typeConstants';

import styles from './styles.module.css';

TransferTermsAndConditions.propTypes = {
  termsChecked: PropTypes.bool.isRequired,
  onTermsToggled: PropTypes.func.isRequired,
};

export function TransferTermsAndConditions({ termsChecked, onTermsToggled }) {
  return (
    <div className={styles.Transfer_termsAndConditions}>
      <h2 className={styles.Transfer_Header}>
        Terms and Conditions
      </h2>
      <h3 className={styles.Transfer_Header}>
        Please review the following Terms and Conditions regarding transfers
      </h3>
      <p>
        I have informed my client that a transfer between my529 accounts with the same account owner, beneficiary, and account type is considered an investment option change, which is only allowed twice each calendar year per beneficiary.
      </p>
      <p>
        I have informed my client if they want to change the investment option for those same accounts, they may do so without incurring a second option change as long as they make the change before the close of market trading on the New York Stock Exchange (NYSE), normally 2:00 p.m. MT, the same day as the transfer.
      </p>
      <p>
        I have informed my client that if they make a transfer request after the close of market trading, or on a weekend or holiday, any additional changes or cancellations must be received by 2:00 p.m. MT, the following business day. The transfer request may not be changed or canceled after that deadline without it counting as one of the two allowed option changes per calendar year.
      </p>
      <p>
        I have confirmed that the beneficiaries on the source and destination accounts involved in this transfer are family members, as defined by Section 529 of the Internal Revenue Code.
      </p>
      <p>
        I have informed my client that if the account owner/agent is a Utah taxpayer and that if they are transferring money from an account held by a beneficiary who was younger than age 19 at the time the beneficiary was designated on the account to an account where the beneficiary was age 19 or older when designated on the account:
      </p>
      <ul>
        <li>
          my529 will report this transfer on Utah state tax form TC-675H and to the Utah State Tax Commission.
        </li>
        <li>
          Any Utah state income tax credit or deduction claimed by the account owner/agent in a prior tax year must be added back to income in the year of the transfer.
        </li>
      </ul>
      <div className={styles.Transfer_termsCheckbox}>
        <FormControlLabel
          control={
            <Checkbox
              checked={termsChecked}
              onChange={onTermsToggled}
            />
          }
          label='I have read and understand these Terms and Conditions.'
        />
      </div>
    </div>
  );
}

TermsAndConditionsReview.propTypes = {
  transfer: PropTypes.object.isRequired,
  destinationAccounts: PropTypes.array.isRequired,
  sourceAccount: PropTypes.object.isRequired,
};

export function TermsAndConditionsReview({ transfer, destinationAccounts, sourceAccount }) {
  const compactedTargets = transfer.TargetAccounts.filter(account => account.AccountId !== '' &&
    ((transfer.transferType === TransferTypes.partial && account[transfer.transferInputType] !== 0) || (transfer.transferType === TransferTypes.full && account.Percentage > 0))
  ).map(target => ({
    ...destinationAccounts.find(account => account.AccountId === target.AccountId),
    ...target,
  }));
  const pluralize = compactedTargets.length > 1 ? 's' : '';
  const optionChanges = compactedTargets.some(target => target.accountGroupId === sourceAccount.accountGroupId);
  return (
    <div className={optionChanges ? styles.Transfer_reviewContainer : styles.Transfer_reviewContainerCompact} key='review'>
      {transfer.transferType === TransferTypes.full &&
      <div className={styles.Transfer_reviewCloseAccount}>
        <div>Source Account Status:</div>
        <div>{transfer.CloseAccount ? 'Will Be Closed' : 'Will Remain Open'}</div>
      </div>
      }
      <div className={styles.Transfer_reviewAccountNumber}>
        <div className={`${styles.Transfer_detailsTitle} ${styles.desktop}`}>
          Account Number
        </div>
        <div className={`${styles.Transfer_detailsTitle} ${styles.mobile}`}>
          Account #
        </div>
        {compactedTargets.map(target => (
          <div className={styles.Transfer_detail} key={target.AccountId}>
            {target.details.accountNumber}
          </div>
        ))}
      </div>
      <div className={styles.Transfer_reviewBeneficiaryName}>
        <div className={styles.Transfer_detailsTitle}>
          Beneficiary Name{pluralize}
        </div>
        {compactedTargets.map(target => (
          <div className={styles.Transfer_detail} key={target.AccountId}>
            {target.beneficiary.name}
          </div>
        ))}
      </div>
      {optionChanges &&
        <div className={styles.Transfer_reviewOptionChange}>
          <div className={`${styles.Transfer_detailsTitle} ${styles.desktop}`}>
            Option Change
          </div>
          <div className={`${styles.Transfer_detailsTitle} ${styles.mobile}`}>
            Opt. Change
          </div>
          {compactedTargets.map(target => (
            <div className={styles.Transfer_detail} key={target.AccountId}>
              <Icon
                style={{
                  color: target.accountGroupId !== sourceAccount.accountGroupId && 'rgba(0,0,0,0)',
                  fontSize: '16px',
                }}
                title={target.accountGroupId === sourceAccount.accountGroupId ? 'Yes' : 'No'}
              >
                {target.accountGroupId === sourceAccount.accountGroupId ? 'check' : 'close'}
              </Icon>
            </div>
          ))}
        </div>
      }
      <div className={styles.Transfer_reviewAmount}>
        <div className={styles.Transfer_detailsTitle}>
          {transfer.transferInputType}{pluralize}
        </div>
        {compactedTargets.map(target => (
          <div className={styles.Transfer_detail} key={target.AccountId}>
            {transfer.transferInputType === TransferInputTypes.Amount ? currencyFormatter(target.Amount) : `${target.Percentage}%`}
          </div>
        ))}
      </div>
      <div className={styles.Transfer_reviewTotalAmount}>
        <div>Total:</div>
        <div>{
          transfer.transferInputType === TransferInputTypes.Amount ?
            currencyFormatter(compactedTargets.reduce((sum, target) => sum + target.Amount, 0)) :
            `${compactedTargets.reduce((sum, target) => sum + target.Percentage, 0)}%`
        }</div>
      </div>
    </div>
  );
}

export default TransferTermsAndConditions;
