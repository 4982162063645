/* 
*
* AppRoot
*
*/
import React, { Component } from 'react';

import { ThemeProvider, createTheme, StyledEngineProvider, } from '@mui/material/styles';
import material_ui_theme from 'utils/config/material_ui_theme';

import { Notifications } from '@frontend/common';
import Navigation from './Navigation';

import './styles.css';

export default class AppRoot extends Component {

  render() {
    return (
      <ThemeProvider theme={createTheme(material_ui_theme)}>
        <StyledEngineProvider injectFirst>
          <Navigation />
          <Notifications />
        </StyledEngineProvider>
      </ThemeProvider>
    );
  }

}
