import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import qs from 'qs';

import { allNotificationsHide } from '@frontend/common';

const select = (state) => ({
  isValid: state.session.isValid
});

export class PublicRoute extends React.Component {

  static propTypes = {
    isValid: PropTypes.bool.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  };

  componentDidMount() {
    window.onload = () => {
      this.props.allNotificationsHide();
    };
  }

  render() {
    const { component: Component, ...rest } = this.props; // eslint-disable-line react/prop-types
    return (
      <Route {...rest} render={props => {
        const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
        return this.props.isValid ? (
          <Redirect
            push
            to={query.referrer || '/'}
          />
        ) : (
          <Component {...props} />
        );
      }}
      />
    );
  }
}

export default connect(select, { allNotificationsHide })(PublicRoute);
