module.exports = [
  {
    id: 'HigherEd',
    messages: [
      "All withdrawn money will be used to pay for the beneficiary's qualified higher education expenses." // eslint-disable-line quotes
    ]
  },
  {
    id: 'K-12Tuition',
    messages: [
      "All withdrawn money will be used to pay for the beneficiary’s qualified K-12 tuition expenses, up to $10,000 per year." // eslint-disable-line quotes
    ]
  },
  {
    id: 'NonQualified',
    messages: [
      'The money will not be used to pay for qualified higher education expenses or K-12 tuition expenses.'
    ]
  },
  {
    id: 'P',
    messages: [
      'The account owner/agent will receive IRS Form 1099-Q. If the account owner is also a Utah taxpayer/resident, they will receive Form TC-675H.',
      'my529 will send the tax forms in January of the following year.'
    ]
  },
  {
    id: 'B',
    messages: [
      'The beneficiary will receive IRS Form 1099-Q. If the account owner is a Utah taxpayer/resident, they will receive Form TC-675H.',
      'my529 will send the tax forms in January of the following year.'
    ]
  },
  {
    id: 'I',
    messages: [
      "my529 will make the withdrawal check payable to the eligible educational institution and send it directly to the school. Please allow up to 2-3 weeks for processing and delivery. The check stub will include the beneficiary’s name and student ID (if provided by the account owner). The beneficiary will receive IRS Form 1099-Q. If the account owner is a Utah taxpayer/resident, they will receive Form TC-675H.", // eslint-disable-line quotes
      'my529 will send the tax forms in January of the following year.'
    ]
  }
];