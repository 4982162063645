/*
*
* TemplateNewCustom
*
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  getServerDateTime,
  getNewStaticSchema,
  getNewAgeBasedSchema,
} from 'components/AppRoot/StaticResources/actions';

import { Breadcrumbs, CalculatorForm } from '@frontend/common';

const select = (state) => ({
  newStaticSchema: state.static.newStaticSchema,
  newAgeBasedSchema: state.static.newAgeBasedSchema,
  serverDateTime: state.static.serverDateTime,
});

export class TemplateNewCustom extends Component {
  static propTypes = {
    getNewStaticSchema: PropTypes.func.isRequired,
    getNewAgeBasedSchema: PropTypes.func.isRequired,
    newStaticSchema: PropTypes.object.isRequired,
    newAgeBasedSchema: PropTypes.object.isRequired,
    getServerDateTime: PropTypes.func.isRequired,
    serverDateTime: PropTypes.string,
    isStatic: PropTypes.bool.isRequired,
    onAllocationChange: PropTypes.func.isRequired,
    onTemplateNameChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onMount: PropTypes.func.isRequired,
  };

  selectCurrSchema = (isStatic, staticSchema, ageBasedSchema) => isStatic ? staticSchema : ageBasedSchema;

  state = {
    currSchema: this.selectCurrSchema(this.props.isStatic, this.props.newStaticSchema, this.props.newAgeBasedSchema),
    loadingOnMount: false,
    templateName: '',
  }

  componentDidMount() {
    const {
      isStatic, getNewStaticSchema, newStaticSchema,
      getNewAgeBasedSchema, newAgeBasedSchema,
      getServerDateTime, onMount
    } = this.props;

    const promises = [];
    const getSchemaApi = isStatic ? getNewStaticSchema : getNewAgeBasedSchema;
    let currSchema = this.selectCurrSchema(isStatic, newStaticSchema, newAgeBasedSchema);

    if (currSchema.FundCategories.length === 0) {
      promises.push(
        getSchemaApi()
          .then(() => {
            currSchema = this.selectCurrSchema(isStatic, this.props.newStaticSchema, this.props.newAgeBasedSchema);
            this.setState({ currSchema });
          }));
    }

    // get current date (calculator needs for the agreement year) - probably better to get always a fresh one
    promises.push(getServerDateTime());
    this.setState({ loadingOnMount: true });
    Promise.all(promises)
      .then(() => {
        // pass to parent
        onMount(currSchema);
      })
      .finally(() => {
        this.setState({ loadingOnMount: false });
      });
  }

  render() {
    const {
      serverDateTime, isStatic,
      onAllocationChange, onTemplateNameChange, onSave
    } = this.props;
    const { loadingOnMount, currSchema } = this.state;
    const newCustomTemplateTitle = isStatic ? 'New Customized Static' : 'New Customized Age-Based';
    return (
      <div>
        <Breadcrumbs
          crumbs={[
            {
              title: 'Templates',
              link: '/templates'
            },
            {
              title: newCustomTemplateTitle
            },
          ]}
        />
        <CalculatorForm
          loading={loadingOnMount}
          stickyHeaderTopOffset={60}
          schema={currSchema}
          currentDate={serverDateTime}
          isTemplateNameEditing={true}
          onAllocationChange={onAllocationChange}
          onTemplateNameChange={onTemplateNameChange}
          onSave={onSave}
        />
      </div>
    );
  }
}

export default withRouter(connect(select, {
  getNewStaticSchema,
  getNewAgeBasedSchema,
  getServerDateTime
})(TemplateNewCustom));
