import dayjs from 'dayjs';

export const errors = {
  required: 'Field is required.',
  phone_number_too_short: 'Phone number needs to be 10 digits.',
  alphabetic_and_allowable_characters_only(allowableChars) {
    return `Allowable characters: alphabetic and ${allowableChars}`;
  },
  alpha_numeric_only: 'Please use only alphanumeric characters.',
  alpha_numeric_and_allowable_characters_only(allowableChars) {
    return `Allowable characters: alphanumeric and ${allowableChars}`;
  },
};

const date_limits = (minDate, maxDate) => {
  return `Date must be between ${minDate} and ${maxDate}.`;
};

export function calendarValidator(value, minDate, maxDate) {
  if (!value) {
    return errors.required;
  }

  value = dayjs(value); // converts date to momentjs just in case it doesn't come in that way
  if (!dayjs(value).isValid()) {
    return 'Select a valid date.';
  }
  else if (minDate && maxDate && (dayjs(value).isAfter(dayjs(maxDate), 'day') || dayjs(value).isBefore(dayjs(minDate), 'day'))) {
    return date_limits(dayjs(minDate).format('MM/DD/YYYY'), dayjs(maxDate).format('MM/DD/YYYY'));
  }
  return '';
}

export function numericOnlyValidator(str) {
  const nonNumericCharsRegex = /[^0-9]/g;

  if (nonNumericCharsRegex.test(str.toString())) {
    return 'Please use only numeric characters.';
  }
  return '';
}

export function alphaNumericOnlyValidator(str) {
  const nonAlphanumericCharsRegex = /[^0-9a-zA-Z ]/g;

  if (nonAlphanumericCharsRegex.test(str)) {
    return errors.alpha_numeric_only;
  }
  return '';
}

export function pinValidator(pin) {
  if (!pin) {
    return 'PIN is required.';
  }
  return '';
}

export function tokenValidator(token) {
  if (!token) {
    return 'Security code is required.';
  }
  return '';
}

export function phoneHasNo10Digits(phone) {
  if (phone.match(/\d/g).length !== 10) {
    return errors.phone_number_too_short;
  }
  return '';
}

export function emailValidator(email) {
  const emailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/; // eslint-disable-line no-useless-escape
  if (email.match(/\s+/)) {
    return 'This field cannot contain spaces'; // no spaces anywhere
  }
  if (!emailRegex.test(email)) {
    return 'Email address is not valid.';
  }
  return '';
}

export function street1Validator(value) {
  const street1Regex = /^[A-Za-z0-9 .,':#/-]+$/g; // eslint-disable-line no-useless-escape
  if (!value) {
    return errors.required;
  }
  if (!street1Regex.test(value)) {
    return errors.alpha_numeric_and_allowable_characters_only(". , - ' : # /"); // eslint-disable-line
  }
  if (value.length > 40) {
    return errors.field_max_length_40;
  }
  return '';
}

export function cityValidator(value) {
  const cityRegex = /^[A-Za-z .,'-]+$/g; // eslint-disable-line no-useless-escape
  if (!value) {
    return errors.required;
  }
  if (!cityRegex.test(value)) {
    return errors.alphabetic_and_allowable_characters_only(" . , - '"); // eslint-disable-line
  }
  return '';
}


