import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot, } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { configureStore, } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import promise from 'redux-promise';
import { get, set, clear } from 'idb-keyval';

import { apiResponse } from 'utils/helpers/middleware';

import throttle from 'lodash/throttle';

import rootReducer from './reducers';
import AppRoot from 'components/AppRoot/index';

if (process.env.NODE_ENV !== 'test') {
  require('./utils/ga/analytics.js');
}

export let store = {};

get('fa_idb') // retrieve redux store from IndexedDB
  .then(val => {
    
    let preloadedState = undefined;
    const token = sessionStorage.getItem('token');
    if (val && token !== null) {
      preloadedState = JSON.parse(val);
    }
    else {
      clear();
    }

    // check if in dev environment
    const URL = window.location.host;
    const isDevEnv = Boolean(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) // eslint-disable-line no-underscore-dangle
      && (URL.includes('localhost') || URL.includes('dev') || URL.includes('qa'));

    // create store
    store = configureStore({
      reducer: rootReducer,
      preloadedState,
      middleware: [promise, apiResponse],
      devTools: isDevEnv, // makes sure devTools are turn off in prod
    });

    store.subscribe(throttle(() => {
      const serializedState = JSON.stringify(store.getState());
      set('fa_idb', serializedState); // save redux store to IndexedDB
    }), 1000);
     
    const container = document.getElementById('root');
    const root = createRoot(container);

    if (process.env.NODE_ENV !== 'test') {
      root.render(
        <Provider store={store}>
          <BrowserRouter>
            <AppRoot />
          </BrowserRouter>
        </Provider>
      );
    }

  });