/*
*
* AccountDetails Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find } from 'lodash';
import bowser from 'bowser';

import {
  getAccountDetails,
  getAccountsByAgent,
  getUpcomingTransactions,
  cancelTransaction,
  getScheduledTransaction,
} from 'components/Features/protected/Accounts/actions';
import { getAccountBaseRoute } from 'components/Features/protected/Accounts/helpers';
import { optionsGet } from 'components/AppRoot/StaticResources/actions';

import {
  LoadingOverlay,
  sizify,
  FloatingActionButton,
  Breadcrumbs,
  Card,
  notificationShow,
  ConfirmModal,
  currencyFormatter,
  StyledLink,
  ContributionIcon,
  WithdrawalIcon,
  OptionChangeIcon,
  IconBtnTooltip,

  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';
import TransactionDetailsModal from '../TransactionDetailsModal';
import PrrCalculator from './PrrCalculator';

import {
  Tabs,
  Tab,
  Icon,
} from '@mui/material';

import events from 'utils/ga';
import { colors } from 'utils/config/constants';

import styles from './styles.module.css';

const select = (state) => ({
  accountDetails: state.accounts.selectedAccount,
  agentAccounts: state.accounts.selectedAgentAccounts,
  upcomingTransactions: state.accounts.upcomingTransactionsByAccount,
  predefinedOptions: state.static.options,
});


export class AccountDetails extends React.Component {

  static propTypes = {
    size: PropTypes.object.isRequired,
    accountActionHandle: PropTypes.func.isRequired,
    accountDetails: PropTypes.object.isRequired,
    getAccountDetails: PropTypes.func.isRequired,
    optionsGet: PropTypes.func.isRequired,
    agentAccounts: PropTypes.array.isRequired,
    getAccountsByAgent: PropTypes.func.isRequired,
    getUpcomingTransactions: PropTypes.func.isRequired,
    upcomingTransactions: PropTypes.object,
    cancelTransaction: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    getScheduledTransaction: PropTypes.func.isRequired,
    predefinedOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        isStaticOrEnrollment: PropTypes.bool.isRequired,
      })
    ).isRequired,
  };

  state = {
    loading: false,
    initialLoading: false,
    showTransactionDetailsModal: false,
    selectedTransactionId: 0,
    cachedAccounts: [],
    focusedAccount: {
      beneficiary: {
        phoneNumbers: [],
      },
      accountOwner: {
        phoneNumbers: [],
      },
      successors: {},
      details: {},
      funds: [],
      transactions: [],
      permissions: {},
      ytdPRR: ''
    },
    prrCalculatorShow: false,
    prrButtonDisabled: false,
    /* State used for canceling a transaction */
    showCancelConfirmationModal: false,
    cancelTransactionLoading: false,
    selectedTransaction: {},
    reloadingUpcomingTransactionsTable: false,

  };

  accountActionsCompile = () => {
    const actions = [];
    const { permissions } = this.state.focusedAccount;
    const { accountId, groupId, agentId } = this.props.match.params;

    if (permissions.optionChanges) {
      actions.push({
        title: 'Option Changes',
        icon: <OptionChangeIcon color={colors.accent} />,
        action: () => this.props.accountActionHandle('optionChanges', accountId, groupId, agentId),
      });
    }
    if (permissions.contributions) {
      actions.push({
        title: 'Contributions',
        icon: <ContributionIcon color={colors.accent} />,
        action: () => this.props.accountActionHandle('contributions', accountId, groupId, agentId),
      });
    }
    if (permissions.transfers) {
      actions.push({
        title: 'Transfers',
        icon: 'swap_horiz',
        action: () => this.props.accountActionHandle('transfers', accountId, groupId, agentId),
      });
    }
    if (permissions.withdrawals) {
      actions.push({
        title: 'Withdrawals',
        icon: <WithdrawalIcon color={colors.accent} width='30px' />,
        action: () => this.props.accountActionHandle('withdrawals', accountId, groupId, agentId),
      });
    }

    if (permissions.contributions || permissions.optionChanges || permissions.transfers) {
      actions.push({
        title: 'Gifting',
        icon: 'card_giftcard',
        action: () => this.props.accountActionHandle('gifting', accountId, groupId, agentId),
      });
    }

    actions.push({
      title: 'Documents',
      icon: 'description',
      action: () => this.props.accountActionHandle('documents', accountId, groupId, agentId),
    });

    return actions;
  };

  accountSelect = (accountId) => {
    const { agentId } = this.props.match.params;
    const account = find(this.props.agentAccounts, { accountId });
    this.props.history.push(getAccountBaseRoute({ agentId, groupId: account.groupId, accountId }));
  };

  fundsCompose = () => {
    const fundTableData = this.state.focusedAccount.funds;

    if (this.props.size.windowWidth < 600) {
      return fundTableData.map(fund => {
        return (
          <div key={fund.id} className={styles.AccountDetails_fund}>
            <div className={styles.AccountDetails_fundName}>{fund.name}</div>
            <div className={styles.AccountDetails_fundDetails}>
              <span>Target Allocation:</span>
              <span>{`${(fund.targetAllocation * 100).toFixed(2)}%`}</span>
            </div>
            <div className={styles.AccountDetails_fundDetails}>
              <span>Actual Allocation:</span>
              <span>{`${(fund.actualAllocation * 100).toFixed(2)}%`}</span>
            </div>
            <div className={styles.AccountDetails_fundDetails}>
              <span>Current Value:</span>
              <span>{currencyFormatter(fund.value)}</span>
            </div>
          </div>
        );
      });
    }
    else {
      return (
        <div className={styles.AccountDetails_tableContainer}>
          <SmartTable
            idKey='id'
            rows={fundTableData}
            loading={this.state.loading}
            columns={[
              {
                key: 'name',
                title: 'Fund Name',
                type: 'string',
                customStyle: { width: '1.75fr' }
              },
              {
                key: 'targetAllocation',
                title: 'Target Allocation',
                type: 'number',
                format: data => `${(data * 100).toFixed(2)}%`,
              },
              {
                key: 'actualAllocation',
                title: 'Actual Allocation',
                type: 'number',
                format: data => `${(data * 100).toFixed(2)}%`,
              },
              {
                key: 'units',
                title: 'Units',
                type: 'string',
                hideOn: ['phone', 'tablet'],
              },
              {
                key: 'unitPrice',
                title: 'NAV',
                type: 'string',
                hideOn: ['phone', 'tablet'],
              },
              {
                key: 'value',
                title: 'Current Value',
                type: 'currency',
                hideOn: ['phone', 'tablet'],
              },
            ]}
          >
            <TableToolbar
              onSearchFocus={() => events.tableSearchAccessed('Account Details (Current Allocation)', window.location.pathname)}
            />
            <TableContainer minWidth='100%'>
              <TableHeader />
              <TableRows />
            </TableContainer>
            <TablePagination />
          </SmartTable>
        </div>
      );
    }
  };

  phoneNumbersCompose = (phones) => {
    if (phones.length > 0) {
      return phones.map(phone => {
        return (
          <div key={phone.PhoneNumberId}>
            {`${phone.Type}: ${phone.Number}`}
          </div>
        );
      });
    }
    else {
      return null;
    }
  };

  transactionDetailsShow = (selectedTransactionId) => {
    this.setState({
      showTransactionDetailsModal: true,
      selectedTransactionId,
    });
  };

  upcomingTransactionEditHandler = (transaction) => {
    let redirectRoutePrefix = getAccountBaseRoute({ ...this.props.match.params, groupId: transaction.accountGroupId, accountId: transaction.accountId });
    this.props.getScheduledTransaction(transaction).then(action => {
      let type;
      switch (transaction.typeName) {
        case 'Option Change': {
          type = 'option-changes';
          break;
        }
        case 'Transfer': {
          const responseObj = action.payload.data;
          redirectRoutePrefix = getAccountBaseRoute({ ...this.props.match.params, accountGroupId: responseObj.AccountGroupId, accountId: responseObj.AccountId });
          type = 'transfers';
          break;
        }
        case 'Withdrawal': {
          type = 'withdrawals';
          break;
        }
        case 'Contribution': {
          type = 'contributions';
          break;
        }
        default:
          break;
      }
      this.props.history.push(`${redirectRoutePrefix}/${type}/${transaction.id}/edit`);
    });
  };

  upcomingTransactionCancelHandler = (transaction) => {
    this.setState({ cancelTransactionLoading: true });
    this.props.cancelTransaction(transaction).then(() => {
      this.props.notificationShow(`${transaction.typeName} cancelled.`, 'success');
    })
      .catch(() => null)
      .finally(() => {
        this.setState({
          cancelTransactionLoading: false,
          showCancelConfirmationModal: false,
          selectedTransaction: {},
          reloadingUpcomingTransactionsTable: true,
        });
        this.props.getUpcomingTransactions(this.props.match.params.accountId)
          .then(() => {
            this.setState({ reloadingUpcomingTransactionsTable: false });
          })
          .catch(() => null);
      });
  };

  upcomingTransactionViewHandler = (transaction) => {
    const redirectRoutePrefix = getAccountBaseRoute({ ...this.props.match.params, groupId: transaction.accountGroupId, accountId: transaction.accountId });
    this.props.getScheduledTransaction(transaction).then(() => {
      let type;
      switch (transaction.typeName) {
        case 'Option Change': {
          type = 'option-changes';
          break;
        }
        default:
          break;
      }
      this.props.history.push(`${redirectRoutePrefix}/${type}/${transaction.id}/view`);
    });
  };

  componentDidMount() {
    this.setState({ initialLoading: true });
    const { agentId, accountId } = this.props.match.params;

    Promise.all([
      this.props.getAccountsByAgent(agentId),
      this.props.getAccountDetails(accountId),
      this.props.getUpcomingTransactions(accountId),
      this.props.predefinedOptions.length === 0 && this.props.optionsGet(),
    ])
      .then(() => {
        this.setState({
          initialLoading: false,
          focusedAccount: this.props.accountDetails,
          cachedAccounts: [this.props.accountDetails],
        });
      })
      .catch(() => null);
  }

  componentDidUpdate(prevProps) {
    const { accountId } = this.props.match.params;
    if (accountId !== prevProps.match.params.accountId) {
      const cachedAccount = find(this.state.cachedAccounts, { accountId: parseInt(accountId) });
      if (cachedAccount) {
        this.setState({ reloadingUpcomingTransactionsTable: true });
        this.props.getUpcomingTransactions(accountId)
          .then(() => {
            this.setState({ reloadingUpcomingTransactionsTable: false });
          })
          .catch(() => null);
        this.setState({
          focusedAccount: cachedAccount,
        });
      }
      else {
        this.setState({ loading: true, reloadingUpcomingTransactionsTable: true });
        Promise.all([
          this.props.getAccountDetails(accountId),
          this.props.getUpcomingTransactions(accountId),
        ])
          .then(() => {
            this.setState({
              loading: false,
              reloadingUpcomingTransactionsTable: false,
              focusedAccount: this.props.accountDetails,
              cachedAccounts: this.state.cachedAccounts.concat([this.props.accountDetails]),
            });
          })
          .catch(() => null);
      }
    }
  }

  render() {
    const { initialLoading, focusedAccount, loading, selectedTransaction, prrCalculatorShow, prrButtonDisabled } = this.state;
    const upcomingTransactions = this.props.upcomingTransactions[focusedAccount.accountId] || [];
    const { agentId, groupId, } = this.props.match.params;
    const accountId = parseInt(this.props.match.params.accountId);
    const isCustomOption = focusedAccount.details.isCustom;

    if (initialLoading) {
      return (
        <div className={styles.AccountDetails_page}>
          <div className={`${styles.AccountDetails_pageNav} hideOnPrint`}>
            <Breadcrumbs
              crumbs={[
                {
                  title: 'Accounts',
                  link: '/accounts'
                },
                { title: 'Details' }
              ]}
            />
            <LoadingOverlay
              show={initialLoading}
              indicatorHeight='15px'
              width='100%'
            />
          </div>
        </div>
      );
    }
    else {
      /* eslint-disable indent, no-nested-ternary */
      return (
        <div className={styles.AccountDetails_page}>
          <div className={styles.AccountDetails_pageNav}>
            <Breadcrumbs
              crumbs={[
                {
                  title: 'Accounts',
                  link: '/accounts'
                },
                { title: 'Details' }
              ]}
            />

          </div>
          <div className={styles.AccountDetails_ownerContainer}>
            <Card
              title='Account Owner/Agent'
            >
              <div className={styles.AccountDetails_accountOwnerName}>{focusedAccount.accountOwner.name}</div>
              <div>{focusedAccount.accountOwner.streetAddress1}</div>
              <div>{focusedAccount.accountOwner.streetAddress2}</div>
              <div>{focusedAccount.accountOwner.streetAddress3}</div>
              <div>{focusedAccount.accountOwner.city && `${focusedAccount.accountOwner.city}, ${focusedAccount.accountOwner.state} ${focusedAccount.accountOwner.zip}`}</div>
              <div>{focusedAccount.accountOwner.country !== 'United States' && focusedAccount.accountOwner.country}</div>
              {this.phoneNumbersCompose(focusedAccount.accountOwner.phoneNumbers)}
            </Card>
          </div>
          <div className={styles.AccountDetails_accountSelector}>
            <div>
              <Tabs
                value={accountId}
                onChange={(e, value) => this.accountSelect(value)}
                variant='scrollable'
              >
                {this.props.agentAccounts.map(account => (
                  <Tab
                    key={account.accountId}
                    label={
                      <div className={`${styles.AccountDetails_accountSelectorTab} ${accountId === account.accountId ? styles.selected : ''}`}>
                        <div>{account.beneficiaryName}</div>
                        <div>{`(...${account.accountNumber.substring(4)})`}</div>
                      </div>
                    }
                    value={account.accountId}
                  />
                ))}
              </Tabs>
            </div>
          </div>
          {loading
            ? <div className={styles.AccountDetails_loading}>
              <LoadingOverlay show={loading} indicatorHeight='10px' width='100%' />
            </div>
            : <div className={styles.AccountDetails_accountContainer}>
              <Card
                title='Beneficiary Contact'
                className={styles.AccountDetails_beneficiaryCard}
              >
                <div>{focusedAccount.beneficiary.streetAddress1}</div>
                <div>{focusedAccount.beneficiary.streetAddress2}</div>
                <div>{focusedAccount.beneficiary.streetAddress3}</div>
                <div>{focusedAccount.beneficiary.city && `${focusedAccount.beneficiary.city}, ${focusedAccount.beneficiary.state} ${focusedAccount.beneficiary.zip}`}</div>
                <div>{focusedAccount.beneficiary.country !== 'United States' && focusedAccount.beneficiary.country}</div>
                {this.phoneNumbersCompose(focusedAccount.beneficiary.phoneNumbers)}
              </Card>
              <Card
                title='Successors'
                className={styles.AccountDetails_successorsCard}
              >
                <div className={styles.AccountDetails_detailTitle}>Primary</div>
                <div>{focusedAccount.successors.primarySuccessorName}</div>
                <div className={styles.AccountDetails_detailTitle}>Secondary</div>
                <div>{focusedAccount.successors.secondarySuccessorName}</div>
              </Card>
              <Card
                title='Details'
                className={styles.AccountDetails_detailsCard}
              >
                <div className={styles.AccountDetails_accountDetails}>
                  <div className={styles.AccountDetails_accountNumber}>
                    <div className={styles.AccountDetails_detailTitle}>Account Number</div>
                    <div>{focusedAccount.details.accountNumber}</div>
                  </div>
                  <div className={styles.AccountDetails_accountType}>
                    <div className={styles.AccountDetails_detailTitle}>Account Type</div>
                    <div>{focusedAccount.details.accountType}</div>
                  </div>
                  <div className={styles.AccountDetails_investmentOption}>
                    <div className={styles.AccountDetails_detailTitle}>Investment Option</div>
                    {bowser.mobile || bowser.tablet || !isCustomOption
                      ? <div>{focusedAccount.details.option}</div>
                      : <StyledLink to={`${getAccountBaseRoute(this.props.match.params)}/investment-path`}>{focusedAccount.details.option}</StyledLink>
                    }
                  </div>
                  <div className={styles.AccountDetails_optionChangesRemaining}>
                    <div className={styles.AccountDetails_detailTitle}>Remaining Option Changes</div>
                    <div>{focusedAccount.details.optionChangesRemaining}</div>
                  </div>
                  <div className={styles.AccountDetails_age}>
                    <div className={styles.AccountDetails_detailTitle}>Beneficiary Age</div>
                    <div>{focusedAccount.beneficiary.age}</div>
                  </div>
                  <div className={styles.AccountDetails_netPrincipal}>
                    <div className={styles.AccountDetails_detailTitle}>Net Principal</div>
                    <div>{focusedAccount.details.netPrincipal}</div>
                  </div>
                  <div className={styles.AccountDetails_ytdContributions}>
                    <div className={styles.AccountDetails_detailTitle}>Current Tax Year to Date Contributions</div>
                    <div>{focusedAccount.details.ytdContributions}</div>
                  </div>
                  <div className={styles.AccountDetails_priorContributions}>
                    <div className={styles.AccountDetails_detailTitle}>Prior Tax Year Contributions</div>
                    <div>{focusedAccount.details.priorContributions}</div>
                  </div>
                </div>
              </Card>
              <Card
                title='Current Allocation'
                className={styles.AccountDetails_fundsCard}
              >
                <div className={styles.AccountDetails_fundDetails}>
                  <div>
                    <div className={styles.AccountDetails_allocationLabel}>Total Value</div>
                    <div className={styles.AccountDetails_allocationValue}>{focusedAccount.details.accountBalance}</div>
                  </div>
                  <div>
                    <div className={styles.AccountDetails_allocationLabel}>Personal Rate of Return (YTD)</div>
                    <div className={`${styles.pprIcon} ${!focusedAccount.ytdPRR.includes('%') ? styles.pprNotAvailable : focusedAccount.ytdPRR.includes('-') ? styles.negativeReturn : styles.positiveReturn}`}>
                      {focusedAccount.ytdPRR.includes('%') && (focusedAccount.ytdPRR.includes('-') ? <Icon>arrow_drop_down</Icon> : <Icon>arrow_drop_up</Icon>)}
                      {focusedAccount.ytdPRR.includes('%') ? focusedAccount.ytdPRR.replace(/\s/g, '') : focusedAccount.ytdPRR}
                      <IconBtnTooltip
                        buttonProps={{ disabled: prrButtonDisabled }}
                        icon='launch'
                        onClick={() => this.setState({ prrCalculatorShow: true })}
                        title='See Personal Rate of Return'
                      />
                    </div>
                  </div>
                </div>
                {this.fundsCompose()}
              </Card>
              <Card
                title='Upcoming Transactions'
                className={styles.AccountDetails_upcomingTransactionsCard}
              >
                {this.state.reloadingUpcomingTransactionsTable && (
                  <div className={styles.AccountDetails_upcomingTransactionsCardLoading}>
                    <LoadingOverlay show={true} indicatorHeight='10px' width='200px' />
                  </div>
                )}
                <div className={styles.AccountDetails_tableContainer}>
                  {upcomingTransactions.length > 0 ?
                    <SmartTable
                      idKey='vdId'
                      rows={upcomingTransactions}
                      columns={[
                        {
                          key: 'type',
                          title: 'Transaction Type',
                          type: 'string',
                        },
                        {
                          key: 'scheduledDate',
                          title: 'Scheduled Date',
                          type: 'dateString',
                          hideOn: ['phone'],
                        },
                        {
                          key: 'frequency',
                          title: 'Frequency',
                          type: 'string',
                          hideOn: ['phone'],
                        },
                        {
                          key: 'status',
                          title: 'Status',
                          type: 'string',
                          hideOn: ['phone'],
                        },
                        {
                          key: 'amount',
                          title: 'Amount',
                          type: 'currency',
                        },
                      ]}
                      actions={[
                        {
                          displayName: 'Edit',
                          type: 'menu',
                          onSelect: row => this.upcomingTransactionEditHandler(row),
                          showIf: row => row.canEdit,
                        },
                        {
                          displayName: 'View',
                          type: 'menu',
                          onSelect: row => this.upcomingTransactionViewHandler(row),
                          showIf: row => !row.canEdit && row.typeName === 'Option Change',
                        },
                        {
                          displayName: 'Cancel',
                          type: 'menu',
                          onSelect: row => this.setState({
                            showCancelConfirmationModal: true,
                            selectedTransaction: row,
                          }),
                          showIf: row => row.canDelete,
                        },
                      ]}
                    >
                      <TableContainer minWidth='100%'>
                        <TableHeader />
                        <TableRows />
                      </TableContainer>
                    </SmartTable>
                    : <span className={styles.AccountDetails_upcomingTransactionsEmptyMessage}>No upcoming transactions to display</span>}
                </div>
              </Card>
              <Card
                title='Transactions'
                className={styles.AccountDetails_transactionsCard}
              >
                <div className={styles.AccountDetails_tableContainer}>
                  <SmartTable
                    idKey='id'
                    rows={focusedAccount.transactions}
                    loading={loading}
                    columns={[
                      {
                        key: 'date',
                        title: 'Date',
                        type: 'dateString',
                      },
                      {
                        key: 'type',
                        title: 'Type',
                        type: 'string',
                        hideOn: ['phone'],
                      },
                      {
                        key: 'basis',
                        title: 'Basis',
                        type: 'currency',
                        hideOn: ['phone', 'tablet'],
                      },
                      {
                        key: 'amount',
                        title: 'Amount',
                        type: 'currency',
                      },
                      {
                        key: 'taxYear',
                        title: 'Tax Year',
                        type: 'number',
                        hideOn: ['phone', 'tablet'],
                        customStyle: { width: '125px' }
                      },
                    ]}
                    actions={[
                      {
                        displayName: 'View Details',
                        type: 'menu',
                        onSelect: row => this.transactionDetailsShow(row.id),
                      },
                    ]}
                  >
                    <TableToolbar
                      onSearchFocus={() => events.tableSearchAccessed('Account Details (Transactions)', window.location.pathname)}
                    />
                    <TableContainer minWidth='100%'>
                      <TableHeader />
                      <TableRows />
                    </TableContainer>
                    <TablePagination />
                  </SmartTable>
                </div>
              </Card>
              {focusedAccount.accountId &&
                <PrrCalculator
                  open={prrCalculatorShow}
                  onCloseHandler={() => this.setState({ prrCalculatorShow: false })}
                  account={focusedAccount}
                  disablePRRButtonInDetails={() => this.setState({ prrButtonDisabled: true })}
                />
              }

            </div>
          }

          <TransactionDetailsModal
            key={this.state.selectedTransactionId}
            show={this.state.showTransactionDetailsModal}
            transactionId={this.state.selectedTransactionId}
            onClose={() => this.setState({ showTransactionDetailsModal: false })}
          />

          <ConfirmModal
            show={this.state.showCancelConfirmationModal}
            title={`Cancel ${selectedTransaction.typeName}`}
            body={`Are you sure you want to cancel this ${selectedTransaction.typeName}?`}
            onModalClose={() => this.setState({ showCancelConfirmationModal: false })}
            onConfirm={() => this.upcomingTransactionCancelHandler(selectedTransaction)}
            isLoading={this.state.cancelTransactionLoading}
          />

          {Object.values(focusedAccount.permissions).indexOf(true) !== -1 ? (
            <FloatingActionButton
              menuOptions={this.accountActionsCompile()}
              hasMenu
            />
          ) : (
            <FloatingActionButton
              hasMenu={false}
              mainButtonTitle='Documents'
              mainIcon='description'
              mainButtonAction={() => this.props.accountActionHandle('documents', accountId, groupId, agentId)}
            />
          )}
        </div>
      );
    }
    /* eslint-enable indent */
  }
}

export default connect(select, {
  getAccountDetails,
  getAccountsByAgent,
  getUpcomingTransactions,
  optionsGet,
  cancelTransaction,
  notificationShow,
  getScheduledTransaction,
})(sizify(AccountDetails));