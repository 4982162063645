/*
*
* ManageLPOA Actions
*
*/
import {
  LPOA_SETTINGS_GET,
  LPOA_SETTINGS_UPDATE,
  LPOA_ADDRESS_UPDATE,
} from './constants';
import {
  GETrequest,
  PUTrequest
} from 'utils/helpers/api_handler';

export function lpoaSettingsGet() {
  const response = GETrequest('/administration/authorizedrepresentative');

  return {
    type: LPOA_SETTINGS_GET,
    payload: response,
  };
}

export function lpoaSettingsUpdate(lpoaSettingsObject) {
  const response = PUTrequest('/administration/authorizedrepresentative', lpoaSettingsObject);

  return {
    type: LPOA_SETTINGS_UPDATE,
    payload: response,
  };
}

export function lpoaAddressUpdate(lpoaAddresses) {
  const payload = PUTrequest('/administration/authorizedrepresentative/address', lpoaAddresses);

  return {
    type: LPOA_ADDRESS_UPDATE,
    payload,
  };
}