/*
*
* AccountsInGroup Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Modal,
  
  SmartTable,
  TableContainer,
  TableHeader,
  TableRows,
} from '@frontend/common';

const select = (state) => ({
  optionChangeDetails: state.accounts.optionChangeDetails
});

export class AccountsInGroup extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    optionChangeDetails: PropTypes.object.isRequired
  };

  render() {
    const {
      loading,
      onClose,
      open,
      optionChangeDetails,
    } = this.props;

    return (
      <Modal
        actionButtons={[
          {
            label: 'Ok',
            action: onClose
          }
        ]}
        onCloseModal={onClose}
        show={open && !loading}
        title='Accounts In Group'

      >
        <div style={{ minHeight: '400px' }}>
          <p>This account is 1 of {optionChangeDetails.accountsInGroup} accounts for the same account owner/beneficiary combination. This option change will count as one of the two investment option changes allowed per beneficiary for this calendar year.</p>
          <p>If you want to change the investment option for another account for the same beneficiary without incurring a second option change, you must make the change on the same day before the close of trading on the New York Stock Exchange, normally 2 p.m. MT. You also must make any modifications or cancellations before the close of trading.</p>
          <p>If you make an investment option request after the close of trading, or on a weekend or holiday, any modifications or cancellations must be received by 2 p.m. MT, the following business day.</p>
          <SmartTable
            idKey='accountId'
            rows={optionChangeDetails.groupAccounts}
            columns={[
              {
                key: 'accountNumber',
                title: 'Account Number',
                type: 'number',
              },
              {
                key: 'optionDescription',
                title: 'Current Option',
                type: 'string',
                hideOn: ['phone']
              },
            ]}
          >
            <TableContainer minWidth='100%'>
              <TableHeader />
              <TableRows />
            </TableContainer>
          </SmartTable>
        </div>
      </Modal>
    );
  }
}

export default withRouter(connect(select, {})(AccountsInGroup));
