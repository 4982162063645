import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  Button,
  TextField,
} from '@mui/material';

import {
  FormWrapper,
  LoadingOverlay,
  notificationShow,
} from '@frontend/common';

import { forgotPassword } from 'components/Features/public/ForgotPassword/actions';

import styles from './styles.module.css';


export class ForgotPassword extends Component {

  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    forgotPassword: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    username: '',
    inputError: '',
  };

  formValidate = () => {
    let formIsValid = false;
    let inputError = '';

    if (this.state.username.length < 1) {
      inputError = 'Username is required.';
    }
    else {
      formIsValid = true;
    }

    this.setState({
      inputError,
    });

    return formIsValid;
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    
    if (this.formValidate()) {
      this.setState({ loading: true });
      this.props.forgotPassword({ login: this.state.username })
        .then(() => {
          this.props.history.push('/login');
          this.props.notificationShow('Request submitted. Check your email.', 'success');
        })
        .catch(() => this.setState({ loading: false }));
    }
  }

  render() {
    return (
      <FormWrapper
        title='Forgot Password'
        instructions='If the provided username is found, password reset instructions will be sent to the associated email address.'
      >
        <form
          className='formContainer'
          onSubmit={this.onFormSubmit}
        >
          <TextField
            onChange={(e) => this.setState({ username: e.target.value, inputError: '' })}
            value={this.state.username}
            label='Username'
            fullWidth
            autoFocus
            disabled={this.state.loading}
            error={Boolean(this.state.inputError)}
            helperText={this.state.inputError}
          />
          <div className={styles.ForgotPassword_buttons}>
            <Button
              variant='text'
              style={{ width: '48%' }}
              onClick={() => this.props.history.push('/login')}
              disabled={this.state.loading}
            >
              Cancel
            </Button>
            <LoadingOverlay show={this.state.loading} width='48%'>
              <Button
                type='submit'
                variant='contained'
                disabled={this.state.loading}
                fullWidth
              >
                Submit
              </Button>
            </LoadingOverlay>
          </div>
        </form>
      </FormWrapper>
    );
  }
}

export default withRouter(connect(null, { 
  notificationShow,
  forgotPassword,
})(ForgotPassword));
