import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Switch,
  withRouter,
} from 'react-router-dom';

import {
  LegalFooter,
  notificationShow,
  SplashScreen,
} from '@frontend/common';

import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';

import Header from './Header';
import Sidenav from './Sidenav';
import Login from 'components/Features/public/Login';
import ActivateUser from 'components/Features/public/ActivateUser';
import ForgotPassword from 'components/Features/public/ForgotPassword';
import ResetPassword from 'components/Features/public/ResetPassword';
import ForgotUsername from 'components/Features/public/ForgotUsername';
import Accounts from 'components/Features/protected/Accounts';
import Dashboard from 'components/Features/protected/Dashboard';
import PageNotFound from 'components/Features/protected/PageNotFound';
import Templates from 'components/Features/protected/Templates';
import NewAccounts from 'components/Features/protected/NewAccounts';
import ManageLPOA from 'components/Features/protected/ManageLPOA';
import ManageUsers from 'components/Features/protected/ManageUsers';
import MyInfo from 'components/Features/protected/MyInfo';
import Reports from 'components/Features/protected/Reports';
import ManageData from 'components/Features/protected/ManageData';
import LPOASettings from 'components/Features/protected/LPOASettings';
import BulkOptionChange from 'components/Features/protected/BulkOptionChange';
import AppTour from 'components/Features/protected/AppTour';
import BulkStatementDownload from 'components/Features/protected/BulkStatementDownload';

// Potentially Blocked Components - see ProtectedRoute
import Multifactor from 'components/Features/protected/Multifactor';

import { getTitle } from 'utils/helpers/title_handler';
import ErrorBoundary from 'utils/helpers/error_boundary';

import { userLogout, clearStore, toggleSplashHasDisplayed, } from './actions';

import styles from './styles.module.css';

const frontendConfig = window.frontendConfig;
const location = window.location;
const appVersion = window.appVersion;

const select = (state) => ({
  isValid: state.session.isValid,
  accountBlocked: state.session.accountBlocked,
  showSideNav: state.session.showSideNav,
  permissions: state.session.permissions,
  splashHasDisplayed: state.session.splashHasDisplayed,
  documents: state.static.documents,
  isAccountListLoaded: state.accounts.accountList.length > 0,
  isAgeBracketsLoaded: state.static.ageBrackets.length > 0,
  claims: state.session.claims,
});

export class Navigation extends Component {

  static propTypes = {
    isValid: PropTypes.bool.isRequired,
    accountBlocked: PropTypes.bool.isRequired,
    notificationShow: PropTypes.func.isRequired,
    showSideNav: PropTypes.bool.isRequired,
    userLogout: PropTypes.func.isRequired,
    permissions: PropTypes.object.isRequired,
    documents: PropTypes.object.isRequired,
    clearStore: PropTypes.func.isRequired,
    isAccountListLoaded: PropTypes.bool.isRequired,
    isAgeBracketsLoaded: PropTypes.bool.isRequired,
    splashHasDisplayed: PropTypes.bool.isRequired,
    toggleSplashHasDisplayed: PropTypes.func.isRequired,
    claims: PropTypes.object.isRequired,
  };

  state = {
    showSideNav: false,
    showEnvMessage: location.hostname.toLowerCase() !== 'fa.my529.org' && (!Object.prototype.hasOwnProperty.call(frontendConfig, 'envWarning') || frontendConfig.envWarning),
  };

  logOut = () => {
    const token = sessionStorage.getItem('token');
    this.props.userLogout({ token })
      .finally(() => {
        this.props.notificationShow('You have logged out.', 'success');
      });
    this.props.clearStore();
    this.props.history.push('/login');
  }

  componentDidMount() {
    const storedVersion = localStorage.getItem('fa_version');
    if (!storedVersion || storedVersion !== appVersion) {
      localStorage.setItem('fa_version', appVersion);
    }
  }

  render() {
    const {
      permissions, splashHasDisplayed, toggleSplashHasDisplayed, isAccountListLoaded,
      isAgeBracketsLoaded, isValid, accountBlocked, documents, claims,
    } = this.props;
    const { showEnvMessage } = this.state;
    return (
      <div>
        {!accountBlocked &&
          <Header
            handleLogout={this.logOut}
            openSideNav={() => this.setState({ showSideNav: true })}
            showMenus={this.props.isValid}
            pageTitle={getTitle(this.props.history.location.pathname)}
            className='hideOnPrint'
          />}
        {!accountBlocked &&
          <Sidenav
            open={this.state.showSideNav}
            onClose={() => this.setState({ showSideNav: false })}
            onOpen={() => this.setState({ showSideNav: true })}
            handleLogout={this.logOut}
            className='hideOnPrint'
          />}
        <div className={styles.Navigation_featuresContainer}>
          <main className={`${styles.Navigation_featuresChildren} ${showEnvMessage ? styles['non-prod'] : ''}`}>
            <ErrorBoundary>
              <Switch>
                <PublicRoute path='/login' component={Login} />
                <PublicRoute path='/activate-user/:type' component={ActivateUser} />
                <PublicRoute path='/forgot-password' component={ForgotPassword} />
                <PublicRoute path='/reset-password' component={ResetPassword} />
                <PublicRoute path='/forgot-username' component={ForgotUsername} />
                {accountBlocked && <ProtectedRoute path='/multifactor' component={Multifactor} />}
                {!accountBlocked && <ProtectedRoute exact path='/' component={Dashboard} />}
                {!accountBlocked && <ProtectedRoute path='/(accounts|agents)' component={Accounts} />}
                {!accountBlocked && <ProtectedRoute path='/templates' component={Templates} />}
                {!accountBlocked && <ProtectedRoute path='/new-accounts' component={NewAccounts} />}
                {!accountBlocked && permissions.optionChanges && <ProtectedRoute path='/bulk-option-change' component={BulkOptionChange} />}
                {!accountBlocked && (permissions.editLPOAContactInfo || permissions.editLPOASettings) && <ProtectedRoute path='/manage-lpoa' component={ManageLPOA} />}
                {!accountBlocked && (permissions.manageUsers && permissions.isFirmEntity) && <ProtectedRoute path='/manage-users' component={ManageUsers} />}
                {!accountBlocked && (permissions.adminReports || permissions.accountReports) && <ProtectedRoute path='/reports' component={Reports} />}
                {!accountBlocked && (permissions.setupData || permissions.downloadData) && <ProtectedRoute path='/manage-data' component={ManageData} />}
                {!accountBlocked && <ProtectedRoute path='/my-info' component={MyInfo} />}
                {!accountBlocked && <ProtectedRoute path='/lpoa-settings' component={LPOASettings} />}
                {!accountBlocked && claims.MasterAdmin === 'true' && <ProtectedRoute path='/bulk-statement-download' component={BulkStatementDownload} />}
                <ProtectedRoute component={PageNotFound} />
              </Switch>
            </ErrorBoundary>
          </main>
          {splashHasDisplayed && <AppTour />}
          {isValid && !accountBlocked && !splashHasDisplayed &&
            <SplashScreen
              isAppLoading={
                (!isAccountListLoaded && !isAgeBracketsLoaded)
                && this.props.history.pathname === '/' // runs min display time if user goes directly to another bookmarked page
              }
              splashHasDisplayed={splashHasDisplayed}
              toggleSplashHasDisplayed={toggleSplashHasDisplayed}
            />}
          <LegalFooter key={documents.programDescription} programDescriptionLink={documents.programDescription} />
        </div>
      </div>
    );
  }
}

export default withRouter(connect(select, {
  notificationShow,
  userLogout,
  clearStore,
  toggleSplashHasDisplayed,
})(Navigation));
