/*
*
* AppTour Component
*
*/
/* eslint-disable quotes */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { saveUserPreference } from 'components/AppRoot/Navigation/actions';
import { USER_PREFERENCE_KEYS } from 'components/AppRoot/Navigation/constants';

import events from 'utils/ga';

import {
  Dialog,
  DialogContent,
  MobileStepper,
  Button,
  IconButton,
  Icon,
  Tooltip,
  MenuItem,
  Fab,
} from '@mui/material';
import { withStyles, } from '@mui/styles';
import { Checkbox } from '@frontend/common';
import Tippy from '@tippyjs/react';

import styles from './styles.module.css';

const select = ({ session }) => ({
  show: session.isValid && !JSON.parse(session.userPreferences[USER_PREFERENCE_KEYS.hasSeenTour]),
  userId: session.claims.UserId,
});

const muiStyles = theme => ({
  stepperRoot: {
    backgroundColor: '#fff',
  },
  stepperDotActive: {
    backgroundColor: theme.palette.secondary.main,
  },
  iconButtonRoot: {
    color: '#000',
  },
});


export class AppTour extends React.Component {

  static propTypes = {
    show: PropTypes.bool.isRequired,
    saveUserPreference: PropTypes.func.isRequired,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    classes: PropTypes.object.isRequired,
  };

  state = {
    setUserPreference: false,
    step: 0,
    showRowMenu: false,
    show: this.props.show || false,
  };

  completeTour = () => {
    events.viewAppTourStep(this.state.step === 5 ? 'finish' : 'skip');
    this.setState({ show: false });
    this.state.setUserPreference && this.props.saveUserPreference(this.props.userId, USER_PREFERENCE_KEYS.hasSeenTour, true)
      .catch(() => null);
  }

  fabStepCompose() {
    return (
      <div className={styles.AppTour_step}>
        <h1>Page Actions</h1>
        <p>Page actions can often be found in a floating button in the bottom right-hand corner. It looks like this (icon may differ):</p>
        <div style={{ display: 'flex', minHeight: '125px', alignItems: 'center' }}>
          <Tooltip
            title='Add'
            placement='left'
          >
            <Fab>
              <Icon>add</Icon>
            </Fab>
          </Tooltip>
        </div>
      </div>
    );
  }

  infoIconStepCompose() {
    return (
      <div className={styles.AppTour_step}>
        <h1>Additional Information</h1>
        <p>{"When additional information is available about a term or feature, you'll see an icon like the one below. Simply hover over the icon to reveal more information."}</p>
        <Tippy content='Here is some additional information!' theme='my529' >
          <Icon style={{ fontSize: '80px', cursor: 'pointer' }}>info_outline</Icon>
        </Tippy>
      </div>
    );
  }

  nextStep(totalSteps) {
    const { step } = this.state;
    if (step < totalSteps - 1) {
      events.viewAppTourStep(step + 2);
      this.setState({
        step: step + 1,
        showRowMenu: false,
      });
    }
  }

  onKeypress = (e, stepCount) => {
    if (e.keyCode === 39) { // keyboard right
      this.nextStep(stepCount);
    }
    else if (e.keyCode === 37) { // keyboard left
      this.previousStep(stepCount);
    }
  }

  previousStep = () => {
    const { step } = this.state;
    if (step !== 0) {
      events.viewAppTourStep((step + 1) - 1);
      this.setState({
        step: step - 1,
        showRowMenu: false,
      });
    }
  };

  rowMenuStepCompose() {
    const { showRowMenu } = this.state;
    return (
      <div className={styles.AppTour_step}>
        <h1>Tables - Row Menu</h1>
        <p>{"You will see many data tables throughout the application. Often times, each row will have a menu that can be used to view more details or take an action. The menu icon looks like this:"}</p>
        <div id={styles.AppTour_rowMenu}>
          <IconButton
            classes={{ root: this.props.classes.iconButtonRoot }}
            onClick={() => this.setState({ showRowMenu: !showRowMenu })}
          >
            <Icon style={{ fontSize: '80px' }}>more_vertical</Icon>
          </IconButton>
          <div id={styles.AppTour_rowMenuFlyout} className={showRowMenu ? styles.show : ''}>
            <MenuItem onClick={() => this.setState({ showRowMenu: false })}>View Account</MenuItem>
            <MenuItem onClick={() => this.setState({ showRowMenu: false })}>Withdrawals</MenuItem>
          </div>
        </div>
      </div>
    );
  }

  searchStepCompose() {
    return (
      <div className={styles.AppTour_step}>
        <h1>Tables - Search and Filter</h1>
        <p>Data tables will often have the ability to search/filter the data. That is done by clicking on the below icon and typing a search term.</p>
        <IconButton classes={{ root: this.props.classes.iconButtonRoot }}>
          <Icon style={{ fontSize: '80px' }}>search</Icon>
        </IconButton>
      </div>
    );
  }

  siteNavigationStepCompose() {
    return (
      <div className={styles.AppTour_step}>
        <h1>Site Navigation</h1>
        <p>The main navigation menu is located in a hidden side drawer. You can reveal the menu by clicking on the icon in the top left-hand corner. It looks like this:</p>
        <IconButton classes={{ root: this.props.classes.iconButtonRoot }}>
          <Icon style={{ fontSize: '80px' }}>menu</Icon>
        </IconButton>
      </div>
    );
  }

  welcomeMessageCompose() {
    return (
      <div className={styles.AppTour_step}>
        <h1>{'Welcome!'}</h1>
        <p>{"Welcome to my529’s suite of Financial Advisor tools! We’re excited to have you here. Before you begin, we would like to highlight a couple features and tools you will be using frequently. Let’s take a look!"}</p>
      </div>
    );
  }

  componentDidMount() {
    if (this.props.show) {
      events.viewAppTourStep(1);
    }
  }

  componentDidUpdate(prevProps) {
    const { show } = this.props;
    if (prevProps.show !== show) {
      if (show) {
        events.viewAppTourStep(1);
      }
      this.setState({
        show,
        step: 0,
      });
    }
  }

  render() {
    const { setUserPreference, step } = this.state;
    const { classes } = this.props;
    const tourSteps = [
      this.welcomeMessageCompose(),
      this.siteNavigationStepCompose(),
      this.rowMenuStepCompose(),
      this.searchStepCompose(),
      this.fabStepCompose(),
      this.infoIconStepCompose(),
    ];

    return (
      <Dialog
        open={this.state.show}
        onKeyUp={e => this.onKeypress(e, tourSteps.length)}
      >
        <DialogContent className={styles.AppTour_container}>
          {tourSteps[step]}
          <div className={styles.AppTour_nav}>
            <MobileStepper
              steps={tourSteps.length}
              activeStep={step}
              position='static'
              classes={{
                root: classes.stepperRoot,
                dotActive: classes.stepperDotActive,
              }}
              nextButton={
                <Button
                  onClick={() => this.nextStep(tourSteps.length)}
                  disabled={step === tourSteps.length - 1}
                >
                  Next
                  <Icon>keyboard_arrow_right</Icon>
                </Button>
              }
              backButton={
                <Button
                  onClick={this.previousStep}
                  disabled={step === 0}
                >
                  <Icon>keyboard_arrow_left</Icon>
                  Back
                </Button>
              }
            />
            <Checkbox
              checked={setUserPreference}
              label='Never show tour again'
              onChange={() => this.setState({ setUserPreference: !setUserPreference })}
            />
            <Button onClick={() => this.completeTour()}>Dismiss</Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connect(select, { saveUserPreference })(withStyles(muiStyles)(AppTour));
