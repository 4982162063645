/*
*
* AccountsToExport Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import { poasForExportGet, poasForExportUpdate } from '../actions';

import AssignAccounts from 'components/Features/protected/ManageUsers/AssignAccounts';
import {
  Card,
  LoadingOverlay,
  notificationShow
} from '@frontend/common';

import { Button } from '@mui/material';
import { Prompt } from 'react-router-dom';

import styles from './styles.module.css';

const select = (state) => ({
  poas: state.manageData.poasForExport,
});

export class AccountsToExport extends React.Component {

  static propTypes = {
    poas: PropTypes.array.isRequired,
    poasForExportGet: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    poasForExportUpdate: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    loading: false,
    updateLoading: false,
    poas: [],
  };

  updateHandle = () => {
    this.setState({
      updateLoading: true,
    });
    const updatedIds = this.state.poas
      .filter(poa => poa.selected)
      .map(poa => poa.id);

    this.props.poasForExportUpdate(updatedIds.length === this.props.poas.length, updatedIds)
      .then(() => {
        this.props.notificationShow('Accounts for export updated.', 'success');
        // redirect to home
        this.props.history.push('/');
      })
      .catch(() => null)
      .finally(() => this.setState({ updateLoading: false }));
  }

  componentDidMount() {
    const { poas } = this.props;
    if (poas.length > 0) {
      this.setState({ poas });
    }
    else {
      this.setState({
        loading: true,
      });
      this.props.poasForExportGet()
        .then(() => {
          this.setState({
            loading: false,
            poas: this.props.poas,
          });
        })
        .catch(() => this.setState({ loading: false }));
    }

    window.onbeforeunload = () => {
      if (!isEqual(poas, this.state.poas)) {
        return 'Are you sure you want to leave? There are unsaved changes on this page.';
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.poas, prevProps.poas)) {
      this.setState({
        poas: this.props.poas,
      });

      window.onbeforeunload = () => {
        if (!isEqual(this.props.poas, this.state.poas)) {
          return 'Are you sure you want to leave? There are unsaved changes on this page.';
        }
      };
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = () => null;
  }

  render() {
    const { poas, loading, updateLoading } = this.state;
    return (
      <div className={styles.AccountsToExport_container}>
        <Card
          title='Accounts to Export'
          className={styles.AccountsToExport_card}
        >
          <AssignAccounts
            accounts={poas}
            onChange={updatedAccounts => this.setState({ poas: updatedAccounts })}
            loading={loading}
          />
          <div className={styles.AccountsToExport_updateButton}>
            <LoadingOverlay
              show={this.state.updateLoading}
              indicatorHeight='5px'
            >
              <Button
                variant='contained'
                disabled={isEqual(this.props.poas, poas) || loading || updateLoading}
                onClick={this.updateHandle}
              >
                Done
              </Button>
            </LoadingOverlay>
          </div>
        </Card>

        <Prompt
          when={!isEqual(this.props.poas, poas)}
          message='Are you sure you want to leave? There are unsaved changes on this page.'
        />
      </div>
    );
  }
}

export default connect(select, {
  poasForExportGet,
  notificationShow,
  poasForExportUpdate
})(AccountsToExport);
