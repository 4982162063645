/*
*
* Reports Component
*
*/
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ReportsList from './ReportsList';
import RunReport from './RunReport';
import PageNotFound from 'components/Features/protected/PageNotFound';


export class Reports extends React.Component {

  render() {
    return (
      <Switch>
        <Route exact path='/reports' component={ReportsList} />
        <Route path='/reports/:reportId' component={RunReport} />
        <Route component={PageNotFound} />
      </Switch>
    );
  }
}

export default Reports;
