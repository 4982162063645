/*
*
* StaticErrorResolveModal Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  resolveBirthdate,
  resolveEmail,
  resolvePOALevel,
  resolveSSN,
  resolveFedTax,
  resolveStateTax,
} from 'components/Features/protected/NewAccounts/actions';
import { errorTypes, detailedErrors } from '../constants';

import {
  Modal,
} from '@frontend/common';

import styles from './styles.module.css';


export class StaticErrorResolveModal extends React.Component {

  static propTypes = {
    staticErrors: PropTypes.array.isRequired,
    resolveBirthdate: PropTypes.func.isRequired,
    resolveEmail: PropTypes.func.isRequired,
    resolvePOALevel: PropTypes.func.isRequired,
    resolveSSN: PropTypes.func.isRequired,
    resolveFedTax: PropTypes.func.isRequired,
    resolveStateTax: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    approveAccount: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
  };

  applyHandle = () => {
    this.setState({ loading: true });
    const apiActions = [];
    this.props.staticErrors.forEach(error => {
      switch (error.type) {
        case errorTypes.AGENT_ERROR:
        case errorTypes.BENEFICIARY_ERROR:
        case errorTypes.SUCCESSOR_ERROR:
        case errorTypes.SECONDARY_SUCCESSOR_ERROR: {
          apiActions.push(this.props.resolveBirthdate(error.ssn, error.approvedValue));
          break;
        }
        case errorTypes.EMAIL_ERROR: {
          apiActions.push(this.props.resolveEmail(error.ssn, error.approvedValue));
          break;
        }
        case errorTypes.SSN_ERROR: {
          apiActions.push(this.props.resolveSSN(error.email, error.approvedValue));
          break;
        }
        case errorTypes.POA_LEVEL_ERROR: {
          apiActions.push(this.props.resolvePOALevel(error.ssn, error.otherSSN, error.approvedValue));
          break;
        }
        case errorTypes.FED_TAX_ERROR: {
          apiActions.push(this.props.resolveFedTax(error.ssn, error.otherSSN, error.approvedValue));
          break;
        }
        case errorTypes.STATE_TAX_ERROR: {
          apiActions.push(this.props.resolveStateTax(error.ssn, error.otherSSN, error.approvedValue));
          break;
        }
        default: break;
      }
    });

    Promise.all(apiActions)
      .then(() => this.props.approveAccount())
      .catch(() => null)
      .finally(() => this.setState({ loading: false }));
  }

  approvedValuesCompose() {
    const approvedValues = this.props.staticErrors.map(error => {
      const errorDetails = detailedErrors.find(errorDetail => errorDetail.type === error.type) || {};
      return (
        <div className={styles.StaticErrorResolveModal_section} key={error.type}>
          <div className={styles.StaticErrorResolveModal_sectionTitle}>{errorDetails.description}</div>
          <hr />
          <div className={styles.StaticErrorResolveModal_approvedValue}>{error.approvedValue}</div>
        </div>
      );
    });

    return (
      <div className={styles.StaticErrorResolveModal_container}>
        <div>This new account has information that conflicts with accounts already approved by the client.</div>
        {approvedValues}
        <div>Either apply the above information to all associated new accounts, or cancel and manually edit this new account to match previously approved accounts.</div>
      </div>
    );
  }

  render() {
    return (
      <Modal
        title='Approved Account Conflicts'
        show={this.props.show}
        onCloseModal={this.props.onCloseModal}
        actionButtons={[
          {
            label: 'Cancel',
            action: this.props.onCloseModal,
            disabled: this.state.loading
          },
          {
            label: 'Apply',
            action: this.applyHandle,
            loading: this.state.loading,
            disabled: this.state.loading,
            buttonType: 'contained',
          },
        ]}
      >
        {this.approvedValuesCompose()}
      </Modal>
    );
  }
}

export default connect(null, {
  resolveBirthdate,
  resolveEmail,
  resolvePOALevel,
  resolveSSN,
  resolveFedTax,
  resolveStateTax,
})(StaticErrorResolveModal);
