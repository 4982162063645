/*
*
* AccountStatus Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { statuses } from '../constants';

import Tippy from '@tippyjs/react';

import styles from './styles.module.css';


export function AccountStatus({ status }) {
  const statusConfig = {
    [statuses.LOADED]: {
      message: 'Needs Advisor review and approval.',
      color: 'var(--warn)',
    },
    [statuses.ADVISOR_APPROVED]: {
      message: 'Needs client review and approval.',
      color: 'var(--warn)',
    },
    [statuses.CLIENT_REJECTED]: {
      message: 'Account not opened.',
      color: 'var(--error)',
    },
    [statuses.EXPIRED]: {
      message: 'Reset required.',
      color: 'var(--error)',
    },
    [statuses.SECURITY_FAILURE]: {
      message: 'ID verification required. Contact my529.',
      color: 'var(--error)',
    },
    [statuses.CLIENT_APPROVED]: {
      message: 'Account is open.',
      color: '#41AD49',
    },
  };

  if (!statusConfig[status]) {
    return (
      <div className={styles.AccountStatus_statusIndicator}>
        <div style={{ backgroundColor: 'var(--warn)' }} />
        <div>{status}</div>
      </div>
    );
  }

  return (
    <Tippy
      content={statusConfig[status].message}
      placement='left'
      theme='newAccountStatus'
      delay={[100, 0]}
    >
      <div className={styles.AccountStatus_statusIndicator}>
        <div style={{ backgroundColor: statusConfig[status].color }} />
        <div>{status}</div>
      </div>
    </Tippy>
  );
}

AccountStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default AccountStatus;
