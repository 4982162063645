/*
*
* NewAccountsList Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import bowser from 'bowser';
import { saveFile } from 'utils/helpers/files';
import { withRouter } from 'react-router-dom';

import { newAccountsListGet, newAccountDownload } from '../actions';
import { statuses } from '../constants';

import BulkUploadModal from './BulkUploadModal';

import Banner from 'components/Features/Banner';
import { BANNER_LOCATIONS } from 'components/AppRoot/StaticResources/constants';

import {
  FloatingActionButton,
  notificationShow,
  Modal,

  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';
import AccountStatus from '../AccountStatus';

import events from 'utils/ga';

export const select = (state) => ({
  webMessages: state.static.webMessages,
  allowNewAccounts: state.newAccounts.allowNewAccounts,
  simpleAccounts: Object.values(state.newAccounts.accounts).map(account => ({
    /* eslint-disable indent */
    id: account.PoaAccountApplicationId,
    benName: `${account.BeneficiaryFirstName || ''} ${account.BeneficiaryLastName || ''}`,
    ownerName: `${account.OwnerFirstName || ''} ${account.OwnerLastName || ''}`,
    option: account.TemplateName ? account.TemplateName : account.OptionName,
    dateLoaded: account.Date,
    status: account.Status,
    statusOrder: (account.Status === statuses.CLIENT_APPROVED && `2-${account.Status}`)
      || ((
        account.Status === statuses.EXPIRED
        || account.Status === statuses.SECURITY_FAILURE
        || account.Status === statuses.CLIENT_REJECTED
      ) && `0-${account.Status}`)
      || `1-${account.Status}`,
    /* eslint-enable indent */
  })),
});


export class NewAccountsList extends React.Component {

  static propTypes = {
    webMessages: PropTypes.object.isRequired,
    simpleAccounts: PropTypes.array.isRequired,
    allowNewAccounts: PropTypes.bool.isRequired,
    newAccountsListGet: PropTypes.func.isRequired,
    newAccountDownload: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    showUploadModal: false,
    showDownloadedModal: false,
  };

  downloadHandle = (row) => {
    this.props.newAccountDownload(row.id)
      .then(action => {
        const downloadedData = action.payload.data;
        if (!downloadedData) {
          this.props.notificationShow('', 'genericError');
        }
        else {
          const fileBlob = new Blob([downloadedData], { type: 'application/zip' });
          const fileName = `FormsFor${row.benName.replace(/\s/g, '')}.zip`;

          if (bowser.name === 'Internet Explorer' || bowser.name === 'Microsoft Edge') {
            navigator.msSaveBlob(fileBlob, fileName);
          }
          else {
            saveFile(fileBlob, fileName);
          }
          this.setState({
            showDownloadedModal: true,
          });
        }
      })
      .catch(() => null);
  }

  componentDidMount() {
    if (this.props.simpleAccounts.length === 0) {
      this.setState({ loading: true });
      this.props.newAccountsListGet()
        .catch(() => null)
        .finally(() => this.setState({ loading: false }));
    }
    else {
      this.props.newAccountsListGet()
        .catch(() => null);
    }
  }

  render() {
    const { loading } = this.state;
    const { history, simpleAccounts, webMessages, allowNewAccounts, notificationShow } = this.props;

    const fabOptions = [{
      title: 'Single Account',
      icon: 'account_circle',
      action: () => this.props.history.push('/new-accounts/add'),
    }];

    if (!bowser.mobile && !bowser.tablet) {
      fabOptions.push({
        title: 'Bulk Upload',
        icon: 'file_upload',
        action: () => this.setState({ showUploadModal: true }),
      });
    }

    return (
      <div>
        <Banner show={Boolean(webMessages[BANNER_LOCATIONS.NEW_ACCOUNTS])} body={webMessages[BANNER_LOCATIONS.NEW_ACCOUNTS]} />

        <SmartTable
          idKey='id'
          rows={simpleAccounts}
          loading={loading}
          columns={[
            {
              key: 'ownerName',
              title: 'Account Owner/Agent',
              type: 'string',
              hideOn: ['phone'],
            },
            {
              key: 'benName',
              title: 'Beneficiary',
              type: 'string',
            },
            {
              key: 'option',
              title: 'Option',
              type: 'string',
              hideOn: ['phone'],
            },
            {
              key: 'dateLoaded',
              title: 'Date Loaded',
              type: 'dateString',
              hideOn: ['phone', 'tablet'],
              customStyle: { width: '.5fr' },
            },
            {
              key: 'statusOrder',
              title: 'Status',
              type: 'string',
              customStyle: { width: '.5fr' },
              format: (order, row) => <AccountStatus status={row.status} />
            },
          ]}
          emptyMessage='No new accounts. Click the + button to begin.'
          actions={[
            {
              displayName: 'View Account',
              type: 'menu',
              onSelect: row => history.push(`/new-accounts/${row.id}`),
              showIf: row => row.status !== statuses.LOADED
            },
            {
              displayName: 'Review',
              type: 'menu',
              onSelect: row => history.push(`/new-accounts/${row.id}`),
              showIf: row => row.status === statuses.LOADED
            },
            {
              displayName: 'Download Application',
              type: 'menu',
              onSelect: row => this.downloadHandle(row),
              showIf: row => row.status === statuses.LOADED || row.status === statuses.ADVISOR_APPROVED
            },
          ]}
        >
          <TableToolbar
            onSearchFocus={() => events.tableSearchAccessed('New Accounts List', window.location.pathname)}
          />
          <TableContainer maxHeight='100%' minWidth='100%'>
            <TableHeader />
            <TableRows />
          </TableContainer>
          <TablePagination />
        </SmartTable>

        <FloatingActionButton
          mainIcon='add'
          menuOptions={fabOptions}
          hasMenu={allowNewAccounts}
          mainButtonAction={() => notificationShow('New account applications are blocked due to a firm merger.', 'warning')}
          mainButtonTitle='Add Account(s)'
        />

        <BulkUploadModal
          show={this.state.showUploadModal}
          onModalClose={() => this.setState({ showUploadModal: false })}
        />

        <Modal
          title='Application Successfully Downloaded'
          show={this.state.showDownloadedModal}
          actionButtons={[
            {
              label: 'Ok',
              action: () => this.setState({ showDownloadedModal: false }),
            },
          ]}
          onCloseModal={() => this.setState({ showDownloadedModal: false })}
        >
          <p>Your account application has been downloaded. Please note that some fields of the downloaded application will need to be filled out before submission. You will be required to select the investment option desired for the account again. If you are using a customized investment template, you will need to print that out separately.</p>
        </Modal>
      </div>
    );
  }
}

export default withRouter(connect(select, {
  newAccountsListGet,
  newAccountDownload,
  notificationShow,
})(NewAccountsList));
