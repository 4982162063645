/*
*
* TemplateAccountsList Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { cloneDeep, find } from 'lodash';
import { 
  Modal,
  properCase,

  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';
import { getTemplateAccountsList } from 'components/Features/protected/Templates/actions';
import { getAccountBaseRoute } from 'components/Features/protected/Accounts/helpers';

import events from 'utils/ga';

const select = (state) => ({
  templateAccountsList: state.templates.templateAccountsList,
});

export class TemplateAccountsList extends React.Component {

  static propTypes = {
    getTemplateAccountsList: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedTemplate: PropTypes.object.isRequired,
    templateAccountsList: PropTypes.array.isRequired,
  };
  
  state = {
    loading: false,
    templateAccountsList: []
  };

  templateAccountsListGet(id) {
    this.setState({ loading: true });

    this.props.getTemplateAccountsList(id)
      .then(() => {
        this.setState({
          loading: false,
          templateAccountsList: this.tableDataFormat(this.props.templateAccountsList)
        });
      })
      .catch(() => this.setState({ loading: false }));
  }

  tableDataFormat(tableData) {
    tableData = cloneDeep(tableData);
    
    tableData.forEach(data => {
      data.AgentName = properCase(`${data.AgtFirst} ${data.AgtLast}`);
      data.BeneficiaryName = properCase(`${data.BenFirst} ${data.BenLast}`);
    });

    return tableData;
  }

  rowMenuHandle(id) {
    this.props.onClose();
    const account = find(this.props.templateAccountsList, { AccountId: parseInt(id) }) || {};
    this.props.history.push(getAccountBaseRoute({ agentId: account.AgentId, groupId: account.AccountGroupId, accountId: id }));
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && this.props.selectedTemplate.id !== prevProps.selectedTemplate.id) {
      this.templateAccountsListGet(this.props.selectedTemplate.id);
    }
  }

  render() {
    return (
      <Modal
        onCloseModal={this.props.onClose}
        maxWidth='lg'
        show={this.props.open}
        title={`Accounts Associated with ${this.props.selectedTemplate.name}`}
      >
        <div style={{ height: '60vh' }}>
          <SmartTable
            idKey='AccountId'
            rows={this.state.templateAccountsList}
            loading={this.state.loading}
            columns={[
              {
                key: 'AgentName',
                title: 'Account Owner / Agent',
                type: 'string',
              },
              {
                key: 'BeneficiaryName',
                title: 'Beneficiary',
                type: 'string',
                hideOn: ['phone', 'tablet'],
              },
              {
                key: 'AccountNumber',
                title: 'Account Number',
                type: 'number',
                hideOn: ['phone'],
              },
              {
                key: 'BenAge',
                title: 'Beneficiary Age',
                type: 'number',
                hideOn: ['phone', 'tablet'],
              },
            ]}
            actions={[{
              displayName: 'View Account',
              type: 'menu',
              onSelect: row => this.rowMenuHandle(row.AccountId),
            }]}
          >
            <TableToolbar
              onSearchFocus={() => events.tableSearchAccessed('Accounts With Templates', window.location.pathname)}
            />
            <TableContainer minWidth='100%' maxHeight='100%'>
              <TableHeader />
              <TableRows />
            </TableContainer>
            <TablePagination />
          </SmartTable>
        </div>
      </Modal>
    );
  }
}

export default withRouter(connect(select, { getTemplateAccountsList })(TemplateAccountsList));
