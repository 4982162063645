/*
*
* NewWithdrawals Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import dayjs from 'dayjs';
import * as validator from 'utils/helpers/form_validation';
import { sanitizeHTML } from 'utils/helpers/sanitation';

import {
  ACH_PAYMENT,
  ACCOUNT_OWNER,
  ACCOUNT_TYPE_UGMA_UTMA,
  BENEFICIARY,
  CHECK_PAYMENT,
  K12_ED,
  HIGHER_ED,
  FULL_BALANCE,
  ONE_TIME,
  PARTIAL_BALANCE,
  SCHOOL,
  ELECTRONIC_PAYMENT,
} from '../constants';
import {
  Card,
  ConfirmModal,
  CurrencyInput,
  DatePicker,
  Dropdown,
  InfoIcon,
  LoadingOverlay,
  Modal,
  ZipInput,
  currencyFormatter,
  notificationShow,

  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';
import StateDropdown from 'components/Features/protected/StateDropdown';
import {
  createNewWithdrawal,
  getSchools
} from '../../../actions';
import {
  getServerDateTime,
} from 'components/AppRoot/StaticResources/actions';

import {
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  FormControl,
  Input,
  InputLabel,
  Step,
  Stepper,
  StepButton,
  TextField,
  Collapse,
  FormHelperText,
  Slide,
} from '@mui/material';

import events from 'utils/ga';

import transactionsStyles from '../../styles.module.css';
import styles from './styles.module.css';

const checkMailingText = 'A check will be mailed in 3-5 business days to:';
const sellDateText = [
  'The Sell Date is the day on which my529 will submit an order to sell units of your investment for your withdrawal. Withdrawn funds will not be available on the Sell Date.',
  'Please allow 2-3 days for the trade to settle – plus mailing time for check requests of up to 2 weeks – when considering which day to schedule the withdrawal.',
  'If the markets are closed to trading on the selected day due to a holiday, weekend or other event, the sell order will be submitted the next business day. my529 offers no guarantee regarding the timing of transactions.'
];

const select = (state) => ({
  schools: state.accounts.schools,
  withdrawalDetails: state.accounts.withdrawalDetails,
  withdrawalVariables: state.static.environmentVars.withdrawalVariables
});

export class NewWithdrawals extends React.Component {
  static propTypes = {
    createNewWithdrawal: PropTypes.func.isRequired,
    getSchools: PropTypes.func.isRequired,
    isProportional: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    notificationShow: PropTypes.func.isRequired,
    schools: PropTypes.array.isRequired,
    warningDisplay: PropTypes.func.isRequired,
    withdrawalDetails: PropTypes.object.isRequired,
    withdrawalVariables: PropTypes.object.isRequired,
    getServerDateTime: PropTypes.func.isRequired,
  };

  partialBalanceWithdrawalOnly = (this.props.isProportional && !this.props.withdrawalDetails.allowFullWithdrawalGroup) || (!this.props.isProportional && !this.props.withdrawalDetails.allowFullWithdrawalIndividual);

  state = {
    createNewWithdrawalLoading: false,
    duplicateWithdrawalModalOpen: false,
    duplicateWithdrawalMessage: '',
    errors: [],
    partialWithdrawalMessageOpen: this.partialBalanceWithdrawalOnly,
    schools: this.props.schools,
    schoolSearchOpen: false,
    schoolSearchLoading: false,
    smallScreen: window.innerWidth <= 1200,
    step0ValidationHasRun: false,
    step1ValidationHasRun: false,
    step2ValidationHasRun: false,
    stepIndex: 0,
    termsChecked: false,
    withdrawal: {
      amount: 0,
      amountType: this.partialBalanceWithdrawalOnly ? PARTIAL_BALANCE : '',
      bankAccount: {},
      leaveAccountOpen: false,
      frequency: ONE_TIME,
      ignoreDuplicates: false,
      paymentType: '',
      proportionalAmounts: [],
      recipientAddress: {
        agent: this.props.withdrawalDetails.agentAddress,
        beneficiary: this.props.withdrawalDetails.beneficiaryAddress,
        school: {
          attn: '',
          name: '',
          street1: '',
          city: '',
          state: { Code: '' },
          postalCode: '',
          schoolId: '',
          fbo: this.props.withdrawalDetails.beneficiaryName,
          studentIdNum: '',
        },
      },
      recipient: '',
      sellDate: this.props.withdrawalVariables.sellDates.length > 0 ? dayjs(this.props.withdrawalVariables.sellDates[0]) : null,
      withdrawalType: '',
    },
    withdrawalDetails: this.props.withdrawalDetails,
    withdrawalVariables: this.props.withdrawalVariables,
    serverDateTime: null,
  };

  recipientAddressDebounce = debounce((e, fieldName) => this.recipientAddressHandle(e, fieldName), 200);

  amountHandle = ({ floatValue = 0 }) => {
    this.withdrawalStateSet('amount', floatValue);

    if (this.props.isProportional) {
      this.proportionalAmountsSet(floatValue);
    }
  }

  amountTypeHandle = (e) => {
    const amountType = e.target.value;
    const { isProportional, withdrawalDetails } = this.props;

    this.withdrawalStateSet('amountType', amountType);

    if (amountType === FULL_BALANCE && isProportional) {
      this.withdrawalStateSet('amount', withdrawalDetails.totalValue.number);
      this.proportionalAmountsSet();
    }
    else if (amountType === FULL_BALANCE && !isProportional) {
      this.withdrawalStateSet('amount', withdrawalDetails.availableBalance.number);
    }
    else if (amountType === PARTIAL_BALANCE && isProportional) {
      this.withdrawalStateSet('amount', 0);
      this.proportionalAmountsSet();
    }
    else {
      this.withdrawalStateSet('amount', 0);
    }
  }

  bankAccountSelectHandle = (value) => {
    this.withdrawalStateSet('bankAccount', this.props.withdrawalDetails.agentBankAccounts.find(bankAccount => bankAccount.id === value));
  }

  checkAddressCompose = () => {
    const { stepIndex, withdrawal: { recipient, recipientAddress } } = this.state;
    const { withdrawalDetails: { agentAddressChanged, beneficiaryAddressChanged } } = this.props;
    const checkMailingInfo = 'As a financial advisor, you are not permitted to make address changes. Please verify the mailing address on file is accurate before requesting a withdrawal. To make a change, have the account owner/agent contact my529.';

    const checkNote = stepIndex !== 3 && (
      <span className={styles.NewWithdrawals_checkMailingMessage}>
        <strong>{checkMailingText}</strong>
        <InfoIcon message={checkMailingInfo} />
      </span>
    );

    const addressChangeMsg = (checkRecipient) => {
      return <span className={styles.NewWithdrawals_error}>{`Check withdrawals to the ${checkRecipient} are disabled because of a recent mailing address change.`}</span>;
    };

    if (recipient === ACCOUNT_OWNER) {
      const agent = recipientAddress.agent;

      return (
        <div className={styles.NewWithdrawals_recipientAddress}>
          {agentAddressChanged ? addressChangeMsg('account agent/account owner') : checkNote}
          <p>{agent.line1}</p>
          {agent.line2 && <p>{agent.line2}</p>}
          <p>{agent.street1}</p>
          {agent.street2 && <p>{agent.street2}</p>}
          <p>{agent.city}, {agent.state.Code} {agent.postalCode}</p>
        </div>
      );
    }
    else if (recipient === BENEFICIARY) {
      const beneficiary = recipientAddress.beneficiary;
      return (
        <div className={styles.NewWithdrawals_recipientAddress}>
          {beneficiaryAddressChanged ? addressChangeMsg('beneficiary') : checkNote}
          <p>{beneficiary.name}</p>
          <p>{beneficiary.street1}</p>
          {beneficiary.street2 && <p>{beneficiary.street2}</p>}
          <p>{beneficiary.city}, {beneficiary.state.Code} {beneficiary.postalCode}</p>
        </div>
      );
    }
    else {
      const school = recipientAddress.school;
      return (
        <div className={styles.NewWithdrawals_recipientAddress}>
          {checkNote}
          <p>{school.name}</p>
          {school.attn && <p>{school.attn}</p>}
          <p>{school.street1}</p>
          <p>{school.city}, {school.state.Code} {school.postalCode}</p>
          <p>FBO: {school.fbo}</p>
          <p>Student ID: {school.studentIdNum}</p>
        </div>
      );
    }
  }

  errorGet = (key) => {
    const error = this.state.errors.find(error => error.key === key);
    return error ? error.message : '';
  }

  formValidate = (nextStep = () => null) => {
    switch (this.state.stepIndex) {
      case 0: {
        const { isProportional, withdrawalDetails: { availableBalance, totalValue }, withdrawalVariables: { minWithdrawal, maxWithdrawal, sellDates } } = this.props;
        this.setState(({ withdrawal: { amount, amountType, sellDate, withdrawalType } }) => {
          const availBalance = isProportional ? totalValue.number : availableBalance.number;
          const errors = [];

          if (amount < minWithdrawal) { // validates amount is more than min withdrawal
            errors.push({
              key: 'amount',
              message: `Minimum withdrawal is ${currencyFormatter(minWithdrawal)}.`
            });
          }
          else if (amount > availBalance) { // validates amount is less than than available balance
            errors.push({
              key: 'amount',
              message: 'Withdrawal exceeds available balance.'
            });
          }
          else if (amount >= maxWithdrawal) { // validates amount is less than online maximum
            errors.push({
              key: 'amount',
              message: `Withdrawals of ${currencyFormatter(maxWithdrawal)} or more require a Signature Guarantee. Visit https://my529.org to complete and submit Withdrawal Request Form 300, which includes a section for a Signature Guarantee.`
            });
          }
          if (!amountType) { // validates amount type (full or partial) is selected
            errors.push({
              key: 'amountType',
              message: 'Amount type is required.'
            });
          }
          if (!withdrawalType) { // validates withdrawal type (HIGHER_ED, ACCOUNT_OWNER, K12_ED) is selected
            errors.push({
              key: 'withdrawalType',
              message: 'Withdrawal type is required.'
            });
          }
          if (!sellDate) { // validates a sell date is selected
            errors.push({
              key: 'sellDate',
              message: 'Sell Date is required.'
            });
          }
          if (!sellDates.some(date => dayjs(date).isSame(sellDate, 'day'))) { // validates that keyed-in date matches a valid sell date
            errors.push({
              key: 'sellDate',
              message: 'Sell Date is not valid.'
            });
          }

          return { errors, step0ValidationHasRun: true };
        }, () => this.state.errors.length === 0 && nextStep());
        break;
      }
      case 1: {
        this.setState(({ withdrawal: { recipient } }) => {
          const errors = [];
          if (!recipient) {
            errors.push({
              key: 'recipient',
              message: 'Recipient is required.'
            });
          }
          return { errors, step1ValidationHasRun: true };
        }, () => this.state.errors.length === 0 && nextStep());
        break;
      }
      case 2: {
        this.setState(({ withdrawal: { bankAccount, paymentType, recipient, recipientAddress: { school } } }) => {
          const errors = [];
          const formattedPostal = school.postalCode.replace(/\D/g, '');
          if (recipient === SCHOOL) {
            if (school.name.length < 1) {
              errors.push({
                key: 'school',
                message: 'School is required. Click the button to select one.'
              });
            }
            const street1Error = validator.street1Validator(school.street1.trim());
            if (street1Error) {
              errors.push({
                key: 'street1',
                message: street1Error,
              });
            }
            const cityError = validator.cityValidator(school.city.trim());
            if (cityError) {
              errors.push({
                key: 'city',
                message: cityError,
              });
            }
            if (!school.state.Code) {
              errors.push({
                key: 'state',
                message: 'State is required.'
              });
            }
            if (formattedPostal.length !== 5 && formattedPostal.length !== 9) {
              errors.push({
                key: 'postalCode',
                message: 'Postal code is required.'
              });
            }
            const attnError = validator.alphaNumericOnlyValidator(school.attn);
            if (attnError) {
              errors.push({
                key: 'attn',
                message: 'Please use only alphanumeric characters.',
              });
            }
            const studentIdNumError = validator.alphaNumericOnlyValidator(school.studentIdNum);
            if (studentIdNumError) {
              errors.push({
                key: 'studentIdNum',
                message: 'Please use only alphanumeric characters.',
              });
            }
          }
          if (recipient === ACCOUNT_OWNER && paymentType === ACH_PAYMENT && !bankAccount.id) {
            errors.push({
              key: 'bankAccount',
              message: 'Bank account is required.'
            });
          }
          if (!paymentType) {
            errors.push({
              key: 'paymentType',
              message: 'Payment type is required.'
            });
          }
          return { errors, step2ValidationHasRun: true };
        }, () => this.state.errors.length === 0 && nextStep());
        break;
      }
      default: break;
    }
  }

  infoMessagesCompose = (messages) => {
    return (<div>{messages.map(msg => <p key={msg}>{msg}</p>)}</div>);
  }

  leaveAccountOpenHandle = () => {
    this.withdrawalStateSet('leaveAccountOpen', !this.state.withdrawal.leaveAccountOpen);
  }

  nextStepHandle = () => {
    const { smallScreen, stepIndex } = this.state;
    this.setState({ stepIndex: stepIndex + 1 });
    if (!smallScreen || stepIndex + 1 === 3) {
      window.scrollTo(0, 0);
    }
    events.withdrawalsStep(this.partialBalanceWithdrawalOnly ? `${stepIndex + 1}_partial` : stepIndex + 1);
  }

  paymentTypeHandle = (e) => {
    const { withdrawalDetails: { agentBankAccounts, accountType } } = this.props;
    const { recipient } = this.state.withdrawal;

    if (
      recipient === SCHOOL ||
      recipient === BENEFICIARY ||
      (recipient === ACCOUNT_OWNER && agentBankAccounts.length === 0) ||
      (recipient === ACCOUNT_OWNER && accountType === ACCOUNT_TYPE_UGMA_UTMA)
    ) {
      this.withdrawalStateSet('paymentType', CHECK_PAYMENT);
    }
    else if (e !== undefined) {
      this.withdrawalStateSet('paymentType', e.target.value);
    }
  }

  previousStepHandle = () => {
    const { stepIndex } = this.state;
    this.setState({ stepIndex: stepIndex - 1 });
    events.withdrawalsStep(this.partialBalanceWithdrawalOnly ? `${stepIndex - 1}_partial` : stepIndex - 1);
  }

  proportionalAmountsSet = (value) => {
    const { withdrawal: { amount, amountType }, withdrawalDetails: { groupAccounts } } = this.state;

    if (amountType === FULL_BALANCE) {
      this.withdrawalStateSet('proportionalAmounts', groupAccounts.map(account => ({ accountId: account.accountId, amount: account.marketValue.number })));
    }
    else {
      this.withdrawalStateSet('proportionalAmounts', groupAccounts.map(account => ({ accountId: account.accountId, amount: (value ? value : amount) * account.percentageOfTotalValue })));
    }
  }

  radioButtonLabelGenerate = (label) => {
    return (
      <span className={styles.NewWithdrawals_radioButtonsLabel}>
        <div className={styles.NewWithdrawals_radioButtonsLabelText}>{label.name}</div>
        <div className={styles.NewWithdrawals_radioButtonsInfoIcon}>
          <InfoIcon message={this.infoMessagesCompose(label.messages)} />
        </div>
      </span>
    );
  }

  recipientAddressHandle = (e, fieldName) => {
    if (e !== undefined) {
      const addresses = { ...this.state.withdrawal.recipientAddress };

      let value;
      if (fieldName === 'postalCode') {
        value = e.formattedValue;
      }
      else {
        value = e.target.value;
      }

      this.withdrawalStateSet('recipientAddress', { ...addresses, school: { ...addresses.school, [fieldName]: value } });
    }
  }

  recipientHandle = (e) => {
    this.withdrawalStateSet('recipient', e.target.value);
  }

  schoolSelect = (schoolId) => {
    const addresses = { ...this.state.withdrawal.recipientAddress };
    const { schools, withdrawalDetails: { schoolAddresses } } = this.props;

    if (schoolAddresses.length > 0 && schoolAddresses.find(address => address.schoolId === schoolId)) {
      this.withdrawalStateSet('recipientAddress', { ...addresses, school: schoolAddresses.find(address => address.schoolId === schoolId) });
    }
    else {
      this.withdrawalStateSet('recipientAddress', { ...addresses, school: { ...addresses.school, schoolId, name: schools.find(school => school.schoolId === schoolId).name } });
    }
    this.setState({ schoolSearchOpen: false });
  }

  schoolsGet = () => {
    if (this.props.schools.length === 0) {
      this.setState({ schoolSearchLoading: true });
      this.props.getSchools()
        .then(() => {
          this.setState({ schoolSearchLoading: false });
        });
    }
  }

  sellDateHandle = (date) => {
    this.withdrawalStateSet('sellDate', date ? date.toDate() : null);
    this.props.warningDisplay(date);
  }

  sellDatesDisable = (date) => {
    return this.props.withdrawalVariables.sellDates.every(validWithdrawalDate => !dayjs(validWithdrawalDate).isSame(date, 'day'));

  }

  stateSelectHandle = (value) => {
    const addresses = { ...this.state.withdrawal.recipientAddress };
    this.withdrawalStateSet('recipientAddress', { ...addresses, school: { ...addresses.school, state: { Code: value } } });
  }

  step0Compose = () => { // Amount, Sell Date & Type
    const {
      partialWithdrawalMessageOpen,
      withdrawal: {
        amount,
        amountType,
        leaveAccountOpen,
        proportionalAmounts,
        sellDate,
        withdrawalType,
      },
    } = this.state;

    const {
      isProportional,
      withdrawalDetails,
      withdrawalVariables
    } = this.props;

    const amountError = this.errorGet('amount');
    const amountTypeError = this.errorGet('amountType');
    const inputStyle = { marginTop: '25px', width: '256px' };
    const withdrawalTypeError = this.errorGet('withdrawalType');

    if (Object.keys(withdrawalDetails).length > 0 && this.partialBalanceWithdrawalOnly) {
      events.withdrawalsStep(partialWithdrawalMessageOpen ? 'messaging_partial' : '0_partial');
      const availableBalanceIsMoreThanMax = isProportional ? withdrawalDetails.totalValue.number >= withdrawalVariables.maxWithdrawal : withdrawalDetails.availableBalance.number >= withdrawalVariables.maxWithdrawal;
      const areThereUncollectedFunds = withdrawalDetails.MarketValue > withdrawalDetails.NetAvailable;

      return (
        <div className={styles.NewWithdrawals_withdrawalType}>
          {partialWithdrawalMessageOpen ?
            <div className={styles.NewWithdrawals_partialBalanceMessaging}>
              <strong>Only a partial balance withdrawal can be performed at this time.</strong>
              {availableBalanceIsMoreThanMax ?
                <p>A full balance withdrawal is not allowed for one or more of the following reasons:</p>
                :
                <p>A full balance withdrawal is not allowed because:</p>
              }
              <ul>
                <li>Another transaction is already scheduled.</li>
                {areThereUncollectedFunds && <li>Withdrawals can be made only from contributed funds that have posted to your account(s) for at least 7 business days.</li>}
                {availableBalanceIsMoreThanMax && <li>Withdrawals of {currencyFormatter(withdrawalVariables.maxWithdrawal)} or more require a Signature Guarantee. Visit <a href='https://my529.org' target='_blank' rel='noopener noreferrer'>https://my529.org</a> to complete and submit Withdrawal Request Form 300, which includes a section for a Signature Guarantee.</li>}
              </ul>
              <Button
                key='partialBalance'
                onClick={() => this.setState({ partialWithdrawalMessageOpen: false })}
                style={{ marginTop: '12px' }}
                variant='contained'
              >
                Continue
              </Button>
            </div>
            :
            [
              // There are no CSS styles for NewWithdrawals_amountError
              <div className={amountError ? 'NewWithdrawals_amountError' : ''} key='amountInput'>
                <CurrencyInput
                  autoFocus
                  disabled={amountType === FULL_BALANCE}
                  errorText={amountError}
                  onChange={this.amountHandle}
                  label='Amount to withdraw...'
                  style={inputStyle}
                  value={amount}
                />
              </div>,
              <Collapse in={amountType === PARTIAL_BALANCE && proportionalAmounts.length > 0} key='partialProportionalAmount'>
                {proportionalAmounts.map(amount => (
                  <div key={amount.accountId}>
                    <small>Account {withdrawalDetails.groupAccounts.find(account => account.accountId === amount.accountId).accountNumber}: {currencyFormatter(amount.amount)}</small>
                  </div>
                ))}
              </Collapse>,
              <div className={styles.NewWithdrawals_sellDate} key='sellDate'>
                <DatePicker
                  error={Boolean(this.errorGet('sellDate'))}
                  helperText={this.errorGet('sellDate')}
                  label='Sell Date'
                  key='sellDate'
                  minDate={withdrawalVariables.sellDates[0]}
                  maxDate={withdrawalVariables.sellDates[withdrawalVariables.sellDates.length - 1]}
                  onChange={this.sellDateHandle}
                  shouldDisableDate={this.sellDatesDisable}
                  value={sellDate}
                />
                <InfoIcon message={<div>{sellDateText.map(text => <p key={text}>{text}</p>)}</div>} />
              </div>,
              <FormControl error={Boolean(withdrawalTypeError)} key='withdrawalType'>
                <RadioGroup
                  value={withdrawalType}
                  onChange={this.withdrawalTypeHandle}
                >
                  {withdrawalVariables.withdrawalTypes.map(type => (
                    <FormControlLabel
                      key={type.id}
                      label={this.radioButtonLabelGenerate(type)}
                      value={type.id}
                      control={<Radio disableRipple />}
                    />
                  ))}
                </RadioGroup>
                {withdrawalTypeError && <FormHelperText>{withdrawalTypeError}</FormHelperText>}
              </FormControl>
            ]
          }
        </div>
      );
    }
    else {
      const fullBalanceText = {
        name: 'Full Balance',
        messages: ['Your withdrawal request will result in a zero balance, so my529 will close your account(s) and cancel any automated contributions. Please contact your employer to cancel automated payroll direct deposits. If you would like your zero-balance account(s) to remain open, please check the box below.']
      };
      return (
        <div className={styles.NewWithdrawals_withdrawalType}>
          <FormControl error={Boolean(amountTypeError)}>
            <RadioGroup
              onChange={this.amountTypeHandle}
              value={amountType}
            >
              <FormControlLabel
                label={this.radioButtonLabelGenerate(fullBalanceText)}
                value={FULL_BALANCE}
                control={<Radio disableRipple />}
              />
              <FormControlLabel
                label='Partial Balance'
                value={PARTIAL_BALANCE}
                control={<Radio disableRipple />}
              />
            </RadioGroup>
            {amountTypeError && <FormHelperText>{amountTypeError}</FormHelperText>}
          </FormControl>

          {/* There are no CSS styles for NewWithdrawals_amountType */}
          <div className={amountType === '' ? '' : 'NewWithdrawals_amountType'} key='amountTypeOptions'>
            <Collapse in={amountType === FULL_BALANCE}>
              {/* There are no CSS styles for NewWithdrawals_leaveAccountOpenCheckbox */}
              <div className='NewWithdrawals_leaveAccountOpenCheckbox'>
                <FormControlLabel
                  label={isProportional ? 'Leave accounts open' : 'Leave account open'}
                  control={
                    <Checkbox
                      checked={leaveAccountOpen}
                      onChange={this.leaveAccountOpenHandle}
                      disableRipple
                    />
                  }
                />
              </div>
            </Collapse>
            <Collapse in={amountType === PARTIAL_BALANCE}>
              {amountType === PARTIAL_BALANCE && // prevents console errors from <CurrencyInput /> when it tries to mount but isn't rendered
                // There are no CSS styles for NewWithdrawals_amountError
                <div className={amountError ? 'NewWithdrawals_amountError' : ''}>
                  <CurrencyInput
                    autoFocus
                    disabled={amountType === FULL_BALANCE}
                    errorText={amountError}
                    onChange={this.amountHandle}
                    label='Amount to withdraw...'
                    style={inputStyle}
                    value={!amount ? 0 : amount}
                  />
                </div>
              }
            </Collapse>
            <Collapse in={amountType === PARTIAL_BALANCE && proportionalAmounts.length > 0}>
              {proportionalAmounts.map(amount => (
                <div key={amount.accountId}>
                  <small>Account {withdrawalDetails.groupAccounts.find(account => account.accountId === amount.accountId).accountNumber}: {currencyFormatter(amount.amount)}</small>
                </div>
              ))}
            </Collapse>
          </div>

          <div className={styles.NewWithdrawals_sellDate} key='sellDate'>
            <DatePicker
              error={Boolean(this.errorGet('sellDate'))}
              helperText={this.errorGet('sellDate')}
              label='Sell Date'
              key='sellDate'
              minDate={withdrawalVariables.sellDates[0]}
              maxDate={withdrawalVariables.sellDates[withdrawalVariables.sellDates.length - 1]}
              onChange={this.sellDateHandle}
              shouldDisableDate={this.sellDatesDisable}
              value={sellDate}
            />
            <InfoIcon message={<div>{sellDateText.map(text => <p key={text}>{text}</p>)}</div>} />
          </div>

          <FormControl error={Boolean(withdrawalTypeError)}>
            <RadioGroup
              onChange={this.withdrawalTypeHandle}
              value={withdrawalType}
            >
              {withdrawalVariables.withdrawalTypes.map(type => (
                <FormControlLabel
                  key={type.id}
                  label={this.radioButtonLabelGenerate(type)}
                  value={type.id}
                  control={<Radio disableRipple />}
                />
              ))}
            </RadioGroup>
            {withdrawalTypeError && <FormHelperText>{withdrawalTypeError}</FormHelperText>}
          </FormControl>
        </div>
      );
    }
  }

  step1Compose = () => { // Recipient
    const { withdrawal: { recipient, withdrawalType } } = this.state;
    const { recipientTypes } = this.props.withdrawalVariables;

    const accountOwner = recipientTypes.find(type => type.id === ACCOUNT_OWNER);
    const beneficiary = recipientTypes.find(type => type.id === BENEFICIARY);
    const school = recipientTypes.find(type => type.id === SCHOOL);

    const recipientError = this.errorGet('recipient');

    if (withdrawalType === K12_ED) {
      return (
        <span className={styles.NewWithdrawals_radioButtonsLabel}>
          <div className={styles.NewWithdrawals_radioButtonsLabelText}>{accountOwner.name}</div>
          <div className={styles.NewWithdrawals_radioButtonsInfoIcon}>
            <InfoIcon message={this.infoMessagesCompose(accountOwner.messages)} />
          </div>
        </span>
      );
    }
    else if (withdrawalType === HIGHER_ED) {
      return (
        <FormControl error={Boolean(recipientError)}>
          <RadioGroup
            value={recipient}
            onChange={this.recipientHandle}
          >
            <FormControlLabel
              label={this.radioButtonLabelGenerate(accountOwner)}
              value={ACCOUNT_OWNER}
              control={<Radio disableRipple />}
            />
            <FormControlLabel
              label={this.radioButtonLabelGenerate(beneficiary)}
              value={BENEFICIARY}
              control={<Radio disableRipple />}
            />
            <FormControlLabel
              label={this.radioButtonLabelGenerate(school)}
              value={SCHOOL}
              control={<Radio disableRipple />}
            />
          </RadioGroup>
          {recipientError && <FormHelperText>{recipientError}</FormHelperText>}
        </FormControl>
      );
    }
    else {
      return (
        <FormControl error={Boolean(recipientError)}>
          <RadioGroup
            value={recipient}
            onChange={this.recipientHandle}
          >
            <FormControlLabel
              label={
                <span className={styles.NewWithdrawals_radioButtonsLabel}>
                  <div className={styles.NewWithdrawals_radioButtonsLabelText}>{accountOwner.name}</div>
                  <div className={styles.NewWithdrawals_radioButtonsInfoIcon}>
                    <InfoIcon message={this.infoMessagesCompose(accountOwner.messages)} />
                  </div>
                </span>
              }
              value={ACCOUNT_OWNER}
              control={<Radio disableRipple />}
            />
            <FormControlLabel
              label={this.radioButtonLabelGenerate(beneficiary)}
              value={BENEFICIARY}
              control={<Radio disableRipple />}
            />
          </RadioGroup>
          {recipientError && <FormHelperText>{recipientError}</FormHelperText>}
        </FormControl>
      );
    }
  }

  step2Compose = () => { // Payment Method
    const {
      withdrawal: {
        bankAccount,
        paymentType,
        recipient,
        recipientAddress,
      },
    } = this.state;
    const { withdrawalDetails: { agentBankAccounts, accountType }, withdrawalVariables } = this.props;
    const bankAccountError = this.errorGet('bankAccount');
    const paymentTypeError = this.errorGet('paymentType');
    const paymentTypes = withdrawalVariables.paymentTypes.filter(type => type.id !== ELECTRONIC_PAYMENT);
    if (
      (recipient === ACCOUNT_OWNER && agentBankAccounts.length === 0) ||
      (recipient === ACCOUNT_OWNER && accountType === ACCOUNT_TYPE_UGMA_UTMA) ||
      recipient === BENEFICIARY
    ) {
      return this.checkAddressCompose();
    }
    else if (recipient === ACCOUNT_OWNER && agentBankAccounts.length > 0) {
      return (
        <div className={styles.NewWithdrawals_paymentType}>
          <FormControl error={Boolean(paymentTypeError)}>
            <RadioGroup
              onChange={this.paymentTypeHandle}
              value={paymentType}
            >
              {paymentTypes.map(type => (
                <FormControlLabel
                  key={type.id}
                  label={type.name}
                  value={type.id}
                  control={<Radio disableRipple />}
                />
              ))}
            </RadioGroup>
            {paymentTypeError && <FormHelperText>{paymentTypeError}</FormHelperText>}
          </FormControl>
          <div className={styles.NewWithdrawals_paymentType}>
            {paymentType === CHECK_PAYMENT && this.checkAddressCompose()}
            <Collapse in={paymentType === ACH_PAYMENT}>
              <Dropdown
                label='Bank Account'
                value={bankAccount.id || ''}
                errorText={bankAccountError}
                onChange={this.bankAccountSelectHandle}
                options={agentBankAccounts.map(acct => ({
                  value: acct.id,
                  display: `${acct.name} ${acct.type}, ${acct.number}`,
                }))}
                FormControlProps={{ style: { minWidth: '300px' } }}
              />
            </Collapse>
          </div>
        </div>
      );
    }
    else {
      const formFieldMargin = { marginTop: '18px' };
      const schoolError = this.errorGet('school');
      return (
        <div className={styles.NewWithdrawals_recipientAddress} key={recipientAddress.school.schoolId}>
          <FormControl style={{ width: '100%', minWidth: '256px' }} error={Boolean(schoolError)}>
            <Button
              onClick={() => { this.setState({ schoolSearchOpen: true }); this.schoolsGet(); }} // eslint-disable-line brace-style
              variant='contained'
              style={{ margin: '15px 0px' }}
            >
              Search Eligible Schools
            </Button>
            {schoolError && <FormHelperText style={{ textAlign: 'center' }}>{schoolError}</FormHelperText>}
          </FormControl>
          <Collapse in={recipientAddress.school.schoolId !== ''}>
            <div className={styles.NewWithdrawals_recipientAddressForm}>
              <strong style={formFieldMargin}>{checkMailingText}</strong>
              <TextField
                value={recipientAddress.school.name}
                disabled={true}
                label='Name of School'
                onChange={(e) => { e.persist(); this.recipientAddressDebounce(e, 'name'); }} // eslint-disable-line brace-style
                fullWidth
                style={formFieldMargin}
              />
              <TextField
                error={Boolean(this.errorGet('attn'))}
                helperText={this.errorGet('attn')}
                defaultValue={recipientAddress.school.attn}
                label='Attention/Department'
                inputProps={{
                  maxLength: 40
                }}
                onChange={(e) => { e.persist(); this.recipientAddressDebounce(e, 'attn'); }} // eslint-disable-line brace-style
                fullWidth
                style={formFieldMargin}
              />
              <FormControl style={formFieldMargin} error={Boolean(this.errorGet('street1'))}>
                <InputLabel>Street Address</InputLabel>
                <Input
                  defaultValue={recipientAddress.school.street1}
                  label='Street Address'
                  inputProps={{
                    maxLength: 40
                  }}
                  onChange={(e) => { e.persist(); this.recipientAddressDebounce(e, 'street1'); }} // eslint-disable-line brace-style
                  fullWidth
                />
                {this.errorGet('street1') && <FormHelperText>{this.errorGet('street1')}</FormHelperText>}
              </FormControl>
              <FormControl style={formFieldMargin} error={Boolean(this.errorGet('city'))}>
                <InputLabel>City</InputLabel>
                <Input
                  defaultValue={recipientAddress.school.city}
                  label='City'
                  inputProps={{
                    maxLength: 25
                  }}
                  onChange={(e) => { e.persist(); this.recipientAddressDebounce(e, 'city'); }} // eslint-disable-line brace-style
                  fullWidth
                />
                {this.errorGet('city') && <FormHelperText>{this.errorGet('city')}</FormHelperText>}
              </FormControl>
              <StateDropdown
                errorText={this.errorGet('state')}
                value={recipientAddress.school.state.Code || ''}
                onChange={this.stateSelectHandle}
                FormControlProps={{ style: formFieldMargin }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: { maxHeight: '300px' }
                    }
                  }
                }}
              />
              <ZipInput
                errorText={this.errorGet('postalCode')}
                onChange={(e) => this.recipientAddressDebounce(e, 'postalCode')}
                label='Postal Code'
                style={formFieldMargin}
                value={recipientAddress.school.postalCode}
              />
              <TextField
                value={recipientAddress.school.fbo}
                disabled={true}
                label='For the benefit of (FBO)'
                name='FBO'
                fullWidth
                style={formFieldMargin}
              />
              <TextField
                error={Boolean(this.errorGet('studentIdNum'))}
                helperText={this.errorGet('studentIdNum')}
                defaultValue={recipientAddress.school.studentIdNum}
                label='Student ID Number'
                inputProps={{
                  maxLength: 15
                }}
                onChange={(e) => { e.persist(); this.recipientAddressDebounce(e, 'studentIdNum'); }} // eslint-disable-line brace-style
                fullWidth
                style={formFieldMargin}
              />
            </div>
          </Collapse>
        </div>
      );
    }
  }

  step3Compose = () => { // Review, Terms & Conditions
    const {
      termsChecked,
      withdrawal: {
        amount,
        amountType,
        bankAccount,
        leaveAccountOpen,
        recipientAddress,
        sellDate,
        withdrawalType,
        paymentType,
      }
    } = this.state;
    const { paymentTypes, withdrawalTypes } = this.props.withdrawalVariables;

    /* eslint-disable quotes */
    return [
      <div className={styles.NewWithdrawals_reviewContainer} key='review'>
        <div>
          <div className={styles.NewWithdrawals_reviewAmount}>
            <div className={styles.NewWithdrawals_detailsTitle}>Amount</div>
            {currencyFormatter(amount)}
          </div>

          {amountType === FULL_BALANCE ?
            <div className={styles.NewWithdrawals_reviewAmount}>
              <div className={styles.NewWithdrawals_detailsTitle}>Account Status</div>
              {leaveAccountOpen ? 'Will remain open' : 'Will be closed'}
            </div>
            :
            null
          }

          <div className={styles.NewWithdrawals_reviewDate}>
            <div className={styles.NewWithdrawals_detailsTitle}>Sell Date</div>
            {this.state.withdrawal.frequency} on {dayjs(sellDate).format('L')}
          </div>

          <div className={styles.NewWithdrawals_reviewType}>
            <div className={styles.NewWithdrawals_detailsTitle}>Withdrawal Type</div>
            {withdrawalTypes.find(type => type.id === withdrawalType).name}
          </div>

          <div className={styles.NewWithdrawals_reviewPayment}>
            <div className={styles.NewWithdrawals_detailsTitle}>Payment Method</div>
            {paymentTypes.find(type => type.id === paymentType).name}
          </div>
        </div>

        <div>
          {paymentType === ACH_PAYMENT ?
            <div className={styles.NewWithdrawals_reviewRecipient}>
              <div className={styles.NewWithdrawals_detailsTitle}>Recipient</div>
              <div className={styles.NewWithdrawals_recipientAddress}>
                <p>{recipientAddress.agent.name}</p>
                <p>{bankAccount.name} {bankAccount.type}, {bankAccount.number}</p>
              </div>
            </div>
            :
            <div className={styles.NewWithdrawals_reviewRecipient}>
              <div className={styles.NewWithdrawals_detailsTitle}>Recipient</div>
              {this.checkAddressCompose()}
            </div>
          }
        </div>
      </div>,
      <div className={styles.NewWithdrawals_termsAndConditions} key='terms&conditions'>
        <h2 className=''>Terms and Conditions</h2>
        <h3 className=''>Please review the following Terms and Conditions regarding withdrawals</h3>

        <h5>Use of Funds</h5>
        <p>I have informed my client that my529 is not responsible for tracking the use of funds withdrawn from my529 accounts or determining their eligibility as qualified higher education expenses (including K-12 tuition expenses). I have informed my client that they are responsible for maintaining adequate records, such as receipts and other documentation, for qualified higher education expenses (including K-12 tuition expenses).</p>
        <p>Federal law limits withdrawals for K-12 tuition expenses from all qualified tuition plans, such as my529, to a combined total of $10,000 annually per beneficiary from all 529 plans. I have informed my client that they are responsible for adhering to the federal limit.</p>

        <h5>Nonqualified Withdrawals</h5>
        <p>{"I have informed my client that any portion of this withdrawal used for nonqualified higher education expenses will make that amount a nonqualified withdrawal. I have informed my client that the earnings portion of nonqualified withdrawals will be considered income and be subject to federal and applicable state income taxes, as well as an additional 10 percent federal penalty tax. Withdrawals due to the beneficiary's death, disability, scholarship (up to the amount of the scholarship), or U.S. service academy attendance are exempt from the penalty tax (but are still subject to income tax on the earnings portion of that withdrawal)."}</p>
        <p>If any part or all of this withdrawal becomes nonqualified, I have informed my client that either they or the beneficiary, as applicable, will be responsible for reporting the withdrawal on an income tax return for the tax year of the withdrawal and for paying any related taxes or penalties.</p>

        <h5>Tax Forms</h5>
        <p>I have informed my client that my529 will send IRS Form 1099-Q in January each year after a withdrawal is issued from an account. This form states the total amount, principal, and earnings portion of all withdrawals in a calendar year, for each payee, as applicable.</p>
        <p>In addition, I have informed my client that Utah taxpayer/resident account owners will receive a TC-675H tax form each year. This form states the following information for an account owner: total qualifying contributions to count toward a Utah state income tax credit, or deduction, total withdrawals made, and total amount transferred to a nonqualified beneficiary.</p>

        <h5>Request Submission</h5>
        <p>I certify that the information entered for this withdrawal request is true and accurate.</p>
        <p>My client has authorized me to make the requested withdrawal. I authorize and request that my529 make the requested withdrawal.</p>
        <p>If the account is an UGMA/UTMA account, the custodian of the account has informed me that they deem the withdrawal request to be necessary for the welfare and benefit of the beneficiary. If the account is owned by a trust, corporation, or other entity, I certify that I am authorized to act on its behalf.</p>
      </div>,
      <div
        className={styles.NewWithdrawals_termsAndConditionsCheckbox}
        key='termsAndConditionsCheckbox'
      >
        <FormControlLabel
          label='I have read and understand these Terms and Conditions.'
          control={
            <Checkbox
              checked={termsChecked}
              onChange={() => this.setState({ termsChecked: !termsChecked })}
            />
          }
        />
      </div>
    ];
    /* eslint-enable quotes */
  }

  step4Compose = () => { // Review, Terms & Conditions
    const {
      withdrawal: {
        amount,
        amountType,
        bankAccount,
        leaveAccountOpen,
        recipientAddress,
        sellDate,
        withdrawalType,
        paymentType,
        serverDateTime,
      }
    } = this.state;
    const { paymentTypes, withdrawalTypes } = this.props.withdrawalVariables;

    /* eslint-disable quotes */
    return [
      <div className={styles.NewWithdrawals_reviewContainer} key='review'>
        <div>
          <div className={styles.NewWithdrawals_reviewAmount}>
            <div className={styles.NewWithdrawals_detailsTitle}>Amount</div>
            {currencyFormatter(amount)}
          </div>

          {amountType === FULL_BALANCE ?
            <div className={styles.NewWithdrawals_reviewAmount}>
              <div className={styles.NewWithdrawals_detailsTitle}>Account Status</div>
              {leaveAccountOpen ? 'Will remain open' : 'Will be closed'}
            </div>
            :
            null
          }

          <div className={styles.NewWithdrawals_reviewDate}>
            <div className={styles.NewWithdrawals_detailsTitle}>Sell Date</div>
            {this.state.withdrawal.frequency} on {dayjs(sellDate).format('L')}
          </div>

          <div className={styles.NewWithdrawals_reviewType}>
            <div className={styles.NewWithdrawals_detailsTitle}>Withdrawal Type</div>
            {withdrawalTypes.find(type => type.id === withdrawalType).name}
          </div>

          <div className={styles.NewWithdrawals_reviewPayment}>
            <div className={styles.NewWithdrawals_detailsTitle}>Payment Method</div>
            {paymentTypes.find(type => type.id === paymentType).name}
          </div>
        </div>

        <div>
          {paymentType === ACH_PAYMENT ?
            <div className={styles.NewWithdrawals_reviewRecipient}>
              <div className={styles.NewWithdrawals_detailsTitle}>Recipient</div>
              <div className={styles.NewWithdrawals_recipientAddress}>
                <p>{recipientAddress.agent.name}</p>
                <p>{bankAccount.name} {bankAccount.type}, {bankAccount.number}</p>
              </div>
            </div>
            :
            <div className={styles.NewWithdrawals_reviewRecipient}>
              <div className={styles.NewWithdrawals_detailsTitle}>Recipient</div>
              {this.checkAddressCompose()}
            </div>
          }
        </div>
      </div>,
      <div className={transactionsStyles.printContainer} key='print'>

        <div className={transactionsStyles.currentTime}>{dayjs(serverDateTime)
          .format('MM/DD/YYYY h:mm A')} MST</div>
        <div className='hideOnPrint'>
          Please print a copy of this page for your records.
        </div>
      </div>
    ];
    /* eslint-enable quotes */
  }

  goToPreviousPage = () => {
    this.props.history.goBack();
  }

  stepperButtonsCompose = () => {
    const {
      createNewWithdrawalLoading,
      partialWithdrawalMessageOpen,
      stepIndex,
      termsChecked,
      withdrawal: {
        paymentType,
        recipient,
      }
    } = this.state;
    const cancelButton = (
      <Button
        variant='text'
        key='cancel'
        onClick={this.goToPreviousPage}
        disabled={createNewWithdrawalLoading}
        style={{ marginRight: '5px' }}
      >
        Cancel
      </Button>
    );
    const nextButton = (onClick, disabled = false) => (
      <Button
        key='nextStep'
        variant='contained'
        onClick={onClick}
        disabled={disabled}
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Next
      </Button>
    );
    const backButton = (
      <Button
        key='prevStep'
        variant='text'
        onClick={() => this.previousStepHandle()}
        disabled={createNewWithdrawalLoading}
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Back
      </Button>
    );
    const agreeButton = (
      <Button
        disabled={!termsChecked || createNewWithdrawalLoading}
        key='nextStep'
        variant='contained'
        onClick={() => this.withdrawalCreate()}
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        I agree
      </Button>
    );
    const printButton = (
      <Button
        key='print'
        onClick={() => window.print()}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Print
      </Button>
    );
    const finishButton = (
      <Button
        key='finish'
        onClick={() => this.props.history.goBack()}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Finish
      </Button>
    );

    let buttons = [];
    switch (stepIndex) { // Amount, Sell Date & Type
      case 0:
        if (!partialWithdrawalMessageOpen) {
          buttons = [
            cancelButton,
            nextButton(() => this.formValidate(this.nextStepHandle))
          ];
        }
        break;
      case 1: // Recipient
        buttons = [
          cancelButton,
          backButton,
          nextButton(() => {
            this.formValidate(this.nextStepHandle);
            this.paymentTypeHandle();
          })
        ];
        break;
      case 2: { // Payment Type
        const { withdrawalDetails: { agentAddressChanged, beneficiaryAddressChanged } } = this.props;

        let isDisabled = false;
        if (recipient === ACCOUNT_OWNER && paymentType === CHECK_PAYMENT) {
          isDisabled = agentAddressChanged;
        }
        else if (recipient === BENEFICIARY) {
          isDisabled = beneficiaryAddressChanged;
        }

        buttons = [
          cancelButton,
          backButton,
          nextButton(() => this.formValidate(this.nextStepHandle), isDisabled)
        ];
        break;
      }
      case 3: // Review, Terms & Conditions
        buttons = [
          cancelButton,
          backButton,
          <LoadingOverlay key='loading' show={createNewWithdrawalLoading}>
            {agreeButton}
          </LoadingOverlay>
        ];
        break;
      case 4:
        buttons = [
          printButton,
          finishButton,
        ];
        break;
      default: break;
    }
    return buttons;
  }

  stepperContentCompose = () => {
    switch (this.state.stepIndex) {
      case 0: // Amount, Sell Date & Type
        return this.step0Compose();

      case 1: // Recipient
        return this.step1Compose();

      case 2: // Payment Method
        return this.step2Compose();

      case 3: // Review, Terms & Conditions
        return this.step3Compose();

      case 4: // Print & Finish
        return this.step4Compose();
      default: break;
    }
  }

  withdrawalCreate = () => {
    const {
      withdrawal: {
        amount,
        amountType,
        bankAccount,
        ignoreDuplicates,
        leaveAccountOpen,
        frequency,
        proportionalAmounts,
        recipientAddress,
        paymentType,
        recipient,
        sellDate,
        withdrawalType,
      }
    } = this.state;

    const withdrawal = {
      AccountAmounts: proportionalAmounts.map(amount => ({ AccountId: amount.accountId, Amount: amount.amount })),
      AccountId: this.props.match.params.accountId,
      Amount: amount,
      BankAccountId: bankAccount.id,
      CloseAccount: amountType === FULL_BALANCE && !leaveAccountOpen,
      ExpenseType: withdrawalType,
      FullBalance: amountType === FULL_BALANCE,
      IgnoreDuplicates: ignoreDuplicates,
      PayeeAddress: recipient === SCHOOL ? {
        StreetAddress1: sanitizeHTML(recipientAddress.school.street1),
        City: sanitizeHTML(recipientAddress.school.city),
        State: sanitizeHTML(recipientAddress.school.state.Code),
        PostalCode: sanitizeHTML(recipientAddress.school.postalCode.replace(/ /g, '')),
      } : null,
      PayeeType: recipient,
      Proportional: this.props.isProportional,
      ScheduleType: frequency,
      School: {
        Department: sanitizeHTML(recipientAddress.school.attn),
        SchoolId: sanitizeHTML(recipientAddress.school.schoolId),
        StudentNumber: sanitizeHTML(recipientAddress.school.studentIdNum)
      },
      TradeDate: sellDate,
      WithdrawalType: paymentType,
    };

    this.setState({ createNewWithdrawalLoading: true });

    this.props.createNewWithdrawal(withdrawal)
      .then((response) => {
        const messages = response.payload.data;
        const dupeMsgs = Array.isArray(messages) ? messages.filter(message => message.MessageType === 3) : [];
        if (dupeMsgs.length > 0) {
          const duplicateWithdrawalMessage = dupeMsgs.map(message => message.Message).join('<\br>');
          this.setState({
            duplicateWithdrawalModalOpen: true,
            duplicateWithdrawalMessage,
            createContributionLoading: false,
            createNewWithdrawalLoading: false,
          });
        }
        else {
          events.withdrawalsStep(this.partialBalanceWithdrawalOnly ? 'final_partial' : 'final');
          this.props.notificationShow('Withdrawal scheduled.', 'success');
          // get server timestamp by returning promise here
          this.props.getServerDateTime()
            .then((response) => {
              const serverDateTime = response.payload.data;
              this.setState({
                serverDateTime,
                createNewWithdrawalLoading: false,
              });
              this.nextStepHandle();
            });
        }
      })
      .catch(() => this.setState({ createNewWithdrawalLoading: false }));
  }

  withdrawalStateSet = (key, value) => {
    const { step0ValidationHasRun, step1ValidationHasRun, step2ValidationHasRun, stepIndex } = this.state;

    this.setState(({ withdrawal }) => {
      return { withdrawal: { ...withdrawal, [key]: value } };
    });

    if (
      (stepIndex === 0 && step0ValidationHasRun) ||
      (stepIndex === 1 && step1ValidationHasRun) ||
      (stepIndex === 2 && step2ValidationHasRun)
    ) {
      this.formValidate();
    }
  }

  withdrawalTypeHandle = (e) => {
    const withdrawalType = e.target.value;
    this.withdrawalStateSet('withdrawalType', withdrawalType);
    this.withdrawalStateSet('recipient', withdrawalType === K12_ED ? ACCOUNT_OWNER : ''); // Recipient gets set to ACCOUNT_OWNER if "K-12 Tution" is selected
  }

  componentDidMount() {
    !this.partialBalanceWithdrawalOnly && events.withdrawalsStep(0);
  }

  render() {
    const {
      createNewWithdrawalLoading,
      duplicateWithdrawalModalOpen,
      duplicateWithdrawalMessage,
      schoolSearchOpen,
      smallScreen,
      stepIndex,
      withdrawal,
    } = this.state;

    return (
      <div>
        <Card>
          <Stepper
            activeStep={stepIndex}
            orientation={smallScreen ? 'vertical' : 'horizontal'}
            className='hideOnPrint'
          >
            <Step>
              <StepButton onClick={() => this.setState({ stepIndex: 0 })} disabled={stepIndex === 4}>
                Amount, Sell Date & Withdrawal Type
              </StepButton>
            </Step>
            <Step>
              <StepButton onClick={() => this.setState({ stepIndex: 1 })} disabled={stepIndex === 4}>
                Recipient
              </StepButton>
            </Step>
            <Step>
              <StepButton onClick={() => this.setState({ stepIndex: 2 })} disabled={stepIndex === 4}>
                Payment Method
              </StepButton>
            </Step>
            <Step>
              <StepButton onClick={() => this.setState({ stepIndex: 3 })} disabled={stepIndex === 4}>
                Terms & Conditions
              </StepButton>
            </Step>
            <Step>
              <StepButton onClick={() => this.setState({ stepIndex: 4 })}>
                Print Review
              </StepButton>
            </Step>
          </Stepper>

          {/* There are no CSS styles for NewWithdrawals_stepperContent */}
          <div className='NewWithdrawals_stepperContent'>
            {this.props.loading ? null : this.stepperContentCompose()}
          </div>

          <div className={`${styles.NewWithdrawals_stepperButtons} hideOnPrint`}>
            {this.stepperButtonsCompose()}
          </div>
        </Card>

        <Modal
          maxWidth='lg'
          onCloseModal={() => this.setState({ schoolSearchOpen: false })}
          show={schoolSearchOpen}
          title='Eligible Educational Institutions'
          DialogProps={{ 'TransitionComponent': Transition, fullScreen: true }}
        >
          <div>
            <SmartTable
              idKey='schoolId'
              rows={this.props.schools}
              loading={this.state.schoolSearchLoading}
              emptyMessage='No Matching Schools'
              columns={[
                {
                  key: 'name',
                  title: 'School',
                  type: 'string',
                  customStyle: { width: '2fr' },
                },
                {
                  key: 'city',
                  title: 'City',
                  type: 'string',
                  hideOn: ['phone', 'tablet'],
                },
                {
                  key: 'state',
                  title: 'State',
                  type: 'string',
                  hideOn: ['phone'],
                  customStyle: { width: '.5fr' },
                },
                {
                  key: 'fafsaId',
                  title: 'FAFSA Code',
                  type: 'string',
                  hideOn: ['phone', 'tablet'],
                  customStyle: { width: '.75fr' },
                },
              ]}
              actions={[
                {
                  type: 'icon',
                  icon: 'add',
                  iconTitle: 'Add',
                  onSelect: row => this.schoolSelect(row.schoolId),
                }
              ]}
            >
              <TableToolbar
                onSearchFocus={() => events.tableSearchAccessed('Withdrawals (Eligible Schools)', window.location.pathname)}
              />
              <TableContainer maxHeight='100%' minWidth='100%'>
                <TableHeader />
                <TableRows />
              </TableContainer>
              <TablePagination />
            </SmartTable>
          </div>
        </Modal>

        <ConfirmModal
          show={duplicateWithdrawalModalOpen}
          title='Create Duplicate Withdrawal?'
          body={duplicateWithdrawalMessage}
          onModalClose={() => this.setState({ duplicateWithdrawalModalOpen: false })}
          onConfirm={() => this.setState({ duplicateWithdrawalModalOpen: false, withdrawal: { ...withdrawal, ignoreDuplicates: true } }, () => this.withdrawalCreate())}
          isLoading={createNewWithdrawalLoading}
        />
      </div>
    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) { // eslint-disable-line
  return <Slide direction='up' ref={ref} {...props} />;
});

export default withRouter(connect(select, {
  getSchools,
  notificationShow,
  createNewWithdrawal,
  getServerDateTime,
})(NewWithdrawals));
