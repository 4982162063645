/*eslint no-console:0*/
import { isEqual, partition } from 'lodash';

/**
 * Wrapper around Google Analytics call to send a custom event.
 * @param eventCategory
 * @param eventAction
 * @param eventLabel
 */
export function sendEvent({ eventCategory, eventAction, eventLabel, }) {
  window.ga('send', 'event', {
    eventCategory,
    eventAction,
    eventLabel
  });
}

/**
 * Wrapper around Google Analytics call to send a manual page view.
 * @param url
 */
export function sendPageView(url) {
  window.ga('send', 'pageview', url);
}

/**
 * Simply takes a function and wraps it with a secondary function to be resolved later.
 * This is similar in concept to currying.
 * We define the functions we are using first and passing arguments to the first parameter and executing later.
 * @param creator
 * @param wrappingFn
 * @returns {object|string}
 */
export function bindGenerator(creator, wrappingFn) {
  return function() {
    return wrappingFn(creator.apply(this, arguments));
  };
}

/**
 * Optimistically resolves functions with strings or objects and throws an error when objects don't work.
 * @param fn
 * @returns {object|string}
 * @throws throws an error if empty strings and empty objects don't work as parameters.
 */
export function tryResolvingValue(fn) {
  if (fn.length === 0) {
    return fn();
  }
  else {
    let response;
    try {
      // First try with strings as parameters
      response = fn.apply(this, Array(fn.length).fill(''));
    }
    catch {
      // Second try sending empty objects as parameters
      response = fn.apply(this, Array(fn.length).fill(({})));
    }
    return response;
  }
}

/**
 * Notifies dev of duplicate events & page views.
 * @param resolvedGenerators {object}
 */
export function duplicateValueNotifier(resolvedGenerators) {
  Object.keys(resolvedGenerators).forEach((key, index) =>
    Object.keys(resolvedGenerators)
      .filter((key2, index2) => key2 !== key && index2 > index) // filtering out current key and all keys past where we currently are.
      .forEach(key2 => {
        if (isEqual(resolvedGenerators[key], resolvedGenerators[key2])) { // if the resolved objects are the same we should let the dev know with a warning
          const type = typeof resolvedGenerators[key];
          if (type === 'object') {
            console.warn(`GA Generator "${key}" has identical event to Generator "${key2}". Event: ${JSON.stringify(resolvedGenerators[key])}`);
          }
          else {
            console.warn(`GA Generator "${key}" has identical page view URL to Generator "${key2}". URL: ${resolvedGenerators[key]}`);
          }
        }
      })
  );
}

/**
 * Takes an object of functions that resolve into strings or objects.
 * @param generators {object}
 */
export function connectGA(generators) {
  const resolvedGenerators = {};
  const unresolvableKeys = [];
  Object.keys(generators).forEach(key => {
    try {
      resolvedGenerators[key] = tryResolvingValue(generators[key]);
    }
    catch {
      unresolvableKeys.push(key);
    }
  });

  // checks for identical events in dev and test.  we don't bother the user's console with our mistakes.
  // if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'dev') {
  //   duplicateValueNotifier(resolvedGenerators);
  // }

  // separating events from page views [[events], [page views]]
  const actionKeysPartition = partition(Object.keys(resolvedGenerators), key => typeof resolvedGenerators[key] === 'object');
  const boundGenerators = {};

  actionKeysPartition[0].forEach(key => boundGenerators[key] = bindGenerator(generators[key], sendEvent));
  actionKeysPartition[1].forEach(key => boundGenerators[key] = bindGenerator(generators[key], sendPageView));

  // throw an error to let a dev know that this script isn't resolving a more complicated generator
  if (unresolvableKeys.length > 0) {
    console.error(`couldn't determine type of certain Action Creators, either fix this code or bind the following actions manually: ${unresolvableKeys.join(', ')}.`);
  }

  return {
    // returning any generators that weren't able to be mapped to bound functions.
    // This way we can still export everything in the index.
    ...generators,
    ...boundGenerators,
  };
}

export function urlScrub(path) {
  if (path.match(/\bagents\b\/(\d+)\/\bgroups\b\/(\d+)\/\baccounts\b\/(\d+)/)) {
    let accountRoute = path.replace(/^\/agents\/(\d+)\/groups\/(\d+)\/accounts\/(\d+)/, '/agents/<agentId>/groups/<groupId>/accounts/<accountId>');

    if (path.match(/\bcontributions\b\/(\d+)/)) {
      accountRoute = accountRoute.replace(/contributions\/(\d+)/, 'contributions/<transactionId>');
    }
    else if (path.match(/\boption-changes\b\/(\d+)/)) {
      accountRoute = accountRoute.replace(/option-changes\/(\d+)/, 'option-changes/<transactionId>');
    }
    else if (path.match(/\btransfers\b\/(\d+)/)) {
      accountRoute = accountRoute.replace(/transfers\/(\d+)/, 'transfers/<transactionId>');
    }
    return accountRoute;
  }
  else if (path.match(/\bmanage-users\b\/(\d+)/)) {
    return path.replace(/^\/manage-users\/(\d+)/, '/manage-users/<advisorId>');
  }
  else if (path.match(/\bnew-accounts\b\/(\{{0,1}([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}\}{0,1})/)) {
    return path.replace(/^\/new-accounts\/(\{{0,1}([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}\}{0,1})$/, '/new-accounts/<guid>');
  }
  else if (path.match(/\bview\b\/(\d+)/)) {
    return path.replace(/^\/templates\/view\/(\d+)/, '/templates/view/<templateId>');
  }
  else if (path.match(/\bcopy\b\/(\d+)/)) {
    return path.replace(/^\/templates\/copy\/(\d+)/, '/templates/copy/<templateId>');
  }
  else {
    return path;
  }
}
