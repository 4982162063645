/*
*
* NewOptions Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep, isEmpty } from 'lodash';
import dayjs from 'dayjs';

import {
  Button,
  Step,
  StepButton,
  Stepper,
} from '@mui/material';

import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';

import {
  Card,
  LoadingOverlay,
  notificationShow,
  allNotificationsHide,
  Dropdown,
} from '@frontend/common';

import {
  customOptionsGet,
  optionsGet,
  getServerDateTime,
  getNewAgeBasedSchema,
  getNewStaticSchema,
} from 'components/AppRoot/StaticResources/actions';

import {
  CUSTOMIZED_AGEBASED,
  CUSTOMIZED_AGEBASED_TEMPLATES,
  CUSTOMIZED_STATIC,
  CUSTOMIZED_STATIC_TEMPLATES,
  STATIC,
  ENROLLMENTDATE,
} from './constants';

import { createNewOptionChange } from '../../../actions';
import { getAccountBaseRoute } from 'components/Features/protected/Accounts/helpers';

import { CalculatorForm, populate3DCalculatorSchema } from '@frontend/common';

import events from 'utils/ga';
import TermsAndConditions from '../TermsAndConditions';

import transactionStyles from '../../styles.module.css';
import styles from './styles.module.css';

const select = (state) => ({
  customStaticOptions: state.static.customStaticOptions,
  customAgeBasedOptions: state.static.customAgeBasedOptions,
  optionChangeDetails: state.accounts.optionChangeDetails,
  selectedTransaction: state.accounts.selectedTransaction,
  predefinedOptions: state.static.options.filter(opt => opt.name.search('Customized') === -1),
  documents: state.static.documents,
  newAgeBasedSchema: state.static.newAgeBasedSchema,
  newStaticSchema: state.static.newStaticSchema,
  serverDateTime: state.static.serverDateTime,
});

export class NewOptions extends React.Component {
  static propTypes = {
    createNewOptionChange: PropTypes.func.isRequired,
    customStaticOptions: PropTypes.array,
    customAgeBasedOptions: PropTypes.array,
    customOptionsGet: PropTypes.func.isRequired,
    documents: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    notificationShow: PropTypes.func.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
    optionChangeDetails: PropTypes.object.isRequired,
    optionsGet: PropTypes.func.isRequired,
    predefinedOptions: PropTypes.array.isRequired,
    selectedTransaction: PropTypes.object.isRequired,
    getServerDateTime: PropTypes.func.isRequired,
    serverDateTime: PropTypes.string,
    newAgeBasedSchema: PropTypes.object.isRequired,
    newStaticSchema: PropTypes.object.isRequired,
    getNewAgeBasedSchema: PropTypes.func.isRequired,
    getNewStaticSchema: PropTypes.func.isRequired,
  };

  state = {
    createOptionChangeLoading: false,
    loadingOnMount: false,
    customAllocationSchema: {}, // TODO: ?
    errors: [],
    isEditing: window.location.href.search('edit') > -1,
    isViewing: window.location.href.search('view') > -1,
    optionCategories: [
      STATIC,
      ENROLLMENTDATE,
      CUSTOMIZED_AGEBASED,
      CUSTOMIZED_STATIC
    ],
    options: [],
    selectedCategoryName: '',
    selectedOptionId: '',
    selectedOption: {},
    smallHeight: window.innerHeight <= 800,
    smallWidth: window.innerWidth <= 1200,
    stepIndex: 0,
    termsChecked: false,
    isOnPrintStep: false,
    matrixAllocations: [],
    calculatorErrors: {},
    showCustomized: false,
  };

  currentSelectionsGet() {
    let selectedCategoryName = '';
    let selectedOptionId = '';
    let currSchema = {};
    const {
      customAgeBasedOptions, customStaticOptions, predefinedOptions,
      selectedTransaction,
    } = this.props;

    const predefinedOption = predefinedOptions.find(option => option.name === selectedTransaction.newOption) || null;
    if (predefinedOption) {
      selectedCategoryName = predefinedOption.optionType === 'T' ? ENROLLMENTDATE : STATIC;
      selectedOptionId = selectedTransaction.predefinedOptionId;
    }
    else if (customAgeBasedOptions.find(option => option.name === selectedTransaction.newOption)) {
      selectedCategoryName = CUSTOMIZED_AGEBASED_TEMPLATES;
      selectedOptionId = selectedTransaction.templateId;

    }
    else if (customStaticOptions.find(option => option.name === selectedTransaction.newOption)) {
      selectedCategoryName = CUSTOMIZED_STATIC_TEMPLATES;
      selectedOptionId = selectedTransaction.templateId;
    }
    else if (selectedTransaction.newOption === 'Customized Age-Based') {
      selectedCategoryName = CUSTOMIZED_AGEBASED;
      currSchema = selectedTransaction.schema;
    }
    else {
      selectedCategoryName = CUSTOMIZED_STATIC;
      currSchema = selectedTransaction.schema;
    }

    this.setOptionsAndSchema(selectedCategoryName);
    this.setState({
      selectedCategoryName,
      selectedOptionId,
      currSchema,
    });
  }

  onAllocationChange = (matrixAllocations, calculatorErrors) => {
    this.setState({ matrixAllocations, calculatorErrors });
    const { hasAllocationAmountError, hasBracketTotalSumOver100Error } = calculatorErrors;
    // throw a toast on specific form errors
    if (hasAllocationAmountError || hasBracketTotalSumOver100Error) {
      hasBracketTotalSumOver100Error && this.props.notificationShow(hasBracketTotalSumOver100Error, 'error');
      hasAllocationAmountError && this.props.notificationShow(hasAllocationAmountError, 'error');
    }
    else {
      this.props.allNotificationsHide();
    }
  }

  nextCalculatorReviewHandler() {
    const {
      calculatorErrors: {
        hasAnyBracketTotalNot100Error,
        hasAllocationAmountError,
        hasBracketTotalSumOver100Error
      }
    } = this.state;

    if (!hasAnyBracketTotalNot100Error && !hasAllocationAmountError && !hasBracketTotalSumOver100Error) {
      this.nextStepHandle();
    }
    else {
      // show error toasts
      hasAnyBracketTotalNot100Error && this.props.notificationShow(hasAnyBracketTotalNot100Error, 'error');
      hasBracketTotalSumOver100Error && this.props.notificationShow(hasBracketTotalSumOver100Error, 'error');
      hasAllocationAmountError && this.props.notificationShow(hasAllocationAmountError, 'error');
    }
  }

  errorGet = (key) => {
    const error = this.state.errors.find(error => error.key === key);
    return error ? error.message : '';
  }

  formValidate = (nextStep = () => null) => {
    switch (this.state.stepIndex) {
      case 0: {
        this.setState(({ isEditing, selectedCategoryName, selectedOptionId }, { selectedTransaction: { predefinedOptionId, templateId } }) => {
          const errors = [];
          if (selectedCategoryName === '') { // validates that option category is selected
            errors.push({
              key: 'optionCategory',
              message: 'Option category is required.'
            });
          }
          else if (selectedOptionId === '' && !this.isCustomized()) {
            errors.push({
              key: 'option',
              message: 'Option is required.'
            });
          }
          else if (isEditing && (selectedOptionId === predefinedOptionId || selectedOptionId === templateId)) { // validates that change was made before proceeding
            errors.push(
              {
                key: 'optionCategory',
                message: 'No change has been made.'
              },
              {
                key: 'option',
                message: 'No change has been made.'
              }
            );
          }
          return { errors };
        }, () => this.state.errors.length === 0 && nextStep());
        break;
      }
      default: break;
    }
  }

  isCustomized() {
    return this.state.selectedCategoryName === CUSTOMIZED_AGEBASED || this.state.selectedCategoryName === CUSTOMIZED_STATIC;
  }

  isCustomizedTemplates() {
    return this.state.selectedCategoryName === CUSTOMIZED_AGEBASED_TEMPLATES || this.state.selectedCategoryName === CUSTOMIZED_STATIC_TEMPLATES;
  }

  nextStepHandle = () => {
    const { isEditing, stepIndex } = this.state;
    this.setState({ stepIndex: stepIndex + 1 });
    !isEditing && events.optionChangesStep(this.isCustomized() ? `${stepIndex + 1}_customized` : `${stepIndex + 1}`);
    window.scrollTo(0, 0);
    if (stepIndex + 1 === 2) {
      // populate the schema with values to be used in view only
      this.setState({ currSchema: populate3DCalculatorSchema(this.state.matrixAllocations, this.state.currSchema) });
    }
  }

  onTermsCheck = () => {
    this.setState({ termsChecked: !this.state.termsChecked });
  }

  optionCategoryMenuCompile() {
    const optionCategories = cloneDeep(this.state.optionCategories);
    if (this.props.customStaticOptions.length > 0) {
      optionCategories.splice(2, 0, CUSTOMIZED_STATIC_TEMPLATES);
    }
    if (this.props.customAgeBasedOptions.length > 0) {
      optionCategories.splice(2, 0, CUSTOMIZED_AGEBASED_TEMPLATES);
    }
    return optionCategories.map(category => ({ value: category }));
  }

  optionCategorySelectHandle = (category) => {
    this.setState({
      errors: [],
      selectedCategoryName: category,
      selectedOptionId: ''
    });
    this.setOptionsAndSchema(category);
  }

  optionChangeCreate() {
    const { accountId } = this.props.match.params;
    this.setState({ createOptionChangeLoading: true });
    const schema = [];
    if (this.state.selectedCategoryName === STATIC || this.state.selectedCategoryName === ENROLLMENTDATE) {
      schema.push({
        AccountId: parseInt(accountId),
        OptionId: this.state.selectedOptionId
      });
    }
    else if (this.state.selectedCategoryName === CUSTOMIZED_AGEBASED_TEMPLATES || this.state.selectedCategoryName === CUSTOMIZED_STATIC_TEMPLATES) {
      schema.push({
        AccountId: parseInt(accountId),
        CustomTemplateHeaderId: this.state.selectedOptionId
      });
    }
    else {
      schema.push({
        AccountId: parseInt(accountId),
        matrixAllocations: this.state.matrixAllocations,
        CustomSchema: this.state.currSchema,
      });
    }

    this.props.createNewOptionChange(schema)
      .then(() => {
        events.optionChangesStep(this.isCustomized() ? 'final_customized' : 'final');
        this.props.notificationShow('Investment option scheduled.', 'success');
        // get server timestamp here
        return this.props.getServerDateTime()
          .then(() => {
            this.setState({
              createOptionChangeLoading: false,
              isOnPrintStep: true,
            });
            this.nextStepHandle();
          });
      })
      .catch(() => this.setState({ createOptionChangeLoading: false }));
  }

  optionsMenuCompile() {
    return this.state.options.map(option => ({
      value: option.id,
      display: option.name,
      props: {
        disabled: option.id === '',
      }
    }));
  }

  setOptionsAndSchema(category) {
    const {
      customAgeBasedOptions, customStaticOptions, optionChangeDetails,
      predefinedOptions, newAgeBasedSchema, newStaticSchema,
    } = this.props;
    let options;
    let currSchema = {};
    switch (category) {
      case STATIC: {
        options = predefinedOptions.filter(option => !option.name.includes('Enroll'));
        break;
      }
      case ENROLLMENTDATE: {
        options = predefinedOptions.filter(option => option.name.includes('Enroll'));
        break;
      }
      case CUSTOMIZED_AGEBASED_TEMPLATES: {
        options = customAgeBasedOptions;
        break;
      }
      case CUSTOMIZED_STATIC_TEMPLATES: {
        options = customStaticOptions;
        break;
      }
      case CUSTOMIZED_AGEBASED: {
        options = [];
        currSchema = newAgeBasedSchema;
        break;
      }
      case CUSTOMIZED_STATIC: {
        options = [];
        currSchema = newStaticSchema;
        break;
      }
      default: break;
    }
    if (!this.state.isEditing) {
      const currentOptionName = optionChangeDetails.optionDescription;
      const filteredOptions = options.filter(opt => opt.name.search(currentOptionName) === -1);
      options = filteredOptions;
    }
    this.setState({ options, currSchema });
  }

  optionSelectHandle = (value) => {
    const id = parseInt(value);
    this.setState({
      errors: [],
      selectedOption: this.state.options.find(option => option.id === id),
      selectedOptionId: id,
    });
  }

  prevStepHandle() {
    const { isEditing, stepIndex } = this.state;
    this.setState({ stepIndex: stepIndex - 1 });
    !isEditing && events.optionChangesStep(this.isCustomized() ? `${stepIndex - 1}_customized` : `${stepIndex - 1}`);
    window.scrollTo(0, 0);
  }

  selectFieldCompose(value, onChange, menuItems, error, additionalProps) {
    const { label } = additionalProps;
    return (
      <Dropdown
        label={label}
        value={value}
        errorText={error}
        onChange={onChange}
        options={menuItems}
        FormControlProps={{ style: { width: '100%', minWidth: '300px' } }}
        SelectProps={additionalProps}
      />
    );
  }

  optionChangeDetailsCompose = () => {
    const {
      selectedCategoryName,
      options,
      selectedOptionId,
      customAllocationSchema,
      currSchema,
      showCustomized
    } = this.state;

    const { serverDateTime } = this.props;

    const query = options.find(option => option.id === selectedOptionId);
    const customTemplateName = query && query.name;
    const showSelectionOption = customTemplateName && isEmpty(customAllocationSchema);

    return (
      <div className={styles.NewOptionChangeDetails}>
        <h2>New Option Change Details</h2>
        {this.isCustomized() ?
          <div
            className={styles.NewOptions_collapsableCustomizedName}
            onClick={() => this.setState({ showCustomized: !showCustomized })}
          >
            <div />
            <div><b>Selected Category Name:</b> {selectedCategoryName}</div>
            <div>
              {showCustomized ? (
                <ExpandLessIcon className='hideOnPrint' />
              ) : (
                <ExpandMoreIcon className='hideOnPrint' />
              )}
            </div>
          </div>
          :
          <div><b>Selected Category Name:</b> {selectedCategoryName}</div>
        }
        {showSelectionOption && <div className={styles.NewOptions_selectedOptionName}><b>Selected Option:</b> {customTemplateName} </div>}
        {this.isCustomized() && showCustomized &&
          <CalculatorForm
            stickyHeaderTopOffset={60}
            schema={currSchema}
            isViewing={true}
            currentDate={serverDateTime}
            hideTemplateName={true}
          />
        }
      </div>
    );
  }

  step0Compose() {
    const { isEditing, isViewing, selectedCategoryName, selectedOptionId } = this.state;
    const isCustomized = this.isCustomized();
    const isCustomizedTemplates = this.isCustomizedTemplates();
    const items = this.optionCategoryMenuCompile();
    const optionsMenu = this.optionsMenuCompile();
    const optionCategoryError = this.errorGet('optionCategory');
    const optionError = this.errorGet('option');

    if (isEditing || isViewing) {
      return (
        <div className={styles.NewOptions_selectFields}>
          {this.selectFieldCompose(
            selectedCategoryName,
            this.optionCategorySelectHandle,
            items,
            optionCategoryError,
            { disabled: isViewing, label: 'Option Category' }
          )}
          {!isCustomized && (
            this.selectFieldCompose(
              selectedOptionId,
              this.optionSelectHandle,
              optionsMenu,
              optionError,
              { disabled: isViewing, label: 'Option' },
            )
          )}
        </div>
      );
    }
    else if (selectedCategoryName === '' || isCustomized) {
      return (
        <div className={styles.NewOptions_selectFields}>
          {this.selectFieldCompose(
            selectedCategoryName,
            this.optionCategorySelectHandle,
            items,
            optionCategoryError,
            { label: 'Option Category' }
          )}
        </div>
      );
    }
    else if (!isCustomized) {
      return (
        <div className={styles.NewOptions_selectFields}>
          {this.selectFieldCompose(
            selectedCategoryName,
            this.optionCategorySelectHandle,
            items,
            optionCategoryError,
            { label: 'Option Category' }
          )}
          {(isCustomizedTemplates && optionsMenu.length === 0)
            ?
            <div className={styles.NewOptions_customTemplatesNotAvailable}>No available templates</div>
            :
            this.selectFieldCompose(
              selectedOptionId,
              this.optionSelectHandle,
              optionsMenu,
              optionError,
              { label: 'Option' }
            )}
        </div>
      );
    }
  }

  step1Compose() {
    const { serverDateTime } = this.props;
    const { isViewing, currSchema, loadingOnMount } = this.state;

    if (this.isCustomized()) {
      return (
        <CalculatorForm
          loading={loadingOnMount}
          stickyHeaderTopOffset={60}
          schema={currSchema}
          isViewing={isViewing}
          onAllocationChange={this.onAllocationChange}
          currentDate={serverDateTime}
        />
      );
    }
    else {
      return this.termsAndConditionsCompose();
    }
  }

  step2Compose() {
    if (!this.state.isOnPrintStep)
      return this.termsAndConditionsCompose();
    else
      return this.step3Compose();
  }

  step3Compose() {
    const { serverDateTime } = this.state;

    return (
      <div className={transactionStyles.printContainer} key='print'>
        {this.optionChangeDetailsCompose()}
        <div className={transactionStyles.currentTime}>{dayjs(serverDateTime)
          .format('MM/DD/YYYY h:mm A')} MST</div>
        <div className='hideOnPrint'>
          Please print a copy of this page for your records.
        </div>
      </div>
    );
  }

  goToPreviousPage = () => {
    this.props.history.goBack();
  }

  stepperButtonsCompose() {
    const { createOptionChangeLoading, isViewing, stepIndex, termsChecked } = this.state;
    const cancelButton = (
      <Button
        variant='text'
        key='cancel'
        disabled={createOptionChangeLoading}
        onClick={this.goToPreviousPage}
        style={{ marginRight: '5px' }}
      >
        Cancel
      </Button>
    );
    const nextButton = (onClick) => (
      <Button
        variant='contained'
        key='nextStep'
        disabled={createOptionChangeLoading}
        onClick={onClick}
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Next
      </Button>
    );
    const backButton = (
      <Button
        variant='text'
        disabled={createOptionChangeLoading}
        key='prevStep'
        onClick={() => this.prevStepHandle()}
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Back
      </Button>
    );
    const agreeButton = (
      <Button
        variant='contained'
        disabled={!termsChecked || createOptionChangeLoading}
        key='nextStep'
        onClick={() => this.optionChangeCreate()}
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        I agree
      </Button>
    );
    const printButton = (
      <Button
        key='print'
        onClick={() => window.print()}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Print
      </Button>
    );
    const finishButton = (
      <Button
        key='finish'
        onClick={() => this.props.history.goBack()}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Finish
      </Button>
    );

    let buttons = [];
    switch (stepIndex) {
      case 0:
        buttons = [
          cancelButton,
          nextButton(() => this.formValidate(this.nextStepHandle))
        ];
        break;
      case 1:
        if (isViewing) {
          buttons = [
            cancelButton,
            backButton
          ];
        }
        else if (!this.isCustomized()) {
          buttons = [
            cancelButton,
            backButton,
            <LoadingOverlay key='loading' show={createOptionChangeLoading}>
              {agreeButton}
            </LoadingOverlay>
          ];
        }
        else {
          buttons = [
            cancelButton,
            backButton,
            nextButton(() => this.nextCalculatorReviewHandler())
          ];
        }
        break;
      case 2:
        if (!this.state.isOnPrintStep)
          buttons = [
            cancelButton,
            backButton,
            <LoadingOverlay key='loading' show={createOptionChangeLoading}>
              {agreeButton}
            </LoadingOverlay>
          ];
        else
          buttons = [
            printButton,
            finishButton,
          ];
        break;
      case 3:
        buttons = [
          printButton,
          finishButton,
        ];
        break;
      default: break;
    }
    return buttons;
  }

  stepperCompose() {
    const { smallWidth, stepIndex } = this.state;
    const stepperStyle = {
      width: smallWidth ? '' : '650px'
    };

    if (this.isCustomized()) {
      return (
        <Stepper
          activeStep={stepIndex}
          orientation={smallWidth ? 'vertical' : 'horizontal'}
          style={stepperStyle}
        >
          <Step>
            <StepButton onClick={() => this.setState({ stepIndex: 0 })} disabled={stepIndex === 3}>Select New Option</StepButton>
          </Step>
          <Step>
            <StepButton onClick={() => this.setState({ stepIndex: 1 })} disabled={stepIndex === 3}>Customize Option</StepButton>
          </Step>
          <Step>
            <StepButton onClick={() => this.setState({ stepIndex: 2 })} disabled={stepIndex === 3}>Terms & Conditions</StepButton>
          </Step>
          <Step>
            <StepButton onClick={() => this.setState({ stepIndex: 3 })}>Print Review</StepButton>
          </Step>
        </Stepper>
      );
    }
    else {
      return (
        <Stepper
          activeStep={stepIndex}
          orientation={smallWidth ? 'vertical' : 'horizontal'}
          style={stepperStyle}
        >
          <Step>
            <StepButton onClick={() => this.setState({ stepIndex: 0 })} disabled={stepIndex === 2}>Select New Option</StepButton>
          </Step>
          <Step>
            <StepButton onClick={() => this.setState({ stepIndex: 1 })} disabled={stepIndex === 2}>Terms & Conditions</StepButton>
          </Step>
          <Step>
            <StepButton onClick={() => this.setState({ stepIndex: 2 })}>Print Review</StepButton>
          </Step>
        </Stepper>
      );
    }
  }

  stepperContentCompose() {
    switch (this.state.stepIndex) {
      case 0:
        return this.step0Compose();
      case 1:
        return this.step1Compose();
      case 2:
        return this.step2Compose();
      case 3:
        return this.step3Compose();
      default: break;
    }
  }

  termsAndConditionsCompose = () => {
    return (
      <div className={styles.newOptionDetailsContainer}>
        {this.optionChangeDetailsCompose()}
        <TermsAndConditions
          checkboxes={[{
            checked: this.state.termsChecked,
            onCheck: this.onTermsCheck,
          }]}
          documents={this.props.documents}
        />
      </div>

    );
  }

  componentDidMount() {
    const {
      customAgeBasedOptions, customStaticOptions, predefinedOptions,
      getNewStaticSchema, getNewAgeBasedSchema, optionsGet,
      newAgeBasedSchema, newStaticSchema, getServerDateTime,
    } = this.props;

    const { isEditing, isViewing, serverDateTime } = this.state;

    // get custom options
    const promises = [];
    if (customAgeBasedOptions.length === 0 || customStaticOptions.length === 0) {
      promises.push(customOptionsGet());
    }
    // get predefined
    if (predefinedOptions.length === 0) {
      promises.push(optionsGet());
    }
    // get customized age-based schema
    if (newAgeBasedSchema.FundCategories.length === 0) {
      promises.push(getNewAgeBasedSchema());
    }
    // get customized static schema
    if (newStaticSchema.FundCategories.length === 0) {
      promises.push(getNewStaticSchema());
    }
    // get current date (calculator needs for the agreement year)
    if (!serverDateTime) {
      promises.push(
        getServerDateTime().then((response) => {
          const serverDateTime = response.payload.data;
          this.setState({ serverDateTime });
        })
      );
    }

    if (promises.length > 0) {
      this.setState({ loadingOnMount: true });
      Promise.all(promises).finally(() => {
        this.setState({ loadingOnMount: false });
        // initialize options
        if ((isEditing || isViewing) && this.props.predefinedOptions.length > 0) {
          this.currentSelectionsGet();
        }
        if (isEditing && Object.keys(this.props.selectedTransaction).length === 0) {
          this.props.history.push(getAccountBaseRoute(this.props.match.params));
        }
        !isEditing && events.optionChangesStep(0);
      });
    }
  }

  render() {
    if (this.state.loadingOnMount) {
      return <LoadingOverlay show={true} width='100%' indicatorHeight='10px' />;
    }
    else if (this.state.isViewing) {
      if (!this.isCustomized()) {
        return (
          <Card className={styles.NewOptions_stepperContentContainer}>
            <div className={styles.NewOptions_stepperContent}>
              {!this.props.loading && this.stepperContentCompose()}
            </div>
          </Card>
        );
      }
      else {
        return (
          <Card className={styles.NewOptions_stepperContentContainer}>
            <div className={styles.NewOptions_stepperContent}>
              {!this.props.loading && this.stepperContentCompose()}
            </div>
            <div className={styles.NewOptions_stepperButtons}>
              {this.stepperButtonsCompose()}
            </div>
          </Card>
        );
      }
    }
    else {
      return (
        <Card className={styles.NewOptions_stepperContentContainer}>
          <div className={`${styles.NewOptions_stepperStepsContainer} hideOnPrint`}>
            <div className={styles.NewOptions_stepperSteps}>
              {this.stepperCompose()}
            </div>
          </div>
          <div className={styles.NewOptions_stepperContent}>
            {!this.props.loading && this.stepperContentCompose()}
          </div>
          <div className={`${styles.NewOptions_stepperButtons} hideOnPrint`}>
            {this.stepperButtonsCompose()}
          </div>
        </Card>
      );
    }
  }
}

// withRef is removed -> forwardRef instead
export default connect(
  select,
  {
    createNewOptionChange,
    customOptionsGet,
    optionsGet,
    notificationShow,
    allNotificationsHide,
    getServerDateTime,
    getNewAgeBasedSchema,
    getNewStaticSchema,
  },
  null,
  { forwardRef: true }
)(NewOptions);