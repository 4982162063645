/*
*
* Templates Reducer
*
*/
import { cloneDeep } from 'lodash';
import {
  GET_TEMPLATE,
  GET_TEMPLATE_ACCOUNTS,
  GET_TEMPLATES,
} from './constants';

import { convert2DCalculatorSchemaTo3D } from '@frontend/common';

const initialState = {
  activeTemplates: [],
  deactivatedTemplates: [],
  template: {
    TemplateType: null,
    FundCategories: [],
  },
  templateAccountsList: [],
};

function TemplatesReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case GET_TEMPLATE: {
      newState.template = convert2DCalculatorSchemaTo3D(action.payload.data);
      return newState;
    }

    case GET_TEMPLATE_ACCOUNTS: {
      newState.templateAccountsList = action.payload.data;
      return newState;
    }

    case GET_TEMPLATES: {
      newState.activeTemplates = action.payload.data.Active;
      newState.deactivatedTemplates = action.payload.data.Deactivated;
      return newState;
    }

    default:
      return state;
  }

}

export default TemplatesReducer;