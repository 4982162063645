/*
*
* Reports Reducers
*
*/
import { cloneDeep } from 'lodash';
import {
  REPORTS_GET,
} from './constants';

const initialState = {
  reports: {},
};

function ReportsReducer(state = initialState, action) {
  
  const newState = cloneDeep(state);

  switch (action.type) {
    
    case REPORTS_GET: {
      newState.reports = action.payload.data
        .reduce((result, report) => ({
          ...result,
          [`${report.ReportId}`]: {
            id: report.ReportId,
            name: report.DisplayName,
            description: report.Description,
            category: report.ReportCategory.toLowerCase(),
            params: report.ReportParams.map(param => ({
              name: param.ParamName,
              displayName: param.DisplayName,
              type: param.DataType,
            })),
          },
        }), {});
      
      return newState;
    }

    default:
      return state;
  }

}

export default ReportsReducer;