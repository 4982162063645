/*
*
* ManageLPOA Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import {
  lpoaSettingsGet,
  lpoaSettingsUpdate,
  lpoaAddressUpdate,
} from './actions';
import { permissionGet } from 'utils/config/permissions_details';
import * as validator from 'utils/helpers/form_validation';

import {
  Checkbox,
  InfoIcon,
  LoadingOverlay,
  ConfirmModal,
  notificationShow,
  Card,
} from '@frontend/common';
import StateDropdown from 'components/Features/protected/StateDropdown';
import {
  Button,
  Icon,
  TextField,
  Tabs,
  Tab,
} from '@mui/material';

import styles from './styles.module.css';

const select = (state) => ({
  lpoaSettings: state.manageLPOA.settings,
  permissions: state.session.permissions,
  fullSettingsObject: state.manageLPOA.fullSettingsObject,
  addresses: state.manageLPOA.addresses,
});
const MAILING = 'mailing';
const PHYSICAL = 'physical';


export class ManageLPOA extends React.Component {

  static propTypes = {
    lpoaSettings: PropTypes.object.isRequired,
    lpoaSettingsGet: PropTypes.func.isRequired,
    permissions: PropTypes.object.isRequired,
    fullSettingsObject: PropTypes.object.isRequired,
    notificationShow: PropTypes.func.isRequired,
    lpoaSettingsUpdate: PropTypes.func.isRequired,
    addresses: PropTypes.object.isRequired,
    lpoaAddressUpdate: PropTypes.func.isRequired,
  };

  state = {
    lpoaSettingsLoading: false,
    showUpdateLPOASettingsModal: false,
    updateLPOALoading: false,
    updateAddressLoading: false,
    lpoaSettings: {
      AllowContribute: false,
      AllowOptionChange: false,
      AllowTransfer: false,
      AllowWithdraw: false,
      AllowViewStateTaxDocuments: false,
      AllowViewFederalTaxDocuments: false,
    },
    mailing: {
      id: 0,
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
    },
    physical: {
      id: 0,
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
    },
    addressInputErrors: [],
    addressTabIndex: 0,
  };

  addressesValidate() {
    const errors = [];
    const requiredFieldError = (input, addressType) => ({
      input,
      addressType,
      message: 'Field is required.'
    });

    const { mailing } = this.state;
    if (!mailing.street1 && !mailing.city && !mailing.state && !mailing.zip) {
      errors.push({
        input: MAILING,
        addressType: MAILING,
        message: 'Mailing address is required.'
      });
    }
    [MAILING, PHYSICAL].forEach(addressType => {
      const { [addressType]: { street1, street2, city, state, zip } } = this.state;
      if (street1 || street2 || city || state || zip) {
        if (!street1) errors.push(requiredFieldError(`${addressType}StreetAddress1`, addressType));
        const cityError = validator.cityValidator(city);
        if (cityError) {
          errors.push({
            input: `${addressType}City`,
            addressType,
            message: cityError
          });
        }
        if (!state) errors.push(requiredFieldError(`${addressType}State`, addressType));
        if (!zip) errors.push(requiredFieldError(`${addressType}Zip`, addressType));
      }
    });

    this.setState({
      addressInputErrors: errors,
    });

    return errors.length === 0;
  }

  addressForApiFormat(address) {
    const { id, street1, street2, city, state, zip } = address;
    if (!street1 && !street2 && !city && !state && !zip) {
      return null;
    }

    return {
      AddressId: id,
      StreetAddress1: street1,
      StreetAddress2: street2,
      City: city,
      State: state,
      PostalCode: zip,
      Country: 'US',
    };
  }

  addressFormCompose(addressType) {
    const { [addressType]: address, updateAddressLoading, lpoaSettingsLoading } = this.state;
    const street1Name = `${addressType}StreetAddress1`;
    const street2Name = `${addressType}StreetAddress2`;
    const cityName = `${addressType}City`;
    const stateName = `${addressType}State`;
    const zipName = `${addressType}Zip`;

    return [
      <TextField
        key={street1Name}
        name={street1Name}
        value={address.street1}
        label='Street Address 1'
        onChange={e => this.addressInputUpdate(addressType, 'street1', e.target.value, street1Name)}
        disabled={lpoaSettingsLoading || updateAddressLoading}
        error={Boolean(this.errorGet(street1Name))}
        helperText={this.errorGet(street1Name)}
        fullWidth
      />,
      <TextField
        key={street2Name}
        name={street2Name}
        value={address.street2}
        label='Street Address 2'
        onChange={e => this.addressInputUpdate(addressType, 'street2', e.target.value, street2Name)}
        disabled={lpoaSettingsLoading || updateAddressLoading}
        error={Boolean(this.errorGet(street2Name))}
        helperText={this.errorGet(street2Name)}
        fullWidth
      />,
      <TextField
        key={cityName}
        name={cityName}
        value={address.city}
        label='City'
        onChange={e => this.addressInputUpdate(addressType, 'city', e.target.value, cityName)}
        disabled={lpoaSettingsLoading || updateAddressLoading}
        error={Boolean(this.errorGet(cityName))}
        helperText={this.errorGet(cityName)}
        fullWidth
      />,
      <StateDropdown
        key={stateName}
        value={address.state}
        errorText={this.errorGet(stateName)}
        onChange={value => this.addressInputUpdate(addressType, 'state', value, stateName)}
        FormControlProps={{ disabled: lpoaSettingsLoading || updateAddressLoading }}
      />,
      <TextField
        key={zipName}
        name={zipName}
        value={address.zip}
        label='ZIP Code'
        onChange={e => this.addressInputUpdate(addressType, 'zip', e.target.value, zipName)}
        disabled={lpoaSettingsLoading || updateAddressLoading}
        error={Boolean(this.errorGet(zipName))}
        helperText={this.errorGet(zipName)}
        fullWidth
      />
    ];
  }

  addressInputUpdate(addressType, key, value, inputName) {
    const updatedErrors = [...this.state.addressInputErrors];
    const errorIndex = updatedErrors.findIndex(error => error.input === inputName);
    if (errorIndex > -1) updatedErrors.splice(errorIndex, 1);

    this.setState({
      [addressType]: {
        ...this.state[addressType],
        [key]: value,
      },
      addressInputErrors: updatedErrors,
    });
  }

  errorGet = (input) => {
    const error = this.state.addressInputErrors.find(error => error.input === input);
    return error ? error.message : '';
  }

  toggleCompose(key) {
    const permission = permissionGet(key);
    return (
      <Checkbox
        label={
          <div className={styles.ManageLPOA_toggleLabel}>
            <span>{permission.label}</span>
            <InfoIcon message={permission.description} />
          </div>
        }
        checked={this.state.lpoaSettings[key] || false}
        onChange={e => this.setState({ lpoaSettings: { ...this.state.lpoaSettings, [key]: e.target.checked } })}
      />
    );
  }

  updateAddressesButtonDisable = () => {
    const { addresses: { mailing, physical } } = this.props;
    const { mailing: stateMailing, physical: statePhysical } = this.state;
    return (
      Object.keys(mailing).every(addressInput => stateMailing[addressInput] === mailing[addressInput])
      && Object.keys(physical).every(addressInput => statePhysical[addressInput] === physical[addressInput])
    );
  }

  updatePermissionsButtonDisable = () => {
    const { lpoaSettings } = this.props;
    const { lpoaSettings: stateLPOASettings } = this.state;
    return Object.keys(lpoaSettings).every(permission => stateLPOASettings[permission] === lpoaSettings[permission]);
  }

  updateAddressHandle = () => {
    if (this.addressesValidate()) {
      const data = {
        MailingAddress: this.addressForApiFormat(this.state.mailing),
        PhysicalAddress: this.addressForApiFormat(this.state.physical),
      };

      this.setState({ updateAddressLoading: true });
      this.props.lpoaAddressUpdate(data)
        .then(() => this.props.notificationShow('Addresses updated.', 'success'))
        .catch(() => null)
        .finally(() => this.setState({ updateAddressLoading: false }));
    }
  }

  updatePermissionsHandle = () => {
    this.setState({ updateLPOALoading: true });
    const updatedFullSettingsObject = {
      ...this.props.fullSettingsObject,
      ...this.state.lpoaSettings,
    };

    this.props.lpoaSettingsUpdate(updatedFullSettingsObject)
      .then(() => {
        this.props.notificationShow('LPOA settings updated.', 'success');
        this.setState({
          updateLPOALoading: false,
          showUpdateLPOASettingsModal: false,
        });
      })
      .catch(() => this.setState({ updateLPOALoading: false }));
  }

  componentDidMount() {
    if (
      Object.keys(this.props.lpoaSettings).length === 0
      || Object.keys(this.props.addresses.mailing).length === 0
      || Object.keys(this.props.addresses.physical).length === 0
    ) {
      this.setState({ lpoaSettingsLoading: true });
      this.props.lpoaSettingsGet()
        .then(() => {
          const { lpoaSettings, addresses } = this.props;
          this.setState({
            lpoaSettingsLoading: false,
            lpoaSettings: { ...lpoaSettings },
            mailing: addresses.mailing,
            physical: addresses.physical,
          });
        })
        .catch(() => this.setState({ lpoaSettingsLoading: false }));
    }
    else {
      const { lpoaSettings, addresses } = this.props;
      this.setState({
        lpoaSettings: { ...lpoaSettings },
        mailing: addresses.mailing,
        physical: addresses.physical,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.lpoaSettings, prevProps.lpoaSettings)) {
      this.setState({ lpoaSettings: { ...this.props.lpoaSettings } });
    }
    if (!isEqual(this.props.addresses, prevProps.addresses)) {
      const { addresses } = this.props;
      this.setState({ 
        mailing: addresses.mailing,
        physical: addresses.physical,
      });
    }
  }

  render() {
    const { lpoaSettingsLoading, addressTabIndex } = this.state;
    const { permissions } = this.props;
    const errorStyles = {
      backgroundColor: 'rgba(255, 0, 0, 0.25)',
      color: 'var(--error)',
    };

    /* eslint-disable indent */
    return (
      <div className={styles.ManageLPOA_container}>
        { permissions.editLPOASettings && (
          <Card
            title='LPOA Settings'
            className={styles.ManageLPOA_card}
          >
            <LoadingOverlay
              show={lpoaSettingsLoading}
              indicatorHeight='10px'
              width='100%'
            >
              <div className={styles.ManageLPOA_cardBody}>
                <div className={styles.ManageLPOA_settingToggles}>
                  <div className={styles.ManageLPOA_settingSection} id={styles.ManageLPOA_settingsSection_first}>
                    <div>Level 1</div>
                    <hr />
                    <div id={styles.ManageLPOA_viewAccountSection}>
                      <Icon style={{ color: 'rgba(0, 0, 0, 0.65)' }}>done</Icon>
                      <span>{permissionGet('AllowView').label}</span>
                    </div>
                  </div>
                  <div className={styles.ManageLPOA_settingSection}>
                    <div>Level 2</div>
                    <hr />
                    {this.toggleCompose('AllowContribute')}
                    {this.toggleCompose('AllowOptionChange')}
                    {this.toggleCompose('AllowTransfer')}
                  </div>
                  <div className={styles.ManageLPOA_settingSection}>
                    <div>Level 3</div>
                    <hr />
                    {this.toggleCompose('AllowWithdraw')}
                  </div>
                  <div className={styles.ManageLPOA_settingSection}>
                    <div>Tax Documents</div>
                    <hr />
                    {this.toggleCompose('AllowViewStateTaxDocuments')}
                    {this.toggleCompose('AllowViewFederalTaxDocuments')}
                  </div>
                </div>
                <div className={styles.ManageLPOA_updateButton}>
                  <Button
                    variant='contained'
                    disabled={this.updatePermissionsButtonDisable() || lpoaSettingsLoading}
                    onClick={() => this.setState({ showUpdateLPOASettingsModal: true })}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </LoadingOverlay>
          </Card>
        )}
        { permissions.editLPOAContactInfo && (
          <Card
            title='Contact Information'
            className={styles.ManageLPOA_card}
          >
            <LoadingOverlay
              show={lpoaSettingsLoading}
              indicatorHeight='10px'
              width='100%'
            >
              <div className={styles.ManageLPOA_cardBody}>
                <div className={styles.ManageLPOA_contactTabs}>
                  <Tabs
                    value={addressTabIndex}
                    onChange={(e, value) => this.setState({ addressTabIndex: value })}
                    variant='fullWidth'
                  >
                    <Tab
                      label='Mailing'
                      style={this.state.addressInputErrors.filter(error => error.addressType === MAILING).length > 0 ? errorStyles : {}}
                    />
                    <Tab
                      label='Physical'
                      style={this.state.addressInputErrors.filter(error => error.addressType === PHYSICAL).length > 0 ? errorStyles : {}}
                    />
                  </Tabs>
                  {addressTabIndex === 0 && [
                      this.errorGet(MAILING) && (<div className={styles.ManageLPOA_errorText} key='mailingGeneralError'>{this.errorGet(MAILING)}</div>),
                      <div key='mailingForm'>{this.addressFormCompose(MAILING)}</div>
                  ]}
                  {addressTabIndex === 1 && this.addressFormCompose(PHYSICAL)}
                </div>
                <div className={styles.ManageLPOA_updateButton}>
                  <LoadingOverlay
                    show={this.state.updateAddressLoading}
                    indicatorHeight='5px'
                  >
                    <Button
                      variant='contained'
                      onClick={this.updateAddressHandle}
                      disabled={this.updateAddressesButtonDisable() || this.state.updateAddressLoading}
                    >
                      Update
                    </Button>
                  </LoadingOverlay>
                </div>
              </div>
            </LoadingOverlay>
          </Card>
        )}

        <ConfirmModal
          show={this.state.showUpdateLPOASettingsModal}
          title='Update LPOA Settings'
          body='Are you sure? Updating LPOA settings affect all LPOA users.'
          onModalClose={() => this.setState({ showUpdateLPOASettingsModal: false })}
          onConfirm={this.updatePermissionsHandle}
          isLoading={this.state.updateLPOALoading}
        />
      </div>
    );
    /* eslint-enable indent */
  }
}

export default connect(select, {
  lpoaSettingsGet,
  lpoaSettingsUpdate,
  notificationShow,
  lpoaAddressUpdate,
})(ManageLPOA);
