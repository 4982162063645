import { POSTrequest } from 'utils/helpers/api_handler';
import {
  USER_ACTIVATE,
} from './constants';


export function activateUser(type, params) {
  const payload = POSTrequest(`/administration${type === 'firm' ? '/financialadvisor' : ''}/activate`, params);
  return {
    type: USER_ACTIVATE,
    payload,
  };
}