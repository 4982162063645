/*
*
* TemplateDeactivate
*
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deactivateTemplate } from 'components/Features/protected/Templates/actions';
import {
  customOptionsGet
} from 'components/AppRoot/StaticResources/actions';
import { ConfirmModal, notificationShow } from '@frontend/common';


export class TemplateDeactivate extends Component {

  static propTypes = {
    deactivateTemplate: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedTemplate: PropTypes.object.isRequired,
    templatesGet: PropTypes.func.isRequired,
    customOptionsGet: PropTypes.func.isRequired,
  };

  state = {
    deactivateModalLoading: false,
  };

  deactivateModalBodyCompose = () => {
    return `Deactivating '${this.props.selectedTemplate.name}' moves the template to the Deactivated tab. It can be reactivated at any time, and investments that were set up using this template remain unaffected.`;
  }

  templateDeactivate() {
    this.setState({
      deactivateModalLoading: true,
    });

    this.props.deactivateTemplate(this.props.selectedTemplate.id)
      .then(() => {
        this.props.notificationShow('Template deactivated.', 'success');
        this.props.templatesGet();
        this.props.onClose();
      })
      .then(() => this.props.customOptionsGet()) // update custom templates
      .catch(() => null)
      .finally(() => this.setState({ deactivateModalLoading: false }));
  }

  render() {
    return (
      <ConfirmModal
        body={this.deactivateModalBodyCompose()}
        isLoading={this.state.deactivateModalLoading}
        onModalClose={this.props.onClose}
        onConfirm={(id) => this.templateDeactivate(id)}
        show={this.props.open}
        title='Deactivate Template?'
      />
    );
  }
}

export default connect(null, {
  deactivateTemplate,
  notificationShow,
  customOptionsGet,
})(TemplateDeactivate);
