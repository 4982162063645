import {
  DELETErequest,
  GETrequest,
  POSTrequest,
  PUTrequest,
} from 'utils/helpers/api_handler';

import {
  startCase
} from 'lodash';

import {
  ACCOUNT_TRANSACTIONS_DETAILS_GET,
  ACCOUNT_DETAILS_GET,
  ACCOUNTS_SEARCH,
  ACCOUNTS_GET_BY_AGENT,
  BANK_ACCOUNT_ADD,
  AGENT_BANK_ACCOUNTS_GET,
  CONTRIBUTION_CREATE,
  CONTRIBUTION_DETAILS_GET,
  CONTRIBUTION_EDIT,
  CONTRIBUTION_EDIT_DETAILS_CLEAR,
  CONTRIBUTION_EDIT_DETAILS_GET,
  GIFT_CODE_STATUS_EDIT,
  NEXT_RUN_DATE_GET,
  OPTION_CHANGE_CREATE,
  OPTION_CHANGE_DETAILS_GET,
  ROUTING_INFO_GET,
  SCHOOLS_GET,
  TRANSACTION_DETAILS_GET,
  UPCOMING_TRANSACTIONS_GET,
  WITHDRAWAL_CREATE,
  WITHDRAWAL_DETAILS_GET,
  TRANSFER_CREATE,
  TRANSFER_UPDATE,
  TRANSACTION_DELETE,
  SCHEDULED_TRANSACTION_GET,
  ACCOUNT_DOCUMENTS_GET,
  ACCOUNT_DOCUMENT_DOWNLOAD_DATA,
  BULK_OPTION_CHANGE_CANDIDATES_GET,
  BULK_OPTION_CHANGE_CREATE,
  ACCOUNT_TRANSACTION_PREVIEW,
  TRANSFER_ACCOUNTS_GET,
  ACCOUNT_INVESTMENT_PATH_GET,
  GET_PRR_DATES,
  GET_PRR,
  COUNTRIES_LIST_GET,
} from './constants';

import { convert3DCalculatorSchemaTo2D } from '@frontend/common';

export function addBankAccount(agentId, bankInfo) {
  const response = POSTrequest(`/agents/${agentId}/bankAccounts`, bankInfo);
  return {
    type: BANK_ACCOUNT_ADD,
    payload: response
  };
}

export function clearContributionEditDetails() {
  return {
    type: CONTRIBUTION_EDIT_DETAILS_CLEAR
  };
}

export function createNewContribution(contribution) {
  const response = POSTrequest('/transactions/ContributionSchedules', contribution);
  return {
    type: CONTRIBUTION_CREATE,
    payload: response
  };
}

export function createNewOptionChange(data) {
  const updatedData = data.map(optionChange => {
    const { matrixAllocations, CustomSchema } = optionChange;
    const isCustomizedOptionChange = CustomSchema && CustomSchema.FundCategories && CustomSchema.FundCategories.length > 0;
    const updatedCustomSchema = isCustomizedOptionChange ? convert3DCalculatorSchemaTo2D(matrixAllocations, CustomSchema) : CustomSchema;
    return { ...optionChange, CustomSchema: updatedCustomSchema };
  });

  const response = POSTrequest('/transactions/optionchanges', updatedData);
  return {
    type: OPTION_CHANGE_CREATE,
    payload: response
  };
}

export function createNewTransfer(payload) {
  const response = POSTrequest('/transactions/transfers', payload);
  return {
    type: TRANSFER_CREATE,
    payload: response,
  };
}

export function createNewWithdrawal(data) {
  const response = POSTrequest('/transactions/WithdrawalSchedules', data);
  return {
    type: WITHDRAWAL_CREATE,
    payload: response
  };
}

export function editContribution(transactionId, data) {
  const response = PUTrequest(`/transactions/contributionSchedules/${transactionId}`, data);
  return {
    type: CONTRIBUTION_EDIT,
    payload: response
  };
}

export function editGiftCodeStatus(accountId, isActive) {
  const response = POSTrequest(`/accounts/${accountId}/GiftCodeStatus?isActive=${isActive}`);
  return {
    type: GIFT_CODE_STATUS_EDIT,
    payload: response
  };
}

export function getAccounts() {
  const response = GETrequest('/search/accounts');
  return {
    type: ACCOUNTS_SEARCH,
    payload: response
  };
}

export function getAccountDetails(accountId) {
  const response = GETrequest(`/accounts/${accountId}`);
  return {
    type: ACCOUNT_DETAILS_GET,
    payload: response,
  };
}

export function getAccountsByAgent(agentId, optionalParam) {
  const payload = optionalParam ? GETrequest(`/search/accounts/byagent/${agentId}?${optionalParam}`) : GETrequest(`/search/accounts/byagent/${agentId}`);
  return {
    type: ACCOUNTS_GET_BY_AGENT,
    payload,
  };
}

export function getAccountTransactionsDetails(accountGroupId, accountId) {
  const response = GETrequest(`/accountgroups/${accountGroupId}`);
  return {
    type: ACCOUNT_TRANSACTIONS_DETAILS_GET,
    meta: { accountGroupId, accountId },
    payload: response,
  };
}

export function getAgentBankAccounts(agentId, transactionType) {
  const response = GETrequest(`/agents/${agentId}/bankaccounts?allow${transactionType}=true`);
  return {
    type: AGENT_BANK_ACCOUNTS_GET,
    payload: response
  };
}

export function getBankRoutingInfo(routingNumber) {
  const response = GETrequest(`/routingnumbers/${routingNumber}`);
  return {
    type: ROUTING_INFO_GET,
    payload: response
  };
}

export function getContributionDetails(accountId) {
  return {
    type: CONTRIBUTION_DETAILS_GET,
    accountId
  };
}

export function getContributionEditDetails(transactionId) {
  const response = GETrequest(`/Transactions/ContributionSchedules/${transactionId}`);
  return {
    type: CONTRIBUTION_EDIT_DETAILS_GET,
    payload: response
  };
}

export function getNextRunDate(dates, day, frequency) {
  let datePath;

  function formatDate(date) {
    return new Date(date).toISOString().split('T')[0]; // eslint-disable-line
  }

  if (dates instanceof Array) {
    const joinedDates = dates.map(date => `start=${formatDate(date)}`).join('&'); // eslint-disable-line
    datePath = `?${joinedDates}&`;
  }
  else {
    datePath = `/${formatDate(dates)}?`;
  }

  const response = GETrequest(`/Transactions/ScheduleRunDates${datePath}frequency=${startCase(frequency)}`);

  return {
    type: NEXT_RUN_DATE_GET,
    payload: response,
    meta: { day, frequency }
  };
}

export function getOptionChangeDetails(accountId) {
  return {
    type: OPTION_CHANGE_DETAILS_GET,
    accountId,
  };
}

export function getSchools() {
  const response = GETrequest('/EducationalInstitutions');
  return {
    type: SCHOOLS_GET,
    payload: response,
  };
}

export function getTransactionDetails(id) {
  const response = GETrequest(`/accounts/transactions/${id}`);
  return {
    type: TRANSACTION_DETAILS_GET,
    payload: response,
  };
}

export function getUpcomingTransactions(accountId) {
  const payload = GETrequest(`/accounts/${accountId}/schedules`);
  return {
    type: UPCOMING_TRANSACTIONS_GET,
    payload,
    meta: { accountId },
  };
}

export function getWithdrawalDetails(groupId, accountId) {
  return {
    type: WITHDRAWAL_DETAILS_GET,
    meta: { groupId, accountId },
  };
}

export function cancelTransaction(transaction) {
  const response = DELETErequest(transaction.url);
  return {
    type: TRANSACTION_DELETE,
    payload: response,
    meta: { accountId: transaction.accountId, transactionId: transaction.id, },
  };
}

export function getScheduledTransaction(transaction) {
  const response = GETrequest(transaction.url);
  return {
    type: SCHEDULED_TRANSACTION_GET,
    payload: response,
    meta: { type: transaction.typeName }
  };
}

export function getTransfer(transferId) {
  const response = GETrequest(`/transactions/transfers/${transferId}`);
  return {
    type: SCHEDULED_TRANSACTION_GET,
    payload: response,
    meta: { type: 'Transfer' },
  };
}

export function updateTransfer(transfer) {
  const response = PUTrequest(`/transactions/transfers/${transfer.TransferId}`, transfer);
  return {
    type: TRANSFER_UPDATE,
    payload: response,
  };
}

export function accountDocumentsGet(accountId) {
  const payload = GETrequest(`/accounts/${accountId}/documents`);

  return {
    type: ACCOUNT_DOCUMENTS_GET,
    payload,
    meta: { accountId },
  };
}

export function downloadAccountDocumentData(url) {
  const payload = GETrequest(url);

  return {
    type: ACCOUNT_DOCUMENT_DOWNLOAD_DATA,
    payload,
  };
}

export function getCandidatesForBulkOptionChange(query) {
  const response = GETrequest(`/transactions/optionchanges/candidates${query}`);
  return {
    type: BULK_OPTION_CHANGE_CANDIDATES_GET,
    payload: response,
  };
}

export function createBulkOptionChange(data) {
  const payload = POSTrequest('/transactions/optionchanges/bulk', data);

  return {
    type: BULK_OPTION_CHANGE_CREATE,
    payload,
  };
}

export function transactionPreview(transactionType, accountId) {
  const payload = GETrequest(`/accounts/${accountId}/preview?type=${transactionType}`);

  return {
    type: ACCOUNT_TRANSACTION_PREVIEW,
    meta: { transactionType },
    payload,
  };
}

export function getTransferAccounts(accountId) {
  const payload = GETrequest(`/accounts/${accountId}/transferaccounts`);

  return {
    type: TRANSFER_ACCOUNTS_GET,
    payload,
    meta: { sourceAccount: parseInt(accountId) },
  };
}

export function accountInvestmentPathGet(accountId) {
  const payload = GETrequest(`/accounts/${accountId}/template`);

  return {
    type: ACCOUNT_INVESTMENT_PATH_GET,
    payload,
  };
}

export function getPRRDates(accountId) {
  const payload = GETrequest(`/accounts/${accountId}/PRRDates`);
  return {
    type: GET_PRR_DATES,
    payload,
  };
}

export function getPRR(accountId, startDate, endDate) {
  const body = { startDate, endDate };
  const payload = POSTrequest(`/accounts/${accountId}/PRR`, body);
  return {
    type: GET_PRR,
    payload,
  };
}

export function getCountries() {
  const response = GETrequest('/Parameters/Countries');
  return {
    type: COUNTRIES_LIST_GET,
    payload: response,
  };
}
