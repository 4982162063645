/*
*
* OptionChanges Component
*
*/
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AccountsInGroup from './AccountsInGroup';
import NewOptions from './NewOptions';
import OptionChangeDetails from './OptionChangeDetails';

import {
  getAccounts,
  getAccountTransactionsDetails,
  getOptionChangeDetails,
} from '../../actions';
import { Breadcrumbs } from '@frontend/common';
import { getAccountBaseRoute } from 'components/Features/protected/Accounts/helpers';

import styles from './styles.module.css';

const select = (state) => ({
  accountList: state.accounts.accountList,
  optionChangeDetails: state.accounts.optionChangeDetails,
});

export class OptionChanges extends React.Component {

  static propTypes = {
    accountList: PropTypes.array.isRequired,
    getAccounts: PropTypes.func.isRequired,
    getAccountTransactionsDetails: PropTypes.func.isRequired,
    getOptionChangeDetails: PropTypes.func.isRequired,
    optionChangeDetails: PropTypes.object.isRequired
  };

  state = {
    accountsInGroupModalOpen: false,
    isViewing: window.location.href.search('view') > -1,
    loading: false,
  };

  accountsInGroupModalShow() {
    this.setState({ accountsInGroupModalOpen: true });
  }

  optionChangeDetailsGet() {
    const { accountId, groupId } = this.props.match.params;

    this.setState({ loading: true });

    if (this.props.accountList.length === 0) {
      this.props.getAccounts()
        .then(() => {
          this.props.getAccountTransactionsDetails(groupId, accountId)
            .then(() => {
              this.setState({ loading: false });
              this.props.getOptionChangeDetails(accountId);
            })
            .catch(() => this.setState({ loading: false }));
        })
        .catch(() => this.setState({ loading: false }));
    }
    else {
      this.props.getAccountTransactionsDetails(groupId, accountId)
        .then(() => {
          this.setState({ loading: false });
          this.props.getOptionChangeDetails(accountId);
        })
        .catch(() => this.setState({ loading: false }));
    }

  }

  componentDidMount() {
    if (this.props.optionChangeDetails.accountsInGroup > 1) {
      this.accountsInGroupModalShow();
    }
    if (parseInt(this.props.optionChangeDetails.accountId) !== parseInt(this.props.match.params.accountId)) {
      this.optionChangeDetailsGet();
    }
  }

  render() {
    const { optionChangeDetails } = this.props;

    return (
      <div className={styles.OptionChanges_container}>
        <div className={`${styles.OptionChanges_pageNav} hideOnPrint`}>
          <Breadcrumbs
            crumbs={[
              {
                title: 'Accounts',
                link: '/accounts'
              },
              {
                title: 'Details',
                link: getAccountBaseRoute(this.props.match.params),
              },
              {
                title: this.state.isViewing ? 'View Investment Option' : 'Change Investment Option',
              },
            ]}
          />
        </div>

        <div className={styles.OptionChanges_optionChangeDetails}>
          <OptionChangeDetails
            accountsInGroupModalShow={() => this.accountsInGroupModalShow()}
            loading={this.state.loading}
            key={`${optionChangeDetails.accountId}_OptionChangeDetails`}
          />
        </div>

        <div className={styles.OptionChanges_newOptionChanges}>
          <NewOptions
            {...this.props}
            loading={this.state.loading}
            key={`${optionChangeDetails.accountId}_NewOptions`}
          />
        </div>

        <AccountsInGroup
          key={`${optionChangeDetails.accountId}_AccountsInGroup`}
          loading={this.state.loading}
          open={this.state.accountsInGroupModalOpen}
          onClose={() => this.setState({ accountsInGroupModalOpen: false })}
        />
      </div>
    );
  }
}

export default withRouter(connect(select, {
  getAccounts,
  getAccountTransactionsDetails,
  getOptionChangeDetails,
})(OptionChanges));