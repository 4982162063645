import React from 'react';
import PropTypes from 'prop-types';
import { PrrCalculatorText } from '../text';
import { IconBtnTooltip } from '@frontend/common';
import {
  Drawer,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  Typography,
} from '@mui/material';

import styles from './styles.module.css';

export function LearnMoreDrawer({ open, onClose }) {

  return (
    <Drawer anchor='bottom' open={open} onClose={onClose} sx={{ zIndex: '1301' }}>
      <div className={styles.learnMoreContainer}>
        <div className={styles.closeIcon}>
          <IconBtnTooltip
            icon='close'
            onClick={onClose}
          />
        </div>
        <hr className={styles.divider} />
        <h3 className={styles.learnMoreInfoHeader}>{PrrCalculatorText.learn_more_title}</h3>
        <div className={styles.learnMoreInfo}>
          {PrrCalculatorText.text_learn_more()}
        </div>
      </div>
      {PrrCalculatorText.text_faq.map(qa => {
        return (
          <Accordion key={qa.question}>
            <AccordionSummary
              expandIcon={<Icon>expand_more</Icon>}
              id='panel1a-header'
            >
              <Typography>
                <span className={styles.question}>
                  {qa.question}
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <span className={styles.answer}>
                  {qa.answer}
                </span>
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Drawer>
  );
}

LearnMoreDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.shape({
    PrrCalculator: PropTypes.shape({
      learn_more_title: PropTypes.string.isRequired,
      text_faq: PropTypes.array.isRequired,
      text_learn_more: PropTypes.func.isRequired,
    }).isRequired,
  }),
};

export default LearnMoreDrawer;
