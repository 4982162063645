import React from 'react';
import PropTypes from 'prop-types';

import {
  InfoIcon,
} from '@frontend/common';

import {
  Icon,
} from '@mui/material';

import { permissionGet } from 'utils/config/permissions_details';

import styles from './styles.module.css';

export class Permissions extends React.Component {
  static propTypes = {
    permissions: PropTypes.object.isRequired,
  };

  permissionCompose(permissionName, enabled) {
    const permission = permissionGet(permissionName);
    /* eslint-disable indent */
    return (
      <div className={styles.MyInfo_permission}>
        <div>
          <span>{permission.label}</span>
          <InfoIcon message={permission.description || ''} />
        </div>
        <Icon>
          {enabled
            ? <span className={styles.MyInfo_permissionEnabled}>done</span>
            : <span className={styles.MyInfo_permissionDisabled}>close</span>
          }
        </Icon>
      </div>
    );
    /* eslint-enable indent */
  }

  render() {
    const { permissions } = this.props;

    return (
      <>
        <h2 className={styles.MyInfo_permissionsTitle}>Permissions</h2>
        <div className={styles.MyInfo_permissionsContainer}>
          <div className={styles.MyInfo_permissionsColumn1}>
            <div className={styles.MyInfo_permissionSection}>
              <div>Transactions</div>
              <hr />
              {this.permissionCompose('AllowContribute', permissions.AllowContribute)}
              {this.permissionCompose('AllowOptionChange', permissions.AllowOptionChange)}
              {this.permissionCompose('AllowTransfer', permissions.AllowTransfer)}
              {this.permissionCompose('AllowWithdraw', permissions.AllowWithdraw)}
            </div>
            <div className={styles.MyInfo_permissionSection}>
              <div>Tax Documents</div>
              <hr />
              {this.permissionCompose('AllowViewStateTaxDocuments', permissions.AllowViewStateTaxDocuments)}
              {this.permissionCompose('AllowViewFederalTaxDocuments', permissions.AllowViewFederalTaxDocuments)}
            </div>
          </div>
          <div className={styles.MyInfo_permissionsColumn2}>
            <div className={styles.MyInfo_permissionSection}>
              <div>Administration</div>
              <hr />
              {this.permissionCompose('AdvisorEdit', permissions.AdvisorEdit)}
              {this.permissionCompose('TemplateEdit', permissions.TemplateEdit)}
              {this.permissionCompose('DataImportSetup', permissions.DataImportSetup)}
              {this.permissionCompose('DataImport', permissions.DataImport)}
            </div>
            <div className={styles.MyInfo_permissionSection}>
              <div>Reports</div>
              <hr />
              {this.permissionCompose('AccountReports', permissions.AccountReports)}
              {this.permissionCompose('AdminReports', permissions.AdminReports)}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Permissions;
