import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DashboardHeader from './DashboardHeader';
import DashboardMetricsToolbar from './DashboardMetricsToolbar';
import BeneficiaryAgeBarChart from './BeneficiaryAgeBarChart';
import Banner from 'components/Features/Banner';
import { BANNER_LOCATIONS } from 'components/AppRoot/StaticResources/constants';
import qs from 'qs';
import { getAccounts } from 'components/Features/protected/Accounts/actions';
import { portfolioProvidersGet } from 'components/Features/protected/ManageData/actions';
import { getAgeBrackets } from 'components/AppRoot/StaticResources/actions';
import { selectedBeneficiaryBarChartType } from './constants';
import { accountsByAgeFilter, accountsByAgeBandFilter, accountsMetricTransformer } from 'utils/helpers/data_filters';

const select = (state) => ({
  accountList: state.accounts.accountList,
  selectedBeneficiaryBarChart: state.dashboardSettings.selectedBeneficiaryBarChart,
  ageBrackets: state.static.ageBrackets,
  webMessages: state.static.webMessages,
});

export class Dashboard extends Component {
  static propTypes = {
    getAccounts: PropTypes.func.isRequired,
    getAgeBrackets: PropTypes.func.isRequired,
    portfolioProvidersGet: PropTypes.func.isRequired,
    accountList: PropTypes.array,
    ageBrackets: PropTypes.array,
    selectedBeneficiaryBarChart: selectedBeneficiaryBarChartType,
    webMessages: PropTypes.object.isRequired,
  };

  state = {
    allAccountMetrics: {},
    accountsByAge: [],
    accountsByAgeBand: [],
    loading: false,
    activeIndex: -1,
  };

  /**
   * Changes the activeIndex for the Metrics Toolbar to know which item in the graph the user's mouse is currently over.
   * @param index {number} index of the item currently active
   */
  onMouseEnterBar = (index) => {
    if (index !== this.state.activeIndex) {
      this.setState({ activeIndex: index });
    }
  }

  /**
   * Resets the activeIndex to -1, which indicates to Metrics Toolbar to switch back to all account data for metrics.
   */
  onMouseLeaveBar = () => {
    this.setState({ activeIndex: -1 });
  }

  /**
   * Redirects to account list with filters applied to data
   */
  onMouseClickBar = () => {
    const { selectedBeneficiaryBarChart } = this.props;
    const { activeIndex } = this.state;
    const value = this.state[selectedBeneficiaryBarChart][activeIndex].key;
    const key = selectedBeneficiaryBarChart === 'accountsByAge' ? 'age' : 'ageGroup';
    this.props.history.push({
      pathname: '/accounts',
      search: `?${qs.stringify({ [key]: value })}`
    });
  }

  /**
   * Handles initial setup of data for graph and and metrics toolbar
   */
  setupChartData() {
    if (!this.props.accountList || this.props.accountList.length === 0 ||
      !this.props.ageBrackets || this.props.ageBrackets.length === 0) {
      this.setState({ loading: true });
      Promise.all([ this.props.getAgeBrackets(), this.props.getAccounts(), this.props.portfolioProvidersGet() ])
        .then(() => {
          this.setState({
            accountsByAge: accountsByAgeFilter(this.props.accountList),
            accountsByAgeBand: accountsByAgeBandFilter(this.props.accountList, this.props.ageBrackets),
            allAccountMetrics: accountsMetricTransformer(this.props.accountList),
            loading: false,
          });
        })
        .catch(() => this.setState({ loading: false }));
    }
    else {
      this.setState({
        accountsByAge: accountsByAgeFilter(this.props.accountList),
        accountsByAgeBand: accountsByAgeBandFilter(this.props.accountList, this.props.ageBrackets),
        allAccountMetrics: accountsMetricTransformer(this.props.accountList),
      });
    }
  }

  componentDidMount() {
    this.setupChartData();
  }

  render() {
    const { webMessages } = this.props;

    return (
      <div>
        <Banner show={Boolean(webMessages[BANNER_LOCATIONS.HOME])} body={webMessages[BANNER_LOCATIONS.HOME]} />
        <DashboardHeader />
        <DashboardMetricsToolbar
          {...this.state}
        />
        <BeneficiaryAgeBarChart
          {...this.state}
          onMouseEnterBar={this.onMouseEnterBar}
          onMouseLeaveBar={this.onMouseLeaveBar}
          onMouseClickBar={this.onMouseClickBar}
        />
      </div>
    );
  }
}

export default withRouter(connect(select, {
  getAccounts,
  getAgeBrackets,
  portfolioProvidersGet,
})(Dashboard));
