/*
*
* AccountDetailsCard Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  currencyFormatter,
  InfoIcon,
} from '@frontend/common';
import styles from './styles.module.css';
import AvailableBalanceInfo from '../../AvailableBalanceInfo';

AccountDetailsCard.propTypes = {
  account: PropTypes.object,
};

AccountDetailsCard.defaultProps = {
  account: {
    accountOwner: {
      name: ''
    },
    beneficiary: {
      name: ''
    },
    details: {
      accountNumber: '',
      accountBalance: '',
      accountType: '',
      netAvailable: 0
    }
  }
};

export function AccountDetailsCard({ account }) {
  const availableBalanceMsg = (
    <div>
      The available balance is the current total value of the account minus pending transactions out of the account - such as withdrawals, transfers, contribution reversals, contributions that have not cleared the bank, or fees.

      <AvailableBalanceInfo />
    </div>
  );

  return (
    <div className={styles.AccountDetailsCard_container}>
      <Card
        className={styles.AccountDetailsCard_accountDetailsCard}
        title='Account Details'
      >
        <div className={styles.AccountDetailsCard_accountDetails}>
          <div className={styles.AccountDetailsCard_accountDetailsAO}>
            <div className={styles.AccountDetailsCard_detailsTitle}>Account Owner</div>
            {account.accountOwner.name}
          </div>
          <div className={styles.AccountDetailsCard_accountDetailsBen}>
            <div className={styles.AccountDetailsCard_detailsTitle}>Beneficiary</div>
            {account.beneficiary.name}
          </div>
          <div className={styles.AccountDetailsCard_accountDetailsAcctNum}>
            <div className={styles.AccountDetailsCard_detailsTitle}>Account Number</div>
            {account.details.accountNumber}
          </div>
          <div className={styles.AccountDetailsCard_accountDetailsTotalVal}>
            <div className={styles.AccountDetailsCard_detailsTitle}>Total Value</div>
            {account.details.accountBalance}
          </div>
          <div className={styles.AccountDetailsCard_accountDetailsAcctType}>
            <div className={styles.AccountDetailsCard_detailsTitle}>Account Type</div>
            {account.details.accountType}
          </div>
          <div className={styles.AccountDetailsCard_accountDetailsAvailableBalance}>
            <div className={styles.AccountDetailsCard_detailsTitle}>
              Available Balance
              <InfoIcon message={availableBalanceMsg} />
            </div>
            {currencyFormatter(account.details.netAvailable)}
          </div>
        </div>
      </Card>
    </div>
  );
}

export default AccountDetailsCard;
