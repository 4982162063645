/*
*
* NewAccounts Actions
*
*/
import { GETrequest, POSTrequest, PUTrequest, DELETErequest } from 'utils/helpers/api_handler';
import {
  NEW_ACCOUNTS_LIST_GET,
  ACCOUNTS_UPLOAD,
  NEW_ACCOUNT_DETAILS_GET,
  NEW_ACCOUNT_UPDATE,
  NEW_ACCOUNT_APPROVE,
  NEW_ACCOUNT_DOWNLOAD,
  RESOLVE_BIRTHDATE,
  RESOLVE_EMAIL,
  RESOLVE_POA_LEVEL,
  RESOLVE_SSN,
  RESOLVE_FED_TAX,
  RESOLVE_STATE_TAX,
  BLANK_ACCOUNT_GET,
  SINGLE_ACCOUNT_CREATE,
  NEW_ACCOUNT_RESET,
  NEW_ACCOUNT_DELETE,
} from './constants';

export function newAccountsListGet() {
  const response = GETrequest('/accounts/new-accounts');

  return {
    type: NEW_ACCOUNTS_LIST_GET,
    payload: response,
  };
}

export function accountsUpload(formData) {
  const response = POSTrequest('/accounts/new-accounts/upload', formData);

  return {
    type: ACCOUNTS_UPLOAD,
    payload: response,
  };
}

export function newAccountDetailsGet(id) {
  const response = GETrequest(`/accounts/new-accounts/${id}`);

  return {
    type: NEW_ACCOUNT_DETAILS_GET,
    payload: response,
  };
}

export function newAccountUpdate(id, newAccount) {
  const response = PUTrequest(`/accounts/new-accounts/${id}`, newAccount);

  return {
    type: NEW_ACCOUNT_UPDATE,
    payload: response,
  };
}

export function newAccountApprove(id) {
  const response = POSTrequest(`/accounts/new-accounts/approve/${id}`);

  return {
    type: NEW_ACCOUNT_APPROVE,
    payload: response,
  };
}

export function newAccountDownload(id) {
  const response = GETrequest(`/accounts/new-accounts/${id}/downloadforms`, { responseType: 'blob' });

  return {
    type: NEW_ACCOUNT_DOWNLOAD,
    payload: response,
  };
}

export function resolveBirthdate(ssn, birthdate) {
  const response = POSTrequest('/accounts/new-accounts/resolve/birthdate', {
    rightSsn: ssn,
    rightBirthdate: birthdate,
  });

  return {
    type: RESOLVE_BIRTHDATE,
    payload: response,
  };
}

export function resolveEmail(ssn, email) {
  const response = POSTrequest('/accounts/new-accounts/resolve/email', {
    rightSsn: ssn,
    rightEmail: email,
  });

  return {
    type: RESOLVE_EMAIL,
    payload: response,
  };
}

export function resolvePOALevel(ssn, otherSSN, poaLevel) {
  const response = POSTrequest('/accounts/new-accounts/resolve/poa', {
    agentSsn: ssn,
    beneficiarySsn: otherSSN,
    rightPOALevel: poaLevel,
  });

  return {
    type: RESOLVE_POA_LEVEL,
    payload: response,
  };
}

export function resolveSSN(email, correctSSN) {
  const response = POSTrequest('/accounts/new-accounts/resolve/email', {
    rightSsn: correctSSN,
    rightEmail: email,
  });

  return {
    type: RESOLVE_SSN,
    payload: response,
  };
}

export function resolveFedTax(agentSsn, beneficiarySsn, rightFedDocs) {
  const payload = POSTrequest('/accounts/new-accounts/resolve/fed', { agentSsn, beneficiarySsn, rightFedDocs });

  return {
    type: RESOLVE_FED_TAX,
    payload,
  };
}

export function resolveStateTax(agentSsn, beneficiarySsn, rightStateDocs) {
  const payload = POSTrequest('/accounts/new-accounts/resolve/state', { agentSsn, beneficiarySsn, rightStateDocs });

  return {
    type: RESOLVE_STATE_TAX,
    payload,
  };
}

export function blankAccountGet() {
  const response = GETrequest('/accounts/new-accounts/new');
  return {
    type: BLANK_ACCOUNT_GET,
    payload: response,
  };
}

export function singleAccountCreate(account) {
  const response = POSTrequest('/accounts/new-accounts', account);

  return {
    type: SINGLE_ACCOUNT_CREATE,
    payload: response,
  };
}

export function newAccountReset(id) {
  const response = POSTrequest(`/accounts/new-accounts/reset/${id}`);

  return {
    type: NEW_ACCOUNT_RESET,
    payload: response,
  };
}

export function newAccountDelete(id) {
  const response = DELETErequest(`/accounts/new-accounts/${id}`);

  return {
    type: NEW_ACCOUNT_DELETE,
    payload: response,
  };
}