/*
*
* NewAccounts Constants
*
*/
export const NEW_ACCOUNTS_LIST_GET = 'src/components/Features/protected/NewAccounts/NEW_ACCOUNTS_LIST_GET';
export const ACCOUNTS_UPLOAD = 'src/components/Features/protected/NewAccounts/ACCOUNTS_UPLOAD';
export const NEW_ACCOUNT_DETAILS_GET = 'src/components/Features/protected/NewAccounts/NEW_ACCOUNT_DETAILS_GET';
export const NEW_ACCOUNT_UPDATE = 'src/components/Features/protected/NewAccounts/NEW_ACCOUNT_UPDATE';
export const NEW_ACCOUNT_APPROVE = 'src/components/Features/protected/NewAccounts/NEW_ACCOUNT_APPROVE';
export const NEW_ACCOUNT_DOWNLOAD = 'src/components/Features/protected/NewAccounts/NEW_ACCOUNT_DOWNLOAD';

export const RESOLVE_BIRTHDATE = 'src/components/Features/protected/NewAccounts/RESOLVE_BIRTHDATE';
export const RESOLVE_EMAIL = 'src/components/Features/protected/NewAccounts/RESOLVE_EMAIL';
export const RESOLVE_POA_LEVEL = 'src/components/Features/protected/NewAccounts/RESOLVE_POA_LEVEL';
export const RESOLVE_SSN = 'src/components/Features/protected/NewAccounts/RESOLVE_SSN';
export const RESOLVE_FED_TAX = 'src/components/Features/protected/NewAccounts/RESOLVE_FED_TAX';
export const RESOLVE_STATE_TAX = 'src/components/Features/protected/NewAccounts/RESOLVE_STATE_TAX';

export const BLANK_ACCOUNT_GET = 'src/components/Features/protected/NewAccounts/BLANK_ACCOUNT_GET';
export const SINGLE_ACCOUNT_CREATE = 'src/components/Features/protected/NewAccounts/SINGLE_ACCOUNT_CREATE';
export const NEW_ACCOUNT_RESET = 'src/components/Features/protected/NewAccounts/NEW_ACCOUNT_RESET';
export const NEW_ACCOUNT_DELETE = 'src/components/Features/protected/NewAccounts/NEW_ACCOUNT_DELETE';

export const statuses = {
  LOADED: 'Loaded',
  ADVISOR_APPROVED: 'Advisor Approved',
  SECURITY_FAILURE: 'Security Failure',
  EXPIRED: 'Expired',
  CLIENT_REJECTED: 'Client Rejected',
  CLIENT_APPROVED: 'Client Approved',
};