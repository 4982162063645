import { cloneDeep } from 'lodash';

export function getTitle(path) {
  
  // Priority is placed to titles placed LOWER on this list
  const titles = [
    { matcher: /^\/$/, title: 'Home' },
    { matcher: '/accounts', title: 'Accounts' },
    { matcher: '/new-accounts', title: 'New Accounts' },
    { matcher: '/notfound', title: 'Page Not Found' },
    { matcher: '/templates', title: 'Customized Templates' },
    { matcher: /^\/accounts\/[0-9]*$/, title: 'Account Details' },
    { matcher: /^\/accounts\/[0-9]*\/option-changes/, title: 'Option Changes' },
    { matcher: /^\/accounts\/[0-9]*\/contributions/, title: 'Contributions' },
    { matcher: /^\/accounts\/[0-9]*\/withdrawals/, title: 'Withdrawals' },
    { matcher: '/manage-lpoa', title: 'Manage LPOA' },
    { matcher: '/manage-users', title: 'Manage Users' },
    { matcher: '/my-info', title: 'My Info' },
    { matcher: '/reports', title: 'Reports' },
    { matcher: '/manage-data', title: 'Manage Data' },
    { matcher: '/manage-data/download', title: 'Download Data' },
    { matcher: '/lpoa-settings', title: 'LPOA Settings' },
    { matcher: '/bulk-option-change', title: 'Bulk Option Change' },
    { matcher: '/bulk-statement-download', title: 'Bulk Statement Download' },
  ];

  let currentTitle = '';
  const rootTitle = 'my529 Financial Advisor Portal';

  cloneDeep(titles).reverse().some(title => { // eslint-disable-line newline-per-chained-call
    if (path.match(title.matcher)) {
      currentTitle = title.title;
      return true;
    }

    return false;
  });

  document.title = currentTitle ? `${currentTitle} | ${rootTitle}` : rootTitle;
  return currentTitle || rootTitle;
}