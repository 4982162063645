/*
*
* TargetAccount Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { TransferInputTypes } from '../typeConstants';
import { formatAccountDisplayText } from '../index';

import {
  Icon,
  IconButton,
} from '@mui/material';
import {
  BaseNumberInput,
  CurrencyInput,
  Dropdown,
} from '@frontend/common';

import styles from '../TransferStepOneContent/styles.module.css';

export function parseFloatWrapper(callback) {
  return ({ floatValue, ...etc }) => {
    callback({
      ...etc,
      value: floatValue,
    });
  };
}

const noop = () => null;

TargetAccount.propTypes = {
  accountOptions: PropTypes.array,
  index: PropTypes.number,
  target: PropTypes.object,
  onAccountChange: PropTypes.func,
  canRemove: PropTypes.bool,
  onRemove: PropTypes.func,
  accountError: PropTypes.string,
  inputError: PropTypes.string,
  inputFieldType: PropTypes.oneOf([TransferInputTypes.Amount, TransferInputTypes.Percentage]),
};

TargetAccount.defaultProps = {
  accountOptions: [],
  index: 0,
  target: { AccountId: '', Amount: 0, Percentage: 0 },
  canRemove: false,
  inputFieldType: TransferInputTypes.Amount,
  onAccountChange: noop,
  onRemove: noop,
  accountError: '',
  inputError: '',
};

export function TargetAccount({
  index,
  accountOptions,
  target,
  inputFieldType,
  onAccountChange,
  canRemove,
  onRemove,
  accountError,
  inputError,
}) {
  return (
    <div className={`${styles.Transfer_destinationRow} ${styles[`row-${index}`]}`}>
      <Dropdown
        label='Destination Account'
        errorText={accountError}
        value={target.AccountId}
        onChange={value => onAccountChange({ ...target, AccountId: value })}
        options={accountOptions.map(account => ({
          value: account.AccountId,
          display: formatAccountDisplayText(account),
          props: {
            disabled: account.disabled,
          },
        }))}
        FormControlProps={{ style: { width: '200px' } }}
        InputLabelProps={{ htmlFor: `destinationSelect-${index}` }}
        SelectProps={{
          inputProps: {
            id: `destinationSelect-${index}`,
            name: 'destinationSelect',
          },
        }}
      />
      {inputFieldType === TransferInputTypes.Amount ?
        <CurrencyInput
          className={styles.Transfer_numberInput}
          label={TransferInputTypes.Amount}
          value={target.Amount}
          onChange={parseFloatWrapper(({ value }) => onAccountChange({
            ...target,
            Amount: value,
          }))}
          errorText={inputError}
        />
        :
        <BaseNumberInput
          className={styles.Transfer_numberInput}
          label={TransferInputTypes.Percentage}
          value={target.Percentage}
          onChange={({ value, }) => onAccountChange({
            ...target,
            Percentage: parseInt(value.replace('.', '')),
          })}
          isNumericFormat
          inputProps={{
            suffix: '%',
            allowNegative: false,
          }}
          errorText={inputError}
        />
      }
      {canRemove &&
      <div>
        <IconButton onClick={onRemove}>
          <Icon>remove_circle</Icon>
        </IconButton>
      </div>}
    </div>
  );
}

export default TargetAccount;
