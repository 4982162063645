import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cloneDeep, includes, partition } from 'lodash';
import dayjs from 'dayjs';

import {
  properCase,
  IconBtnTooltip,

  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';

import {
  Avatar,
  Chip,
  Icon,
  MenuItem,
} from '@mui/material';

import { getAgeBrackets } from 'components/AppRoot/StaticResources/actions';
import { getAccounts } from 'components/Features/protected/Accounts/actions';
import { accountsByQueryFilters, queryParser, filtersToSearchString } from 'utils/helpers/data_filters';
import { getAccountBaseRoute } from 'components/Features/protected/Accounts/helpers';

import events from 'utils/ga';

import styles from './styles.module.css';

const select = (state) => ({
  accountList: state.accounts.accountList,
  ageBrackets: state.static.ageBrackets,
});


export class AccountsList extends Component {

  static propTypes = {
    getAccounts: PropTypes.func.isRequired,
    accountList: PropTypes.array,
    getAgeBrackets: PropTypes.func.isRequired,
    ageBrackets: PropTypes.array,
    accountActionHandle: PropTypes.func.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
  };

  state = {
    search: this.props.location.search,
    filters: queryParser(this.props.location.search),
    filterChips: [],
    showAllChips: false,
    menuItemsSelected: [],
    accounts: [],
    loading: false,
  };

  static setupFilterChips(ageBrackets, filters) {
    return [
      {
        label: 'Upcoming Birthdays (30 days)',
        key: 'upcomingBirthdaysDuration',
        avatar: (
          <Avatar>
            <Icon>cake</Icon>
          </Avatar>
        ),
        isActive: filters => filters.upcomingBirthdaysDuration !== null,
        addFilter: filters => ({
          ...filters,
          upcomingBirthdaysDuration: dayjs.duration('P30D')
        }),
        removeFilter: filters => ({
          ...filters,
          upcomingBirthdaysDuration: null
        })
      },
      {
        label: 'Zero Balance Accounts',
        key: 'zeroBalances',
        avatar: (
          <Avatar>
            <Icon>attach_money</Icon>
          </Avatar>
        ),
        isActive: filters => filters.zeroBalances,
        addFilter: filters => ({
          ...filters,
          zeroBalances: true
        }),
        removeFilter: filters => ({
          ...filters,
          zeroBalances: false
        })
      },
      ...(filters.ages.map((age) => ({
        label: `Age ${age}`,
        key: `age-${age}`,
        isActive: filters => includes(filters.ages, `${age}`),
        addFilter: filters => ({
          ...filters,
          ages: [
            ...filters.ages,
            `${age}`
          ]
        }),
        removeFilter: filters => ({
          ...filters,
          ages: filters.ages.filter(ageFilter => ageFilter !== `${age}`)
        })
      }))),
      ...ageBrackets.map(ageGroup => ({
        label: ageGroup.longDisplayName,
        key: `ageGroup-${ageGroup.displayName}`,
        isActive: filters => includes(filters.ageGroups, ageGroup.displayName),
        addFilter: filters => ({
          ...filters,
          ageGroups: [
            ...filters.ageGroups,
            ageGroup.displayName
          ]
        }),
        removeFilter: filters => ({
          ...filters,
          ageGroups: filters.ageGroups.filter(ageGroupFilter => ageGroupFilter !== ageGroup.displayName)
        })
      }))
    ];
  }

  accountsGet() {
    if (this.props.accountList.length === 0) {
      this.setState({ loading: true });
      Promise.all([this.props.getAccounts(), this.props.getAgeBrackets()])
        .then(() => {
          this.setState({
            accounts: this.tableDataFormat(accountsByQueryFilters(this.props.accountList, this.state.filters)),
            filterChips: AccountsList.setupFilterChips(this.props.ageBrackets, this.state.filters),
            loading: false,
          });
        })
        .catch(() => null);
    }
    else {
      this.setState({
        accounts: this.tableDataFormat(accountsByQueryFilters(this.props.accountList, this.state.filters)),
        filterChips: AccountsList.setupFilterChips(this.props.ageBrackets, this.state.filters),
      });
    }
  }

  tableDataFormat(accounts) {
    const formattedAccounts = cloneDeep(accounts);

    formattedAccounts.forEach(account => {
      account.AgentName = properCase(`${account.AgtFirst} ${account.AgtLast}`);
      account.BeneficiaryName = properCase(`${account.BenFirst} ${account.BenLast}`);
      account.PcName = properCase(`${account.PcFirstName} ${account.PcLastName}`);
    });

    return formattedAccounts;
  }

  addFilter = (filterChip) => {
    const newFilters = filterChip.addFilter(this.state.filters);
    this.setState({
      filters: newFilters
    });
    this.props.history.push({
      pathname: '/accounts',
      search: `?${filtersToSearchString(newFilters)}`
    });
  };

  removeFilter = (filterChip) => {
    const newFilters = filterChip.removeFilter(this.state.filters);
    this.setState({
      filters: newFilters,
    });
    this.props.history.push({
      pathname: '/accounts',
      search: `?${filtersToSearchString(newFilters)}`
    });
  };

  renderFilterComponent = () => {
    const { showAllChips, filters, filterChips } = this.state;
    const chipPartition = partition(filterChips, chip => chip.isActive(filters));
    return (
      <div className={styles.TableToolbar_toolbarContainer}>
        <div className={styles.TableToolbar_iconMenu}>
          <IconBtnTooltip
            icon='filter_list'
            onClick={() => this.setState({ showAllChips: !showAllChips })}
            title='Filter'
          />
          <div className={`${styles.TableToolbar_menu} ${showAllChips ? styles.show : ''}`}>
            {filterChips.map(filterChip => (
              <MenuItem
                key={filterChip.key}
                onClick={() => {
                  this.setState({ showAllChips: false });
                  filterChip.isActive(filters) ? this.removeFilter(filterChip) : this.addFilter(filterChip);
                }}
                style={filterChip.isActive(filters) ? { color: 'var(--accent)', fontWeight: 'bold' } : {}}
              >
                {filterChip.label}
              </MenuItem>
            ))}
          </div>
          <div
            className={`${styles.TableToolbar_menuOverlay} ${showAllChips ? styles.show : ''}`}
            onClick={() => this.setState({ showAllChips: false })}
          />
        </div>
        {chipPartition[0].map(filterChip => (
          <Chip
            key={filterChip.key}
            label={filterChip.label}
            avatar={filterChip.avatar}
            deleteIcon={<Icon>close</Icon>}
            onDelete={() => this.removeFilter(filterChip)}
            style={{ margin: '2px 5px 2px 0', color: '#fff' }}
          />
        ))}
      </div>
    );
  }

  componentDidMount() {
    this.accountsGet();
  }

  componentDidUpdate() {
    const { location: { search }, accountList } = this.props;
    if (search.length > 0 && search !== this.state.search) {
      const newFilters = queryParser(search);
      this.setState({
        search,
        filters: newFilters,
        accounts: this.tableDataFormat(accountsByQueryFilters(accountList, newFilters)),
      });
    }
  }

  render() {
    return (
      <SmartTable
        idKey='AccountId'
        rows={this.state.accounts}
        loading={this.state.loading}
        columns={[
          {
            key: 'AcctNumber',
            title: 'Account Number',
            type: 'string',
            hideOn: ['phone'],
            customStyle: { width: '.75fr' },
          },
          {
            key: 'AgentName',
            title: 'Account Owner / Agent',
            type: 'string',
          },
          {
            key: 'BeneficiaryName',
            title: 'Beneficiary',
            type: 'string',
            hideOn: ['phone'],
          },
          {
            key: 'BenBirthDate',
            title: 'Beneficiary DOB',
            type: 'dateString',
            hideOn: ['phone', 'tablet'],
            customStyle: { width: '.75fr' },
          },
          {
            key: 'AcctOption',
            title: 'Investment Option',
            type: 'string',
            hideOn: ['phone', 'tablet'],
            customStyle: { width: '1.25fr' },
          },
          {
            key: 'PcName',
            title: 'Primary Contact',
            type: 'string',
            hideOn: ['phone', 'tablet'],
          },
          {
            key: 'FaLevel',
            title: 'Access',
            type: 'string',
            hideOn: ['phone', 'tablet'],
            customStyle: { width: '.5fr' },
            format: data => `Level ${data}`,
          },
          {
            key: 'AcctBalance',
            title: 'Market Value',
            type: 'currency',
            customStyle: { width: '.75fr' }
          },
        ]}
        actions={[
          {
            displayName: 'View Details',
            type: 'menu',
            onSelect: row => this.props.history.push(getAccountBaseRoute({ agentId: row.AgentId, groupId: row.AccountGroupId, accountId: row.AccountId })),
          },
          {
            displayName: 'Option Changes',
            type: 'menu',
            onSelect: row => this.props.accountActionHandle('optionChanges', row.AccountId, row.AccountGroupId, row.AgentId),
            showIf: row => JSON.parse(row.OptionChange),
          },
          {
            displayName: 'Contributions',
            type: 'menu',
            onSelect: row => this.props.accountActionHandle('contributions', row.AccountId, row.AccountGroupId, row.AgentId),
            showIf: row => JSON.parse(row.Contribute),
          },
          {
            displayName: 'Transfers',
            type: 'menu',
            onSelect: row => this.props.accountActionHandle('transfers', row.AccountId, row.AccountGroupId, row.AgentId),
            showIf: row => JSON.parse(row.Transfer),
          },
          {
            displayName: 'Withdrawals',
            type: 'menu',
            onSelect: row => this.props.accountActionHandle('withdrawals', row.AccountId, row.AccountGroupId, row.AgentId),
            showIf: row => JSON.parse(row.Withdraw),
          },
          {
            displayName: 'Gifting',
            type: 'menu',
            onSelect: row => this.props.accountActionHandle('gifting', row.AccountId, row.AccountGroupId, row.AgentId),
            showIf: row => JSON.parse(row.FaLevel) > 1,
          },
          {
            displayName: 'Documents',
            type: 'menu',
            onSelect: row => this.props.accountActionHandle('documents', row.AccountId, row.AccountGroupId, row.AgentId),
          },
        ]}
      >
        <TableToolbar
          customToolbarSection={this.renderFilterComponent}
          onSearchFocus={() => events.tableSearchAccessed('Accounts List', window.location.pathname)}
        />
        <TableContainer maxHeight='100%' minWidth='100%'>
          <TableHeader />
          <TableRows />
        </TableContainer>
        <TablePagination />
      </SmartTable>
    );
  }
}

export default withRouter(connect(select, { getAccounts, getAgeBrackets })(AccountsList));
