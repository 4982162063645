/*
*
* AccountsInGroup Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  
  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
} from '@frontend/common';

AccountsInGroup.propTypes = {
  groupAccounts: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onProportionalSelect: PropTypes.func.isRequired,
};

export function AccountsInGroup({ groupAccounts, loading, onClose, onProportionalSelect, open }) {
  
  const tableData = groupAccounts.map(account => ({
    accountId: account.accountId,
    accountNumber: account.accountNumber,
    availableBalance: account.availableBalance.number,
    optionDescription: account.optionDescription,
  }));
  
  return (
    <Modal
      actionButtons={[
        {
          label: 'From one account',
          action: onClose,
        },
        {
          label: window.innerWidth <= 1200 ? 'From all accounts' : 'From all displayed accounts',
          action: onProportionalSelect,
        }
      ]}
      show={open && !loading}
      title='Withdraw Funds'
      onCloseModal={onClose}
    >
      <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Withdrawing Funds from Multiple Accounts</p>
      <p>There are two ways to withdraw funds if you own multiple accounts for the same beneficiary:</p>
      <ol>
        <li>You may select one account and the amount you wish to withdraw or</li>
        <li>You may withdraw funds proportionally from all displayed accounts for the same beneficiary</li>
      </ol>
      <div style={{ minHeight: '175px' }}>
        <SmartTable
          idKey='accountId'
          rows={tableData}
          columns={[
            {
              key: 'accountNumber',
              title: 'Account Number',
              type: 'number',
            },
            {
              key: 'optionDescription',
              title: 'Current option',
              type: 'string',
              hideOn: ['phone'],
              customStyle: { width: '1.5fr' }
            },
            {
              key: 'availableBalance',
              title: 'Available',
              type: 'currency',
              hideOn: ['phone'],
            },
          ]}
        >
          <TableContainer minWidth='100%'>
            <TableHeader />
            <TableRows />
          </TableContainer>
          <TablePagination />
        </SmartTable>
      </div>
    </Modal>
  );
}

export default AccountsInGroup;