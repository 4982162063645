/*
*
* Reports Actions
*
*/
import {
  REPORTS_GET,
  REPORT_RUN,
  REPORT_DOWNLOAD,
} from './constants';

import { GETrequest, POSTrequest } from 'utils/helpers/api_handler';

export function reportsGet() {
  const payload = GETrequest('/reports');

  return {
    type: REPORTS_GET,
    payload,
  };
}

export function reportRun(reportId, params) {
  const payload = POSTrequest(`/reports/${reportId}`, params);

  return {
    type: REPORT_RUN,
    payload,
  };
}

export function reportDownload(reportId, params) {
  const payload = POSTrequest(`/reports/${reportId}/download`, params);

  return {
    type: REPORT_DOWNLOAD,
    payload,
  };
}