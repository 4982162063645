/*
*
* TemplateActivate
*
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { activateTemplate } from 'components/Features/protected/Templates/actions';
import {
  customOptionsGet
} from 'components/AppRoot/StaticResources/actions';
import { ConfirmModal, notificationShow } from '@frontend/common';


export class TemplateActivate extends Component {

  static propTypes = {
    activateTemplate: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedTemplate: PropTypes.object.isRequired,
    templatesGet: PropTypes.func.isRequired,
    customOptionsGet: PropTypes.func.isRequired,
  };

  state = {
    activateModalLoading: false,
  };

  activateModalBodyCompose = () => {
    return `Activating '${this.props.selectedTemplate.name}' moves the template to the Active tab. It can be deactivated at any time, and investments that were set up using this template remain unaffected.`;
  }

  templateActivate = () => {
    this.setState({
      activateModalLoading: true,
    });

    this.props.activateTemplate(this.props.selectedTemplate.id)
      .then(() => {
        this.props.notificationShow('Template activated.', 'success');
        this.props.templatesGet();
        this.props.onClose();
      })
      .then(() => this.props.customOptionsGet()) // update custom templates
      .catch(() => null)
      .finally(() => this.setState({ activateModalLoading: false }));
  }

  render() {
    return (
      <ConfirmModal
        body={this.activateModalBodyCompose()}
        isLoading={this.state.activateModalLoading}
        onModalClose={this.props.onClose}
        onConfirm={(id) => this.templateActivate(id)}
        show={this.props.open}
        title='Activate Template?'
      />
    );
  }
}

export default connect(null, { 
  activateTemplate, 
  notificationShow,
  customOptionsGet,
})(TemplateActivate);
