import { cloneDeep } from 'lodash';
import { BENEFICIARY_BAR_CHART_CHANGE } from './constants';
import { VIEW_BY_AGE_BAND } from './constants';

const initialState = {
  selectedBeneficiaryBarChart: VIEW_BY_AGE_BAND
};

function DashboardSettingsReducer(state = initialState, action) {
  const newState = cloneDeep(state);

  switch (action.type) {
    case BENEFICIARY_BAR_CHART_CHANGE: {
      newState.selectedBeneficiaryBarChart = action.selectedBeneficiaryBarChart;
      return newState;
    }
    default: {
      return newState;
    }
  }
}

export default DashboardSettingsReducer;
