/*
*
* NewRecurring Component
*
*/
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clone } from 'lodash';
import dayjs from 'dayjs';
import {
  Card,
  currencyFormatter,
  CurrencyInput,
  DatePicker,
  LoadingOverlay,
  Dropdown,
} from '@frontend/common';
import {
  getNextRunDate
} from '../../../actions';
import {
  MONTHLY,
  TWICE_MONTHLY,
  DISABLED_DATES
} from '../constants';
import {
  Checkbox,
  Icon,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Step,
  StepButton,
  Stepper,
  Tooltip,
} from '@mui/material';
import TermsConditions from '../TermsConditions';

import events from 'utils/ga';

import transactionsStyles from '../../styles.module.css';
import styles from './styles.module.css';

const select = (state) => ({
  contributionDetails: state.accounts.contributionDetails,
  contributionVariables: state.static.environmentVars.contributionVariables,
  nextRunDates: state.accounts.nextRunDates.monthly,
});

const secondDateError = 'Date must occur within 1 month after first start date.';

export class NewRecurring extends React.Component {
  static propTypes = {
    bankAccountsLoading: PropTypes.bool.isRequired,
    contributionDetails: PropTypes.object.isRequired,
    contributionCreate: PropTypes.func.isRequired,
    contributionEdit: PropTypes.func.isRequired,
    contributionVariables: PropTypes.object.isRequired,
    createContributionLoading: PropTypes.bool.isRequired,
    getNextRunDate: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    nextRunDates: PropTypes.object.isRequired,
    onAddBankAccountModalOpen: PropTypes.func.isRequired,
    smallWidth: PropTypes.bool.isRequired,
    serverDateTime: PropTypes.string,
  };

  state = {
    contribution: {
      bankAccount: {
        id: '',
        name: '',
        number: '',
      },
      day1: '',
      day2: '',
      selectedAccounts: [],
      type: MONTHLY,
    },
    errors: [],
    step0ValidationHasRun: false,
    step1ValidationHasRun: false,
    stepIndex: 0,
    termsChecked: false,
  };

  amountHandle = ({ floatValue = 0 }) => {
    const { selectedAccounts } = this.state.contribution;

    selectedAccounts[0].contributionAmount = floatValue;
    this.contributionSet('selectedAccounts', selectedAccounts);
  }

  bankAccountSelectHandle = (value) => {
    this.contributionSet('bankAccount', this.props.contributionDetails.agentBankAccounts.find(bankAccount => bankAccount.id === value));
  }

  contributionDateHandle = (date, day) => {
    const { selectedAccounts } = this.state.contribution;
    if (day === 'endDate') {
      selectedAccounts[0].endDate = date;
      this.contributionSet('selectedAccounts', selectedAccounts);
    }
    else {
      this.contributionSet(day, date);
    }

    this.nextRunDateGet(date, day);
  }

  contributionFormat = () => {
    const { contribution } = this.state;
    if (contribution.type === MONTHLY) {
      contribution.day2 = null;
    }
    if (this.props.isEditing) {
      this.props.contributionEdit(contribution);
    }
    else {
      this.props.contributionCreate(contribution);
    }
  }

  contributionSet = (key, value) => {
    const { stepIndex, step0ValidationHasRun, step1ValidationHasRun } = this.state;

    this.setState(({ contribution }) => {
      return { contribution: { ...contribution, [key]: value } };
    });

    if (
      (stepIndex === 0 && step0ValidationHasRun) ||
      (stepIndex === 1 && step1ValidationHasRun)
    ) {
      this.formValidate();
    }
  }

  errorGet = (key) => {
    const error = this.state.errors.find(error => error.key === key);
    return error ? error.message : '';
  }

  formValidate = (nextStep = () => null) => {
    const { maxRecurringContribution, minRecurringContribution, firstAvailTradeDate, recurringContributionLeadDays } = this.props.contributionVariables;

    switch (this.state.stepIndex) {
      case 0: {
        this.setState(({ contribution: { bankAccount, selectedAccounts, type } }) => {
          const amount = selectedAccounts[0].contributionAmount;
          const errors = [];

          if ( // validates that amount doesn't exceed maximums for monthly and twice-monthly
            (type === MONTHLY && (amount > maxRecurringContribution || amount < minRecurringContribution)) ||
            (type === TWICE_MONTHLY && (amount > (maxRecurringContribution / 2) || amount < minRecurringContribution))
          ) {
            errors.push({
              key: 'amount',
              message: 'Enter a valid amount.'
            });
          }
          if (!bankAccount.id) {
            errors.push({
              key: 'bankAccount',
              message: 'Select an existing bank account or add a new one.'
            });
          }
          return { errors, step0ValidationHasRun: true };
        }, () => this.state.errors.length === 0 && nextStep());

        break;
      }
      case 1: {
        this.setState(({ contribution: { day1, day2, selectedAccounts, type } }) => {
          const { contributionAmount, endDate } = selectedAccounts[0];
          const errors = [];

          if (!day1) {
            errors.push({
              key: 'day1',
              message: 'Start date is required.'
            });
          }
          else if (!dayjs(day1).isValid()) { // validate date format
            errors.push({
              key: 'day1',
              message: 'Invalid Date Format'
            });
          }
          else if (!dayjs(day1).isBetween(dayjs(firstAvailTradeDate), dayjs(firstAvailTradeDate).add(recurringContributionLeadDays, 'd'), null, '[]')) {
            errors.push({
              key: 'day1',
              message: `Start date should be between ${dayjs(firstAvailTradeDate).format('MM/DD/YYYY')} and ${dayjs(firstAvailTradeDate).add(recurringContributionLeadDays, 'd').format('MM/DD/YYYY')}.` // eslint-disable-line
            });
          }

          if (type === TWICE_MONTHLY) {
            if (!dayjs(day2).isValid()) { // validates that day2 is populated while editing
              errors.push({
                key: 'day2',
                message: 'Second date is required for twice monthly contributions.'
              });
            }
            if (
              (dayjs(day2).isSameOrBefore(day1, 'day') || // validates that day2 occurs after day1
                dayjs(day2).isSameOrAfter(dayjs(day1).add(1, 'month'), 'day')) // validates that day2 is within a month of day1
            ) {
              errors.push({
                key: 'day2',
                message: secondDateError
              });
            }
            if (contributionAmount > maxRecurringContribution / 2 || contributionAmount < minRecurringContribution) { // validates that amount doesn't exceed maximums for twice-monthly
              errors.push({
                key: 'amount',
                message: 'Enter a valid amount.'
              });
            }
          }

          if (endDate && !dayjs(endDate).isValid()) {
            errors.push({
              key: 'endDate',
              message: 'Invalid Date Format'
            });
          }
          else if (
            ((type === TWICE_MONTHLY && (dayjs(endDate).isSameOrBefore(day1, 'day') || dayjs(endDate).isSameOrBefore(day2, 'day'))) || // validates that end date occurs after day1 and day2
              (type === MONTHLY && dayjs(endDate).isSameOrBefore(day1, 'day'))) // validates that end date occurs after day1
          ) {
            errors.push({
              key: 'endDate',
              message: type === MONTHLY ? 'End date must occur after start date.' : 'End date must occur after both start dates.'
            });
          }

          return { errors, step1ValidationHasRun: true };
        }, () => this.state.errors.length === 0 && nextStep());

        break;
      }
      default: break;
    }
  }

  nextMinDateGet = (startDate = this.state.contribution.day1) => {
    let momentDate = dayjs(startDate).add(1, 'd');

    if (DISABLED_DATES.includes(momentDate.date())) {
      do {
        momentDate = dayjs(momentDate).add(1, 'd');
      }
      while (DISABLED_DATES.includes(momentDate.date()));
    }

    return momentDate;
  }

  nextRunDateCompose = (start) => {
    const { contributionDetails: { contribution: { day1, day2, endDate } }, nextRunDates } = this.props;

    if (
      endDate &&
      (dayjs(day1).isSameOrAfter(endDate) || dayjs(day2).isSameOrAfter(endDate))
    ) {
      return <small>Contribution is scheduled to end before next run date.</small>;
    }

    return <small>Next run date: {dayjs(start === 'start1' ? nextRunDates.day1 : nextRunDates.day2).format('L')}</small>;
  }

  nextRunDateGet = (date, day) => {
    if (dayjs(date).isValid()) {
      this.props.getNextRunDate(date, day, MONTHLY);
    }
  }

  nextStepHandle = () => {
    this.setState({ stepIndex: this.state.stepIndex + 1 });
    window.scrollTo(0, 0);
    !this.props.isEditing && events.contributionsRecurringStep(this.state.stepIndex + 1);
  }

  prevStepHandle = () => {
    this.setState({ stepIndex: this.state.stepIndex - 1 });
    window.scrollTo(0, 0);
    !this.props.isEditing && events.contributionsRecurringStep(this.state.stepIndex - 1);
  }

  goToPreviousPage = () => {
    this.props.history.goBack();
  }

  stepperButtonsCompose = () => {
    const {
      contribution,
      stepIndex,
      termsChecked
    } = this.state;
    const cancelButton = (
      <Button
        variant='text'
        key='cancel'
        disabled={this.props.createContributionLoading}
        onClick={this.goToPreviousPage}
        style={{ marginRight: '5px' }}
      >
        Cancel
      </Button>
    );
    const backButton = (
      <Button
        key='prevStep'
        onClick={() => this.prevStepHandle()}
        variant='text'
        disabled={this.props.createContributionLoading}
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Back
      </Button>
    );
    const nextButton = (
      <Button
        key='nextStep'
        onClick={() => this.formValidate(this.nextStepHandle)}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Next
      </Button>
    );
    const agreeButton = (
      <Button
        disabled={!termsChecked || this.props.createContributionLoading}
        key='nextStep'
        onClick={() => this.contributionFormat()}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        I agree
      </Button>
    );

    const printButton = (
      <Button
        key='print'
        onClick={() => window.print()}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Print
      </Button>
    );
    const finishButton = (
      <Button
        key='finish'
        onClick={() => this.props.history.goBack()}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Finish
      </Button>
    );

    if (contribution.selectedAccounts.length > 0) {
      let buttons = [];
      switch (stepIndex) {
        case 0:
          buttons = [
            cancelButton,
            nextButton
          ];
          break;
        case 1:
          buttons = [
            cancelButton,
            backButton,
            nextButton
          ];
          break;
        case 2:
          buttons = [
            cancelButton,
            backButton,
            <LoadingOverlay key='loading' show={this.props.createContributionLoading}>
              {agreeButton}
            </LoadingOverlay>
          ];
          break;
        case 3:
          buttons = [
            printButton,
            finishButton,
          ];
          break;
        default: break;
      }
      return buttons;
    }
  }

  step0ContentCompose = () => {
    const { contribution: { bankAccount, selectedAccounts } } = this.state;
    const { bankAccountsLoading, contributionDetails: { agentBankAccounts }, contributionVariables: { minRecurringContribution, maxRecurringContribution }, onAddBankAccountModalOpen } = this.props;

    if (selectedAccounts.length > 0) {
      const { accountNumber, beneficiaryName, contributionAmount } = selectedAccounts[0];

      return [
        <div className={styles.NewRecurring_step0Container} key='accountAndAmountContainer'>
          <div className={styles.NewRecurring_accountContainer} style={this.errorGet('amount') ? { marginBottom: 'calc(.75em + 8px)' } : {}} key='accountContainer'>
            <div className={styles.NewRecurring_account} id='account'>{`${beneficiaryName} ...${accountNumber.slice(accountNumber.length - 5)}`}</div>
            <label htmlFor='account' className={styles.NewRecurring_accountLabel}>Selected Account</label>
          </div>
          <div className={styles.NewRecurring_amount} key='amountContainer'>
            <CurrencyInput
              className={styles.NewRecurring_currencyInput}
              errorText={this.errorGet('amount')}
              label='Amount'
              onChange={this.amountHandle}
              style={{ marginBottom: '0px' }}
              value={contributionAmount}
            />
          </div>

          {agentBankAccounts.length > 0 ?
            <div className={styles.NewRecurring_bankAccount}>
              <LoadingOverlay show={bankAccountsLoading}>
                <Dropdown
                  label='Bank Account'
                  value={bankAccount.id}
                  errorText={this.errorGet('bankAccount')}
                  onChange={this.bankAccountSelectHandle}
                  options={agentBankAccounts.map(acct => ({
                    value: acct.id,
                    display: acct.id === 0 ? acct.name : `${acct.name}, ${acct.number}`,
                  }))}
                  FormControlProps={{ style: { width: '100%', minWidth: '256px' } }}
                />
              </LoadingOverlay>
              <Tooltip
                title='Add new bank account'
                placement='right'
              >
                <Icon onClick={onAddBankAccountModalOpen} className={styles.NewRecurring_addButton}>add</Icon>
              </Tooltip>
            </div>
            :
            <div className={styles.NewRecurring_bankAccount}>
              <div>
                <Button
                  onClick={onAddBankAccountModalOpen}
                  variant='contained'
                >
                  Add Bank Account
                </Button>
              </div>
              <div className={`${styles.NewRecurring_error} ${styles.NewRecurring_errorsContainer}`}>
                {this.errorGet('bankAccount') && agentBankAccounts.length === 0 ? 'Add a new account.' : ''}
              </div>
            </div>
          }
        </div>,
        <div className={styles.NewRecurring_errorsContainer} key='errorsContainer'>
          {this.errorGet('amount') &&
            <div className={styles.NewRecurring_error}>
              Enter an amount between {currencyFormatter(minRecurringContribution)} and {currencyFormatter(maxRecurringContribution)} for monthly contributions, or between {currencyFormatter(minRecurringContribution)} and {currencyFormatter(maxRecurringContribution / 2)} for twice monthly contributions.
            </div>
          }
        </div>
      ];
    }
  }

  step1ContentCompose = () => {
    const { isEditing, contributionVariables: { firstAvailTradeDate, recurringContributionLeadDays } } = this.props;
    const { contribution: { day1, day2, selectedAccounts, type } } = this.state;

    return (
      <div className={styles.NewRecurring_step1Container}>
        <RadioGroup
          className='NewRecurring_step1RadioButtons' // There are no CSS styles for NewRecurring_step1RadioButtons
          onChange={(e) => this.contributionSet('type', e.target.value)} // eslint-disable-line
          value={type}
        >
          <FormControlLabel
            control={<Radio />}
            label={MONTHLY}
            value={MONTHLY}
          />
          <FormControlLabel
            control={<Radio />}
            label={TWICE_MONTHLY}
            value={TWICE_MONTHLY}
          />
        </RadioGroup>

        <div className={styles.NewRecurring_step1Date1}>
          <DatePicker
            error={Boolean(this.errorGet('day1'))}
            helperText={this.errorGet('day1')}
            label={isEditing ? 'Next Scheduled Day' : type === MONTHLY ? 'Contribution Start Date' : 'First Start Date'} // eslint-disable-line
            key='datePickerDay1'
            minDate={firstAvailTradeDate}
            maxDate={dayjs(firstAvailTradeDate).add(recurringContributionLeadDays, 'd')}
            onChange={date => this.contributionDateHandle(date, 'day1')}
            shouldDisableDate={(date) => DISABLED_DATES.includes(dayjs(date).date())}
            value={day1}
          />
          {this.nextRunDateCompose('start1')}
        </div>

        {type === TWICE_MONTHLY &&
          <div className={styles.NewRecurring_step1Date2}>
            <DatePicker
              error={Boolean(this.errorGet('day2'))}
              helperText={this.errorGet('day2')}
              label={isEditing ? 'Following Scheduled Day' : 'Second Start Date'}
              key='datePickerDay2'
              maxDateMessage={secondDateError}
              minDate={dayjs(firstAvailTradeDate)}
              maxDate={dayjs(day1).add(1, 'M').subtract(1, 'd')} // eslint-disable-line
              onChange={date => this.contributionDateHandle(date, 'day2')}
              shouldDisableDate={(date) => DISABLED_DATES.includes(dayjs(date).date())}
              value={day2 ? day2 : null}
            />
            {dayjs(day2).isValid() && this.nextRunDateCompose('start2')}
          </div>
        }

        <div className={styles.NewRecurring_endDate}>
          <DatePicker
            error={Boolean(this.errorGet('endDate'))}
            helperText={this.errorGet('endDate')}
            label='Optional End Date'
            key='datePickerEndDate'
            minDate={firstAvailTradeDate}
            onChange={date => this.contributionDateHandle(date, 'endDate')}
            value={selectedAccounts[0].endDate ? selectedAccounts[0].endDate : null}
          />
        </div>
      </div>
    );
  }

  step2ContentCompose = () => {
    const { contribution: { bankAccount, day1, day2, selectedAccounts, type }, termsChecked } = this.state;
    const { nextRunDates } = this.props;

    let nextRunDate = '';
    if (type === MONTHLY) {
      nextRunDate = dayjs(nextRunDates.day1);
    }
    else {
      nextRunDate = dayjs(nextRunDates.day1).isBefore(dayjs(nextRunDates.day2), 'day') ? dayjs(nextRunDates.day1) : dayjs(nextRunDates.day2);
    }

    return (
      <div>
        <div className={styles.NewRecurring_reviewContainer}>
          <span className={styles.NewRecurring_reviewInfoAccountNum}>
            <div className={styles.NewRecurring_detailsTitle}>Account Number:</div>
            {selectedAccounts.map(account => (<div className={styles.NewRecurring_detail} key={account.accountId}>{account.accountNumber}</div>))}
          </span>

          <span className={styles.NewRecurring_reviewInfoBen}>
            <div className={styles.NewRecurring_detailsTitle}>Beneficiary Name:</div>
            {selectedAccounts.map(account => (<div className={styles.NewRecurring_detail} key={account.accountId}>{account.beneficiaryName}</div>))}
          </span>

          <span className={styles.NewRecurring_reviewInfoSched}>
            <div className={styles.NewRecurring_detailsTitle}>{type === MONTHLY ? 'Scheduled Day:' : 'Scheduled Days:'}</div>
            <div>{type === MONTHLY ? `On the ${dayjs(day1).format('Do')} of every month` : `On the ${dayjs(day1).format('Do')} and ${dayjs(day2).format('Do')} of every month`}</div>
          </span>

          <span className={styles.NewRecurring_reviewInfoNextDate}>
            <div className={styles.NewRecurring_detailsTitle}>Next Contribution Date:</div>
            <div>{nextRunDate.format('L')}</div>
          </span>

          {selectedAccounts[0].endDate && (
            <div className={styles.NewRecurring_reviewInfoEndDate}>
              <div className={styles.NewRecurring_detailsTitle}>End Date:</div>
              <div>{dayjs(selectedAccounts[0].endDate).format('L')}</div>
            </div>
          )}

          <span className={styles.NewRecurring_reviewInfoAmount}>
            <div className={styles.NewRecurring_detailsTitle}>Amount:</div>
            {selectedAccounts.map(account => (<div className={styles.NewRecurring_detail} key={account.accountId}>{currencyFormatter(account.contributionAmount)}</div>))}
          </span>

          <span className={styles.NewRecurring_reviewInfoBankAccount}>
            <div className={styles.NewRecurring_detailsTitle}>Bank Account:</div>
            <div>{bankAccount.name} {bankAccount.type}, {bankAccount.number}</div>
          </span>
        </div>
        <div className={styles.NewRecurring_termsAndConditions}>
          <div className={styles.NewRecurring_terms}>
            <TermsConditions />
          </div>
          <div className={styles.NewRecurring_checkbox}>
            <FormControlLabel
              label='I have read and understand the above terms and conditions.'
              control={
                <Checkbox
                  checked={termsChecked}
                  onChange={() => this.setState({ termsChecked: !termsChecked })}
                />
              }
            />
          </div>
        </div>
      </div>
    );
  }

  step3ContentCompose = () => {
    const { contribution: { bankAccount, day1, day2, selectedAccounts, type } } = this.state;
    const { nextRunDates } = this.props;

    let nextRunDate = '';
    if (type === MONTHLY) {
      nextRunDate = dayjs(nextRunDates.day1);
    }
    else {
      nextRunDate = dayjs(nextRunDates.day1).isBefore(dayjs(nextRunDates.day2), 'day') ? dayjs(nextRunDates.day1) : dayjs(nextRunDates.day2);
    }

    return (
      <div className={transactionsStyles.printContainer}>
        <div className={styles.NewRecurring_reviewContainer}>
          <span className={styles.NewRecurring_reviewInfoAccountNum}>
            <div className={styles.NewRecurring_detailsTitle}>Account Number:</div>
            {selectedAccounts.map(account => (<div className={styles.NewRecurring_detail} key={account.accountId}>{account.accountNumber}</div>))}
          </span>

          <span className={styles.NewRecurring_reviewInfoBen}>
            <div className={styles.NewRecurring_detailsTitle}>Beneficiary Name:</div>
            {selectedAccounts.map(account => (<div className={styles.NewRecurring_detail} key={account.accountId}>{account.beneficiaryName}</div>))}
          </span>

          <span className={styles.NewRecurring_reviewInfoSched}>
            <div className={styles.NewRecurring_detailsTitle}>{type === MONTHLY ? 'Scheduled Day:' : 'Scheduled Days:'}</div>
            <div>{type === MONTHLY ? `On the ${dayjs(day1).format('Do')} of every month` : `On the ${dayjs(day1).format('Do')} and ${dayjs(day2).format('Do')} of every month`}</div>
          </span>

          <span className={styles.NewRecurring_reviewInfoNextDate}>
            <div className={styles.NewRecurring_detailsTitle}>Next Contribution Date:</div>
            <div>{nextRunDate.format('L')}</div>
          </span>

          {selectedAccounts[0].endDate && (
            <div className={styles.NewRecurring_reviewInfoEndDate}>
              <div className={styles.NewRecurring_detailsTitle}>End Date:</div>
              <div>{dayjs(selectedAccounts[0].endDate).format('L')}</div>
            </div>
          )}

          <span className={styles.NewRecurring_reviewInfoAmount}>
            <div className={styles.NewRecurring_detailsTitle}>Amount:</div>
            {selectedAccounts.map(account => (<div className={styles.NewRecurring_detail} key={account.accountId}>{currencyFormatter(account.contributionAmount)}</div>))}
          </span>

          <span className={styles.NewRecurring_reviewInfoBankAccount}>
            <div className={styles.NewRecurring_detailsTitle}>Bank Account:</div>
            <div>{bankAccount.name} {bankAccount.type}, {bankAccount.number}</div>
          </span>
        </div>
        <div className={transactionsStyles.currentTime}>{dayjs(this.props.serverDateTime)
          .format('MM/DD/YYYY h:mm A')} MST</div>
        <div className='hideOnPrint'>
          Please print a copy of this page for your records.
        </div>
      </div>
    );
  }

  stepperContentCompose = () => {
    switch (this.state.stepIndex) {
      case 0: return this.step0ContentCompose();
      case 1: return this.step1ContentCompose();
      case 2: return this.step2ContentCompose();
      case 3: return this.step3ContentCompose();

      default:
        break;
    }
  }

  componentDidMount() {
    const { contributionDetails: { agentAccounts, contribution, selectedAccountId }, contributionVariables: { firstAvailTradeDate }, isEditing } = this.props;

    if (Object.keys(agentAccounts).length > 0) {
      if (isEditing) {
        this.setState({ contribution });
        this.nextRunDateGet(contribution.day1, 'day1');
        this.nextRunDateGet(contribution.day2, 'day2');
      }
      else {
        const firstNonDisabledDate = DISABLED_DATES.includes(dayjs(firstAvailTradeDate).date()) ? this.nextMinDateGet(firstAvailTradeDate) : dayjs(firstAvailTradeDate);
        const secondNonDisabledDate = DISABLED_DATES.includes(dayjs(firstAvailTradeDate).add(1, 'd').date()) ? this.nextMinDateGet(dayjs(firstAvailTradeDate).add(1, 'd')) : dayjs(firstAvailTradeDate).add(1, 'd'); // eslint-disable-line
        this.contributionDateHandle(firstNonDisabledDate, 'day1');
        this.contributionDateHandle(secondNonDisabledDate, 'day2');
        this.contributionSet('selectedAccounts', [clone(agentAccounts.find(agentAccount => agentAccount.accountId === selectedAccountId))]);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.serverDateTime !== this.props.serverDateTime && this.props.serverDateTime !== null) {
      this.nextStepHandle();
    }
  }

  render() {
    const { stepIndex } = this.state;

    return (
      <Card className={styles.NewRecurring_container}>
        <div className={`${styles.NewRecurring_stepperSteps} hideOnPrint`}>
          <Stepper
            activeStep={stepIndex}
            orientation={this.props.smallWidth ? 'vertical' : 'horizontal'}
            style={this.props.smallWidth ? {} : { width: '650px' }}
          >
            <Step>
              <StepButton
                style={stepIndex !== 0 && this.errorGet('amount') ? { backgroundColor: 'rgba(255, 0, 0, 0.25)' } : {}}
                onClick={() => this.setState({ stepIndex: 0 })}
                disabled={stepIndex === 3}
              >
                Amount & Bank Account
              </StepButton>
            </Step>
            <Step>
              <StepButton onClick={() => this.setState({ stepIndex: 1 })} disabled={stepIndex === 3}>Contribution Frequency</StepButton>
            </Step>
            <Step>
              <StepButton onClick={() => this.setState({ stepIndex: 2 })} disabled={stepIndex === 3}>Terms & Conditions</StepButton>
            </Step>
            <Step>
              <StepButton onClick={() => this.setState({ stepIndex: 3 })}>Print Review</StepButton>
            </Step>
          </Stepper>
        </div>

        <div className={styles.NewRecurring_stepperContent}>
          {!this.props.loading && (this.stepperContentCompose())}
        </div>

        <div className={`${styles.NewRecurring_stepperButtons} hideOnPrint`}>
          {!this.props.loading && (this.stepperButtonsCompose())}
        </div>
      </Card>
    );
  }
}

export default withRouter(connect(select, { getNextRunDate })(NewRecurring));
