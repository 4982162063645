/*
*
* ManageUsers Constants
*
*/
export const USERS_LIST_GET = 'src/components/Features/protected/ManageUsers/USERS_LIST_GET';
export const USER_DETAILS_GET = 'src/components/Features/protected/ManageUsers/USER_DETAILS_GET';
export const USER_UPDATE = 'src/components/Features/protected/ManageUsers/USER_UPDATE';
export const POA_ACCOUNTS_GET = 'src/components/Features/protected/ManageUsers/POA_ACCOUNTS_GET';
export const USER_CREATE = 'src/components/Features/protected/ManageUsers/USER_CREATE';
export const PRIMARY_CONTACTS_ASSIGN = 'src/components/Features/protected/ManageUsers/PRIMARY_CONTACTS_ASSIGN';
export const DEACTIVATE_USER = 'src/components/Features/protected/ManageUsers/DEACTIVATE_USER';
export const REACTIVATE_USER = 'src/components/Features/protected/ManageUsers/REACTIVATE_USER';
export const ACTIVATION_EMAIL_RESEND = 'src/components/Features/protected/ManageUsers/ACTIVATION_EMAIL_RESEND';
