/*
*
* ReportCard Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Paper } from '@mui/material';

import styles from './styles.module.css';

export class ReportCard extends React.Component {

  static propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  };
  
  state = {
    hovered: false,
  };

  render() {
    const { id, name, description } = this.props;

    return (
      <div
        className={styles.ReportCard_container}
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
        onClick={() => this.props.history.push(`/reports/${id}`)}
      >
        <Paper
          elevation={this.state.hovered ? 3 : 1}
          style={{
            padding: '10px',
            borderTop: '3px solid var(--accent)',
            minHeight: '100px',
          }}
        >
          <div>{name}</div>
          <hr className={styles.ReportCard_separator} />
          <div className={styles.ReportCard_description}>{description}</div>
        </Paper>
      </div>
    );
  }
}

export default withRouter(ReportCard);
