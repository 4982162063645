export const errorTypes = {
  EMAIL_ERROR: 'Email',
  AGENT_ERROR: 'Agent',
  BENEFICIARY_ERROR: 'Beneficiary',
  SUCCESSOR_ERROR: 'Successor',
  SECONDARY_SUCCESSOR_ERROR: 'SSuccessor',
  POA_LEVEL_ERROR: 'POALevel',
  SSN_ERROR: 'SSN',
  FED_TAX_ERROR: 'CanViewFedTaxDocs',
  STATE_TAX_ERROR: 'CanViewStateTaxDocs',
};

export const detailedErrors = [
  {
    type: errorTypes.EMAIL_ERROR,
    description: 'Agent Email',
  },
  {
    type: errorTypes.AGENT_ERROR,
    description: 'Agent Birthdate',
  },
  {
    type: errorTypes.BENEFICIARY_ERROR,
    description: 'Beneficiary Birthdate',
  },
  {
    type: errorTypes.SUCCESSOR_ERROR,
    description: 'Successor Birthdate',
  },
  {
    type: errorTypes.SECONDARY_SUCCESSOR_ERROR,
    description: 'Secondary Successor Birthdate',
  },
  {
    type: errorTypes.POA_LEVEL_ERROR,
    description: 'Access Level',
  },
  {
    type: errorTypes.SSN_ERROR,
    description: 'Agent Social Security Number',
  },
  {
    type: errorTypes.FED_TAX_ERROR,
    description: 'View Federal Tax Documents',
  },
  {
    type: errorTypes.STATE_TAX_ERROR,
    description: 'View State Tax Documents',
  },
];