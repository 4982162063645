/*
*
* MyInfo Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PersistentDrawers, { Row, LeftMenuItems, LeftMenuItem, RightDrawer } from './PersistentDrawers';

import {
  LoadingOverlay,
  notificationShow,
  InfoIcon
} from '@frontend/common';

import Username from './Username';
import Password from './Password';
import TwoFA from './TwoFA';
import Email from './Email';
import PhoneNumbers from './PhoneNumbers';
import Permissions from './Permissions';
import NotificationToggles from './NotificationToggles';
import NameHeaderWithEdit from './NameHeaderWithEdit';

import { getPreferredMethod } from 'components/Features/protected/Multifactor/actions';
import { passwordChange, advisorGet, contactInfoUpdate } from './actions';
import { passwordRequirementsGet } from 'components/AppRoot/StaticResources/actions';
import { usernameChange, advisorNameUpdate } from 'components/AppRoot/Navigation/actions';

import styles from './styles.module.css';

const select = (state) => ({
  passwordRequirements: state.static.passwordRequirements,
  claims: state.session.claims,
  advisor: state.myInfo.advisor,
  lpoaHelpNumber: state.static.environmentVars.lpoaHelpNumber,
  preferredInfo: state.multifactor.preferredInfo,
  is2FABypassed: state.session.is2FABypassed,
});

export class MyInfo extends React.Component {
  static propTypes = {
    lpoaHelpNumber: PropTypes.string.isRequired,
    passwordRequirements: PropTypes.array.isRequired,
    passwordRequirementsGet: PropTypes.func.isRequired,
    passwordChange: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    claims: PropTypes.object.isRequired,
    advisor: PropTypes.object.isRequired,
    advisorGet: PropTypes.func.isRequired,
    usernameChange: PropTypes.func.isRequired,
    contactInfoUpdate: PropTypes.func.isRequired,
    advisorNameUpdate: PropTypes.func.isRequired,
    getPreferredMethod: PropTypes.func.isRequired,
    preferredInfo: PropTypes.object.isRequired,
    is2FABypassed: PropTypes.bool.isRequired,
  };

  state = {
    ClientNotifications: null,
    TransactionNotifications: null,
    loading: false,
  };

  componentDidMount() {
    const promises = [];

    if (this.props.passwordRequirements.length === 0) {
      promises.push(this.props.passwordRequirementsGet());
    }

    if (this.props.advisor.Pin === '') {
      promises.push(this.props.advisorGet());
    }

    promises.push(this.props.getPreferredMethod());

    this.setState({ loading: true });
    Promise.all(promises)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    const { loading } = this.state;
    const {
      advisor: {
        EmailAddress,
        permissions,
        PhoneNumber,
        PhoneNumber1,
      },
      claims: {
        Login,
      },
      preferredInfo,
      is2FABypassed,
    } = this.props;

    return (
      <div className={styles.myInfo_left_container}>
        <LoadingOverlay show={loading} width='100%'>
          <NameHeaderWithEdit />
          <PersistentDrawers drawerWidth='390px' marginTop='60px'>
            <Row>
              <LeftMenuItems>
                <LeftMenuItem>
                  <div className={styles.title}>
                    Username
                  </div>
                </LeftMenuItem>
                <LeftMenuItem>
                  <div className={styles.contentLighter}>
                    {Login}
                  </div>
                </LeftMenuItem>
              </LeftMenuItems>
              <RightDrawer title='Username'>
                <Username />
              </RightDrawer>
            </Row>

            <Row>
              <LeftMenuItems>
                <LeftMenuItem>
                  <div className={styles.title}>
                    Password
                  </div>
                </LeftMenuItem>
                <LeftMenuItem>
                  <div className={styles.contentLighter}>
                    **********
                  </div>
                </LeftMenuItem>
              </LeftMenuItems>
              <RightDrawer title='Password'>
                <Password />
              </RightDrawer>
            </Row>
            {preferredInfo.IsRegistered && !is2FABypassed && (
              <Row>
                <LeftMenuItems>
                  <LeftMenuItem>
                    <div className={styles.title}>
                      Two-Factor Authentication
                    </div>
                  </LeftMenuItem>
                  <LeftMenuItem>
                    <div className={styles.contentLighter}>
                      {preferredInfo.IsRegistered ? 'Registered' : 'Not Registered'}
                    </div>
                  </LeftMenuItem>
                </LeftMenuItems>
                <RightDrawer title='Two-Factor Authentication'>
                  <TwoFA />
                </RightDrawer>
              </Row>
            )}
            <Row>
              <LeftMenuItems>
                <LeftMenuItem>
                  <div className={styles.title}>
                    Email
                  </div>
                </LeftMenuItem>
                <LeftMenuItem>
                  <div className={styles.contentLighter}>
                    {EmailAddress}
                  </div>
                </LeftMenuItem>
              </LeftMenuItems>
              <RightDrawer title='Email'>
                <Email />
              </RightDrawer>
            </Row>

            <Row>
              <LeftMenuItems>
                <LeftMenuItem>
                  <div className={styles.title}>
                    Phone Numbers
                  </div>
                </LeftMenuItem>
                <LeftMenuItem>
                  <div className={styles.contentLighter}>
                    Work: {PhoneNumber.Number}
                  </div>
                  <div className={styles.contentLighter}>
                    Cell: {PhoneNumber1.Number}
                  </div>
                </LeftMenuItem>
              </LeftMenuItems>
              <RightDrawer
                title={(
                  <div>
                    Phone Numbers<InfoIcon message='Do you have an international phone number? If so, please call us at 800.418.2551 or submit form 500, Account Information Change' />
                  </div>
                )}
              >
                <PhoneNumbers />
              </RightDrawer>
            </Row>
          </PersistentDrawers>
          <NotificationToggles />
          <Permissions permissions={permissions} />
        </LoadingOverlay>
      </div>
    );
  }
}

export default connect(select, {
  passwordRequirementsGet,
  passwordChange,
  notificationShow,
  advisorGet,
  usernameChange,
  contactInfoUpdate,
  advisorNameUpdate,
  getPreferredMethod
})(MyInfo);
