/*
*
* GiactRejection Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormHelperText,
} from '@mui/material';
import {
  Modal,
} from '@frontend/common';

import styles from './styles.module.css';

GiactRejectionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseGiactRejectionModal: PropTypes.func.isRequired,
};

export function GiactRejectionModal({ open, handleCloseGiactRejectionModal }) {
  
  return (
    <Modal
      title='Authentication Failed'
      show={open}
      onCloseModal={() => handleCloseGiactRejectionModal()}
      modal={false}
      maxWidth='md'
      fullWidth
    >
      <div className={styles.giactText}>
        <FormHelperText error={true} style={{ fontSize: 'large' }}>
        Our verification process was not able to authenticate your financial institution account.
        </FormHelperText>
        <ul>
          <li>Please ensure that the name at the financial institution matches your name exactly and that you are not attempting to use a bank account associated with a third party.</li>
          <li>Account verification services are provided by GIACT Systems, LLC and are based on information provided by financial institutions. my529 does not have information regarding the specific reasons for a decline.</li>
        </ul>
        <h3>How Do I Correct Information GIACT Has About Me?</h3>
        <p>If information reflected within your bank or credit union Disclosure of Consumer Report Information is inaccurate, you may initiate a dispute at no cost by calling GIACT Systems toll-free at 833.802.8092 from 8:30 a.m. to 5 p.m. CT, emailing <a href='mailto:support@giact.com' target='_blank' rel='noopener noreferrer'>support@giact.com</a>, or writing to GIACT Systems at:</p>
        <ul style={{ listStyleType: 'none' }} >
          <li>GIACT Systems, LLC.</li>
          <li>Attention: Consumer Resolutions</li>
          <li>P.O. Box 1116</li>
          <li>Allen, TX 75013</li>
        </ul>
        <p>For more information, visit <a href='https://www.giact.com/consumer-inquiries/' target='_blank' rel='noopener noreferrer'>https://www.giact.com/consumer-inquiries/</a></p>
        <h3>Additional Questions?</h3>
        <p>You may contact my529 by emailing <a href='mailto:advisorinfo@my529.org' target='_blank' rel='noopener noreferrer'>advisorinfo@my529.org</a> or by calling our office toll-free at 888.529.1886 on business days from 7 a.m. to 5 p.m. MT.</p>
      </div>
    </Modal>
  );
}

export default GiactRejectionModal;
