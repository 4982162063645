import React from 'react';

const AvailableBalanceInfo = () => (
  <>
    <p>All gift contributions to my529 accounts are subject to a hold, regardless of whether they are contributed directly from a bank account, from a debit card or by check.</p>
    <ul>
      <li>
        <strong>Contributions for account owners who have been with my529 less than six months:</strong> Funds will be held for 20 business days after the deposit.
      </li>
      <li>
        <strong>Contributions for account owners that have been with my529 over six months:</strong> Funds will be held for at least 7 business days after the deposit.
      </li>
      <li>
        <strong>Gift Program contributions made by debit card</strong> cannot be withdrawn until 45 business days after deposit.
      </li>
    </ul>
  </>
);

export default AvailableBalanceInfo;
