/*
*
* Welcome Component
*
*/
import React from 'react';
import { Modal } from '@frontend/common';

export class Welcome extends React.Component {
  state = {
    modalOpen: window.location.search === '?request=account-access-redirect'
  }

  onModalClose = () => {
    this.setState({ modalOpen: false });
  }

  render() {
    return (
      <Modal
        actionButtons={[
          {
            label: 'Ok',
            action: () => this.onModalClose()
          }
        ]}
        onCloseModal={() => this.onModalClose()}
        show={this.state.modalOpen}
        title='Welcome'
      >
        Welcome to the new my529 Financial Advisor Access. Please bookmark this page for your convenience going forward.
      </Modal>
    );
  }
}

export default Welcome;