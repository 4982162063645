import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import qs from 'qs';

import {
  Button,
  TextField,
} from '@mui/material';

import {
  FormWrapper,
  LoadingOverlay,
  PasswordInput,
  PasswordRequirements,
  notificationShow,
  allNotificationsHide,
} from '@frontend/common';

import { activateUser } from './actions';
import { passwordRequirementsGet } from 'components/AppRoot/StaticResources/actions';

import styles from './styles.module.css';

const select = (state) => ({
  passwordRequirements: state.static.passwordRequirements,
});


export class ActivateUser extends Component {

  static propTypes = {
    activateUser: PropTypes.func.isRequired,
    passwordRequirements: PropTypes.array.isRequired,
    passwordRequirementsGet: PropTypes.func.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    notificationShow: PropTypes.func.isRequired,
  };

  state = {
    taxID: '',
    pin: '',
    username: '',
    password: '',
    passwordConfirm: '',
    initialLoading: false,
    loading: false,
    passwordPassesValidation: false,
    errors: {},
  };

  formValidate = () => {
    let isValid = false;
    const errors = {};

    if (this.props.match.params.type === 'firm') {
      if (!this.state.pin) {
        errors.pin = 'PIN is required.';
      }
    }
    else {
      if (!this.state.taxID) {
        errors.taxID = 'Tax ID / SSN is required.';
      }
    }

    if (!this.state.username) {
      errors.username = 'Username is required.';
    }
    else {
      if (this.state.username.match(/^\s+|\s+$/)) {
        errors.username = 'Field cannot contain leading or trailing spaces.';
      }
    }

    if (!this.state.password) {
      errors.password = 'Password is required.';
    }

    if (!this.state.passwordConfirm) {
      errors.passwordConfirm = 'Confirm Password is required.';
    }

    if (this.state.password && this.state.passwordConfirm && this.state.password !== this.state.passwordConfirm) {
      errors.passwordConfirm = 'Must match password above.';
    }

    if (this.state.password && !this.state.passwordPassesValidation) {
      errors.password = 'Must match criteria below.';
    }

    if (
      this.props.match.params.type === 'firm'
      && this.state.pin
      && this.state.username
      && (!this.state.username.match(/^\s+|\s+$/))
      && this.state.password
      && this.state.passwordPassesValidation
      && this.state.passwordConfirm
      && this.state.password === this.state.passwordConfirm
    ) {
      isValid = true;
    }
    else if (
      this.props.match.params.type === 'master'
      && this.state.taxID
      && this.state.username
      && (!this.state.username.match(/^\s+|\s+$/))
      && this.state.password
      && this.state.passwordPassesValidation
      && this.state.passwordConfirm
      && this.state.password === this.state.passwordConfirm
    ) {
      isValid = true;
    }

    this.setState({
      errors,
    });

    return isValid;
  }

  inputUpdate(input, value) {
    const updatedErrors = cloneDeep(this.state.errors);

    if (this.state[input] !== value) {
      updatedErrors[input] = '';
    }

    this.setState({
      [input]: value,
      errors: updatedErrors,
    });
  }

  onFormSubmit = (e) => {
    e.preventDefault();

    if (this.formValidate()) {
      const userType = this.props.match.params.type;
      this.props.allNotificationsHide();
      this.setState({ loading: true });
      const data = {
        guid: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).request,
        login: this.state.username,
        password1: this.state.password,
        password2: this.state.passwordConfirm,
      };
      document.getElementById('usernameField').blur();
      document.getElementById('passwordField').blur();
      document.getElementById('passwordConfirmField').blur();

      if (userType === 'firm') {
        document.getElementById('pinField').blur();
        data.pin = this.state.pin;
      }
      else {
        document.getElementById('taxIDField').blur();
        data.taxId = this.state.taxID;
      }

      this.props.activateUser(userType, data)
        .then(() => {
          this.props.notificationShow('User activated. Please log in.', 'success');
          this.props.history.push('/login');
        })
        .catch(() => this.setState({ loading: false }));
    }
  }

  componentDidMount() {
    if (this.props.passwordRequirements.length === 0) {
      this.setState({ initialLoading: true });
      this.props.passwordRequirementsGet()
        .catch(() => null)
        .finally(() => this.setState({ initialLoading: false }));
    }
  }

  render() {
    /* eslint-disable indent */
    return (
      <FormWrapper
        title='Activate User'
      >
        <LoadingOverlay show={this.state.initialLoading} width='100%'>
          <form
            className='formContainer'
            onSubmit={this.onFormSubmit}
          >
            { this.props.match.params.type === 'firm'
                ? <TextField
                    id='pinField'
                    value={this.state.pin}
                    onChange={(e) => this.inputUpdate('pin', e.target.value)}
                    label='PIN'
                    fullWidth
                    disabled={this.state.loading}
                    error={Boolean(this.state.errors.pin)}
                    helperText={this.state.errors.pin}
                  />
                : <TextField
                    id='taxIDField'
                    value={this.state.taxID}
                    onChange={(e) => this.inputUpdate('taxID', e.target.value)}
                    label='Tax ID / SSN'
                    fullWidth
                    disabled={this.state.loading}
                    error={Boolean(this.state.errors.taxID)}
                    helperText={this.state.errors.taxID}
                  />
            }
            <TextField
              id='usernameField'
              value={this.state.username}
              onChange={(e) => this.inputUpdate('username', e.target.value)}
              label='Username'
              fullWidth
              disabled={this.state.loading}
              error={Boolean(this.state.errors.username)}
              helperText={this.state.errors.username}
            />
            <PasswordInput
              id='passwordField'
              value={this.state.password}
              onChange={(e) => this.inputUpdate('password', e.target.value)}
              label='Password'
              fullWidth
              variant='standard'
              disabled={this.state.loading}
              errorText={this.state.errors.password}
            />
            <PasswordInput
              id='passwordConfirmField'
              value={this.state.passwordConfirm}
              onChange={(e) => this.inputUpdate('passwordConfirm', e.target.value)}
              label='Confirm Password'
              fullWidth
              variant='standard'
              disabled={this.state.loading}
              errorText={this.state.errors.passwordConfirm}
            />
            <div className={styles.ActivateUser_passwordRequirements}>
              <PasswordRequirements
                passwordRequirements={this.props.passwordRequirements}
                password={this.state.password}
                onPasswordCheck={ (isValid) => this.setState({ passwordPassesValidation: isValid }) }
              />
            </div>
            <div className={styles.ActivateUser_buttons}>
              <Button
                variant='text'
                style={{ width: '48%' }}
                onClick={() => this.props.history.push('/login')}
                disabled={this.state.loading}
              >
                Cancel
              </Button>
              <LoadingOverlay show={this.state.loading} width='48%'>
                <Button
                  type='submit'
                  variant='contained'
                  disabled={this.state.loading}
                  fullWidth
                >
                  Submit
                </Button>
              </LoadingOverlay>
            </div>
          </form>
        </LoadingOverlay>
      </FormWrapper>
    );
    /* eslint-enable indent */
  }
}

export default withRouter(connect(select, {
  notificationShow,
  activateUser,
  passwordRequirementsGet,
  allNotificationsHide,
})(ActivateUser));
