/*
*
* MyInfo Reducers
*
*/
import { cloneDeep } from 'lodash';
import {
  ADVISOR_GET,
  CONTACT_INFO_UPDATE,
} from './constants';

const initialState = {
  advisor: {
    FirstName: '',
    LastName: '',
    EmailAddress: '',
    Pin: '',
    permissions: {},
    PhoneNumber: { Number: '' },
    PhoneNumber1: { Number: '' },
    TransactionNotifications: false,
    ClientNotifications: false,
  },
};

export function mapAdvisor(advisor) {
  return {
    FinancialAdvisorId: advisor.FinancialAdvisorId,
    FirstName: advisor.FirstName,
    LastName: advisor.LastName,
    EmailAddress: advisor.EmailAddress,
    ClientNotifications: advisor.ClientNotifications,
    TransactionNotifications: advisor.TransactionNotifications,
    PhoneNumber: advisor.PhoneNumber ? advisor.PhoneNumber : { Number: '' },
    PhoneNumber1: advisor.PhoneNumber1 ? advisor.PhoneNumber1 : { Number: '' },
    Pin: advisor.Pin,
    permissions: {
      AllowContribute: advisor.AllowContribute,
      AllowOptionChange: advisor.AllowOptionChange,
      AllowTransfer: advisor.AllowTransfer,
      AllowWithdraw: advisor.AllowWithdraw,
      AllowViewStateTaxDocuments: advisor.AllowViewStateTaxDocuments,
      AllowViewFederalTaxDocuments: advisor.AllowViewFederalTaxDocuments,
      AdvisorEdit: advisor.AdvisorEdit,
      EditFirmContactInfo: advisor.EditFirmContactInfo,
      EditFirmAdvisorDefaults: advisor.EditFirmAdvisorDefaults,
      TemplateEdit: advisor.TemplateEdit,
      DataImportSetup: advisor.DataImportSetup,
      DataImport: advisor.DataImport,
      AccountReports: advisor.AccountReports,
      AdminReports: advisor.AdminReports,
      AllowUnsubscribe: advisor.AllowUnsubscribe,
      ViewAllAccounts: advisor.ViewAllAccounts,
    },
  };
}

function MyInfoReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case ADVISOR_GET: 
    case CONTACT_INFO_UPDATE: {
      newState.advisor = mapAdvisor(action.payload.data);
      return newState;
    }

    default:
      return state;
  }

}

export default MyInfoReducer;