/*
*
* NewUser Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  cloneDeep,
  find,
  findIndex,
} from 'lodash';

import { lpoaSettingsGet } from 'components/Features/protected/ManageLPOA/actions';
import { poaAccountsGet, userCreate, usersListGet } from '../actions';
import { permissionGet } from 'utils/config/permissions_details';

import {
  Card,
  sizify,
  emailValidate,
  Breadcrumbs,
  InfoIcon,
  Checkbox,
  LoadingOverlay,
  notificationShow,
  Modal,
} from '@frontend/common';
import AssignAccounts from '../AssignAccounts';

import {
  Button,
  TextField,
  Step,
  Stepper,
  StepButton,
} from '@mui/material';

import styles from './styles.module.css';

const select = (state) => ({
  lpoaSettings: state.manageLPOA.settings,
  poaAccounts: state.manageUsers.poaAccounts,
  newPin: state.manageUsers.createdUserPin,
});


export class NewUser extends React.Component {

  static propTypes = {
    size: PropTypes.object.isRequired,
    lpoaSettings: PropTypes.object.isRequired,
    lpoaSettingsGet: PropTypes.func.isRequired,
    poaAccounts: PropTypes.array.isRequired,
    poaAccountsGet: PropTypes.func.isRequired,
    userCreate: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    usersListGet: PropTypes.func.isRequired,
    newPin: PropTypes.string,
  };

  state = {
    activeStep: 0,
    user: {
      FirstName: '',
      LastName: '',
      Email: '',
      PhoneNumber: '',
      PhoneNumber1: '',
      AllowContribute: false,
      AllowOptionChange: false,
      AllowTransfer: false,
      AllowWithdraw: false,
      AllowViewStateTaxDocuments: false,
      AllowViewFederalTaxDocuments: false,
      AdvisorEdit: false,
      TemplateEdit: false,
      DataImportSetup: false,
      DataImport: false,
      AccountReports: false,
      AdminReports: false,
      POAIds: [],
      ViewAllAccounts: true,
    },
    contactInputErrors: [],
    accountAssignment: 'all',
    accounts: [],
    loading: false,
    showPINModal: false,
    pin: '',
  };

  accountAssignmentChange = (updatedAccounts, selectedIds, selectedButton) => {
    if (this.state.user.ViewAllAccounts !== (selectedButton === 'all')) {
      this.userUpdate('ViewAllAccounts', selectedButton === 'all');
    }
    this.setState({
      accounts: updatedAccounts,
    }, () => {
      this.userUpdate('POAIds', selectedIds);
    });
  }

  accountsFormat(accounts) {
    return accounts.map(account => ({
      ...account,
      selected: true,
    }));
  }

  buttonsCompose() {
    switch (this.state.activeStep) {
      case 0: {
        return (
          <div className={styles.NewUser_buttons}>
            <Button
              variant='contained'
              onClick={this.contactInfoValidate}
            >
              Next
            </Button>
          </div>
        );
      }
      case 1: {
        return (
          <div className={styles.NewUser_buttons}>
            <Button
              variant='text'
              onClick={() => this.setState({ activeStep: 0 })}
            >
              Back
            </Button>
            <Button
              variant='contained'
              onClick={() => this.setState({ activeStep: 2 })}
            >
              Next
            </Button>
          </div>
        );
      }
      case 2: {
        const { loading } = this.state;
        return (
          <div className={styles.NewUser_buttons}>
            <Button
              variant='text'
              onClick={() => this.setState({ activeStep: 1 })}
              disabled={loading}
            >
              Back
            </Button>
            <LoadingOverlay show={loading} >
              <Button
                variant='contained'
                onClick={this.userCreateHandle}
                disabled={loading}
              >
                Finish
              </Button>
            </LoadingOverlay>
          </div>
        );
      }
      default: break;
    }
  }

  contactInfoValidate = (e) => {
    e.preventDefault();
    const errors = [];
    if (!this.state.user.FirstName) {
      errors.push({
        input: 'FirstName',
        message: 'First Name is required.'
      });
    }

    if (!this.state.user.LastName) {
      errors.push({
        input: 'LastName',
        message: 'Last Name is required.'
      });
    }

    if (!this.state.user.EmailAddress) {
      errors.push({
        input: 'EmailAddress',
        message: 'Email is required.'
      });
    }
    else if (this.state.user.EmailAddress && !emailValidate(this.state.user.EmailAddress)) {
      errors.push({
        input: 'EmailAddress',
        message: 'Invalid email address.'
      });
    }

    if (errors.length > 0) {
      this.setState({
        contactInputErrors: errors,
      });
    }
    else {
      this.setState({
        contactInputErrors: [],
        activeStep: 1,
      });
    }
  }

  errorGet(input) {
    const error = find(this.state.contactInputErrors, { input });
    return error ? error.message : '';
  }

  lpoaSettingsMatch = (user) => {
    return {
      ...user,
      ...this.props.lpoaSettings,
    };
  }

  permissionsToggleCompose(key) {
    const toggleDetails = permissionGet(key);
    return (
      <div>
        <Checkbox
          label={<div className={styles.NewUser_toggleLabel}><span>{toggleDetails.label}</span><InfoIcon message={toggleDetails.description} /></div>}
          checked={this.state.user[key] || false}
          onChange={e => this.userUpdate(key, e.target.checked)}
        />
      </div>
    );
  }

  stepCompose() {
    switch (this.state.activeStep) {
      case 0: {
        return (
          <form
            className={styles.NewUser_contactInputs}
            onSubmit={this.contactInfoValidate}
          >
            <TextField
              value={this.state.user.FirstName || ''}
              label='First Name'
              onChange={(e) => this.userUpdate('FirstName', e.target.value)}
              error={Boolean(this.errorGet('FirstName'))}
              helperText={this.errorGet('FirstName')}
              fullWidth
              autoFocus
            />
            <TextField
              value={this.state.user.LastName || ''}
              label='Last Name'
              onChange={(e) => this.userUpdate('LastName', e.target.value)}
              error={Boolean(this.errorGet('LastName'))}
              helperText={this.errorGet('LastName')}
              fullWidth
            />
            <TextField
              value={this.state.user.EmailAddress || ''}
              label='Email'
              onChange={(e) => this.userUpdate('EmailAddress', e.target.value)}
              error={Boolean(this.errorGet('EmailAddress'))}
              helperText={this.errorGet('EmailAddress')}
              fullWidth
            />
            <TextField
              value={this.state.user.PhoneNumber || ''}
              label='Work Phone'
              onChange={(e) => this.userUpdate('PhoneNumber', e.target.value)}
              fullWidth
            />
            <TextField
              value={this.state.user.PhoneNumber1 || ''}
              label='Cellphone'
              onChange={(e) => this.userUpdate('PhoneNumber1', e.target.value)}
              fullWidth
            />
            <button hidden type='submit' />
          </form>
        );
      }
      case 1: {
        return (
          <form
            className={styles.NewUser_toggles}
            onSubmit={e => {
              e.preventDefault();
              this.setState({ activeStep: 2 });
            }}
          >
            <div className={styles.NewUser_toggleSection} id={styles.NewUser_toggleSection_transactions}>
              <div>Transactions</div>
              <hr />
              {this.permissionsToggleCompose('AllowContribute')}
              {this.permissionsToggleCompose('AllowOptionChange')}
              {this.permissionsToggleCompose('AllowTransfer')}
              {this.permissionsToggleCompose('AllowWithdraw')}
            </div>
            <div className={styles.NewUser_toggleSection} id={styles.NewUser_toggleSection_taxDocs}>
              <div>Tax Documents</div>
              <hr />
              {this.permissionsToggleCompose('AllowViewStateTaxDocuments')}
              {this.permissionsToggleCompose('AllowViewFederalTaxDocuments')}
            </div>
            <div className={styles.NewUser_toggleSection} id={styles.NewUser_toggleSection_admin}>
              <div>Administration</div>
              <hr />
              {this.permissionsToggleCompose('AdvisorEdit')}
              {this.permissionsToggleCompose('TemplateEdit')}
              {this.permissionsToggleCompose('DataImportSetup')}
              {this.permissionsToggleCompose('DataImport')}
            </div>
            <div className={styles.NewUser_toggleSection} id={styles.NewUser_toggleSection_reports}>
              <div>Reports</div>
              <hr />
              {this.permissionsToggleCompose('AccountReports')}
              {this.permissionsToggleCompose('AdminReports')}
            </div>
            <button hidden type='submit' />
          </form>
        );
      }
      case 2: {
        return (
          <form
            style={{ width: '100%' }}
            onSubmit={this.userCreateHandle}
          >
            <AssignAccounts
              accounts={this.state.accounts}
              onChange={this.accountAssignmentChange}
              allAccountsSelected={this.state.user.ViewAllAccounts}
              disableInputs={this.state.loading}
            />
            <button hidden type='submit' />
          </form>
        );
        /* eslint-enable indent */
      }
      default: break;
    }
  }

  userCreateHandle = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    this.props.userCreate(this.state.user)
      .then(({ payload }) => {
        this.props.notificationShow('User created.', 'success');
        this.setState({
          showPINModal: true,
          pin: payload.data.Pin,
        });
      })
      .catch(() => null)
      .finally(() => this.setState({ loading: false }));
  }

  userUpdate = (key, value) => {
    const updatedUser = cloneDeep(this.state.user);
    const updateInputErrors = cloneDeep(this.state.contactInputErrors);
    const errorIndex = findIndex(this.state.contactInputErrors, { input: key });
    if (errorIndex !== -1) {
      updateInputErrors.splice(errorIndex, 1);
    }
    updatedUser[key] = value;
    this.setState({
      user: updatedUser,
      contactInputErrors: updateInputErrors,
    });
  }

  componentDidMount() {
    const apiCalls = [];
    if (Object.keys(this.props.lpoaSettings).length === 0) {
      apiCalls.push(this.props.lpoaSettingsGet());
    }

    if (this.props.poaAccounts.length === 0) {
      apiCalls.push(this.props.poaAccountsGet());
    }

    if (apiCalls.length > 0) {
      Promise.all(apiCalls)
        .then(() => {
          const user = this.lpoaSettingsMatch(this.state.user);
          const accounts = this.accountsFormat(this.props.poaAccounts);
          accounts.forEach(account => {
            user.POAIds.push(account.id);
          });
          this.setState({
            user,
            accounts,
          });
        })
        .catch(() => null);
    }
    else {
      const user = this.lpoaSettingsMatch(this.state.user);
      const accounts = this.accountsFormat(this.props.poaAccounts);
      accounts.forEach(account => {
        user.POAIds.push(account.id);
      });
      this.setState({
        user,
        accounts,
      });
    }
  }

  render() {
    const { loading } = this.state;
    /* eslint-disable indent */
    return (
      <div className={styles.NewUser_page}>
        <div className={styles.NewUser_container}>
          <Breadcrumbs
            crumbs={[
              {
                title: 'Users',
                link: '/manage-users'
              },
              { title: 'New' }
            ]}
          />
          <Card>
            {this.props.size.windowWidth > 600
              ? <div className={styles.NewUser_stepper}>
                <Stepper activeStep={this.state.activeStep} style={{ width: '100%' }}>
                  <Step>
                    <StepButton onClick={() => loading ? null : this.setState({ activeStep: 0 })}>Contact Info</StepButton>
                  </Step>
                  <Step>
                    <StepButton onClick={() => loading ? null : this.setState({ activeStep: 1 })}>Permissions</StepButton>
                  </Step>
                  <Step>
                    <StepButton onClick={() => loading ? null : this.setState({ activeStep: 2 })}>Assign Accounts</StepButton>
                  </Step>
                </Stepper>
              </div>
              : <div className={styles.NewUser_mobileStepper}>Step {this.state.activeStep + 1} of 3</div>
            }
            {this.stepCompose()}
            {this.buttonsCompose()}
          </Card>
        </div>

        <Modal
          show={this.state.showPINModal}
          title='User PIN'
          onCloseModal={() => this.props.history.push('/manage-users')}
          actionButtons={[{
            label: 'Ok',
            action: () => this.props.history.push('/manage-users'),
          }]}
        >
          <div>The user whose information you entered has been sent an email requesting their participation. Upon accepting your request, the new user will need to enter the PIN provided below to validate their setup. You are responsible for communicating the following PIN to the new user:</div>
          <div className={styles.NewUser_pin}>{this.state.pin}</div>
          <div>The activation email will be valid for the next 10 calendar days.</div>
        </Modal>
      </div>
    );
    /* eslint-enable indent */
  }
}

export default withRouter(connect(select, {
  lpoaSettingsGet,
  poaAccountsGet,
  userCreate,
  notificationShow,
  usersListGet,
})(sizify(NewUser)));
