/*
*
* ProviderTile Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';

import imagePaths from '../images/imagePaths';

import styles from './styles.module.css';


export class ProviderTile extends React.Component {

  static propTypes = {
    provider: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired,
      authorizedDate: PropTypes.string,
    }).isRequired,
    editing: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['backOffice', 'portfolioProvider']),
    onViewAuthorizationClick: PropTypes.func,
  };

  static defaultProps = {
    onViewAuthorizationClick: () => null,
  };

  state = {
    hovering: false,
  };

  render() {
    const { hovering } = this.state;
    const { provider: { id, name, enabled }, editing, onClick, type } = this.props;
    const imagePath = imagePaths[id.toString()];
    let image = null;

    if (imagePath) {
      image = <img src={require(`components/Features/protected/ManageData/SetupData/images${imagePath}`)} alt={name} />;
    }
    
    return (
      <div
        className={`${styles.ProviderTile_providerContainer} ${enabled ? styles.enabled : ''} ${editing ? styles.editing : ''}`}
        onClick={editing ? () => onClick(this.props.provider) : () => null }
        onMouseEnter={() => this.setState({ hovering: true })}
        onMouseLeave={() => this.setState({ hovering: false })}
        onTouchStart={() => this.setState({ hovering: true })}
        onTouchCancel={() => this.setState({ hovering: false })}
      >
        {image
          ? image
          : <span>{name}</span>
        }
        <div className={`${styles.ProviderTile_viewAuthorization} ${!editing && hovering && enabled && type === 'backOffice' ? styles.show : ''}`}>
          {!editing && hovering && enabled && type === 'backOffice' && (
            <Button
              variant='contained'
              onClick={() => this.props.onViewAuthorizationClick(this.props.provider)}
            >
              View Authorization
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default ProviderTile;
