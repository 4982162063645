/*
*
* PrimaryContact Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { orderBy, isEqual, filter } from 'lodash';

import { userDetailsGet, primaryContactsAssign, usersListGet } from '../actions';
import { getAccounts } from 'components/Features/protected/Accounts/actions';

import AssignAccounts from '../AssignAccounts';
import {
  Card,
  Breadcrumbs,
  notificationShow,
  properCase,
  InfoIcon,
  LoadingOverlay,
  StyledLink,
} from '@frontend/common';

import { Button } from '@mui/material';

import styles from './styles.module.css';

const select = (state, { match }) => ({
  user: state.manageUsers.users[match.params.advisorId] || { POAs: [] },
  users: Object.values(state.manageUsers.users),
});

export const primaryContactDescription = 'The Primary Contact is the person my529 contacts about any issues regarding client accounts. By default, the entity’s Master Administrator is the Primary Contact for all accounts, unless a different Primary Contact is selected to an account.';


export class PrimaryContact extends React.Component {

  static propTypes = {
    user: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    userDetailsGet: PropTypes.func.isRequired,
    primaryContactsAssign: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    getAccounts: PropTypes.func.isRequired,
    usersListGet: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    updateLoading: false,
    poas: [],
  };

  poasCompile(poas) {
    return orderBy(poas.map(poa => ({
      ...poa,
      selected: poa.isPrimaryContact,
    })), ['selected'], ['desc']);
  }

  updateHandle = () => {
    const poaIds = filter(this.state.poas, poa => poa.selected).map(poa => poa.id);
    this.setState({ updateLoading: true });
    this.props.primaryContactsAssign(this.props.match.params.advisorId, poaIds)
      .then(() => {
        this.props.notificationShow('Primary Contact assignments updated.', 'success');
        this.props.getAccounts()
          .catch(() => null);
      })
      .catch(() => null)
      .finally(() => this.setState({ updateLoading: false }));
  }
  
  componentDidMount() {
    const { advisorId } = this.props.match.params;
    if (!this.props.user.dataComplete) {
      this.setState({ loading: true });
      this.props.userDetailsGet(advisorId)
        .then(() => {
          const { user, users } = this.props;
          this.setState({
            loading: false,
            poas: this.poasCompile(user.POAs),
          });

          if (users.length === 0 || (users.length === 1 && users[0].advisorId === user.advisorId)) {
            this.props.usersListGet()
              .catch(() => null);
          }
        })
        .catch(() => this.setState({ loading: false }));
    }
    else {
      this.setState({
        poas: this.poasCompile(this.props.user.POAs),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.poasCompile(this.props.user.POAs), this.poasCompile(prevProps.user.POAs))) {
      this.setState({
        poas: this.poasCompile(this.props.user.POAs),
      });
    }
  }

  render() {
    const { user } = this.props;
    const { loading, updateLoading, poas } = this.state;
    return (
      <div className={styles.PrimaryContact_page}>
        <div className={styles.PrimaryContact_breadcrumbs}>
          {loading && (
            <LoadingOverlay
              show={loading}
              width='100%'
              indicatorHeight='10px'
            />
          )}
          <Breadcrumbs
            crumbs={[
              {
                title: 'Users',
                link: '/manage-users'
              },
              { title: 'Primary Contact' }
            ]}
          />
        </div>
        <Card className={styles.PrimaryContact_assignCard}>
          <div className={styles.PrimaryContact_cardTitle}>
            <span>Assign Primary Contact</span>
            <InfoIcon message={primaryContactDescription} />
          </div>
          <div className={styles.PrimaryContact_name}>
            {loading ? '' : properCase(`${user.FirstName} ${user.LastName}`)}
          </div>
          {(!loading && poas.length > 0) && [
            <AssignAccounts
              key='AssignAccounts'
              accounts={poas}
              onChange={(updatedPOAs) => this.setState({ poas: updatedPOAs }) }
              disableInputs={updateLoading}
            />,
            <div
              key='saveButton'
              className={styles.PrimaryContact_saveButton}
            >
              <LoadingOverlay
                show={updateLoading}
              >
                <Button
                  variant='contained'
                  disabled={isEqual(poas, this.poasCompile(user.POAs)) || updateLoading}
                  onClick={this.updateHandle}
                >
                  Save
                </Button>
              </LoadingOverlay>
            </div>
          ]}
          {(!loading && poas.length === 0) && (
            <div className={styles.PrimaryContact_noAccountsMessage}>
              The user currently has no accounts selected and therefore cannot be set as a Primary Contact. You can assign accounts on the<br />
              <StyledLink to={`/manage-users/${this.props.match.params.advisorId}`}>user&apos;s details page.</StyledLink>
            </div>
          )}
        </Card>
      </div>
    );
  }
}

export default connect(select, {
  userDetailsGet,
  primaryContactsAssign,
  notificationShow,
  getAccounts,
  usersListGet
})(PrimaryContact);
