/*
*
* StaticResources Actions
*
*/
import {
  PASSWORD_REQUIREMENTS_GET,
  RELATIONSHIPS_GET,
  OPTIONS_GET,
  CUSTOM_OPTIONS_GET,
  POA_LEVELS_GET,
  AGE_BRACKETS_GET,
  ENVIRONMENT_VARIABLES_GET,
  STATIC_DOCUMENTS_GET,
  SERVER_DATETIME_GET,
  WEB_MESSAGES_GET,
  SET_LOADING_PUBLIC_VARS,
  GET_NEW_AGE_BASED_SCHEMA,
  GET_NEW_STATIC_SCHEMA,
} from './constants';

import { GETrequest } from 'utils/helpers/api_handler';


export function passwordRequirementsGet() {
  const response = GETrequest('/authentication/passwordrequirements');
  return {
    type: PASSWORD_REQUIREMENTS_GET,
    payload: response,
  };
}

export function relationshipsGet() {
  const response = GETrequest('/scalar/relations');
  return {
    type: RELATIONSHIPS_GET,
    payload: response,
  };
}

export function optionsGet() {
  const response = GETrequest('/scalar/options');
  return {
    type: OPTIONS_GET,
    payload: response,
  };
}

export function customOptionsGet() {
  const response = GETrequest('/scalar/customizedoptions');
  return {
    type: CUSTOM_OPTIONS_GET,
    payload: response,
  };
}

export function getNewAgeBasedSchema() {
  const response = GETrequest('/template/new?typeCode=a');
  return {
    type: GET_NEW_AGE_BASED_SCHEMA,
    payload: response
  };
}

export function getNewStaticSchema() {
  const response = GETrequest('/template/new?typeCode=s');
  return {
    type: GET_NEW_STATIC_SCHEMA,
    payload: response
  };
}

export function poaLevelsGet() {
  const response = GETrequest('/scalar/poalevels');
  return {
    type: POA_LEVELS_GET,
    payload: response,
  };
}

export function getAgeBrackets() {
  const response = GETrequest('/template/AgeBrackets');
  return {
    type: AGE_BRACKETS_GET,
    payload: response
  };
}

export function getEnvironmentVariables(params) {
  return {
    type: ENVIRONMENT_VARIABLES_GET,
    payload: params ? GETrequest(`/Parameters?name=${params.join('&name=')}`) : GETrequest('/Parameters'),
  };
}

export function getStaticDocuments() {
  const payload = GETrequest('/forms');
  return {
    type: STATIC_DOCUMENTS_GET,
    payload,
  };
}

export function getServerDateTime() {
  const payload = GETrequest('/parameters/serverdatetime');
  return {
    type: SERVER_DATETIME_GET,
    payload,
  };
}

export function getWebMessages() {
  const payload = GETrequest('/WebsiteMessage');
  return {
    type: WEB_MESSAGES_GET,
    payload
  };
}

export function setLoadingPublicVars(isLoading) {
  return {
    type: SET_LOADING_PUBLIC_VARS,
    payload: { isLoading },
  };
}