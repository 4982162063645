/*
*
* UsersList Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import { partition } from 'lodash';
import {
  Tabs,
  Tab,
} from '@mui/material';
import {
  properCase,
  FloatingActionButton,
  notificationShow,
  ConfirmModal,

  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';

import {
  usersListGet,
  deactivateUser,
  reactivateUser,
  activationEmailResend,
} from '../actions';
import { getAccounts } from 'components/Features/protected/Accounts/actions';

import events from 'utils/ga';

export const select = (state) => ({
  users: Object.values(state.manageUsers.users).map(user => ({
    id: user.advisorId,
    name: `${properCase(user.FirstName)} ${properCase(user.LastName)}`,
    email: user.EmailAddress,
    status: user.status,
    lastLogin: user.lastLogin
      ? dayjs(user.lastLogin).format('MM/DD/YYYY h:mm A')
      : 'Never',
    deactivationDate: user.DeactivationDate
      ? dayjs(user.DeactivationDate).format('MM/DD/YYYY h:mm A')
      : 'Never',
    activationDate: user.ActivationDate
      ? dayjs(user.ActivationDate).format('MM/DD/YYYY h:mm A')
      : 'Never',
  })),
  session: state.session,
});

export class UsersList extends React.Component {

  static propTypes = {
    users: PropTypes.array.isRequired,
    usersListGet: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    deactivateUser: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    activationEmailResend: PropTypes.func.isRequired,
    reactivateUser: PropTypes.func.isRequired,
    session: PropTypes.object.isRequired,
    getAccounts: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    selectedUserId: null,
    selectedUserName: '',
    showDeactivateConfirmationModal: false,
    deactivateUserLoading: false,
    showResendEmailModal: false,
    resendEmailLoading: false,
    activeTab: 'active',
    showReactivateConfirmationModal: false,
    reactivateUserLoading: false,
  };

  reactivateUserHandler = () => {
    this.setState({ reactivateUserLoading: true });
    this.props.reactivateUser(this.state.selectedUserId)
      .then(() => {
        this.props.notificationShow('User reactivated.', 'success');
        const reactivatedUserId = this.state.selectedUserId;
        this.setState({
          reactivateUserLoading: false,
          showReactivateConfirmationModal: false,
          selectedUserId: null,
        }, () => {
          this.setState({ selectedUserName: '' });
          // redirect to the just reactivated user details
          this.props.history.push(`/manage-users/${reactivatedUserId}`);
        });
      })
      .catch(() => this.setState({ reactivateUserLoading: false }));
  };

  deactivateUserHandler = () => {
    this.setState({ deactivateUserLoading: true });
    this.props.deactivateUser(this.state.selectedUserId)
      .then(() => {
        this.props.notificationShow('User deactivated.', 'success');
        this.props.getAccounts()
          .catch(() => null);
        this.setState({
          deactivateUserLoading: false,
          showDeactivateConfirmationModal: false,
          selectedUserId: null,
        }, () => {
          this.setState({ selectedUserName: '' });
        });
      })
      .catch(() => this.setState({ deactivateUserLoading: false }));
  };

  resendActivationEmailHandle = (advisorId) => {
    this.setState({ resendEmailLoading: true });
    this.props.activationEmailResend(advisorId)
      .then(() => {
        this.props.notificationShow('Activation email sent.', 'success');
        this.setState({
          selectedUserId: null,
          showResendEmailModal: false,
        }, () => {
          this.setState({
            selectedUserName: '',
            resendEmailLoading: false,
          });
        });
      })
      .catch(() => this.setState({ resendEmailLoading: false }));
  };

  componentDidMount() {
    if (this.props.users.length === 0) {
      this.setState({ loading: true });
      this.props.usersListGet()
        .catch(() => null)
        .finally(() => this.setState({ loading: false }));
    }
  }

  render() {
    const {
      selectedUserName,
      selectedUserId,
      showDeactivateConfirmationModal,
      deactivateUserLoading,
      loading,
      activeTab,
      showReactivateConfirmationModal,
      reactivateUserLoading,
    } = this.state;
    const { users, session } = this.props;
    const usersPartition = partition(users, user => user.status !== 'Deactivated'); // [[activeUsers], [deactivatedUsers]]

    return (
      <div>
        <div style={{ fontWeight: 'bold', fontSize: 'bigger', textAlign: 'center', padding: '15px' }}>Master Administrator: {session.claims.MasterAdminName}</div>
        <Tabs
          value={activeTab}
          onChange={(e, value) => this.setState({ activeTab: value })}
          variant='fullWidth'
        >
          <Tab
            label='Active'
            value='active'
          />
          <Tab
            label='Deactivated'
            value='deactivated'
          />
        </Tabs>
        {/* There are no CSS styles for UsersList_table */}
        <div className='UsersList_table'>
          {activeTab === 'active' && (
            <SmartTable
              idKey='id'
              rows={usersPartition[0]}
              loading={loading}
              columns={[
                {
                  key: 'name',
                  title: 'Name',
                  type: 'string',
                },
                {
                  key: 'email',
                  title: 'Email',
                  type: 'string',
                  hideOn: ['phone', 'tablet']
                },
                {
                  key: 'status',
                  title: 'Status',
                  type: 'string',
                  hideOn: ['phone']
                },
                {
                  key: 'lastLogin',
                  title: 'Last Login',
                  type: 'string',
                },
                {
                  key: 'activationDate',
                  title: 'Activation Date',
                  type: 'string',
                },
              ]}
              actions={[
                {
                  displayName: 'View Advisor',
                  type: 'menu',
                  onSelect: row => this.props.history.push(`/manage-users/${row.id}`),
                },
                {
                  displayName: 'Assign Primary Contact',
                  type: 'menu',
                  onSelect: row => this.props.history.push(`/manage-users/${row.id}/primary-contact`),
                },
                {
                  displayName: 'Resend Activation Email',
                  type: 'menu',
                  onSelect: row => this.setState({
                    showResendEmailModal: true,
                    selectedUserName: row.name,
                    selectedUserId: row.id,
                  }),
                  showIf: row => row.status === 'Unactivated',
                },
                {
                  displayName: 'Deactivate',
                  type: 'menu',
                  onSelect: row => this.setState({
                    selectedUserName: row.name,
                    selectedUserId: row.id,
                    showDeactivateConfirmationModal: true,
                  }),
                  showIf: row => row.id !== parseInt(session.claims.FinancialAdvisorId),
                },
              ]}
            >
              <TableToolbar
                onSearchFocus={() => events.tableSearchAccessed('Users List', window.location.pathname)}
              />
              <TableContainer minWidth='100%' maxHeight='100%'>
                <TableHeader />
                <TableRows />
              </TableContainer>
              <TablePagination />
            </SmartTable>
          )}
          {activeTab === 'deactivated' && (
            <SmartTable
              idKey='id'
              rows={usersPartition[1]}
              loading={loading}
              columns={[
                {
                  key: 'name',
                  title: 'Name',
                  type: 'string',
                },
                {
                  key: 'email',
                  title: 'Email',
                  type: 'string',
                  hideOn: ['phone', 'tablet']
                },
                {
                  key: 'status',
                  title: 'Status',
                  type: 'string',
                  hideOn: ['phone']
                },
                {
                  key: 'deactivationDate',
                  title: 'Deactivated Date',
                  type: 'string',
                },
              ]}
              actions={[
                {
                  displayName: 'View Advisor',
                  type: 'menu',
                  onSelect: row => this.props.history.push(`/manage-users/${row.id}`),
                },
                {
                  displayName: 'Reactivate',
                  type: 'menu',
                  onSelect: row => this.setState({
                    selectedUserName: row.name,
                    selectedUserId: row.id,
                    showReactivateConfirmationModal: true,
                  }),
                },
              ]}
            >
              <TableToolbar
                onSearchFocus={() => events.tableSearchAccessed('Users List', window.location.pathname)}
              />
              <TableContainer minWidth='100%' maxHeight='100%'>
                <TableHeader />
                <TableRows />
              </TableContainer>
              <TablePagination />
            </SmartTable>
          )}
        </div>

        <ConfirmModal
          show={showDeactivateConfirmationModal}
          title={'Deactivate User'}
          body={`Are you sure you want to deactivate ${selectedUserName ? selectedUserName : 'this user'}?`}
          onModalClose={() => this.setState({ showDeactivateConfirmationModal: false })}
          onConfirm={() => this.deactivateUserHandler()}
          isLoading={deactivateUserLoading}
        />

        <ConfirmModal
          show={showReactivateConfirmationModal}
          title={'Reactivate User'}
          body={`Are you sure you want to reactivate ${selectedUserName ? selectedUserName : 'this user'}?`}
          onModalClose={() => this.setState({ showReactivateConfirmationModal: false })}
          onConfirm={() => this.reactivateUserHandler()}
          isLoading={reactivateUserLoading}
        />

        <ConfirmModal
          show={this.state.showResendEmailModal}
          title='Resend Email'
          body={`Are you sure you want to resend an activation email to ${selectedUserName ? selectedUserName : 'this user'}?`}
          onModalClose={() => this.setState({ showResendEmailModal: false, })}
          onConfirm={() => this.resendActivationEmailHandle(selectedUserId)}
          isLoading={this.state.resendEmailLoading}
        />

        <FloatingActionButton
          hasMenu={false}
          mainIcon='add'
          mainButtonTitle='Add User'
          mainButtonAction={() => this.props.history.push('/manage-users/new')}
        />
      </div>
    );
  }
}

export default withRouter(connect(select, {
  usersListGet,
  deactivateUser,
  notificationShow,
  activationEmailResend,
  reactivateUser,
  getAccounts,
})(UsersList));
