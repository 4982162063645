/*
*
* WithdrawalDetails Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card,
  InfoIcon,
  LoadingOverlay,
} from '@frontend/common';
import styles from './styles.module.css';
import AvailableBalanceInfo from '../../AvailableBalanceInfo';

const select = (state) => ({
  withdrawalDetails: state.accounts.withdrawalDetails
});

export class WithdrawalDetails extends React.Component {

  static propTypes = {
    isProportional: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    withdrawalDetails: PropTypes.object.isRequired,
  };

  render() {
    const { isProportional, loading, withdrawalDetails } = this.props;
    const availableBalanceMsg = (
      <div>
        Withdrawals can be made only from contributed funds that have posted to your account(s) for at least 7 days.

        <p>The Available Balance is the current total value of the account minus pending transactions.</p>

        <AvailableBalanceInfo />
      </div>
    );


    return (
      <div className={styles.WithdrawalDetails_container}>
        {loading ?
          <div className={styles.WithdrawalDetails_loading}>
            <LoadingOverlay
              show={loading}
              width='100%'
              indicatorHeight='10px'
            />
          </div>
          :
          null
        }
        {isProportional ?
          <Card
            className={styles.WithdrawalDetails_accountDetailsCard}
            title='Account Details'
          >
            <div className={styles.WithdrawalDetails_cardContentContainerProportional}>
              <div className={styles.WithdrawalDetails_accountOwner}>
                <div className={styles.WithdrawalDetails_detailsTitle}>Account Owner</div>
                {!loading && withdrawalDetails.agentName}
              </div>
              <div className={styles.WithdrawalDetails_beneficiary}>
                <div className={styles.WithdrawalDetails_detailsTitle}>Beneficiary</div>
                {!loading && withdrawalDetails.beneficiaryName}
              </div>
              <div className={styles.WithdrawalDetails_acctNum}>
                <div className={styles.WithdrawalDetails_detailsTitle}>Account Numbers</div>
                {!loading && withdrawalDetails.groupAccounts.map(account => <div key={account.accountId}>{account.accountNumber}</div>)}
              </div>
              <div className={styles.WithdrawalDetails_acctType}>
                <div className={styles.WithdrawalDetails_detailsTitle}>Account Type</div>
                {!loading && withdrawalDetails.accountType}
              </div>
              <div className={styles.WithdrawalDetails_groupAvailableBalance}>
                <span className={styles.WithdrawalDetails_groupAvailableBalanceInfo}>
                  <div className={styles.WithdrawalDetails_detailsTitle}>Total Available Balance</div>
                  <InfoIcon message={availableBalanceMsg} />
                </span>
                {!loading && withdrawalDetails.totalValue.display}
              </div>
            </div>
          </Card>
          :
          <Card
            className={styles.WithdrawalDetails_accountDetailsCard}
            title='Account Details'
          >
            <div className={styles.WithdrawalDetails_cardContentContainerNonProportional}>
              <div className={styles.WithdrawalDetails_accountOwner}>
                <div className={styles.WithdrawalDetails_detailsTitle}>Account Owner</div>
                {!loading && withdrawalDetails.agentName}
              </div>
              <div className={styles.WithdrawalDetails_beneficiary}>
                <div className={styles.WithdrawalDetails_detailsTitle}>Beneficiary</div>
                {!loading && withdrawalDetails.beneficiaryName}
              </div>
              <div className={styles.WithdrawalDetails_acctNum}>
                <div className={styles.WithdrawalDetails_detailsTitle}>Account Number</div>
                {!loading && withdrawalDetails.accountNumber}
              </div>
              <div className={styles.WithdrawalDetails_currentOpt}>
                <div className={styles.WithdrawalDetails_detailsTitle}>Current Option</div>
                {!loading && withdrawalDetails.optionDescription}
              </div>
              <div className={styles.WithdrawalDetails_acctType}>
                <div className={styles.WithdrawalDetails_detailsTitle}>Account Type</div>
                {!loading && withdrawalDetails.accountType}
              </div>
              <div className={styles.WithdrawalDetails_marketVal}>
                <div className={styles.WithdrawalDetails_detailsTitle}>Market Value</div>
                {!loading && withdrawalDetails.marketValue}
              </div>
              <div className={styles.WithdrawalDetails_availableBalance}>
                <div className={styles.WithdrawalDetails_availableBalanceInfo}>
                  <div className={styles.WithdrawalDetails_detailsTitle}>Available Balance</div>
                  <InfoIcon message={availableBalanceMsg} />
                </div>
                <div className={!withdrawalDetails.availableBalance.number ? styles.WithdrawalDetails_availableBalanceIsZero : ''}>{!loading && withdrawalDetails.availableBalance.display}</div>
              </div>
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default connect(select, {})(WithdrawalDetails);
