export function saveFile(file, fileName) {
  const hiddenElement = document.createElement('a');
  const fileURL = URL.createObjectURL(file);
  hiddenElement.href = fileURL;
  hiddenElement.download = fileName || 'download';
  document.body.appendChild(hiddenElement);
  hiddenElement.click();
  setTimeout(() => {
    document.body.removeChild(hiddenElement);
    URL.revokeObjectURL(fileURL);
  }, 50);
}