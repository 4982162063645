import {
  HIT,
  NAVIGATION
} from '../constants';
import { urlScrub } from '../helpers';

export function helpAccessed(url) {
  return {
    eventCategory: NAVIGATION,
    eventAction: HIT,
    eventLabel: urlScrub(url),
  };
}

export function resourceAccessed(resourceName) {
  return {
    eventCategory: NAVIGATION,
    eventAction: HIT,
    eventLabel: resourceName,
  };
}