// Google Analytics using Autotrack plugin
import 'autotrack/lib/plugins/event-tracker';
import 'autotrack/lib/plugins/url-change-tracker';
import 'autotrack/lib/plugins/clean-url-tracker';

import { urlScrub } from './helpers';

const ga = window.ga;

ga('create', 'UA-87240905-2', 'auto');
ga('require', 'eventTracker');
ga('require', 'urlChangeTracker');
ga('require', 'cleanUrlTracker', {
  stripQuery: true,
  queryDimensionIndex: 1,
  indexFilename: 'index.html',
  trailingSlash: 'remove',
  urlFieldsFilter: (fieldsObj, parseUrl) => {
    fieldsObj.page = urlScrub(parseUrl(fieldsObj.page).pathname);
    return fieldsObj;
  },
});
ga('send', 'pageview');