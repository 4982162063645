/*
*
* NewSpecial Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { clone } from 'lodash';
import dayjs from 'dayjs';
import {
  Card,
  currencyFormatter,
  CurrencyInput,
  DatePicker,
  Dropdown,
  InfoIcon,
  LoadingOverlay,
} from '@frontend/common';
import {
  getNextRunDate
} from '../../../actions';
import {
  BIRTHDAY,
  ANNUAL_EVENT,
  YEARLY
} from '../constants';
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  Icon,
  Radio,
  RadioGroup,
  Button,
  Step,
  StepButton,
  Stepper,
  Tooltip,
} from '@mui/material';
import TermsConditions from '../TermsConditions';
import events from 'utils/ga';

import transactionsStyles from '../../styles.module.css';
import styles from './styles.module.css';

const select = (state) => ({
  contributionDetails: state.accounts.contributionDetails,
  contributionVariables: state.static.environmentVars.contributionVariables,
  nextRunDates: state.accounts.nextRunDates.yearly,
});

export class NewSpecial extends React.Component {
  static propTypes = {
    bankAccountsLoading: PropTypes.bool.isRequired,
    contributionDetails: PropTypes.object.isRequired,
    contributionCreate: PropTypes.func.isRequired,
    contributionEdit: PropTypes.func.isRequired,
    contributionVariables: PropTypes.object.isRequired,
    createContributionLoading: PropTypes.bool.isRequired,
    getNextRunDate: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    nextRunDates: PropTypes.object.isRequired,
    onAddBankAccountModalOpen: PropTypes.func.isRequired,
    smallWidth: PropTypes.bool.isRequired,
    serverDateTime: PropTypes.string,
  };

  state = {
    contribution: {
      bankAccount: {
        id: '',
        name: '',
        number: '',
      },
      day1: '',
      occasion: BIRTHDAY.id,
      selectedAccounts: [],
      type: YEARLY
    },
    errors: [],
    nextRunDateLoading: false,
    stepIndex: 0,
    step0ValidationHasRun: false,
    step1ValidationHasRun: false,
    termsChecked: false,
  }

  accountSelect = (value, rowIndex) => {
    const { contribution: { selectedAccounts } } = this.state;
    const matchingAccount = this.props.contributionDetails.agentAccounts.find(agentAccount => agentAccount.accountId === value);

    const accountUpdatedWithInputData = {
      ...matchingAccount,
      contributionAmount: selectedAccounts[rowIndex].contributionAmount,
      endDate: selectedAccounts[rowIndex].endDate
    };

    selectedAccounts.splice(rowIndex, 1, accountUpdatedWithInputData);

    this.contributionSet('selectedAccounts', selectedAccounts);
  }

  addAccount = () => {
    const { contribution: { selectedAccounts } } = this.state;
    const newSelectedAccounts = [...selectedAccounts, { accountId: '', contributionAmount: 0, endDate: '' }];
    this.contributionSet('selectedAccounts', newSelectedAccounts);
  }

  amountHandle = ({ floatValue = 0 }, rowIndex) => {
    const { contribution: { selectedAccounts } } = this.state;

    selectedAccounts[rowIndex].contributionAmount = floatValue;
    this.contributionSet('selectedAccounts', selectedAccounts);
  }

  bankAccountSelectHandle = (value) => {
    this.contributionSet('bankAccount', this.props.contributionDetails.agentBankAccounts.find(bankAccount => bankAccount.id === value));
  }

  contributionDateHandle = (date, day, rowIndex) => {
    const { contribution: { selectedAccounts } } = this.state;
    const account = selectedAccounts[rowIndex];
    const formattedDate = dayjs(date).format('L');

    switch (day) {
      case 'endDate':
        account.endDate = formattedDate;
        this.contributionSet('selectedAccounts', selectedAccounts);

        if (Object.prototype.hasOwnProperty.call(account, 'beneficiaryBday') && date !== '') {
          this.nextRunDateGet({}, BIRTHDAY.id);
        }
        break;
      case 'day1':
        // wait till user finishes typing the date
        this.contributionSet(day, formattedDate);
        if (dayjs(formattedDate).isValid()) {
          this.nextRunDateGet(formattedDate, day);
        }
        break;
      default: // do nothing
    }
  }

  contributionSet = (key, value) => {
    this.setState({ contribution: { ...this.state.contribution, [key]: value } }, () => {
      if (
        (this.state.stepIndex === 0 && this.state.step0ValidationHasRun) ||
        (this.state.stepIndex === 1 && this.state.step1ValidationHasRun)
      ) {
        this.formValidate();
      }
    });
  }

  errorGet = (key, row) => {
    const error = this.state.errors.find(error => error.key === key && error.row === row);
    return error ? error.message : '';
  }

  formValidate = (nextStep = () => null) => {

    switch (this.state.stepIndex) {
      case 0: {
        const { contributionVariables: { maxOneTimeContribution, minOneTimeContribution, firstAvailTradeDate }, nextRunDates } = this.props;
        this.setState(({ contribution: { day1, occasion, selectedAccounts } }) => {
          const errors = [];
          selectedAccounts.forEach(({ accountId, contributionAmount, endDate, nextBdayRunDate }, index) => {
            if ((accountId && contributionAmount > maxOneTimeContribution) || (accountId && contributionAmount < minOneTimeContribution)) { // validates amounts against min and max parameters
              errors.push({
                key: 'amount',
                message: 'Enter a valid amount.',
                row: index
              });
            }
            if (!accountId && contributionAmount) { // validates all accounts that also have amounts entered have a beneficiary selected
              errors.push({
                key: 'account',
                message: 'Select an account or remove the row.',
                row: index
              });
            }
            if (occasion === ANNUAL_EVENT.id) { // validates day1 is selected
              if (!day1) {
                errors.push({
                  key: 'day1',
                  message: 'Start date is required.'
                });
              }
              else if (!dayjs(day1).isValid()) { // validate date format
                errors.push({
                  key: 'day1',
                  message: 'Invalid Date Format'
                });
              }
              else if (!dayjs(day1).isBetween(dayjs(firstAvailTradeDate), dayjs(firstAvailTradeDate).add(1, 'y'), null, '[)')) {
                errors.push({
                  key: 'day1',
                  message: `Start date should be between ${dayjs(firstAvailTradeDate).format('MM/DD/YYYY')} and ${dayjs(firstAvailTradeDate).add(1, 'y')
                    .subtract(1, 'd')
                    .format('MM/DD/YYYY')}.`
                });
              }
            }
            if (endDate && !dayjs(endDate).isValid()) { // validate date format
              errors.push({
                key: 'endDate',
                message: 'Invalid Date Format',
                row: index
              });
            }
            else if ( // validates end date
              (occasion === ANNUAL_EVENT.id && dayjs(endDate).isSameOrBefore(nextRunDates.day1, 'day')) ||
              (occasion === BIRTHDAY.id && dayjs(endDate).isSameOrBefore(nextBdayRunDate, 'day'))
            ) {
              errors.push({
                key: 'endDate',
                message: 'End date must occur after start date.',
                row: index
              });
            }
          });
          return { errors, step0ValidationHasRun: true };
        }, () => this.state.errors.length === 0 && nextStep());
        break;
      }
      case 1: {
        this.setState(({ contribution: { bankAccount } }) => {
          const errors = [];
          if (!bankAccount.id) {
            errors.push({
              key: 'bankAccount',
              message: 'Select an existing bank account or add a new one.',
            });
          }
          return { errors, step1ValidationHasRun: true };
        }, () => this.state.errors.length === 0 && nextStep());
        break;
      }
      default: break;
    }
  }

  nextRunDateGet = (date, day) => {
    this.setState({ nextRunDateLoading: true });

    if (day === BIRTHDAY.id) {
      const { selectedAccounts } = this.state.contribution;
      const selectedAccountsWithBirthdays = selectedAccounts.filter(account => account.beneficiaryBday);
      const birthdays = selectedAccountsWithBirthdays.map(account => account.beneficiaryBday);

      this.props.getNextRunDate(birthdays, day, YEARLY)
        .then(() => {
          this.props.nextRunDates.birthday.forEach((bday, index) => {
            selectedAccountsWithBirthdays[index].nextBdayRunDate = bday;
          });
          this.contributionSet('selectedAccounts', selectedAccounts);
          this.setState({ nextRunDateLoading: false });
        })
        .catch(() => this.setState({ nextRunDateLoading: false }));
    }
    else {
      this.props.getNextRunDate(date, day, YEARLY)
        .then(() => {
          this.setState({ nextRunDateLoading: false });
        })
        .catch(() => this.setState({ nextRunDateLoading: false }));
    }
  }

  nextStepHandle = () => {
    this.setState({ stepIndex: this.state.stepIndex + 1 });
    window.scrollTo(0, 0);
    !this.props.isEditing && events.contributionsSpecialStep(this.state.stepIndex + 1);
  }

  prevStepHandle = () => {
    this.setState({ stepIndex: this.state.stepIndex - 1 });
    window.scrollTo(0, 0);
    !this.props.isEditing && events.contributionsSpecialStep(this.state.stepIndex - 1);
  }

  removeAccount = (rowIndex) => {
    const { contribution: { selectedAccounts } } = this.state;
    selectedAccounts.splice(rowIndex, 1);
    this.contributionSet('selectedAccounts', selectedAccounts);
  }

  step0ContentCompose = () => {
    /* eslint-disable indent */
    const { contribution: { day1, occasion, selectedAccounts }, errors } = this.state;
    const { contributionDetails: { agentAccounts }, contributionVariables: { firstAvailTradeDate }, isEditing, nextRunDates } = this.props;

    return (
      <div>
        <div className={styles.NewSpecial_occasionSelectionContainer}>
          <RadioGroup
            label='Occasion Type'
            name='Occasion Type'
            onChange={(e) => this.contributionSet('occasion', e.target.value)}
            value={occasion}
          >
            <FormControlLabel
              control={<Radio disabled={isEditing && occasion !== BIRTHDAY.id} />}
              label={BIRTHDAY.display}
              value={BIRTHDAY.id}
            />
            <FormControlLabel
              control={<Radio disabled={isEditing && occasion !== ANNUAL_EVENT.id} />}
              label={
                <span className={styles.NewSpecial_radioButtonLabel}>
                  <div className={styles.NewSpecial_radioButtonsLabelText}>{ANNUAL_EVENT.display}</div>
                  {/* There are no CSS styles for NewSpecial_radioButtonsInfoIcon */}
                  <div className='NewSpecial_radioButtonsInfoIcon'>
                    <InfoIcon message='To create multiple annual event contributions, complete this contribution and repeat the process.' />
                  </div>
                </span>
              }
              value={ANNUAL_EVENT.id}
            />
          </RadioGroup>

          <Collapse in={occasion === ANNUAL_EVENT.id} className={styles.NewSpecial_annualEventDateContainer}>
            <DatePicker
              error={Boolean(this.errorGet('day1'))}
              helperText={this.errorGet('day1')}
              label='Start Date'
              minDate={dayjs(firstAvailTradeDate)}
              maxDate={dayjs(firstAvailTradeDate).add(1, 'y').subtract(1, 'd')} // eslint-disable-line
              onChange={date => this.contributionDateHandle(date, 'day1')}
              value={day1 ? day1 : null}
            />
            {dayjs(nextRunDates.day1).isValid() && dayjs(day1).isValid() && <small>Next run date: {dayjs(nextRunDates.day1).format('L')}</small>}
          </Collapse>
        </div>

        <div className={styles.NewSpecial_step0Container}>
          {selectedAccounts.map((selectedAccount, rowIndex) => {
            return (
              <div key={rowIndex} className={occasion === BIRTHDAY.id ? styles.NewSpecial_step0Birthday : styles.NewSpecial_step0AnnualEvent}>
                <div className={styles.NewSpecial_accounts}>
                  <Dropdown
                    label='Selected Account'
                    value={selectedAccount.accountId}
                    errorText={this.errorGet('account', rowIndex)}
                    onChange={value => this.accountSelect(value, rowIndex)}
                    options={(isEditing ? selectedAccounts : agentAccounts).map(acct => ({
                      value: acct.accountId,
                      display: `${acct.beneficiaryName} ...${acct.accountNumber.slice(acct.accountNumber.length - 5)}`,
                      props: isEditing
                        ? {}
                        : { disabled: selectedAccounts.some(account => account.accountId === acct.accountId) && selectedAccounts[rowIndex].accountId !== acct.accountId }
                    }))}
                    FormControlProps={{ style: { width: '100%', minWidth: '256px', margin: '0px' } }}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>

                <div className={styles.NewSpecial_amount}>
                  <CurrencyInput
                    className={styles.NewSpecial_currencyInput}
                    errorText={this.errorGet('amount', rowIndex)}
                    label='Amount'
                    onChange={vals => this.amountHandle(vals, rowIndex)}
                    style={{ margin: '0px' }}
                    value={selectedAccounts[rowIndex].contributionAmount}
                  />
                </div>

                {occasion === BIRTHDAY.id &&
                  <div className={styles.NewSpecial_birthdayContainer}>
                    <div className={styles.NewSpecial_birthday} id='birthday'>
                      {selectedAccount.beneficiaryBday && dayjs(selectedAccount.beneficiaryBday).format('MMM D')}
                    </div>
                    <label htmlFor='birthday' className={styles.NewSpecial_birthdayLabel}>Birthday</label>
                  </div>
                }

                <div className={styles.NewSpecial_endDateContainer}>
                  <DatePicker
                    error={Boolean(this.errorGet('endDate', rowIndex))}
                    helperText={this.errorGet('endDate', rowIndex)}
                    label='Optional End Date'
                    minDate={dayjs(firstAvailTradeDate)}
                    onChange={date => this.contributionDateHandle(date, 'endDate', rowIndex)}
                    sx={{ margin: '0px', width: '100%' }}
                    value={selectedAccount.endDate || null}
                  />
                </div>

                {rowIndex === 0 ?
                  <div className={styles.NewSpecial_hideIcon}>
                    <Icon className={styles.NewSpecial_icon}>remove_circle</Icon>
                  </div>
                  :
                  <div className={styles.NewSpecial_removeAccount}>
                    <Tooltip
                      title='Remove account'
                      placement='top'
                    >
                      <Icon onClick={() => this.removeAccount(rowIndex)} className={styles.NewSpecial_icon}>remove_circle</Icon>
                    </Tooltip>
                  </div>
                }
              </div>
            );
          })}

          {agentAccounts.length !== selectedAccounts.length && !isEditing && (
            <Tooltip
              title='Add account'
              placement='top'
            >
              <Icon onClick={() => this.addAccount()} className={styles.NewSpecial_icon} style={{ marginTop: '25px' }}>add_circle</Icon>
            </Tooltip>
          )}

          <div className={styles.NewSpecial_total}>
            {errors.some(error => error.key === 'amount') && <div className={styles.NewSpecial_totalErrors}>{`Enter an amount between ${currencyFormatter(this.props.contributionVariables.minOneTimeContribution)} and ${currencyFormatter(this.props.contributionVariables.maxOneTimeContribution)}.`}</div>}
            <div className={styles.NewSpecial_totalAmountStep0}>
              <div>Total:</div>
              <div>{currencyFormatter(selectedAccounts.map(account => account.contributionAmount).reduce((val1, val2) => val1 + val2, 0))}</div>
            </div>
          </div>
        </div>
      </div>
    );
    /* eslint-enable indent */
  }

  step1ContentCompose = () => {
    const { bankAccountsLoading, contributionDetails: { agentBankAccounts }, onAddBankAccountModalOpen } = this.props;
    const bankAccountError = this.errorGet('bankAccount');
    return (
      <div>
        {agentBankAccounts.length > 0 ?
          <div className={styles.NewSpecial_bankAccount}>
            <LoadingOverlay show={bankAccountsLoading}>
              <Dropdown
                label='Bank Account'
                value={this.state.contribution.bankAccount.id}
                errorText={bankAccountError}
                onChange={this.bankAccountSelectHandle}
                options={agentBankAccounts.map(acct => ({
                  value: acct.id,
                  display: acct.id === 0 ? `${acct.name}` : `${acct.name}, ${acct.number}`,
                }))}
                FormControlProps={{ style: { width: '100%', minWidth: '256px' } }}
              />
            </LoadingOverlay>
            <Tooltip
              title='Add new bank account'
              placement='top'
            >
              <Icon onClick={onAddBankAccountModalOpen} className={styles.NewSpecial_icon}>add</Icon>
            </Tooltip>
          </div>
          :
          <div className={styles.NewSpecial_bankAccount}>
            <Button onClick={onAddBankAccountModalOpen} variant='contained'>
              Add Bank Account
            </Button>
          </div>
        }
      </div>
    );
  }

  step2ContentCompose = () => {
    const { contribution: { bankAccount, occasion, selectedAccounts }, termsChecked } = this.state;
    const tablet = this.props.smallWidth;
    const phone = window.innerWidth <= 600;
    const filteredAccounts = selectedAccounts.filter(account => account.accountId);

    return (
      <div>
        <div className={styles.NewSpecial_reviewContainer}>
          <div className={styles.NewSpecial_reviewBeneficiaryName}>
            <div className={styles.NewSpecial_detailsTitle}>Beneficiary Name</div>
            {filteredAccounts.map(account => (<div className={styles.NewSpecial_detail} key={account.accountId}>{account.beneficiaryName}</div>))}
          </div>

          {!phone && !tablet && (
            <div className={styles.NewSpecial_reviewAccountNumber}>
              <div className={styles.NewSpecial_detailsTitle}>Account Number</div>
              {filteredAccounts.map(account => (<div className={styles.NewSpecial_detail} key={account.accountId}>{account.accountNumber}</div>))}
            </div>
          )}

          {!phone && (
            <div className={styles.NewSpecial_reviewBankAccount}>
              <div className={styles.NewSpecial_detailsTitle}>Bank Account</div>
              {filteredAccounts.map(account => (<div className={styles.NewSpecial_detail} key={account.accountId}>{bankAccount.name} {bankAccount.type}, {bankAccount.number}</div>))}
            </div>
          )}

          {!phone && (
            <div className={styles.NewSpecial_reviewNextDate}>
              <div className={styles.NewSpecial_detailsTitle}>Next Run Date</div>
              {occasion === BIRTHDAY.id ?
                filteredAccounts.map(account => <div className={styles.NewSpecial_detail} key={account.accountId}>{dayjs(account.nextBdayRunDate).format('L')}</div>)
                :
                filteredAccounts.map(account => <div className={styles.NewSpecial_detail} key={account.accountId}>{dayjs(this.props.nextRunDates.day1).format('L')}</div>)
              }
            </div>
          )}

          {!phone && !tablet && filteredAccounts.some(account => account.endDate) && (
            <div className={styles.NewSpecial_reviewEndDate}>
              <div className={styles.NewSpecial_detailsTitle}>End Date</div>
              {filteredAccounts.map(account => (<div className={styles.NewSpecial_detail} key={account.accountId}>{dayjs(account.endDate).isValid() ? <div>{dayjs(account.endDate).format('L')}</div> : <div>None</div>}</div>))}
            </div>
          )}

          <div className={styles.NewSpecial_reviewAmount}>
            <div className={styles.NewSpecial_detailsTitle}>Amount</div>
            {filteredAccounts.map(account => (<div className={styles.NewSpecial_detail} key={account.accountId}>{currencyFormatter(account.contributionAmount)}</div>))}
          </div>
        </div>

        <div className={styles.NewSpecial_totalAmountStep2}>
          <div>Total:</div>
          <div>{currencyFormatter(filteredAccounts.map(account => account.contributionAmount).reduce((val1, val2) => val1 + val2, 0))}</div>
        </div>

        <div className={styles.NewSpecial_termsContainer}>
          <div className={styles.NewSpecial_terms}>
            <TermsConditions contributionType='special' />
          </div>
          <div className={styles.NewSpecial_checkbox}>
            <FormControlLabel
              label='I have read and understand the above terms and conditions.'
              control={
                <Checkbox
                  checked={termsChecked}
                  onChange={() => this.setState({ termsChecked: !termsChecked })}
                />
              }
            />
          </div>
        </div>
      </div>
    );
  }

  step3ContentCompose = () => {
    const { contribution: { bankAccount, occasion, selectedAccounts } } = this.state;
    const tablet = this.props.smallWidth;
    const phone = window.innerWidth <= 600;
    const filteredAccounts = selectedAccounts.filter(account => account.accountId);

    return (
      <div className={transactionsStyles.printContainer}>
        <div className={styles.NewSpecial_reviewContainer}>
          <div className={styles.NewSpecial_reviewBeneficiaryName}>
            <div className={styles.NewSpecial_detailsTitle}>Beneficiary Name</div>
            {filteredAccounts.map(account => (<div className={styles.NewSpecial_detail} key={account.accountId}>{account.beneficiaryName}</div>))}
          </div>

          {!phone && !tablet && (
            <div className={styles.NewSpecial_reviewAccountNumber}>
              <div className={styles.NewSpecial_detailsTitle}>Account Number</div>
              {filteredAccounts.map(account => (<div className={styles.NewSpecial_detail} key={account.accountId}>{account.accountNumber}</div>))}
            </div>
          )}

          {!phone && (
            <div className={styles.NewSpecial_reviewBankAccount}>
              <div className={styles.NewSpecial_detailsTitle}>Bank Account</div>
              {filteredAccounts.map(account => (<div className={styles.NewSpecial_detail} key={account.accountId}>{bankAccount.name} {bankAccount.type}, {bankAccount.number}</div>))}
            </div>
          )}

          {!phone && (
            <div className={styles.NewSpecial_reviewNextDate}>
              <div className={styles.NewSpecial_detailsTitle}>Next Run Date</div>
              {occasion === BIRTHDAY.id ?
                filteredAccounts.map(account => <div className={styles.NewSpecial_detail} key={account.accountId}>{dayjs(account.nextBdayRunDate).format('L')}</div>)
                :
                filteredAccounts.map(account => <div className={styles.NewSpecial_detail} key={account.accountId}>{dayjs(this.props.nextRunDates.day1).format('L')}</div>)
              }
            </div>
          )}

          {!phone && !tablet && filteredAccounts.some(account => account.endDate) && (
            <div className={styles.NewSpecial_reviewEndDate}>
              <div className={styles.NewSpecial_detailsTitle}>End Date</div>
              {filteredAccounts.map(account => (<div className={styles.NewSpecial_detail} key={account.accountId}>{dayjs(account.endDate).isValid() ? <div>{dayjs(account.endDate).format('L')}</div> : <div>None</div>}</div>))}
            </div>
          )}

          <div className={styles.NewSpecial_reviewAmount}>
            <div className={styles.NewSpecial_detailsTitle}>Amount</div>
            {filteredAccounts.map(account => (<div className={styles.NewSpecial_detail} key={account.accountId}>{currencyFormatter(account.contributionAmount)}</div>))}
          </div>
        </div>

        <div className={styles.NewSpecial_totalAmountStep2}>
          <div>Total:</div>
          <div>{currencyFormatter(filteredAccounts.map(account => account.contributionAmount).reduce((val1, val2) => val1 + val2, 0))}</div>
        </div>

        <div className={transactionsStyles.currentTime}>{dayjs(this.props.serverDateTime)
          .format('MM/DD/YYYY h:mm A')} MST</div>
        <div className='hideOnPrint'>
          Please print a copy of this page for your records.
        </div>
      </div>
    );
  }

  goToPreviousPage = () => {
    this.props.history.goBack();
  }

  stepperButtonsCompose = () => {
    const {
      nextRunDateLoading,
      stepIndex,
      termsChecked,
      contribution
    } = this.state;
    const {
      createContributionLoading,
      isEditing,
      contributionEdit,
      contributionCreate
    } = this.props;

    const cancelButton = (
      <Button
        variant='text'
        key='cancel'
        disabled={nextRunDateLoading || createContributionLoading}
        onClick={this.goToPreviousPage}
        style={{ marginRight: '5px' }}
      >
        Cancel
      </Button>
    );
    const backButton = (
      <Button
        key='prevStep'
        onClick={() => this.prevStepHandle()}
        variant='text'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Back
      </Button>
    );
    const nextButton = (onClick) => (
      <Button
        disabled={nextRunDateLoading}
        key='nextStep'
        onClick={onClick}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Next
      </Button>
    );
    const agreeButton = (
      <Button
        disabled={!termsChecked || createContributionLoading}
        key='nextStep'
        onClick={() => isEditing ? contributionEdit(contribution) : contributionCreate(contribution)}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        I agree
      </Button>
    );

    const printButton = (
      <Button
        key='print'
        onClick={() => window.print()}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Print
      </Button>
    );
    const finishButton = (
      <Button
        key='finish'
        onClick={() => this.props.history.goBack()}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Finish
      </Button>
    );

    let buttons = [];
    switch (stepIndex) {
      case 0:
        buttons = [
          cancelButton,
          nextButton(() => this.formValidate(this.nextStepHandle))
        ];
        break;
      case 1:
        buttons = [
          cancelButton,
          backButton,
          nextButton(() => this.formValidate(this.nextStepHandle))
        ];
        break;
      case 2:
        buttons = [
          cancelButton,
          backButton,
          <LoadingOverlay key='loading' show={this.props.createContributionLoading}>
            {agreeButton}
          </LoadingOverlay>
        ];
        break;
      case 3:
        buttons = [
          printButton,
          finishButton,
        ];
        break;
      default: break;
    }
    return buttons;
  }

  stepperContentCompose = () => {
    switch (this.state.stepIndex) {
      case 0: return this.step0ContentCompose();
      case 1: return this.step1ContentCompose();
      case 2: return this.step2ContentCompose();
      case 3: return this.step3ContentCompose();

      default:
        break;
    }
  }

  componentDidMount() {
    const { contributionDetails: { agentAccounts, contribution, selectedAccountId }, isEditing } = this.props;

    if (Object.keys(agentAccounts).length > 0) {
      if (isEditing) {
        this.setState({ contribution },
          () => this.nextRunDateGet(contribution.day1, contribution.occasion === BIRTHDAY.id ? BIRTHDAY.id : 'day1'));
      }
      else {
        // Need to go around contributionSet() helper so that nextRunDateGet() has account set to state. This can be replaced with the helper on future versions of React that do not batch state.
        this.setState(
          {
            contribution: {
              ...this.state.contribution,
              selectedAccounts: [clone(agentAccounts.find(agentAccount => agentAccount.accountId === selectedAccountId))]
            }
          },
          () => this.nextRunDateGet('', BIRTHDAY.id));
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.serverDateTime !== this.props.serverDateTime && this.props.serverDateTime !== null) {
      this.nextStepHandle();
    }
  }

  render() {
    const { stepIndex } = this.state;

    return (
      <Card className={styles.NewSpecial_container}>
        <div className={`${styles.NewSpecial_stepperSteps} hideOnPrint`}>
          <Stepper
            activeStep={stepIndex}
            orientation={this.props.smallWidth ? 'vertical' : 'horizontal'}
            style={this.props.smallWidth ? {} : { width: '650px' }}
          >
            <Step>
              <StepButton onClick={() => this.setState({ stepIndex: 0 })} disabled={stepIndex === 3}>Amount & Date</StepButton>
            </Step>
            <Step>
              <StepButton onClick={() => this.setState({ stepIndex: 1 })} disabled={stepIndex === 3}>Bank Account</StepButton>
            </Step>
            <Step>
              <StepButton onClick={() => this.setState({ stepIndex: 2 })} disabled={stepIndex === 3}>Terms & Conditions</StepButton>
            </Step>
            <Step>
              <StepButton onClick={() => this.setState({ stepIndex: 3 })}>Print Review</StepButton>
            </Step>
          </Stepper>
        </div>

        <div className={styles.NewSpecial_stepperContent}>
          {this.props.loading ? null : this.stepperContentCompose()}
        </div>

        <div className={`${styles.NewSpecial_stepperButtons} hideOnPrint`}>
          {this.props.loading ? null : this.stepperButtonsCompose()}
        </div>
      </Card>
    );
  }
}

export default withRouter(connect(select, { getNextRunDate })(NewSpecial));
