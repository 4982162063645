import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PhoneMethods from './PhoneMethods';
import PhoneAuthenticate from './PhoneAuthenticate';
import SubmitToken from '../SubmitToken';

import {
  Button,
} from '@mui/material';

import {
  notificationShow,
} from '@frontend/common';

import {
  getPreferredMethod,
  savePreferredMethod,
} from '../actions';
import { advisorGet } from 'components/Features/protected/MyInfo/actions';

import { resetClaims, userLogout, clearStore } from 'components/AppRoot/Navigation/actions';

import { REGISTER_METHODS } from '../constants';

import styles from '../styles.module.css';

const select = (state) => ({
  claims: state.session.claims,
});

const AUTHENTICATION_STEPS = {
  SHOW_SMS_OR_CALL_SELECTION: 'SHOW_SMS_OR_CALL_SELECTION',
  PHONE_AUTH: 'PHONE_AUTH',
  SHOW_OTHER_AUTHENTICATOR: 'SHOW_OTHER_AUTHENTICATOR',
};

export class Authentication extends Component {

  static propTypes = {
    claims: PropTypes.object.isRequired,
    getPreferredMethod: PropTypes.func.isRequired,
    savePreferredMethod: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    userLogout: PropTypes.func.isRequired,
    resetClaims: PropTypes.func.isRequired,
    clearStore: PropTypes.func.isRequired,
    advisorGet: PropTypes.func.isRequired,
  };

  state = {
    isLoading: false,
    selectedMethod: '',
    authenticationStep: '',
    phoneNumber: '',
    attemptWarning: '',
    token: '',
    isResubmitting: false,
    verifiedAppCode: false,
  };

  resetClaims() {
    this.props.resetClaims()
      .then(() => {
        this.props.history.push('/'); // might need saved previous path
      });
  }

  logOut = () => {
    const token = sessionStorage.getItem('token');
    this.props.userLogout({ token })
      .finally(() => {
        this.props.notificationShow('You have logged out.', 'success');
      });
    this.props.clearStore();
    this.props.history.push('/login');
  };

  onVerificationSuccess = (selectedMethod) => {
    const params = {
      AlwaysRequired: false, // assuming user has no saying in their user preferences
      PreferredMethod: selectedMethod
    };
    // also load advisor details with PIN here
    Promise.all([this.props.savePreferredMethod(params), this.props.advisorGet()])
      .then(() => this.resetClaims()); // updates claims and opens home 
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.props.getPreferredMethod()
      .then((response) => {
        const { PreferredMethod, PhoneNumber, CountryCode, VerifiedAppCode } = response.payload.data;

        let authenticationStep;
        switch (PreferredMethod) {
          case REGISTER_METHODS.SMS:
          case REGISTER_METHODS.CALL:
            authenticationStep = AUTHENTICATION_STEPS.SHOW_SMS_OR_CALL_SELECTION;
            break;
          case REGISTER_METHODS.INAPPTOKEN:
            authenticationStep = AUTHENTICATION_STEPS.SHOW_OTHER_AUTHENTICATOR;
            break;
          default: break;
        }

        this.setState({
          isLoading: false,
          selectedMethod: PreferredMethod,
          authenticationStep,
          phoneNumber: PhoneNumber,
          countryCode: CountryCode,
          verifiedAppCode: VerifiedAppCode
        });
      });
  }

  render() {
    const {
      authenticationStep, selectedMethod, isLoading, phoneNumber, countryCode,
      verifiedAppCode
    } = this.state;

    const isDomestic = countryCode === '+1'; // this is for existing users with intl phone numbers

    let render2FAVerificationStep;
    const useToken = () => (
      <div>
        <Button
          color='secondary'
          variant='text'
          style={{ marginTop: '10px' }}
          className={styles.buttons}
          onClick={() => this.setState({ authenticationStep: AUTHENTICATION_STEPS.SHOW_OTHER_AUTHENTICATOR })}
        >
          Use authenticator
        </Button>
      </div>
    );

    const useTextOrPhone = () => (
      <div>
        <Button
          color='secondary'
          disabled={isLoading}
          className={styles.buttons}
          onClick={() => this.setState({ authenticationStep: AUTHENTICATION_STEPS.SHOW_SMS_OR_CALL_SELECTION })}
        >
          Use text or phone call
        </Button>
      </div>
    );

    switch (authenticationStep) {
      case AUTHENTICATION_STEPS.SHOW_SMS_OR_CALL_SELECTION:
        render2FAVerificationStep = (
          <>
            <PhoneMethods
              preferredMethod={selectedMethod}
              phoneNumber={phoneNumber}
              onSubmitPhoneSuccess={(selectedMethod) => this.setState({ authenticationStep: AUTHENTICATION_STEPS.PHONE_AUTH, selectedMethod })}
            />
            {verifiedAppCode && useToken()}
          </>
        );
        break;
      case AUTHENTICATION_STEPS.PHONE_AUTH:
        render2FAVerificationStep = (
          <PhoneAuthenticate
            selectedMethod={selectedMethod}
            phoneNumber={phoneNumber}
            onVerificationSuccess={() => this.onVerificationSuccess(selectedMethod)}
          />
        );
        break;
      case AUTHENTICATION_STEPS.SHOW_OTHER_AUTHENTICATOR:
        render2FAVerificationStep = (
          <>
            <h2>Enter a security code from your authenticator app</h2>
            <SubmitToken
              method={REGISTER_METHODS.INAPPTOKEN}
              onVerificationSuccess={() => this.onVerificationSuccess(REGISTER_METHODS.INAPPTOKEN)}
              onVerificationFail={this.logOut}
            />
            {phoneNumber && isDomestic && useTextOrPhone()}
          </>
        );
        break;
      default: break;
    }

    return (
      <>
        {render2FAVerificationStep}
        <div>
          <Button
            color='secondary'
            variant='text'
            style={{ marginTop: '10px' }}
            className={styles.buttons}
            onClick={this.logOut}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  }
}

export default connect(select, {
  getPreferredMethod,
  savePreferredMethod,
  resetClaims,
  userLogout,
  clearStore,
  notificationShow,
  advisorGet,
})(Authentication);