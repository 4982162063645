/*
 *
 * Sessions constants
 *
 */
export const INITIALIZE_STORE = 'App_Root/components/Sessions/INITIALIZE_STORE';

export const BROWSER_WARNING = 'App_Root/components/Sessions/BROWSER_WARNING';
export const MOBILE_WARNING = 'App_Root/components/Sessions/MOBILE_WARNING';

export const USER_LOGIN = 'App_Root/components/Sessions/USER_LOGIN';

export const TOKEN_VALIDATE = 'App_Root/components/Sessions/TOKEN_VALIDATE';
export const TOKEN_VALIDATE_INTERVAL_ID_SET = 'App_Root/components/Sessions/TOKEN_VALIDATE_INTERVAL_ID_SET';
export const TOKEN_VALIDATE_INTERVAL_ID_CLEAR = 'App_Root/components/Sessions/TOKEN_VALIDATE_INTERVAL_ID_CLEAR';

export const USERNAME_CHANGE = 'App_Root/components/Sessions/USERNAME_CHANGE';

export const ADVISOR_NAME_UPDATE = 'App_Root/components/Sessions/ADVISOR_NAME_UPDATE';

export const GET_USER_PREFERENCES = 'App_Root/components/Sessions/GET_USER_PREFERENCES';
export const SAVE_USER_PREFERENCE = 'App_Root/components/Sessions/SAVE_USER_PREFERENCE';
export const USER_PREFERENCE_KEYS = {
  hasSeenTour: 'HasSeenFATour',
};

export const REFRESH_CLAIMS = 'App_Root/components/Sessions/REFRESH_CLAIMS';
export const RESET_CLAIMS = 'App_Root/components/Sessions/RESET_CLAIMS';

export const TOGGLE_SPLASH_HAS_DISPLAYED = 'App_Root/components/Sessions/TOGGLE_SPLASH_HAS_DISPLAYED';

export const REASONS_BLOCKED = {
  TWO_FACTOR_REGISTRATION: 'TwoFactorRegistration',
  TWO_FACTOR_AUTHENTICATION: 'TwoFactorAuthentication',
};

export const TWO_FACTOR_STATUS = {
  COMPLETED: 'Completed',
  PENDING: 'Pending',
  BYPASSED: 'Bypassed',
  UNAVAILABLE: 'Unavailable',
  REQUIRED: 'Required'
};

export const TWO_FACTOR_TYPES = {
  REGISTRATION: 'registrations',
  AUTHENTICATION: 'authentication',
  PROTECTED: 'protected',
  UPDATE: 'update'
};