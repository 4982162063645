/*
*
* UserDetails Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  cloneDeep,
  orderBy,
  isEqual,
  find,
  findIndex,
  pick,
} from 'lodash';
import { Prompt } from 'react-router-dom';

import { userDetailsGet, userUpdate, poaAccountsGet, usersListGet } from '../actions';
import { lpoaSettingsGet } from 'components/Features/protected/ManageLPOA/actions';
import { permissionGet } from 'utils/config/permissions_details';
import { getAccounts } from 'components/Features/protected/Accounts/actions';

import {
  Breadcrumbs,
  Card,
  LoadingOverlay,
  Checkbox,
  InfoIcon,
  FloatingActionButton,
  ConfirmModal,
  notificationShow,
  emailValidate,
} from '@frontend/common';
import AssignAccounts from '../AssignAccounts';

import {
  TextField,
} from '@mui/material';

import styles from './styles.module.css';

const select = (state, { match }) => ({
  user: state.manageUsers.users[match.params.advisorId] || { POAs: [] },
  users: Object.values(state.manageUsers.users),
  lpoaSettings: state.manageLPOA.settings,
  poaAccounts: state.manageUsers.poaAccounts,
  claims: state.session.claims,
});


export class UserDetails extends React.Component {

  static propTypes = {
    user: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    userDetailsGet: PropTypes.func.isRequired,
    lpoaSettings: PropTypes.object.isRequired,
    lpoaSettingsGet: PropTypes.func.isRequired,
    userUpdate: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    poaAccounts: PropTypes.array.isRequired,
    poaAccountsGet: PropTypes.func.isRequired,
    usersListGet: PropTypes.func.isRequired,
    claims: PropTypes.object.isRequired,
    getAccounts: PropTypes.func.isRequired,
  };

  state = {
    user: {},
    loading: false,
    userChanged: false,
    showSaveConfirmModal: false,
    saveLoading: false,
    selectedPOAAccounts: [],
    inputErrors: [],
  };

  accountAssignmentsChange = (updatedAccounts, poaIds, selectedButton) => {
    if (this.state.user.ViewAllAccounts !== (selectedButton === 'all')) {
      this.updateUser('ViewAllAccounts', selectedButton === 'all');
    }
    this.setState({
      selectedPOAAccounts: updatedAccounts,
    }, () => {
      this.updateUser('POAIds', selectedButton === 'all' ? null : poaIds);
    });
  }
  
  errorGet(input) {
    const error = find(this.state.inputErrors, { input });
    return error ? error.message : '';
  }

  inputsValidate() {
    const errors = [];

    if (!this.state.user.FirstName) {
      errors.push({
        input: 'FirstName',
        message: 'Field is required.'
      });
    }

    if (!this.state.user.LastName) {
      errors.push({
        input: 'LastName',
        message: 'Field is required.'
      });
    }

    if (!this.state.user.EmailAddress) {
      errors.push({
        input: 'EmailAddress',
        message: 'Field is required.'
      });
    }
    else if (this.state.user.EmailAddress && !emailValidate(this.state.user.EmailAddress)) {
      errors.push({
        input: 'EmailAddress',
        message: 'Invalid email address.'
      });
    }

    this.setState({
      inputErrors: errors,
    });

    if (errors.length > 0) {
      return false;
    }

    return true;
  }

  permissionsToggleCompose(key) {
    const toggleDetails = permissionGet(key);
    return (
      <div>
        <Checkbox
          label={<div className={styles.UserDetails_toggleLabel}><span>{toggleDetails.label}</span><InfoIcon message={toggleDetails.description} /></div>}
          checked={this.state.user[key] || false}
          onChange={e => this.updateUser(key, e.target.checked)}
        />
      </div>
    );
  }

  poaAccountsCompile = () => {
    const selectedAccountIds = this.props.user.POAs.map(poa => poa.id);
    const formattedAccounts = cloneDeep(this.props.poaAccounts);
    formattedAccounts.forEach(account => {
      if (this.props.user.ViewAllAccounts) {
        account.selected = true;
      }
      else {
        account.selected = selectedAccountIds.indexOf(account.id) !== -1;
      }
    });

    return orderBy(formattedAccounts, ['selected'], ['desc']);
  }

  revertHandle = () => {
    this.setState({
      user: cloneDeep(this.props.user),
      selectedPOAAccounts: this.poaAccountsCompile(),
      userChanged: false,
    });
  }

  saveHandle = () => {
    this.setState({ saveLoading: true });
    this.props.userUpdate(this.props.match.params.advisorId, pick(this.state.user, [
      'AccountReports',
      'AdminReports',
      'AdvisorEdit',
      'AllowContribute',
      'AllowOptionChange',
      'AllowTransfer',
      'AllowViewFederalTaxDocuments',
      'AllowViewStateTaxDocuments',
      'AllowWithdraw',
      'DataImport',
      'DataImportSetup',
      'EditFirmAdvisorDefaults',
      'EditFirmContactInfo',
      'EmailAddress',
      'FirstName',
      'LastName',
      'POAIds',
      'PhoneNumber',
      'PhoneNumber1',
      'TemplateEdit',
      'ViewAllAccounts',
    ]))
      .then(() => {
        this.props.notificationShow('User updated.', 'success');
        this.setState({
          saveLoading: false,
          showSaveConfirmModal: false,
          userChanged: false,
          user: cloneDeep(this.props.user),
        });
        if (parseInt(this.props.claims.FinancialAdvisorId) === parseInt(this.props.match.params.advisorId)) {
          this.props.getAccounts()
            .catch(() => null);
        }
      })
      .catch(() => this.setState({ saveLoading: false }));
  }

  updateUser(input, value) {
    const updatedUser = cloneDeep(this.state.user);
    const errorIndex = findIndex(this.state.inputErrors, { input });
    const updatedErrors = cloneDeep(this.state.inputErrors);
    if (errorIndex > -1) {
      updatedErrors.splice(errorIndex, 1);
    }
    updatedUser[input] = value;
    this.setState({
      user: updatedUser,
      userChanged: !isEqual(updatedUser, this.props.user),
      inputErrors: updatedErrors,
    });
  }

  componentDidMount() {
    const apiCalls = [];

    window.onbeforeunload = () => {
      if (this.state.userChanged) {
        return 'Are you sure you want to reload page? All unsaved changes will be lost.';
      }
    };

    const { user } = this.props;
    if (!user.dataComplete) {
      apiCalls.push(this.props.userDetailsGet(this.props.match.params.advisorId));
    }

    if (Object.keys(this.props.lpoaSettings).length === 0) {
      apiCalls.push(this.props.lpoaSettingsGet());
    }

    if (this.props.poaAccounts.length === 0) {
      apiCalls.push(this.props.poaAccountsGet());
    }

    if (apiCalls.length > 0) {
      this.setState({ loading: true });
      Promise.all(apiCalls)
        .then(() => {
          const { user, users } = this.props;
          this.setState({
            user: cloneDeep(user),
            selectedPOAAccounts: this.poaAccountsCompile(),
            loading: false,
          });

          if (users.length === 0 || (users.length === 1 && users[0].advisorId === user.advisorId)) {
            this.props.usersListGet()
              .catch(() => null);
          }
        })
        .catch(() => this.setState({ loading: false }));
    }
    else {
      this.setState({
        user: cloneDeep(this.props.user),
        selectedPOAAccounts: this.poaAccountsCompile(),
      });
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = () => null;
  }

  render() {
    const { user, userChanged, loading } = this.state;
    /* eslint-disable indent */
    return (
      <div className={styles.UserDetails_page}>
        <div className={styles.UserDetails_container}>
          {/* There are no CSS styles for UserDetails_nav */}
          <div className='UserDetails_nav'>
            { loading
                ? <LoadingOverlay
                    show={loading}
                    width='100%'
                    height='20px'
                    indicatorHeight='10px'
                  />
                : null
            }
            <Breadcrumbs
              crumbs={[
                {
                  title: 'Users',
                  link: '/manage-users'
                },
                { title: 'Details' }
              ]}
            />
          </div>
          <div className={styles.UserDetails_cards}>
            <Card
              title='Contact Info'
              className={styles.UserDetails_contact}
            >
              <div className={styles.UserDetails_contactInputsContainer}>
                <TextField
                  label='First Name'
                  value={user.FirstName || ''}
                  onChange={e => this.updateUser('FirstName', e.target.value)}
                  error={Boolean(this.errorGet('FirstName'))}
                  helperText={this.errorGet('FirstName')}
                  fullWidth
                />
                <TextField
                  label='Last Name'
                  value={user.LastName || ''}
                  onChange={e => this.updateUser('LastName', e.target.value)}
                  error={Boolean(this.errorGet('LastName'))}
                  helperText={this.errorGet('LastName')}
                  fullWidth
                />
                <TextField
                  label='Email'
                  value={user.EmailAddress || ''}
                  onChange={e => this.updateUser('EmailAddress', e.target.value)}
                  error={Boolean(this.errorGet('EmailAddress'))}
                  helperText={this.errorGet('EmailAddress')}
                  fullWidth
                />
                <TextField
                  label='Work Phone'
                  value={user.PhoneNumber || ''}
                  onChange={e => this.updateUser('PhoneNumber', e.target.value)}
                  fullWidth
                />
                <TextField
                  label='Cellphone'
                  value={user.PhoneNumber1 || ''}
                  onChange={e => this.updateUser('PhoneNumber1', e.target.value)}
                  fullWidth
                />
                {user.pin && (
                  <div className={styles.UserDetails_nonEditableField}>
                    <span>PIN:</span>
                    <span>{user.pin}</span>
                  </div>
                )}
                {user.username && (
                  <div className={styles.UserDetails_nonEditableField}>
                    <span>Username:</span>
                    <span>{user.username}</span>
                  </div>
                )}
              </div>
            </Card>
            <Card
              title='Permissions'
              className={styles.UserDetails_permissions}
            >
              <div className={styles.UserDetails_toggles}>
                <div className={styles.UserDetails_toggleSection} id={styles.UserDetails_toggleSection_transactions}>
                  <div>Transactions</div>
                  <hr />
                  {this.permissionsToggleCompose('AllowContribute')}
                  {this.permissionsToggleCompose('AllowOptionChange')}
                  {this.permissionsToggleCompose('AllowTransfer')}
                  {this.permissionsToggleCompose('AllowWithdraw')}
                </div>
                <div className={styles.UserDetails_toggleSection} id={styles.UserDetails_toggleSection_taxDocs}>
                  <div>Tax Documents</div>
                  <hr />
                  {this.permissionsToggleCompose('AllowViewStateTaxDocuments')}
                  {this.permissionsToggleCompose('AllowViewFederalTaxDocuments')}
                </div>
                <div className={styles.UserDetails_toggleSection} id={styles.UserDetails_toggleSection_admin}>
                  <div>Administration</div>
                  <hr />
                  {this.permissionsToggleCompose('AdvisorEdit')}
                  {this.permissionsToggleCompose('TemplateEdit')}
                  {this.permissionsToggleCompose('DataImportSetup')}
                  {this.permissionsToggleCompose('DataImport')}
                </div>
                <div className={styles.UserDetails_toggleSection} id={styles.UserDetails_toggleSection_reports}>
                  <div>Reports</div>
                  <hr />
                  {this.permissionsToggleCompose('AccountReports')}
                  {this.permissionsToggleCompose('AdminReports')}
                </div>
              </div>
            </Card>
            <Card
              title='Assigned Accounts'
              className={styles.UserDetails_accounts}
            >
              <AssignAccounts
                accounts={this.state.selectedPOAAccounts}
                onChange={this.accountAssignmentsChange}
                loading={loading}
                allAccountsSelected={this.state.user.ViewAllAccounts}
              />
            </Card>
          </div>
        </div>

        {userChanged && (
          <div className={styles.UserDetails_fab}>
            <FloatingActionButton
              menuOptions={[
                {
                  title: 'Save',
                  icon: 'save',
                  action: () => this.inputsValidate() ? this.setState({ showSaveConfirmModal: true }) : null,
                },
                {
                  title: 'Revert',
                  icon: 'undo',
                  action: this.revertHandle,
                },
              ]}
              hasMenu
            />
          </div>
        )}

        <ConfirmModal
          show={this.state.showSaveConfirmModal}
          title='Save Changes'
          body='Are you sure you want to update this user?'
          onModalClose={() => this.setState({ showSaveConfirmModal: false })}
          onConfirm={this.saveHandle}
          isLoading={this.state.saveLoading}
        />

        <Prompt
          when={userChanged}
          message='Are you sure you want to leave? There are unsaved changes for this user.'
        />
      </div>
    );
    /* eslint-enable indent */
  }
}

export default connect(select, {
  userDetailsGet,
  lpoaSettingsGet,
  userUpdate,
  notificationShow,
  poaAccountsGet,
  usersListGet,
  getAccounts,
})(UserDetails);
