/*
*
* NewAccounts Reducers
*
*/
import { cloneDeep } from 'lodash';
import {
  NEW_ACCOUNTS_LIST_GET,
  ACCOUNTS_UPLOAD,
  NEW_ACCOUNT_DETAILS_GET,
  NEW_ACCOUNT_UPDATE,
  NEW_ACCOUNT_APPROVE,
  BLANK_ACCOUNT_GET,
} from './constants';

const initialState = {
  accounts: {},
  warnings: [],
  formErrors: [],
  staticErrors: [],
  conflictErrors: [],
  blankAccount: {},
  allowNewAccounts: false,
};


function NewAccountsReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case NEW_ACCOUNTS_LIST_GET: {
      newState.accounts = action.payload.data.NewAccounts
        .reduce((result, account) => ({ ...result, [account.PoaAccountApplicationId]: account }), {});
      newState.allowNewAccounts = action.payload.data.AllowNewAccounts;

      return newState;
    }

    case ACCOUNTS_UPLOAD: {
      // messaging is handled by middleware now
      return newState;
    }

    case NEW_ACCOUNT_DETAILS_GET:
    case NEW_ACCOUNT_UPDATE: {
      newState.formErrors = [];
      newState.staticErrors = [];
      newState.conflictErrors = [];

      const account = action.payload.data;
      newState.accounts[account.PoaAccountApplicationId] = account;

      return newState;
    }

    case NEW_ACCOUNT_APPROVE: {
      newState.formErrors = [];
      newState.staticErrors = [];
      newState.conflictErrors = [];

      const response = action.payload.data;
      if (response.FormErrors) {
        newState.formErrors = response.FormErrors.map(error => {
          return {
            input: error.type,
            message: error.error,
          };
        });
      }

      if (response.StaticErrors) {
        newState.staticErrors = response.StaticErrors.map(error => {
          return {
            type: error.type,
            ssn: error.ssn,
            otherSSN: error.secondSsn,
            approvedValue: error.correctOption,
            email: error.email,
          };
        });
      }

      if (response.ConflictErrors) {
        newState.conflictErrors = response.ConflictErrors.map(error => {
          return {
            type: error.type,
            ssn: error.ssn,
            otherSSN: error.secondSsn,
            options: error.otherOptions,
            email: error.email,
          };
        });
      }

      return newState;
    }

    case BLANK_ACCOUNT_GET: {
      newState.blankAccount = {
        ...action.payload.data,
        CanViewFedTaxDocs: true,
        CanViewStateTaxDocs: true,
      };

      return newState;
    }

    default: {
      return state;
    }
  }

}

export default NewAccountsReducer;