/*
*
* TemplateView
*
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Breadcrumbs, CalculatorForm } from '@frontend/common';

import { getTemplate } from 'components/Features/protected/Templates/actions';

const select = (state) => ({
  template: state.templates.template,
});

export class TemplateView extends Component {

  static propTypes = {
    getTemplate: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    investmentPath: PropTypes.object,
    template: PropTypes.object.isRequired,
    showTemplateBreadcrumbs: PropTypes.bool,
    schema: PropTypes.object,
    hideTemplateName: PropTypes.bool,
  };

  static defaultProps = {
    showTemplateBreadcrumbs: true,
    hideTemplateName: false,
  };

  state = {
    template: {
      FundName: '',
      FundCategories: []
    },
    loading: false,
  }

  componentDidMount() {
    const { investmentPath, match: { params: { id } } } = this.props;
    if (investmentPath) {
      this.setState({ template: investmentPath });
    }
    else if (id) {
      this.setState({ loading: true });
      this.props.getTemplate(`${id}`, false)
        .then(() => this.setState({ template: this.props.template }))
        .catch(() => null)
        .finally(() => this.setState({ loading: false }));
    }
  }

  render() {
    const { showTemplateBreadcrumbs, hideTemplateName } = this.props;
    const { template, isStatic, loading } = this.state;
    const templateName = template.TemplateName ? template.TemplateName : (isStatic ? 'New Customized Static' : 'New Customized Age-Based'); // eslint-disable-line no-nested-ternary
    
    return (
      <div>
        {showTemplateBreadcrumbs &&
          <Breadcrumbs
            crumbs={[
              {
                title: 'Templates',
                link: '/templates'
              },
              {
                title: templateName
              },
            ]}
          />
        }
        <CalculatorForm
          loading={loading}
          stickyHeaderTopOffset={60}
          schema={template}
          isViewing
          hideTemplateName={hideTemplateName}
        />
      </div>
    );
  }
}

export default withRouter(connect(select, { getTemplate })(TemplateView));
