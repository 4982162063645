import {
  CONTRIBUTIONS_ONETIME_STEPPER,
  CONTRIBUTIONS_RECURRING_STEPPER,
  CONTRIBUTIONS_SPECIAL_STEPPER,
  OPTION_CHANGES_STEPPER,
  WITHDRAWALS_STEPPER
} from '../constants';

export function contributionsOneTimeStep(step) {
  return `${CONTRIBUTIONS_ONETIME_STEPPER}${step}`;
}

export function contributionsRecurringStep(step) {
  return `${CONTRIBUTIONS_RECURRING_STEPPER}${step}`;
}

export function contributionsSpecialStep(step) {
  return `${CONTRIBUTIONS_SPECIAL_STEPPER}${step}`;
}

export function optionChangesStep(step) {
  return `${OPTION_CHANGES_STEPPER}${step}`;
}

export function withdrawalsStep(step) {
  return `${WITHDRAWALS_STEPPER}${step}`;
}