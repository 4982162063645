import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  Button,
  Icon,
  Menu,
  MenuItem,
} from '@mui/material';

import styles from './styles.module.css';

const select = (state) => ({
  userDetails: state.session.userDetails,
  lpoaDetails: state.session.lpoaDetails,
  advisor: state.myInfo.advisor,
});

export class NameMenu extends Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
    onLogoutClick: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    lpoaDetails: PropTypes.object.isRequired,
    advisor: PropTypes.object.isRequired,
  };

  state = {
    anchorEl: null
  };

  logoutClickHandle = () => {
    this.menuClose();
    this.props.onLogoutClick();
  }

  menuClose = () => {
    this.setState({ anchorEl: null });
  }

  myInfoClickHandle = () => {
    this.menuClose();
    this.props.history.push('/my-info');
  }

  lpoaSettingsClickHandle = () => {
    this.menuClose();
    this.props.history.push('/lpoa-settings');
  }

  render() {
    const { anchorEl } = this.state;
    return (
      <div>
        <Button
          aria-owns={anchorEl ? styles['name-menu'] : undefined}
          aria-haspopup='true'
          onClick={event => this.setState({ anchorEl: event.currentTarget })}
        >
          <div className={styles.NameMenu_name}>{this.props.name}</div>
          <Icon className={styles.NameMenu_icon}>{anchorEl ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
        </Button>
        <Menu
          id={styles['name-menu']}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.menuClose}
          style={{ top: '44px' }}
        >
          <MenuItem onClick={this.myInfoClickHandle}>My Info</MenuItem>
          <MenuItem onClick={this.lpoaSettingsClickHandle}>LPOA Settings</MenuItem>
          <MenuItem onClick={this.logoutClickHandle}>Log out</MenuItem>
          <div className={styles.NameMenu_userDetails}>
            <div className={styles.NameMenu_lpoaName}>{this.props.lpoaDetails.firmName}</div>
            <div className={styles.NameMenu_detailRow}>
              <span>LPOA #: </span>
              <span><b>{this.props.userDetails.lpoaNumber}</b></span>
            </div>
            <div className={styles.NameMenu_detailRow}>
              <span>PIN: </span>
              <span><b>{this.props.advisor.Pin}</b></span>
            </div>
            <div className={styles.NameMenu_detailRow}>
              <span>Previous Login: </span>
              <span>{this.props.userDetails.previousLogin}</span>
            </div>
          </div>
        </Menu>
      </div>
    );
  }
}

export default withRouter(connect(select)(NameMenu));
