import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material';

import {
  InfoIcon,
  LoadingOverlay,
  PhoneNumberInput
} from '@frontend/common';

import { REGISTER_METHODS } from '../../constants.js';

import styles from '../../styles.module.css';

export class RegisterPhoneAndMethod extends Component {

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    defaultMethod: PropTypes.string.isRequired,
    defaultPhoneNumber: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  state = {
    value: {
      phoneNumber: this.props.defaultPhoneNumber,
      method: this.props.defaultMethod,
    },
    errors: {
      phoneNumber: '',
      method: '',
    },
  };

  onSubmit = () => {
    const { value } = this.state;

    // error checking here
    const errors = {
      phoneNumber: '',
      method: '',
    };

    if (value.method === REGISTER_METHODS.SMS || value.method === REGISTER_METHODS.CALL) {
      if (value.phoneNumber === '') {
        errors.phoneNumber = 'Phone is required.';
      }
      else if (value.phoneNumber.length < 10) {
        errors.phoneNumber = 'Phone number needs to be 10 digits.';
      }
    }

    if (value.method === '') {
      errors.method = '2FA method is required.';
    }

    this.setState({ errors });
    const hasError = Object.values(errors).some(err => err !== '');
    this.props.onSubmit(value, hasError); // for parent
  }

  onPhoneChange = (phoneNumber) => {
    const value = { ...this.state.value, phoneNumber: phoneNumber.replace(/\D/g, '') };
    const errors = { ...this.state.errors, phoneNumber: '' };
    this.setState({ value, errors });
  }

  onMethodChange = (method) => {
    const value = { ...this.state.value, method };
    const errors = { ...this.state.errors, method: '' };
    this.setState({ value, errors });
  };

  render() {
    const { isLoading } = this.props;
    const { value, errors } = this.state;

    return (
      <>
        <h3>Register for Two Factor Authentication</h3>

        <div className={styles.intructionSteps_container}>
          <div className={styles.intructionSteps_body}>Step 1: Choose a registration method you would like to use. You have the option to change this method in your account settings after logging in.</div>
        </div>

        <div className={styles.methods}>
          <RadioGroup
            disabled={isLoading}
            aria-label='registration method'
            name='method'
            value={value.method}
            onChange={(e) => this.onMethodChange(e.target.value)}
          >
            <FormControlLabel value={REGISTER_METHODS.SMS} control={<Radio />} label='Text Me' style={{ marginTop: '5px' }} />
            <FormControlLabel value={REGISTER_METHODS.CALL} control={<Radio />} label='Call Me' style={{ marginTop: '5px' }} />

            <div style={{ textAlign: 'left' }}>
              <div>
                <FormControlLabel value={REGISTER_METHODS.INAPPTOKEN} control={<Radio />} label='Authenticator' />
                <InfoIcon message="To receive a security code (token), install an authenticator app such as Google Authenticator or Microsoft Authenticator on your phone. Download an app from your phone's app store." />
              </div>
            </div>

          </RadioGroup>
          <FormHelperText error={Boolean(errors.method)}>{errors.method}</FormHelperText>
        </div>

        {(value.method === REGISTER_METHODS.SMS || value.method === REGISTER_METHODS.CALL) &&
          <>
            <div className={styles.intructionSteps_container}>
              <div className={styles.intructionSteps_body}>Step 2: Please enter the 10 digit US phone number you would like to use to register for 2FA.</div>
            </div>

            <div className={styles.phoneInput}>
              <PhoneNumberInput
                disabled={isLoading}
                label='Enter Phone Number'
                name='2FA Phone'
                onChange={({ formattedValue }) => this.onPhoneChange(formattedValue)}
                errorText={errors.phoneNumber}
                variant='outlined'
              />
            </div>

          </>
        }

        <div className={styles.buttons}>
          <LoadingOverlay show={isLoading} width='100%'>
            <Button
              variant='contained'
              onClick={this.onSubmit}
              fullWidth
            >
              Register
            </Button>
          </LoadingOverlay>
        </div>
      </>
    );
  }
}

export default RegisterPhoneAndMethod;