/*
*
* DashboardHeader Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Paper,
} from '@mui/material';
import { withStyles, } from '@mui/styles';
import { Dropdown } from '@frontend/common';

import styles from './styles.module.css';
import { selectedBeneficiaryBarChartType, VIEW_BY_AGE, VIEW_BY_AGE_BAND } from '../constants';
import { changeBeneficiaryBarChart } from '../actions';

const select = (state) => ({
  selectedBeneficiaryBarChart: state.dashboardSettings.selectedBeneficiaryBarChart
});
const muiStyles = theme => ({
  menuItemRoot: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '19px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
    },
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  menuItemSelected: {
    color: theme.palette.secondary.main,
    backgroundColor: 'rgb(0, 0, 0, .1) !important',
  },
  selectRoot: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '19px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
    },
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
});


export class DashboardHeader extends React.Component {

  static propTypes = {
    selectedBeneficiaryBarChart: selectedBeneficiaryBarChartType,
    changeBeneficiaryBarChart: PropTypes.func,
    classes: PropTypes.object.isRequired,
  };

  handleViewChange = (event) => {
    this.props.changeBeneficiaryBarChart(event);
  }

  render() {
    const { selectedBeneficiaryBarChart, classes } = this.props;
    return (
      <Paper
        elevation={1}
        style={{ width: '100%', padding: '10px' }}
        className={styles.DashboardHeader_container}
      >
        <Dropdown
          value={selectedBeneficiaryBarChart}
          onChange={this.handleViewChange}
          options={[
            {
              value: VIEW_BY_AGE,
              display: 'Accounts By Age'
            },
            {
              value: VIEW_BY_AGE_BAND,
              display: 'Accounts By Age Group'
            },
          ]}
          FormControlProps={{ className: styles.DashboardHeader_ViewSelect }}
          SelectProps={{ classes: { select: classes.selectRoot } }}
          MenuItemProps={{ classes: { root: classes.menuItemRoot, selected: classes.menuItemSelected } }}
        />
      </Paper>
    );
  }
}

export default connect(select, { changeBeneficiaryBarChart })(withStyles(muiStyles)(DashboardHeader));
