/*
*
* ManageData Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import AccountsToExport from './AccountsToExport';
import SetupData from './SetupData';
import PortfolioDataDownload from './PortfolioDataDownload';
import PageNotFound from 'components/Features/protected/PageNotFound';

import Banner from 'components/Features/Banner';
import { BANNER_LOCATIONS } from 'components/AppRoot/StaticResources/constants';

const select = (state) => ({
  permissions: state.session.permissions,
  webMessages: state.static.webMessages,
});


export class ManageData extends React.Component {

  static propTypes = {
    permissions: PropTypes.object.isRequired,
    webMessages: PropTypes.object.isRequired,
  };

  render() {
    const { setupData, downloadData } = this.props.permissions;
    const { webMessages } = this.props;
    return (
      <div><br />
        <Banner show={Boolean(webMessages[BANNER_LOCATIONS.BOSP_MSG])} body={webMessages[BANNER_LOCATIONS.BOSP_MSG]} />
        <Switch>
          {setupData && (<Route path='/manage-data/accounts' component={AccountsToExport} />)}
          {downloadData && (<Route path='/manage-data/download' component={PortfolioDataDownload} />)}
          {setupData && (<Route exact path='/manage-data' component={SetupData} />)}
          <Route component={PageNotFound} />
        </Switch>
      </div>
    );
  }
}

export default connect(select, {})(ManageData);
