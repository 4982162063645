/*
*
* ManageData Reducers
*
*/
import { cloneDeep, orderBy, find } from 'lodash';
import { properCase } from '@frontend/common';
import dayjs from 'dayjs';
import {
  POAS_FOR_EXPORT_GET,
  POAS_FOR_EXPORT_UPDATE,
  BACK_OFFICES_GET,
  BACK_OFFICES_UPDATE,
  PORTFOLIO_PROVIDERS_GET,
  PORTFOLIO_PROVIDERS_UPDATE,
} from './constants';

const initialState = {
  poasForExport: [],
  backOffices: [],
  portfolioProviders: [],
};


function ManageDataReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case POAS_FOR_EXPORT_GET: {
      const allOptions = action.payload[0].data;
      const selectedPoaIds = action.payload[1].data.map(poa => poa.POAId);
      newState.poasForExport = allOptions.map(poa => ({
        id: poa.POAId,
        ownerName: properCase(poa.AgentName),
        beneficiaryName: properCase(poa.BeneName),
        selected: selectedPoaIds.indexOf(poa.POAId) !== -1,
      }));

      return newState;
    }

    case POAS_FOR_EXPORT_UPDATE: {
      const updatedIds = action.payload.data;
      newState.poasForExport = newState.poasForExport.map(poa => ({
        ...poa,
        selected: updatedIds.indexOf(poa.id) !== -1,
      }));

      return newState;
    }

    case BACK_OFFICES_GET: {
      const backOfficeOptions = action.payload[0];
      const selectedBackOffices = action.payload[1];
      const allBackOffices = backOfficeOptions.data;

      newState.backOffices = orderBy(allBackOffices.map(office => {
        const enabledBackOffice = find(selectedBackOffices.data, { BackOfficeProviderId: office.BackOfficeProviderId });
        return {
          id: office.BackOfficeProviderId,
          name: properCase(office.Name, ['(BOSS)', 'BAM']),
          enabled: Boolean(enabledBackOffice),
          authorizedDate: enabledBackOffice ? enabledBackOffice.AuthorizationDate : null,
        };
      }), ['name'], ['asc']);

      return newState;
    }

    case BACK_OFFICES_UPDATE: {
      const updatedIds = action.payload.data;
      newState.backOffices = newState.backOffices.map(office => {
        const enabled = updatedIds.indexOf(office.id) > -1;
        return {
          ...office,
          enabled,
          authorizedDate: enabled ? (!office.authorizedDate ? dayjs().toISOString() : office.authorizedDate) : null, //eslint-disable-line no-nested-ternary
        };
      });

      return newState;
    }

    case PORTFOLIO_PROVIDERS_GET: {
      const providerOptions = action.payload[0];
      const selectedProviders = action.payload[1];
      const selectedProviderCodes = selectedProviders.data.map(provider => provider.Code);

      newState.portfolioProviders = orderBy(providerOptions.data.map(provider => ({
        id: provider.Code,
        name: provider.Name,
        enabled: selectedProviderCodes.indexOf(provider.Code) > -1,
      })), ['name'], ['asc']);

      return newState;
    }

    case PORTFOLIO_PROVIDERS_UPDATE: {
      const updatedCodes = action.payload.data;
      newState.portfolioProviders = newState.portfolioProviders.map(provider => ({
        ...provider,
        enabled: updatedCodes.indexOf(provider.id) > -1,
      }));

      return newState;
    }

    default:
      return state;
  }

}

export default ManageDataReducer;