import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { tokenValidator } from 'utils/helpers/form_validation';
import { cleanDigits } from 'utils/helpers/sanitation';

import {
  Button,
  TextField
} from '@mui/material';

import {
  LoadingOverlay,
} from '@frontend/common';

import {
  twoFactorTokenVerification,
} from '../actions';

import styles from '../styles.module.css';

export class SubmitToken extends Component {

  static propTypes = {
    twoFactorTokenVerification: PropTypes.func.isRequired,
    onVerificationSuccess: PropTypes.func.isRequired,
    onVerificationFail: PropTypes.func,
    disabled: PropTypes.bool,
    getIsSubmitting: PropTypes.func,
    method: PropTypes.string.isRequired,
  };

  state = {
    isSubmitting: false,
    token: '',
    tokenError: '',
    attemptWarning: false,
  };

  handleTokenInput = e => {
    this.setState({
      tokenError: '',
      token: e.target.value
    });
  }

  onTokenSubmit(e) {
    e.preventDefault();
    const { token } = this.state;
    const { method, twoFactorTokenVerification, onVerificationFail, onVerificationSuccess } = this.props;

    const tokenError = tokenValidator(token);
    if (tokenError) {
      this.setState({ tokenError });
    }
    else {
      this.setState({ isSubmitting: true });
      // strip non numeric characters to prevent SQL injection
      let sanitizedToken = cleanDigits(token);
      // prevent sending empty string if no digits but count as a failed attempt 
      sanitizedToken = sanitizedToken ? sanitizedToken : '1';
      twoFactorTokenVerification(sanitizedToken, method)
        .then(response => {
          const { AttemptsRemaining, Verified } = response.payload.data;

          if (!Verified) {
            let attemptWarning = '';
            if (AttemptsRemaining === 0) {
              // pass to parent if method exists
              onVerificationFail && onVerificationFail();
            }
            else if (AttemptsRemaining === 1) {
              attemptWarning = 'Warning: You have one more attempt to validate the security code before you will be logged out of the website. Security code is invalid';
            }

            this.setState({
              isSubmitting: false,
              attemptWarning,
              tokenError: 'Security code is invalid',
            });
          }
          else {
            onVerificationSuccess();
          }
        });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isSubmitting !== this.state.isSubmitting) {
      // pass to parent if method exists
      this.props.getIsSubmitting && this.props.getIsSubmitting(this.state.isSubmitting);
    }
  }

  render() {
    const { disabled } = this.props;
    const { isSubmitting, tokenError, attemptWarning, token } = this.state;

    return (
      <>
        <form
          id='verify'
          onSubmit={e => this.onTokenSubmit(e)}
        >
          <div>
            <TextField
              error={Boolean(tokenError)}
              helperText={tokenError}
              label='Security Code'
              onChange={this.handleTokenInput}
              value={token}
              variant='filled'
              inputProps={{ style: { textAlign: 'center' } }}
              autoFocus
              disabled={disabled || isSubmitting}
            />
          </div>
          <div className={styles.buttons}>
            <LoadingOverlay show={isSubmitting} width='100%'>
              <Button
                variant='contained'
                style={{ marginTop: '20px' }}
                type='submit'
                fullWidth
                disabled={disabled}
              >
                Submit security code
              </Button>
            </LoadingOverlay>
          </div>
        </form>
        <div className={styles.attemptWarning}>{attemptWarning}</div>
      </>
    );
  }
}

export default connect(null, {
  twoFactorTokenVerification,
})(SubmitToken);