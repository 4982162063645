/*
*
* AssignAccounts Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { filter, cloneDeep, isEqual } from 'lodash';

import {
  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';

import {
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material';

import events from 'utils/ga';

import styles from './styles.module.css';


export class AssignAccounts extends React.Component {

  static propTypes = {
    accounts: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    allAccountsSelected: PropTypes.bool,
    disableInputs: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  };

  state = {
    accounts: [],
    accountAssignment: this.accountAssignmentGet(this.props.accounts),
  };

  accountAssignmentGet(accounts) {
    const selectedAccounts = filter(accounts, data => data.selected);
    if (!this.props.allAccountsSelected && selectedAccounts.length === 0) {
      return 'none';
    }
    else if (selectedAccounts.length !== accounts.length) {
      return 'some';
    }

    return 'all';
  }

  onRadioButtonToggle(selectedButton) {
    const updatedAccounts = cloneDeep(this.state.accounts);
    const poaIds = [];
    if (selectedButton === 'all') {
      updatedAccounts.forEach(account => {
        account.selected = true;
        poaIds.push(account.id);
      });
    }
    else if (selectedButton === 'some') {
      filter(updatedAccounts, data => data.selected)
        .forEach(account => {
          poaIds.push(account.id);
        });
    }

    this.setState({
      accountAssignment: selectedButton,
    }, () => {
      this.props.onChange(updatedAccounts, poaIds, selectedButton);
    });
  }

  componentDidMount() {
    this.setState({
      accounts: cloneDeep(this.props.accounts),
      accountAssignment: this.accountAssignmentGet(this.props.accounts),
    });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props, prevProps)) {
      if (this.state.accountAssignment !== 'some') {
        this.setState({
          accounts: cloneDeep(this.props.accounts),
          accountAssignment: this.accountAssignmentGet(this.props.accounts),
        });
      }
      else {
        this.setState({
          accounts: cloneDeep(this.props.accounts),
        });
      }
    }
  }

  render() {
    const { loading, disableInputs } = this.props;

    return (
      <div className={styles.AssignAccounts_container}>
        <div className={styles.AssignAccounts_radioButtons}>
          <RadioGroup
            value={this.state.accountAssignment}
            onChange={e => this.onRadioButtonToggle(e.target.value)}
          >
            <FormControlLabel
              value='all'
              label='All Accounts'
              control={
                <Radio
                  disabled={loading || disableInputs}
                  disableRipple
                />
              }
            />
            <FormControlLabel
              value='some'
              label='Select Specific Accounts'
              control={
                <Radio
                  disabled={loading || disableInputs}
                  disableRipple
                />
              }
            />
          </RadioGroup>
        </div>
        <div className={styles.AssignAccounts_count}><strong>{filter(this.state.accounts, data => data.selected).length}</strong> of {this.state.accounts.length} accounts selected.</div>
        <div className={styles.AssignAccounts_table}>
          <SmartTable
            idKey='id'
            rows={this.state.accounts}
            loading={loading}
            selectEnabled
            onChange={rows => {
              const poaIds = rows.filter(row => row.selected).map(row => row.id);
              const selectedAccounts = this.state.accounts.filter(row => row.selected);

              if (selectedAccounts.length !== poaIds.length) { // check for change in selections
                const accountAssignment = this.accountAssignmentGet(rows);
                this.setState({
                  accountAssignment,
                }, () => this.props.onChange(rows, poaIds, accountAssignment));
              }
            }}
            columns={[
              {
                key: 'ownerName',
                title: 'Account Owner/Agent',
                type: 'string',
                hideOn: ['phone'],
              },
              {
                key: 'beneficiaryName',
                title: 'Beneficiary',
                type: 'string',
              },
            ]}
          >
            <TableToolbar
              onSearchFocus={() => events.tableSearchAccessed('Assigned Accounts', window.location.pathname)}
            />
            <TableContainer minWidth='100%' maxHeight='100%'>
              <TableHeader />
              <TableRows />
            </TableContainer>
            <TablePagination />
          </SmartTable>
        </div>
      </div>
    );
  }
}

export default AssignAccounts;
