import {
  GETrequest,
  PUTrequest,
  POSTrequest
} from 'utils/helpers/api_handler';
import {
  ACTIVATE_TEMPLATE,
  DEACTIVATE_TEMPLATE,
  GET_TEMPLATES,
  GET_TEMPLATE,
  GET_TEMPLATE_ACCOUNTS,
  SAVE_TEMPLATE,
} from './constants';

import { convert3DCalculatorSchemaTo2D } from '@frontend/common';

export function activateTemplate(id) {
  const response = PUTrequest(`/template/activate/${id}`);
  return {
    type: ACTIVATE_TEMPLATE,
    payload: response
  };
}

export function deactivateTemplate(id) {
  const response = PUTrequest(`/template/deactivate/${id}`);
  return {
    type: DEACTIVATE_TEMPLATE,
    payload: response
  };
}

export function getTemplate(id, includeZeroAllocations) {
  const response = GETrequest(`/template?templateId=${id}&includeZeroAllocations=${includeZeroAllocations}`);
  return {
    type: GET_TEMPLATE,
    payload: response
  };
}

export function getTemplateAccountsList(id) {
  const response = GETrequest(`/template/accounts/${id}`);
  return {
    type: GET_TEMPLATE_ACCOUNTS,
    payload: response
  };
}

export function getTemplates() {
  const response = GETrequest('/search/templates');
  return {
    type: GET_TEMPLATES,
    payload: response
  };
}

export function saveTemplate(matrixAllocations, template) {
  const populatedTemplate = convert3DCalculatorSchemaTo2D(matrixAllocations, template);
  const response = POSTrequest('/template', populatedTemplate);
  return {
    type: SAVE_TEMPLATE,
    payload: response
  };
}