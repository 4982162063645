/*
*
* NewOneTime Component
*
*/
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { clone } from 'lodash';
import {
  Card,
  currencyFormatter,
  CurrencyInput,
  DatePicker,
  LoadingOverlay,
  Dropdown,
} from '@frontend/common';
import {
  ONE_TIME
} from '../constants';
import {
  Checkbox,
  Icon,
  Button,
  FormControlLabel,
  Step,
  StepButton,
  Stepper,
  Tooltip
} from '@mui/material';
import TermsConditions from '../TermsConditions';

import events from 'utils/ga';

import transactionsStyles from '../../styles.module.css';
import styles from './styles.module.css';

const select = (state) => ({
  contributionDetails: state.accounts.contributionDetails,
  contributionVariables: state.static.environmentVars.contributionVariables,
});

export class NewOneTime extends React.Component {

  static propTypes = {
    bankAccountsLoading: PropTypes.bool.isRequired,
    contributionDetails: PropTypes.object.isRequired,
    contributionCreate: PropTypes.func.isRequired,
    contributionEdit: PropTypes.func.isRequired,
    contributionVariables: PropTypes.object.isRequired,
    createContributionLoading: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    onAddBankAccountModalOpen: PropTypes.func.isRequired,
    smallWidth: PropTypes.bool.isRequired,
    serverDateTime: PropTypes.string,
  };

  state = {
    contribution: {
      bankAccount: {
        id: '',
        name: '',
        number: '',
      },
      day1: this.props.contributionVariables.oneTimeContributionDates && this.props.contributionVariables.oneTimeContributionDates[0],
      selectedAccounts: [],
      type: ONE_TIME
    },
    errors: [],
    step0ValidationHasRun: false,
    step1ValidationHasRun: false,
    stepIndex: 0,
    termsChecked: false,
  };

  accountSelect = (value, rowIndex) => {
    const { contribution: { selectedAccounts } } = this.state;
    const matchingAccount = this.props.contributionDetails.agentAccounts.find(agentAccount => agentAccount.accountId === value);
    const accountUpdatedWithAmount = { ...matchingAccount, contributionAmount: selectedAccounts[rowIndex].contributionAmount };

    selectedAccounts.splice(rowIndex, 1, accountUpdatedWithAmount);

    this.contributionSet('selectedAccounts', selectedAccounts);
  }

  addAccount = () => {
    const { contribution: { selectedAccounts } } = this.state;

    const newSelectedAccounts = [...selectedAccounts, { accountId: '', contributionAmount: 0 }];
    this.contributionSet('selectedAccounts', newSelectedAccounts);
  }

  amountHandle = ({ floatValue = 0 }, rowIndex) => {
    const { contribution: { selectedAccounts } } = this.state;

    selectedAccounts[rowIndex].contributionAmount = floatValue;
    this.contributionSet('selectedAccounts', selectedAccounts);
  }

  bankAccountSelectHandle = (value) => {
    this.contributionSet('bankAccount', this.props.contributionDetails.agentBankAccounts.find(bankAccount => bankAccount.id === value));
  }

  contributionDateHandle = (e) => {
    this.contributionSet('day1', e);
  }

  contributionDatesDisable = (date) => {
    return this.props.contributionVariables.oneTimeContributionDates.every(validContributionDate => !dayjs(validContributionDate).isSame(date, 'day'));
  }

  contributionSet = (key, value) => {
    this.setState({ contribution: { ...this.state.contribution, [key]: value } });
    if (
      (this.state.stepIndex === 0 && this.state.step0ValidationHasRun) ||
      (this.state.stepIndex === 1 && this.state.step1ValidationHasRun)
    ) {
      this.formValidate();
    }
  }

  defaultSelectedAccountSet = () => {
    this.contributionSet('selectedAccounts', [clone(this.props.contributionDetails.agentAccounts.find(agentAccount => agentAccount.accountId === this.props.contributionDetails.selectedAccountId))]);
  }

  errorGet = (key, row) => {
    const error = this.state.errors.find(error => error.key === key && error.row === row);
    return error ? error.message : '';
  }

  formValidate = (nextStep = () => null) => {
    const { maxOneTimeContribution, minOneTimeContribution } = this.props.contributionVariables;
    switch (this.state.stepIndex) {
      case 0: {
        this.setState(({ contribution: { selectedAccounts } }) => {
          const errors = [];
          selectedAccounts.forEach(({ accountId, contributionAmount }, index) => { // validates amounts against min and max parameters
            if ((accountId && contributionAmount > maxOneTimeContribution) || (accountId && contributionAmount < minOneTimeContribution)) {
              errors.push({
                key: 'amount',
                message: 'Enter a valid amount.',
                row: index
              });
            }
            if (!accountId && contributionAmount) { // validates all accounts that also have amounts entered have a beneficiary selected
              errors.push({
                key: 'account',
                message: 'Select an account or remove the row.',
                row: index
              });
            }
          });
          return { errors, step0ValidationHasRun: true };
        }, () => this.state.errors.length === 0 && nextStep());
        break;
      }

      case 1: {
        this.setState(({ contribution: { bankAccount } }) => {
          const errors = [];
          if (!bankAccount.id) {
            errors.push({
              key: 'bankAccount',
              message: 'Select an existing bank account or add a new one.'
            });
          }
          return { errors, step1ValidationHasRun: true };
        }, () => this.state.errors.length === 0 && nextStep());
        break;
      }
      default: break;
    }
  }

  nextStepHandle = () => {
    this.setState({ stepIndex: this.state.stepIndex + 1 });
    window.scrollTo(0, 0);
    !this.props.isEditing && events.contributionsOneTimeStep(this.state.stepIndex + 1);
  }

  prevStepHandle = () => {
    this.setState({ stepIndex: this.state.stepIndex - 1 });
    window.scrollTo(0, 0);
    !this.props.isEditing && events.contributionsOneTimeStep(this.state.stepIndex - 1);
  }

  removeAccount = (rowIndex) => {
    const { contribution: { selectedAccounts } } = this.state;
    selectedAccounts.splice(rowIndex, 1);
    this.contributionSet('selectedAccounts', selectedAccounts);
  }

  goToPreviousPage = () => {
    this.props.history.goBack();
  }

  stepperButtonsCompose = () => {
    const { contribution, stepIndex, termsChecked } = this.state;
    const { contributionEdit, contributionCreate, createContributionLoading, isEditing } = this.props;
    const cancelButton = (
      <Button
        variant='text'
        key='cancel'
        disabled={createContributionLoading}
        onClick={this.goToPreviousPage}
        style={{ marginRight: '5px' }}
      >
        Cancel
      </Button>
    );
    const nextButton = (
      <Button
        key='nextStep'
        disabled={createContributionLoading}
        onClick={() => this.formValidate(this.nextStepHandle)}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Next
      </Button>
    );
    const backButton = (
      <Button
        key='prevStep'
        disabled={createContributionLoading}
        onClick={() => this.prevStepHandle()}
        variant='text'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Back
      </Button >
    );
    const agreeButton = (
      <Button
        key='nextStep'
        disabled={!termsChecked || createContributionLoading}
        onClick={() => isEditing ? contributionEdit(contribution) : contributionCreate(contribution)}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        I agree
      </Button>
    );
    const printButton = (
      <Button
        key='print'
        onClick={() => window.print()}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Print
      </Button>
    );
    const finishButton = (
      <Button
        key='finish'
        onClick={() => this.props.history.goBack()}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Finish
      </Button>
    );

    let buttons = [];
    switch (stepIndex) {
      case 0:
        buttons = [
          cancelButton,
          nextButton
        ];
        break;
      case 1:
        buttons = [
          cancelButton,
          backButton,
          nextButton
        ];
        break;
      case 2:
        buttons = [
          cancelButton,
          backButton,
          <LoadingOverlay key='loading' show={createContributionLoading}>
            {agreeButton}
          </LoadingOverlay>
        ];
        break;
      case 3:
        buttons = [
          printButton,
          finishButton,
        ];
        break;
      default: break;
    }
    return buttons;
  }

  step0ContentCompose = () => {
    const { contribution: { selectedAccounts }, errors } = this.state;
    const { contributionDetails: { agentAccounts }, isEditing } = this.props;

    return (
      <div className={styles.NewOneTime_step0Container}>
        {selectedAccounts.map((selectedAccount, rowIndex) => {
          return (
            <div className={styles.NewOneTime_step0AccountsContainer} key={rowIndex}>
              <div className={styles.NewOneTime_accounts}>
                <Dropdown
                  label='Selected Account'
                  value={selectedAccount.accountId}
                  errorText={this.errorGet('account', rowIndex)}
                  onChange={value => this.accountSelect(value, rowIndex)}
                  options={(isEditing ? selectedAccounts : agentAccounts).map(account => ({
                    value: account.accountId,
                    display: `${account.beneficiaryName} ...${account.accountNumber.slice(account.accountNumber.length - 5)}`,
                    props: {
                      disabled: selectedAccounts.some(selAccount => selAccount.accountId === account.accountId) && selectedAccounts[rowIndex].accountId !== account.accountId,
                    },
                  }))}
                  FormControlProps={{ style: { width: '100%', minWidth: '256px' } }}
                />
              </div>

              <div className={styles.NewOneTime_amount}>
                <CurrencyInput
                  className={styles.NewOneTime_currencyInput}
                  errorText={this.errorGet('amount', rowIndex)}
                  label='Amount'
                  onChange={vals => this.amountHandle(vals, rowIndex)}
                  value={selectedAccount.contributionAmount}
                />
              </div>

              {rowIndex === 0 ?
                <div className={styles.NewOneTime_removeAccount}>
                  <Icon className={styles.NewOneTime_hideIcon}>remove_circle</Icon>
                </div>
                :
                <div className={styles.NewOneTime_removeAccount}>
                  <Tooltip
                    title='Remove account'
                    placement='right'
                  >
                    <Icon onClick={() => this.removeAccount(rowIndex)}>remove_circle</Icon>
                  </Tooltip>
                </div>
              }
            </div>
          );
        })}

        {agentAccounts.length !== selectedAccounts.length && !isEditing &&
          <div className={styles.NewOneTime_addAccount}>
            <Tooltip
              title='Add account'
              placement='right'
            >
              <Icon onClick={() => this.addAccount()}>add_circle</Icon>
            </Tooltip>
          </div>
        }

        <div className={styles.NewOneTime_totalAndErrorsContainer}>
          {errors.some(error => error.key === 'amount') && <div className={styles.NewOneTime_errors}>{`Enter an amount between ${currencyFormatter(this.props.contributionVariables.minOneTimeContribution)} and ${currencyFormatter(this.props.contributionVariables.maxOneTimeContribution)}.`}</div>}
          <div className={styles.NewOneTime_totalAmountStep0}>
            <div>Total:</div>
            <div>{currencyFormatter(selectedAccounts.map(account => account.contributionAmount).reduce((val1, val2) => val1 + val2, 0))}</div>
          </div>
        </div>
      </div>
    );
  }

  step1ContentCompose = () => {
    const { bankAccountsLoading, contributionDetails: { agentBankAccounts }, contributionVariables, onAddBankAccountModalOpen } = this.props;

    return (
      <div className={styles.NewOneTime_step1Container}>
        <DatePicker
          key='datePicker'
          label='Contribution Date'
          minDate={contributionVariables.oneTimeContributionDates[0]}
          maxDate={contributionVariables.oneTimeContributionDates[contributionVariables.oneTimeContributionDates.length - 1]}
          onChange={this.contributionDateHandle}
          shouldDisableDate={this.contributionDatesDisable}
          value={this.state.contribution.day1}
        />

        {agentBankAccounts.length > 0 ?
          <div className={styles.NewOneTime_bankAccount}>
            <LoadingOverlay show={bankAccountsLoading}>
              <Dropdown
                label='Bank Account'
                value={this.state.contribution.bankAccount.id}
                errorText={this.errorGet('bankAccount')}
                onChange={this.bankAccountSelectHandle}
                options={agentBankAccounts.map(acct => ({
                  value: acct.id,
                  display: acct.id === 0 ? acct.name : `${acct.name}, ${acct.number}`,
                }))}
                FormControlProps={{ style: { width: '256px' } }}
              />
            </LoadingOverlay>

            <Tooltip
              title='Add new bank account'
              placement='top'
            >
              <Icon onClick={onAddBankAccountModalOpen} className={styles.NewOneTime_addBank}>add</Icon>
            </Tooltip>
          </div>
          :
          <div className={styles.NewOneTime_bankAccount}>
            <LoadingOverlay show={bankAccountsLoading}>
              <Button
                onClick={onAddBankAccountModalOpen}
                variant='contained'
              >
                Add Bank Account
              </Button>
            </LoadingOverlay>
          </div>
        }
      </div>
    );
  }

  step2ContentCompose = () => {
    const { contribution: { bankAccount, day1, selectedAccounts }, termsChecked } = this.state;
    const filteredAccounts = selectedAccounts.filter(account => account.accountId);

    return (
      <div>
        <div className={styles.NewOneTime_reviewContainer}>
          {!this.props.smallWidth &&
            <div className={styles.NewOneTime_reviewAccountNumber}>
              <div className={styles.NewOneTime_detailsTitle}>Account Number</div>
              {filteredAccounts.map(account => (<div className={styles.NewOneTime_detail} key={account.accountId}>{account.accountNumber}</div>))}
            </div>
          }

          <div className={styles.NewOneTime_reviewBeneficiaryName}>
            <div className={styles.NewOneTime_detailsTitle}>Beneficiary Name</div>
            {filteredAccounts.map(account => (<div className={styles.NewOneTime_detail} key={account.accountId}>{account.beneficiaryName}</div>))}
          </div>

          {!this.props.smallWidth &&
            <div className={styles.NewOneTime_reviewBankAccount}>
              <div className={styles.NewOneTime_detailsTitle}>Bank Account</div>
              <div className={styles.NewOneTime_detail}>{bankAccount.name} {bankAccount.type}, {bankAccount.number}</div>
            </div>
          }

          {!this.props.smallWidth &&
            <div className={styles.NewOneTime_reviewDate}>
              <div className={styles.NewOneTime_detailsTitle}>Date</div>
              {filteredAccounts.map(account => (<div className={styles.NewOneTime_detail} key={account.accountId}>{dayjs(day1).format('L')}</div>))}
            </div>
          }

          <div className={styles.NewOneTime_reviewAmount}>
            <div className={styles.NewOneTime_detailsTitle}>Amount</div>
            {filteredAccounts.map(account => (<div className={styles.NewOneTime_detail} key={account.accountId}>{currencyFormatter(account.contributionAmount)}</div>))}
          </div>
        </div>

        <div className={styles.NewOneTime_totalAmountStep2}>
          <div>Total:</div>
          <div>{currencyFormatter(filteredAccounts.map(account => account.contributionAmount).reduce((val1, val2) => val1 + val2, 0))}</div>
        </div>

        <div className={styles.NewOneTime_termsContainer}>
          <div className={styles.NewOneTime_terms}>
            <TermsConditions />
          </div>
          <div className={styles.NewOneTime_checkbox}>
            <FormControlLabel
              label='I have read and understand the above terms and conditions.'
              control={
                <Checkbox
                  checked={termsChecked}
                  onChange={() => this.setState({ termsChecked: !termsChecked })}
                />
              }
            />
          </div>
        </div>
      </div>
    );
  }

  step3ContentCompose = () => {
    const { contribution: { bankAccount, day1, selectedAccounts } } = this.state;
    const filteredAccounts = selectedAccounts.filter(account => account.accountId);

    return (
      <div className={transactionsStyles.printContainer}>
        <div className={styles.NewOneTime_reviewContainer}>
          {!this.props.smallWidth &&
            <div className={styles.NewOneTime_reviewAccountNumber}>
              <div className={styles.NewOneTime_detailsTitle}>Account Number</div>
              {filteredAccounts.map(account => (<div className={styles.NewOneTime_detail} key={account.accountId}>{account.accountNumber}</div>))}
            </div>
          }

          <div className={styles.NewOneTime_reviewBeneficiaryName}>
            <div className={styles.NewOneTime_detailsTitle}>Beneficiary Name</div>
            {filteredAccounts.map(account => (<div className={styles.NewOneTime_detail} key={account.accountId}>{account.beneficiaryName}</div>))}
          </div>

          {!this.props.smallWidth &&
            <div className={styles.NewOneTime_reviewBankAccount}>
              <div className={styles.NewOneTime_detailsTitle}>Bank Account</div>
              <div className={styles.NewOneTime_detail}>{bankAccount.name} {bankAccount.type}, {bankAccount.number}</div>
            </div>
          }

          {!this.props.smallWidth &&
            <div className={styles.NewOneTime_reviewDate}>
              <div className={styles.NewOneTime_detailsTitle}>Date</div>
              {filteredAccounts.map(account => (<div className={styles.NewOneTime_detail} key={account.accountId}>{dayjs(day1).format('L')}</div>))}
            </div>
          }

          <div className={styles.NewOneTime_reviewAmount}>
            <div className={styles.NewOneTime_detailsTitle}>Amount</div>
            {filteredAccounts.map(account => (<div className={styles.NewOneTime_detail} key={account.accountId}>{currencyFormatter(account.contributionAmount)}</div>))}
          </div>
        </div>

        <div className={styles.NewOneTime_totalAmountStep2}>
          <div>Total:</div>
          <div>{currencyFormatter(filteredAccounts.map(account => account.contributionAmount).reduce((val1, val2) => val1 + val2, 0))}</div>
        </div>

        <div className={transactionsStyles.currentTime}>{dayjs(this.props.serverDateTime)
          .format('MM/DD/YYYY h:mm A')} MST</div>
        <div className='hideOnPrint'>
          Please print a copy of this page for your records.
        </div>
      </div>
    );
  }

  stepperContentCompose = () => {
    switch (this.state.stepIndex) {
      case 0: return this.step0ContentCompose();
      case 1: return this.step1ContentCompose();
      case 2: return this.step2ContentCompose();
      case 3: return this.step3ContentCompose();

      default:
        break;
    }
  }

  componentDidMount = () => {
    const { agentAccounts, contribution } = this.props.contributionDetails;

    if (Object.keys(agentAccounts).length > 0) {
      if (this.props.isEditing) {
        this.setState({ contribution });
      }
      else {
        this.defaultSelectedAccountSet();
        events.contributionsOneTimeStep(this.state.stepIndex);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.serverDateTime !== this.props.serverDateTime && this.props.serverDateTime !== null) {
      this.nextStepHandle();
    }
  }

  render() {
    const { stepIndex } = this.state;
    return (
      <Card className={styles.NewOneTime_container}>
        <div className={`${styles.NewOneTime_stepperSteps} hideOnPrint`}>
          <Stepper
            activeStep={stepIndex}
            orientation={this.props.smallWidth ? 'vertical' : 'horizontal'}
            style={this.props.smallWidth ? {} : { width: '650px' }}
          >
            <Step>
              <StepButton onClick={() => this.setState({ stepIndex: 0 })} variant='contained' disabled={stepIndex === 3}>Amount</StepButton>
            </Step>
            <Step>
              <StepButton onClick={() => this.setState({ stepIndex: 1 })} variant='contained' disabled={stepIndex === 3}>Contribution Date & Bank Account</StepButton>
            </Step>
            <Step>
              <StepButton onClick={() => this.setState({ stepIndex: 2 })} variant='contained' disabled={stepIndex === 3}>Terms & Conditions</StepButton>
            </Step>
            <Step>
              <StepButton onClick={() => this.setState({ stepIndex: 3 })} variant='contained'>Print Review</StepButton>
            </Step>
          </Stepper>
        </div>

        <div className={styles.NewOneTime_stepperContent}>
          {!this.props.loading && this.stepperContentCompose()}
        </div>

        <div className={`${styles.NewOneTime_stepperButtons} hideOnPrint`}>
          {!this.props.loading && this.stepperButtonsCompose()}
        </div>
      </Card>
    );
  }
}

export default withRouter(connect(select, {})(NewOneTime));
