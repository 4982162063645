/*
*
* LPOASettings Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { lpoaSettingsGet } from 'components/Features/protected/ManageLPOA/actions';
import { permissionGet } from 'utils/config/permissions_details';

import {
  LoadingOverlay,
  Card,
  InfoIcon,
  properCase,
} from '@frontend/common';
import {
  Icon,
  Collapse
} from '@mui/material';
import { primaryContactDescription } from 'components/Features/protected/ManageUsers/PrimaryContact';

import styles from './styles.module.css';

const select = (state) => ({
  lpoaSettings: state.manageLPOA.settings,
  claims: state.session.claims,
  staticDocs: state.static.documents,
  users: state.manageUsers.users,
});


export class LPOASettings extends React.Component {

  static propTypes = {
    lpoaSettings: PropTypes.object.isRequired,
    lpoaSettingsGet: PropTypes.func.isRequired,
    claims: PropTypes.object.isRequired,
    staticDocs: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
  };

  state = {
    loading: false,
    defaultPrimaryContact: this.props.claims.DefaultPrimaryContactName,
    showAuthorizationInstructions: false,
  };

  settingCompose(settingKey) {
    const settingConfig = permissionGet(settingKey);
    const settingEnabled = this.props.lpoaSettings[settingKey];

    if (settingConfig.label) {
      return (
        <div className={styles.LPOASettings_setting}>
          <div>
            <span>{settingConfig.label}</span>
            <InfoIcon message={settingConfig.description} />
          </div>
          <Icon>
            {settingEnabled
              ? <span className={styles.LPOASettings_settingEnabled}>done</span>
              : <span className={styles.LPOASettings_settingDisabled}>clear</span>
            }
          </Icon>
        </div>
      );
    }

    return null;
  }

  componentDidMount() {
    const { claims, lpoaSettings, lpoaSettingsGet, users, } = this.props;

    if (Object.keys(lpoaSettings).length === 0) {
      this.setState({ loading: true });
      lpoaSettingsGet()
        .catch(() => null)
        .finally(() => this.setState({ loading: false }));
    }
    
    if (!isEmpty(users)) {
      let defaultPrimaryContact = Object.values(users).filter(user => user.IsDefaultPrimaryContact)[0] || null;
      defaultPrimaryContact = defaultPrimaryContact ? defaultPrimaryContact : claims.MasterAdminName;
      this.setState({ defaultPrimaryContact });
    }
  }
  
  render() {
    const { LPOAAuthorizationCode, MasterAdminName } = this.props.claims;
    const { defaultPrimaryContact, showAuthorizationInstructions } = this.state;

    /* eslint-disable indent */
    return (
      <div className={styles.LPOASettings_page}>
        <Card
          title='LPOA Settings'
          className={styles.LPOASettings_lpoaSettingsCard}
        >
          <LoadingOverlay
            show={this.state.loading}
            indicatorHeight='10px'
            width='100%'
          >
            <div className={styles.LPOASettings_lpoaSettingsContainer}>
              {MasterAdminName && (
                <div className={styles.LPOASettings_firmDetail}>
                  <span>Master Admin</span>
                  <span>{properCase(MasterAdminName)}</span>
                </div>
              )}
              {defaultPrimaryContact && (
                <div className={styles.LPOASettings_firmDetail}>
                  <div className={styles.LPOASettings_firmDetailTitle}>
                    <span>Default Primary Contact</span>
                    <InfoIcon message={primaryContactDescription} />
                  </div>
                  <span>{properCase(defaultPrimaryContact)}</span>
                </div>
              )}
              <div className={styles.LPOASettings_lpoaSettingSection}>
                <div>Level 1</div>
                <hr />
                <div className={styles.LPOASettings_setting}>
                  <div>
                    <span>{permissionGet('AllowView').label}</span>
                  </div>
                  <Icon>
                    <span className={styles.LPOASettings_settingEnabled}>done</span>
                  </Icon>
                </div>
              </div>
              <div className={styles.LPOASettings_lpoaSettingSection}>
                <div>Level 2</div>
                <hr />
                {this.settingCompose('AllowContribute')}
                {this.settingCompose('AllowOptionChange')}
                {this.settingCompose('AllowTransfer')}
              </div>
              <div className={styles.LPOASettings_lpoaSettingSection}>
                <div>Level 3</div>
                <hr />
                {this.settingCompose('AllowWithdraw')}
              </div>
              <div className={styles.LPOASettings_lpoaSettingSection}>
                <div>Tax Documents</div>
                <hr />
                {this.settingCompose('AllowViewStateTaxDocuments')}
                {this.settingCompose('AllowViewFederalTaxDocuments')}
              </div>
            </div>
          </LoadingOverlay>
        </Card>
        <Card
          title='Online Authorization Code'
          className={styles.LPOASettings_authorizationCard}
        >
          <div className={styles.LPOASettings_authorizationContainer}>
            <div className={styles.LPOASettings_authorizationCode}>
              {LPOAAuthorizationCode
                ? [
                    <span key='code'>{LPOAAuthorizationCode}</span>,
                    <Icon
                      key='icon'
                      style={{ fontSize: '20px', color: 'var(--accent)', cursor: 'pointer' }}
                      onClick={() => this.setState({ showAuthorizationInstructions: !showAuthorizationInstructions })}
                    >
                      {showAuthorizationInstructions ? 'clear' : 'help_outline'}
                    </Icon>
                  ]
                : <span>Not enabled.</span>
              }
            </div>
            {(LPOAAuthorizationCode) && (
              <Collapse in={showAuthorizationInstructions} unmountOnExit>
                <div className={styles.LPOASettings_authorizationLanguage}>
                  Your clients may grant limited power of attorney authority to your firm through their my529 Account Access 
                  if you provide them with this Limited Power of Attorney (LPOA) Online Authorization Code. You may wish to include&nbsp;
                  <a href={this.props.staticDocs.onlineLPOAGuide} target='_blank' rel='noopener noreferrer'>these instructions</a>
                  &nbsp;when communicating this code to your clients.
                </div>
              </Collapse>
            )}
          </div>
        </Card>
      </div>
    );
    /* eslint-enable indent */
  }
}

export default connect(select, { lpoaSettingsGet })(LPOASettings);
