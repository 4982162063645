/*
*
* TransactionDetailsModal Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getTransactionDetails } from 'components/Features/protected/Accounts/actions';

import { 
  Modal,
  sizify,
  LoadingOverlay,
  
  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';

import events from 'utils/ga';

import styles from './styles.module.css';

const select = (state, { transactionId }) => {
  return {
    transaction: state.accounts.postedTransactionDetailsCollection[transactionId] || { }
  };
};

export class TransactionDetailsModal extends React.Component {

  static propTypes = {
    show: PropTypes.bool.isRequired,
    transactionId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    onClose: PropTypes.func.isRequired,
    size: PropTypes.object.isRequired,
    getTransactionDetails: PropTypes.func.isRequired,
    transaction: PropTypes.object.isRequired
  };

  state = {
    loading: false,
  };

  fundsCompose() {
    let fundComponents = [];
    const { selectedTransactionId, transactionDetails } = this.props.transaction;

    if (selectedTransactionId === this.props.transactionId) {
      if (this.props.size.windowWidth < 600) {
        fundComponents = transactionDetails.map(fund => {
          return (
            <div key={fund.id} className={styles.TransactionDetailsModal_fundContainer}>
              <div className={styles.TransactionDetailsModal_fundName}>{fund.name}</div>
              <div className={styles.TransactionDetailsModal_fundDetailsRow}>
                <span>Units:&nbsp;</span>
                <span>{fund.units}</span>
              </div>
              <div className={styles.TransactionDetailsModal_fundDetailsRow}>
                <span>Price:&nbsp;</span>
                <span>{fund.price}</span>
              </div>
              <div className={styles.TransactionDetailsModal_fundDetailsRow}>
                <span>Amount:&nbsp;</span>
                <span>{fund.amount}</span>
              </div>
            </div>
          );
        });
      }
      else {
        fundComponents = (
          <SmartTable
            idKey='id'
            rows={transactionDetails}
            columns={[
              {
                key: 'name',
                title: 'Fund Name',
                type: 'string',
              },
              {
                key: 'units',
                title: 'Units',
                type: 'string',
                hideOn: ['phone', 'tablet'],
                customStyle: { width: '125px' },
              },
              {
                key: 'price',
                title: 'NAV',
                type: 'string',
                hideOn: ['phone', 'tablet'],
                customStyle: { width: '100px' },
              },
              {
                key: 'amount',
                title: 'Amount',
                type: 'currency',
                customStyle: { width: '125px' },
              },
            ]}
          >
            <TableToolbar
              onSearchFocus={() => events.tableSearchAccessed('Transaction Details', window.location.pathname)}
            />
            <TableContainer maxHeight='100%' minWidth='100%'>
              <TableHeader />
              <TableRows />
            </TableContainer>
            <TablePagination />
          </SmartTable>
        );
      }
      return (
        <div className={styles.TransactionDetailsModal_funds}>
          {fundComponents}
        </div>
      );
    }
  }

  componentDidMount() {
    const { show, transactionId, transaction } = this.props;
    const hasDetails = Object.prototype.hasOwnProperty.call(transaction, 'selectedTransactionId');

    if (show && !hasDetails) {
      this.setState({ loading: true });
      this.props.getTransactionDetails(transactionId)
        .then(() => {
          this.setState({ loading: false });
        });
    }
  }

  render() {
    const { loading } = this.state;
    const { onClose, show, transaction } = this.props;
    return (
      <Modal
        show={show}
        title='Transaction'
        onCloseModal={onClose}
      >
        <LoadingOverlay
          show={loading}
          height='100%'
          width='100%'
          indicatorHeight='15px'
        >
          <div className={styles.TransactionDetailsModal_container}>
            <div className={styles.TransactionDetailsModal_details}>
              <div className={styles.TransactionDetailsModal_mainDetails}>
                <div className={styles.TransactionDetailsModal_detailRow}>
                  <div className={styles.TransactionDetailsModal_rowTitle}>Date</div>
                  <div className={styles.TransactionDetailsModal_rowValue}>{!loading && transaction.postDate}</div>
                </div>
                <div className={styles.TransactionDetailsModal_detailRow}>
                  <div className={styles.TransactionDetailsModal_rowTitle}>Total</div>
                  <div className={styles.TransactionDetailsModal_rowValue}>{!loading && transaction.totalAmount}</div>
                </div>
                <div className={styles.TransactionDetailsModal_detailRow}>
                  <div className={styles.TransactionDetailsModal_rowTitle}>Transaction Type</div>
                  <div className={styles.TransactionDetailsModal_rowValue}>{!loading && transaction.type}</div>
                </div>
                <div className={styles.TransactionDetailsModal_detailRow}>
                  <div className={styles.TransactionDetailsModal_rowTitle}>Type</div>
                  <div className={styles.TransactionDetailsModal_rowValue}>{!loading && transaction.source}</div>
                </div>
              </div>

              <div className={styles.TransactionDetailsModal_payeeDetails}>
                {transaction.bankInfo && !loading && [
                  <div className={styles.TransactionDetailsModal_detailRow} key='bankName'>
                    <div className={styles.TransactionDetailsModal_rowTitle}>Bank Name</div>
                    <div className={styles.TransactionDetailsModal_rowValue}>{!loading && transaction.bankInfo.bankName}</div>
                  </div>,
                  <div className={styles.TransactionDetailsModal_detailRow} key='bankNumber'>
                    <div className={styles.TransactionDetailsModal_rowTitle}>Number</div>
                    <div className={styles.TransactionDetailsModal_rowValue}>{!loading && transaction.bankInfo.bankAccountNumber}</div>
                  </div>,
                  <div className={styles.TransactionDetailsModal_detailRow} key='bankType'>
                    <div className={styles.TransactionDetailsModal_rowTitle}>Bank Account Type</div>
                    <div className={styles.TransactionDetailsModal_rowValue}>{!loading && transaction.bankInfo.bankAccountType}</div>
                  </div>
                ]}
                {transaction.accessCardNumber && !loading &&
                  <div className={styles.TransactionDetailsModal_detailRow}>
                    <div className={styles.TransactionDetailsModal_rowTitle}>Card Number</div>
                    <div className={styles.TransactionDetailsModal_rowValue}>{!loading && transaction.accessCardNumber}</div>
                  </div>}
                {transaction.payee && !transaction.accessCardNumber && !loading && // should not show for any Access Card transactions
                  <div className={styles.TransactionDetailsModal_detailRow}>
                    <div className={styles.TransactionDetailsModal_rowTitle}>Payee Name</div>
                    <div className={styles.TransactionDetailsModal_rowValue}>{!loading && transaction.payee}</div>
                  </div>}
              </div>

            </div>
            {!loading && this.fundsCompose()}
          </div>
        </LoadingOverlay>
      </Modal>
    );
  }
  
}

export default sizify(connect(select, { getTransactionDetails })(TransactionDetailsModal));
