import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  TextField,
  Button,
} from '@mui/material';

import {
  My529Logo,
  InfoIcon,
  IconBtnTooltip,
  LoadingOverlay,
  notificationShow,
  properCase,
} from '@frontend/common';

import { advisorNameUpdate } from 'components/AppRoot/Navigation/actions';
import { contactInfoUpdate } from '../actions';


import styles from '../styles.module.css';

const select = (state) => ({
  lpoaHelpNumber: state.static.environmentVars.lpoaHelpNumber,
  advisor: state.myInfo.advisor,
  claims: state.session.claims,
});

export class NameHeaderWithEdit extends React.Component {
  static propTypes = {
    lpoaHelpNumber: PropTypes.string.isRequired,
    notificationShow: PropTypes.func.isRequired,
    advisor: PropTypes.object.isRequired,
    advisorNameUpdate: PropTypes.func.isRequired,
    contactInfoUpdate: PropTypes.func.isRequired,
    claims: PropTypes.object.isRequired,
  };

  state = {
    showNameEdit: false,
    firstName: '',
    lastName: '',
    firstNameError: '',
    lastNameError: '',
    submitting: false,
  }

  disableUpdateButton = () => {
    const { firstName, lastName } = this.state;
    const { FirstName, LastName } = this.props.advisor;
    let firstNameError = '';
    let lastNameError = '';
    if (!firstName) {
      firstNameError = 'Field is required.';
    }

    if (!lastName) {
      lastNameError = 'Field is required.';
    }

    if (firstName === FirstName && lastName === LastName) {
      firstNameError = 'No change to update.';
      lastNameError = 'No change to update.';
    }

    return Boolean(firstNameError) && Boolean(lastNameError);
  }

  onNameSubmit = (e) => {
    e.preventDefault();
    const { firstName, lastName } = this.state;

    const updatedAdvisor = {
      FinancialAdvisorId: this.props.advisor.FinancialAdvisorId,
      FirstName: firstName,
      LastName: lastName
    };
    this.setState({ submitting: true });
    this.props.contactInfoUpdate(updatedAdvisor)
      .then(() => {
        this.props.advisorNameUpdate(properCase(`${firstName} ${lastName}`));
        this.props.notificationShow('Advisor name updated.', 'success');
      })
      .finally(() => this.setState({ submitting: false }));
  }

  onNameChange = (keyName, value) => {
    // set value and clear all errors
    this.setState({ [keyName]: value, firstNameError: '', lastNameError: '' });
  }

  onCancel = () => {
    const { FirstName, LastName } = this.props.advisor;
    // also clear form errors and set name to the default one
    this.setState({
      showNameEdit: false,
      firstNameError: '',
      lastNameError: '',
      firstName: FirstName,
      lastName: LastName
    });
  }

  componentDidMount() {
    // set advisor name to its default values if already in Redux
    const { FirstName, LastName } = this.props.advisor;
    this.setState({
      firstName: FirstName,
      lastName: LastName
    });
  }

  componentDidUpdate(prevProps) {
    // once adviser details loads set advisor name to its default values if not already in Redux
    const { FirstName, LastName } = this.props.advisor;
    if (prevProps.advisor.FirstName !== FirstName || prevProps.advisor.LastName !== LastName) {
      this.setState({
        firstName: FirstName,
        lastName: LastName
      });
    }
  }

  render() {
    const {
      claims: { MasterAdmin },
      advisor: { FirstName, LastName },
      lpoaHelpNumber
    } = this.props;
    const { 
      showNameEdit, firstName, lastName, 
      firstNameError, lastNameError, submitting 
    } = this.state;

    const isMasterAdmin = MasterAdmin === 'true';

    return (
      <div className={styles.myInfo_header}>
        <div>
          <div>Advisor</div>
          <div className={styles.myInfo_nameContainer}>
            <h2 className={styles.myInfo_name}>
              {!showNameEdit
                ?
                `${FirstName} ${LastName}`
                :
                (
                  <form className={styles.myInfo_nameEdit} onSubmit={this.onNameSubmit}>
                    <div>
                      <TextField
                        label='First Name'
                        name='firstName'
                        value={firstName}
                        onChange={(e) => this.onNameChange('firstName', e.target.value)}
                        error={Boolean(firstNameError)}
                        helperText={firstNameError}
                        disabled={submitting}
                      />
                    </div>
                    <div>
                      <TextField
                        label='Last Name'
                        name='lastName'
                        value={lastName}
                        onChange={(e) => this.onNameChange('lastName', e.target.value)}
                        error={Boolean(lastNameError)}
                        helperText={lastNameError}
                        disabled={submitting}
                      />
                    </div>
                    {/* There are no CSS styles for myInfo_nameEditButton */}
                    <div className='myInfo_nameEditButton'>
                      <LoadingOverlay show={submitting}>
                        <Button
                          type='submit'
                          variant='contained'
                          disabled={this.disableUpdateButton()}
                        >
                          Update
                        </Button>
                      </LoadingOverlay>
                    </div>
                    <div className='myInfo_nameEditButton'>
                      <Button
                        variant='outlined'
                        onClick={this.onCancel}
                        disabled={submitting}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                )

              }
            </h2>
            {isMasterAdmin
              ?
              <InfoIcon message={`Please contact my529 at ${lpoaHelpNumber} for information about changing the Master Administrator.`} />
              :
              <div className={styles.myInfo_nameEditIcon}>
                {!showNameEdit &&
                  <IconBtnTooltip
                    icon='edit'
                    onClick={() => this.setState({ showNameEdit: true })}
                    title='Change advisor name'
                  />
                }

              </div>
            }
          </div>
        </div>
        <div><My529Logo /></div>
      </div>
    );
  }
}

export default connect(select, {
  advisorNameUpdate,
  contactInfoUpdate,
  notificationShow
})(NameHeaderWithEdit);
