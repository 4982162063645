export const ACCOUNT_TYPE_INSTITUTIONAL = 'Institutional';
export const ACCOUNT_TYPE_UGMA_UTMA = 'UGMA/UTMA';
export const ACH_PAYMENT = 'Ach';
export const ACCOUNT_OWNER = 'P';
export const BENEFICIARY = 'B';
export const CHECK_PAYMENT = 'Check';
export const K12_ED = 'K-12Tuition';
export const FULL_BALANCE = 'Full Balance';
export const HIGHER_ED = 'HigherEd';
export const NONQUALIFIED = 'Nonqualified';
export const ONE_TIME = 'One-Time';
export const PARTIAL_BALANCE = 'Partial Balance';
export const SCHOOL = 'I';
export const ELECTRONIC_PAYMENT = 'ElectronicSchoolPayment';