import { connectGA } from './helpers';
// import * as sampleEvents from './Dashboard.samples';
import * as pageNotFoundEvents from './events/PageNotFound.events.js';
import * as appTourEvents from './events/AppTour.events.js';
import * as navigationEvents from './events/Navigation.events.js';
import * as commonEvents from './events/CommonPackage.events.js';
import * as transactionEvents from './events/Transactions.events.js';

const connectedGAEvents = connectGA({
  ...pageNotFoundEvents,
  ...appTourEvents,
  ...navigationEvents,
  ...commonEvents,
  ...transactionEvents,
});

export default connectedGAEvents;
