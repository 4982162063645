/*
*
* AccountInvestmentPath Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  LoadingOverlay,
  Breadcrumbs,
} from '@frontend/common';
import TemplateView from 'components/Features/protected/Templates/TemplateView';
import { accountInvestmentPathGet } from '../actions';
import { getAccountBaseRoute } from '../helpers';

import styles from './styles.module.css';

const select = (state) => ({
  investmentPath: state.accounts.investmentPath
});

export class AccountInvestmentPath extends React.Component {

  static propTypes = {
    accountInvestmentPathGet: PropTypes.func.isRequired,
    investmentPath: PropTypes.object,
  };

  state = {
    loading: false,
  };

  getInvestmentPath = () => {
    this.setState({ loading: true });
    this.props.accountInvestmentPathGet(this.props.match.params.accountId)
      .catch(() => null)
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  componentDidMount() {
    // for simplicity always load
    this.getInvestmentPath();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.accountId !== this.props.match.params.accountId ||
      prevProps.investmentPath.TemplateId !== this.props.investmentPath.TemplateId
    ) {
      this.getInvestmentPath();
    }
  }

  render() {
    const breadcrumbs = (
      <Breadcrumbs
        crumbs={[
          {
            title: 'Accounts',
            link: '/accounts',
          },
          {
            title: 'Details',
            link: getAccountBaseRoute(this.props.match.params),
          },
          { title: 'Investment Path' }
        ]}
      />
    );

    return (
      <div className={styles.AccountInvestmentPath_page}>
        <div className={styles.AccountInvestmentPath_container}>
          {breadcrumbs}
          {this.state.loading
            ?
            <LoadingOverlay
              show={true}
              width='100%'
              indicatorHeight='10px'
            />
            :
            <div>
              <div className={styles.AccountInvestmentPath_name}>
                {this.props.investmentPath.TemplateName}
              </div>
              <TemplateView
                hideTemplateName={true}
                showTemplateBreadcrumbs={false}
                investmentPath={this.props.investmentPath}
                loading={this.state.loading}
              />
            </div>
          }
        </div>
      </div>
    );
  }
}

export default connect(select, { accountInvestmentPathGet })(AccountInvestmentPath);
