/*
*
* BulkOptionChange Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Banner from 'components/Features/Banner';
import { BANNER_LOCATIONS } from 'components/AppRoot/StaticResources/constants';

import {
  Card,
  notificationShow,
  LoadingOverlay,
  InfoIcon,
  Dropdown,

  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';

import {
  Stepper,
  Step,
  StepButton,
  Button,
  Input,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';

import {
  STATIC,
  ENROLLMENTDATE,
  CUSTOMIZED_AGEBASED_TEMPLATES,
  CUSTOMIZED_STATIC_TEMPLATES,
} from '../Accounts/Transactions/OptionChanges/NewOptions/constants';

import { customOptionsGet, optionsGet } from 'components/AppRoot/StaticResources/actions';
import { getCandidatesForBulkOptionChange, createBulkOptionChange } from '../Accounts/actions';

import events from 'utils/ga';

import TermsAndConditions from '../Accounts/Transactions/OptionChanges/TermsAndConditions';
import styles from './styles.module.css';

const select = (state) => ({
  customStaticOptions: state.static.customStaticOptions,
  customAgeBasedOptions: state.static.customAgeBasedOptions,
  predefinedOptions: state.static.options.filter(opt => (opt.name.search('Customized') === -1)),
  candidates: state.accounts.bulkOptionChangeCandidates,
  documents: state.static.documents,
  webMessages: state.static.webMessages,
});

export class BulkOptionChange extends React.Component {
  static propTypes = {
    webMessages: PropTypes.object.isRequired,
    customOptionsGet: PropTypes.func.isRequired,
    optionsGet: PropTypes.func.isRequired,
    customStaticOptions: PropTypes.array.isRequired,
    customAgeBasedOptions: PropTypes.array.isRequired,
    predefinedOptions: PropTypes.array.isRequired,
    notificationShow: PropTypes.func.isRequired,
    candidates: PropTypes.arrayOf(PropTypes.shape({
      // Ids
      AccountGroupId: PropTypes.number.isRequired,
      AccountId: PropTypes.number.isRequired,
      AccountNumber: PropTypes.number.isRequired,
      // Names + meta
      Agent: PropTypes.string.isRequired,
      Beneficiary: PropTypes.string.isRequired,
      OptionName: PropTypes.string.isRequired,
      RemainingOptionChanges: PropTypes.number.isRequired,
      // Flags
      AccountGroupConflict: PropTypes.bool.isRequired,
      OptionChangePendingConflict: PropTypes.bool.isRequired,
      OptionConflict: PropTypes.bool.isRequired,
    })).isRequired,
    getCandidatesForBulkOptionChange: PropTypes.func.isRequired,
    createBulkOptionChange: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    documents: PropTypes.object.isRequired,
  };

  state = {
    stepIndex: 0,
    loading: true,
    isLoadingSubmit: false,
    selectedCategoryName: '',
    selectedOptionId: '',
    optionCategories: [],
    isLoadingAccounts: false,
    selectableCandidates: [],
    selectedCandidateIds: [],
    termsChecked: [false, false],
    accountSelection: 'none',
    accountGroupConflicts: 0,
    optionChangePendingConflicts: 0,
    optionConflicts: 0,
    zeroOptionChanges: 0,
  };

  onOptionCategoryChange = value => {
    this.setState({
      selectedCategoryName: value,
      selectedOptionId: '',
    });
  };

  onOptionChange = value => {
    this.setState({
      selectedOptionId: value,
      isLoadingAccounts: true,
    });
    const queryKey = this.state.selectedCategoryName === STATIC ? 'optionId' : 'customTemplateHeaderId';
    this.props.getCandidatesForBulkOptionChange(`?${queryKey}=${value}`).then(this.prepareCandidates);
  };

  prepareCandidates = () => {
    const { selectedCandidateIds } = this.state;
    const { candidates } = this.props;
    const newSelectableCandidates = candidates.filter(candidate =>
      !(candidate.AccountGroupConflict || candidate.OptionChangePendingConflict || candidate.OptionConflict) &&
      candidate.RemainingOptionChanges > 0
    );
    const newSelectableIds = newSelectableCandidates.map(candidate => candidate.AccountId);
    const newSelectedIds = selectedCandidateIds.filter(selectedId => newSelectableIds.includes(selectedId));


    this.setState({
      isLoadingAccounts: false,
      selectableCandidates: newSelectableCandidates.map(account => {
        if (newSelectedIds.includes(account.AccountId)) {
          return {
            ...account,
            selected: true
          };
        }
        return {
          ...account,
          selected: false
        };
      }),
      selectedCandidateIds: newSelectedIds,
      accountSelection: this.accountSelectionGet(newSelectedIds, newSelectableCandidates),
      accountGroupConflicts: candidates.reduce((sum, candidate) => candidate.AccountGroupConflict ? sum + 1 : sum, 0),
      optionChangePendingConflicts: candidates.reduce((sum, candidate) => candidate.OptionChangePendingConflict ? sum + 1 : sum, 0),
      optionConflicts: candidates.reduce((sum, candidate) => candidate.OptionConflict ? sum + 1 : sum, 0),
      zeroOptionChanges: candidates.reduce((sum, candidate) => candidate.RemainingOptionChanges === 0 ? sum + 1 : sum, 0),
    });
  };

  handleNextStep = () => {
    const { createBulkOptionChange, notificationShow, history, } = this.props;
    const { stepIndex, selectedCategoryName, selectedOptionId, selectedCandidateIds, } = this.state;
    if (stepIndex === 2) {
      this.setState({ isLoadingSubmit: true });
      createBulkOptionChange({
        AccountIds: selectedCandidateIds,
        [selectedCategoryName === STATIC || selectedCategoryName === ENROLLMENTDATE ? 'OptionId' : 'CustomTemplateHeaderId']: selectedOptionId,
      }).then(action => {
        const messages = (action.payload.data || []).filter(message => message.MessageType === 1);
        if (messages.length === 0) {
          notificationShow('Bulk Option Change scheduled.', 'success');
        }
        else {
          notificationShow(`Bulk Option Change scheduled for ${selectedCandidateIds - messages.length} of ${selectedCandidateIds} accounts.`, 'warning');
        }
      })
        .catch(() => null)
        .finally(() => {
          this.setState({ isLoadingSubmit: false });
          history.push('/');
        });
    }
    else {
      this.setState({
        stepIndex: stepIndex + 1,
      });
    }
    window.scrollTo(0, 0);
  };

  handlePrevStep = () => {
    this.setState({
      stepIndex: this.state.stepIndex - 1,
    });
    window.scrollTo(0, 0);
  };

  stepZeroContent = () => {
    const { selectedCategoryName, selectedOptionId, optionCategories } = this.state;
    const selectedCategory = optionCategories.find(category => category.name.search(selectedCategoryName) !== -1);
    return (
      // There are no CSS styles for NewOptions_multipleSelectFieldContainer
      <div className='NewOptions_multipleSelectFieldContainer'>
        <div>
          <LoadingOverlay show={this.state.loading} >
            <Dropdown
              label='Option Category'
              value={selectedCategoryName}
              onChange={this.onOptionCategoryChange}
              options={optionCategories.map(category => ({ value: category.name }))}
              FormControlProps={{ style: { width: '285px' } }}
              InputLabelProps={{ htmlFor: 'optionCategorySelect' }}
              SelectProps={{ inputProps: { id: 'optionCategorySelect' } }}
            />
          </LoadingOverlay>
        </div>
        {(selectedCategoryName !== '' && selectedCategory !== undefined && selectedCategory.options.length > 0) && (
          <div style={{ alignSelf: 'center' }}>
            <Dropdown
              label='Option'
              value={selectedOptionId}
              onChange={this.onOptionChange}
              options={selectedCategory.options.map(option => ({
                value: option.id,
                display: option.name,
                props: { disabled: option.id === '' },
              }))}
              FormControlProps={{ style: { width: '285px' } }}
              InputLabelProps={{ htmlFor: 'optionSelect' }}
              SelectProps={{
                inputProps: { id: 'optionSelect' },
                input: <Input />
              }}
            />
          </div>
        )}
      </div>
    );
  };

  accountSelectionGet = (selectedCandidateIds, selectableCandidates = this.state.selectableCandidates) => {
    if (selectableCandidates.length > 0 && selectedCandidateIds.length === 0) {
      return 'none';
    }
    else if (selectedCandidateIds.length !== selectableCandidates.length ||
      (this.state.accountSelection === 'some' && selectedCandidateIds.length > 0)) {
      return 'some';
    }
    return 'all';
  };

  onRadioButtonToggle = (val) => {
    const { selectedCandidateIds, selectableCandidates } = this.state;
    let nextSelected = selectedCandidateIds;
    let nextCandidates = selectableCandidates;

    if (val === 'all') {
      nextCandidates = selectableCandidates.map(account => ({
        ...account,
        selected: true,
      }));
      nextSelected = this.state.selectableCandidates.map(candidate => candidate.AccountId);
    }
    else if (val === 'none') {
      nextSelected = [];
      nextCandidates = selectableCandidates.map(account => ({
        ...account,
        selected: false,
      }));
    }

    this.setState({
      selectableCandidates: nextCandidates,
      selectedCandidateIds: nextSelected,
      accountSelection: val,
    });
  };

  stepOneContent = () => {
    const { accountSelection, selectableCandidates, selectedCandidateIds, isLoadingAccounts,
      accountGroupConflicts, optionChangePendingConflicts, optionConflicts, zeroOptionChanges,
    } = this.state;
    return (
      <div className={styles.BulkOptionChange_accountSelectionContainer}>
        {selectableCandidates.length < this.props.candidates.length &&
          <div className={styles.BulkOptionChange_ineligibleContainer}>
            <div className={styles.BulkOptionChange_ineligibleHeader}>
              Accounts not eligible for Bulk Option Change:
              <InfoIcon
                message='Accounts are ineligible for an option change if: (1)They are part of an account group (more than one account for the same account owner/beneficiary combination); (2) An option change is already scheduled; (3) There are no remaining option changes available for the account for the year; (4) The account is already invested in the selected option.'
              />
            </div>
            <ul>
              {/* There are no CSS styles for BulkOptionChange_ineligibleCount */}
              {accountGroupConflicts > 0 &&
                <li>
                  <span className='BulkOptionChange_ineligibleCount'>{accountGroupConflicts}</span> account(s) in account groups (more than one account for the same account owner/beneficiary combination)
                </li>
              }
              {optionChangePendingConflicts > 0 &&
                <li>
                  <span className='BulkOptionChange_ineligibleCount'>{optionChangePendingConflicts}</span> account(s) with pending option changes
                </li>
              }
              {optionConflicts > 0 &&
                <li>
                  <span className='BulkOptionChange_ineligibleCount'>{optionConflicts}</span> account(s) already in selected option
                </li>
              }
              {zeroOptionChanges > 0 &&
                <li>
                  <span className='BulkOptionChange_ineligibleCount'>{zeroOptionChanges}</span> account(s) with no option changes left
                </li>
              }
            </ul>
          </div>
        }
        <div>
          <div className={styles.BulkOptionChange_radioButtons}>
            <RadioGroup
              aria-label='Mass Account Selection'
              name='accountSelection'
              value={accountSelection}
              onChange={e => this.onRadioButtonToggle(e.target.value)}
            >
              <FormControlLabel
                value='all'
                label='All Accounts'
                control={<Radio disabled={isLoadingAccounts} disableRipple />}
              />
              <FormControlLabel
                value='some'
                label='Select Specific Accounts'
                control={<Radio disabled={isLoadingAccounts} disableRipple />}
              />
              <FormControlLabel
                value='none'
                label='None'
                control={<Radio disabled={isLoadingAccounts} disableRipple />}
              />
            </RadioGroup>
          </div>
          <div className={styles.BulkOptionChange_count}>
            <strong>
              {selectedCandidateIds.length}
            </strong> of {selectableCandidates.length} accounts selected
          </div>
        </div>
        <div className={styles.BulkOptionChange_tableContainer}>
          <SmartTable
            idKey='AccountId'
            rows={selectableCandidates}
            loading={isLoadingAccounts}
            emptyMessage='No eligible accounts for this option.'
            selectEnabled
            onChange={rows => {
              const selectedCandidateIds = rows.filter(row => row.selected).map(row => row.AccountId);
              this.setState({
                selectedCandidateIds,
                selectableCandidates: rows,
                accountSelection: this.accountSelectionGet(selectedCandidateIds),
              });
            }}
            columns={[
              {
                key: 'AccountNumber',
                title: 'Account Number',
                type: 'number',
                customStyle: { width: '.75fr' },
                hideOn: ['phone'],
              },
              {
                key: 'Agent',
                title: 'Account Owner / Agent',
                type: 'string',
              },
              {
                key: 'Beneficiary',
                title: 'Beneficiary',
                type: 'string',
              },
              {
                key: 'OptionName',
                title: 'Investment Option',
                type: 'string',
                hideOn: ['phone', 'tablet'],
                customStyle: { width: '1.25fr' },
              },
              {
                key: 'RemainingOptionChanges',
                title: 'Remaining Option Changes',
                type: 'number',
                hideOn: ['phone', 'tablet'],
                customStyle: { width: '125px' },
              },
            ]}
          >
            <TableToolbar
              onSearchFocus={() => events.tableSearchAccessed('Bulk Option Change', window.location.pathname)}
            />
            <TableContainer maxHeight='100%' minWidth='100%'>
              <TableHeader />
              <TableRows />
            </TableContainer>
            <TablePagination />
          </SmartTable>
        </div>
      </div>
    );
  };

  stepTwoContent = () => {
    const { termsChecked } = this.state;
    return (
      <TermsAndConditions
        checkboxes={[
          {
            checked: termsChecked[0],
            onCheck: () => this.setState({ termsChecked: [!termsChecked[0], termsChecked[1]] }),
          },
          {
            checked: termsChecked[1],
            label: 'I understand that this will send emails for all accounts selected.',
            onCheck: () => this.setState({ termsChecked: [termsChecked[0], !termsChecked[1]] }),
          }
        ]}
        documents={this.props.documents}
      />
    );
  };

  stepperContentCompose = () => {
    switch (this.state.stepIndex) {
      case 0: return this.stepZeroContent();
      case 1: return this.stepOneContent();
      case 2: return this.stepTwoContent();
      default: break;
    }
  };

  goToPreviousPage = () => {
    this.props.history.goBack();
  }

  stepperButtonsCompose = () => {
    const { isLoadingSubmit, selectedOptionId, selectedCandidateIds, termsChecked } = this.state;
    const cancelButton = (
      <Button
        variant='text'
        key='cancel'
        disabled={isLoadingSubmit}
        onClick={this.goToPreviousPage}
        style={{ marginRight: '5px' }}
      >
        Cancel
      </Button>
    );
    const nextButton = (disabled = false) => (
      <Button
        key='nextStep'
        disabled={disabled}
        onClick={() => this.handleNextStep()}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Next
      </Button>
    );
    const backButton = (
      <Button
        key='prevStep'
        onClick={() => this.handlePrevStep()}
        variant='text'
        disabled={isLoadingSubmit}
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Back
      </Button>
    );
    const submitButton = (
      <Button
        onClick={() => this.handleNextStep()}
        variant='contained'
        disabled={!termsChecked.reduce((sum, val) => sum && val, true)}
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Submit
      </Button>
    );

    let buttons = [];
    switch (this.state.stepIndex) {
      case 0:
        buttons = [
          cancelButton,
          nextButton(selectedOptionId === '')
        ];
        break;
      case 1:
        buttons = [
          cancelButton,
          backButton,
          nextButton(selectedCandidateIds.length === 0)
        ];
        break;
      case 2:
        buttons = [
          cancelButton,
          backButton,
          <LoadingOverlay key='nextStep' show={isLoadingSubmit}>
            {submitButton}
          </LoadingOverlay>
        ];
        break;
      default: break;
    }
    return buttons;
  };

  componentDidMount() {
    Promise.all([
      this.props.customOptionsGet(),
      this.props.optionsGet(),
    ]).then(() => {
      const { customAgeBasedOptions, customStaticOptions, predefinedOptions } = this.props;
      const optionCategories = [STATIC, ENROLLMENTDATE];

      if (this.props.customStaticOptions.length > 0) {
        optionCategories.splice(2, 0, CUSTOMIZED_STATIC_TEMPLATES);
      }
      if (this.props.customAgeBasedOptions.length > 0) {
        optionCategories.splice(2, 0, CUSTOMIZED_AGEBASED_TEMPLATES);
      }

      this.setState({
        optionCategories: optionCategories.map(categoryName => {
          let options;
          switch (categoryName) {
            case STATIC: {
              options = predefinedOptions.filter(option => option.optionType === 'S');
              break;
            }

            case ENROLLMENTDATE: {
              options = predefinedOptions.filter(option => option.optionType === 'T');
              break;
            }

            case CUSTOMIZED_AGEBASED_TEMPLATES: {
              options = customAgeBasedOptions;
              break;
            }

            case CUSTOMIZED_STATIC_TEMPLATES: {
              options = customStaticOptions;
              break;
            }
            default: break;
          }
          return {
            name: categoryName,
            options,
          };
        }),
        loading: false
      });
    })
      .catch(() => null);
  }

  render() {
    const { webMessages } = this.props;

    return (
      <>
        <Banner show={Boolean(webMessages[BANNER_LOCATIONS.BULK_OPTION_CHANGE])} body={webMessages[BANNER_LOCATIONS.BULK_OPTION_CHANGE]} />
        <div className={styles.BulkOptionChange_container}>
          <Card className={styles.BulkOptionChange_workflow}>
            <Stepper
              activeStep={this.state.stepIndex}
              orientation={window.innerWidth <= 1200 ? 'vertical' : 'horizontal'}
            >
              <Step>
                <StepButton onClick={() => this.setState({ stepIndex: 0 })}>Select New Option</StepButton>
              </Step>
              <Step>
                <StepButton onClick={() => this.setState({ stepIndex: 1 })}>Select Accounts</StepButton>
              </Step>
              <Step>
                <StepButton onClick={() => this.setState({ stepIndex: 2 })}>Terms & Conditions</StepButton>
              </Step>
            </Stepper>
            <div className={styles.BulkOptionChange_contentContainer}>
              {this.stepperContentCompose()}
            </div>
            <div>
              {this.stepperButtonsCompose()}
            </div>
          </Card>
        </div>
      </>
    );
  }
}

export default connect(select, {
  customOptionsGet,
  optionsGet,
  notificationShow,
  getCandidatesForBulkOptionChange,
  createBulkOptionChange,
})(BulkOptionChange);
