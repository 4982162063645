import React, { Component } from 'react';
import { Switch, withRouter, Route } from 'react-router-dom';

import { Paper } from '@mui/material';

import { My529Logo } from '@frontend/common';

import Registration from './Registration/index';
import VerifyIdentity from './VerifyIdentity/index';
import Authentication from './Authentication/index';
import Welcome from './Welcome/index';

import styles from './styles.module.css';

export class Multifactor extends Component {

  render() {
    return (
      <div className={styles.container}>
        <Paper>
          <div className={styles.body}>
            <div className={styles.logo}><My529Logo /></div>
            <Switch>
              <Route exact path='/multifactor' render={props => (<Welcome {...props} />)} />
              <Route exact path='/multifactor/register' render={props => (<Registration {...props} />)} />
              <Route exact path='/multifactor/verify-identity' render={props => (<VerifyIdentity {...props} />)} />
              <Route exact path='/multifactor/authenticate' render={props => (<Authentication {...props} />)} />
            </Switch>
          </div>
        </Paper>
      </div>
    );
  }
}

export default withRouter(Multifactor);