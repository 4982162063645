import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import bowser from 'bowser';

import { getAccountBaseRoute } from './helpers';
import {
  getAccountTransactionsDetails,
  getOptionChangeDetails,
  transactionPreview,
  getAccounts,
  getAccountsByAgent,
  getAgentBankAccounts,
  getContributionDetails,
  getWithdrawalDetails,
  getAccountDetails,
  getTransferAccounts,
} from './actions';
import { TRANSACTION_TYPES } from './constants';
import {
  customOptionsGet,
  optionsGet,
  getEnvironmentVariables,
} from 'components/AppRoot/StaticResources/actions';

import AccountDetails from './AccountDetails';
import AccountsList from './AccountsList';
import PageNotFound from 'components/Features/protected/PageNotFound';
import Contributions from './Transactions/Contributions';
import OptionChanges from './Transactions/OptionChanges';
import Transfers from './Transactions/Transfers';
import Withdrawals from './Transactions/Withdrawals';
import Documents from './Documents';
import Gifting from './Gifting';
import AccountInvestmentPath from './AccountInvestmentPath';
import Banner from 'components/Features/Banner';
import { BANNER_LOCATIONS } from 'components/AppRoot/StaticResources/constants';

import {
  Modal,
  LoadingOverlay,
} from '@frontend/common';
import { Icon } from '@mui/material';

import styles from './styles.module.css';

const select = (state) => ({
  accountDetails: state.accounts.selectedAccount,
  accountList: state.accounts.accountList,
  contributionDetails: state.accounts.contributionDetails,
  optionChangeDetails: state.accounts.optionChangeDetails,
  permissions: state.session.permissions,
  withdrawalDetails: state.accounts.withdrawalDetails,
  previewDetails: state.accounts.transactionPreviewDetails,
  options: state.static.options,
  customStaticTemplates: state.static.customStaticOptions,
  customAgeBasedTemplates: state.static.customAgeBasedOptions,
  contributionVariables: state.static.environmentVars.contributionVariables,
  withdrawalVariables: state.static.environmentVars.withdrawalVariables,
  webMessages: state.static.webMessages,
});


export class Accounts extends React.Component {
  
  static propTypes = {
    webMessages: PropTypes.object.isRequired,
    accountList: PropTypes.array.isRequired,
    accountDetails: PropTypes.object.isRequired,
    contributionDetails: PropTypes.object.isRequired,
    optionChangeDetails: PropTypes.object.isRequired,
    permissions: PropTypes.object.isRequired,
    withdrawalDetails: PropTypes.object.isRequired,
    getAccountTransactionsDetails: PropTypes.func.isRequired,
    getOptionChangeDetails: PropTypes.func.isRequired,
    transactionPreview: PropTypes.func.isRequired,
    previewDetails: PropTypes.object,
    options: PropTypes.array,
    customStaticTemplates: PropTypes.array,
    customAgeBasedTemplates: PropTypes.array,
    optionsGet: PropTypes.func.isRequired,
    customOptionsGet: PropTypes.func.isRequired,
    getAccounts: PropTypes.func.isRequired,
    getAccountsByAgent: PropTypes.func.isRequired,
    getAgentBankAccounts: PropTypes.func.isRequired,
    getEnvironmentVariables: PropTypes.func.isRequired,
    getContributionDetails: PropTypes.func.isRequired,
    contributionVariables: PropTypes.object,
    withdrawalVariables: PropTypes.object,
    getWithdrawalDetails: PropTypes.func.isRequired,
    getAccountDetails: PropTypes.func.isRequired,
    getTransferAccounts: PropTypes.func.isRequired,
  };

  state = {
    showTransactionPrepModal: false,
    preparingTransaction: false,
    transactionTitle: '',
    transactionTitleSuffix: '',
  };

  accountActionHandle = (action, accountId, groupId, agentId) => {
    const baseURL = getAccountBaseRoute({ agentId, groupId, accountId });
    switch (action) {
      case 'optionChanges': {
        this.optionChangePrepare(accountId, groupId, baseURL);
        break;
      }
      case 'contributions': {
        this.contributionPrepare(accountId, agentId, baseURL);
        break;
      }
      case 'transfers': {
        this.transferPrepare(accountId, baseURL);
        break;
      }
      case 'withdrawals': {
        this.withdrawalPrepare(accountId, groupId, agentId, baseURL);
        break;
      }
      case 'documents': {
        this.props.history.push(`${baseURL}/documents`);
        break;
      }
      case 'gifting': {
        this.props.history.push(`${baseURL}/gifting`);
        break;
      }
      default: {
        if (process.env.NODE_ENV === 'development') {
          console.error('accountActionHandle() requires one of these as the action argument:\n\noptionChange\ncontibutions\ntransfers\nwithdrawals\ndocuments'); // eslint-disable-line no-console
        }
      }
    }
  };

  /**
   * @typedef TransactionPreviewFulfilled
   * @param {object} action
   * @returns {Promise}
   */

  /**
   *
   * @param transactionType
   * @param accountId
   * @param onFulfilled {TransactionPreviewFulfilled} onFulfilled
   */
  transactionPreviewWrapper = (transactionType, accountId, onFulfilled) => {
    let transactionTitle;
    let transactionTitleSuffix = ' cannot be performed on this account because:';
    switch (transactionType) {
      case TRANSACTION_TYPES.CONTRIBUTION:
        transactionTitle = 'Contributions';
        break;
      case TRANSACTION_TYPES.OPTION_CHANGE:
        transactionTitle = 'Option Changes';
        break;
      case TRANSACTION_TYPES.TRANSFER:
        transactionTitle = 'Transfers';
        transactionTitleSuffix = ' cannot be made from this account because:';
        break;
      case TRANSACTION_TYPES.WITHDRAWAL:
        transactionTitle = 'Withdrawals';
        break;
      default:
        transactionTitle = '';
        break;
    }

    this.setState({
      showTransactionPrepModal: true,
      preparingTransaction: true,
      transactionTitle,
      transactionTitleSuffix,
    });

    this.props.transactionPreview(transactionType, accountId)
      .then(action => {
        const { previewDetails } = this.props;
        if (previewDetails.canContinue && previewDetails.errors.length === 0) {
          onFulfilled(action)
            .finally(() => {
              this.setState({
                showTransactionPrepModal: false,
                preparingTransaction: false,
                transactionTitle: '',
                transactionTitleSuffix: '',
              });
            });
        }
        else {
          this.setState({ preparingTransaction: false });
        }
      })
      .catch(() => {
        this.setState({ preparingTransaction: false });
      });
  };

  contributionPrepare(accountId, agentId, baseURL) {
    this.transactionPreviewWrapper(TRANSACTION_TYPES.CONTRIBUTION, accountId, () => {
      const { contributionVariables } = this.props;
      const apiCalls = [
        this.props.getAccountsByAgent(agentId, 'canContribute=true'),
        this.props.getAgentBankAccounts(agentId, 'contributions'),
        this.props.getEnvironmentVariables(Object.keys(contributionVariables).length !== 0 && ['ValidOneTimeContributionDates', 'CurrentTradeDate']),
      ];

      return Promise.all(apiCalls)
        .then(() => {
          this.props.getContributionDetails(accountId);
          this.props.history.push(`${baseURL}/contributions/new`);
        })
        .catch(() => null);
    });
  }

  optionChangePrepare(accountId, groupId, baseURL) {
    this.transactionPreviewWrapper(TRANSACTION_TYPES.OPTION_CHANGE, accountId, () => {
      const { accountList, customAgeBasedTemplates, customStaticTemplates, options } = this.props;
      
      const apiCalls = [
        this.props.getAccountTransactionsDetails(groupId, accountId),
        options.length === 0 && this.props.optionsGet(),
        (customAgeBasedTemplates.length === 0 || customStaticTemplates.length === 0) && this.props.customOptionsGet()
      ];

      if (accountList.length === 0) {
        return this.props.getAccounts()
          .then(() => {
            Promise.all(apiCalls).then(() => {
              this.props.getOptionChangeDetails(accountId);
              this.props.history.push(`${baseURL}/option-changes/new`);
            });
          })
          .catch(() => null);
      }
      else {
        return Promise.all(apiCalls)
          .then(() => {
            this.props.getOptionChangeDetails(accountId);
            this.props.history.push(`${baseURL}/option-changes/new`);
          })
          .catch(() => null);
      }
    });
  }

  withdrawalPrepare(accountId, groupId, agentId, baseURL) {
    this.transactionPreviewWrapper(TRANSACTION_TYPES.WITHDRAWAL, accountId,
      () => {
        const { accountList, withdrawalVariables: { sellDates } } = this.props;
        const apiCalls = [
          this.props.getAccountTransactionsDetails(groupId, accountId),
          this.props.getAgentBankAccounts(agentId, 'withdrawals'),
          this.props.getEnvironmentVariables(sellDates.length !== 0 && ['ValidOneTimeWithdrawalDates'])
        ];

        if (accountList.length === 0) {
          return this.props.getAccounts()
            .then(() => {
              Promise.all(apiCalls)
                .then(() => {
                  this.props.getWithdrawalDetails(groupId, accountId);
                  this.props.history.push(`${baseURL}/withdrawals/new`);
                })
                .catch(() => null);
            })
            .catch(() => null);
        }
        else {
          return Promise.all(apiCalls)
            .then(() => {
              this.props.getWithdrawalDetails(groupId, accountId);
              this.props.history.push(`${baseURL}/withdrawals/new`);
            })
            .catch(() => null);
        }
      });
  }

  transferPrepare(accountId, baseURL) {
    this.transactionPreviewWrapper(TRANSACTION_TYPES.TRANSFER, accountId, () => {
      return Promise.all([
        this.props.getAccountDetails(accountId),
        this.props.getTransferAccounts(accountId),
      ]).then(() => {
        this.props.history.push(`${baseURL}/transfers/new`);
      })
        .catch(() => null);
    });
  }

  render() {
    const baseRouteURL = '/agents/:agentId/groups/:groupId/accounts/:accountId';
    const { accountDetails, contributionDetails, optionChangeDetails, permissions, withdrawalDetails, previewDetails, webMessages } = this.props;
    const { showTransactionPrepModal, preparingTransaction } = this.state;

    return (
      <div>
        <Switch>
          <Route exact path='/accounts' render={(props) => (<AccountsList accountActionHandle={this.accountActionHandle} {...props} />)} />
          <Route
            exact
            path={baseRouteURL}
            render={props => <AccountDetails accountActionHandle={this.accountActionHandle} key={accountDetails.AccountId} {...props} />}
          />
          {permissions.contributions && ([
            <Route
              key='contributionsNew'
              path={`${baseRouteURL}/contributions/new`}
              render={props => (
                <>
                  <Banner show={Boolean(webMessages[BANNER_LOCATIONS.CONTRIBUTIONS_NEW])} body={webMessages[BANNER_LOCATIONS.CONTRIBUTIONS_NEW]} />
                  <Contributions key={`${contributionDetails.selectedAccountId}_NewContributions`} {...props} />
                </>
              )}
            />,
            <Route
              key='contributionsEdit'
              path={`${baseRouteURL}/contributions/:transactionId/edit`}
              render={props => (
                <>
                  <Banner show={Boolean(webMessages[BANNER_LOCATIONS.CONTRIBUTIONS_EDIT])} body={webMessages[BANNER_LOCATIONS.CONTRIBUTIONS_EDIT]} />
                  <Contributions key={`${contributionDetails.contribution.transactionId}_EditContributions`} {...props} />
                </>
              )}
            />
          ])}
          {permissions.optionChanges && ([
            <Route
              path={`${baseRouteURL}/option-changes/new`}
              render={props => (
                <>
                  <Banner show={Boolean(webMessages[BANNER_LOCATIONS.OPTION_CHANGES_NEW])} body={webMessages[BANNER_LOCATIONS.OPTION_CHANGES_NEW]} />
                  <OptionChanges key={`${optionChangeDetails.accountId}_New`} {...props} />
                </>
              )}
              key='optionChangesNew'
            />,
            <Route
              path={`${baseRouteURL}/option-changes/:transactionId/edit`}
              render={props => (
                <>
                  <Banner show={Boolean(webMessages[BANNER_LOCATIONS.OPTION_CHANGES_EDIT])} body={webMessages[BANNER_LOCATIONS.OPTION_CHANGES_EDIT]} />
                  <OptionChanges key={`${optionChangeDetails.accountId}_Edit`} {...props} />
                </>
              )}
              key='optionChangesEdit'
            />
          ])}
          <Route
            path={`${baseRouteURL}/option-changes/:transactionId/view`}
            render={props => (
              <>
                <Banner show={Boolean(webMessages[BANNER_LOCATIONS.OPTION_CHANGES_VIEW])} body={webMessages[BANNER_LOCATIONS.OPTION_CHANGES_VIEW]} />
                <OptionChanges key={`${optionChangeDetails.accountId}_View`} {...props} />
              </>
            )}
            key='optionChangesView'
          />
          {permissions.transfers && (
            <Route
              key='transfersNew'
              path={`${baseRouteURL}/transfers/new`}
              render={props => (
                <>
                  <Banner show={Boolean(webMessages[BANNER_LOCATIONS.TRANSFERS_NEW])} body={webMessages[BANNER_LOCATIONS.TRANSFERS_NEW]} />
                  <Transfers key={`${accountDetails.accountId}_View`} {...props} />
                </>
              )}
            />
          )}
          {permissions.transfers && (
            <Route
              key='transfersEdit'
              path={`${baseRouteURL}/transfers/:transferId/edit`}
              render={props => (
                <>
                  <Banner show={Boolean(webMessages[BANNER_LOCATIONS.TRANSFERS_EDIT])} body={webMessages[BANNER_LOCATIONS.TRANSFERS_EDIT]} />
                  <Transfers key={`${accountDetails.accountId}_Edit`} {...props} />
                </>
              )}
            />
          )}
          {permissions.withdrawals && (
            <Route
              path={`${baseRouteURL}/withdrawals/new`}
              render={props => (
                <>
                  <Banner show={Boolean(webMessages[BANNER_LOCATIONS.WITHDRAWALS_NEW])} body={webMessages[BANNER_LOCATIONS.WITHDRAWALS_NEW]} />
                  <Withdrawals key={`${withdrawalDetails.accountId}_Withdrawals`} {...props} />
                </>
              )}
            />
          )}
          <Route path={`${baseRouteURL}/documents`} component={Documents} />
          <Route path={`${baseRouteURL}/gifting`} component={Gifting} />
          {!bowser.mobile && !bowser.tablet && <Route path={`${baseRouteURL}/investment-path`} component={AccountInvestmentPath} />}
          <Route component={PageNotFound} />
        </Switch>

        <Modal
          show={showTransactionPrepModal}
          title=''
          onCloseModal={() => this.setState({ showTransactionPrepModal: false })}
          actionButtons={[
            {
              label: 'Cancel',
              action: () => this.setState({ showTransactionPrepModal: false }),
              disabled: preparingTransaction,
            },
          ]}
          fullScreen={false}
        >
          <div className={styles.Accounts_preparingModal}>
            {preparingTransaction && [
              <div
                key='message'
                className={styles.Accounts_preparingMessage}
              >
                Preparing Transaction...
              </div>,
              <LoadingOverlay
                key='indicator'
                show={true}
                width='100%'
                indicatorHeight='10px'
              />
            ]}
            {!preparingTransaction && !previewDetails.canContinue && previewDetails.errors.length > 0 && (
              <div>
                <div style={{ marginBottom: '10px' }}><strong>{this.state.transactionTitle}</strong>{this.state.transactionTitleSuffix}</div>
                {previewDetails.errors.map((error, index) => (
                  <div
                    key={index}
                    className={styles.Accounts_preparingError}
                  >
                    <Icon>close</Icon>
                    <span>{error}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(connect(select, {
  getAccountTransactionsDetails,
  getOptionChangeDetails,
  transactionPreview,
  optionsGet,
  customOptionsGet,
  getAccounts,
  getAccountsByAgent,
  getAgentBankAccounts,
  getContributionDetails,
  getEnvironmentVariables,
  getWithdrawalDetails,
  getAccountDetails,
  getTransferAccounts,
})(Accounts));
