/*
*
* NewAccountReview Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import {
  cloneDeep,
  findIndex,
  isEqual,
} from 'lodash';
import dayjs from 'dayjs';

import {
  newAccountDetailsGet,
  newAccountUpdate,
  newAccountsListGet,
  newAccountApprove,
  newAccountDownload,
  newAccountReset,
  newAccountDelete,
} from '../actions';
import { statuses } from '../constants';
import bowser from 'bowser';
import { saveFile } from 'utils/helpers/files';

import ConflictErrorResolveModal from './ConflictErrorResolveModal';
import StaticErrorResolveModal from './StaticErrorResolveModal';
import NewAccountForm from '../NewAccountForm';
import {
  Breadcrumbs,
  LoadingOverlay,
  FloatingActionButton,
  Modal,
  ConfirmModal,
  notificationShow,
  allNotificationsHide,
} from '@frontend/common';
import AccountStatus from '../AccountStatus';

import styles from './styles.module.css';

/* eslint-disable indent */
export const select = (state, { match }) => {
  const cachedAccount = state.newAccounts.accounts[match.params.id];
  const parseDate = date => !date || (date instanceof Date) || dayjs.isDayjs(date) ? date : dayjs(date, 'YYYY-MM-DD').toDate();

  return {
    accountDetails: cachedAccount
      ? {
        ...cachedAccount,
        BeneficiaryBirthdate: parseDate(cachedAccount.BeneficiaryBirthdate),
        OwnerBirthdate: parseDate(cachedAccount.OwnerBirthdate),
        SuccessorBirthdate: parseDate(cachedAccount.SuccessorBirthdate),
        SSuccessorBirthDate: parseDate(cachedAccount.SSuccessorBirthDate),
      }
      : {},
    formErrors: state.newAccounts.formErrors,
    staticErrors: state.newAccounts.staticErrors,
    conflictErrors: state.newAccounts.conflictErrors,
  };
};
/* eslint-enable indent */


export class NewAccountReview extends React.Component {

  static propTypes = {
    accountDetails: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    newAccountDetailsGet: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    newAccountUpdate: PropTypes.func.isRequired,
    newAccountsListGet: PropTypes.func.isRequired,
    newAccountApprove: PropTypes.func.isRequired,
    newAccountDownload: PropTypes.func.isRequired,
    formErrors: PropTypes.array,
    staticErrors: PropTypes.array,
    conflictErrors: PropTypes.array,
    allNotificationsHide: PropTypes.func.isRequired,
    newAccountDelete: PropTypes.func.isRequired,
    newAccountReset: PropTypes.func.isRequired,
  };

  state = {
    newAccount: {},
    loading: false,
    useOwnerAddresses: false,
    showApproveConfirmModal: false,
    showDownloadedModal: false,
    approveApplicationLoading: false,
    showConflictErrorResolveModal: false,
    showStaticErrorResolveModal: false,
    formErrors: [],
    tabErrorCounts: {},
    showDeleteModal: false,
    deleteApplicationLoading: false,
    showResetModal: false,
    resetApplicationLoading: false,
  };

  approveHandle = (reloadAccount) => {
    this.setState({
      approveApplicationLoading: true,
      showConflictErrorResolveModal: false,
      showStaticErrorResolveModal: false,
    }, () => {
      window.scrollTo(0, 0);
    });

    if (reloadAccount) {
      this.setState({ loading: true });
      this.props.newAccountDetailsGet(this.props.match.params.id)
        .catch(() => null);
    }
    this.props.newAccountApprove(this.props.match.params.id)
      .then(() => {
        this.setState({
          approveApplicationLoading: false,
          loading: false,
          showApproveConfirmModal: false,
        });
        const { formErrors, staticErrors, conflictErrors, notificationShow } = this.props;

        if (formErrors.length > 0) {
          this.setState({
            editing: true,
            formErrors: cloneDeep(formErrors),
            tabErrorCounts: this.tabErrorsTotal(formErrors),
          });
          notificationShow(`There ${formErrors.length > 1 ? `are ${formErrors.length} issues` : 'is 1 issue'} with the new account data entered. Please review all the form inputs.`, 'error');
        }
        else if (staticErrors.length > 0) {
          this.setState({ showStaticErrorResolveModal: true });
        }
        else if (conflictErrors.length > 0) {
          this.setState({ showConflictErrorResolveModal: true });
        }
        else {
          notificationShow('Account approved.', 'success');
          this.props.newAccountsListGet()
            .catch(() => null);
          this.props.history.push('/new-accounts');
        }
      })
      .catch(() => this.setState({ loading: false, approveApplicationLoading: false }));
  }

  downloadHandle = () => {
    this.props.newAccountDownload(this.props.match.params.id)
      .then(action => {
        const downloadedData = action.payload.data;
        if (!downloadedData) {
          this.props.notificationShow('', 'genericError');
        }
        else {
          const fileBlob = new Blob([downloadedData], { type: 'application/zip' });
          const benFirstName = this.state.newAccount.BeneficiaryFirstName ? this.state.newAccount.BeneficiaryFirstName : '';
          const benLastName = this.state.newAccount.BeneficiaryLastName ? this.state.newAccount.BeneficiaryLastName : '';
          const fileName = `FormsFor${benFirstName + benLastName}.zip`;

          if (bowser.name === 'Internet Explorer' || bowser.name === 'Microsoft Edge') {
            navigator.msSaveBlob(fileBlob, fileName);
          }
          else {
            saveFile(fileBlob, fileName);
          }
          this.setState({
            showDownloadedModal: true,
          });
        }
      })
      .catch(() => null);
  }

  deleteHandle = () => {
    this.setState({ deleteApplicationLoading: true });
    this.props.newAccountDelete(this.props.match.params.id)
      .then(() => {
        this.props.newAccountsListGet()
          .catch(() => null);
        this.props.notificationShow('Account deleted.', 'success');
        this.props.history.push('/new-accounts');
      })
      .catch(() => this.setState({ deleteApplicationLoading: false }));
  }

  copyOwnerAddresses(account) {
    const updatedAccount = cloneDeep(account);

    updatedAccount.BeneficiaryMailingAddress1 = updatedAccount.OwnerMailingAddress1;
    updatedAccount.BeneficiaryMailingAddress2 = updatedAccount.OwnerMailingAddress2;
    updatedAccount.BeneficiaryMailingCity = updatedAccount.OwnerMailingCity;
    updatedAccount.BeneficiaryMailingState = updatedAccount.OwnerMailingState;
    updatedAccount.BeneficiaryMailingZipCode = updatedAccount.OwnerMailingZipCode;
    updatedAccount.BeneficiaryPhysicalAddress1 = updatedAccount.OwnerPhysicalAddress1;
    updatedAccount.BeneficiaryPhysicalAddress2 = updatedAccount.OwnerPhysicalAddress2;
    updatedAccount.BeneficiaryPhysicalCity = updatedAccount.OwnerPhysicalCity;
    updatedAccount.BeneficiaryPhysicalState = updatedAccount.OwnerPhysicalState;
    updatedAccount.BeneficiaryPhysicalZipCode = updatedAccount.OwnerPhysicalZipCode;
    updatedAccount.BeneficiaryIsResident = updatedAccount.OwnerIsResident;

    return updatedAccount;
  }

  fabCompose() {
    let fabOptions = [];
    const status = this.state.newAccount.Status;

    if (status === statuses.LOADED) {
      if (!isEqual(this.props.accountDetails, this.state.newAccount)) {
        fabOptions = [
          {
            title: 'Save & Approve',
            icon: 'check',
            action: () => this.saveNewAccountChanges(true),
          },
          {
            title: 'Save Changes',
            icon: 'save',
            action: () => this.saveNewAccountChanges(false),
          },
          {
            title: 'Revert Changes',
            icon: 'undo',
            action: this.revertChanges,
          },
        ];
      }
      else {
        fabOptions = [
          {
            title: 'Approve',
            icon: 'check',
            action: () => this.setState({ showApproveConfirmModal: true }),
          },
          {
            title: 'Download Application',
            icon: 'download',
            action: () => this.downloadHandle(),
          },
        ];
      }

      fabOptions.push({
        title: 'Delete',
        icon: 'delete',
        action: () => this.setState({ showDeleteModal: true }),
      });
    }
    else if (status !== statuses.LOADED && status !== statuses.CLIENT_APPROVED) {
      fabOptions = [{
        title: 'Reset',
        icon: 'restore',
        action: () => this.setState({ showResetModal: true }),
      }];
    }

    if (fabOptions.length > 0) {
      const hasMultipleOptions = fabOptions.length > 1;
      return (
        <FloatingActionButton
          hasMenu={hasMultipleOptions}
          mainIcon={hasMultipleOptions ? '' : fabOptions[0].icon}
          mainButtonTitle={hasMultipleOptions ? '' : fabOptions[0].title}
          mainButtonAction={hasMultipleOptions ? undefined : fabOptions[0].action}
          menuOptions={hasMultipleOptions ? fabOptions : undefined}
        />
      );
    }

    return null;
  }

  resetAccountHandle = () => {
    this.setState({ resetApplicationLoading: true });
    this.props.newAccountReset(this.props.match.params.id)
      .then(() => {
        this.props.notificationShow('Account reset.', 'success');
        this.props.newAccountsListGet()
          .catch(() => null);
        this.props.history.push('/new-accounts');
      })
      .catch(() => this.setState({ resetApplicationLoading: false }));
  }

  revertChanges = () => {
    this.setState({
      newAccount: cloneDeep(this.props.accountDetails),
      useOwnerAddresses: false,
      formErrors: [],
      tabErrorCounts: this.tabErrorsTotal([]),
    });
    this.props.allNotificationsHide();
  }

  saveNewAccountChanges = (tryApproving) => {
    window.scrollTo(0, 0);
    this.props.allNotificationsHide();
    this.setState({ loading: true });
    let account = this.state.newAccount;
    if (this.state.useOwnerAddresses) {
      account = this.copyOwnerAddresses(this.state.newAccount);
    }
    this.props.newAccountUpdate(this.props.match.params.id, account)
      .then(() => {
        this.props.notificationShow('Account updated.', 'success');
        this.setState({
          loading: false,
          editing: false,
          showApproveConfirmModal: tryApproving,
          formErrors: [],
          tabErrorCounts: this.tabErrorsTotal([]),
        });
      })
      .catch(() => this.setState({ loading: false }));
  }

  setReloadBlock = (propsAccount) => {
    window.onbeforeunload = () => {
      if (!isEqual(propsAccount, this.state.newAccount) && propsAccount.Status === statuses.LOADED) {
        return 'Are you sure you want to reload page? All unsaved changes will be lost.';
      }
    };
  }

  tabErrorsTotal = (formErrors) => {
    const tabErrorCounts = {
      aoMailing: 0,
      aoPhysical: 0,
      beneMailing: 0,
      benePhysical: 0,
      successor: 0,
      ssuccessor: 0,
    };

    formErrors.forEach(error => {
      if (error.input.includes('OwnerMailing')) {
        tabErrorCounts.aoMailing++;
      }
      else if (error.input.includes('OwnerPhysical')) {
        tabErrorCounts.aoPhysical++;
      }
      else if (error.input.includes('BeneficiaryMailing')) {
        tabErrorCounts.beneMailing++;
      }
      else if (error.input.includes('BeneficiaryPhysical')) {
        tabErrorCounts.benePhysical++;
      }
      else if (error.input.includes('Successor')) {
        if (error.input.includes('SSuccessor')) {
          tabErrorCounts.ssuccessor++;
        }
        else {
          tabErrorCounts.successor++;
        }
      }
    });

    return tabErrorCounts;
  }

  updateNewAccount = (input, newValue) => {
    const updatedNewAccount = cloneDeep(this.state.newAccount);
    const updatedFormErrors = cloneDeep(this.state.formErrors);
    const errorIndex = findIndex(updatedFormErrors, { input });

    updatedNewAccount[input] = newValue;

    if (errorIndex > -1) {
      updatedFormErrors.splice(errorIndex, 1);
    }
    if (input === 'OptionName') {
      updatedNewAccount.TemplateName = '';
    }
    if (input === 'OwnerEmail') {
      updatedNewAccount.OwnerEmail = newValue.trim();
    }

    this.setState({
      newAccount: updatedNewAccount,
      formErrors: updatedFormErrors,
      tabErrorCounts: this.tabErrorsTotal(updatedFormErrors),
    });
  }

  componentDidMount() {
    if (Object.keys(this.props.accountDetails).length === 0) {
      this.setState({ loading: true });
      this.props.newAccountsListGet()
        .then(({ payload }) => {
          if (!payload.data.find(account => account.PoaAccountApplicationId === this.props.match.params.id)) {
            this.props.history.push('/new-accounts');
          }
          else {
            const { accountDetails } = this.props;
            this.setState({
              newAccount: cloneDeep(accountDetails),
              loading: false,
            }, () => this.setReloadBlock(accountDetails));
          }
        })
        .catch(() => this.setState({ loading: false }));
    }
    else {
      const { accountDetails } = this.props;
      this.setState({ newAccount: cloneDeep(accountDetails) }, () => this.setReloadBlock(accountDetails));

      this.props.newAccountDetailsGet(this.props.match.params.id)
        .catch(() => null);
    }
  }

  componentDidUpdate(prevProps) {
    const { accountDetails } = this.props;
    if (!isEqual(accountDetails, prevProps.accountDetails)) {
      this.setState({ newAccount: cloneDeep(accountDetails) }, () => this.setReloadBlock(accountDetails));
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = () => null;
  }

  /* eslint-disable indent */
  render() {
    const { loading, newAccount: { Status: status }, newAccount } = this.state;

    return (
      <div className={styles.NewAccountReview_page}>
        <div className={styles.NewAccountReview_content}>
          {loading && (
            <LoadingOverlay
              show={loading}
              width='100%'
              height='25px'
              indicatorHeight='10px'
            />
          )}
          <div className={styles.NewAccountReview_navigation}>
            <Breadcrumbs
              crumbs={[
                {
                  title: 'New Accounts',
                  link: '/new-accounts'
                },
                { title: 'Review' },
              ]}
            />
            <AccountStatus status={status || ''} />
          </div>
          <NewAccountForm
            account={newAccount}
            disableInputs={loading || status !== statuses.LOADED}
            updateAccount={this.updateNewAccount}
            formErrors={this.state.formErrors}
            tabErrorCounts={this.state.tabErrorCounts}
            toggleUseOwnerAddresses={(useOwnerAddresses) => { // updates bene address now, so it can be properly updated and saved
              const updatedAccount = useOwnerAddresses ? this.copyOwnerAddresses(newAccount) : newAccount;
              this.setState({ newAccount: updatedAccount, useOwnerAddresses });
            }}
          />
        </div>

        {!loading && status && this.fabCompose()}

        <ConfirmModal
          title='Approve Application'
          body={<p>By clicking <strong>Confirm</strong>, you certify that the information entered for your client&#39;s account application is accurate and complete. The account agreement will be sent via email to your client for review and approval.</p>}
          show={this.state.showApproveConfirmModal}
          onModalClose={() => this.setState({ showApproveConfirmModal: false })}
          isLoading={this.state.approveApplicationLoading}
          onConfirm={() => this.approveHandle(false)}
        />

        <ConflictErrorResolveModal
          show={this.state.showConflictErrorResolveModal}
          onCloseModal={() => this.setState({ showConflictErrorResolveModal: false })}
          conflictErrors={this.props.conflictErrors}
          approveAccount={() => this.approveHandle(true)}
        />

        <StaticErrorResolveModal
          show={this.state.showStaticErrorResolveModal}
          onCloseModal={() => this.setState({ showStaticErrorResolveModal: false })}
          staticErrors={this.props.staticErrors}
          approveAccount={() => this.approveHandle(true)}
        />

        <Prompt
          when={!isEqual(this.props.accountDetails, newAccount)}
          message='There are unsaved changes for this new account. Are you sure you want to leave?'
        />

        <ConfirmModal
          title='Delete Application'
          show={this.state.showDeleteModal}
          onModalClose={() => this.setState({ showDeleteModal: false })}
          onConfirm={this.deleteHandle}
          body={<p>Are you sure? The account application will be permanently deleted.</p>}
          isLoading={this.state.deleteApplicationLoading}
        />

        <ConfirmModal
          title='Reset Application'
          show={this.state.showResetModal}
          onModalClose={() => this.setState({ showResetModal: false })}
          onConfirm={this.resetAccountHandle}
          body={<p>Are you sure? Resetting will cancel any email links sent to the client and return the account application to a &#34;Loaded&#34; state.</p>}
          isLoading={this.state.resetApplicationLoading}
        />

        <Modal
          title='Application Successfully Downloaded'
          show={this.state.showDownloadedModal}
          actionButtons={[
            {
              label: 'Ok',
              action: () => this.setState({ showDownloadedModal: false }),
            },
          ]}
          onCloseModal={() => this.setState({ showDownloadedModal: false })}
        >
          <p>Your account application has been downloaded. Please note that some fields of the downloaded application will need to be filled out before submission. You will be required to select the investment option desired for the account again. If you are using a customized investment template, you will need to print that out separately.</p>
        </Modal>
      </div>
    );
  }
  /* eslint-enable indent */
}

export default withRouter(connect(select, {
  newAccountDetailsGet,
  newAccountUpdate,
  notificationShow,
  newAccountsListGet,
  newAccountApprove,
  newAccountDownload,
  allNotificationsHide,
  newAccountReset,
  newAccountDelete,
})(NewAccountReview));
