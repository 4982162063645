/*
*
* ManageUsers Actions
*
*/
import {
  USERS_LIST_GET,
  USER_DETAILS_GET,
  USER_UPDATE,
  POA_ACCOUNTS_GET,
  USER_CREATE,
  PRIMARY_CONTACTS_ASSIGN,
  DEACTIVATE_USER,
  REACTIVATE_USER,
  ACTIVATION_EMAIL_RESEND,
} from './constants';
import {
  GETrequest,
  PUTrequest,
  POSTrequest,
} from 'utils/helpers/api_handler';

export function usersListGet() {
  const response = GETrequest('/administration/authorizedrepresentative/advisors');

  return {
    type: USERS_LIST_GET,
    payload: response,
  };
}

export function userDetailsGet(id) {
  const response = GETrequest(`/administration/financialadvisor/${id}`);

  return {
    type: USER_DETAILS_GET,
    payload: response,
  };
}

export function userUpdate(id, userObject) {
  const response = PUTrequest(`/administration/financialadvisor/${id}`, userObject);

  return {
    type: USER_UPDATE,
    payload: response,
  };
}

export function poaAccountsGet() {
  const response = GETrequest('/administration/authorizedrepresentative/powerofattorney');

  return {
    type: POA_ACCOUNTS_GET,
    payload: response,
  };
}

export function userCreate(user) {
  const response = POSTrequest('/administration/financialadvisor', user);

  return {
    type: USER_CREATE,
    payload: response,
  };
}

export function primaryContactsAssign(advisorId, poaIds) {
  const payload = PUTrequest(`/administration/financialadvisor/${advisorId}/primarycontacts`, poaIds);

  return {
    type: PRIMARY_CONTACTS_ASSIGN,
    payload,
  };
}

export function deactivateUser(advisorId) {
  const payload = PUTrequest(`/administration/financialadvisor/${advisorId}/deactivate`);

  return {
    type: DEACTIVATE_USER,
    payload,
    meta: { advisorId },
  };
}

export function reactivateUser(advisorId) {
  const payload = PUTrequest(`/administration/financialadvisor/${advisorId}/reactivate`);

  return {
    type: REACTIVATE_USER,
    payload,
    meta: { advisorId },
  };
}

export function activationEmailResend(advisorId) {
  const payload = POSTrequest(`/administration/financialadvisor/resend/${advisorId}`);

  return {
    type: ACTIVATION_EMAIL_RESEND,
    payload,
  };
}
