/*
*
* Email Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  TextField,
  Button,
} from '@mui/material';

import {
  LoadingOverlay,
  notificationShow,
} from '@frontend/common';

import { emailValidator } from 'utils/helpers/form_validation';

import { contactInfoUpdate } from '../actions';

import styles from '../styles.module.css';

const select = (state) => ({
  advisor: state.myInfo.advisor,
});

export class Email extends React.Component {
  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    advisor: PropTypes.object.isRequired,
    contactInfoUpdate: PropTypes.func.isRequired,
  };

  state = {
    newEmail: '',
    confirmEmail: '',
    newEmailError: '',
    confirmEmailError: '',
    submitting: false,
  }

  onEmailChange = (keyName, value) => {
    // also reset username errors
    this.setState({
      [keyName]: value,
      newEmailError: '',
      confirmEmailError: '',
    });
  }

  disableSubmitButton = () => {
    return !this.state.newEmail || !this.state.confirmEmail;
  }

  emailInputValidate = () => {
    const { advisor: { EmailAddress } } = this.props;
    const { newEmail, confirmEmail } = this.state;

    let newEmailError = '';
    let confirmEmailError = '';

    newEmailError = emailValidator(newEmail);

    confirmEmailError = emailValidator(confirmEmail);

    if (EmailAddress.toLowerCase() === newEmail.toLowerCase()) {
      newEmailError = 'Email address is same as current.';
    }

    if (newEmail.toLowerCase() !== confirmEmail.toLowerCase()) {
      confirmEmailError = 'Emails must match.';
    }

    this.setState({ newEmailError, confirmEmailError });
    const hasError = Boolean(newEmailError) || Boolean(confirmEmailError);

    return !hasError;
  }

  onEmailSubmit = (e) => {
    e.preventDefault();
    if (this.emailInputValidate()) {
      const { newEmail } = this.state;

      const updatedAdvisor = {
        FinancialAdvisorId: this.props.advisor.FinancialAdvisorId,
        EmailAddress: newEmail
      };
      this.setState({ submitting: true });
      this.props.contactInfoUpdate(updatedAdvisor)
        .then(() => {
          this.props.notificationShow('Advisor email updated.', 'success');
        })
        .finally(() => this.setState({ submitting: false }));
    }
  }

  render() {
    const { advisor: { EmailAddress } } = this.props;
    const {
      newEmail, confirmEmail, submitting,
      newEmailError, confirmEmailError,
    } = this.state;

    return (
      <form className={styles.myInfo_right_container} onSubmit={this.onEmailSubmit}>

        <div className={styles.myInfo_textInput}>
          <TextField
            label='Current Email'
            name='currentEmailAddress'
            value={EmailAddress}
            disabled={true}
            fullWidth
          />
        </div>

        <div className={styles.myInfo_textInput}>
          <TextField
            autoComplete='new-password' // the only way to disable autocomplete
            label='New Email'
            name='newEmailAddress'
            value={newEmail}
            onChange={(e) => this.onEmailChange('newEmail', e.target.value)}
            error={Boolean(newEmailError)}
            helperText={newEmailError}
            disabled={submitting}
            fullWidth
          />
        </div>

        <div className={styles.myInfo_textInput}>
          <TextField
            autoComplete='new-password' // the only way to disable autocomplete
            label='Confirm Email'
            name='confirmEmailAddress'
            value={confirmEmail}
            onChange={(e) => this.onEmailChange('confirmEmail', e.target.value)}
            error={Boolean(confirmEmailError)}
            helperText={confirmEmailError}
            disabled={submitting}
            fullWidth
          />
        </div>

        <div className={styles.myInfo_button}>
          <LoadingOverlay show={submitting}>
            <Button
              type='submit'
              variant='contained'
              disabled={this.disableSubmitButton()}
            >
              Change Email
            </Button>
          </LoadingOverlay>

        </div>

      </form>
    );
  }
}

export default connect(select, {
  contactInfoUpdate,
  notificationShow
})(Email);
