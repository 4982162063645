/*
*
* ExternalProvidersCard Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep, findIndex, isEqual } from 'lodash';
import ReactDOMServer from 'react-dom/server';

// atob/btoa methods were used because of Checkmarx high severity issue

import {
  Card,
  LoadingOverlay,
  sizify,
  notificationShow,
  Modal,
} from '@frontend/common';
import {
  Button,
  Stepper,
  Step,
  StepButton,
  Collapse,
} from '@mui/material';
import ProviderTile from '../ProviderTile';
import BackOfficeAuthorization from '../BackOfficeAuthorization';

import { backOfficesUpdate } from '../../actions';

import styles from './styles.module.css';

const select = (state) => ({
  lpoaDetails: state.session.lpoaDetails,
});


export class ExternalProvidersCard extends React.Component {

  static propTypes = {
    lpoaDetails: PropTypes.object.isRequired,
    backOffices: PropTypes.array.isRequired,
    backOfficesUpdate: PropTypes.func.isRequired,
    size: PropTypes.object.isRequired,
    notificationShow: PropTypes.func.isRequired,
    afterSave: PropTypes.func,
  };

  state = {
    editing: false,
    backOffices: [],
    editingStep: 0,
    loading: false,
    showAuthorizationModal: false,
    authorizationModalProvider: {
      name: '',
      date: null,
    },
  };

  authorizationStepCompose() {
    const enabledBackOffices = this.enabledBackOfficesGet();
    const { firmName } = this.props.lpoaDetails;
    if (enabledBackOffices.length === 0) {
      return (
        <div id={styles.ExternalProvidersCard_removeAllProviders}>By unselecting all providers, my529 account data will no longer be provided to any external service providers. Click &quot;Save&quot; to remove all providers.</div>
      );
    }

    return enabledBackOffices.map(office => (
      <BackOfficeAuthorization
        key={office.id}
        firmName={firmName}
        address={atob(this.firmAddressCompose())}
        providerName={office.name}
      />
    ));
  }

  buttonsCompose() {
    const { editing, editingStep, loading, backOffices } = this.state;
    const scrollToTop = (callback) => {
      callback();
      window.scrollTo(0, 0);
    };
    const buttons = [];

    if (!editing) {
      buttons.push(
        <Button
          variant='contained'
          key='EditButton'
          onClick={() => scrollToTop(() => this.setState({ editing: true }))}
        >
          Edit
        </Button>
      );
    }
    else {
      if (editingStep === 0) {
        buttons.push(
          <Button
            variant='contained'
            key='NextButton'
            onClick={() => scrollToTop(() => this.setState({ editingStep: 1 }))}
            disabled={isEqual(backOffices, this.props.backOffices)}
          >
            Next
          </Button>
        );
      }
      else if (editingStep === 1) {
        buttons.push(
          <LoadingOverlay
            key='SaveButton'
            show={loading}
            indicatorHeight='5px'
          >
            <Button
              variant='contained'
              onClick={this.updateBackOfficesHandle}
              disabled={loading}
            >
              {this.enabledBackOfficesGet().length > 0 ? 'Agree & Save' : 'Save'}
            </Button>
          </LoadingOverlay>
        );
      }

      buttons.unshift(
        <Button
          key='CancelButton'
          variant='text'
          onClick={() => scrollToTop(() => this.setState({ editing: false, backOffices: this.props.backOffices, editingStep: 0 }))}
          disabled={loading}
        >
          Cancel
        </Button>
      );
    }

    return buttons;
  }

  enabledBackOfficesGet = () => {
    return this.state.backOffices.filter(office => office.enabled);
  }

  firmAddressCompose = () => {
    const { address: { street1, street2, street3, city, state, zip } } = this.props.lpoaDetails;
    return btoa(`${street1}${street2 ? `, ${street2}` : ''}${street3 ? `, ${street3}` : ''}, ${city}, ${state} ${zip}`);
  }

  onTileClick = (backOffice) => {
    const updatedBackOffices = cloneDeep(this.state.backOffices);
    const index = findIndex(updatedBackOffices, { id: backOffice.id });
    if (index > -1) {
      updatedBackOffices[index].enabled = !updatedBackOffices[index].enabled;
    }

    this.setState({
      backOffices: updatedBackOffices,
    });
  }

  printAuthorization = () => {
    const { lpoaDetails: { firmName } } = this.props;
    const { authorizationModalProvider: { name, date } } = this.state;

    const printWindow = window.open();
    printWindow.document.write(
      ReactDOMServer.renderToStaticMarkup(
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <style type='text/css'>
            {`
              .styles_BackOfficeAuthorization_container__1YYeR {
                max-width: 700px;
                margin-bottom: 50px;
              }
              
              .styles_BackOfficeAuthorization_container__1YYeR > p {
                line-height: 135%;
              }

              .styles_BackOfficeAuthorization_title__1rXvs {
                font-weight: bold;
                font-size: 1.1em;
                text-align: center;
              }

              .styles_BackOfficeAuthorization_emphasize__3WAd4,
              .styles_BackOfficeAuthorization_firmName__3XKd7,
              .styles_BackOfficeAuthorization_date__RzsAM {
                font-weight: bold;
              }

              .styles_BackOfficeAuthorization_firmName__3XKd7,
              .styles_BackOfficeAuthorization_date__RzsAM {
                margin-left: 20px;
              }

              .styles_BackOfficeAuthorization_firmName__3XKd7 {
                font-size: 1.2em;
                margin-top: 5px;
                margin-bottom: 5px;
              }
            `}
          </style>
          <BackOfficeAuthorization
            firmName={firmName}
            address={atob(this.firmAddressCompose())}
            providerName={name}
            date={date}
          />
        </div>
      )
    );
    printWindow.print();
    printWindow.close();
  }

  updateBackOfficesHandle = () => {
    this.setState({ loading: true });
    const backOfficeIds = this.enabledBackOfficesGet().map(office => office.id);
    this.props.backOfficesUpdate(backOfficeIds)
      .then(() => {
        this.props.notificationShow('External Providers updated.', 'success');
        this.setState({
          loading: false,
          editing: false,
          editingStep: 0,
          backOffices: this.props.backOffices,
        }, () => {
          this.props.afterSave(this.enabledBackOfficesGet());
        });
      })
      .catch(() => this.setState({ loading: false }));
  }

  componentDidMount() {
    this.setState({
      backOffices: this.props.backOffices,
    });
  }

  render() {
    const { editing, backOffices, editingStep } = this.state;
    const { size: { windowWidth }, lpoaDetails: { firmName } } = this.props;

    return (
      <Card
        title='External Service Providers'
        className={styles.ExternalProvidersCard_providersCard}
      >
        <Collapse in={editing} unmountOnExit>
          <div className={styles.ExternalProvidersCard_stepper}>
            <Stepper
              activeStep={editingStep}
              orientation={windowWidth < 600 ? 'vertical' : 'horizontal'}
            >
              <Step>
                <StepButton onClick={() => this.setState({ editingStep: 0 })}>Select</StepButton>
              </Step>
              <Step>
                <StepButton onClick={() => this.setState({ editingStep: 1 })}>Authorize</StepButton>
              </Step>
            </Stepper>
          </div>
        </Collapse>

        {editingStep === 0 && (
          <div className={styles.ExternalProvidersCard_providers}>
            <div className={styles.ExternalProvidersCard_providersGrid}>
              {backOffices.map(firm => {
                return (
                  <ProviderTile
                    key={firm.id}
                    provider={firm}
                    editing={editing}
                    onClick={this.onTileClick}
                    type='backOffice'
                    onViewAuthorizationClick={provider => this.setState({ showAuthorizationModal: true, authorizationModalProvider: { name: provider.name, date: provider.authorizedDate } })}
                  />
                );
              })}
            </div>
          </div>
        )}

        {editingStep === 1 && this.authorizationStepCompose()}

        <div className={styles.ExternalProvidersCard_buttons}>
          {this.buttonsCompose()}
        </div>

        <Modal
          show={this.state.showAuthorizationModal}
          title={windowWidth > 600 ? 'Authorization Agreement' : 'Authorization'}
          onCloseModal={() => this.setState({ showAuthorizationModal: false, authorizationModalProvider: { name: '', date: null } })}
          maxWidth='md'
          actionButtons={[
            {
              label: 'Print',
              action: this.printAuthorization,
              buttonType: 'contained',
            },
            {
              label: 'Close',
              action: () => this.setState({ showAuthorizationModal: false, authorizationModalProvider: { name: '', date: null } })
            },
          ]}
        >
          <BackOfficeAuthorization
            firmName={firmName}
            address={atob(this.firmAddressCompose())}
            providerName={this.state.authorizationModalProvider.name}
            date={this.state.authorizationModalProvider.date}
          />
        </Modal>
      </Card>
    );
  }
}

export default connect(select, { backOfficesUpdate, notificationShow })(sizify(ExternalProvidersCard));
