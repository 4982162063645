/*
*
* BulkUploadModal Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { truncate } from 'lodash';

import {
  Button,
  Icon,
} from '@mui/material';

import {
  Modal,
  notificationShow,
  allNotificationsHide
} from '@frontend/common';
import { accountsUpload, newAccountsListGet } from 'components/Features/protected/NewAccounts/actions';

import styles from './styles.module.css';

const frontendConfig = window.frontendConfig;

export class BulkUploadModal extends React.Component {

  static propTypes = {
    show: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
    accountsUpload: PropTypes.func.isRequired,
    newAccountsListGet: PropTypes.func.isRequired,
  };

  state = {
    fileName: '',
    file: {},
    fileUploading: false,
  };

  fileUpload = () => {
    this.setState({ fileUploading: true });

    const formData = new FormData();
    formData.append('file', this.state.file);
    this.props.accountsUpload(formData)
      .then(() => {
        this.props.allNotificationsHide();
        this.props.notificationShow('File uploaded.', 'success');
        this.props.newAccountsListGet();
        this.modalCloseHandle();
        this.setState({ fileUploading: false });
      })
      .catch(() => this.setState({ fileUploading: false }));
  }

  onFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      this.setState({
        fileName: file.name,
        file,
      });
    }
  }

  modalCloseHandle = () => {
    this.props.onModalClose();
    document.getElementById('uploadInput').value = '';
    this.setState({
      fileName: '',
      file: {},
    });
  }

  spreadsheetDownload = () => {
    window.location = `${frontendConfig.api}/accounts/new-accounts/download`;
  }

  render() {
    const { fileUploading } = this.state;
    return (
      <Modal
        title='Bulk Upload'
        show={this.props.show}
        onCloseModal={() => this.modalCloseHandle(false)}
        actionButtons={[
          {
            label: 'Cancel',
            action: this.modalCloseHandle,
            disabled: fileUploading,
          },
          {
            label: 'Upload',
            action: this.fileUpload,
            disabled: !this.state.fileName,
            loading: fileUploading,
            buttonType: 'contained',
          },
        ]}
      >
        <div className={styles.BulkUploadModal_body}>
          <Button
            variant='text'
            disabled={fileUploading}
            onClick={this.spreadsheetDownload}
          >
            <Icon style={{ marginRight: '5px' }}>file_download</Icon>
            Download Spreadsheet
          </Button>
          <div className={styles.BulkUploadModal_chooseFileButton}>
            <Button
              variant='contained'
              type='file'
              onClick={() => document.getElementById('uploadInput').click()}
              disabled={fileUploading}
            >
              Choose File
            </Button>
            <input
              className={styles.BulkUploadModal_fileInput}
              type='file'
              id='uploadInput'
              onChange={this.onFileSelect}
              accept='.xls*'
            />
          </div>
          <div className={styles.BulkUploadModal_fileName}>{truncate(this.state.fileName, { length: 40 }) || 'No file selected.'}</div>
        </div>
      </Modal>
    );
  }
}

export default connect(null, {
  notificationShow,
  allNotificationsHide,
  accountsUpload,
  newAccountsListGet
})(BulkUploadModal);
