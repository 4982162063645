import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import {
  Button,
  TextField,
} from '@mui/material';

import {
  FormWrapper,
  LoadingOverlay,
  emailValidate,
  notificationShow,
} from '@frontend/common';

import { forgotUsername } from 'components/Features/public/ForgotUsername/actions';

import styles from './styles.module.css';


export class ForgotUsername extends Component {

  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    forgotUsername: PropTypes.func.isRequired,
    errorMsgs: PropTypes.array,
  };

  state = {
    loading: false,
    lpoaNum: '',
    email: '',
    errors: {},
  };

  formValidate = () => {
    let formIsValid = false;
    let errors = {};
    const lpoaNumIsValid = this.state.lpoaNum.match(/^[0-9]*$/);
    const emailIsValid = emailValidate(this.state.email);

    if (!this.state.lpoaNum) {
      errors.lpoaNum = 'LPOA Number is required.';
    }

    if (!this.state.email) {
      errors.email = 'Email is required.';
    }

    if (this.state.lpoaNum && !lpoaNumIsValid) {
      errors.lpoaNum = 'Can only contain numbers.';
    }

    if (this.state.email && !emailIsValid) {
      errors.email = 'Invalid email address.';
    }

    if (
      this.state.lpoaNum
      && lpoaNumIsValid
      && this.state.email
      && emailIsValid
    ) {
      formIsValid = true;
      errors = {};
    }

    this.setState({
      errors,
    });

    return formIsValid;
  }

  inputUpdate = (inputName, value) => {
    const clonedErrors = cloneDeep(this.state.errors);

    if (value.length > 0 && this.state.errors[inputName]) {
      clonedErrors[inputName] = '';
    }

    this.setState({
      [inputName]: value,
      errors: clonedErrors,
    });
  }

  onFormSubmit = (e) => {
    
    e.preventDefault();

    if (this.formValidate()) {
      document.getElementById('lpoaNumField').blur();
      document.getElementById('emailField').blur();

      const data = {
        email: this.state.email,
        LPOANumber: this.state.lpoaNum,
      };
      
      this.setState({ loading: true });
      this.props.forgotUsername(data)
        .then(() => {
          this.props.notificationShow('Request submitted. Check your email.', 'success');
          this.props.history.push('/login');
        })
        .catch(() => this.setState({ loading: false }));
    }
  }

  render() {
    return (
      <FormWrapper
        title='Forgot Username'
        instructions={'If the LPOA number and email are in the system, an email with your username will be sent to you. If you cannot remember your LPOA number or email, please contact your firm\'s master administrator.'}
      >
        <form
          className='formContainer'
          onSubmit={this.onFormSubmit}
        >
          <TextField
            id='lpoaNumField'
            value={this.state.lpoaNum}
            label='LPOA Number'
            fullWidth
            autoFocus
            disabled={this.state.loading}
            onChange={(e) => this.inputUpdate('lpoaNum', e.target.value)}
            error={Boolean(this.state.errors.lpoaNum)}
            helperText={this.state.errors.lpoaNum}
          />
          <TextField
            id='emailField'
            value={this.state.email}
            label='Email'
            fullWidth
            disabled={this.state.loading}
            onChange={(e) => this.inputUpdate('email', e.target.value)}
            error={Boolean(this.state.errors.email)}
            helperText={this.state.errors.email}
          />
          <div className={styles.ForgotUsername_buttons}>
            <Button
              variant='text'
              style={{ width: '48%' }}
              onClick={() => this.props.history.push('/login')}
              disabled={this.state.loading}
            >
              Cancel
            </Button>
            <LoadingOverlay show={this.state.loading} width='48%'>
              <Button
                type='submit'
                variant='contained'
                disabled={this.state.loading}
                fullWidth
              >
                Submit
              </Button>
            </LoadingOverlay>
          </div>
        </form>
      </FormWrapper>
    );
  }
}

export default withRouter(connect(null, {
  notificationShow,
  forgotUsername
})(ForgotUsername));
