/*
*
* Withdrawals Component
*
*/
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import AccountsInGroup from './AccountsInGroup';
import NewWithdrawals from './NewWithdrawals';
import WithdrawalDetails from './WithdrawalDetails';
import {
  getAccounts,
  getAccountTransactionsDetails,
  getAgentBankAccounts,
  getOptionChangeDetails,
  getWithdrawalDetails,
} from '../../actions';
import {
  Breadcrumbs,
  notificationShow
} from '@frontend/common';
import { getEnvironmentVariables } from 'components/AppRoot/StaticResources/actions';
import { getAccountBaseRoute } from 'components/Features/protected/Accounts/helpers';
import styles from './styles.module.css';

const select = (state) => ({
  accountList: state.accounts.accountList,
  withdrawalDetails: state.accounts.withdrawalDetails,
  withdrawalVariables: state.static.environmentVars.withdrawalVariables,
});

export class Withdrawals extends React.Component {

  static propTypes = {
    accountList: PropTypes.array.isRequired,
    getAccounts: PropTypes.func.isRequired,
    getAccountTransactionsDetails: PropTypes.func.isRequired,
    getAgentBankAccounts: PropTypes.func.isRequired,
    getEnvironmentVariables: PropTypes.func.isRequired,
    getWithdrawalDetails: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    withdrawalDetails: PropTypes.object.isRequired,
    withdrawalVariables: PropTypes.object.isRequired,
  };

  state = {
    accountsInGroupModalOpen: false,
    displayWarning: false,
    isProportional: false,
    loading: false,
  };

  warningDisplay = (date) => {
    const { tradeCutoff, tradeCutoffWarningTime } = this.props.withdrawalVariables;

    if (date) {
      if (dayjs().isSame(dayjs(date), 'day')) {
        date = dayjs();
      }
      else {
        date = dayjs(date);
      }
    }

    if (tradeCutoff) {
      const userTime = date ? date : dayjs();
      const warningTime = dayjs(tradeCutoff).subtract(tradeCutoffWarningTime, 'minute');

      if (
        dayjs(userTime).isSameOrAfter(warningTime) &&
        dayjs(userTime).isBefore(tradeCutoff)
      ) {
        this.setState({ displayWarning: true });
      }
      else {
        this.setState({ displayWarning: false });
      }
    }
  }

  withdrawalDetailsGet = () => {
    const { accountId, agentId, groupId } = this.props.match.params;
    const { accountList, withdrawalVariables: { sellDates } } = this.props;

    const apiCalls = [
      this.props.getAccountTransactionsDetails(groupId, accountId),
      this.props.getAgentBankAccounts(agentId, 'withdrawals'),
      this.props.getEnvironmentVariables(sellDates.length !== 0 && ['ValidOneTimeWithdrawalDates'])
    ];



    this.setState({ loading: true });

    if (accountList.length === 0) {
      this.props.getAccounts()
        .then(() => {
          return Promise.all(apiCalls)
            .then(() => this.props.getWithdrawalDetails(groupId, accountId))
            .catch(() => this.setState({ loading: false }));
        })
        .catch(() => this.setState({ loading: false }));
    }
    else {
      return Promise.all(apiCalls)
        .then(() => this.props.getWithdrawalDetails(groupId, accountId))
        .catch(() => this.setState({ loading: false }));
    }
  }

  componentDidMount() {
    const { withdrawalDetails: { accountId, groupAccounts }, match: { params } } = this.props;

    if (accountId !== parseInt(params.accountId)) {
      this.withdrawalDetailsGet();
    }
    if (groupAccounts.length > 1) {
      this.setState({ accountsInGroupModalOpen: true });
    }

    this.warningDisplay();
  }

  render() {
    const { displayWarning, isProportional, loading, accountsInGroupModalOpen } = this.state;
    const { match: { params }, withdrawalDetails, withdrawalVariables } = this.props;

    return (
      <div className={styles.Withdrawals_container}>
        <div className={`${styles.Withdrawals_pageNav} hideOnPrint`}>
          <Breadcrumbs
            crumbs={[
              {
                title: 'Accounts',
                link: '/accounts'
              },
              {
                title: 'Details',
                link: getAccountBaseRoute(params),
              },
              {
                title: 'New Withdrawal',
              },
            ]}
          />
        </div>

        {displayWarning ?
          <div className={styles.Withdrawals_tradeCutoffWarning}>
            {`The withdrawal request must be submitted by ${dayjs(withdrawalVariables.tradeCutoff).format('h:mm a')} MT to be eligible for today's trading session.`} {/* eslint-disable-line */}
          </div>
          :
          null
        }

        <div className={styles.Withdrawals_withdrawalDetails}>
          <WithdrawalDetails
            isProportional={isProportional}
            loading={loading}
            key={`${withdrawalDetails.accountId}_WithdrawalDetails`}
          />
        </div>

        <div className={styles.Withdrawals_newWithdrawals}>
          <NewWithdrawals
            isProportional={isProportional}
            loading={loading}
            key={`${withdrawalDetails.accountId}_NewWithdrawals_${isProportional}`}
            warningDisplay={this.warningDisplay}
          />
        </div>

        <AccountsInGroup
          key={`${withdrawalDetails.accountId}_AccountsInGroup`}
          loading={loading}
          open={accountsInGroupModalOpen}
          onClose={() => this.setState({ accountsInGroupModalOpen: false })}
          onProportionalSelect={() => this.setState({ accountsInGroupModalOpen: false, isProportional: true })}
          groupAccounts={withdrawalDetails.groupAccounts}
        />
      </div>
    );
  }
}

export default withRouter(connect(select, {
  getAccounts,
  getAccountTransactionsDetails,
  getAgentBankAccounts,
  getOptionChangeDetails,
  getEnvironmentVariables,
  getWithdrawalDetails,
  notificationShow
})(Withdrawals));
