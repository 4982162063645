/*
*
* AddSingleAccount Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep, isEqual } from 'lodash';
import { withRouter, Prompt } from 'react-router-dom';

import Banner from 'components/Features/Banner';
import { BANNER_LOCATIONS } from 'components/AppRoot/StaticResources/constants';

import {
  blankAccountGet,
  singleAccountCreate,
  newAccountsListGet,
} from '../actions';

import NewAccountForm from '../NewAccountForm';
import {
  Breadcrumbs,
  LoadingOverlay,
  FloatingActionButton,
  notificationShow,
} from '@frontend/common';

import styles from './styles.module.css';

const select = (state) => ({
  blankAccount: state.newAccounts.blankAccount,
  webMessages: state.static.webMessages,
});

export class AddSingleAccount extends React.Component {

  static propTypes = {
    webMessages: PropTypes.object.isRequired,
    blankAccount: PropTypes.object,
    notificationShow: PropTypes.func.isRequired,
    blankAccountGet: PropTypes.func.isRequired,
    singleAccountCreate: PropTypes.func.isRequired,
    newAccountsListGet: PropTypes.func.isRequired,
  };

  state = {
    useOwnerAddresses: false,
    account: {},
    loading: false,
  };

  createAccountHandle = () => {
    this.setState({ loading: true });
    let account = cloneDeep(this.state.account);

    if (this.state.useOwnerAddresses) {
      account = this.copyOwnerAddresses(account);
    }

    this.props.singleAccountCreate(account)
      .then(() => {
        this.props.notificationShow('New account added.', 'success');
        this.props.newAccountsListGet()
          .catch(() => null);
        this.props.history.push('/new-accounts');
      })
      .catch(() => this.setState({ loading: false }));
  }

  updateAccount = (input, value) => {
    const updatedAccount = cloneDeep(this.state.account);

    updatedAccount[input] = value;
    if (input === 'OptionName') {
      updatedAccount.TemplateName = '';
    }
    if (input === 'OwnerEmail') {
      updatedAccount.OwnerEmail = value.trim();
    }

    this.setState({
      account: updatedAccount,
    });
  }

  copyOwnerAddresses(account) {
    const updatedAccount = cloneDeep(account);

    updatedAccount.BeneficiaryMailingAddress1 = updatedAccount.OwnerMailingAddress1;
    updatedAccount.BeneficiaryMailingAddress2 = updatedAccount.OwnerMailingAddress2;
    updatedAccount.BeneficiaryMailingCity = updatedAccount.OwnerMailingCity;
    updatedAccount.BeneficiaryMailingState = updatedAccount.OwnerMailingState;
    updatedAccount.BeneficiaryMailingZipCode = updatedAccount.OwnerMailingZipCode;
    updatedAccount.BeneficiaryPhysicalAddress1 = updatedAccount.OwnerPhysicalAddress1;
    updatedAccount.BeneficiaryPhysicalAddress2 = updatedAccount.OwnerPhysicalAddress2;
    updatedAccount.BeneficiaryPhysicalCity = updatedAccount.OwnerPhysicalCity;
    updatedAccount.BeneficiaryPhysicalState = updatedAccount.OwnerPhysicalState;
    updatedAccount.BeneficiaryPhysicalZipCode = updatedAccount.OwnerPhysicalZipCode;
    updatedAccount.BeneficiaryIsResident = updatedAccount.OwnerIsResident;

    return updatedAccount;
  }

  componentDidMount() {
    if (Object.keys(this.props.blankAccount).length === 0) {
      this.setState({ loading: true });
      this.props.blankAccountGet()
        .then(() => {
          const blankAccount = cloneDeep(this.props.blankAccount);
          this.setState({
            account: blankAccount,
            loading: false,
          });

          window.onbeforeunload = () => {
            if (!isEqual(blankAccount, this.state.account)) {
              return 'Are you sure you want to reload page? All unsaved changes will be lost.';
            }
          };
        })
        .catch(() => this.setState({ loading: false }));
    }
    else {
      const blankAccount = cloneDeep(this.props.blankAccount);
      this.setState({ account: blankAccount });

      window.onbeforeunload = () => {
        if (!isEqual(blankAccount, this.state.account)) {
          return 'Are you sure you want to reload page? All unsaved changes will be lost.';
        }
      };
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = () => null;
  }

  /* eslint-disable indent */
  render() {
    const { blankAccount, webMessages } = this.props;
    const { account, loading } = this.state;

    return (
      <div className={styles.AddSingleAccount_page}>
        <div className={styles.AddSingleAccount_content}>
          <div className={styles.AddSingleAccount_navigation}>
          <Banner show={Boolean(webMessages[BANNER_LOCATIONS.NEW_ACCOUNTS_ADD])} body={webMessages[BANNER_LOCATIONS.NEW_ACCOUNTS_ADD]} />
            {loading
              ?
              <LoadingOverlay
                show={loading}
                indicatorHeight='10px'
                width='100%'
                height='20px'
              />
              :
              null
            }
            <Breadcrumbs
              crumbs={[
                {
                  title: 'New Accounts',
                  link: '/new-accounts'
                },
                { title: 'Add' },
              ]}
            />
          </div>
          <NewAccountForm
            account={account}
            updateAccount={this.updateAccount}
            disableInputs={loading}
            toggleUseOwnerAddresses={(useOwnerAddresses) => { // updates bene address now, so it can be properly updated and saved
              const updatedAccount = useOwnerAddresses ? this.copyOwnerAddresses(account) : account;
              this.setState({ account: updatedAccount, useOwnerAddresses });
            }}
          />
        </div>

        <FloatingActionButton
          hasMenu={false}
          mainIcon='save'
          mainButtonTitle='Save Account'
          mainButtonAction={this.createAccountHandle}
        />

        <Prompt
          when={!isEqual(blankAccount, account) && !loading}
          message='There are unsaved changes for this new account. Are you sure you want to leave?'
        />
      </div>
    );
  }
  /* eslint-enable indent */
}

export default withRouter(connect(select, {
  blankAccountGet,
  singleAccountCreate,
  newAccountsListGet,
  notificationShow,
})(AddSingleAccount));
