import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

import {
  Button,
} from '@mui/material';

import {
  FormWrapper,
  LoadingOverlay,
  PasswordInput,
  PasswordRequirements,
  notificationShow,
} from '@frontend/common';

import { resetPassword } from 'components/Features/public/ResetPassword/actions';
import { passwordRequirementsGet } from 'components/AppRoot/StaticResources/actions';

import styles from './styles.module.css';


const select = (state) => ({
  passwordRequirements: state.static.passwordRequirements,
});

export class ResetPassword extends Component {

  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    passwordRequirementsGet: PropTypes.func.isRequired,
    passwordRequirements: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
  };

  state = {
    loading: false,
    password: '',
    passwordConfirmation: '',
    formErrors: {},
    passwordPassesValidation: false,
  };

  formValidate() {
    let isValid = false;
    let formErrors = {};

    if (!this.state.password) {
      formErrors.password = 'Password is required.';
    }

    if (!this.state.passwordConfirmation) {
      formErrors.passwordConfirmation = 'Confirm Password is required.';
    }

    if (this.state.passwordConfirmation && this.state.password !== this.state.passwordConfirmation) {
      formErrors.passwordConfirmation = 'Passwords must match.';
    }
    if (this.state.password && !this.state.passwordPassesValidation) {
      formErrors.password = 'Password does not meet criteria below.';
    }
    /* eslint-disable indent */
    if (
      this.state.password
      && this.state.passwordConfirmation
      && this.state.passwordPassesValidation
      && this.state.password === this.state.passwordConfirmation) {
        formErrors = {};
        isValid = true;
    }
    /* eslint-enable indent */

    this.setState({
      formErrors,
    });

    return isValid;
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    if (this.formValidate()) {
      document.getElementById('password').blur();
      document.getElementById('passwordConfirm').blur();

      const postData = {
        id: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).request,
        password1: this.state.password,
        password2: this.state.passwordConfirmation,
      };

      this.setState({ loading: true });
      this.props.resetPassword(postData)
        .then(() => {
          this.props.history.push('/login');
          this.props.notificationShow('Password changed.', 'success');
        })
        .catch(() => this.setState({ loading: false }));
    }
  }

  onInputChange = (inputName, value) => {
    this.setState({
      [inputName]: value,
      formErrors: {
        ...this.state.formErrors,
        [inputName]: '',
      }
    });
  }

  componentDidMount() {
    if (this.props.passwordRequirements.length === 0) {
      this.setState({ loading: true });
      this.props.passwordRequirementsGet()
        .catch(() => null)
        .finally(() => this.setState({ loading: false }));
    }
  }

  render() {
    return (
      <FormWrapper title={'Reset Password'}>
        <LoadingOverlay
          show={this.state.loading}
          width='100%'
        >
          <form
            className='formContainer'
            onSubmit={this.onFormSubmit}
          >
            <PasswordInput
              id='password'
              onChange={ (e) => this.onInputChange('password', e.target.value) }
              label='New password'
              errorText={this.state.formErrors.password}
              fullWidth
              variant='standard'
              disabled={this.state.loading}
            />
            <PasswordInput
              id='passwordConfirm'
              onChange={ (e) => this.onInputChange('passwordConfirmation', e.target.value) }
              label='Confirm new password'
              errorText={this.state.formErrors.passwordConfirmation}
              fullWidth
              variant='standard'
              disabled={this.state.loading}
            />
            <div className={styles.ResetPassword_divPadding}>
              <PasswordRequirements
                passwordRequirements={this.props.passwordRequirements}
                password={this.state.password}
                onPasswordCheck={isValid => this.setState({ passwordPassesValidation: isValid })}
              />
            </div>
            <div className={styles.ResetPassword_buttons}>
              <Button
                style={{ width: '48%' }}
                onClick={() => this.props.history.push('/login')}
                disabled={this.state.loading}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                variant='contained'
                disabled={this.state.loading}
                style={{ width: '48%' }}
              >
                Submit
              </Button>
            </div>
          </form>
        </LoadingOverlay>
      </FormWrapper>
    );
  }
}

export default withRouter(connect(select, {
  resetPassword,
  passwordRequirementsGet,
  notificationShow,
})(ResetPassword));
