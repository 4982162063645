import {
  SEARCH,
  COMMON_PACKAGE,
} from '../constants';
import { urlScrub } from '../helpers';

export function tableSearchAccessed(tableName, url) {
  return {
    eventCategory: COMMON_PACKAGE,
    eventAction: SEARCH,
    eventLabel: `${tableName} - ${urlScrub(url)}`,
  };
}