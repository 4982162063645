/*
*
* TermsConditions Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

export class TermsConditions extends React.Component {
  static propTypes = {
    contributionType: PropTypes.string,
  };

  static defaultProps = {
    contributionType: 'non-special'
  };

  render() {
    /* eslint-disable quotes */
    return (
      <div className={styles.TermsConditions_container}>
        <h1 className={styles.TermsConditions_modalHeader}>Terms and Conditions</h1>
        <h2 className={styles.TermsConditions_modalSubheader}>Please review the following Terms and Conditions regarding contributions</h2>
        <h2 className={styles.TermsConditions_modalSubheader}>I have informed my client of the following:</h2>
        {this.props.contributionType === 'special' && (
          <div>
            <p>About Special Occasion Contributions:</p>
            <ul>
              <li>You may initiate annual contributions for birthdays, holidays, or other special occasions by entering a dollar amount and date (when applicable) the account owner wants the transaction to occur.</li>
              <li>Contributions will occur every year on the date selected (or the next business day if the date falls on a holiday or weekend).</li>
              <li>One week prior to the scheduled contribution day, my529 will send the account owner an email reminder of the upcoming contributions.</li>
            </ul>
          </div>
        )}
        <p>Warning: The following types of accounts cannot be used to fund online contributions. Requests to do so will be rejected and may incur reject fees and/or market losses:</p>
        <ul>
          <li>Brokerage and mutual fund accounts (such as Vanguard, Charles Schwab, etc.)</li>
          <li>Accounts drawn on credit unions that do not clear their own checks (they use third-party routing numbers)</li>
          <li>Savings accounts with restricted access privileges</li>
          <li>Bank accounts with UGMA/UTMA designations</li>
        </ul>
        <p>Although online contributions may not be made from the accounts listed above, checks drawn from those accounts may be submitted, as long as they are:</p>
        <ul>
          <li>Made payable to my529</li>
          <li>Sent to my529, PO Box 145100, Salt Lake City, UT 84114-5100</li>
          <li>{"Marked with the beneficiary's name and my529 account number"}</li>
          <li>{"Checks drawn from bank accounts with UGMA/UTMA designations must be contributed to a my529 UGMA/UTMA account in the beneficiary's name."}</li>
        </ul>
        <p>Notes:</p>
        <ul>
          <li>{"A separate transaction will appear on the account owner's bank statement for each my529 account receiving a contribution."}</li>
          <li>{"The account owner's my529 quarterly statement will reflect the contribution(s). This is their contribution receipt."}</li>
          <li>The funds will normally be debited from the bank account within three business days after the contribution appears in the my529 account.</li>
          <li>my529 UGMA/UTMA accounts may not be funded with an online contribution schedule. Instead, to set up or change automated contributions to an UGMA/UTMA account, please submit the Scheduled Electronic Contributions Authorization/Change form (form 200).</li>
          <li>The account owner will receive an email confirming the setup, change, or cancellation of their contribution schedule.</li>
          <li>The account owner should ensure that funds are available on the agreed-upon contribution day(s). Returned transactions or insufficient funds could cause fees to be assessed by my529 and/or the financial institution.</li>
          <li>Cancellation Instructions: This authorization is to remain in full effect until the end date (if applicable) or until the following:
            <ol className={styles.number}>
              <li>The bank account(s) specified above are deleted; or</li>
              <li>
                my529 is notified of the revocation of this authorization by doing one of the following:
                <ol className={styles.roman}>
                  <li>logging into Account Access at my529.org and using the online account feature Manage Contributions; {this.props.contributionType === 'special' && ('or')} </li>
                  <li>contacting my529 by telephone at 800.418.2551{this.props.contributionType !== 'special' ? '; or' : '.'} </li>
                  {this.props.contributionType !== 'special' && (
                    <li>submitting Form 200 via fax to 800.214.2956 or via U.S. Mail to PO Box 145100, Salt Lake City, UT 84114-5100.</li>
                  )}
                </ol>
              </li>
            </ol>
          </li>
          <li>To cancel or make changes to this contribution, your online request must be received before the close of market trading with the New York Stock Exchange (NYSE) (normally 2:00 p.m. MT), on the business day of the scheduled contribution. This contribution may not be cancelled or changed after that deadline. Any other revocation will not be effective until my529 has had a reasonable opportunity to act on the termination notification.</li>
          <li><b>If I am an authorized user in my529’s Limited Power of Attorney (LPOA) program,</b> and have been granted Level 2 or Level 3 LPOA authorization, I certify that I have been instructed and authorized by my client to add the bank account information contained herein and initiate a debit from that bank account to contribute to my client’s my529 account(s). I and my firm agree to indemnify and hold harmless my529 for any losses associated with this transaction.</li>
        </ul>
        <i>{"I authorize my529 to initiate, and the account owner's financial institution to honor, an electronic payment in the amount(s) specified above from the account owner's bank account. This authority pertains to the account(s) listed above. The information I have provided is accurate. I understand that rejected transactions or insufficient funds could cause fees to be assessed by my529 and/or the account owner's financial institution."}</i>
      </div>
    );
    /* eslint-enable quotes */
  }
}

export default TermsConditions;
