/*
*
* DualPushButtonSwitch Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { find, } from 'lodash';
import {
  Radio,
  RadioGroup,
  Button, FormControlLabel
} from '@mui/material';

import styles from './styles.module.css';

const radioButtonStyle = {
  display: 'inline-block',
  width: 'auto',
  padding: '10px',
};

// eslint-disable-next-line react/prop-types
function OptionButton({ label, value, active, disabled, onChange, style }) {
  return (
    <Button
      variant='contained'
      style={{
        color: active ? '#FFFFFF' : 'var(--accent)',
        backgroundColor: active ? 'var(--accent)' : '#FFFFFF',
        ...style,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      }}
      disabled={disabled}
      // There are no CSS styles for active
      className={active ? 'active' : undefined}
      onClick={() => onChange(value)}
    >
      {label}
    </Button>
  );
}

DualPushButtonSwitch.propTypes = {
  onChange: PropTypes.func.isRequired,
  groupLabel: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    radioLabel: PropTypes.string,
  })).isRequired,
};

export function DualPushButtonSwitch({ onChange, groupLabel, options }) {
  const useCompressedLayout = window.innerWidth <= 600;
  return useCompressedLayout ? (
    <div className={styles.DualPushButtonSwitch_container}>
      <fieldset>
        <RadioGroup
          name={groupLabel}
          className={styles.DualPushButtonSwitch_radioGroup}
          value={find(options, (option) => option.active).value}
          onChange={(_, value) => onChange(value)}
        >
          {options.map((option, index) => (
            <FormControlLabel
              key={`DualPushButtonSwitch_radioButton_${index}`}
              style={radioButtonStyle}
              disabled={option.disabled}
              value={option.value}
              label={option.radioLabel || option.label}
              control={<Radio disableRipple />}
            />
          ))}
        </RadioGroup>
      </fieldset>
    </div>
  ) : (
    <div className={styles.DualPushButtonSwitch_container}>
      {/* There are no CSS styles for pushButton_container or 1 */}
      <div className='pushButton_container 1'>
        <OptionButton
          {...options[0]}
          onChange={onChange}
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        />
      </div>
      {/* There are no CSS styles for pushButton_container or 2 */}
      <div className='pushButton_container 2'>
        <OptionButton
          {...options[1]}
          onChange={onChange}
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        />
      </div>
    </div>
  );
}

export default DualPushButtonSwitch;
