import { find } from 'lodash';

const permissions = [
  {
    permissionName: 'AllowView',
    description: '',
    label: 'View Account Information',
  },
  {
    permissionName: 'AllowContribute',
    description: 'A financial advisor may set up scheduled electronic contributions online or by submitting a Scheduled Electronic Contributions Authorization/Change Form 200 on behalf of client accounts.',
    label: 'Contributions',
  },
  {
    permissionName: 'AllowOptionChange',
    description: 'A financial advisor may change the investment option selected for each beneficiary on an account twice per calendar year. An investment option change can be made online or by submitting an Investment Option Change Form 405.',
    label: 'Investment Option Changes',
  },
  {
    permissionName: 'AllowTransfer',
    description: 'A financial advisor may schedule an internal transfer for the same account owner/agent online or by submitting an Internal Transfer Form 400.',
    label: 'Transfers',
  },
  {
    permissionName: 'AllowWithdraw',
    description: 'A financial advisor may initiate a withdrawal from my529 client accounts online or by submitting a Withdrawal Request Form 300. A withdrawal may be made payable only to the account owner, the beneficiary, or an eligible educational institution.',
    label: 'Withdrawals',
  },
  {
    permissionName: 'AllowViewStateTaxDocuments',
    description: 'For Utah residents/taxpayers only: Unless otherwise stated by the my529 account owner, a financial advisor may access a TC-675H form, which contains aggregated information about contributions, withdrawals, and transfers.',
    label: 'State Tax Documents',
  },
  {
    permissionName: 'AllowViewFederalTaxDocuments',
    description: 'Unless otherwise specified by the my529 account owner, a financial advisor may access the 1099-Q tax form, which contains aggregated information about withdrawals from the account.',
    label: 'Federal Tax Documents',
  },
  {
    permissionName: 'AdvisorEdit',
    description: 'Add additional users, assign/edit permissions and assign/edit access to client accounts. This feature is only available for Entity Limited Power of Attorney.',
    label: 'Create / Edit Users',
  },
  {
    permissionName: 'TemplateEdit',
    description: 'Create customized age-based and static investment option templates that may be saved and applied to client accounts.',
    label: 'Create Customized Templates',
  },
  {
    permissionName: 'DataImportSetup',
    description: 'Set up/edit link to a participating my529 data solution provider. This feature is only available for Entity Limited Power of Attorney.',
    label: 'Data Integration - Manage',
  },
  {
    permissionName: 'DataImport',
    description: 'Manually download client data that can imported into Morningstar Office or Schwab Portfolio Center. Please note that any user downloading the data files will have access, via those data files, to any my529 accounts designated on the Accounts to Export page, even if that user is not designated on the power of attorney agreement. This feature is only available for Entity Limited Power of Attorney.',
    label: 'Data Integration - Download',
  },
  {
    permissionName: 'AccountReports',
    description: 'Download reports containing detailed information on linked client accounts. This feature is only available for Entity Limited Power of Attorney.',
    label: 'Client Reports',
  },
  {
    permissionName: 'AdminReports',
    description: 'Download reports containing detailed information on authorized users for your firm. This feature is only available for Entity Limited Power of Attorney.',
    label: 'Admin and Compliance Reports',
  },
];

export function permissionGet(permissionName) {
  const permission = find(permissions, { permissionName });
  return permission ? permission : {};
}