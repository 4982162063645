import { POSTrequest, PUTrequest, GETrequest } from 'utils/helpers/api_handler';
import {
  BROWSER_WARNING,
  INITIALIZE_STORE,
  MOBILE_WARNING,
  USER_LOGIN,
  USERNAME_CHANGE,
  ADVISOR_NAME_UPDATE,
  GET_USER_PREFERENCES,
  SAVE_USER_PREFERENCE,
  TOGGLE_SPLASH_HAS_DISPLAYED,
  REFRESH_CLAIMS,
  RESET_CLAIMS,
} from './constants';

////////////////////////////////// LOGIN //////////////////////////////////
export function userLogin(params) {
  const response = POSTrequest('/authentication/login', params);
  return {
    type: USER_LOGIN,
    payload: response
  };
}

////////////////////////////////// LOGOUT //////////////////////////////////
export function userLogout(params) {
  const response = POSTrequest('/authentication/logout', params);
  return {
    type: INITIALIZE_STORE,
    payload: response
  };
}

////////////////////////////////// SESSION MGMT //////////////////////////////////
export function setBrowserWarning() {
  return {
    type: BROWSER_WARNING,
  };
}

export function setMobileWarning() {
  return {
    type: MOBILE_WARNING,
  };
}

export function clearStore() {
  return {
    type: INITIALIZE_STORE
  };
}

export function refreshClaims() { // This only refreshes claims, not the token. The token that is returned is the same as the existing session.
  const response = GETrequest('/authentication/ClaimRefresh');
  return {
    type: REFRESH_CLAIMS,
    payload: response,
  };
}

export function resetClaims() {
  const response = GETrequest('/authentication/claims');
  return {
    type: RESET_CLAIMS,
    payload: response,
  };
}

////////////////////////////////// USERNAME //////////////////////////////////
export function usernameChange(username, password) {
  const payload = PUTrequest('/authentication/changeusername', { username, password });

  return {
    type: USERNAME_CHANGE,
    payload,
  };
}

////////////////////////////////// OTHER //////////////////////////////////
export function advisorNameUpdate(name) {
  return {
    type: ADVISOR_NAME_UPDATE,
    name,
  };
}

export function getUserPreferences() {
  const payload = GETrequest('/users/preferences');
  
  return {
    type: GET_USER_PREFERENCES,
    payload,
  };
}

export function saveUserPreference(userId, key, value) {
  const payload = PUTrequest('/users/preferences', {
    UserId: userId,
    Identifier: key,
    Value: value,
  });
  
  return {
    type: SAVE_USER_PREFERENCE,
    payload,
  };
}

export function toggleSplashHasDisplayed() {
  return {
    type: TOGGLE_SPLASH_HAS_DISPLAYED,
  };
}