import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Switch,
} from '@mui/material';

import {
  LoadingOverlay,
  InfoIcon,
  notificationShow
} from '@frontend/common';

import { contactInfoUpdate } from '../actions';

import styles from './styles.module.css';

const select = (state) => ({
  advisor: state.myInfo.advisor,
});


export class NotificationToggles extends React.Component {
  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    advisor: PropTypes.object.isRequired,
    contactInfoUpdate: PropTypes.func.isRequired,
  };

  state = {
    togglingClientNotifications: false,
    togglingTransactionNotifications: false,
  }

  onNotificationToggle = (keyName) => {
    const updatedAdvisor = {
      FinancialAdvisorId: this.props.advisor.FinancialAdvisorId,
      [keyName]: !this.props.advisor[keyName],
    };
    this.setState({ [`toggling${keyName}`]: true });
    this.props.contactInfoUpdate(updatedAdvisor)
      .then(() => {
        this.props.notificationShow('Notifications updated.', 'success');
      })
      .finally(() => this.setState({ [`toggling${keyName}`]: false }));
  }

  render() {
    const { togglingClientNotifications, togglingTransactionNotifications } = this.state;
    const {
      advisor: {
        TransactionNotifications,
        ClientNotifications,
      }
    } = this.props;

    return (
      <div style={{ marginBottom: '40px ', display: 'flex', flexDirection: 'column', marginLeft: '25px', marginTop: '10px' }}>
        {/* There are no CSS styles for container */}
        <div className='container' style={{ marginBottom: '10px' }}>
          <div className={styles.row}>
            <div className={`${styles.col} ${styles.label}`}>
              <div>
                Transaction Notifications<InfoIcon message='A financial advisor may elect to receive email notifications for transactions on my529 accounts on which they have limited power of attorney access.' />
              </div>

            </div>
            <div className={`${styles.col} ${styles.toggle}`}>
              <LoadingOverlay show={togglingTransactionNotifications}>
                <Switch value='UtahResident' checked={TransactionNotifications} onChange={() => this.onNotificationToggle('TransactionNotifications')} />
              </LoadingOverlay>
            </div>
          </div>

          <div className={styles.row}>
            <div className={`${styles.col} ${styles.label}`}>
              <div>
                Client Notifications<InfoIcon message='A financial advisor may elect to receive email notifications when limited power of attorney access has been granted for a new account, or when the limited power of attorney access for an existing account has been modified or revoked.' />
              </div>

            </div>
            <div className={`${styles.col} ${styles.toggle}`}>
              <LoadingOverlay show={togglingClientNotifications}>
                <Switch value='UtahResident' checked={ClientNotifications} onChange={() => this.onNotificationToggle('ClientNotifications')} />
              </LoadingOverlay>
            </div>
          </div>


        </div>
      </div>

    );
  }

}

export default connect(select, {
  contactInfoUpdate,
  notificationShow
})(NotificationToggles);
