import {
  cloneDeep,
  find,
  omit,
} from 'lodash';

import dayjs from 'dayjs';

import {
  currencyFormatter,
  properCase,
} from '@frontend/common';

import {
  ACCOUNT_DETAILS_GET,
  ACCOUNTS_SEARCH,
  ACCOUNT_TRANSACTIONS_DETAILS_GET,
  ACCOUNTS_GET_BY_AGENT,
  AGENT_BANK_ACCOUNTS_GET,
  CONTRIBUTION_DETAILS_GET,
  CONTRIBUTION_EDIT_DETAILS_CLEAR,
  CONTRIBUTION_EDIT_DETAILS_GET,
  NEXT_RUN_DATE_GET,
  OPTION_CHANGE_DETAILS_GET,
  ROUTING_INFO_GET,
  SCHOOLS_GET,
  TRANSACTION_DETAILS_GET,
  UPCOMING_TRANSACTIONS_GET,
  WITHDRAWAL_DETAILS_GET,
  TRANSACTION_DELETE,
  SCHEDULED_TRANSACTION_GET,
  ACCOUNT_DOCUMENTS_GET,
  BULK_OPTION_CHANGE_CANDIDATES_GET,
  ACCOUNT_TRANSACTION_PREVIEW,
  TRANSFER_ACCOUNTS_GET,
  ACCOUNT_INVESTMENT_PATH_GET,
  GET_PRR_DATES,
  COUNTRIES_LIST_GET
} from './constants';

import { convert2DCalculatorSchemaTo3D } from '@frontend/common';

import {
  MONTHLY,
  TWICE_MONTHLY
} from './Transactions/Contributions/constants';

// formatter for Fund Units since it should include commas and decimal should at 5 places
const fundUnitsFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 5 });

const bankNameCaseExceptions = ['HSBC', 'JPMorgan', 'N.A.', 'NA', 'PNC', 'TD', 'U.S.', 'U.S.A.', 'US', 'USA'];

const initialState = {
  accountList: [],
  accountTransactionsDetails: {},
  agentBankAccounts: [],
  bankName: '',
  contributionDetails: {
    agentAccounts: [],
    contribution: {
      endDate: '',
      transactionId: '',
      type: ''
    },
  },
  contributionEditDetails: {},
  nextRunDates: {
    monthly: {
      day1: '',
      day2: '',
    },
    yearly: {
      birthday: [],
      day1: '',
    },
  },
  optionChangeDetails: {
    accounts: [],
    currentAccount: {},
    history: [],
    scheduled: []
  },
  postedTransactionDetailsCollection: {},
  schools: [],
  selectedAccount: {
    beneficiary: {
      phoneNumbers: [],
    },
    accountOwner: {
      phoneNumbers: [],
    },
    successors: {},
    details: {},
    funds: [],
    transactions: [],
    permissions: {},
  },
  selectedAgentAccounts: [],
  selectedTransaction: {
    schema: {
      TemplateName: null,
      FundCategories: [],
    }
  },
  upcomingTransactionsByAccount: {},
  withdrawalDetails: {
    agentAddress: {
      street1: '',
      street2: '',
      city: '',
      state: '',
      postalCode: '',
    },
    availableBalance: {
      display: '',
      number: 0
    },
    beneficiaryAddress: {
      street1: '',
      street2: '',
      city: '',
      state: '',
      postalCode: '',
    },
    groupAccounts: [],
    totalValue: {
      display: '',
      number: 0
    }
  },
  transfersByOwner: {},
  documents: {},
  bulkOptionChangeCandidates: [],
  transactionPreviewDetails: {
    canContinue: false,
    errors: [],
  },
  cache: {}, // [accountId]: ?accountDetails
  transfers: {}, // [transferId]: transfer
  transferAccounts: {}, // [sourceAccountId]: PotentialTargetAccounts[]
  investmentPath: { TemplateId: -1, TemplateName: '', FundCategories: [] },
  pRRDates: {
    minDate: null,
    maxDate: null,
  },
  countries: []
};

export function accountFilter(accountId, state) {
  return find(state.accountList, { AccountId: accountId });
}

export function buildTransfer(data) {
  return {
    TransferId: data.TransferId,
    AccountId: data.AccountId,
    AccountGroupId: data.AccountGroupId,
    CloseAccount: data.CloseAccount,
    Amount: data.Amount,
    Percentage: data.Percentage,
    TradeDate: data.TradeDate,
    Status: data.Status,
    TargetAccounts: data.TargetAccounts.map(target => ({
      AccountId: target.AccountId,
      Amount: target.Amount,
      Percentage: target.Percentage,
    })),
    cachedAt: dayjs.utc(),
  };
}

function AccountsReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case ACCOUNTS_SEARCH: {
      newState.accountList = action.payload.data;
      return newState;
    }

    case ACCOUNT_DETAILS_GET: {
      const account = action.payload.data;
      const beneAddress = account.Beneficiary.MailingAddress;
      const accountOwnerAddress = account.Agent.MailingAddress;
      newState.selectedAccount = {
        accountId: account.AccountId,
        accountGroupId: account.AccountGroupId,
        beneficiary: {
          name: properCase(`${account.Beneficiary.FirstName} ${account.Beneficiary.LastName}`),
          streetAddress1: properCase(beneAddress.StreetAddress1),
          streetAddress2: properCase(beneAddress.StreetAddress2),
          streetAddress3: properCase(beneAddress.StreetAddress3),
          city: properCase(beneAddress.City),
          state: beneAddress.State,
          zip: beneAddress.PostalCode,
          country: properCase(beneAddress.Country),
          phoneNumbers: account.Beneficiary.PhoneNumbers,
          age: account.Beneficiary.Age,
        },
        accountOwner: {
          name: properCase(`${account.Agent.FirstName} ${account.Agent.LastName}`),
          streetAddress1: properCase(accountOwnerAddress.StreetAddress1),
          streetAddress2: properCase(accountOwnerAddress.StreetAddress2),
          streetAddress3: properCase(accountOwnerAddress.StreetAddress3),
          city: properCase(accountOwnerAddress.City),
          state: accountOwnerAddress.State,
          zip: accountOwnerAddress.PostalCode,
          country: properCase(accountOwnerAddress.Country),
          phoneNumbers: account.Agent.PhoneNumbers,
        },
        successors: {
          primarySuccessorName: account.Successor ? properCase(`${account.Successor.FirstName} ${account.Successor.LastName}`) : 'None',
          secondarySuccessorName: account.SecondarySuccessor ? properCase(`${account.SecondarySuccessor.FirstName} ${account.SecondarySuccessor.LastName}`) : 'None',
        },
        details: {
          accountType: account.AccountType,
          accountNumber: account.AccountNumber,
          option: account.TemplateName ? account.TemplateName : account.Option.Name,
          optionChangesRemaining: account.OptionChangesRemaining,
          optionId: account.Option.OptionId,
          isCustom: account.Option.IsCustom,
          netPrincipal: currencyFormatter(account.AccountBalance.NetPrincipalContributions),
          ytdContributions: currencyFormatter(account.AccountBalance.YTDContributions),
          priorContributions: currencyFormatter(account.AccountBalance.PriorContributions),
          accountBalance: currencyFormatter(account.AccountBalance.Balance),
          netAvailable: account.NetAvailable,
        },
        funds: account.OptionAllocationFunds.map(fund => ({
          id: fund.Fund.FundId,
          name: fund.Fund.Name,
          targetAllocation: fund.Percentage,
          actualAllocation: fund.ActualPercentage,
          value: fund.Value,
          units: fundUnitsFormatter.format(fund.Units),
          unitPrice: fund.FormattedPrice,
        })),
        transactions: account.Transactions.map(trans => ({
          id: trans.TransactionId,
          date: trans.PostDate,
          type: trans.Type,
          basis: trans.BasisAmount,
          amount: trans.Amount,
          taxYear: trans.TaxYear,
        })),
        permissions: {
          optionChanges: JSON.parse(account.OptionChange || false),
          contributions: JSON.parse(account.Contribute || false),
          transfers: JSON.parse(account.Transfer || false),
          withdrawals: JSON.parse(account.Withdraw || false),
        },
        cachedAt: dayjs.utc(),
        ytdPRR: account.YearToDatePRR,
      };
      newState.cache[newState.selectedAccount.accountId] = newState.selectedAccount;

      return newState;
    }

    case ACCOUNT_TRANSACTIONS_DETAILS_GET: {
      newState.accountTransactionsDetails = action.payload.data;
      return newState;
    }

    case ACCOUNTS_GET_BY_AGENT: {
      newState.selectedAgentAccounts = action.payload.data.map(account => ({
        accountId: account.AccountId,
        accountNumber: account.AcctNumber,
        acctOption: account.AcctOption,
        accountType: account.AccountType,
        agentId: account.AgentId,
        agentEmail: account.AgtEmail,
        ytdPRR: account.ytdPRR,
        agentName: `${properCase(account.AgtFirst)} ${properCase(account.AgtLast)}`,
        beneficiaryName: `${properCase(account.BenFirst)} ${properCase(account.BenLast)}`,
        beneficiaryBday: account.BenBirthDate,
        groupId: account.AccountGroupId,
        giftCode: account.GiftCode,
        giftCodeActive: account.GiftCodeActive,
        marketValue: currencyFormatter(account.AcctBalance),
      }));
      return newState;
    }

    case AGENT_BANK_ACCOUNTS_GET: {
      newState.agentBankAccounts = action.payload.data.map(acct => {
        return {
          id: acct.BankAccountId,
          name: properCase(acct.BankName, bankNameCaseExceptions),
          number: acct.MaskedBankAccountNumber,
          type: `(${acct.BankAccountType})`
        };
      });

      return newState;
    }

    case CONTRIBUTION_DETAILS_GET: {
      const { agentBankAccounts, contributionEditDetails: edit, selectedAgentAccounts: accounts } = newState;

      const selectedAccount = Object.keys(edit).length > 0 && (accounts.find(account => account.accountId === edit.AccountId));

      newState.contributionDetails = {
        agentAccounts: accounts.map(account => ({
          ...account,
          contributionAmount: 0,
          endDate: '',
        })),
        agentBankAccounts,
        agentName: accounts[0].agentName,
        selectedAccountId: parseInt(action.accountId),
        contribution: selectedAccount && ({
          bankAccount: agentBankAccounts.find(account => account.id === edit.BankAccountId),
          day1: edit.ScheduleDate1.ScheduledDate,
          day2: edit.ScheduleDate2 ? edit.ScheduleDate2.ScheduledDate : '',
          endDate: edit.EndDate,
          occasion: edit.Occasion,
          selectedAccounts: [{
            accountId: edit.AccountId,
            accountNumber: selectedAccount.accountNumber,
            beneficiaryBday: selectedAccount.beneficiaryBday,
            beneficiaryName: selectedAccount.beneficiaryName,
            contributionAmount: edit.Amount,
            endDate: edit.EndDate || '',
          }],
          transactionId: edit.ScheduleId,
          type: edit.InvestmentType === MONTHLY && edit.ScheduleDate2 !== null ? TWICE_MONTHLY : edit.InvestmentType,
        })
      };

      return newState;
    }

    case CONTRIBUTION_EDIT_DETAILS_CLEAR: {
      newState.contributionEditDetails = {};

      return newState;
    }

    case CONTRIBUTION_EDIT_DETAILS_GET: {
      newState.contributionEditDetails = action.payload.data;
      return newState;
    }

    case NEXT_RUN_DATE_GET: {
      const response = action.payload.data;
      const frequency = action.meta.frequency.toLowerCase();
      const day = action.meta.day.toLowerCase();

      if (frequency === MONTHLY.toLowerCase()) {
        newState.nextRunDates.monthly[day] = dayjs(response);
      }
      else {
        if (Array.isArray(response)) {
          newState.nextRunDates.yearly[day] = response.map(date => dayjs(date));
        }
        else {
          newState.nextRunDates.yearly[day] = dayjs(response);
        }
      }
      return newState;
    }

    case OPTION_CHANGE_DETAILS_GET: {
      const accountId = parseInt(action.accountId);

      const currentAccount = Object.assign(
        accountFilter(accountId, newState),
        { AccountId: accountId },
        newState.accountTransactionsDetails
      );

      const groupAccounts = currentAccount.AccountInfo.map(account => {
        return {
          accountId: account.AccountId,
          accountNumber: account.AccountNumber,
          optionDescription: account.OptionName,
          totalValue: currencyFormatter(account.MarketValue),
        };
      });

      newState.optionChangeDetails = {
        accountId: currentAccount.AccountId,
        accountNumber: currentAccount.AcctNumber,
        accountsInGroup: currentAccount.AccountInfo.length,
        accountType: currentAccount.AccountType,
        agentName: properCase(`${currentAccount.AgtFirst} ${currentAccount.AgtLast}`),
        beneficiaryName: properCase(`${currentAccount.BenFirst} ${currentAccount.BenLast}`),
        groupAccounts,
        marketValue: currencyFormatter(currentAccount.MarketValue),
        optionChangeCreate: currentAccount.OptionChangeCreate,
        optionChangesRemaining: currentAccount.RemainingOptionChanges,
        optionDescription: currentAccount.AcctOption,
      };

      return newState;
    }

    case ROUTING_INFO_GET: {
      newState.bankName = action.payload.payload ? '' : properCase(action.payload.data.BankName, bankNameCaseExceptions);

      return newState;
    }

    case SCHOOLS_GET: {
      const schools = action.payload.data;

      newState.schools = schools.map(school => {
        return {
          city: properCase(school.City),
          fafsaId: school.Code,
          name: properCase(school.Name),
          schoolId: school.CollegeId.toString(),
          state: school.State
        };
      });

      return newState;
    }

    case TRANSACTION_DETAILS_GET: {
      const response = action.payload.data;

      newState.postedTransactionDetailsCollection[response.TransactionId] = {
        accessCardNumber: response.AccessCard !== null ? response.AccessCard.CardNumber : '',
        bankInfo: response.BankAccount !== null && ({
          bankName: response.BankAccount.BankName,
          bankAccountNumber: response.BankAccount.MaskedBankAccountNumber,
          bankAccountType: response.BankAccount.BankAccountType,
        }),
        basisAmount: currencyFormatter(response.BasisAmount),
        payee: properCase(response.PayeeName),
        postDate: dayjs(response.PostDate).format('L'),
        source: response.AccessCard !== null ? response.AccessCard.Title : (response.Source || 'N/A'), // if access card is used, the title should be display as the source
        totalAmount: currencyFormatter(response.Amount),
        transactionDetails: response.TransactionDetails.map(details => ({
          amount: details.Amount || 0,
          id: details.TransactionDetailId,
          name: details.Fund.Name,
          price: details.FormattedPrice,
          units: fundUnitsFormatter.format(details.Units),
        })),
        selectedTransactionId: response.TransactionId,
        type: response.Type
      };

      return newState;
    }

    case UPCOMING_TRANSACTIONS_GET: {
      newState.upcomingTransactionsByAccount = {
        ...omit(newState.upcomingTransactionsByAccount, `${action.meta.accountId}`),
        [action.meta.accountId]: action.payload.data.map(trans => ({
          id: trans.ScheduleId,
          vdId: `${trans.ScheduleId}-${trans.Frequency}-${trans.Status}`,
          type: trans.TransactionType,
          amount: trans.Amount,
          scheduledDate: trans.ScheduledDate,
          frequency: trans.Frequency,
          status: trans.Status,
          url: trans.URL,
          canEdit: trans.CanEdit,
          canDelete: trans.CanDelete,
          accountId: trans.AccountId,
          accountGroupId: trans.AccountGroupId,
          typeName: trans.TransactionType
            .replace(' In', '')
            .replace(' Out', ''),
        })),
      };

      return newState;
    }

    case TRANSACTION_DELETE: {
      const upcomingTransactions = newState.upcomingTransactionsByAccount[action.meta.accountId];
      newState.upcomingTransactionsByAccount[action.meta.accountId] =
        upcomingTransactions.filter(transaction => transaction.id !== action.meta.transactionId);

      return newState;
    }

    case WITHDRAWAL_DETAILS_GET: {
      const accountId = parseInt(action.meta.accountId);

      const currentAccount = Object.assign(
        accountFilter(accountId, newState),
        { AccountId: accountId },
        newState.accountTransactionsDetails
      );

      const accountInGroup = find(currentAccount.AccountInfo, { AccountId: accountId });

      newState.withdrawalDetails = {
        accountId: currentAccount.AccountId,
        accountNumber: currentAccount.AcctNumber,
        accountType: currentAccount.AccountType,
        agentAddress: {
          line1: properCase(currentAccount.Agent.Recipient.Line1),
          line2: properCase(currentAccount.Agent.Recipient.Line2, ['C/O', 'FBO', 'UGMA/UTMA']),
          name: properCase(`${currentAccount.Agent.FirstName} ${currentAccount.Agent.LastName}`),
          street1: properCase(currentAccount.Agent.MailingAddress.StreetAddress1, ['PO']),
          street2: properCase(currentAccount.Agent.MailingAddress.StreetAddress2, ['PO']),
          city: properCase(currentAccount.Agent.MailingAddress.City),
          state: { Code: currentAccount.Agent.MailingAddress.State },
          postalCode: currentAccount.Agent.MailingAddress.PostalCode,
        },
        agentAddressChanged: currentAccount.AgentAddressChanged,
        agentBankAccounts: newState.agentBankAccounts,
        agentName: properCase(`${currentAccount.Agent.FirstName} ${currentAccount.Agent.LastName}`),
        allowFullWithdrawalIndividual: accountInGroup.AllowFullWithdrawal,
        allowFullWithdrawalGroup: currentAccount.AllowFullWithdrawal,
        availableBalance: {
          display: currencyFormatter(accountInGroup.AvailableForWithdrawal),
          number: accountInGroup.AvailableForWithdrawal
        },
        beneficiaryAddress: {
          name: properCase(`${currentAccount.Beneficiary.FirstName} ${currentAccount.Beneficiary.LastName}`),
          street1: properCase(currentAccount.Beneficiary.MailingAddress.StreetAddress1, ['PO']),
          street2: properCase(currentAccount.Beneficiary.MailingAddress.StreetAddress2, ['PO']),
          city: properCase(currentAccount.Beneficiary.MailingAddress.City),
          state: { Code: currentAccount.Beneficiary.MailingAddress.State },
          postalCode: currentAccount.Beneficiary.MailingAddress.PostalCode,
        },
        beneficiaryAddressChanged: currentAccount.BeneficiaryAddressChanged,
        beneficiaryName: properCase(`${currentAccount.Beneficiary.FirstName} ${currentAccount.Beneficiary.LastName}`),
        groupAccounts: currentAccount.AccountInfo.map(account => {
          return {
            accountId: account.AccountId,
            accountNumber: account.AccountNumber,
            allowFullWithdrawalIndividual: account.AllowFullWithdrawal,
            availableBalance: {
              display: currencyFormatter(account.AvailableForWithdrawal),
              number: account.AvailableForWithdrawal
            },
            optionDescription: account.OptionName,
            percentageOfTotalValue: account.AvailableForWithdrawal / currentAccount.AvailableForWithdrawal || 0,
            marketValue: {
              display: currencyFormatter(account.MarketValue),
              number: account.MarketValue
            },
          };
        }),
        groupId: currentAccount.AccountGroupId,
        marketValue: currencyFormatter(accountInGroup.MarketValue),
        MarketValue: accountInGroup.MarketValue,
        NetAvailable: accountInGroup.NetAvailable,
        optionDescription: currentAccount.AcctOption,
        schoolAddresses: currentAccount.SchoolAddresses.map(address => {
          return {
            attn: properCase(address.Department),
            name: properCase(address.SchoolName),
            street1: properCase(address.Address.StreetAddress1),
            city: properCase(address.Address.City),
            state: { Code: address.Address.State },
            postalCode: address.Address.PostalCode,
            schoolId: address.SchoolId.toString(),
            fbo: `${properCase(`${currentAccount.Beneficiary.FirstName} ${currentAccount.Beneficiary.LastName}`)}`,
            studentIdNum: address.StudentId,
          };
        }),
        totalValue: {
          display: currencyFormatter(currentAccount.AvailableForWithdrawal),
          number: currentAccount.AvailableForWithdrawal
        },
      };

      return newState;
    }

    case SCHEDULED_TRANSACTION_GET: {
      let transaction = action.payload.data;
      switch (action.meta.type) {
        case 'Transfer':
          transaction = buildTransfer(transaction);
          newState.transfers[transaction.TransferId] = transaction;
          break;

        case 'Option Change': {
          transaction = {
            accountId: transaction.Actions[0].AccountId,
            actionId: transaction.Actions[0].ActionId,
            schema: transaction.Actions[0].Schema ? convert2DCalculatorSchemaTo3D(transaction.Actions[0].Schema) : null,
            currentOption: transaction.Actions[0].CurrentOption,
            newOption: transaction.Actions[0].NewOption,
            predefinedOptionId: transaction.Actions[0].NewOptionId,
            optionChangeId: transaction.OptionChangeId,
            status: transaction.Status,
            templateId: transaction.Actions[0].TemplateId,
            createdDate: transaction.TradeDate
          };
          break;
        }
        case 'Withdrawal':
        case 'Contribution':
        default:
          break;
      }
      newState.selectedTransaction = transaction;

      return newState;
    }

    case ACCOUNT_DOCUMENTS_GET: {
      const { data } = action.payload;
      newState.documents[action.meta.accountId] = data.map((document, index) => ({
        id: index + 1,
        type: document.DocumentType,
        fileType: document.Format,
        year: document.Year,
        quarter: document.Quarter,
        url: document.Url,
        description: document.Description,
        additionalDocURL: document.Supplement ? document.Supplement.Url : null,
      }));

      return newState;
    }

    case BULK_OPTION_CHANGE_CANDIDATES_GET: {
      newState.bulkOptionChangeCandidates = action.payload.data.map(candidate => ({
        ...candidate,
        AccountNumber: parseInt(candidate.AccountNumber),
        Beneficiary: properCase(candidate.Beneficiary),
        Agent: properCase(candidate.Agent),
      }));

      return newState;
    }

    case ACCOUNT_TRANSACTION_PREVIEW: {
      const response = action.payload.data;
      newState.transactionPreviewDetails = {
        canContinue: response[action.meta.transactionType],
        errors: response.Comments,
      };

      return newState;
    }

    case TRANSFER_ACCOUNTS_GET: {
      newState.transferAccounts[action.meta.sourceAccount] = action.payload.data.map(account => ({
        AccountId: account.AccountId,
        accountGroupId: account.AccountGroupId,
        beneficiary: {
          name: properCase(`${account.BenFirst} ${account.BenLast}`),
        },
        details: {
          accountNumber: account.AcctNumber,
        }
      }));

      return newState;
    }

    case ACCOUNT_INVESTMENT_PATH_GET: {
      newState.investmentPath = convert2DCalculatorSchemaTo3D(action.payload.data);
      return newState;
    }

    case GET_PRR_DATES: {
      newState.pRRDates = {
        minDate: action.payload.data.StartDate,
        maxDate: action.payload.data.EndDate,
      };
      return newState;
    }

    case COUNTRIES_LIST_GET: {
      newState.countries = action.payload.data;
      return newState;
    }

    default:
      return state;
  }

}

export default AccountsReducer;
