/*
*
* TemplateCopy
*
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Breadcrumbs, CalculatorForm } from '@frontend/common';

import { getServerDateTime } from 'components/AppRoot/StaticResources/actions';
import { getTemplate } from 'components/Features/protected/Templates/actions';

const select = (state) => ({
  template: state.templates.template,
  serverDateTime: state.static.serverDateTime,
});

export class TemplateCopy extends Component {

  static propTypes = {
    getTemplate: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    getServerDateTime: PropTypes.func.isRequired,
    serverDateTime: PropTypes.string,
    onAllocationChange: PropTypes.func.isRequired,
    onTemplateNameChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onMount: PropTypes.func.isRequired,
  };

  state = {
    template: {
      FundName: '',
      FundCategories: []
    },
    loading: true,
    serverDateTime: null,
    templateName: ''
  };

  componentDidMount() {
    const {
      getTemplate, match,
      getServerDateTime, serverDateTime, onMount
    } = this.props;

    const promises = [];

    // get current date (calculator needs for the agreement year)
    if (!serverDateTime) {
      promises.push(getServerDateTime());
    }

    promises.push(getTemplate(`${match.params.id}`, true)
      .then(() => {
        const template = { ...this.props.template, TemplateName: '' };
        this.setState({ template });
        // pass to parent
        onMount(template);
      })
      .catch(() => null));

    this.setState({ loading: true });
    Promise.all(promises)
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const {
      serverDateTime, onAllocationChange,
      onTemplateNameChange, onSave
    } = this.props;
    const { loading, template } = this.state;
    const isStatic = template.TemplateType === 'S';
    const newCustomTemplateTitle = isStatic ? 'New Customized Static' : 'New Customized Age-Based';

    return (
      <div>
        <Breadcrumbs
          crumbs={[
            {
              title: 'Templates',
              link: '/templates'
            },
            {
              title: newCustomTemplateTitle
            },
          ]}
        />
        <CalculatorForm
          loading={loading}
          stickyHeaderTopOffset={60}
          schema={template}
          currentDate={serverDateTime}
          isTemplateNameEditing={true}
          onAllocationChange={onAllocationChange}
          onTemplateNameChange={onTemplateNameChange}
          onSave={onSave}
        />
      </div>
    );
  }
}

export default withRouter(connect(select, { 
  getTemplate,
  getServerDateTime 
})(TemplateCopy));