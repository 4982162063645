/*
*
* ManageUsers Reducers
*
*/
import { cloneDeep } from 'lodash';
import {
  USERS_LIST_GET,
  USER_DETAILS_GET,
  USER_UPDATE,
  POA_ACCOUNTS_GET,
  USER_CREATE,
  PRIMARY_CONTACTS_ASSIGN,
  DEACTIVATE_USER,
  REACTIVATE_USER,
} from './constants';
import { properCase } from '@frontend/common';

const initialState = {
  users: {},
  poaAccounts: [],
  createdUserPin: '',
};

export function userDataMap(user, dataComplete) {
  return {
    advisorId: user.FinancialAdvisorId,
    FirstName: user.FirstName,
    LastName: user.LastName,
    EmailAddress: user.EmailAddress,
    PhoneNumber: user.PhoneNumber ? user.PhoneNumber.Number : '',
    PhoneNumber1: user.PhoneNumber1 ? user.PhoneNumber1.Number : '',
    ActivationDate: user.ActivationDate,
    AllowContribute: user.AllowContribute,
    AllowOptionChange: user.AllowOptionChange,
    AllowTransfer: user.AllowTransfer,
    AllowWithdraw: user.AllowWithdraw,
    AllowViewStateTaxDocuments: user.AllowViewStateTaxDocuments,
    AllowViewFederalTaxDocuments: user.AllowViewFederalTaxDocuments,
    AdvisorEdit: user.AdvisorEdit,
    EditFirmContactInfo: user.EditFirmContactInfo,
    EditFirmAdvisorDefaults: user.EditFirmAdvisorDefaults,
    TemplateEdit: user.TemplateEdit,
    DataImportSetup: user.DataImportSetup,
    DataImport: user.DataImport,
    AccountReports: user.AccountReports,
    AdminReports: user.AdminReports,
    DeactivationDate: user.DeactivationDate,
    POAs: user.POAs
      ? user.POAs.map(poa => ({
        id: poa.POAId,
        ownerName: properCase(poa.AgentName),
        beneficiaryName: properCase(poa.BeneName),
        isPrimaryContact: poa.IsPrimaryContact,
      }))
      : [],
    POAIds: null,
    ViewAllAccounts: user.ViewAllAccounts,
    IsDefaultPrimaryContact: user.IsDefaultPrimaryContact,
    pin: user.Pin,
    username: user.Username,
    status: user.Status,
    lastLogin: user.LastLogin,
    dataComplete,
  };
}

function ManageUsersReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case USERS_LIST_GET: {
      const cachedUsers = cloneDeep(newState.users);
      newState.users = action.payload.data
        .reduce((result, user) => ({
          ...result,
          [user.FinancialAdvisorId]: cachedUsers[user.FinancialAdvisorId]
            ? { ...userDataMap(user, false), ...cachedUsers[user.FinancialAdvisorId] }
            : userDataMap(user, false),
        }), {});
      return newState;
    }

    case USER_DETAILS_GET:
    case USER_UPDATE:
    case PRIMARY_CONTACTS_ASSIGN:
    case USER_CREATE: {
      const user = userDataMap(action.payload.data, true);
      newState.users[user.advisorId] = user;
      return newState;
    }

    case POA_ACCOUNTS_GET: {
      newState.poaAccounts = action.payload.data.map(account => ({
        id: account.POAId,
        ownerName: properCase(account.AgentName),
        beneficiaryName: properCase(account.BeneName),
      }));

      return newState;
    }

    case DEACTIVATE_USER: {
      newState.users[action.meta.advisorId] = userDataMap(action.payload.data, false);
      return newState;
    }

    case REACTIVATE_USER: {
      newState.users[action.meta.advisorId] = userDataMap(action.payload.data, false);
      return newState;
    }

    default:
      return state;
  }

}

export default ManageUsersReducer;
