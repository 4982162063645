import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import events from 'utils/ga';

import {
  Icon,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
} from '@mui/material';

import { withStyles, } from '@mui/styles';

const styles = theme => ({
  listItemText: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '24px',
    color: theme.palette.primary.main,
    lineHeight: 'initial',
  },
  icon: {
    color: theme.palette.primary.main,
  }
});


export class SidenavMenuOption extends Component {
  
  static propTypes = {
    name: PropTypes.string.isRequired,
    link: PropTypes.string,
    onOptionClick: PropTypes.func,
    rootMenuOptionIconName: PropTypes.string,
    classes: PropTypes.object.isRequired,
    externalLink: PropTypes.string,
    isSideNavOpen: PropTypes.bool,
  };

  state = {
    showMenuOptions: false,
  };

  dropdownMenuOptionCompose() {
    const { showMenuOptions } = this.state;
    const { rootMenuOptionIconName, classes, children, name } = this.props;
    const nestedMenuOptions = this.nestedMenuOptionsCompose(children);

    return (
      <div className='hideOnPrint'>
        <ListItem
          onClick={() => this.setState({ showMenuOptions: !showMenuOptions })}
          button
        >
          <ListItemIcon>
            <Icon className={classes.icon}>{rootMenuOptionIconName}</Icon>
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.listItemText }}>
            {name}
          </ListItemText>
          <Icon className={classes.icon}>{showMenuOptions ? 'expand_less' : 'expand_more'}</Icon>
        </ListItem>
        <Collapse in={showMenuOptions}>
          <List component='div' >
            {nestedMenuOptions}
          </List>
        </Collapse>
      </div>
    );
  }

  menuOptionClickHandle(link, onMenuOptionClick, externalLink, name) {
    if (link) {
      this.props.history.push(link);
    }
    else if (externalLink) {
      events.resourceAccessed(name);
      window.open(externalLink, '_blank');
    }
    this.setState({ showMenuOptions: false });
    onMenuOptionClick();
  }

  nestedMenuOptionsCompose(options) {
    const optionsArray = options.length ? options : new Array(options);

    return optionsArray.map((option, index) => {
      const { link, onOptionClick, externalLink, name } = option.props;
      return (
        <ListItem
          key={index}
          onClick={() => this.menuOptionClickHandle(link, onOptionClick, externalLink, name)}
          button
        >
          {name}
        </ListItem>
      );
    });
  }

  normalMenuOptionCompose() {
    const { classes, link, onOptionClick, rootMenuOptionIconName, name, externalLink } = this.props;
    return (
      <ListItem onClick={() => this.menuOptionClickHandle(link, onOptionClick, externalLink, name)} button >
        <ListItemIcon>
          <Icon className={classes.icon}>{rootMenuOptionIconName}</Icon>
        </ListItemIcon>
        <ListItemText classes={{ primary: classes.listItemText }}>
          {name}
        </ListItemText>
      </ListItem>
    );
  }

  componentDidUpdate(prevProps) {
    const { children, isSideNavOpen } = this.props;
    // if showing, hide menu options when side nav is closed
    if (!isEmpty(children) && !isSideNavOpen && prevProps.isSideNavOpen) this.setState({ showMenuOptions: false, });
  }

  render() {
    return (
      // There are no CSS styles for SidenavMenuOption_container
      <div className='SidenavMenuOption_container'>
        {this.props.children
          ? this.dropdownMenuOptionCompose()
          : this.normalMenuOptionCompose()
        }
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(SidenavMenuOption));
