import { combineReducers } from '@reduxjs/toolkit';

import { INITIALIZE_STORE } from 'components/AppRoot/Navigation/constants';

import Session from 'components/AppRoot/Navigation/reducer';
import StaticResources from 'components/AppRoot/StaticResources/reducer';
import Accounts from 'components/Features/protected/Accounts/reducer';
import Notifications from '@frontend/common/build/components/Notifications/reducer';
import Templates from 'components/Features/protected/Templates/reducer';
import NewAccounts from 'components/Features/protected/NewAccounts/reducer';
import DashboardSettings from 'components/Features/protected/Dashboard/reducer';
import ManageLPOA from 'components/Features/protected/ManageLPOA/reducer';
import ManageUsers from 'components/Features/protected/ManageUsers/reducer';
import MyInfo from 'components/Features/protected/MyInfo/reducer';
import Reports from 'components/Features/protected/Reports/reducer';
import ManageData from 'components/Features/protected/ManageData/reducer';
import Multifactor from 'components/Features/protected/Multifactor/reducer';

/*
Ability to reinitialize the entire store.
This action type is used by /logout
*/
const rootReducer = (state, action) => {
  if (action.type === INITIALIZE_STORE) {
    state = {
      static: {
        ageBrackets: [],
        documents: state.static.documents,
        webMessages: state.static.webMessages,
        loadingPublicVars: state.static.loadingPublicVars,
        environmentVars: state.static.environmentVars,
        passwordRequirements: state.static.passwordRequirements,
        options: state.static.options,
        customAgeBasedOptions: state.static.customAgeBasedOptions,
        customStaticOptions: state.static.customStaticOptions,
        newAgeBasedSchema: state.static.newAgeBasedSchema,
        newStaticSchema: state.static.newStaticSchema,
        relationships: [],
        poaLevels: [],
      }
    };
    // clear token at the same time
    sessionStorage.removeItem('token');
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  session: Session,
  static: StaticResources,
  accounts: Accounts,
  notifications: Notifications,
  templates: Templates,
  newAccounts: NewAccounts,
  dashboardSettings: DashboardSettings,
  manageLPOA: ManageLPOA,
  manageUsers: ManageUsers,
  myInfo: MyInfo,
  reports: Reports,
  manageData: ManageData,
  multifactor: Multifactor,
});

export default rootReducer;
