import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Bowser from 'bowser';
import { clear } from 'idb-keyval';

import NameMenu from './NameMenu';
import { My529Logo, EnvHeader, LoadingOverlay } from '@frontend/common';

import {
  Icon,
  IconButton
} from '@mui/material';

import {
  setBrowserWarning,
  setMobileWarning
} from 'components/AppRoot/Navigation/actions';
import { portfolioProvidersGet } from 'components/Features/protected/ManageData/actions';
import { getStaticDocuments, getEnvironmentVariables, getWebMessages, setLoadingPublicVars } from 'components/AppRoot/StaticResources/actions';
import { getUserPreferences } from 'components/AppRoot/Navigation/actions';
import { ENV_COLOR_KEY } from '../../StaticResources/constants';

import events from 'utils/ga';

import styles from './styles.module.css';

const bowser = Bowser.getParser(window.navigator.userAgent).parsedResult;

const frontendConfig = window.frontendConfig;
const location = window.location;

const select = (state) => ({
  userDetails: state.session.userDetails,
  showSideNav: state.session.showSideNav,
  isValid: state.session.isValid,
  showBrowserWarning: state.session.showBrowserWarning,
  showMobileWarning: state.session.showMobileWarning,
  staticDocs: state.static.documents,
  lpoaHelpNumber: state.static.environmentVars.lpoaHelpNumber,
  environmentColor: state.static.environmentVars.environmentColor,
});

export class Header extends Component {

  static propTypes = {
    handleLogout: PropTypes.func.isRequired,
    openSideNav: PropTypes.func.isRequired,
    pageTitle: PropTypes.string,
    userDetails: PropTypes.object.isRequired,
    showSideNav: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    showBrowserWarning: PropTypes.bool.isRequired,
    showMobileWarning: PropTypes.bool.isRequired,
    setBrowserWarning: PropTypes.func.isRequired,
    setMobileWarning: PropTypes.func.isRequired,
    portfolioProvidersGet: PropTypes.func.isRequired,
    getStaticDocuments: PropTypes.func.isRequired,
    staticDocs: PropTypes.object.isRequired,
    lpoaHelpNumber: PropTypes.string,
    getEnvironmentVariables: PropTypes.func.isRequired,
    getWebMessages: PropTypes.func.isRequired,
    setLoadingPublicVars: PropTypes.func.isRequired,
    getUserPreferences: PropTypes.func.isRequired,
    environmentColor: PropTypes.string.isRequired,
  };

  state = {
    loadingPrefs: false,
    showHelpInfo: false,
    showEnvMessage: location.hostname.toLowerCase() !== 'fa.my529.org' && (!Object.prototype.hasOwnProperty.call(frontendConfig, 'envWarning') || frontendConfig.envWarning),
  };

  warningsCompile() {
    if (this.props.showBrowserWarning && (bowser.browser.name === 'Internet Explorer')) {
      return this.warningMessageCompose('browser', 'You are currently using a browser that is not supported. For the best experience, please use Chrome or Firefox.');
    }
    else if (this.props.showMobileWarning && (bowser.platform.type === 'mobile' || bowser.platform.type === 'tablet')) {
      return this.warningMessageCompose('mobile', 'Mobile browsing of this website is currently in beta. For full functionality, please use a desktop browser.');
    }
    else {
      return null;
    }
  }

  warningMessageCompose(warningType, message) {
    return (
      <div className={styles.Header_browserWarningContainer}>
        <span>{message}</span>
        <div
          className={styles.Header_browserWarningClose}
          onClick={() => this.dismissWarning(warningType)}
        >
          <Icon className='material-icons'>close</Icon>
        </div>
      </div>
    );
  }

  environmentWarningHeader = () => {
    const environmentColor = localStorage.getItem(ENV_COLOR_KEY) || this.props.environmentColor;
    const { showEnvMessage } = this.state;
    return (
      <div className='hideOnPrint'>
        <EnvHeader environmentColor={environmentColor} hide={!showEnvMessage} />
      </div>
    );
  };

  dismissWarning(warningType) {
    switch (warningType) {
      case 'browser': {
        this.props.setBrowserWarning();
        break;
      }
      case 'mobile': {
        this.props.setMobileWarning();
        break;
      }
      default: {
        return null;
      }
    }
  }

  componentDidMount() {
    clear(); // clear IndexedDB
    
    const apiCalls = [];

    if (Object.keys(this.props.staticDocs).length === 0) {
      apiCalls.push(this.props.getStaticDocuments());
    }

    if (!this.props.lpoaHelpNumber) {
      apiCalls.push(this.props.getEnvironmentVariables());
    }

    apiCalls.push(this.props.getWebMessages());

    this.props.setLoadingPublicVars(true);
    Promise.all(apiCalls)
      .then(() => this.props.setLoadingPublicVars(false))
      .catch(() => this.props.setLoadingPublicVars(false));
  }

  componentDidUpdate(prevProps) {
    if (this.props.isValid && this.props.isValid !== prevProps.isValid) {
      const apiCalls = [
        this.props.portfolioProvidersGet(),
        this.props.getUserPreferences(),
      ];
      this.setState({ loadingPrefs: true });
      Promise.all([apiCalls])
        .catch(() => null)
        .finally(() => this.setState({ loadingPrefs: false }));
    }
  }

  render() {
    const { showHelpInfo, loadingPrefs } = this.state;
    const { lpoaHelpNumber } = this.props;

    if (this.props.isValid) {
      return (
        <div className={`${styles.Header_navContainer} hideOnPrint`}>
          <header className={styles.Header_protectedNav}>
            <div className={styles.Header_left}>
              <div className={styles.Header_lighterBackground}>
                <div className={styles.Header_menuIcon}>
                  <IconButton
                    aria-label='Main Menu'
                    onClick={this.props.openSideNav}
                    style={{ color: '#fff' }}
                  >
                    <Icon>menu</Icon>
                  </IconButton>
                </div>
                <My529Logo color='#fff' className={styles.Header_sideLogo} />
              </div>
              <div className={styles.Header_arrow} />
              <h1 className={styles.Header_pageTitle}>
                {this.props.pageTitle}
              </h1>
            </div>
            <div className={styles.Header_right}>
              <div id={styles.Header_helpIcon}>
                {showHelpInfo && <div id={styles.Header_helpMenuOverlay} onClick={() => this.setState({ showHelpInfo: false })} />}
                <IconButton
                  aria-label='Help Number'
                  onClick={() => { this.setState({ showHelpInfo: !showHelpInfo }); events.helpAccessed(window.location.pathname); }} // eslint-disable-line
                  style={{ color: '#fff' }}
                >
                  <Icon>help_outline</Icon>
                </IconButton>
                <div id={styles.Header_helpMenu} className={showHelpInfo ? styles.show : ''} style={{ display: showHelpInfo ? 'block' : 'none' }}>
                  Need help? Contact my529 at:
                  <div>{lpoaHelpNumber ? <a href={`tel:${lpoaHelpNumber}`}>{lpoaHelpNumber}</a> : 'Loading...'}</div>
                </div>
              </div>
              <LoadingOverlay show={loadingPrefs}>
                <NameMenu
                  name={this.props.userDetails.name}
                  onLogoutClick={this.props.handleLogout}
                />
              </LoadingOverlay>

            </div>
          </header>
          {this.environmentWarningHeader()}
          {this.warningsCompile()}
        </div>
      );
    }
    else {
      return (
        <div className={styles.Header_navContainer}>
          <header className={styles.Header_publicNav}>
            <a
              href='https://advisor.my529.org/'
              target='_self'
              rel='noopener noreferrer'
              style={{ paddingTop: '3px' }}
            >
              <My529Logo color='#fff' />
            </a>
          </header>
          {this.environmentWarningHeader()}
          {this.warningsCompile()}
        </div>
      );
    }
  }
}

export default connect(select, {
  setBrowserWarning,
  setMobileWarning,
  portfolioProvidersGet,
  getStaticDocuments,
  getEnvironmentVariables,
  getUserPreferences,
  getWebMessages,
  setLoadingPublicVars,
})(Header);
