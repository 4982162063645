/*
*
* ContributionDetails Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card } from '@frontend/common';

import styles from './styles.module.css';

const select = (state) => ({
  contributionDetails: state.accounts.contributionDetails
});

export class ContributionDetails extends React.Component {
  static propTypes = {
    contributionDetails: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  render() {
    const { loading, contributionDetails } = this.props;
    
    return (
      // There are no CSS styles for ContributionDetails_container
      <Card className='ContributionDetails_container'>
        <div className={styles.ContributionDetails_accountDetailsAO}>
          <div className={styles.ContributionDetails_detailsTitle}>Account Owner</div>
          {loading ? '' : contributionDetails.agentName}
        </div>
      </Card>
    );
  }
}

export default connect(select, {})(ContributionDetails);
