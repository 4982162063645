/*
*
* ReportsList Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { reportsGet } from '../actions';
import ReportCard from './ReportCard';

import { LoadingOverlay } from '@frontend/common';

import styles from './styles.module.css';

const select = (state) => ({
  permissions: state.session.permissions,
  reports: Object.values(state.reports.reports),
});


export class ReportsList extends React.Component {

  static propTypes = {
    permissions: PropTypes.object.isRequired,
    reports: PropTypes.array.isRequired,
    reportsGet: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
  };

  reportCardCompose(report) {
    return (
      <ReportCard
        key={report.id}
        id={report.id}
        name={report.name}
        description={report.description ? report.description : ''}
      />
    );
  }

  componentDidMount() {
    if (this.props.reports.length === 0) {
      this.setState({ loading: true });
      this.props.reportsGet()
        .catch(() => null)
        .finally(() => this.setState({ loading: false }));
    }
  }

  render() {
    /* eslint-disable indent */
    const { reports, permissions } = this.props;
    const { loading } = this.state;
    const accountReports = reports.filter(report => report.category === 'account');
    const adminReports = reports.filter(report => report.category === 'admin');

    return (
      <div className={styles.ReportsList_page}>
        {loading && (
          <div className={styles.ReportsList_loading}>
            <LoadingOverlay
              show={loading}
              width='100%'
              indicatorHeight='10px'
            />
          </div>
        )}
        <div className={styles.ReportsList_reports}>
          {(permissions.accountReports && accountReports.length > 0) && (
            <div className={styles.ReportsList_section}>
              <div>Clients</div>
              <hr />
              {accountReports.map(report => this.reportCardCompose(report))}
            </div>
          )}
          {(permissions.adminReports && adminReports.length > 0) && (
            <div className={styles.ReportsList_section}>
              <div>Admin and Compliance</div>
              <hr />
              {adminReports.map(report => this.reportCardCompose(report))}
            </div>
          )}
        </div>
      </div>
    );
    /* eslint-enable indent */
  }
}

export default connect(select, { reportsGet })(ReportsList);
