import {
  HIT,
  PAGE_NOT_FOUND,
} from '../constants';
import { urlScrub } from '../helpers';

export function pageNotFoundHit(url) {
  return {
    eventCategory: PAGE_NOT_FOUND,
    eventAction: HIT,
    eventLabel: urlScrub(url),
  };
}