/*
*
* ManageData Actions
*
*/
import {
  POAS_FOR_EXPORT_GET,
  POAS_FOR_EXPORT_UPDATE,
  BACK_OFFICES_GET,
  BACK_OFFICES_UPDATE,
  PORTFOLIO_PROVIDERS_GET,
  PORTFOLIO_PROVIDERS_UPDATE,
  PORTFOLIO_DATA_DOWNLOAD,
} from './constants';
import { GETrequest, PUTrequest, POSTrequest } from 'utils/helpers/api_handler';

export function poasForExportGet() {
  const payload = Promise.all([
    GETrequest('/data/poasoptions'),
    GETrequest('/data/poas')
  ]);
  
  return {
    type: POAS_FOR_EXPORT_GET,
    payload,
  };
}

export function poasForExportUpdate(allSelected, poaIds) {
  const payload = PUTrequest('/data/poas', {
    AllAccounts: allSelected,
    PoaIds: poaIds,
  });
  
  return {
    type: POAS_FOR_EXPORT_UPDATE,
    payload,
  };
}

export function backOfficesGet() {
  const payload = Promise.all([
    GETrequest('/data/backofficesoptions'),
    GETrequest('/data/backoffices')
  ]);
  
  return {
    type: BACK_OFFICES_GET,
    payload,
  };
}

export function backOfficesUpdate(ids) {
  const payload = POSTrequest('/data/backoffice', ids);

  return {
    type: BACK_OFFICES_UPDATE,
    payload,
  };
}

export function portfolioProvidersGet() {
  const payload = Promise.all([
    GETrequest('/data/downloadformatsoptions'),
    GETrequest('/data/downloadformats')
  ]);
  
  return {
    type: PORTFOLIO_PROVIDERS_GET,
    payload,
  };
}

export function portfolioProvidersUpdate(codes) {
  const payload = PUTrequest('/data/downloadformats', codes);

  return {
    type: PORTFOLIO_PROVIDERS_UPDATE,
    payload,
  };
}

export function portfolioDataDownload(downloadFormat, startDate, endDate) {
  // responseType indicates the type of data that the server will respond with and it needs to be 'blob'
  const payload = POSTrequest('/data/downloadfile', { downloadFormat, startDate, endDate }, { responseType: 'blob' });
  return {
    type: PORTFOLIO_DATA_DOWNLOAD,
    payload,
  };
}