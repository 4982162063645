/*
*
* MyInfo Actions
*
*/
import { POSTrequest, GETrequest, PUTrequest } from 'utils/helpers/api_handler';
import {
  PASSWORD_CHANGE,
  ADVISOR_GET,
  CONTACT_INFO_UPDATE,
} from './constants';

export function passwordChange(oldpassword, newpassword1, newpassword2) {
  const payload = POSTrequest('/authentication/changepassword', {
    oldpassword,
    newpassword1,
    newpassword2
  });

  return {
    type: PASSWORD_CHANGE,
    payload,
  };
}

export function advisorGet() {
  const payload = GETrequest('/administration/financialadvisor/self');
  return {
    type: ADVISOR_GET,
    payload,
  };
}

export function contactInfoUpdate(advisor) {
  const payload = PUTrequest('/administration/financialadvisor/self/update', advisor);
  return {
    type: CONTACT_INFO_UPDATE,
    payload,
  };
}