/*
*
* TransferStepOneContent Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  reduce,
  some,
} from 'lodash';
import {
  currencyFormatter,
} from '@frontend/common';
import {
  Checkbox,
  IconButton,
  TextField,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icon,
} from '@mui/material';
import DualPushButtonSwitch from './DualPushButtonSwitch';
import { TransferTypes, TransferInputTypes } from '../typeConstants';
import TargetAccount from '../TargetAccount';
import { formatAccountDisplayText } from '../index';

import styles from './styles.module.css';

export function onKeyUp(showFullBalanceDialog) {
  return (e) => {
    // enabling keyboard accessibility
    if (e.key === 'Enter' || e.key === 'Space') {
      showFullBalanceDialog();
    }
  };
}

TransferStepOneContent.propTypes = {
  sourceAccount: PropTypes.object.isRequired,
  destinationAccounts: PropTypes.array.isRequired,
  transfer: PropTypes.object.isRequired,
  onTransferChange: PropTypes.func.isRequired,
  onTargetChange: PropTypes.func.isRequired,
  addTargetAccount: PropTypes.func.isRequired,
  removeTargetAccount: PropTypes.func.isRequired,
  showFullBalanceDialog: PropTypes.func.isRequired,
  getErrorForInput: PropTypes.func.isRequired,
};

export function TransferStepOneContent(props) {
  const {
    sourceAccount,
    destinationAccounts,
    transfer,
    onTransferChange,
    showFullBalanceDialog,
    onTargetChange,
    addTargetAccount,
    removeTargetAccount,
    getErrorForInput,
  } = props;
  return (
    <div className={styles.Transfer_stepOneContainer}>
      <div key='TransferContainer-transferType' className={styles.Transfer_transferTypeContainer}>
        <div className={styles.Transfer_transferTypeToggleContainer}>
          <RadioGroup
            aria-label='Transfer Type Toggle'
            name='TransferType'
            className={styles.Transfer_transferType_radioButtonGroup}
            value={transfer.transferType}
            onChange={() => onTransferChange('transferType')}
            style={{
              display: 'flex'
            }}
          >
            <FormControlLabel
              value='partial'
              label='Partial Transfer'
              control={<Radio disableRipple />}
            />
            <FormControlLabel
              value='full'
              label={
                <span>
                  Full Balance Transfer
                  <div
                    className={styles.InfoIcon_container}
                    onClick={showFullBalanceDialog}
                    onKeyUp={onKeyUp(showFullBalanceDialog)}
                    tabIndex={0}
                    style={{
                      display: 'inline-flex'
                    }}
                  >
                    <Icon style={{ cursor: 'pointer', fontSize: '22px', color: 'var(--accent)' }}>
                      {/* There are no CSS styles for InfoIcon_icon */}
                      <div className='InfoIcon_icon'>
                        help_outline
                      </div>
                    </Icon>
                  </div>
                </span>
              }
              control={<Radio disableRipple />}
            />
          </RadioGroup>

        </div>
      </div>
      <div className={styles.Transfer_accountsSelection}>
        <div className={styles.Transfer_sourceContainer}>
          <div className={`${styles.Transfer_sourceAccountDescription} ${getErrorForInput('Transfer_sourceContainer') ? styles.error : ''}`}>
            <div className={styles.Transfer_sourceAccountLabel}>
              Source Account
            </div>
            <div className={styles.Transfer_sourceAccountName}>
              {formatAccountDisplayText(sourceAccount)}
            </div>
            <div className={styles.error}>
              {getErrorForInput('Transfer_sourceContainer')}
            </div>
          </div>
          <div className={styles.Transfer_keepAccountOpenCheckbox}>
            {transfer.transferType === TransferTypes.full ?
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!transfer.CloseAccount}
                    onChange={() => onTransferChange('CloseAccount')}
                  />
                }
                label='Leave this account open'
              /> : null
            }
          </div>
        </div>
        <div className={styles.Transfer_iconContainer}>
          <Icon style={{ color: 'black' }}>
            arrow_forward
          </Icon>
        </div>
        <div className={styles.Transfer_destinationColumn}>
          <DualPushButtonSwitch
            groupLabel='Amount/Percentage'
            onChange={() => onTransferChange('transferInputType')}
            options={[
              {
                active: transfer.transferInputType === TransferInputTypes.Amount,
                disabled: transfer.transferType === TransferTypes.full,
                label: '$',
                radioLabel: 'Amount ($)',
                value: TransferInputTypes.Amount,
              },
              {
                active: transfer.transferInputType === TransferInputTypes.Percentage,
                label: '%',
                radioLabel: 'Percentage (%)',
                value: TransferInputTypes.Percentage,
              }
            ]}
          />
          {transfer.TargetAccounts.map((targetAccount, rowIndex) => {
            const accountErrorText = getErrorForInput(`Transfer_destinationContainer-${rowIndex}`);
            return (
              <TargetAccount
                key={`TargetAccount-${rowIndex}`}
                index={rowIndex}
                target={targetAccount}
                accountOptions={destinationAccounts.map(account => ({
                  ...account,
                  disabled: some(transfer.TargetAccounts, (dest, destIndex) => {
                    return destIndex !== rowIndex && dest.AccountId === account.AccountId;
                  }),
                })
                )}
                canRemove={rowIndex !== 0}
                accountError={accountErrorText}
                inputError={getErrorForInput(`Transfer_inputContainer-${rowIndex}`)}
                inputFieldType={transfer.transferInputType}
                onAccountChange={onTargetChange(rowIndex)}
                onRemove={() => removeTargetAccount(rowIndex)}
              />
            );
          })}
          <div className={styles.Transfer_destinationFooter}>
            <div key='Transfer_addIconContainer' className={styles.Transfer_addIconContainer}>
              <IconButton
                disabled={destinationAccounts.length <= transfer.TargetAccounts.length}
                onClick={addTargetAccount}
              >
                <Icon>add_circle</Icon>
              </IconButton>
            </div>
            <div key='Transfer_amountTotal' className={styles.Transfer_amountTotal}>
              <FormControl error={Boolean(getErrorForInput('Transfer_amountTotal'))}>
                <TextField
                  label='Total'
                  style={{
                    width: 'auto',
                    display: 'inline-block',
                    cursor: 'text',
                    color: getErrorForInput('Transfer_amountTotal') ? 'var(--error)' : 'var(--text)'
                  }}
                  name='Transfer_totalRowContainer_textField'
                  value={
                    transfer.transferInputType === TransferInputTypes.Amount ?
                      currencyFormatter(reduce(transfer.TargetAccounts, (sum, account) => sum + (account[transfer.transferInputType] || 0), 0)) :
                      `${reduce(transfer.TargetAccounts, (sum, account) => sum + (account[transfer.transferInputType] || 0), 0)}%`
                  }
                  inputProps={{
                    readOnly: true,
                  }}
                />
                <FormHelperText>{getErrorForInput('Transfer_amountTotal')}</FormHelperText>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransferStepOneContent;
