import { cloneDeep } from 'lodash';
import { properCase } from '@frontend/common';
import {
  BROWSER_WARNING,
  MOBILE_WARNING,
  USER_LOGIN,
  USERNAME_CHANGE,
  ADVISOR_NAME_UPDATE,
  GET_USER_PREFERENCES,
  SAVE_USER_PREFERENCE,
  USER_PREFERENCE_KEYS,
  TOGGLE_SPLASH_HAS_DISPLAYED,
  RESET_CLAIMS,
  TWO_FACTOR_STATUS,
} from './constants';


const initialState = {
  isValid: false,
  claims: {},
  accountBlocked: false,
  reasonBlocked: '',
  is2FABypassed: true,
  showBrowserWarning: true,
  showMobileWarning: true,
  showSideNav: false,
  splashHasDisplayed: false,
  userDetails: {},
  permissions: {},
  lpoaDetails: {
    address: {},
  },
  userPreferences: {
    [USER_PREFERENCE_KEYS.hasSeenTour]: 'true',
  },
};

export function mapClaims(claims) {
  return {
    userDetails: {
      email: claims.EmailAddress,
      previousLogin: claims.LastLogin,
      name: properCase(claims.Name || ''),
      pin: claims.Pin,
      lpoaNumber: claims.AuthorizedRepresentativeNumber,
    },
    lpoaDetails: {
      firmName: properCase(claims.AuthorizedRepresentativeName || ''),
      address: {
        street1: properCase(claims.AuthorizedRepresentativeMailingStreetAddress1 || ''),
        street2: properCase(claims.AuthorizedRepresentativeMailingStreetAddress2 || ''),
        street3: properCase(claims.AuthorizedRepresentativeMailingStreetAddress3 || ''),
        city: properCase(claims.AuthorizedRepresentativeMailingCity || ''),
        state: claims.AuthorizedRepresentativeMailingState,
        zip: claims.AuthorizedRepresentativeMailingPostalCode,
      }
    },
    permissions: {
      isFirmEntity: claims.AuthorizedRepresentativeType === 'Entity',
      contributions: JSON.parse(claims.AllowContribute || false),
      optionChanges: JSON.parse(claims.AllowOptionChange || false),
      transfers: JSON.parse(claims.AllowTransfer || false),
      withdrawals: JSON.parse(claims.AllowWithdraw || false),
      viewStateTaxDocs: JSON.parse(claims.AllowViewStateTaxDocuments || false),
      viewFedTaxDocs: JSON.parse(claims.AllowViewFederalTaxDocuments || false),
      adminReports: JSON.parse(claims.AdminReports || false),
      accountReports: JSON.parse(claims.AccountReports || false),
      editTemplates: JSON.parse(claims.TemplateEdit || false),
      setupData: JSON.parse(claims.DataImportSetup || false),
      downloadData: JSON.parse(claims.DataImport || false),
      manageUsers: JSON.parse(claims.AdvisorEdit || false),
      editLPOAContactInfo: JSON.parse(claims.EditFirmContactInfo || false),
      editLPOASettings: JSON.parse(claims.EditFirmAdvisorDefaults || false),
    },
  };
}

export function constructClaims(claims) {
  return claims.reduce((result, claim) => ({ ...result, [claim.m_type]: claim.m_value }), {});
}

export default function NavigationReducer(state = initialState, action) {  
  const newState = cloneDeep(state);

  switch (action.type) {
    case BROWSER_WARNING: {
      newState.showBrowserWarning = !state.showBrowserWarning;
      return newState;
    }

    case MOBILE_WARNING: {
      newState.showMobileWarning = !state.showMobileWarning;
      return newState;
    }

    case USER_LOGIN: 
    case RESET_CLAIMS: {
      const rawClaims = action.payload.data.Claims ? action.payload.data.Claims : action.payload.data;
      const claims = constructClaims(rawClaims);
      const mappedClaims = mapClaims(claims);
      newState.claims = claims;
      if (action.payload.data.Token) {
        sessionStorage.setItem('token', action.payload.data.Token);
      }
      newState.accountBlocked = claims.AccountBlocked === 'True'; 
      newState.reasonBlocked = newState.accountBlocked ? claims.AccountBlock : ''; // when AccountBlocked is true AccountBlock is missing from api
      newState.is2FABypassed = claims.TwoFactorAuthentication === TWO_FACTOR_STATUS.BYPASSED;
      newState.userDetails = mappedClaims.userDetails;
      newState.permissions = mappedClaims.permissions;
      newState.lpoaDetails = mappedClaims.lpoaDetails;
      newState.isValid = true;
      return newState;
    }

    case USERNAME_CHANGE: {
      const claims = constructClaims(action.payload.data.Claims);
      const mappedClaims = mapClaims(claims);
      newState.claims = claims;
      newState.userDetails = mappedClaims.userDetails;
      newState.permissions = mappedClaims.permissions;
      newState.lpoaDetails = mappedClaims.lpoaDetails;

      sessionStorage.setItem('token', action.payload.data.Token);

      return newState;
    }

    case ADVISOR_NAME_UPDATE: {
      newState.userDetails.name = action.name;
      return newState;
    }

    case GET_USER_PREFERENCES: {
      const preferences = action.payload.data.reduce((result, preference) => ({ ...result, [preference.Identifier]: preference.Value }), {});
      newState.userPreferences = {
        ...preferences,
        [USER_PREFERENCE_KEYS.hasSeenTour]: preferences[USER_PREFERENCE_KEYS.hasSeenTour] ? preferences[USER_PREFERENCE_KEYS.hasSeenTour] : 'false',
      };
      return newState;
    }

    case SAVE_USER_PREFERENCE: {
      const preference = action.payload.data;
      newState.userPreferences[preference.Identifier] = preference.Value;
      return newState;
    }

    case TOGGLE_SPLASH_HAS_DISPLAYED: {
      newState.splashHasDisplayed = true;
      return newState;
    }

    default: {
      return state;
    }
  }
}
