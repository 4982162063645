import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter, } from 'react-router-dom';
import LogoutWarning from './LogoutWarning';

import {
  allNotificationsHide,
  notificationShow,
} from '@frontend/common';

import {
  userLogout,
  clearStore,
} from './actions';

import { REASONS_BLOCKED } from './constants';

const msg_logged_out_inactivity = 'Your session has expired. You have been automatically logged out.';
const msg_logged_out_inactivity_warning = (time) => `Your session will expire in ${time}. If you wish to continue click CLOSE`;
const title_logged_out_inactivity_warning = 'Inactivity Warning';

const select = (state) => ({
  environmentVars: state.static.environmentVars,
  isValid: state.session.isValid,
  reasonBlocked: state.session.reasonBlocked,
  identityVerified: state.multifactor.PINVerification.IdentityVerified,
});

const windowEvents = [
  'click', // detects mouse click or finger press on button
  'keydown', // detects any key being pressed down
  'mousedown', // detects mouse click
  'mousemove', // detects any mouse movement
  'touchstart', // detects touch events for smaller screens
  'scroll' // detects scrolling on any device
];

export class ProtectedRoute extends React.Component {

  static propTypes = {
    reasonBlocked: PropTypes.string.isRequired,
    identityVerified: PropTypes.bool.isRequired,
    environmentVars: PropTypes.shape({
      LogoutWarningTimeout: PropTypes.number.isRequired,
      AccountAccessNoFrontEndActivityTimeout: PropTypes.number.isRequired,
    }).isRequired,
    userLogout: PropTypes.func.isRequired,
    clearStore: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    component: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func,
    ]),
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
  };

  state = {
    inactivityWarningOn: false,
  };

  // timers
  inactivityWarningTimer = null;

  /* inactivity */
  inactivityLogout = () => {
    const token = sessionStorage.getItem('token');
    this.props.userLogout({ token })
      .finally(() => this.props.notificationShow(msg_logged_out_inactivity, 'error'));
    this.props.clearStore();
    this.props.history.push('/login');
  }

  restartInactivityProcedure = () => {
    const { environmentVars: { AccountAccessNoFrontEndActivityTimeout } } = this.props;
    if (!this.state.inactivityWarningOn) {
      // reset all timers
      const noFrontEndActivityTimeoutInMs = AccountAccessNoFrontEndActivityTimeout * 1000;
      clearTimeout(this.inactivityWarningTimer);
      this.inactivityWarningTimer = setTimeout(() => this.setState({ inactivityWarningOn: true }), noFrontEndActivityTimeoutInMs);
    }
  }

  cancelInactivityWarning = () => {
    clearTimeout(this.inactivityWarningTimer);
    this.setState({ inactivityWarningOn: false });
  };

  handleAccountBlocks = () => {
    const { reasonBlocked, history, location: { pathname }, identityVerified } = this.props;
    
    switch (reasonBlocked) {
      case REASONS_BLOCKED.TWO_FACTOR_REGISTRATION: {
        // make sure all registration routes are enabled but account for the pin verification
        let updatedPathname;
        if (
          pathname === '/multifactor' ||
          pathname === '/multifactor/verify-identity'
        ) {
          updatedPathname = pathname;
        }
        else if (pathname === '/multifactor/register' && identityVerified) {
          updatedPathname = pathname;
        }
        else {
          updatedPathname = '/multifactor';
        }
        history.push(updatedPathname);
        break;
      }        

      case REASONS_BLOCKED.TWO_FACTOR_AUTHENTICATION:
        // there is only 1 authentication path
        history.push('/multifactor/authenticate');
        break;

      default: return; // no blocks
    }
  }

  componentDidMount() {
    if (this.props.isValid) {
      windowEvents.forEach(e => window.addEventListener(e, this.restartInactivityProcedure));
      this.restartInactivityProcedure();
    }

    window.onload = () => {
      this.props.allNotificationsHide();
    };

    this.handleAccountBlocks();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.handleAccountBlocks();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.inactivityWarningTimer);
    windowEvents.forEach(e => window.removeEventListener(e, this.restartInactivityProcedure));
  }

  render() {
    const { environmentVars: { LogoutWarningTimeout }, component: Component, ...rest } = this.props;
    const { inactivityWarningOn } = this.state;
    const logoutWarningTimeoutInMs = LogoutWarningTimeout * 1000;

    return (
      <Route {...rest} render={props => {
        return this.props.isValid
          ?
          <div>
            <Component {...props} />
            <LogoutWarning
              logoutWarningInterval={logoutWarningTimeoutInMs}
              parseWarningMsg={msg_logged_out_inactivity_warning}
              modalTitle={title_logged_out_inactivity_warning}
              closeButtonLabel={'Close'}
              onWarningClose={this.cancelInactivityWarning}
              warningOn={inactivityWarningOn}
              onTimeout={this.inactivityLogout}
              onCloseContinueWithTimeout={false}
            />
          </div>
          :
          <Redirect
            push
            to={{
              pathname: '/login',
              search: props.location.pathname !== '/' && `?referrer=${props.location.pathname}`,
            }}
          />;
      }}
      />
    );
  }
}

export default withRouter(connect(select, {
  allNotificationsHide,
  notificationShow,
  userLogout,
  clearStore,
})(ProtectedRoute));
