/*
*
* ManageUsers Component
*
*/
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import UsersList from './UsersList';
import NewUser from './NewUser';
import UserDetails from './UserDetails';
import PrimaryContact from './PrimaryContact';
import PageNotFound from 'components/Features/protected/PageNotFound';


export class ManageUsers extends React.Component {

  render() {
    return (
      <Switch>
        <Route exact path='/manage-users' component={UsersList} />
        <Route exact path='/manage-users/new' component={NewUser} />
        <Route exact path='/manage-users/:advisorId/primary-contact' component={PrimaryContact} />
        <Route path='/manage-users/:advisorId' component={UserDetails} />
        <Route component={PageNotFound} />
      </Switch>
    );
  }
}

export default ManageUsers;
