/*
*
* OptionChangeDetails Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Icon } from '@mui/material';
import {
  Card,
  LoadingOverlay
} from '@frontend/common';
import styles from './styles.module.css';

const select = (state) => ({
  optionChangeDetails: state.accounts.optionChangeDetails
});

export class OptionChangeDetails extends React.Component {

  static propTypes = {
    accountsInGroupModalShow: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    optionChangeDetails: PropTypes.object.isRequired,
  };

  render() {
    const {
      accountsInGroupModalShow,
      loading,
      optionChangeDetails
    } = this.props;

    return (
      <div className={styles.OptionChangeDetails_container}>

        <div className={styles.OptionChangeDetails_loading}>
          <LoadingOverlay
            show={loading}
            width='100%'
            indicatorHeight='10px'
          />
        </div>

        <Card
          className={styles.OptionChangeDetails_accountDetailsCard}
          title='Account Details'
        >
          <div className={styles.OptionChangeDetails_accountDetails}>
            <div className={styles.OptionChangeDetails_accountDetailsAO}>
              <div className={styles.OptionChangeDetails_detailsTitle}>Account Owner</div>
              {loading ? '' : optionChangeDetails.agentName}
            </div>
            <div className={styles.OptionChangeDetails_accountDetailsBen}>
              <div className={styles.OptionChangeDetails_detailsTitle}>Beneficiary</div>
              {loading ? '' : optionChangeDetails.beneficiaryName}
            </div>
            <div className={styles.OptionChangeDetails_accountDetailsAcctNum}>
              <div className={styles.OptionChangeDetails_detailsTitle}>Account Number</div>
              {loading ? '' : optionChangeDetails.accountNumber}
            </div>
            <div className={styles.OptionChangeDetails_accountDetailsTotalVal}>
              <div className={styles.OptionChangeDetails_detailsTitle}>Total Value</div>
              {loading ? '' : optionChangeDetails.marketValue}
            </div>
            <div className={styles.OptionChangeDetails_accountDetailsAcctType}>
              <div className={styles.OptionChangeDetails_detailsTitle}>Account Type</div>
              {loading ? '' : optionChangeDetails.accountType}
            </div>
            {optionChangeDetails.accountsInGroup > 1 && (
              <div className={styles.OptionChangeDetails_accountDetailsAccountsInGroup}>
                <div className={styles.OptionChangeDetails_accountDetailsAccountsInGroupInfo}>
                  <div className={styles.OptionChangeDetails_detailsTitle}>Accounts in Group</div>
                  <Icon id='OptionChangeDetails_accountDetailsAccountsInGroupIcon' onClick={accountsInGroupModalShow} style={{ cursor: 'pointer', fontSize: '18px', color: 'var(--accent)' }} >help_outline</Icon>
                </div>
                {loading ? '' : optionChangeDetails.accountsInGroup}
              </div>
            )}
          </div>
        </Card>
        <Card
          className={styles.OptionChangeDetails_optionDetailsCard}
          title='Option Change Details'
        >
          <div className={styles.OptionChangeDetails_optionDetails}>
            <div className={styles.OptionChangeDetails_optionDetailsCurrentOpt}>
              <div className={styles.OptionChangeDetails_detailsTitle}>Current Option</div>
              {loading ? '' : optionChangeDetails.optionDescription}
            </div>
            <div className={styles.OptionChangeDetails_optionDetailsRemainingOpts}>
              <div className={styles.OptionChangeDetails_detailsTitle}>Option Changes Remaining for Calendar Year</div>
              <div className={optionChangeDetails.optionChangesRemaining === 0 ? styles.OptionChangeDetails_zeroOptionChangesRemaining : ''}>{loading ? '' : optionChangeDetails.optionChangesRemaining}</div>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

export default withRouter(connect(select, {})(OptionChangeDetails));
