/*
*
* PortfolioDataDownload Component
*
*/
/* eslint-disable newline-per-chained-call */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dayjs from 'dayjs';

import { portfolioProvidersGet, portfolioDataDownload } from '../actions';
import { saveFile } from 'utils/helpers/files';
import languageConfig from './languageConfig';

import {
  LoadingOverlay,
  Card,
  DatePicker,
  notificationShow,
} from '@frontend/common';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
} from '@mui/material';

import styles from './styles.module.css';

const select = (state) => ({
  providers: state.manageData.portfolioProviders,
  oldestSelectableDate: state.static.environmentVars.downloadPortfolioData.oldestSelectableDate
});

export class PortfolioDataDownload extends React.Component {

  static propTypes = {
    providers: PropTypes.array.isRequired,
    portfolioProvidersGet: PropTypes.func.isRequired,
    portfolioDataDownload: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    oldestSelectableDate: PropTypes.oneOf([PropTypes.object, PropTypes.string]).isRequired,
  };

  state = {
    loading: false,
    downloadLoading: false,
    selectedProvider: '',
    startDate: this.previousBusinessDayGet(dayjs()).toDate(),
    endDate: this.previousBusinessDayGet(dayjs()).toDate(),
    errors: {
      endDate: '',
      startDate: ''
    },
  };

  dateRangeVerify = () => {
    const { startDate, endDate } = this.state;
    const dateDiff = dayjs(endDate).diff(dayjs(startDate), 'day');
    let weekendCount = 0;
    const errors = {
      endDate: '',
      startDate: ''
    };

    const { oldestSelectableDate } = this.props;
    const yesterday = dayjs().subtract(1, 'day');

    for (let i = dateDiff; i > 0; i--) {
      const day = dayjs(endDate).subtract(i, 'day');
      if (day.day() === 0 || day.day() === 6) {
        weekendCount++;
      }
    }

    if (!dayjs(startDate).isBetween(oldestSelectableDate, yesterday, null, '[]')) {
      errors.startDate = `Start date must occure between ${dayjs(oldestSelectableDate).format('MM/DD/YYYY')} and ${dayjs(yesterday).format('MM/DD/YYYY')}`;
    }

    if (endDate < startDate) {
      errors.endDate = 'End date must occur after start date.';
    }
    else if ((dateDiff - weekendCount) >= 30) {
      errors.endDate = 'Date must occur within 30 weekdays after start date.';
    }
    else if (!dayjs(endDate).isBetween(startDate, yesterday, null, '[]')) {
      errors.endDate = `End date must occure between ${dayjs(startDate).format('MM/DD/YYYY')} and ${dayjs(yesterday).format('MM/DD/YYYY')}`;
    }

    this.setState({ errors });
    const errorMessages = Object.values(errors).find(val => val);
    return !errorMessages || errorMessages.length === 0;
  }

  downloadDataHandle = () => {
    if (this.dateRangeVerify()) {
      this.setState({ downloadLoading: true });
      const { selectedProvider, startDate, endDate } = this.state;
      this.props.portfolioDataDownload(selectedProvider, startDate, endDate)
        .then(({ payload: { data, headers, status } }) => {
          /* 
             Blob response type must be set by axios post request in actions and api errors are handled by middleware now.
             There is no other way to change the download file name in a blob so Steven's solution saveFile() is good.
             But this requires backend to set 'Access-Control-Expose-Headers' to expose'content-disposition' header where the file name is extracted from
             otherwise browser CORS rules will remove it.
          */
          if (status === 200) {
            const fileName = headers['content-disposition'].split('filename=')[1].split('.zip')[0];
            saveFile(data, fileName);
          }
          else if (status === 400) {
            // Since this API call mandates that the data come back formatted as a blob, the JSON data 
            // is tucked into that blob. This unwraps that and converts the Blob to JSON.
            const fr = new FileReader();
            fr.onload = (e) => {
              const jsonArray = JSON.parse(e.target.result);
              jsonArray.forEach(error => this.props.notificationShow(error.Message, 'error'));
            };
            fr.readAsText(data);
          }

        })
        .catch(() => null)
        .finally(() => this.setState({ downloadLoading: false }));
    }
  }

  enabledProvidersGet(providers) {
    return providers.filter(provider => provider.enabled);
  }

  languageCompose() {
    const config = languageConfig[this.state.selectedProvider];
    if (config) {
      return (
        <div className={styles.PortfolioDataDownload_providerLanguage}>
          The {config.interfaceName} allows you to import your clients&apos; account information into {config.toolName}.
          Click the button below to download several files bundled into one importable &quot;.zip&quot; archive that contains relevant account data as of a specified date.
        </div>
      );
    }

    return null;
  }

  previousBusinessDayGet(dayjsDate) {
    if (dayjsDate.day() === 0 || dayjsDate.day() === 1) {
      return dayjsDate.subtract(dayjsDate.day() === 1 ? 3 : 2, 'day');
    }

    return dayjsDate.subtract(1, 'day');
  }

  shouldDisableDate(date) {
    return date.day() === 0 || date.day() === 6;
  }

  componentDidMount() {
    if (this.props.providers.length === 0) {
      this.setState({ loading: true });
      this.props.portfolioProvidersGet()
        .then(() => {
          const enabledProviders = this.enabledProvidersGet(this.props.providers);
          this.setState({
            loading: false,
            selectedProvider: enabledProviders.length === 1 ? enabledProviders[0].id : '',
          });
        })
        .catch(() => this.setState({ loading: false }));
    }
    else {
      const enabledProviders = this.enabledProvidersGet(this.props.providers);
      if (enabledProviders.length === 1) {
        this.setState({
          selectedProvider: enabledProviders[0].id,
        });
      }
    }
  }

  render() {
    const { loading, downloadLoading, selectedProvider, startDate, endDate, errors } = this.state;
    const { providers, oldestSelectableDate } = this.props;
    const enabledProviders = this.enabledProvidersGet(providers);
    const yesterday = dayjs().subtract(1, 'day');

    if (loading) {
      return (
        // There are no CSS styles for PortfolioDownload_container
        <div className='PortfolioDataDownload_container'>
          <LoadingOverlay
            show={loading}
            width='100%'
            indicatorHeight='10px'
          />
        </div>
      );
    }

    /* eslint-disable indent */
    return (
      <div className={styles.PortfolioDataDownload_page}>
        <Card
          title='Download Portfolio Data'
          className={styles.PortfolioDataDownload_card}
        >
          {enabledProviders.length > 1
            ?
            <RadioGroup
              className={styles.PortfolioDataDownload_radioButtons}
              name='providersSelector'
              value={selectedProvider}
              onChange={(e, selectedProvider) => this.setState({ selectedProvider })}
            >
              {enabledProviders.map(provider => (
                <FormControlLabel
                  key={provider.id}
                  value={provider.id}
                  label={provider.name}
                  control={<Radio disableRipple disabled={downloadLoading} />}
                />
              ))}
            </RadioGroup>
            :
            enabledProviders.length === 1 && (<div className={styles.PortfolioDataDownload_singleProvider}>{enabledProviders[0].name}</div>)
          }
          {this.languageCompose()}
          <div className={styles.PortfolioDataDownload_datePickers}>
            <DatePicker
              name='startDate'
              label='Start Date'
              value={startDate}
              onChange={startDate => this.setState({ startDate: startDate ? startDate.toDate() : null, errors: {} })}
              shouldDisableDate={this.shouldDisableDate}
              minDate={oldestSelectableDate}
              maxDate={yesterday}
              error={Boolean(errors.startDate)}
              helperText={errors.startDate}
              disabled={downloadLoading}
            />
            <DatePicker
              name='endDate'
              label='End Date'
              value={endDate}
              onChange={endDate => this.setState({ endDate: endDate ? endDate.toDate() : null, errors: {} })}
              shouldDisableDate={this.shouldDisableDate}
              minDate={oldestSelectableDate}
              maxDate={yesterday}
              error={Boolean(errors.endDate)}
              helperText={errors.endDate}
              disabled={downloadLoading}
            />
          </div>
          <div className={styles.PortfolioDataDownload_downloadButton}>
            <LoadingOverlay show={downloadLoading} >
              <Button
                variant='contained'
                onClick={this.downloadDataHandle}
                disabled={!selectedProvider || downloadLoading}
              >
                Download
              </Button>
            </LoadingOverlay>
          </div>
        </Card>
      </div>
    );
    /* eslint-enable indent */
  }
}

export default connect(select, {
  portfolioProvidersGet,
  portfolioDataDownload,
  notificationShow,
})(PortfolioDataDownload);
