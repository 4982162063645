/*
*
* PortfolioDataCard Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep, findIndex, isEqual } from 'lodash';

import { portfolioProvidersUpdate } from '../../actions';

import {
  Card,
  LoadingOverlay,
  notificationShow,
} from '@frontend/common';
import { Button } from '@mui/material';
import ProviderTile from '../ProviderTile';

import styles from './styles.module.css';


export class PortfolioDataCard extends React.Component {

  static propTypes = {
    portfolioProviders: PropTypes.array.isRequired,
    afterSave: PropTypes.func.isRequired,
    portfolioProvidersUpdate: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
  };

  state = {
    editing: false,
    providers: [],
    loading: false,
  };

  onTileClick = (provider) => {
    const updatedProviders = cloneDeep(this.state.providers);
    const index = findIndex(updatedProviders, { id: provider.id });
    if (index > -1) {
      updatedProviders[index].enabled = !updatedProviders[index].enabled;
    }

    this.setState({
      providers: updatedProviders,
    });
  }

  selectedProvidersGet = () => {
    return this.state.providers.filter(provider => provider.enabled);
  }

  updateProvidersHandle = () => {
    this.setState({ loading: true });
    const selectedProviderCodes = this.selectedProvidersGet().map(provider => provider.id);
    this.props.portfolioProvidersUpdate(selectedProviderCodes)
      .then(() => {
        this.props.notificationShow('Portfolio Providers updated.', 'success');
        this.setState({
          editing: false,
          loading: false,
          providers: this.props.portfolioProviders,
        }, () => {
          this.props.afterSave(this.selectedProvidersGet());
        });
      })
      .catch(() => this.setState({ loading: false }));
  }

  componentDidMount() {
    this.setState({
      providers: this.props.portfolioProviders,
    });
  }

  render() {
    const { editing, providers, loading } = this.state;

    /* eslint-disable indent */
    return (
      <Card
        title='Portfolio Data Providers'
        className={styles.PortfolioDataCard_card}
      >
        <div className={styles.PortfolioDataCard_tiles}>
          {providers.map(provider => {
            return (
              <ProviderTile
                key={provider.id}
                provider={provider}
                editing={editing}
                onClick={this.onTileClick}
              />
            );
          })}
        </div>
        <div className={styles.PortfolioDataCard_buttons}>
          {editing
            ? [
                <Button
                  key='CancelButton'
                  variant='text'
                  onClick={() => this.setState({ editing: false, providers: this.props.portfolioProviders })}
                  disabled={loading}
                >
                  Cancel
                </Button>,
                <LoadingOverlay
                  key='SaveButton'
                  show={loading}
                  indicatorHeight='5px'
                >
                  <Button
                    variant='contained'
                    onClick={this.updateProvidersHandle}
                    disabled={isEqual(providers, this.props.portfolioProviders) || loading}
                  >
                    Save
                  </Button>
                </LoadingOverlay>,
              ]
            : <Button
                variant='contained'
                onClick={() => this.setState({ editing: true })}
              >
                Edit
              </Button>
          }
        </div>
      </Card>
    );
    /* eslint-enable indent */
  }
}

export default connect(null, { portfolioProvidersUpdate, notificationShow })(PortfolioDataCard);
