/*
*
* BulkStatementDownload Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '@mui/material';

import {
  Card,
  LoadingOverlay,
  notificationShow,
} from '@frontend/common';

import { 
  bulkStatementsAvailableGet,
  bulkStatementsDownloadGet,
} from './actions';

import { saveFile } from 'utils/helpers/files';

import styles from './styles.module.css';

const select = (state) => ({
  claims: state.session.claims,
});

export class BulkStatementDownload extends React.Component {
  static propTypes = {
    bulkStatementsAvailableGet: PropTypes.func.isRequired,
    bulkStatementsDownloadGet: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
  };

  state = {
    areStatementsAvailable: true,
    isLoading: false,
  };

  downloadBulkStatements = () => {
    this.setState({ isLoading: true });
    this.props.bulkStatementsAvailableGet()
      .then(response => {
        this.setState({ areStatementsAvailable: response.payload.data }, () => {
          if (response.payload.data) {
            this.props.notificationShow('Starting download', 'success');
            this.props.bulkStatementsDownloadGet()
              .then(({ payload: { data, headers, status } }) => {
                if (status === 200) {
                  const fileName = headers['content-disposition'].split('filename=')[1].split('.zip')[0];
                  saveFile(data, fileName);
                }
              });
          }
          else {
            this.props.notificationShow('No statements available', 'error');
          }
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { areStatementsAvailable, isLoading } = this.state;

    return (
      <div className={styles.BulkStatementDownload_container}>
        <Card className={styles.BulkStatementDownload_cardContainer} title='Bulk Statement Download'>
          <div className={styles.BulkStatementDownload_cardBody}>
            Click here to download the most recent quarterly statements for your firm&apos;s linked client accounts.
            Please note that these statements are generated on demand and may be formatted differently from the statement your client receives.
          </div>
          <div className={styles.BulkStatementDownload_cardActions}>
            <LoadingOverlay show={isLoading} width='100%'>
              <Button
                disabled={!areStatementsAvailable}
                fullWidth
                onClick={this.downloadBulkStatements}
                variant='contained'
              >
                Download
              </Button>
            </LoadingOverlay>
          </div>
        </Card>
      </div>
    );
  }
}

export default connect(select, {
  bulkStatementsAvailableGet,
  bulkStatementsDownloadGet,
  notificationShow,
})(BulkStatementDownload);
