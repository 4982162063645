/*
*
* PersistentDrawer Component
*
*/
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  Divider,
  Icon,
  Drawer,
  IconButton,
  List,
  ListItem,
} from '@mui/material';

import {
  sizify,
} from '@frontend/common';

import styles from './styles.module.css';

export class LeftMenuItem extends React.Component {
  static displayName = 'LeftMenuItem';
  render() {
    return this.props.children;
  }
}

export class LeftMenuItems extends React.Component {
  static displayName = 'LeftMenuItems';
  render() {
    return this.props.children;
  }
}

export class Row extends React.Component {
  static displayName = 'Row';
  render() {
    return this.props.children;
  }
}

export class RightDrawer extends React.Component {
  static displayName = 'RightDrawer';
  render() {
    return this.props.children;
  }
}

export class PersistentDrawers extends React.Component {

  static propTypes = {
    drawerWidth: PropTypes.string.isRequired,
    size: PropTypes.shape({
      windowWidth: PropTypes.number.isRequired,
    }).isRequired,
    marginTop: PropTypes.string,
  };

  state = {
    rowsOpen: [],
  };

  handleDrawerToggle = (drawerIndex) => {
    // first close all drawers
    const rowsOpen = this.state.rowsOpen.map(() => false);
    rowsOpen[drawerIndex] = !this.state.rowsOpen[drawerIndex];
    this.setState({ rowsOpen });
  };

  isDrawerOpen = () => this.state.rowsOpen.reduce((rowOpen, isOneOpen) => rowOpen || isOneOpen, false);

  componentDidMount() {
    const rowsOpen = [];
    this.props.children
      .filter((child) => child)
      .filter((child) => child.type.displayName === 'Row')
      .map(() => rowsOpen.push(false));
    this.setState({ rowsOpen });
  }

  render() {
    const { size, drawerWidth, children, marginTop } = this.props;

    let isDrawerFullWidth = false;
    let drawerWidthCalculated = drawerWidth;
    if (size.windowWidth < 650) {
      drawerWidthCalculated = '100%';
      isDrawerFullWidth = true;
    }
    const mainMarginRight = this.isDrawerOpen() ? drawerWidthCalculated : '0px';

    const rows = children
      .filter((child) => child)
      .filter((child) => child.type.displayName === 'Row');

    const leftMenuItems = rows
      .map((row) => row.props.children
        .filter((child) => child)
        .find(child => child.type.displayName === 'LeftMenuItems'));

    const drawers = rows
      .map((row) => row.props.children
        .filter((child) => child)
        .find((child) => child.type.displayName === 'RightDrawer'));

    return (
      <div>
        <main
          style={{ marginRight: `${mainMarginRight}`, transition: 'margin-right 0.2s', transitionTimingFunction: 'linear' }}
        >
          <List component='nav' aria-label='My Info'>
            {
              leftMenuItems.map((items, itemsIndex) => {
                return (
                  <Fragment key={`items_${itemsIndex}`}>
                    <ListItem
                      button
                      onClick={() => this.handleDrawerToggle(itemsIndex)}
                      className={styles.row}
                      disableGutters={isDrawerFullWidth}
                    >
                      <div className={styles.forMobile}>
                        {items.props.children
                          .filter(child => child.type.displayName === 'LeftMenuItem')
                          .map((leftMenuItem, itemIndex) => {
                            const firstColStyle = itemIndex === 0 ? styles.title : '';
                            return (
                              <div key={`item_${itemIndex}`} className={`${styles.col} ${firstColStyle}`}>
                                {leftMenuItem.props.children}
                              </div>
                            );
                          })
                        }
                      </div>
                      
                      {<div className={`${styles.col} ${styles.arrow} ${styles.end}`}>
                        <Icon>{this.state.rowsOpen[itemsIndex] ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}</Icon>
                      </div>}
                    </ListItem>
                    <Divider />
                  </Fragment>
                );
              })
            }
          </List>
        </main>
        <div>
          <div>
            {
              drawers.map((rightDrawer, index) => (
                <Fragment key={`row_${index}`}>
                  <Drawer
                    variant='persistent'
                    anchor='right'
                    open={this.state.rowsOpen[index]}
                    PaperProps={{
                      style: { width: drawerWidthCalculated, height: `calc(100% - ${marginTop})`, marginTop } // 
                    }}
                  >
                    <div className={styles.header}>
                      {isDrawerFullWidth &&
                        <div className={`${styles.closeButtonArrow}`}>
                          <IconButton
                            aria-label='close'
                            onClick={() => this.handleDrawerToggle(index)}
                          >
                            <Icon>keyboard_arrow_left</Icon>
                          </IconButton>
                        </div>
                      }
                      <div className={`${styles.drawerHeaderItem}`}>
                        {rightDrawer.props.title}
                      </div>
                      <div className={`${styles.closeButton}`}>
                        <IconButton
                          aria-label='close'
                          onClick={() => this.handleDrawerToggle(index)}
                        >
                          <Icon>close</Icon>
                        </IconButton>
                      </div>
                    </div>
                    <Divider />
                    <div className={styles.drawerContent}>
                      {rightDrawer}
                    </div>
                  </Drawer>
                </Fragment>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default sizify(PersistentDrawers);
