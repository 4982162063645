module.exports = {
  1: '/providers/BAMAdvisorServices.png',
  3: '/providers/OrionAdvisorServices.png',
  6: '/providers/EnvestnetTamarac.png',
  8: '/providers/BlackDiamondPerformanceReporting.png',
  10: '/providers/InteractiveAdvisorySoftware.png',
  12: '/providers/Assetbook.png',
  15: '/providers/eMoney.png',
  22: '/providers/BackOfficeSupportService.png',
  25: '/providers/Addepar.png',
  27: '/providers/Advyzon.png',
  29: '/providers/RightCapital_Logo.png',
  34: '/providers/PanoramixLogoSquare.png',
  38: '/providers/SummitLogo.png',
  42: '/providers/Capitect.png',
  PortfolioCenter: '/portfolioFormats/PortfolioCenter.png',
  Morningstar: '/portfolioFormats/Morningstar.png',
};