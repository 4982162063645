import React from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  Typography,
} from '@mui/material';
import { IconBtnTooltip } from '@frontend/common';

import styles from './styles.module.css';

export function LearnMoreDrawer({ open, onClose, currentYear, annualGiftExclusionAmount, maximumBeneficiaryDeposit, maximumBenficiaryAccountValueForGift }) {

  const GiftingLanguage = {
    learn_more_title: 'Gifting Program Dashboard',
    text_learn_more: 'You can have my529 set up Gift Codes for you to allow family and friends to contribute to your account(s).',
    text_faq(currentYear, annualGiftExclusionAmount, maximumBeneficiaryDeposit, maximumBenficiaryAccountValueForGift) {
      return [
        {
          question: 'What is the my529 Gift Program?',
          answer: 'The my529 Gift Program is an easy, free service that allows account owners to invite family and friends to contribute to their my529 college savings account.'
        },
        {
          question: 'How do I invite family and friends to participate in my529\'s Gift Program?',
          answer: 'To give family and friends the opportunity to contribute to your my529 account, please enroll in my529\'s Gift Program through Account Access and select Gift Program. When you enroll, you will receive a unique gift code and a URL that links to your personal gift page. When shared with family and friends, the gift code and link ensure the proper deposit of any gift contributions, without disclosing your personal my529 account information. Anyone with your gift code will be able to access your gift page. As the account owner, you decide with whom you\'d like to share the link. You may share this gift code or link by email, in person, by phone, or by mail. my529 also provides links and default messages to share your gift page using Twitter and Facebook. You may copy and use the URL on your other social media sites as well. You may also turn off access to your page in your my529 Gift Program dashboard.'
        },
        {
          question: 'Do friends and family need to have a my529 account to contribute to my account?',
          answer: 'No. Anyone may contribute to your my529 account as long as he or she has your my529 gift code or link to your gift page.'
        },
        {
          question: 'What if I don\'t have someone\'s email address?',
          answer: 'You may also pass along your gift code in person, by mail, or by phone.'
        },
        {
          question: 'How will my friends and family give the gift?',
          answer: 'They may give a gift in two ways—online or by mail. When making an electronic contribution, the giver provides a bank routing and checking account number through your secure online my529 gift page. When making a payment by mail, the giver prints a deposit slip found on your gift page and mails a paper check to my529.'
        },
        {
          question: 'When people give gifts, do they have access to my my529 account information?',
          answer: 'No. When someone gives a gift, the gift code or gift link ensures that the funds will be directed to your my529 account without sharing your account number in the process.'
        },
        {
          question: 'How will I know if I\'ve received a gift?',
          answer: 'my529 will notify you by email when a gift posts to your account. You will be able to view the gift online in Account Access usually within three days after it has posted to your account, and it will be included in your account balance.'
        },
        {
          question: 'May I specify my unborn baby as a beneficiary on my account to receive gifts before the baby is born?',
          answer: 'Because a U.S. Social Security Number is required for all beneficiaries, your unborn baby cannot yet be named as the beneficiary on the account. You may, however, name yourself as the beneficiary until the baby is born. When your baby is born, simply change the beneficiary on your account to your baby\'s name using a Beneficiary Change/Correction form (form 510). The new beneficiary must be a "member of the family" of the current beneficiary, as defined in the my529 Program Description. Please note that contributions made to accounts before the baby\'s birth, to a beneficiary age 19 or older, do not qualify for the Utah state income tax credit.'
        },
        {
          question: 'May I return a gift made to my my529 account?',
          answer: 'No. Gifts made to a my529 account are considered \'completed\' gifts. Once gift funds are invested in your my529 account, they blend with other contributions. Any withdrawal is treated as a distribution from your account. Returning a gift would be considered a "nonqualified withdrawal," subject to taxes and a 10 percent federal penalty tax on any earnings. To return a gift, you may want to withdraw money from a different type of account, your checking or savings account, for example, to avoid taxes.'
        },
        {
          question: 'What if I have a question about a gift made to my account?',
          answer: 'You may contact my529 toll-free at 800.418.2551 or by email at info@my529.org. Representatives are available to answer questions on regular business days from 7 a.m. to 6 p.m. MT.'
        },
        {
          question: 'Do I owe taxes on gifts made to my my529 account?',
          answer: `You should not owe taxes on gifts made to your my529 account, but people who make large gifts to your account may owe gift taxes. A gift tax or generation skipping transfer (GST) tax may occur for someone making gifts more than ${annualGiftExclusionAmount} per year to any one person. Anyone giving large monetary gifts should consult a tax advisor before sending the contributions to my529. my529 does not provide tax advice to the person giving the gift.`
        },
        {
          question: 'Does the gift count toward my my529 account balance limit?',
          answer: `Yes. For ${currentYear}, my529 will accept contributions until all my529 account balances for the same beneficiary total ${maximumBeneficiaryDeposit}, above which additional contributions are not allowed. However, there is a separate limit for gifts. When the account balance has reached ${maximumBenficiaryAccountValueForGift}, any gift contribution will be returned to the person who made it. Please see the my529 Program Description, downloadable at my529.org, for more information about contribution limits.`
        },
        {
          question: 'When are gift contributions invested?',
          answer: 'If the contribution is received in good order, it will be posted to your my529 account within three business days.'
        },
        {
          question: 'How are gift contributions allocated?',
          answer: 'Gift contributions will be allocated according to the investment option and/or allocations that you, as the account owner, have selected for your my529 account.'
        },
        {
          question: 'Are there any fees associated with giving a gift through the my529 Gift Program?',
          answer: 'No, my529 charges no fees to the giver or the account owner for giving a gift to an account. However, charges for insufficient funds may be assessed.'
        },
        {
          question: 'Will the unique gift code and URL that links to my gift page ever expire?',
          answer: 'No. Both the unique gift code and URL will be active as long as you participate in the my529 Gift Program.'
        },
        {
          question: 'Are gift contributions available for immediate withdrawal?',
          answer: 'If your account ownership with my529 is less than six months old, gift contributions made online using a bank account or using a check by mail are held for 20 business days. If your account ownership has been established for at least six months, online gift contributions are held for at least seven business days. Gift Program contributions made by debit card cannot be withdrawn until 45 business days after deposit.'
        }
      ];
    }
  };

  const learnMore = () => (
    <div className={styles.learnMoreContainer}>
      <div className={styles.closeIcon}>
        <IconBtnTooltip
          icon='close'
          onClick={onClose}
        />
      </div>
      <hr className={styles.divider} />
      <h3 className={styles.learnMoreInfoHeader}>{GiftingLanguage.learn_more_title}</h3>
      <div className={styles.learnMoreInfo}>
        {GiftingLanguage.text_learn_more}
      </div>
      {GiftingLanguage.text_faq(currentYear, annualGiftExclusionAmount, maximumBeneficiaryDeposit, maximumBenficiaryAccountValueForGift).map(qa => {
        return (
          <Accordion key={qa.question}>
            <AccordionSummary
              expandIcon={<Icon>keyboard_arrow_down</Icon>}
              aria-controls='learn-more'
              id='learn-more'
            >
              <Typography>{qa.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{qa.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );

  return (
    <Drawer anchor='bottom' open={open} onClose={onClose}>
      {learnMore()}
    </Drawer>
  );
}

LearnMoreDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentYear: PropTypes.string.isRequired,
  annualGiftExclusionAmount: PropTypes.string.isRequired,
  maximumBeneficiaryDeposit: PropTypes.string.isRequired,
  maximumBenficiaryAccountValueForGift: PropTypes.string.isRequired,
};

export default LearnMoreDrawer;
