/*
*
* Documents Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { startCase } from 'lodash';

import { getAccountBaseRoute } from 'components/Features/protected/Accounts/helpers';
import {
  accountDocumentsGet,
  downloadAccountDocumentData,
  getAccountDetails,
  getCountries
} from '../actions';

import {
  Breadcrumbs,
  TableDataTypes,
  TableColumnHideOptions,
  LoadingOverlay,
  Card,
  createStatementPdfToNewWindow,
  createCertificatePdfToNewWindow,
  createConfirmationLetterPdfToNewWindow,
  loadingAnimationHTML,
  loadingErrorHTML,
  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
  notificationShow,
  TAXFORM1099_COPY_PATHS,
  TAXFORMTC675_PATHS,
  TCFORM_ACCOUNT_TYPE_CODES,
  get1099QPdfBytes,
  getTC675HPdfBytes,
  mapPdfDataFrom1099QData,
  mapPdfDataFromTC675HData
} from '@frontend/common';

import events from 'utils/ga';

import styles from './styles.module.css';

const select = (state) => ({
  documents: state.accounts.documents,
  accountDetails: state.accounts.selectedAccount,
  DocumentTypes: state.static.environmentVars.DocumentTypes,
  countries: state.accounts.countries,
});

export class Documents extends React.Component {

  static propTypes = {
    documents: PropTypes.object.isRequired,
    accountDocumentsGet: PropTypes.func.isRequired,
    downloadAccountDocumentData: PropTypes.func.isRequired,
    accountDetails: PropTypes.object.isRequired,
    getAccountDetails: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    DocumentTypes: PropTypes.shape({
      AccountCertificate: PropTypes.string,
      QuarterlyStatement: PropTypes.string,
      FederalTaxForm: PropTypes.string,
      UtahStateTaxForm: PropTypes.string,
      AccountConfirmation: PropTypes.string,
    }).isRequired,
    getCountries: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
  };

  state = {
    accountDocs: [],
    initialLoading: false,
    downloadLoading: false,
  };

  openPdfStatement(url, documentType, pdf1099Path) {
    const { DocumentTypes, downloadAccountDocumentData, countries } = this.props;

    this.setState({ downloadLoading: true });
    const statementWindow = window.open('', '_blank'); // needs to be outside of async call to work in Safari
    statementWindow.document.write(loadingAnimationHTML); // this will get replaced by generated pdf once the api loads all data
    let apiCall;
    switch (documentType) {
      // generate statement
      case DocumentTypes.QuarterlyStatement:
        apiCall = downloadAccountDocumentData(url)
          .then(response => createStatementPdfToNewWindow(response.payload.data, statementWindow, 'Statement'));
        break;

      // generate account certificate
      case DocumentTypes.AccountCertificate:
        apiCall = downloadAccountDocumentData(url)
          .then(response => createCertificatePdfToNewWindow(response.payload.data, statementWindow));
        break;

      // generate acct upload confirmation letter
      case DocumentTypes.AccountConfirmation:
        apiCall = downloadAccountDocumentData(url)
          .then(response => createConfirmationLetterPdfToNewWindow(response.payload.data, statementWindow));
        break;

      // load tax docs
      case DocumentTypes.FederalTaxForm:
        apiCall = downloadAccountDocumentData(url)
          .then(response => {
            const pdfData = mapPdfDataFrom1099QData(response.payload.data, countries);
            try {
              return get1099QPdfBytes(pdfData, pdf1099Path)
                .then(pdfBytes => {
                  const file = new Blob([pdfBytes], { type: 'application/pdf' });
                  const fileURL = URL.createObjectURL(file);
                  statementWindow.location = fileURL; // appeases Safari where window.open is outside of async call
                });
            }
            catch (err) {
              return err;
            }
          });
        break;

      case DocumentTypes.UtahStateTaxForm:
        apiCall = downloadAccountDocumentData(url)
          .then(response => {
            try {
              const data = response.payload.data;
              const pdfData = mapPdfDataFromTC675HData(data, this.props.countries);
              let pdfTC675HPath; // this path needs to be decided after data are loaded
              switch (data.AccountType) {
                case TCFORM_ACCOUNT_TYPE_CODES.INDIVIDUAL:
                  pdfTC675HPath = TAXFORMTC675_PATHS.INDIVIDUAL_INSTRUCTIONS;
                  break;
                case TCFORM_ACCOUNT_TYPE_CODES.INSTITUTIONAL:
                  pdfTC675HPath = TAXFORMTC675_PATHS.INSTITUTIONAL_INSTRUCTIONS;
                  break;
                case TCFORM_ACCOUNT_TYPE_CODES.UGMAUTMA:
                  pdfTC675HPath = TAXFORMTC675_PATHS.UGMAUTMA_INSTRUCTIONS;
                  break;
                default: // do nothing
              }

              return getTC675HPdfBytes(pdfData, pdfTC675HPath)
                .then(pdfBytes => {
                  const file = new Blob([pdfBytes], { type: 'application/pdf' });
                  const fileURL = URL.createObjectURL(file);
                  statementWindow.location = fileURL; // appeases Safari where window.open is outside of async call
                });
            }
            catch (err) {
              return err;
            }
          });
        break;

      default:
        this.setState({ downloadLoading: false });
        this.props.notificationShow('Document type does not exist.', 'error');
    }

    // shared promise catch and finally
    apiCall && apiCall
      .catch(() => {
        // show error in a new tab by replacing its html content
        statementWindow.document.close(); // clear first
        statementWindow.document.write(loadingErrorHTML);
      })
      .finally(() => {
        this.setState({ downloadLoading: false });
      });
  }

  componentDidMount() {
    const apiCalls = [];
    const { documents, accountDetails, match: { params: { accountId } } } = this.props;

    if (this.props.countries.length === 0) {
      this.props.getCountries();
    }

    if (parseInt(accountDetails.accountId) !== parseInt(accountId)) {
      apiCalls.push(this.props.getAccountDetails(accountId));
    }

    if (documents[accountId]) {
      this.setState({
        accountDocs: documents[accountId],
      });
    }
    else {
      apiCalls.push(
        this.props.accountDocumentsGet(accountId)
          .then(() => {
            this.setState({
              accountDocs: this.props.documents[accountId],
              initialLoading: false,
            });
          })
          .catch(() => null)
      );
    }

    if (apiCalls.length > 0) {
      this.setState({ initialLoading: true });
      Promise.all(apiCalls)
        .catch(() => null)
        .finally(() => {
          this.setState({
            initialLoading: false,
          });
        });
    }
  }

  render() {
    const { initialLoading } = this.state;
    const { match, accountDetails } = this.props;

    if (initialLoading) {
      return (
        <div className={styles.Documents_page}>
          <div className={styles.Documents_container}>
            <LoadingOverlay
              show={initialLoading}
              indicatorHeight='15px'
              width='100%'
            />
          </div>
        </div>
      );
    }

    return (
      <div className={styles.Documents_page}>
        <div className={`${styles.Documents_container} hideOnPrint`}>
          <Breadcrumbs
            crumbs={[
              {
                title: 'Accounts',
                link: '/accounts'
              },
              {
                title: 'Details',
                link: getAccountBaseRoute(match.params),
              },
              {
                title: 'Documents',
              },
            ]}
          />
          <Card
            title='Account Details'
            className={styles.Documents_detailsCard}
          >
            <div className={styles.Documents_details}>
              <div
                className={styles.Documents_detailsSection}
                id={styles.Documents_details_acctOwner}
              >
                <div>Account Owner</div>
                <div>{accountDetails.accountOwner.name}</div>
              </div>
              <div
                className={styles.Documents_detailsSection}
                id={styles.Documents_details_beneficiary}
              >
                <div>Beneficiary</div>
                <div>{accountDetails.beneficiary.name}</div>
              </div>
              <div
                className={styles.Documents_detailsSection}
                id={styles.Documents_details_acctNum}
              >
                <div>Account Number</div>
                <div>{accountDetails.details.accountNumber}</div>
              </div>
              <div
                className={styles.Documents_detailsSection}
                id={styles.Documents_details_acctType}
              >
                <div>Account Type</div>
                <div>{accountDetails.details.accountType}</div>
              </div>
            </div>
          </Card>
          <LoadingOverlay
            show={this.state.downloadLoading}
            indicatorHeight='15px'
            width='100%'
          >
            <SmartTable
              idKey='id'
              rows={this.state.accountDocs}
              emptyMessage='No documents to display.'
              columns={[
                {
                  key: 'type',
                  title: 'Document Type',
                  type: TableDataTypes.STRING,
                  format: data => startCase(data),
                },
                {
                  key: 'description',
                  title: 'Description',
                  type: TableDataTypes.STRING,
                  hideOn: [TableColumnHideOptions.PHONE, TableColumnHideOptions.TABLET],
                },
                {
                  key: 'year',
                  title: 'Year',
                  type: TableDataTypes.NUMBER,
                  customStyle: { width: '.25fr' },
                },
                {
                  key: 'quarter',
                  title: 'Quarter',
                  type: TableDataTypes.NUMBER,
                  customStyle: { width: '.25fr' },
                  format: data => data ? `Q${data}` : data,
                },
              ]}
              actions={[
                {
                  displayName: 'Download',
                  type: 'icon',
                  icon: 'picture_as_pdf',
                  iconTitle: 'Open PDF',
                  onSelect: row => this.openPdfStatement(row.url, row.type, TAXFORM1099_COPY_PATHS.COPY_B_MAIL_INSTRUCTIONS),
                },
                {
                  displayName: 'View Additional Pages',
                  type: 'menu',
                  onSelect: row => this.openPdfStatement(row.additionalDocURL, row.type),
                  showIf: row => row.additionalDocURL,
                },
              ]}
            >
              <TableToolbar
                onSearchFocus={() => events.tableSearchAccessed('Documents', window.location.pathname)}
                canFilter
              />
              <TableContainer maxHeight='100%' minWidth='100%'>
                <TableHeader />
                <TableRows />
              </TableContainer>
              <TablePagination />
            </SmartTable>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

export default connect(select, {
  accountDocumentsGet,
  downloadAccountDocumentData,
  getAccountDetails,
  notificationShow,
  getCountries,
})(Documents);
