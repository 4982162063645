/*
*
* TermsAndConditions Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import styles from './styles.module.css';

export function TermsAndConditions({
  documents,
  checkboxes
}) {
  return [
    <div className={`${styles.TermsAndConditions_termsAndConditions} hideOnPrint`} key='terms&conditions'>
      <h2 className={styles.TermsAndConditions_modalHeader}>Terms and Conditions</h2>
      <h3 className={styles.TermsAndConditions_modalSubheader}>Please review the following Terms and Conditions regarding investment option changes</h3>
      <h3 className={styles.TermsAndConditions_modalSubheader}>I have informed my client of the following:</h3>

      <h5>General Information</h5>
      <ul>
        <li>For important information about each investment option, please read <a href={documents.programDescription || '#'} target='_blank' rel='noopener noreferrer'>my529's Program Description</a>.</li> {/*eslint-disable-line react/no-unescaped-entities*/}
        <li>For information regarding the various investment options, see <a href='https://my529.org/investment-options/' target='_blank' rel='noopener noreferrer'>investment information</a> at my529.org.</li>
        <li>Changing the investment option for this account will count as one investment option change. You may change your investment option for this account owner/beneficiary combination only twice per calendar year.</li>
        <li>No more than one of these accounts may be invested in the same investment option at the same time.</li>
        <li>Changing the investment option below will change the investment option for the entire balance. To transfer funds between accounts with the same beneficiary, account type, and account owner/agent, you can submit my529’s <a href={documents.form400 || '#'} target='_blank' rel='noopener noreferrer'>Internal Transfer Form 400 </a> or select Transfers in Account Access.</li> {/*eslint-disable-line react/no-unescaped-entities*/}
      </ul>

      <h5>Customized Options</h5>
      <ul>
        <li>Changing the allocation of the underlying funds within a Customized Static account is considered an investment option change.</li>
        <li>Changing the allocation of the underlying funds within a Customized Age-Based account after the initial allocation is finalized, even if the change applies to an age bracket other than the one the beneficiary is currently in, is considered an investment option change.</li>
      </ul>

      <h5>Timing</h5>
      <ul>
        <li>The investment option change will usually take effect within three business days following the day you make the investment option change.</li>
      </ul>

      <h5>Cancellation</h5>
      <ul>
        <li>To cancel or modify your investment option change request, you must do so on the same day the request was submitted, before the close of trading on the New York Stock Exchange (NYSE), usually 2 p.m. MT. If you submitted your request after the close of trading, or on a weekend or holiday, you must submit your modification or cancellation request before the close of trading on the next day the NYSE is open. Your investment option change request may not be cancelled or modified after these deadlines.</li>
      </ul>
    </div>,
    <div
      className={`${styles.TermsAndConditions_termsAndConditionsCheckbox} hideOnPrint`}
      key='termsAndConditionsCheckbox'
    >
      {
        checkboxes.map((box, index) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={box.checked}
                onChange={box.onCheck}
              />
            }
            key={index}
            label={box.label ? box.label : 'I have read and understand the above Terms and Conditions.'}
          />
        ))
      }
    </div>
  ];
}

TermsAndConditions.propTypes = {
  checkboxes: PropTypes.arrayOf(
    PropTypes.shape({
      onCheck: PropTypes.func.isRequired,
      checked: PropTypes.bool.isRequired,
      label: PropTypes.string
    }),
  ).isRequired,
  documents: PropTypes.object.isRequired,
};

export default TermsAndConditions;
