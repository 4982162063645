import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@frontend/common';

export const FullBalanceDialog = ({ show, onClose, }) => (
  <Modal
    title='Full Balance Transfers'
    show={show}
    onCloseModal={onClose}
    modal={false}
    actionButtons={[
      {
        label: 'Close',
        action: onClose
      }
    ]}
  >
    <div>
      <p>
        You have selected a full-balance transfer of the my529 account shown above. The following will result:
      </p>
      <ul>
        <li>
          The entire available balance will be transferred to the account(s) selected. The available balance includes all contributions posted to your account after at least seven business days.
        </li>
        <li>
          Funds not in the available balance include pending transactions out of the account such as:
          <ul>
            <li>Withdrawals</li>
            <li>Transfers</li>
            <li>Fees</li>
            <li>Contribution reversals</li>
            <li>Contributions that have not cleared the bank</li>
            <li>Gift program contributions</li>
          </ul>
        </li>

        <li>
          All Gift Program contributions to my529 accounts are subject to a hold and may not be available at the time of a full balance transfer, whether they are contributed directly from a bank account, from a debit card or by check.
          <ul>
            <li>
              <b>Gift Program contributions, made from a bank account or check, to my529 accounts that were established by a first-time account owner less than six months ago</b> will be held for 20 business days after the deposit.
            </li>
            <li>
              <b>Gift Program contributions, made from a bank account or check, to my529 accounts that have been established longer than six months — or from an account owner who has been with my529 over six months</b> — will be held for at least seven business days after the deposit.
            </li>
            <li>
              <b>Gift Program contributions made by debit card</b> cannot be withdrawn until 45 business days after deposit.
            </li>
          </ul>
        </li>
        <li>
          Your account will be closed unless you check the box below which says, “Leave this account open”. If you are a Utah taxpayer, closing your account may have Utah state income tax implications. Please see Part 9 of the Program Description for more information.
        </li>
        <li>
          Any scheduled electronic contributions will be cancelled unless you check the box below which says, “Leave this account open”.
        </li>
      </ul>
      <p>
        If the account owner/agent contributes to this account through payroll direct deposit and is closing the account, they must notify their employer to discontinue contributions.
      </p>
    </div>
  </Modal>
);

FullBalanceDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FullBalanceDialog;
