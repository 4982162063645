import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import {
  Button,
  Switch
} from '@mui/material';

import {
  Breadcrumbs,
  LoadingOverlay,
  Card,
  CardAligned,
  currencyFormatter,
  Modal,
} from '@frontend/common';

import Banner from 'components/Features/Banner';
import { BANNER_LOCATIONS } from 'components/AppRoot/StaticResources/constants';

import { getAccountBaseRoute } from 'components/Features/protected/Accounts/helpers';
import { editGiftCodeStatus } from 'components/Features/protected/Accounts/actions';
import { getAccountsByAgent } from 'components/Features/protected/Accounts/actions';

import LearnMore from './LearnMore';

import styles from './styles.module.css';

const select = (state) => ({
  agentAccounts: state.accounts.selectedAgentAccounts,
  currentYear: state.static.environmentVars.ServerDateTime.substring(0, 4),
  annualGiftExclusionAmount: currencyFormatter(state.static.environmentVars.AnnualGiftExclusionAmount),
  maximumBeneficiaryDeposit: currencyFormatter(state.static.environmentVars.MaximumBeneficiaryDeposit),
  maximumBenficiaryAccountValueForGift: currencyFormatter(state.static.environmentVars.MaximumBenficiaryAccountValueForGift),
  webMessages: state.static.webMessages,
});

export class Gifting extends Component {

  static propTypes = {
    editGiftCodeStatus: PropTypes.func.isRequired,
    getAccountsByAgent: PropTypes.func.isRequired,
    agentAccounts: PropTypes.array.isRequired,
    currentYear: PropTypes.string.isRequired,
    annualGiftExclusionAmount: PropTypes.string.isRequired,
    maximumBeneficiaryDeposit: PropTypes.string.isRequired,
    maximumBenficiaryAccountValueForGift: PropTypes.string.isRequired,
    webMessages: PropTypes.object.isRequired,
  };

  state = {
    initialLoading: true,
    loading: false,
    learnMoreOpen: false,
    showWarning: false,
    savingGifting: false,
    accountIdToChange: 0,
    giftStatusToChange: false,
  };

  ShowGiftWarning = (AccountId, GiftCodeActive) => {
    this.setState({
      showWarning: true,
      accountIdToChange: AccountId,
      giftStatusToChange: !GiftCodeActive,
    });
  }
  
  OnGiftActiveChange = () => {
    this.setState({ savingGifting: true, showWarning: false });
    this.props.editGiftCodeStatus(this.state.accountIdToChange, this.state.giftStatusToChange)
      .then(() => {
        this.props.getAccountsByAgent(this.props.match.params.agentId)
          .then(() => {
            this.setState({
              savingGifting: false,
            });
          });
      })
      .catch(() => {
        this.setState({ savingGifting: false });
      });
  }

  renderAccountRows() {
    const { agentAccounts } = this.props;

    const results = agentAccounts.length === 0 || this.state.loading
      ? 'Beneficiary\'s have not been configured yet.'
      : agentAccounts.map(acc => {
        const isActive = Boolean(acc.giftCodeActive);
        const statusText = isActive ? 'Enabled' : 'Disabled';
        const giftCode = isActive ? acc.giftCode : 'Inactive';
        const giftCodeColor = isActive ? '#000' : 'rgba(0,0,0,.30)';
        return (
          <div className={styles.dashRow} key={acc.accountId}>
            <div className={styles.beneficiary}>
              <p className={styles.labelTILE}>Beneficiary</p>
              <p className={styles.dataTILE}>{`${acc.beneficiaryName}`}</p>
            </div>
            <div className={styles.code}>
              <p className={styles.labelTILE}>Code</p>
              <p className={styles.dataTILE} style={{ textTransform: 'capitalize', color: giftCodeColor }}>{giftCode}</p>
            </div>
            <div className={styles.acct}>
              <p className={styles.labelTILE}>Account</p>
              <p className={styles.acctType}>{acc.acctOption}</p>
              <p className={styles.dataTILE} style={{ color: '#3399FF', letterSpacing: '3px' }}>
                <Link to={getAccountBaseRoute({ agentId: acc.agentId, groupId: acc.groupId, accountId: acc.accountId })}>{acc.accountNumber}</Link>
              </p>
            </div>
            <div className={styles.status}>
              <p className={styles.labelTILE} style={{ justifySelf: 'center' }}>Status</p>
              <LoadingOverlay show={this.state.savingGifting}>
                <Switch
                  checked={isActive}
                  onClick={() => this.ShowGiftWarning(acc.accountId, acc.giftCodeActive)}
                  value={isActive}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </LoadingOverlay>
              <p style={{ fontSize: '10px', marginTop: '3px' }}>{statusText}</p>
            </div>
          </div>
        );
      });

    return (
      results
    );
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.getAccountsByAgent(this.props.match.params.agentId)
      .then(() => {
        this.setState({ loading: false, initialLoading: false });
      });
  }

  render() {
    const {
      initialLoading,
      loading,
      learnMoreOpen,
      showWarning,
    } = this.state;
    const {
      agentAccounts,
      currentYear,
      annualGiftExclusionAmount,
      maximumBeneficiaryDeposit,
      maximumBenficiaryAccountValueForGift,
      match,
      webMessages,
    } = this.props;
    if (initialLoading) {
      return (
        <LoadingOverlay
          show={initialLoading}
          indicatorHeight='15px'
          width='100%'
        />
      );
    }
    else {
      return (
        <div className={styles.paperContainer}>
          <Banner show={Boolean(webMessages[BANNER_LOCATIONS.GIFTING_MSG])} body={webMessages[BANNER_LOCATIONS.GIFTING_MSG]} />
          <Breadcrumbs
            crumbs={[
              {
                title: 'Accounts',
                link: '/accounts'
              },
              {
                title: 'Details',
                link: getAccountBaseRoute(match.params),
              },
              {
                title: 'Gifting',
              },
            ]}
          />

          <div className={styles.GiftingAccountDetails_ownerContainer} hidden={!(agentAccounts && agentAccounts.length > 0)}>
            <Card title='Account Owner/Agent' show={!loading}>
              <div className={styles.GiftingAccountDetails_accountOwnerName}>
                {agentAccounts && agentAccounts.length > 0 ? agentAccounts[0].agentName : ''}
              </div>
              <div>
                {agentAccounts && agentAccounts.length > 0 ? agentAccounts[0].agentEmail : ''}
              </div>
            </Card>
          </div>

          <div className={styles.paperTitle}>Gifting</div>

          <CardAligned>

            <LoadingOverlay show={loading}>
              {this.renderAccountRows()}
            </LoadingOverlay>

          </CardAligned>
          <div className={styles.learnMore}>
            <Button
              variant='outlined'
              color='secondary'
              onClick={() => this.setState({ learnMoreOpen: true })}
            >
              Learn More
            </Button>
          </div>

          <LearnMore
            onClose={() => this.setState({ learnMoreOpen: false })}
            open={learnMoreOpen}
            currentYear={currentYear}
            annualGiftExclusionAmount={annualGiftExclusionAmount}
            maximumBeneficiaryDeposit={maximumBeneficiaryDeposit}
            maximumBenficiaryAccountValueForGift={maximumBenficiaryAccountValueForGift}
          />

          <Modal show={showWarning} title=''
            onCloseModal={() => this.setState({ showWarning: false })}
            actionButtons={[
              {
                action: () => this.OnGiftActiveChange(),
                label: 'Ok',
              }
            ]}
          >
            <div>Are you sure you want to make this change? The account owner will be notified via email of this change.</div>
          </Modal>

        </div>
      );
    }
  }
}

export default withRouter(connect(select, {
  editGiftCodeStatus,
  getAccountsByAgent,
})(Gifting));
