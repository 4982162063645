/*
*
* SetupData Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { backOfficesGet, portfolioProvidersGet } from '../actions';

import {
  LoadingOverlay,
  sizify,
  notificationShow,
  Modal,
} from '@frontend/common';

import ExternalProvidersCard from './ExternalProvidersCard';
import PortfolioDataCard from './PortfolioDataCard';

import styles from './styles.module.css';

const select = (state) => ({
  backOffices: state.manageData.backOffices,
  portfolioProviders: state.manageData.portfolioProviders,
});

export class SetupData extends React.Component {

  static propTypes = {
    backOffices: PropTypes.array.isRequired,
    backOfficesGet: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    portfolioProviders: PropTypes.array.isRequired,
    portfolioProvidersGet: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    showUpdateAccountsModal: false,
  };

  componentDidMount() {
    if (this.props.backOffices.length === 0 || this.props.portfolioProviders.length === 0) {
      this.setState({ loading: true });
      Promise.all([
        this.props.backOfficesGet(),
        this.props.portfolioProvidersGet(),
      ])
        .catch(() => null)
        .finally(() => this.setState({ loading: false }));
    }
  }

  render() {
    const { loading, showUpdateAccountsModal } = this.state;
    if (loading) {
      return (
        // There are no CSS styles for SetupData_loading
        <div className='SetupData_loading'>
          <LoadingOverlay
            show={loading}
            width='100%'
            indicatorHeight='10px'
          />
        </div>
      );
    }

    return (
      <div className={styles.SetupData_page}>
        <ExternalProvidersCard
          backOffices={this.props.backOffices}
          afterSave={selectedProviders => selectedProviders.length > 0 ? this.setState({ showUpdateAccountsModal: true }) : null }
        />

        <PortfolioDataCard
          portfolioProviders={this.props.portfolioProviders}
          afterSave={selectedProviders => selectedProviders.length > 0 ? this.setState({ showUpdateAccountsModal: true }) : null }
        />

        <Modal
          show={showUpdateAccountsModal}
          title='Update Accounts'
          onCloseModal={() => this.setState({ showUpdateAccountsModal: false })}
          actionButtons={[
            {
              label: 'Update',
              action: () => this.props.history.push('/manage-data/accounts'),
              buttonType: 'contained',
            },
            {
              label: 'Not Now',
              action: () => this.setState({ showUpdateAccountsModal: false, editing: false, editingStep: 0 }),
            },
          ]}
        >
          <span>Would you like to update the list of my529 accounts to be included in either the direct data feed to external service providers or the manual download?</span>
        </Modal>
      </div>
    );
  }
}

export default connect(select, {
  backOfficesGet,
  portfolioProvidersGet,
  notificationShow,
})(withRouter(sizify(SetupData)));
