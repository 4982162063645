/*
*
* Transfers Component
*
*/
import React from 'react';
import { withRouter, } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find, reduce, get, } from 'lodash';
import {
  Breadcrumbs,
  LoadingOverlay,
  notificationShow,
} from '@frontend/common';
import {
  getTransfer,
  getAccountDetails,
  getTransferAccounts,
  createNewTransfer,
  updateTransfer,
} from '../../actions';
import {
  getServerDateTime,
} from 'components/AppRoot/StaticResources/actions';
import { getAccountBaseRoute } from '../../helpers';
import AccountDetailsCard from './AccountDetailsCard';
import Transfer from './Transfer';
import {
  FullBalanceDialog,
  OptionChangeDialog,
} from './Dialogs';
import { TransferInputTypes, TransferTypes } from './Transfer/typeConstants';
import { validationErrors } from './Transfer/errorConstants';

import styles from './styles.module.css';

const select = (state, { match }) => ({
  sourceAccount: state.accounts.cache[match.params.accountId],
  destinationAccounts: state.accounts.transferAccounts[match.params.accountId] || [],
  transfer: match.params.transferId ? state.accounts.transfers[match.params.transferId] : undefined,
});

export class Transfers extends React.Component {

  static propTypes = {
    sourceAccount: PropTypes.object,
    destinationAccounts: PropTypes.array.isRequired,
    transfer: PropTypes.object,
    createNewTransfer: PropTypes.func.isRequired,
    updateTransfer: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    getTransfer: PropTypes.func.isRequired,
    getAccountDetails: PropTypes.func.isRequired,
    getTransferAccounts: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        agentId: PropTypes.string.isRequired,
        groupId: PropTypes.string.isRequired,
        accountId: PropTypes.string.isRequired,
        transferId: PropTypes.string,
      }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    getServerDateTime: PropTypes.func.isRequired,
  };

  state = {
    editing: this.props.location.pathname.includes('/edit'),
    isLoading: false,
    sourceAccount: this.props.sourceAccount,
    transfer: this.props.transfer !== undefined
      ?
      {
        ...this.props.transfer,
        transferType: this.props.transfer.Percentage < 100 ? TransferTypes.partial : TransferTypes.full,
        transferInputType: this.props.transfer.Percentage > 0 ? TransferInputTypes.Percentage : TransferInputTypes.Amount,
      }
      :
      {
        AccountId: parseInt(this.props.match.params.accountId),
        Amount: 0,
        Percentage: 0,
        CloseAccount: true,
        TargetAccounts: [{ AccountId: '', Amount: 0, Percentage: 0 }],
        transferType: TransferTypes.partial,
        transferInputType: TransferInputTypes.Amount,
      },
    runValidation: false,
    errors: [],
    isLoadingSubmit: false,
    showOptionChangeModal: false,
    showFullBalanceDialog: false,
    hasSeenFullBalanceDialog: false,
    serverDateTime: null,
  };

  setupState = () => {
    const { transfer, sourceAccount } = this.props;

    if (this.state.editing) {
      const newTransfer = {
        AccountId: transfer.AccountId,
        Amount: get(transfer, 'Amount', 0),
        Percentage: get(transfer, 'Percentage', 0),
        CloseAccount: get(transfer, 'CloseAccount', true),
        TargetAccounts: get(transfer, 'TargetAccounts', []).map(target => ({
          AccountId: target.AccountId,
          Amount: get(target, 'Amount', 0),
          Percentage: get(target, 'Percentage', 0),
        }))
      };
      this.setState({
        sourceAccount: {
          ...sourceAccount,
          details: {
            ...sourceAccount.details,
            netAvailable: transfer.TargetAccounts.reduce((sum, account) => sum + account.Amount, sourceAccount.details.netAvailable)
          }
        },
        isLoading: false,
        transfer: {
          ...newTransfer,
          transferType: newTransfer.Percentage < 100 ? TransferTypes.partial : TransferTypes.full,
          transferInputType: newTransfer.Percentage > 0 ? TransferInputTypes.Percentage : TransferInputTypes.Amount,
        },
        hasSeenFullBalanceDialog: newTransfer.Percentage === 100,
      });
    }
    else {
      this.setState({
        sourceAccount: this.props.sourceAccount,
        isLoading: false,
      });
    }
  };

  transfersDetailsGet = () => {
    const { accountId, transferId } = this.props.match.params;
    const { getTransfer, getAccountDetails, getTransferAccounts, transfer, sourceAccount, destinationAccounts } = this.props;
    if ((this.state.editing && !transfer) || !sourceAccount || destinationAccounts.length === 0) {
      this.setState({ isLoading: true });
    }
    return Promise.all([
      this.state.editing && getTransfer(transferId),
      getAccountDetails(accountId),
      getTransferAccounts(accountId),
    ])
      .catch(() => null)
      .finally(() => {
        this.setupState();
      });
  };

  prepareTransferPayload = () => {
    const {
      transfer,
    } = this.state;
    const targets = transfer.TargetAccounts.filter(account => account.AccountId !== '' &&
      ((transfer.transferType === TransferTypes.partial && account[transfer.transferInputType] !== 0) || (transfer.transferType === TransferTypes.full && account.Percentage > 0))
    );
    const transferPayload = {
      AccountId: transfer.AccountId,
      TargetAccounts: targets.map(account => {
        const newAccount = {
          AccountId: account.AccountId,
        };
        if (transfer.transferType === TransferTypes.partial && transfer.transferInputType === TransferInputTypes.Amount) {
          newAccount.Amount = account.Amount;
        }
        else {
          newAccount.Percentage = account.Percentage;
        }
        return newAccount;
      }),
    };
    if (this.props.transfer !== undefined) {
      transferPayload.TradeDate = this.props.transfer.TradeDate;
      transferPayload.TransferId = this.props.transfer.TransferId;
    }

    if (transfer.transferType === TransferTypes.full) {
      transferPayload.CloseAccount = transfer.CloseAccount;
    }
    return transferPayload;
  };

  editTransfer = () => {
    return this.props.updateTransfer(this.prepareTransferPayload())
      .then(() => {
        this.props.notificationShow('Transfer updated.', 'success');
        // get server timestamp by returning promise here
        return this.props.getServerDateTime()
          .then((response) => {
            const serverDateTime = response.payload.data;
            this.setState({ serverDateTime });
          });
      });
  };

  createNewTransfer = () => {
    return this.props.createNewTransfer(this.prepareTransferPayload())
      .then(() => {
        this.props.notificationShow('Transfer created.', 'success');
        // get server timestamp by returning promise here
        return this.props.getServerDateTime()
          .then((response) => {
            const serverDateTime = response.payload.data;
            this.setState({ serverDateTime });
          });
      });
  };

  showFullBalanceDialog = () => {
    this.setState({
      showFullBalanceDialog: true,
    });
  };

  stateChangeValidationWrapper = (stateChanges) => {
    if (this.state.runValidation) {
      this.setState({
        errors: this.transferFormErrors({
          ...this.state,
          ...stateChanges
        }),
        ...stateChanges
      });
    }
    else {
      this.setState(stateChanges);
    }
  };

  onTransferChange = (key, value) => {
    const { transfer } = this.state;
    switch (key) {
      case 'transferType': {
        this.transferTypeToggle();
        break;
      }
      case 'transferInputType': {
        this.stateChangeValidationWrapper({
          transfer: {
            ...transfer,
            transferInputType: transfer.transferInputType === TransferInputTypes.Amount ? TransferInputTypes.Percentage : TransferInputTypes.Amount,
          }
        });
        break;
      }
      case 'CloseAccount': {
        this.stateChangeValidationWrapper({
          transfer: {
            ...transfer,
            CloseAccount: !transfer.CloseAccount,
          }
        });
        break;
      }
      default: {
        this.stateChangeValidationWrapper({
          transfer: {
            ...transfer,
            [key]: value,
          }
        });
      }
    }
  };

  onTargetChange = (targetAccountIndex) => {
    return (nextTarget) => {
      const { AccountId } = nextTarget;
      const { destinationAccounts, } = this.props;
      const { sourceAccount, transfer } = this.state;
      let showOptionChangeModal = false;
      if (transfer.TargetAccounts[targetAccountIndex].AccountId !== AccountId) {
        const account = find(destinationAccounts, { AccountId });
        if (account) {
          showOptionChangeModal = account.accountGroupId === sourceAccount.accountGroupId;
        }
      }
      this.stateChangeValidationWrapper({
        transfer: {
          ...transfer,
          TargetAccounts: transfer.TargetAccounts.map((account, index) => {
            if (targetAccountIndex === index) {
              return nextTarget;
            }
            return account;
          })
        },
        showOptionChangeModal,
      });
    };
  };

  addTargetAccount = () => {
    const { transfer: { TargetAccounts, ...other } } = this.state;
    this.stateChangeValidationWrapper({
      transfer: {
        ...other,
        TargetAccounts: [
          ...TargetAccounts,
          { AccountId: '', Amount: 0, Percentage: 0 }
        ]
      }
    });
  };

  removeTargetAccount = index => {
    const { transfer: { TargetAccounts, ...other } } = this.state;
    this.stateChangeValidationWrapper({
      transfer: {
        ...other,
        TargetAccounts: TargetAccounts.filter((_, rowIndex) => rowIndex !== index)
      }
    });
  };

  transferTypeToggle = () => {
    const {
      transfer,
      hasSeenFullBalanceDialog,
    } = this.state;
    const newTransferType = transfer.transferType === TransferTypes.partial ? TransferTypes.full : TransferTypes.partial;
    this.stateChangeValidationWrapper({
      transfer: {
        ...transfer,
        transferType: newTransferType,
        transferInputType: transfer.transferType === TransferTypes.partial ? TransferInputTypes.Percentage : transfer.transferInputType,
        TargetAccounts: newTransferType === TransferTypes.full && !transfer.TargetAccounts.some(target => target.Percentage > 0) ?
          transfer.TargetAccounts.map((target, index) => {
            // transfer type is being changed to full, so set percentage on first destination to 100%
            if (index === 0 && transfer.transferType === TransferTypes.partial && target.Percentage !== 100) {
              return {
                ...target,
                Percentage: 100
              };
            }
            return target;
          }) : transfer.TargetAccounts,
      },
      showFullBalanceDialog: !hasSeenFullBalanceDialog,
    });
  };

  canContinue = (onContinue) => {
    const errors = this.transferFormErrors();
    this.setState({
      errors,
      runValidation: true,
    });
    return errors.length === 0 && ((onContinue && onContinue()) || true);
  };

  transferFormErrors = (nextState) => {
    const {
      transfer,
      sourceAccount,
    } = nextState || this.state;
    const errors = [];
    const totalPercentage = reduce(transfer.TargetAccounts, (sum, account) => sum + account.Percentage, 0);
    const totalAmount = reduce(transfer.TargetAccounts, (sum, account) => sum + account.Amount, 0);

    // total field
    if (transfer.transferType === TransferTypes.full) {
      // must equal 100% allocation
      if (totalPercentage !== 100) {
        errors.push({
          key: 'Transfer_amountTotal',
          message: validationErrors.fullBalanceNotAllocated,
        });
      }
    }
    else {
      if (transfer.transferInputType === TransferInputTypes.Percentage && totalPercentage > 99) {
        errors.push({
          key: 'Transfer_amountTotal',
          message: validationErrors.partialBalancePercentOverMax
        });
      }
      else if (transfer.transferInputType === TransferInputTypes.Percentage && totalPercentage === 0) {
        errors.push({
          key: 'Transfer_amountTotal',
          message: validationErrors.partialBalancePercentUnderMinimum
        });
      }
      else if (transfer.transferInputType === TransferInputTypes.Amount) {
        if (totalAmount > sourceAccount.details.netAvailable) {
          errors.push({
            key: 'Transfer_amountTotal',
            message: validationErrors.totalGreaterThanAvailable,
          });
        }
        if (totalAmount < 1) {
          errors.push({
            key: 'Transfer_amountTotal',
            message: validationErrors.totalUnderMinimum,
          });
        }
      }
    }

    // only 1 row
    if (transfer.TargetAccounts.length === 1) {
      //  destination account isn't selected
      if (transfer.TargetAccounts[0].AccountId === '') {
        errors.push({
          key: 'Transfer_destinationContainer-0',
          message: validationErrors.destinationAccountMissing,
        });
      }
    }

    transfer.TargetAccounts.forEach((account, index) => {
      // destination has amount/percentage filled in, but no account selected
      if ((
        (transfer.transferType === TransferTypes.full && account.Percentage > 0) ||
        (transfer.transferType === TransferTypes.partial && (
          (transfer.transferInputType === TransferInputTypes.Amount && account.Amount > 0) ||
          (transfer.transferInputType === TransferInputTypes.Percentage && account.Percentage > 0)
        ))
      ) && account.AccountId === '') {
        errors.push({
          key: `Transfer_destinationContainer-${index}`,
          message: validationErrors.destinationAccountMissing,
        });
      }
    });
    return errors;
  };

  componentDidMount() {
    this.transfersDetailsGet();
  }

  render() {
    const {
      match,
      location,
      destinationAccounts
    } = this.props;
    const {
      isLoading,
      sourceAccount,
      editing,
      transfer,
      serverDateTime,
    } = this.state;
    return (
      <div className={styles.Transfers_transfersContainer}>
        <div className={`${styles.Transfers_pageNav} hideOnPrint`}>
          {(location.pathname.indexOf('new') > -1 || location.pathname.indexOf('edit') > -1) &&
            <Breadcrumbs
              crumbs={[
                {
                  title: 'Accounts',
                  link: '/accounts'
                },
                {
                  title: 'Details',
                  link: getAccountBaseRoute(match.params),
                },
                { title: `${location.pathname.indexOf('new') > -1 ? 'New' : 'Edit Scheduled'} Transfer` },
              ]}
            />}
        </div>
        <div key='Transfers_detailsContainer' className={styles.Transfers_detailsContainer}>
          <LoadingOverlay
            show={isLoading}
            width='100%'
            indicatorHeight='15px'
          >
            <AccountDetailsCard
              account={sourceAccount}
            />
          </LoadingOverlay>
        </div>
        <div key='Transfers_children' className={styles.Transfers_children}>
          <LoadingOverlay
            show={isLoading}
            width='100%'
            indicatorHeight='15px'
          >
            <Transfer
              errors={this.state.errors}
              canContinue={this.canContinue}
              cardTitle={editing ? 'Edit Scheduled Transfer' : 'Create Transfer'}
              showFullBalanceDialog={this.showFullBalanceDialog}
              transfer={transfer}
              onTransferChange={this.onTransferChange}
              onTargetChange={this.onTargetChange}
              addTargetAccount={this.addTargetAccount}
              removeTargetAccount={this.removeTargetAccount}
              sourceAccount={sourceAccount}
              destinationAccounts={destinationAccounts}
              save={editing ? this.editTransfer : this.createNewTransfer}
              serverDateTime={serverDateTime}
            />
          </LoadingOverlay>
        </div>
        <FullBalanceDialog
          show={this.state.showFullBalanceDialog}
          onClose={() => {
            this.setState({
              showFullBalanceDialog: false,
              hasSeenFullBalanceDialog: true,
            });
          }}
        />
        <OptionChangeDialog
          show={this.state.showOptionChangeModal}
          onClose={() => {
            this.setState({ showOptionChangeModal: false });
          }}
        />
      </div>
    );
  }
}

export default withRouter(connect(select, {
  createNewTransfer,
  updateTransfer,
  notificationShow,
  getAccountDetails,
  getTransferAccounts,
  getTransfer,
  getServerDateTime,
})(Transfers));
