/*
*
* DashboardMetricsToolbar Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, sizify } from '@frontend/common';
import { Tooltip } from '@mui/material';
import { selectedBeneficiaryBarChartType } from '../constants';

import styles from './styles.module.css';

const select = (state) => ({
  selectedBeneficiaryBarChart: state.dashboardSettings.selectedBeneficiaryBarChart,
});

export class DashboardMetricsToolbar extends React.Component {
  static propTypes = {
    size: PropTypes.shape({
      windowWidth: PropTypes.number
    }),
    selectedBeneficiaryBarChart: selectedBeneficiaryBarChartType,
    allAccountMetrics: PropTypes.object,
    accountsByAge: PropTypes.array,
    accountsByAgeBand: PropTypes.array,
    activeIndex: PropTypes.number,
    metricConfigs: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
    })),
    history: PropTypes.shape({
      push: PropTypes.func
    })
  };

  state = {
    cardHovering: ''
  };

  onMouseEnterCard = (key) => {
    this.setState({
      cardHovering: key
    });
  };

  onMouseLeaveCard = () => {
    this.setState({
      cardHovering: ''
    });
  };

  onClickCard = (search) => {
    this.props.history.push({
      pathname: '/accounts',
      search
    });
  };

  render() {
    const {
      size,
      selectedBeneficiaryBarChart,
      allAccountMetrics,
      activeIndex = -1,
    } = this.props;
    const { cardHovering } = this.state;
    const dataNode = activeIndex === -1 ? allAccountMetrics :
      (this.props[selectedBeneficiaryBarChart] || [])[activeIndex];

    return (
      <div className={styles.DashboardMetricsToolbar_container}>
        <Card
          className={styles.DashboardMetricsToolbar_MetricCard}
          title='Assets'
        >
          {dataNode === undefined ? '' : dataNode.assets}
        </Card>
        <Card
          className={`${styles.DashboardMetricsToolbar_MetricCard} ${styles.clickable}`}
          zDepth={cardHovering === 'accounts' ? 3 : 1}
          onMouseEnter={() => this.onMouseEnterCard('accounts')}
          onMouseLeave={() => this.onMouseLeaveCard()}
          onClick={() => this.onClickCard('')}
        >
          <div className={styles.actionableTitle}>Accounts</div>
          {dataNode === undefined ? '' : dataNode.accounts}
        </Card>
        <Card
          className={styles.DashboardMetricsToolbar_MetricCard}
          title='Avg Balance'
        >
          {dataNode === undefined ? '' : dataNode.avgBalance}
        </Card>
        <Card
          className={`${styles.DashboardMetricsToolbar_MetricCard} ${styles.clickable}`}
          zDepth={cardHovering === 'upcomingBirthdays' ? 3 : 1}
          onMouseEnter={() => this.onMouseEnterCard('upcomingBirthdays')}
          onMouseLeave={() => this.onMouseLeaveCard()}
          onClick={() => this.onClickCard('?upcomingBirthdays=P30D')}
        >
          <div className={styles.actionableTitle}>Upcoming Birthdays</div>
          {size.windowWidth > 600 && (
            <Tooltip
              title={<div style={{ textAlign: 'center' }}>View accounts with beneficiary<br />birthdays in next 30 days</div>}
              position='bottom-start'
              open={cardHovering === 'upcomingBirthdays'}
              style={{ position: 'absolute', marginTop: '30px' }}
            >
              <span />
            </Tooltip>
          )}
          {dataNode === undefined ? '' : dataNode.upcomingBirthdays}
        </Card>
        <Card
          className={`${styles.DashboardMetricsToolbar_MetricCard} ${styles.clickable}`}
          zDepth={cardHovering === 'zeroBalances' ? 3 : 1}
          onMouseEnter={() => this.onMouseEnterCard('zeroBalances')}
          onMouseLeave={() => this.onMouseLeaveCard()}
          onClick={() => this.onClickCard('?zeroBalances=true')}
        >
          <div className={styles.actionableTitle}>Zero Balances</div>
          {size.windowWidth > 600 && (
            <Tooltip
              title='View zero balance accounts'
              position='bottom-start'
              open={cardHovering === 'zeroBalances'}
              style={{ position: 'absolute', marginTop: '30px' }}
            >
              <span />
            </Tooltip>
          )}
          {dataNode === undefined ? '' : dataNode.zeroBalances}
        </Card>
      </div>
    );
  }
}

export default withRouter(connect(select, {})(sizify(DashboardMetricsToolbar)));
