/*
*
* ConflictErrorResolveModal Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  resolveBirthdate,
  resolveEmail,
  resolvePOALevel,
  resolveSSN,
  resolveFedTax,
  resolveStateTax,
} from 'components/Features/protected/NewAccounts/actions';
import { errorTypes, detailedErrors } from '../constants';

import {
  Modal,
} from '@frontend/common';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import styles from './styles.module.css';


export class ConflictErrorResolveModal extends React.Component {

  static propTypes = {
    show: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    conflictErrors: PropTypes.array.isRequired,
    resolveBirthdate: PropTypes.func.isRequired,
    resolveEmail: PropTypes.func.isRequired,
    resolvePOALevel: PropTypes.func.isRequired,
    resolveSSN: PropTypes.func.isRequired,
    resolveFedTax: PropTypes.func.isRequired,
    resolveStateTax: PropTypes.func.isRequired,
    approveAccount: PropTypes.func.isRequired,
  };

  state = {
    canSave: false,
    loading: false,
  };

  conflictPickersCompile() {
    const conflicts = this.props.conflictErrors.map((error) => {
      const errorDetails = detailedErrors.find(errorDetail => errorDetail.type === error.type) || {};
      const radioButtons = (
        <RadioGroup
          name={error.type}
          onChange={(e, value) => this.onRadioButtonSelect(`resolved${error.type}`, value)}
          value={this.state[`resolved${error.type}`]}
        >
          {error.options.map(errorOption => (
            <FormControlLabel
              key={`${error.type}_${errorOption}`}
              value={errorOption}
              label={`${errorOption}`}
              control={<Radio disableRipple />}
            />
          ))}
        </RadioGroup>
      );

      return (
        <div className={styles.ConflictErrorResolveModal_conflictSection} key={error.type}>
          <small>{errorDetails.description}</small>
          <hr />
          {radioButtons}
        </div>
      );
    });

    return (
      <div className={styles.ConflictErrorResolveModal_conflictsContainer}>
        <div>Select the correct information below to resolve discrepancies between uploaded accounts.</div>
        {conflicts}
      </div>
    );
  }

  onRadioButtonSelect(key, value) {
    this.setState({
      [key]: value,
    }, () => {
      const canSave = this.props.conflictErrors.every(error => {
        return this.state[`resolved${error.type}`];
      });
      this.setState({ canSave });
    });
  }

  onSave = () => {
    this.setState({ loading: true });
    const { conflictErrors } = this.props;
    const apiActions = [];
    const hasEmailAndSSNConflict = Boolean(conflictErrors.find(error => error.type === errorTypes.SSN_ERROR))
      && Boolean(conflictErrors.find(error => error.type === errorTypes.EMAIL_ERROR));

    conflictErrors.forEach(error => {
      switch (error.type) {
        case errorTypes.AGENT_ERROR:
        case errorTypes.BENEFICIARY_ERROR:
        case errorTypes.SUCCESSOR_ERROR:
        case errorTypes.SECONDARY_SUCCESSOR_ERROR: {
          apiActions.push(this.props.resolveBirthdate(error.ssn, this.state[`resolved${error.type}`]));
          break;
        }
        case errorTypes.EMAIL_ERROR: {
          apiActions.push(this.props.resolveEmail(hasEmailAndSSNConflict ? this.state.resolvedSSN : error.ssn, this.state.resolvedEmail));
          break;
        }
        case errorTypes.SSN_ERROR: {
          apiActions.push(this.props.resolveSSN(hasEmailAndSSNConflict ? this.state.resolvedEmail : error.email, this.state.resolvedSSN));
          break;
        }
        case errorTypes.POA_LEVEL_ERROR: {
          apiActions.push(this.props.resolvePOALevel(error.ssn, error.otherSSN, this.state.resolvedPOALevel));
          break;
        }
        case errorTypes.FED_TAX_ERROR: {
          apiActions.push(this.props.resolveFedTax(error.ssn, error.otherSSN, this.state.resolvedCanViewFedTaxDocs));
          break;
        }
        case errorTypes.STATE_TAX_ERROR: {
          apiActions.push(this.props.resolveStateTax(error.ssn, error.otherSSN, this.state.resolvedCanViewStateTaxDocs));
          break;
        }
        default: break;
      }
    });

    Promise.all(apiActions)
      .then(() => this.props.approveAccount())
      .catch(() => null)
      .finally(() => this.setState({ loading: false }));
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      if (this.props.show && this.props.conflictErrors.length > 0) {
        const conflicts = {};
        this.props.conflictErrors.forEach(error => {
          conflicts[`resolved${error.type}`] = null;
        });
        this.setState({
          canSave: false,
          ...conflicts
        });
      }
      else if (!this.props.show) {
        this.setState({ canSave: false });
      }
    }
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        title='Resolve Conflict(s)'
        onCloseModal={this.props.onCloseModal}
        actionButtons={[
          {
            label: 'Cancel',
            action: this.props.onCloseModal,
            disabled: this.state.loading,
          },
          {
            label: 'Save',
            action: this.onSave,
            disabled: !this.state.canSave || this.state.loading,
            loading: this.state.loading,
            buttonType: 'contained',
          },
        ]}
      >
        {this.conflictPickersCompile()}
      </Modal>
    );
  }
}

export default connect(null, {
  resolveBirthdate,
  resolveEmail,
  resolvePOALevel,
  resolveSSN,
  resolveFedTax,
  resolveStateTax,
})(ConflictErrorResolveModal);
