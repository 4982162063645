/*
*
* RunReport Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep, kebabCase } from 'lodash';
import dayjs from 'dayjs';
import bowser from 'bowser';
import { withRouter } from 'react-router-dom';

import {
  reportsGet,
  reportRun,
  reportDownload,
} from '../actions';
import { saveFile } from 'utils/helpers/files';

import {
  Card,
  LoadingOverlay,
  DataTable,
  notificationShow,
  Breadcrumbs,
  DatePicker,
} from '@frontend/common';
import { Button, Chip, } from '@mui/material';

import styles from './styles.module.css';

const reportsWithFilter = [ // used to turn on Filter for specific reports
  {
    id: 22, // LPOA Upcoming Transactions Report
    columnsForFiltering: ['Transaction Type', 'Recurrence'],
  },
  {
    id: 25, // LPOA Completed Transactions Detail Report
    columnsForFiltering: ['Transaction Type',],
  },
];

export const select = (state, { match }) => {
  const report = state.reports.reports[match.params.reportId];
  return {
    reportDetails: report ? report : { params: [] },
    claims: state.session.claims,
  };
};


export class RunReport extends React.Component {

  static propTypes = {
    reportDetails: PropTypes.object.isRequired,
    reportsGet: PropTypes.func.isRequired,
    claims: PropTypes.object.isRequired,
    reportRun: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    reportDownload: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    reportRunning: false,
    reportDownloading: false,
    params: [],
    reportHasRun: false,
    reportData: {
      tableMapping: {
        id: 'id',
        headers: {},
      },
      rows: [],
    },
    inputErrors: {},
    activeGraphDateFilter: {},
    dateFilters: [
      { label: '1 Month', filterDate: dayjs().subtract('1', 'month') },
      { label: '3 Month', filterDate: dayjs().subtract('3', 'month') },
      { label: '6 Month', filterDate: dayjs().subtract('6', 'month') },
      { label: 'YTD', filterDate: dayjs().startOf('year') },
      { label: '1 Year', filterDate: dayjs().subtract('1', 'year') },
    ],
    columnsForFiltering: [],
    showFilter: false,
  };

  dataTableCompose() {
    const { reportHasRun, reportRunning, reportDownloading, reportData, columnsForFiltering, showFilter } = this.state;
    if (reportData.rows.length > 0) {
      return (
        <div className={styles.RunReport_dataTable}>
          <DataTable
            tableData={reportData.rows}
            tableDataMapping={reportData.tableMapping}
            onDownloadClick={this.downloadReport}
            downloadLoading={reportDownloading}
            showDownload
            showFilter={showFilter}
            columnsForFiltering={columnsForFiltering}
          />
        </div>
      );
    }
    else if (reportHasRun && !reportRunning && reportData.rows.length === 0) {
      return (
        <div className={styles.RunReport_noDataMessage}>There is no report data for the selected criteria.</div>
      );
    }
    else {
      return null;
    }
  }

  downloadReport = () => {
    if (this.paramInputsVerify()) {
      this.setState({ reportDownloading: true });
      this.props.reportDownload(this.props.match.params.reportId, this.reportParamsCompile())
        .then(action => {
          const downloadedData = action.payload.data;
          if (!downloadedData) {
            this.props.notificationShow('', 'genericError');
          }
          else {
            const blob = new Blob([downloadedData], { type: 'text/csv;charset=utf-8' });
            const fileName = `${kebabCase(this.props.reportDetails.name)}-${dayjs().format('MM-DD-YY')}.csv`;

            if (bowser.name === 'Internet Explorer' || bowser.name === 'Microsoft Edge') {
              navigator.msSaveBlob(blob, fileName);
            }
            else {
              saveFile(blob, fileName);
            }
          }
        })
        .catch(() => null)
        .finally(() => this.setState({ reportDownloading: false }));
    }
  }

  mapParams(props) {
    const { claims, reportDetails } = props;

    return reportDetails.params.map(param => ({
      name: param.name,
      value: (param.name === 'AuthorizedRepresentativeId' && claims.AuthorizedRepresentativeId)
        || (param.name === 'FinancialAdvisorId' && claims.FinancialAdvisorId)
        || null,
    }));
  }

  paramInputsCompose() {
    const { activeGraphDateFilter, dateFilters, inputErrors, reportRunning, } = this.state;
    const params = this.props.reportDetails.params.filter(param => param.type === 'datetime');

    return (
      <>
        <div className={styles.paramDateInputs}>
          {params.map(param => {
            const paramInputs = this.state.params.find(({ name }) => name === param.name);
            return (
              <DatePicker
                key={param.name}
                label={param.displayName}
                name={param.name}
                value={paramInputs ? paramInputs.value : {}}
                onChange={date => this.paramInputUpdate(param.name, date ? date.toDate() : date)}
                shouldDisableDate={date => date >= new Date().setHours(0, 0, 0, 0)}
                error={Boolean(inputErrors[param.name])}
                helperText={inputErrors[param.name]}
              />
            );
          })}
        </div>

        {params.length === 2 &&
          <div className={styles.dateFilterChips}>
            {dateFilters.map(dateFilter => {
              const isActiveChip = activeGraphDateFilter.label === dateFilter.label;

              return (
                <Chip
                  color={isActiveChip ? 'secondary' : 'default'}
                  disabled={reportRunning}
                  key={dateFilter.label}
                  label={dateFilter.label}
                  onClick={() => this.filterByDateRange(dateFilter)}
                  style={{ margin: '2px 5px 2px 0', }}
                  variant='filled'
                />
              );
            })}
          </div>}
      </>
    );
  }

  filterByDateRange = dateFilter => {
    const updatedParams = cloneDeep(this.state.params);
    const inputErrors = cloneDeep(this.state.inputErrors);
    const startDateIndex = this.state.params.findIndex(param => param.name === 'StartDate');
    const endDateIndex = this.state.params.findIndex(param => param.name === 'EndDate');

    if (startDateIndex > -1) {
      updatedParams[startDateIndex].value = dateFilter.filterDate;
      delete inputErrors.StartDate;
    }
    if (endDateIndex > -1) {
      updatedParams[endDateIndex].value = dayjs().subtract('1', 'day');
      delete inputErrors.EndDate;
    }

    this.setState({ activeGraphDateFilter: dateFilter, params: updatedParams, inputErrors });
  }

  paramInputUpdate(paramName, value) {
    const updatedParams = cloneDeep(this.state.params);
    const inputErrors = cloneDeep(this.state.inputErrors);
    const paramIndex = this.state.params.findIndex(param => param.name === paramName);

    if (paramIndex > -1) {
      updatedParams[paramIndex].value = value;
      delete inputErrors[paramName];
      this.setState({
        params: updatedParams,
        reportHasRun: false,
        inputErrors,
        activeGraphDateFilter: {},
      });
    }
  }

  paramInputsVerify = () => {
    const inputErrors = {};
    this.state.params.forEach(param => {
      if (!param.value) {
        inputErrors[param.name] = 'Field is required.';
      }
    });

    this.setState({ inputErrors });

    return Object.keys(inputErrors).length === 0;
  }

  reportParamsCompile = () => {
    const formattedParams = {};
    this.state.params.forEach(param => {
      formattedParams[param.name] = param.value;
    });

    return formattedParams;
  }

  runReport = () => {
    const defaultReportData = {
      tableMapping: {
        id: 'id',
        headers: {},
      },
      rows: [],
    };
    if (this.paramInputsVerify()) {
      this.setState({
        reportRunning: true,
        reportData: { ...defaultReportData }
      });
      this.props.reportRun(this.props.match.params.reportId, this.reportParamsCompile())
        .then(action => {
          if (action.payload.status === 200) {
            const reportData = { ...defaultReportData };
            const columnNames = action.payload.data.ColumnNames;
            const rows = action.payload.data.Data;

            columnNames.forEach(name => {
              reportData.tableMapping.headers[name] = name;
            });

            reportData.rows = [];
            rows.forEach((row, index) => {
              const rowData = { id: index };
              row.forEach((cell, index) => {
                rowData[columnNames[index]] = cell;
              });

              reportData.rows.push(rowData);
            });

            if (reportData.rows.length > 5000) {
              this.props.notificationShow('Only displaying 5,000 rows as the selected report parameters returned a large data set. Please download report to see all data.', 'warning');
              reportData.rows = reportData.rows.slice(0, 4999);
            }

            this.setState({
              reportData,
              reportHasRun: true,
            });
          }
        })
        .catch(() => null)
        .finally(() => this.setState({ reportRunning: false }));
    }
  }

  componentDidMount() {
    const { reportDetails } = this.props;
    if (!reportDetails.id || reportDetails.params.length === 0) {
      this.setState({ loading: true });
      this.props.reportsGet()
        .then(() => {
          if (!this.props.reportDetails.id) {
            this.props.history.push('/reports');
          }
          else {
            this.setState({
              loading: false,
              params: this.mapParams(this.props),
            });
          }
        })
        .catch(() => null);
    }
    else {
      this.setState({
        loading: false,
        params: this.mapParams(this.props),
      });
    }

    // set any potential columns that can be filter to pass into the DataTable for the Filter
    const reportWithFilter = reportsWithFilter.find(report => report.id === reportDetails.id);
    const showFilter = reportWithFilter !== undefined;
    const columnsForFiltering = showFilter ? reportWithFilter.columnsForFiltering : [];
    this.setState({ showFilter, columnsForFiltering });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className={styles.RunReport_page}>
          <LoadingOverlay
            show={true}
            indicatorHeight='15px'
            width='100%'
          />
        </div>
      );
    }
    else {
      return (
        <div className={styles.RunReport_page}>
          <div className={styles.RunReport_breadcrumbs}>
            <Breadcrumbs
              crumbs={[
                {
                  title: 'Reports',
                  link: '/reports'
                },
                { title: 'Run Report' }
              ]}
            />
          </div>
          <Card
            title={this.props.reportDetails.name}
            className={styles.RunReport_reportDetails}
          >
            <div className={styles.RunReport_description}>{this.props.reportDetails.description}</div>
            <div className={styles.RunReport_form}>
              {this.paramInputsCompose()}
              <div className={styles.RunReport_runButton}>
                <LoadingOverlay
                  show={this.state.reportRunning}
                  indicatorHeight='5px'
                >
                  <Button
                    variant='contained'
                    onClick={this.runReport}
                    disabled={this.state.reportRunning}
                  >
                    Run Report
                  </Button>
                </LoadingOverlay>
              </div>
            </div>
          </Card>
          {this.dataTableCompose()}
        </div>
      );
    }
  }
}

export default withRouter(connect(select, {
  reportsGet,
  reportRun,
  notificationShow,
  reportDownload,
})(RunReport));
