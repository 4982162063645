/*
*
* Contributions Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  createNewContribution,
  editContribution,
  clearContributionEditDetails,
  getAccountsByAgent,
  getAgentBankAccounts,
  getContributionDetails,
  getContributionEditDetails
} from '../../actions';
import {
  getEnvironmentVariables,
  getServerDateTime,
} from 'components/AppRoot/StaticResources/actions';
import { getAccountBaseRoute } from 'components/Features/protected/Accounts/helpers';
import {
  Breadcrumbs,
  ConfirmModal,
  LoadingOverlay,
  notificationShow,
} from '@frontend/common';
import {
  MONTHLY,
  ONE_TIME,
  TWICE_MONTHLY,
  YEARLY
} from './constants';
import AddBankAccount from './AddBankAccount';
import ContributionDetails from './ContributionDetails';
import NewOneTime from './NewOneTime';
import NewSpecial from './NewSpecial';
import NewRecurring from './NewRecurring';
import {
  Tabs,
  Tab
} from '@mui/material';

import events from 'utils/ga';

import styles from './styles.module.css';

const select = (state) => ({
  contributionDetails: state.accounts.contributionDetails,
  contributionVariables: state.static.environmentVars.contributionVariables,
  selectedAgentAccounts: state.accounts.selectedAgentAccounts,
});

export class Contributions extends React.Component {
  static propTypes = {
    clearContributionEditDetails: PropTypes.func.isRequired,
    contributionDetails: PropTypes.object.isRequired,
    contributionVariables: PropTypes.object.isRequired,
    createNewContribution: PropTypes.func.isRequired,
    editContribution: PropTypes.func.isRequired,
    getAccountsByAgent: PropTypes.func.isRequired,
    getAgentBankAccounts: PropTypes.func.isRequired,
    getContributionDetails: PropTypes.func.isRequired,
    getContributionEditDetails: PropTypes.func.isRequired,
    getEnvironmentVariables: PropTypes.func.isRequired,
    getServerDateTime: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    selectedAgentAccounts: PropTypes.array.isRequired,
  };

  state = {
    addBankAccountModalOpen: false,
    bankAccountsLoading: false,
    duplicateContribution: {},
    duplicateContributionMessage: '',
    duplicateContributionModalOpen: false,
    createContributionLoading: false,
    ignoreDuplicates: false,
    isEditing: window.location.href.search('edit') > -1,
    loading: false,
    smallWidth: Boolean(window.innerWidth <= 1200),
    tabIndex: 0,
    onPrintStep: false,
    serverDateTime: null,
  };

  contributionCreate = (contribution) => {
    this.setState({ createContributionLoading: true });

    const formattedContribution = {
      AccountAmounts: contribution.selectedAccounts.filter(account => account.accountId).map(account => ({
        AccountId: account.accountId,
        Amount: account.contributionAmount,
        EndDate: account.endDate || null,
      })),
      BankAccountId: contribution.bankAccount.id,
      IgnoreDuplicates: this.state.ignoreDuplicates,
      Occasion: contribution.occasion || null,
      ScheduleType: contribution.type.replace(/ /g, ''),
      TradeDate: contribution.day1,
      TradeDate2: contribution.day2 || null,
    };

    this.props.createNewContribution(formattedContribution)
      .then(response => {
        // precondition messages handled here
        const messages = response.payload.data;
        const dupeMsgs = Array.isArray(messages) ? messages.filter(message => message.MessageType === 3) : [];
        if (dupeMsgs.length > 0) {
          const duplicateContributionMessage = dupeMsgs.map(message => message.Message).join('<\br>');
          this.setState({
            duplicateContribution: contribution,
            duplicateContributionMessage,
            duplicateContributionModalOpen: true,
            createContributionLoading: false
          });
        }
        else {
          // google analytics
          switch (contribution.type) {
            case ONE_TIME: { events.contributionsOneTimeStep('final'); break; }
            case MONTHLY: { events.contributionsRecurringStep('final'); break; }
            case YEARLY: { events.contributionsSpecialStep('final'); break; }
            default: break;
          }
          this.setState({
            duplicateContribution: {},
            onPrintStep: true,
          });
          this.props.notificationShow('Contribution scheduled.', 'success');
          // get server timestamp by returning promise here
          this.props.getServerDateTime()
            .then((response) => {
              const serverDateTime = response.payload.data;
              this.setState({
                serverDateTime,
                createContributionLoading: false,
              });
            });
        }
      })
      .catch(() => this.setState({ createContributionLoading: false }));
  }

  contributionDetailsGet = (calledFrom) => {
    const { accountId, agentId, transactionId } = this.props.match.params;

    const apiCalls = [
      this.props.getAgentBankAccounts(agentId, 'contributions'),
      this.props.getEnvironmentVariables(Object.keys(this.props.contributionVariables).length !== 0 && ['ValidOneTimeContributionDates', 'CurrentTradeDate']),
      calledFrom !== 'addBankAccount' && this.props.getAccountsByAgent(agentId, 'canContribute=true'),
      this.state.isEditing && this.props.getContributionEditDetails(transactionId)
    ];

    this.setState(calledFrom === 'addBankAccount' ? { bankAccountsLoading: true } : { loading: true });

    Promise.all(apiCalls)
      .then(() => {
        this.setState({ bankAccountsLoading: false, loading: false });
        return this.props.getContributionDetails(accountId);
      })
      .catch(() => this.setState({ bankAccountsLoading: false, loading: false }));
  }

  contributionEdit = (contribution) => {
    this.setState({ createContributionLoading: true });

    const formattedContribution = {
      Amount: contribution.selectedAccounts[0].contributionAmount,
      BankAccountId: contribution.bankAccount.id,
      EndDate: contribution.selectedAccounts[0].endDate || null,
      Occasion: contribution.occasion || null,
      Frequency: contribution.type.replace(/ /g, ''),
      TradeDate: contribution.day1,
      TradeDate2: contribution.day2 || null,
    };

    this.props.editContribution(this.props.match.params.transactionId, formattedContribution)
      .then(() => {
        this.props.notificationShow('Contribution updated.', 'success');
        // get server timestamp by returning promise here
        this.props.getServerDateTime()
          .then((response) => {
            const serverDateTime = response.payload.data;
            this.setState({
              serverDateTime,
              createContributionLoading: false,
            });
          });
      })
      .catch(() => this.setState({ createContributionLoading: false }));
  }

  defaultTabGet = () => {
    if (this.state.isEditing) {
      switch (this.props.contributionDetails.contribution.type) {
        case ONE_TIME: this.setState({ tabIndex: 0 });
          break;
        case MONTHLY: this.setState({ tabIndex: 1 });
          break;
        case TWICE_MONTHLY: this.setState({ tabIndex: 1 });
          break;
        case YEARLY: this.setState({ tabIndex: 2 });
          break;
        default: this.setState({ tabIndex: 0 });
      }
    }
    else {
      this.setState({ tabIndex: 0 });
    }
  }

  onAddBankAccountModalOpen = () => {
    this.setState({ addBankAccountModalOpen: true });
  }

  onTabChange = (e, val) => {
    this.setState({ tabIndex: val });
    if (!this.state.isEditing) {
      switch (val) {
        case 0: { events.contributionsOneTimeStep(0); break; }
        case 1: { events.contributionsRecurringStep(0); break; }
        case 2: { events.contributionsSpecialStep(0); break; }
        default: break;
      }
    }
  }

  componentDidMount() {
    const { contributionDetails: { agentAccounts, contribution, selectedAccountId }, match: { params: { accountId, transactionId } } } = this.props;

    if (
      parseInt(selectedAccountId) !== parseInt(accountId) ||
      agentAccounts.length === 0 ||
      (this.state.isEditing && contribution.transactionId !== parseInt(transactionId))
    ) {
      this.contributionDetailsGet();
    }
    else {
      this.props.getEnvironmentVariables(['ValidOneTimeContributionDates', 'CurrentTradeDate']);
    }
    this.defaultTabGet();
  }

  componentWillUnmount() {
    this.props.clearContributionEditDetails();
  }

  render() {
    const { agentId } = this.props.match.params;
    const {
      addBankAccountModalOpen,
      bankAccountsLoading,
      duplicateContribution,
      duplicateContributionMessage,
      createContributionLoading,
      duplicateContributionModalOpen,
      loading,
      isEditing,
      smallWidth,
      tabIndex,
      onPrintStep,
      serverDateTime,
    } = this.state;
    const { type, selectedAgentAccounts } = this.props.contributionDetails.contribution;

    return (
      <div className={styles.Contributions_container}>
        <div className={`${styles.Contributions_pageNav} hideOnPrint`}>
          <Breadcrumbs
            crumbs={[
              {
                title: 'Accounts',
                link: '/accounts'
              },
              {
                title: 'Details',
                link: getAccountBaseRoute(this.props.match.params),
              },
              {
                title: isEditing ? 'Edit Contributions' : 'New Contributions',
              },
            ]}
          />
        </div>

        {loading &&
          <div className={styles.Contributions_loading}>
            <LoadingOverlay
              show={loading}
              width='100%'
              indicatorHeight='10px'
            />
          </div>
        }

        <div className={styles.Contributions_details}>
          <ContributionDetails
            key={`${this.props.selectedAgentAccounts}_Details`}
            loading={loading}
          />
        </div>

        <div className={styles.Contributions_newContributions}>
          <Tabs onChange={(e, val) => this.onTabChange(e, val)} value={tabIndex} centered>
            <Tab label='One-Time' disabled={(isEditing && type !== ONE_TIME) || onPrintStep} />
            <Tab label='Recurring' disabled={(isEditing && (type !== MONTHLY || type !== TWICE_MONTHLY)) || onPrintStep} />
            <Tab label='Special Occasion' disabled={(isEditing && type !== YEARLY) || onPrintStep} />
          </Tabs>

          {tabIndex === 0 &&
            <NewOneTime
              bankAccountsLoading={bankAccountsLoading}
              contributionCreate={this.contributionCreate}
              contributionEdit={this.contributionEdit}
              createContributionLoading={createContributionLoading}
              isEditing={isEditing && type === ONE_TIME}
              key={`${selectedAgentAccounts}_One-Time`}
              loading={loading}
              onAddBankAccountModalOpen={() => this.onAddBankAccountModalOpen()}
              smallWidth={smallWidth}
              serverDateTime={serverDateTime}
            />
          }

          {tabIndex === 1 &&
            <NewRecurring
              bankAccountsLoading={bankAccountsLoading}
              contributionCreate={this.contributionCreate}
              contributionEdit={this.contributionEdit}
              createContributionLoading={createContributionLoading}
              isEditing={isEditing && (type === MONTHLY || type === TWICE_MONTHLY)}
              key={`${selectedAgentAccounts}_Recurring`}
              loading={loading}
              onAddBankAccountModalOpen={() => this.onAddBankAccountModalOpen()}
              smallWidth={smallWidth}
              serverDateTime={serverDateTime}
            />
          }

          {tabIndex === 2 &&
            <NewSpecial
              bankAccountsLoading={bankAccountsLoading}
              contributionCreate={this.contributionCreate}
              contributionEdit={this.contributionEdit}
              createContributionLoading={createContributionLoading}
              isEditing={isEditing && type === YEARLY}
              key={`${selectedAgentAccounts}_Special`}
              loading={loading}
              onAddBankAccountModalOpen={() => this.onAddBankAccountModalOpen()}
              smallWidth={smallWidth}
              serverDateTime={serverDateTime}
            />
          }
        </div>

        <AddBankAccount
          contributionDetailsGet={() => this.contributionDetailsGet('addBankAccount')}
          onClose={() => this.setState({ addBankAccountModalOpen: false })}
          open={addBankAccountModalOpen}
          agentId={agentId}
        />

        <ConfirmModal
          show={duplicateContributionModalOpen}
          title='Create Duplicate Contribution?'
          body={duplicateContributionMessage}
          onModalClose={() => this.setState({ duplicateContributionModalOpen: false })}
          onConfirm={() => this.setState({ duplicateContributionModalOpen: false, ignoreDuplicates: true }, () => this.contributionCreate(duplicateContribution))}
          isLoading={createContributionLoading}
        />
      </div>
    );
  }
}

export default connect(select, {
  createNewContribution,
  editContribution,
  clearContributionEditDetails,
  getAccountsByAgent,
  getAgentBankAccounts,
  getContributionDetails,
  getEnvironmentVariables,
  getServerDateTime,
  getContributionEditDetails,
  notificationShow
})(Contributions);
