import React, { Fragment } from 'react';

export const PrrCalculatorText = {
  learn_more_title: 'Personal Rate of Return Footer/Disclosure:',
  prr_not_available: 'Not Available',
  text_learn_more() {
    return (
      <div>
        <p><b>Performance returns for time periods longer than 365 days have been annualized.</b></p>

        <p>The Personal Rate of Return produced by this calculator (Personal Rate of Return) is a dollar-weighted calculation methodology (also known as Internal Rate of Return) used to measure the actual performance of an account, including the impact of the timing and dollar amount of deposits and withdrawals (i.e., cash flows). The formula used by my529 considers any activity in the account, including cash flows, dividends, interest and fees. It can be used to answer the fundamental question of how an investment is performing.</p>

        <p>The Personal Rate of Return should not be confused with performance returns posted by my529 for its investment options or returns for underlying funds. The performance returns posted for my529&apos;s investment options and for the underlying funds use a time-weighted calculation that normalizes returns by not considering cash flows. Performance reported for my529 investment options may differ significantly from the Personal Rate of Return of an account due to the unique transaction activity that occurred within the account.</p>

        <p>Year-to-date (YTD) performance returns are calculated from either the beginning of the calendar year or the opening date of the account, if the account was opened after the beginning of the year. The earliest date that may be selected is the account opening date, or January 1, 2005, whichever is later. If an account was not funded on the opening date, the calculation will use the funding date as the beginning date. Performance may be calculated up to the previous trading date.</p>

        <p>The Personal Rate of Return should not be used in making investment decisions. It is provided by my529 as a courtesy for informational purposes only with respect to the unique transaction activity that occurred within your account. You should consider other factors like your time horizon, risk tolerance, investment objectives, personal financial situation, and tax implications before making changes to your investment option.</p>

        <p><b>Past performance is not a guarantee of future performance.</b></p>
      </div>
    );
  },
  text_faq: [
    {
      question: 'How was my Personal Rate of Return calculated?',
      answer: 'Your Personal Rate of Return was determined using a dollar-weighted rate of return calculation. This calculation is based on a number of factors, including changes in the value of the my529 units you own, dividends and interest earned, fees paid and the dollar amount and timing of your contribution(s) and/or withdrawal(s). This type of calculation is also sometimes referred to as an Internal Rate of Return.',
    },
    {
      question: 'What is the difference between dollar-weighted and time-weighted rates of return?',
      answer: (
        <Fragment>
          A dollar-weighted rate of return is used to evaluate the combined investment decisions of an investment manager and an individual investor. The dollar-weighted Personal Rate of Return measures the performance of the underlying investments, including dividends, interest and fees, and also considers the impact of the relative dollar amount and timing of the contribution(s) and withdrawal(s) that an account owner makes.
          <br /><br />
          In contrast, a time-weighted rate of return is commonly used to evaluate the performance of a fund or an investment manager. This rate of return measures the performance of the underlying investments, including dividends, interest and fees, but seeks to exclude or minimize the impact of the dollar amount and timing of contributions and withdrawals. The exclusion of cash flows normalizes the returns to allow for easier comparison of the investment manager&apos;s performance and other investment options.
          <br /><br />
          Investment Option Performance published on my529.org is calculated using a time-weighted rate of return, whereas the account specific Personal Rates of Return within Account Access are calculated using a personal (dollar-weighted) rate of return.
        </Fragment>
      ),
    },
    {
      question: 'How will withdrawals affect my performance?',
      answer: 'Withdrawals are not treated as a decline in your return, but will decrease the investment option value used to calculate your return. The dollar-weighted methodology, or Personal Rate of Return, takes into account the timing and size of contributions and withdrawals.',
    },
    {
      question: 'What time frame can I select for the Personal Rate of Return?',
      answer: 'The Personal Rate of Return can be calculated from any time period beginning as far back as January 1, 2005, or account opening (whichever is later) and ending as recently as the previous trading day. Account performance data is calculated nightly.',
    },
    {
      question: 'What does it mean when the Personal Rate of Return for time periods longer than 365 days has been annualized?',
      answer: 'A Personal Rate of Return for any time period longer than 365 days has been annualized, or calculated as the equivalent annual return an investment received over the selected period. Returns for a time period less than 365 days, on the other hand, have not been annualized to prevent any projection of performance.',
    },
  ],
  title: 'Personal Rate of Return',
  subtitle: 'Calculate your personal rate of return.',
  rate_of_return_txt: 'Rate of return',
  start_date_label: 'Start Date',
  end_date_label: 'End Date',
  btn_calculate: 'Calculate',
  btn_learnmore: 'Learn More',
};