import { find, groupBy, reduce, uniq, some, sortBy, filter, replace } from 'lodash';
import dayjs from 'dayjs';
import qs from 'qs';
import { currencyFormatter, properCase } from '@frontend/common';

const isBirthdayInRange = (range, start) => {
  const beginning = dayjs(start).startOf('day');
  const end = dayjs(beginning).add(range);
  return dob => {
    const date = dayjs(dob).year(beginning.year());
    if (date.isBefore(beginning)) {
      date.year(date.year() + 1);
    }
    return date.isBetween(beginning, end, null, '[]');
  };
};

const commonMetricsWrapper = accountsByGroup => {
  const birthdayFilter = isBirthdayInRange(dayjs.duration('P30D'));
  const decimalRegex = /\.\d\d/;
  return accountsByGroup.map(accountGraphGroup => {
    const accounts = accountGraphGroup.accountList;
    return ({
      ...accountGraphGroup,
      assets: replace(currencyFormatter(
        accounts && accounts.length > 0 ?
          reduce(accounts, ((sum, account) => sum + account.AcctBalance), 0).toFixed() : 0
      ), decimalRegex, ''),
      avgBalance: replace(currencyFormatter(
        accounts && accounts.length > 0 ?
          (reduce(accounts, ((sum, account) => sum + account.AcctBalance), 0) / accounts.length).toFixed() : 0
      ), decimalRegex, ''),
      upcomingBirthdays: accounts && accounts.length > 0 ?
        accounts.map(account => account.BenBirthDate).filter(birthdayFilter).length : 0,
      zeroBalances: accounts && accounts.length > 0 ? filter(accounts,
        account => account.AcctBalance === 0
      ).length : 0,
    });
  });
};

const accountsMetricTransformer = accounts => {
  return commonMetricsWrapper([{
    accounts: accounts !== undefined ? accounts.length : 0,
    accountList: accounts
  }])[0];
};

const accountsByAgeBandFilter = (accounts, ageBrackets) => {
  const now = dayjs();
  const accountsByAgeBand = groupBy(accounts,
    account => find(ageBrackets, ageBracket => {
      const age = now.diff(dayjs(account.BenBirthDate), 'year');
      return (ageBracket.maxAge === null && age >= ageBracket.minAge) ||
        (ageBracket.maxAge !== null && (ageBracket.minAge <= age && age <= ageBracket.maxAge));
    }).displayName
  );
  return commonMetricsWrapper(ageBrackets.map(ageBand => ({
    accounts: accountsByAgeBand[ageBand.displayName] !== undefined ? accountsByAgeBand[ageBand.displayName].length : 0,
    key: ageBand.displayName,
    accountList: accountsByAgeBand[ageBand.displayName]
  })));
};

const accountsByAgeFilter = (accounts) => {
  const now = dayjs();
  const accountsByAge = groupBy(accounts,
    account => now.diff(dayjs(account.BenBirthDate), 'year')
  );
  const ages = uniq(sortBy([
    ...Array.from(Array(20).keys()).map(age => `${age}`),
    ...Object.keys(accountsByAge)
  ], parseInt));

  return commonMetricsWrapper(ages.map(years => ({
    accounts: accountsByAge[years] !== undefined ? accountsByAge[years].length : 0,
    key: years,
    accountList: accountsByAge[years]
  })));
};

/**
 *
 * @param {{ageGroups: Array, ages: Array, upcomingBirthdaysDuration: Object?, zeroBalances: boolean}} filters
 * @returns {string}
 */
const filtersToSearchString = (filters) => {
  return qs.stringify({
    ageGroup: filters.ageGroups,
    age: filters.ages,
    upcomingBirthdays: filters.upcomingBirthdaysDuration ? filters.upcomingBirthdaysDuration.toISOString() : undefined,
    zeroBalances: filters.zeroBalances ? filters.zeroBalances : undefined,
  }, { indices: false });
};

/**
 * Parses the search parameters in the url and creates a filters object that will be used to filter the account list
 * @param {string} search
 * @returns {{ageGroups: Array, ages: Array, upcomingBirthdaysDuration: Object?, zeroBalances: boolean}}
 */
const queryParser = (search) => {
  const filters = {
    ageGroups: [],
    ages: [],
    upcomingBirthdaysDuration: null,
    zeroBalances: false
  };
  const query = qs.parse(search, { ignoreQueryPrefix: true });
  if (query) {
    if ('ageGroup' in query) {
      if (Array.isArray(query.ageGroup)) {
        filters.ageGroups.push(...query.ageGroup);
      }
      if (typeof query.ageGroup === 'string') {
        filters.ageGroups.push(query.ageGroup);
      }
    }
    if ('age' in query) {
      if (Array.isArray(query.age)) {
        filters.ages.push(...query.age);
      }
      if (typeof query.age === 'string') {
        filters.ages.push(query.age);
      }
    }
    if ('upcomingBirthdays' in query && typeof query.upcomingBirthdays === 'string') {
      const upcomingBirthdaysDuration = dayjs.duration(query.upcomingBirthdays);
      if (dayjs.isDuration(upcomingBirthdaysDuration)) {
        filters.upcomingBirthdaysDuration = upcomingBirthdaysDuration;
      }
    }
    if ('zeroBalances' in query && typeof query.zeroBalances === 'string') {
      filters.zeroBalances = true;
    }
  }
  return filters;
};

/**
 * Checks array of parsed ageGroups from url and returns true if Beneficiary Age is within any of the ageGroups
 * @param {string[]} ageGroups
 * @param account
 * @returns {boolean}
 */
const isInAgeGroups = (ageGroups, account) => {
  return some(ageGroups, (ageGroupString) => {
    if (ageGroupString.indexOf('-') > -1) {
      const ages = ageGroupString.split('-').map(str => parseInt(str, 10));
      if (some(ages, age => Number.isNaN(age))) {
        ageGroupString = find(ages, age => !Number.isNaN(age));
      }
      else {
        return account.BenAge >= ages[0] && account.BenAge <= ages[1];
      }
    }
    else if (ageGroupString.indexOf('+') > -1) {
      return account.BenAge >= parseInt(ageGroupString.slice(0, -1));
    }
    return account.BenAge === parseInt(ageGroupString);
  });
};

/**
 * Checks array of parsed ages from url and returns true if Beneficiary Age matches any of the ages
 * @param {string[]} ages
 * @param account
 * @returns {boolean}
 */
const isInAges = (ages, account) => {
  return some(ages, ageString => account.BenAge === parseInt(ageString));
};

/**
 *
 * @param accounts
 * @param {{ageGroups: Array, ages: Array, upcomingBirthdaysDuration: Object?, zeroBalances: boolean}} filters
 * @returns Object[] of accounts either unchanged or filtered down by filters specified (if any)
 */
const accountsByQueryFilters = (accounts, filters) => {
  let filteredAccountList = accounts.filter(account => {
    // if there is ageGroups or ages specified, make sure Beneficiary Age is in at least one of those filters
    if ((filters.ageGroups.length > 0 || filters.ages.length > 0) &&
      (!isInAgeGroups(filters.ageGroups, account) && !isInAges(filters.ages, account))
    ) {
      return false;
    }

    // if zeroBalances filter is specified, make sure account balance is 0
    return !(filters.zeroBalances && account.AcctBalance !== 0);
  });

  // if upcomingBirthdaysDuration filter is specified, make sure Beneficiary Birthday is within the date range and now
  if (filters.upcomingBirthdaysDuration !== null) {
    const checkDOB = isBirthdayInRange(filters.upcomingBirthdaysDuration);
    filteredAccountList = filteredAccountList.filter(account => checkDOB(account.BenBirthDate));
  }

  // sorts by soonest birthday from now, then age ascending
  if (filters.upcomingBirthdaysDuration !== null) {
    const now = dayjs().startOf('day');
    filteredAccountList.sort((accountA, accountB) => {
      const nextBirthdayA = dayjs(accountA.BenBirthDate).year(now.year());
      if (nextBirthdayA.isBefore(now)) {
        nextBirthdayA.add(1, 'y');
      }
      const nextBirthdayB = dayjs(accountB.BenBirthDate).year(now.year());
      if (nextBirthdayB.isBefore(now)) {
        nextBirthdayB.add(1, 'y');
      }
      if (nextBirthdayA.diff(nextBirthdayB) === 0) {
        return Math.sign(accountA.BenAge - accountB.BenAge);
      }
      return Math.sign(nextBirthdayA.diff(nextBirthdayB));
    });
  }
  // sorts by age ascending, then full name ascending
  else if (filters.ages.length > 0 || filters.ageGroups.length > 0) {
    filteredAccountList.sort((accountA, accountB) => {
      const ageDiff = Math.sign(accountA.BenAge - accountB.BenAge);
      if (ageDiff === 0) {
        return properCase(`${accountA.BenFirst} ${accountA.BenLast}`).localeCompare(
          properCase(`${accountB.BenFirst} ${accountB.BenLast}`)
        );
      }
      return ageDiff;
    });
  }
  return filteredAccountList;
};

export {
  accountsByAgeBandFilter,
  accountsByAgeFilter,
  accountsMetricTransformer,
  accountsByQueryFilters,
  queryParser,
  filtersToSearchString
};
