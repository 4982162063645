/*
*
* Transfer Component
*
*/
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import dayjs from 'dayjs';
import { Card, LoadingOverlay } from '@frontend/common';
import {
  Step,
  StepButton,
  Stepper,
  Button,
} from '@mui/material';
import TransferSetup from './TransferStepOneContent';
import {
  TermsAndConditionsReview,
  TransferTermsAndConditions
} from './TransferTermsAndConditions';

import transactionsStyles from '../../styles.module.css';
import styles from './styles.module.css';

export function formatAccountDisplayText(account) {
  const accountNumber = get(account, 'details.accountNumber', '').toString();
  return `${get(account, 'beneficiary.name', '')} ...${accountNumber.substring(accountNumber.length - 5)}`;
}

export class Transfer extends React.Component {
  static propTypes = {
    cardTitle: PropTypes.string.isRequired,
    sourceAccount: PropTypes.object,
    destinationAccounts: PropTypes.array,
    save: PropTypes.func.isRequired,
    transfer: PropTypes.object.isRequired,
    canContinue: PropTypes.func.isRequired,
    errors: PropTypes.array.isRequired,
    onTransferChange: PropTypes.func.isRequired,
    onTargetChange: PropTypes.func.isRequired,
    addTargetAccount: PropTypes.func.isRequired,
    removeTargetAccount: PropTypes.func.isRequired,
    showFullBalanceDialog: PropTypes.func.isRequired,
    serverDateTime: PropTypes.string,
  };

  static defaultProps = {
    sourceAccount: {},
    destinationAccounts: [],
  };

  state = {
    stepIndex: 0,
    termsChecked: false,
    isLoadingSubmit: false,
  };

  isSmallWindow = () => window.innerWidth < 600;

  onTermsToggled = () => {
    this.setState({
      termsChecked: !this.state.termsChecked
    });
  };

  getErrorForInput = (inputKey) => {
    const { errors } = this.props;
    const error = errors.find(err => err.key === inputKey);
    return error !== undefined ? error.message : '';
  };

  handlePrevStep = () => {
    this.setState({
      stepIndex: this.state.stepIndex - 1,
    });

    window.scrollTo(0, 0);
  };

  handleNextStep = () => {
    const { canContinue, } = this.props;
    const { stepIndex, termsChecked, } = this.state;

    switch (stepIndex) {
      case 0: {
        canContinue(() => {
          this.setState({
            stepIndex: stepIndex + 1,
          });
          window.scrollTo(0, 0);
        });
        break;
      }
      case 1: {
        if (!termsChecked) {
          return;
        }
        canContinue(() => {
          this.setState({ isLoadingSubmit: true });
          this.props.save()
            .then(() => {
              this.setState({
                stepIndex: this.state.stepIndex + 1
              });
            })
            .catch(() => {
              this.setState({
                isLoadingSubmit: false,
                stepIndex: this.state.stepIndex - 1
              });
            });
        });
        break;
      }
      default: break;
    }
  };

  stepperCompose = () => {
    const { stepIndex } = this.state;
    const stepperStyle = {
      width: this.isSmallWindow() ? '' : '650px'
    };
    return (
      <Stepper
        activeStep={this.state.stepIndex}
        orientation={this.isSmallWindow() ? 'vertical' : 'horizontal'}
        style={stepperStyle}
        className='hideOnPrint'
      >
        <Step>
          <StepButton onClick={() => this.setState({ stepIndex: 0 })} disabled={stepIndex === 2}>Select Transfer Amount</StepButton>
        </Step>
        <Step>
          <StepButton onClick={() => this.setState({ stepIndex: 1 })} disabled={stepIndex === 2}>Terms & Conditions</StepButton>
        </Step>
        <Step>
          <StepButton onClick={() => this.setState({ stepIndex: 2 })}>
            Print Review
          </StepButton>
        </Step>
      </Stepper>
    );
  };

  stepperContentCompose = () => {
    const {
      stepIndex,
      termsChecked,
    } = this.state;
    const {
      serverDateTime,
    } = this.props;

    switch (stepIndex) {
      case 0:
        return (
          <TransferSetup
            {...this.props}
            getErrorForInput={this.getErrorForInput}
          />
        );
      case 1:
        return [
          <TermsAndConditionsReview
            key='tcReview'
            transfer={this.props.transfer}
            destinationAccounts={this.props.destinationAccounts}
            sourceAccount={this.props.sourceAccount}
          />,
          <TransferTermsAndConditions
            key='tc'
            termsChecked={termsChecked}
            onTermsToggled={this.onTermsToggled}
          />
        ];
      case 2:
        return [
          <TermsAndConditionsReview
            key='tcReview'
            transfer={this.props.transfer}
            destinationAccounts={this.props.destinationAccounts}
            sourceAccount={this.props.sourceAccount}
          />,
          <div className={transactionsStyles.printContainer} key='print'>
            <div className={transactionsStyles.currentTime}>{dayjs(serverDateTime)
              .format('MM/DD/YYYY h:mm A')} MST</div>
            <div className='hideOnPrint'>
              Please print a copy of this page for your records.
            </div>
          </div>,
        ];
      default: break;
    }
  };

  goToPreviousPage = () => {
    this.props.history.goBack();
  }

  stepperButtonsCompose = () => {
    const { stepIndex, termsChecked, isLoadingSubmit } = this.state;
    const cancelButton = (
      <Button
        variant='text'
        key='cancel'
        disabled={isLoadingSubmit}
        onClick={this.goToPreviousPage}
        style={{ marginRight: '5px' }}
      >
        Cancel
      </Button>
    );
    const nextButton = (
      <Button
        key='nextStep'
        disabled={isLoadingSubmit}
        onClick={this.handleNextStep}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Next
      </Button>
    );
    const backButton = (
      <Button
        key='prevStep'
        disabled={isLoadingSubmit}
        onClick={this.handlePrevStep}
        variant='text'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Back
      </Button>
    );
    const submitButton = (
      <Button
        disabled={!termsChecked || isLoadingSubmit}
        onClick={this.handleNextStep}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Submit
      </Button>
    );
    const printButton = (
      <Button
        key='print'
        onClick={() => window.print()}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Print
      </Button>
    );
    const finishButton = (
      <Button
        key='finish'
        onClick={() => this.props.history.goBack()}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Finish
      </Button>
    );
    let buttons = [];
    switch (stepIndex) {
      case 0:
        buttons = [
          cancelButton,
          nextButton
        ];
        break;
      case 1:
        buttons = [
          cancelButton,
          backButton,
          <LoadingOverlay key='nextStep' show={isLoadingSubmit}>
            {submitButton}
          </LoadingOverlay>
        ];
        break;
      case 2:
        buttons = [
          printButton,
          finishButton,
        ];
        break;
      default: break;
    }
    return buttons;
  };

  render() {
    return (
      <div>
        {/* There are no CSS styles for Transfer_stepperContentContainer */}
        <Card className='Transfer_stepperContentContainer' title={this.props.cardTitle}>
          {/* There are no CSS styles for Transfer_stepperStepsContainer */}
          <div className='Transfer_stepperStepsContainer'>
            <div className={`${styles.Transfer_stepperSteps} hideOnPrint`}>
              {this.stepperCompose()}
            </div>
          </div>
          <div className={`${styles.Transfer_stepperContent} ${styles[`step-${this.state.stepIndex}`]}`}>
            {this.stepperContentCompose()}
          </div>
          <div className={`${styles.Transfer_stepperButtons} hideOnPrint`}>
            {this.stepperButtonsCompose()}
          </div>
        </Card>
      </div>
    );
  }
}

export default withRouter(Transfer);
