import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@frontend/common';

export const OptionChangeDialog = ({ show, onClose, }) => (
  <Modal
    title='Investment Option Change Information'
    show={show}
    onCloseModal={onClose}
    modal={false}
    actionButtons={[
      {
        label: 'OK',
        action: onClose,
      }
    ]}
  >
    <div>
      <p>
        This transfer will count as one of the two total investment option changes allowed per beneficiary for this calendar year.
      </p>
      <p>
        If, after making this transfer, you want to change the investment option for the same beneficiary&apos;s accounts of the same account type,
        you may do so without incurring a second option change as long as you make the change before the close of market trading on the New York Stock Exchange (NYSE),
        normally 2:00 p.m. MT. You also must make any changes or cancellations before the close of market trading.
      </p>
      <p>
        If you make a transfer request after the close of market trading, or on a weekend or holiday, any additional changes or cancellations must be received by 2:00 p.m. MT, the following business day.
      </p>
    </div>
  </Modal>
);

OptionChangeDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default OptionChangeDialog;
