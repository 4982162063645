/*
*
* StateDropdown Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getEnvironmentVariables } from 'components/AppRoot/StaticResources/actions';

import { Dropdown } from '@frontend/common';

const select = (state) => ({
  states: state.static.environmentVars.usStates,
});


export class StateDropdown extends React.Component {

  static propTypes = {
    states: PropTypes.array.isRequired,
    getEnvironmentVariables: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.states.length === 0) {
      this.props.getEnvironmentVariables(['USStates'])
        .catch(() => null);
    }
  }

  render() {
    const { states, ...otherProps } = this.props;
    return (
      <Dropdown
        label='State'
        options={states.map(state => ({ value: state.abbreviation, display: state.fullName }))}
        {...otherProps}
      />
    );
  }
}

export default connect(select, { getEnvironmentVariables })(StateDropdown);
