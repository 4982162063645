/*
*
* TemplatesList
*
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  Tabs,
  Tab,
} from '@mui/material';

import { getTemplates } from 'components/Features/protected/Templates/actions';
import {
  FloatingActionButton,
  Modal,
  notificationShow,
  properCase,
  Dropdown,
  AgeBasedTemplateIcon,
  StaticTemplateIcon,

  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';

import Banner from 'components/Features/Banner';
import { BANNER_LOCATIONS } from 'components/AppRoot/StaticResources/constants';

import TemplateAccountsList from './TemplateAccountsList';
import TemplateActivate from './TemplateActivate';
import TemplateDeactivate from './TemplateDeactivate';

import events from 'utils/ga';

import styles from './styles.module.css';

const select = (state) => ({
  activeTemplates: state.templates.activeTemplates,
  deactivatedTemplates: state.templates.deactivatedTemplates,
  permissions: state.session.permissions,
  webMessages: state.static.webMessages,
});

export class TemplatesList extends Component {

  static propTypes = {
    activeTemplates: PropTypes.array.isRequired,
    deactivatedTemplates: PropTypes.array.isRequired,
    getTemplates: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    permissions: PropTypes.object.isRequired,
    webMessages: PropTypes.object.isRequired,
  };

  state = {
    accountsViewModalLoading: false,
    accountsViewModalOpen: false,
    activateModalOpen: false,
    activeTab: 'active',
    activeTemplates: [],
    canEditTemplates: this.props.permissions.editTemplates,
    copyModalOpen: false,
    deactivatedTemplates: [],
    deactivateModalOpen: false,
    loading: false,
    messages: [],
    selectedTemplate: {
      id: 0,
      name: ''
    },
    viewTemplateModalOpen: false,
  };

  fabButtonsCompose() {
    const options = [
      {
        title: 'Copy Existing Template',
        icon: 'content_copy',
        action: () => this.setState({ copyModalOpen: true })
      },
      {
        title: 'New Static Template',
        icon: <StaticTemplateIcon color='var(--accent)' />,
        action: () => this.props.history.push('/templates/new/static')
      },
      {
        title: 'New Age-Based Template',
        icon: <AgeBasedTemplateIcon color='var(--accent)' />,
        action: () => this.props.history.push('/templates/new/age-based')
      },
    ];

    if (this.state.activeTemplates.concat(this.state.deactivatedTemplates).length === 0) {
      return options.slice(1);
    }

    return options;
  }

  tableDataFormat(tableData) {
    tableData.forEach(data => {
      data.TpDeactivatedByAdvisor = properCase(data.TpDeactivatedByAdvisor);
      data.TpCreatedByAdvisor = properCase(data.TpCreatedByAdvisor);
      data.TpTypeDescription = properCase(data.TpTypeDescription);
    });

    return tableData;
  }

  templatesGet() {
    this.props.getTemplates()
      .then(() => {
        this.setState({
          activeTemplates: this.tableDataFormat(this.props.activeTemplates),
          deactivatedTemplates: this.tableDataFormat(this.props.deactivatedTemplates),
          loading: false,
        });
      })
      .catch(() => this.setState({ loading: false }));
  }

  templateModalClose(modalName) {
    this.setState({
      [modalName]: false,
      selectedTemplate: {
        id: 0,
        name: ''
      },
    });
  }

  templateSelectionCompose = () => {
    return this.state.activeTemplates
      .concat(this.state.deactivatedTemplates)
      .map(template => ({
        value: template.CustomTemplateHeaderId,
        display: template.TpName,
      }));
  }

  componentDidMount() {
    if (this.props.activeTemplates.length === 0) {
      this.setState({
        loading: true,
      }, () => this.templatesGet());
    }
    else {
      this.setState({
        activeTemplates: this.tableDataFormat(this.props.activeTemplates),
        deactivatedTemplates: this.tableDataFormat(this.props.deactivatedTemplates),
      }, () => this.templatesGet());
    }
  }

  render() {
    const { loading, activeTemplates, deactivatedTemplates, canEditTemplates, activeTab } = this.state;
    const actions = [
      {
        displayName: 'View Template',
        type: 'menu',
        onSelect: row => this.props.history.push(`/templates/view/${row.CustomTemplateHeaderId}`),
      },
      {
        displayName: 'View Accounts',
        type: 'menu',
        onSelect: row => this.setState({
          accountsViewModalOpen: true,
          accountsViewModalLoading: true,
          selectedTemplate: {
            id: row.CustomTemplateHeaderId,
            name: row.TpName,
          }
        }),
      },
      {
        displayName: 'Activate',
        type: 'menu',
        onSelect: row => this.setState({
          activateModalOpen: true,
          selectedTemplate: {
            id: row.CustomTemplateHeaderId,
            name: row.TpName,
          }
        }),
        showIf: () => canEditTemplates && activeTab === 'deactivated'
      },
      {
        displayName: 'Deactivate',
        type: 'menu',
        onSelect: row => this.setState({
          deactivateModalOpen: true,
          selectedTemplate: {
            id: row.CustomTemplateHeaderId,
            name: row.TpName,
          }
        }),
        showIf: () => canEditTemplates && activeTab !== 'deactivated'
      },
    ];
    const baseColumns = [
      {
        key: 'TpName',
        title: 'Name',
        type: 'string',
      },
      {
        key: 'TpTypeDescription',
        title: 'Type',
        type: 'string',
        hideOn: ['phone'],
      },
    ];

    const { webMessages } = this.props;
    return (
      <div><br />
        <Banner show={Boolean(webMessages[BANNER_LOCATIONS.TEMPLATE_MSG])} body={webMessages[BANNER_LOCATIONS.TEMPLATE_MSG]} />
        <Tabs
          value={activeTab}
          onChange={(e, value) => this.setState({ activeTab: value })}
          variant='fullWidth'
        >
          <Tab
            label='Active'
            value='active'
          />
          <Tab
            label='Deactivated'
            value='deactivated'
          />
        </Tabs>
        <div className={styles.TemplatesList_table}>
          {activeTab === 'active' && (
            <SmartTable
              idKey='CustomTemplateHeaderId'
              rows={activeTemplates}
              loading={loading}
              emptyMessage='No customized investment option templates to display.'
              actions={actions}
              columns={[
                ...baseColumns,
                {
                  key: 'TpCreatedByAdvisor',
                  title: 'Created By',
                  type: 'string',
                  hideOn: ['phone', 'tablet'],
                },
                {
                  key: 'CreatedDate',
                  title: 'Created Date',
                  type: 'dateString',
                  hideOn: ['phone', 'tablet'],
                },
              ]}
            >
              <TableToolbar
                onSearchFocus={() => events.tableSearchAccessed('Templates List (Activated)', window.location.pathname)}
              />
              <TableContainer minWidth='100%' maxHeight='100%'>
                <TableHeader />
                <TableRows />
              </TableContainer>
              <TablePagination />
            </SmartTable>
          )}
          {activeTab === 'deactivated' && (
            <SmartTable
              idKey='CustomTemplateHeaderId'
              rows={deactivatedTemplates}
              loading={loading}
              emptyMessage='No deactivated templates to display.'
              actions={actions}
              columns={[
                ...baseColumns,
                {
                  key: 'TpDeactivatedByAdvisor',
                  title: 'Deactivated By',
                  type: 'string',
                  hideOn: ['phone', 'tablet'],
                },
                {
                  key: 'DeactivatedDate',
                  title: 'Deactivated Date',
                  type: 'dateString',
                  hideOn: ['phone', 'tablet'],
                },
              ]}
            >
              <TableToolbar
                onSearchFocus={() => events.tableSearchAccessed('Templates List (Deactivated)', window.location.pathname)}
              />
              <TableContainer minWidth='100%' maxHeight='100%'>
                <TableHeader />
                <TableRows />
              </TableContainer>
              <TablePagination />
            </SmartTable>
          )}
        </div>

        {canEditTemplates && (
          <FloatingActionButton
            mainButtonTitle='Create Template'
            mainIcon='add'
            menuOptions={this.fabButtonsCompose()}
            hasMenu
          />
        )}

        <Modal
          actionButtons={[
            {
              label: 'Cancel',
              action: () => this.templateModalClose('copyModalOpen'),
              disabled: this.state.loading,
            },
            {
              label: 'Copy',
              action: () => { this.props.history.push(`/templates/copy/${this.state.selectedTemplate.id}`); this.templateModalClose('copyModalOpen'); }, //eslint-disable-line brace-style
              disabled: this.state.selectedTemplate.id === 0,
              loading: this.state.loading,
              buttonType: 'contained',
            }
          ]}
          onCloseModal={() => this.templateModalClose('copyModalOpen')}
          show={this.state.copyModalOpen}
          title='Copy Template'
        >
          <div className={styles.TemplatesList_copyTemplate}>
            <Dropdown
              label='Template to Copy'
              value={this.state.selectedTemplate.id}
              onChange={value => this.setState({ selectedTemplate: { id: value, name: '' } })}
              options={this.templateSelectionCompose()}
            />
          </div>
        </Modal>

        <TemplateAccountsList
          onClose={() => this.templateModalClose('accountsViewModalOpen')}
          open={this.state.accountsViewModalOpen}
          selectedTemplate={this.state.selectedTemplate}
        />

        <TemplateActivate
          onClose={() => this.templateModalClose('activateModalOpen')}
          open={this.state.activateModalOpen}
          selectedTemplate={this.state.selectedTemplate}
          templatesGet={() => this.templatesGet()}
        />

        <TemplateDeactivate
          onClose={() => this.templateModalClose('deactivateModalOpen')}
          open={this.state.deactivateModalOpen}
          selectedTemplate={this.state.selectedTemplate}
          templatesGet={() => this.templatesGet()}
        />
      </div>
    );
  }
}

export default withRouter(connect(select, { getTemplates, notificationShow })(TemplatesList));
