/*
*
* NewAccounts Component
*
*/
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import NewAccountsList from './NewAccountsList';
import NewAccountReview from './NewAccountReview';
import AddSingleAccount from './AddSingleAccount';
import PageNotFound from 'components/Features/protected/PageNotFound';


export class NewAccounts extends React.Component {

  render() {
    return (
      <Switch>
        <Route exact path='/new-accounts' component={NewAccountsList} />
        <Route exact path='/new-accounts/add' component={AddSingleAccount} />
        <Route path='/new-accounts/:id' component={NewAccountReview} />
        <Route component={PageNotFound} />
      </Switch>
    );
  }
}

export default NewAccounts;
