export const ACCOUNT_DETAILS_GET = 'app/Features/protected/Accounts/ACCOUNT_DETAILS_GET';
export const ACCOUNT_TRANSACTIONS_DETAILS_GET = 'app/Features/protected/Accounts/ACCOUNT_TRANSACTIONS_DETAILS_GET';
export const ACCOUNTS_SEARCH = 'app/Features/protected/Accounts/ACCOUNTS_SEARCH';
export const ACCOUNTS_GET_BY_AGENT = 'app/Features/protected/Accounts/ACCOUNTS_GET_BY_AGENT';

export const AGENT_BANK_ACCOUNTS_GET = 'app/Features/protected/Accounts/AGENT_BANK_ACCOUNTS_GET';

export const BANK_ACCOUNT_ADD = 'app/Features/protected/Accounts/BANK_ACCOUNT_ADD';

export const CONTRIBUTION_CREATE = 'app/Features/protected/Accounts/CONTRIBUTION_CREATE';
export const CONTRIBUTION_DETAILS_GET = 'app/Features/protected/Accounts/CONTRIBUTION_DETAILS_GET';
export const CONTRIBUTION_EDIT = 'app/Features/protected/Accounts/CONTRIBUTION_EDIT';
export const CONTRIBUTION_EDIT_DETAILS_CLEAR = 'app/Features/protected/Accounts/CONTRIBUTION_EDIT_DETAILS_CLEAR';
export const CONTRIBUTION_EDIT_DETAILS_GET = 'app/Features/protected/Accounts/CONTRIBUTION_EDIT_DETAILS_GET';

export const GIFT_CODE_STATUS_EDIT = 'app/Features/protected/Accounts/GIFT_CODE_STATUS_EDIT';

export const NEXT_RUN_DATE_GET = 'app/Features/protected/Accounts/NEXT_RUN_DATE_GET';

export const OPTION_CHANGE_CANCEL = 'app/Features/protected/Accounts/OPTION_CHANGE_CANCEL';
export const OPTION_CHANGE_CREATE = 'app/Features/protected/Accounts/OPTION_CHANGE_CREATE';
export const OPTION_CHANGE_DETAILS_GET = 'app/Features/protected/Accounts/OPTION_CHANGE_DETAILS_GET';

export const ROUTING_INFO_GET = 'app/Features/protected/Accounts/ROUTING_INFO_GET';

export const SCHOOLS_GET = 'app/Features/protected/Accounts/SCHOOLS_GET';

export const TRANSACTION_DETAILS_GET = 'app/Features/protected/Accounts/TRANSACTION_DETAILS_GET';

export const UPCOMING_TRANSACTIONS_GET = 'app/Features/protected/Accounts/UPCOMING_TRANSACTIONS_GET';

export const WITHDRAWAL_CREATE = 'app/Features/protected/Accounts/WITHDRAWAL_CREATE';
export const WITHDRAWAL_DETAILS_GET = 'app/Features/protected/Accounts/WITHDRAWAL_DETAILS_GET';

export const TRANSFER_CREATE = 'app/Features/protected/Accounts/TRANSFER_CREATE';
export const TRANSFER_UPDATE = 'app/Features/protected/Accounts/TRANSFER_UPDATE';
export const TRANSACTION_DELETE = 'app/Features/protected/Accounts/TRANSACTION_DELETE';
export const SCHEDULED_TRANSACTION_GET = 'app/Features/protected/Accounts/SCHEDULED_TRANSACTION_GET';

export const ACCOUNT_DOCUMENTS_GET = 'app/Features/protected/Accounts/ACCOUNT_DOCUMENTS_GET';
export const ACCOUNT_DOCUMENT_DOWNLOAD_DATA = 'app/Features/protected/Accounts/ACCOUNT_DOCUMENT_DOWNLOAD_DATA';

export const BULK_OPTION_CHANGE_CANDIDATES_GET = 'app/Features/protected/Accounts/BULK_OPTION_CHANGE_CANDIDATES_GET';
export const BULK_OPTION_CHANGE_CREATE = 'app/Features/protected/Accounts/BULK_OPTION_CHANGE_CREATE';

export const TRANSFER_ACCOUNTS_GET = 'app/Features/protected/Accounts/TRANSFER_ACCOUNTS_GET';

export const ACCOUNT_INVESTMENT_PATH_GET = 'app/Features/protected/Accounts/ACCOUNT_INVESTMENT_PATH_GET';

export const ACCOUNT_TRANSACTION_PREVIEW = 'app/Features/protected/Accounts/ACCOUNT_TRANSACTION_PREVIEW';
export const TRANSACTION_TYPES = {
  WITHDRAWAL: 'Withdrawal',
  TRANSFER: 'TransferOut',
  OPTION_CHANGE: 'OptionChange',
  CONTRIBUTION: 'Contribution',
};

export const PDF_FILE = 'PDF';
export const HTML_FILE = 'HTML';

export const GET_PRR_DATES = 'src/components/Features/protected/Accounts/GET_PRR_DATES';
export const GET_PRR = 'src/components/Features/protected/Accounts/GET_PRR';

export const COUNTRIES_LIST_GET = 'src/components/Features/protected/Accounts/COUNTRIES_LIST_GET,';
