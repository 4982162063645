/*
*
* AddBankAccount Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Modal,
  notificationShow
} from '@frontend/common';
import {
  addBankAccount,
  getAgentBankAccounts,
  getBankRoutingInfo
} from '../../../actions';
import checkExample from './CheckExample.gif';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';

import GiactRejectionModal from '../GiactRejectionModal';

import styles from './styles.module.css';

const select = (state) => ({
  bankName: state.accounts.bankName,
  GiactResultTypes: state.static.environmentVars.GiactResultTypes,
});

export class AddBankAccount extends React.Component {

  static propTypes = {
    addBankAccount: PropTypes.func.isRequired,
    agentId: PropTypes.string.isRequired,
    bankName: PropTypes.string.isRequired,
    contributionDetailsGet: PropTypes.func.isRequired,
    getAgentBankAccounts: PropTypes.func.isRequired,
    getBankRoutingInfo: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    GiactResultTypes: PropTypes.object.isRequired,
  };

  state = {
    bankAccountLoading: false,
    bankAccountNumber: '',
    bankAccountType: '',
    bankName: '',
    confirmedBankAccountNumber: '',
    copyError: '',
    routingNumber: '',
    errors: {},
    showGiactRejectionModal: false,
  };

  bankAccountAdd = () => {
    const { bankAccountNumber, bankAccountType, routingNumber } = this.state;
    const { GiactResultTypes } = this.props;

    const bankInfo = {
      BankAccountNumber: bankAccountNumber,
      BankAccountType: bankAccountType,
      RoutingNumber: routingNumber
    };

    this.setState({ bankAccountLoading: true });

    this.props.addBankAccount(this.props.agentId, bankInfo)
      .then((response) => { 
        const newBankAccount = response.payload.data;
        const GiactResult = newBankAccount.GiactResult;
        this.formClear();
        this.props.onClose();
        // check on GIACT here, set flag to show the rejection screen
        this.setState({ bankAccountLoading: false, copyError: '' });
        if (GiactResult === GiactResultTypes.Fail) {
          return this.setState({ showGiactRejectionModal: true });
        }
        else {
          return this.props.getAgentBankAccounts(this.props.agentId, 'contributions')
            .then(() => this.props.contributionDetailsGet())
            .catch(() => null);
        }

      })
      .catch(() => this.setState({ bankAccountLoading: false, copyError: '' }));
  }

  copyPrevent = () => {
    const confirmField = document.getElementById('confirmedBankAccountNum');

    confirmField.onpaste = (e) => {
      e.preventDefault();
      this.setState({ copyError: 'Please type bank account number to confirm.' });
    };
  }

  formClear = () => {
    this.setState({
      bankAccountNumber: '',
      bankAccountType: '',
      bankName: '',
      confirmedBankAccountNumber: '',
      routingNumber: '',
    });
  }

  inputFormat = (value) => {
    return value.replace(/[^0-9.]/g, '').replace(/(\.*)\./g, '');
  }

  routingInfoGet = () => {
    if (this.state.routingNumber) {
      this.props.getBankRoutingInfo(this.state.routingNumber)
        .then(() => this.setState({ bankName: this.props.bankName, errors: {} }))
        .catch((error) => this.setState({ bankName: '', errors: { routingNumber: error.payload.data[0].Message } }));
    }
  }

  render() {
    const { onClose, open } = this.props;
    const { bankAccountLoading, bankAccountNumber, bankAccountType, bankName, confirmedBankAccountNumber, copyError, errors, routingNumber } = this.state;
    const confirmError = confirmedBankAccountNumber.length > 0 && bankAccountNumber !== confirmedBankAccountNumber;

    return (
      <>
        <Modal
          actionButtons={[
            {
              action: () => this.bankAccountAdd(),
              buttonType: 'contained',
              disabled: bankAccountNumber !== confirmedBankAccountNumber || !bankAccountNumber || !bankAccountType || !confirmedBankAccountNumber || !routingNumber || Object.keys(errors).length > 0,
              label: 'Add',
              loading: bankAccountLoading
            },
            {
              action: () => onClose(),
              label: 'Cancel',
              disabled: bankAccountLoading
            },
          ]}
          maxWidth='lg'
          onCloseModal={onClose}
          show={open}
          title='Add Bank Account'
        >
          <div className={styles.AddBankAccount_formContainer}>
            <div className={styles.AddBankAccount_form}>
              <FormControl>
                <FormLabel className={styles.AddBankAccount_radioButtonHeader}>Bank Account Type</FormLabel>
                <RadioGroup
                  onChange={(e) => this.setState({ bankAccountType: e.target.value })}
                  value={bankAccountType}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label='Checking'
                    value='Checking'
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label='Savings'
                    value='Savings'
                  />
                </RadioGroup>
              </FormControl>


              <FormControl className={styles.AddBankAccount_textField} error={Boolean(errors.routingNumber)}>
                <InputLabel htmlFor='routingNumber'>Routing Number</InputLabel>
                <Input
                  id='routingNumber'
                  inputProps={{
                    maxLength: 17
                  }}
                  onBlur={() => this.routingInfoGet()}
                  onChange={e => this.setState({ routingNumber: e.target.value, errors: { ...errors, routingNumber: '' } })}
                  value={routingNumber}
                />
                <FormHelperText>{errors.routingNumber}</FormHelperText>
              </FormControl>
              {bankName && (<div className={styles.AddBankAccount_bankName}>{bankName}</div>)}

              <TextField
                className={styles.AddBankAccount_textField}
                inputProps={{
                  maxLength: 17
                }}
                onChange={e => this.setState({ bankAccountNumber: e.target.value })}
                onInput={e => e.target.value = this.inputFormat(e.target.value)}
                label='Bank Account Number'
              />

              <FormControl className={styles.AddBankAccount_textField} error={Boolean(confirmError) || Boolean(copyError)}>
                <InputLabel htmlFor='confirmedBankAccountNum'>Confirm Bank Account Number</InputLabel>
                <Input
                  id='confirmedBankAccountNum'
                  inputProps={{
                    maxLength: 17
                  }}
                  onChange={e => this.setState({ confirmedBankAccountNumber: e.target.value, copyError: '' })}
                  onFocus={() => this.copyPrevent()}
                  onInput={e => e.target.value = this.inputFormat(e.target.value)}
                  value={confirmedBankAccountNumber}
                />
                {confirmError && <FormHelperText>Bank account numbers must match.</FormHelperText>}
                <FormHelperText>{copyError}</FormHelperText>
              </FormControl>
            </div>
            <div>
              <img className={styles.AddBankAccount_checkImg} src={checkExample} alt='Check Example' />
            </div>
          </div>
          <div>
            <ul>
              <li>The linked account must be a checking or savings account at a bank or credit union located in the United States.</li>
              <li>You must own the linked account and your name must be in the account title.</li>
              <li>my529 will verify the information you provide with the appropriate financial institution.</li>
            </ul>
          </div>
        </Modal>
        <GiactRejectionModal
          open={this.state.showGiactRejectionModal}
          handleCloseGiactRejectionModal={() => this.setState({ showGiactRejectionModal: false })}
        />
      </>
    );
  }
}

export default connect(select, {
  addBankAccount,
  getAgentBankAccounts,
  getBankRoutingInfo,
  notificationShow
})(AddBankAccount);
