/*
*
* ManageData Constants
*
*/
export const POAS_FOR_EXPORT_GET = 'src/components/Features/protected/ManageData/POAS_FOR_EXPORT_GET';
export const POAS_FOR_EXPORT_UPDATE = 'src/components/Features/protected/ManageData/POAS_FOR_EXPORT_UPDATE';

export const BACK_OFFICES_GET = 'src/components/Features/protected/ManageData/BACK_OFFICES_GET';
export const BACK_OFFICES_UPDATE = 'src/components/Features/protected/ManageData/BACK_OFFICES_UPDATE';

export const PORTFOLIO_PROVIDERS_GET = 'src/components/Features/protected/ManageData/PORTFOLIO_PROVIDERS_GET';
export const PORTFOLIO_PROVIDERS_UPDATE = 'src/components/Features/protected/ManageData/PORTFOLIO_PROVIDERS_UPDATE';
export const PORTFOLIO_DATA_DOWNLOAD = 'src/components/Features/protected/ManageData/PORTFOLIO_DATA_DOWNLOAD';