/** Event Actions **/
export const CLICK = 'Click';
export const GRAPH = 'Graph';
export const HIT = 'Page hit';
export const SEARCH = 'Search';


/** Page View URLs **/
export const SIGNUP_STEPPER = '/sign-up/step_';
export const APP_TOUR_STEPPER = '/app-tour/step_';
export const CONTRIBUTIONS_ONETIME_STEPPER = '/contributions/new/one-time/step_';
export const CONTRIBUTIONS_RECURRING_STEPPER = '/contributions/new/recurring/step_';
export const CONTRIBUTIONS_SPECIAL_STEPPER = '/contributions/new/special/step_';
export const OPTION_CHANGES_STEPPER = '/option-changes/new/step_';
export const WITHDRAWALS_STEPPER = '/withdrawals/new/step_';

/** Event Categories **/
export const COMMON_PACKAGE = 'Common Package';
export const NAVIGATION = 'Navigation';
export const PAGE_NOT_FOUND = 'Page Not Found';


/** Event Labels **/