/*
*
* PageNotFound Component
*
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import {
  Icon,
  Button,
} from '@mui/material';

import events from 'utils/ga';

import styles from './styles.module.css';

export class PageNotFound extends Component {

  static propTypes = {
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    events.pageNotFoundHit(this.props.location.pathname);
  }

  render() {
    return (
      <div className={styles.PageNotFound_container}>
        <div className={styles.PageNotFound_shrug}>
          {'¯\\_(ツ)_/¯'}
        </div>
        <p className={styles.PageNotFound_message}>
          Oops! Looks like you reached a page that doesn&apos;t exist.
        </p>
        <Button
          variant='contained'
          onClick={() => this.props.history.push('/')}
        >
          <Icon>keyboard_arrow_left</Icon>
          Back to Home
        </Button>
      </div>
    );
  }
}

export default withRouter(PageNotFound);