/*
*
* PhoneNumbers Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
} from '@mui/material';

import {
  notificationShow,
  PhoneNumberInput,
  LoadingOverlay,
} from '@frontend/common';

import { phoneHasNo10Digits } from 'utils/helpers/form_validation';
import { cleanDigits } from 'utils/helpers/sanitation';

import { contactInfoUpdate } from '../actions';

import styles from '../styles.module.css';

const select = (state) => ({
  advisor: state.myInfo.advisor,
});

export class PhoneNumbers extends React.Component {
  static propTypes = {
    advisor: PropTypes.object.isRequired,
    notificationShow: PropTypes.func.isRequired,
    contactInfoUpdate: PropTypes.func.isRequired,
  }

  state = {
    workPhone: this.props.advisor.PhoneNumber.Number,
    cellPhone: this.props.advisor.PhoneNumber1.Number,
    workPhoneError: '',
    cellPhoneError: '',
    submitting: false,
  };

  disableUpdateButton = () => {
    const { workPhone, cellPhone } = this.state;
    const { PhoneNumber, PhoneNumber1 } = this.props.advisor;

    return cleanDigits(workPhone) === cleanDigits(PhoneNumber.Number) && cleanDigits(cellPhone) === cleanDigits(PhoneNumber1.Number);
  }

  phoneInputsValidate() {
    const { workPhone, cellPhone } = this.state;

    let workPhoneError = '';
    let cellPhoneError = '';

    if (workPhone) {
      workPhoneError = phoneHasNo10Digits(workPhone);
    }

    if (cellPhone) {
      cellPhoneError = phoneHasNo10Digits(cellPhone);
    }

    this.setState({ workPhoneError, cellPhoneError });
    const hasError = Boolean(workPhoneError) || Boolean(cellPhoneError);

    return !hasError;
  }

  onPhoneChange = (keyName, value) => {
    // set value and clear related field error
    this.setState({ [keyName]: value, workPhoneError: '', [`${keyName}Error`]: '' });
  }

  onSubmitPhoneNumbers = (e) => {
    e.preventDefault();
    if (this.phoneInputsValidate()) {
      const { workPhone, cellPhone } = this.state;
      const updatedAdvisor = {
        FinancialAdvisorId: this.props.advisor.FinancialAdvisorId,
        PhoneNumber: cleanDigits(workPhone),
        PhoneNumber1: cleanDigits(cellPhone),
      };
      this.setState({ submitting: true });
      this.props.contactInfoUpdate(updatedAdvisor)
        .then(() => {
          this.props.notificationShow('Advisor phone numbers updated.', 'success');
        })
        .finally(() => this.setState({ submitting: false }));
    }
  }

  componentDidUpdate(prevProps) {
    const {
      advisor: {
        PhoneNumber,
        PhoneNumber1
      }
    } = this.props;

    if (
      prevProps.advisor.PhoneNumber.Number !== PhoneNumber.Number ||
      prevProps.advisor.PhoneNumber1.Number !== PhoneNumber1.Number
    ) {
      this.setState({ workPhone: PhoneNumber.Number, cellPhone: PhoneNumber1.Number });
    }
  }

  render() {
    const {
      advisor: {
        PhoneNumber,
        PhoneNumber1
      }
    } = this.props;
    const { workPhone, cellPhone, workPhoneError, cellPhoneError, submitting } = this.state;

    const isIntlWorkPhone = (PhoneNumber.Number === workPhone && PhoneNumber.MaskType !== 'US') ? 'true' : 'false';
    const isIntlCellPhone = (PhoneNumber1.Number === cellPhone && PhoneNumber1.MaskType !== 'US') ? 'true' : 'false';

    return (
      <form className={styles.myInfo_right_container} onSubmit={this.onSubmitPhoneNumbers}>
        <div className={styles.warning}>Warning: Changing a phone number here will not change the phone number you have registered for two factor authentication.</div>

        <div className={styles.myInfo_textInput}>
          <PhoneNumberInput
            label='Work Phone'
            name='Work'
            onChange={({ formattedValue }) => this.onPhoneChange('workPhone', formattedValue)}
            value={workPhone}
            fullWidth
            errorText={workPhoneError}
            isintl={isIntlWorkPhone}
            disabled={submitting}
          />
        </div>

        <div className={styles.myInfo_textInput}>
          <PhoneNumberInput
            label='Cell Phone'
            name='Cell'
            onChange={({ formattedValue }) => this.onPhoneChange('cellPhone', formattedValue)}
            value={cellPhone}
            fullWidth
            errorText={cellPhoneError}
            isintl={isIntlCellPhone}
            disabled={submitting}
          />
        </div>

        <div className={styles.myInfo_button}>
          <LoadingOverlay show={submitting}>
            <Button
              type='submit'
              variant='contained'
              disabled={this.disableUpdateButton()}
            >
              Update
            </Button>
          </LoadingOverlay>
        </div>
      </form>
    );
  }
}

export default connect(select, {
  notificationShow,
  contactInfoUpdate,
})(PhoneNumbers);
