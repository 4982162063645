export const validationErrors = {
  fullBalanceNotAllocated: 'Full Balance Transfers must be 100% allocated.',
  partialBalancePercentOverMax: 'Total must be less than 100%.',
  partialBalancePercentUnderMinimum: 'Total must be greater than 0%.',
  totalGreaterThanAvailable: 'Total must be less than Available Balance.',
  totalUnderMinimum: 'Total must be at least $1.00.',
  accountOwnerSingleAccount: 'There is only one account under this Account Owner.',
  destinationAccountMissing: 'Destination account is missing.',
  noOptionChangesRemaining: (beneficiaryName) => `No Option Changes Remaining for Beneficiary ${beneficiaryName}.`,
  custodialToOtherType: (typeName) => `${typeName} Accounts cannot be transferred to other account types.`,
  custodialFromOtherType: (typeName) => `${typeName} Accounts cannot be transferred from other account types.`,
  custodialToOtherBeneficiary: (typeName) => `${typeName} Accounts cannot be transferred to other Beneficiaries.`,
  sourceAccountZeroBalance: 'Source Account has no funds to transfer.',
  termsNotChecked: 'I have read and understand these terms and conditions.',
};
